var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "mr-1"
  }, [_c('v-btn', {
    attrs: {
      "elevation": "0",
      "outlined": "",
      "small": _vm.small,
      "color": "info"
    },
    on: {
      "click": _vm.templateDownload
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.template")) + " ")])], 1), _c('span', [_c('excel-import-create', {
    attrs: {
      "dto": _vm.createDto,
      "baseData": _vm.baseData,
      "outlined": _vm.outlined,
      "small": _vm.small,
      "text": _vm.$t('common.nouns.import')
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }