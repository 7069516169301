import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IService, Service } from "@/models/service.entity";

export const ServiceDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IService> {
  protected mapEntity(entity: IService): IService {
    return new Service(entity);
  }

  getIdentifier(entity: IService): string {
    return entity.id;
  }

  mapsConfig = {};
})();
