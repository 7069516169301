import { RouteConfigSingleView } from "vue-router/types/router";
import i18n from "../../plugins/I18nPlugin";
import { ActionEnum } from "../enum/authActionEnum";
import { BackendResourceEnum, FrontendResourceEnum, ResourceEnum } from "../enum/authResourceEnum";
import { NavigationCategoryEnum } from "../enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "../interface/custom.route.config.interface";
import { PartnerModule } from "../modules/partner";
import { UserModule } from "../modules/me-user.store";
import Vue from "vue";

export class NavigationRoute implements ICustomRouteConfig, RouteConfigSingleView {
  path = "";
  name?: string;

  navigation?: {
    category: NavigationCategoryEnum;
    icon: string;
    isEnabled: boolean;
    isSideBarNavigation: boolean;
    isQuickLink: boolean;
    isProfileMenu: boolean;
    priority?: number;
  };
  permission?: {
    resource: FrontendResourceEnum | BackendResourceEnum;
    action: ActionEnum;
    field?: FrontendResourceEnum | BackendResourceEnum;
  };

  constructor(config: ICustomRouteConfig & RouteConfigSingleView) {
    if (config.path) {
      this.path = config.path;
    }
    if (config.name) {
      this.name = config.name;
    }

    if (config.navigation) {
      this.navigation = config.navigation;
    }
    if (config.permission) {
      this.permission = config.permission;
    }
  }

  get title() {
    if (!this.name) {
      return null;
    }

    return i18n.t(`nav.${this.name}.title`).toString();
  }

  get description() {
    if (!this.name) {
      return null;
    }

    return i18n.t(`nav.${this.name}.description`).toString();
  }

  get category() {
    if (!this.navigation?.category) {
      return null;
    }

    return i18n.t(`navCategory.${this.navigation?.category}`).toString();
  }

  get tags(): string[] {
    if (!this.name) {
      return [];
    }

    return i18n.t(`nav.${this.name}.tags`, { returnObjects: true }) as any;
  }

  get isAccessAllowed() {
    return this.isUserPermittedToSee();
  }

  /**
   * checks if user is permitted to see the item
   *
   * @param item the item to check
   */
  private isUserPermittedToSee() {
    if (!this.permission) {
      return true;
    }

    const { resource, action } = this.permission;

    return UserModule.abilities.can(action, resource, this.ressourceId);
  }

  get ressourceId(): string | undefined {
    /**
     * No specific id permission needed
     */
    if (!this.permission?.field) {
      return undefined;
    }

    if (this.permission?.field === ResourceEnum.PARTNER) {
      return PartnerModule.partner._id;
    }

    Vue.$log.warn(
      `Please implement the Ressource Enum in Naviagtion route module of Type: ${this.permission?.field}. Element:" ${this.path}`
    );

    return undefined;
  }
}
