
























import { Component, Vue } from "vue-property-decorator";
import { FahrzeugscheinModule } from "@/store/modules/fahrzeugschein";

@Component({
  components: {}
})
export default class FahrzeugscheinImage extends Vue {
  currentRotation = 0;
  zoomed = false;
  currentScale = 1;
  loading = false;

  constructor() {
    super();
  }

  get fahrzeugscheinImage() {
    return FahrzeugscheinModule.fahrzeugscheinImage.image_url;
  }

  async mounted() {
    this.loading = true;
    try {
      await FahrzeugscheinModule.getFahrzeugscheinImage(this.$route.params.id);
    } catch {
      this.$log.error("Error loading fahrzeugschein image");
    } finally {
      this.loading = false;
    }
  }

  zoomImage(event: Event) {
    const image = event.currentTarget as HTMLImageElement;

    if (!this.zoomed) {
      this.currentScale = 1.5;

      image.style.transform = "scale(" + this.currentScale + ") rotate(" + this.currentRotation + "deg)";
      image.style.transition = "0.7s ease-in-out";
      image.classList.remove("zoomOff");
      image.classList.add("zoomOn");
    } else {
      this.currentScale = 1;

      image.style.transform = "scale(" + this.currentScale + ") rotate(" + this.currentRotation + "deg)";
      image.style.transition = "0.7s ease-in-out";
      image.classList.remove("zoomOn");
      image.classList.add("zoomOff");
    }
    this.zoomed = !this.zoomed;
  }

  rotateImage() {
    const image = this.$el.querySelector(".fahrzeugscheinImage") as HTMLElement;

    if (image != null) {
      this.currentRotation += 90;
      image.style.transform = "scale(" + this.currentScale + ") rotate(" + this.currentRotation + "deg)";
      image.style.transition = "0.2s ease-in-out";
    }
  }
}
