import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { Booking, IBooking } from "@/models/booking.entity";

export const BookingDataAccessLayer = new (class BookingDataAccessLayer extends AbstractLocalDataAccessLayer<IBooking> {
  getIdentifier(entity: IBooking): string {
    return entity.id;
  }

  protected mapEntity(entity: IBooking): IBooking {
    return new Booking(entity);
  }
})();
