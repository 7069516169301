





























import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { Vehicle, IVehicle } from "@/models/vehicle.entity";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { CostModule } from "@/store/modules/cost.store";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { handleError } from "@/lib/utility/handleError";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";

@Component({
  components: { AnalyticsDateRangeSelectorDialog, LatestEntriesCardEmpty, RefsVehicle }
})
export default class FleetHomeVehicleCostAnalytics extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  partnerId!: string;

  readonly mobileRows = 5;
  readonly desktopRows = 5;

  isLoadingCosts = false;

  dates: string[] = [];

  costOrderDesc = true;

  beforeMount() {
    const year = new Date().getFullYear();

    const startDate = new Date();
    startDate.setFullYear(year - 1);
    startDate.setMonth(0);
    startDate.setDate(1);
    const endDate = new Date();
    startDate.setFullYear(year + 2);
    endDate.setMonth(0);
    endDate.setDate(1);

    this.dates.splice(0);
    this.dates.push(endDate.toISOString(), startDate.toISOString());
  }

  get costs() {
    return CostModule.filtered;
  }

  get rowNumber() {
    let sliceSize = this.desktopRows;
    if (this.isMobile) {
      sliceSize = this.mobileRows;
    }

    return sliceSize;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get bones() {
    const bone = "list-item, divider";
    const bones = [];

    for (let i = 0; i < this.rowNumber; i++) {
      bones.push(bone);
    }

    return bones.join(",");
  }

  async mounted() {
    await this.loadCosts();
  }

  async loadCosts() {
    CostModule.setFilters([
      new PageFilterElement({
        key: "refs.refType",
        operation: PageFilterOperationEnum.EQUAL,
        value: BackendResourceEnum.VEHICLE
      }),
      new PageFilterElement({
        key: "date",
        operation: PageFilterOperationEnum.GREATER_EQUAL,
        value: this.dates[0]
      }),
      new PageFilterElement({
        key: "date",
        operation: PageFilterOperationEnum.LESS_EQUAL,
        value: this.dates[1]
      })
    ]);

    this.isLoadingCosts = true;
    await CostModule.fetchAll({
      partnerId: this.partnerId
    }).catch(handleError);

    this.isLoadingCosts = false;

    await this.setVehicles();
  }

  vehicles: { total: string; vehicle: IVehicle; goToVehicle: () => void }[] = [];

  toggleCostOrder() {
    this.costOrderDesc = !this.costOrderDesc;
    this.setVehicles();
  }

  async setVehicles() {
    const costs = CostModule.filtered;

    const vehicleCostMap: Map<string, number> = new Map();

    for (const cost of costs) {
      for (const ref of cost.refs) {
        if (ref.refType !== BackendResourceEnum.VEHICLE) {
          continue;
        }

        vehicleCostMap.set(ref.refId, (vehicleCostMap.get(ref.refId) ?? 0) + cost.total);
      }
    }

    let vehicleIdsAndCost: { vehicleId: string; total: number }[] = [];

    for (const vehicleId of Array.from(vehicleCostMap.keys())) {
      const total = vehicleCostMap.get(vehicleId) ?? 0;
      vehicleIdsAndCost.push({ vehicleId, total });
    }

    if (this.costOrderDesc) {
      vehicleIdsAndCost.sort((a, b) => a.total - b.total);
    } else {
      vehicleIdsAndCost.sort((a, b) => b.total - a.total);
    }
    vehicleIdsAndCost = vehicleIdsAndCost.slice(0, this.rowNumber);

    const res: { total: string; vehicle: IVehicle; goToVehicle: () => void }[] = [];
    for (const { vehicleId, total } of vehicleIdsAndCost) {
      const vehicle =
        VehicleModule.maps.id.get(vehicleId)[0] ??
        (await new Vehicle({
          id: vehicleId,
          partnerId: this.partnerId
        }).fetch());

      res.push({
        total: total.toLocaleString("de-DE", { style: "currency", currency: "EUR" }),
        vehicle,
        goToVehicle: () => this.goToVehicle(vehicle)
      });
    }

    this.vehicles.splice(0);
    this.vehicles.push(...res);
  }

  goToVehicle(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, VehicleTabs.COST, false);
  }
}
