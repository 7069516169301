import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { IResource, Resource } from "@/models/resource.entity";
import { BookingResourceControllerFindAllByPartnerIdParamsGen } from "@/services/booking/v1/data-contracts";
import { Module, getModule } from "vuex-module-decorators";
import store from "../../store/VuexPlugin";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ResourceDataAccessLayer } from "./access-layers/resource.access-layer";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import resourceService from "@/services/booking/services/resourceService";
import { PaginationFilterListElement } from "./base-pagination.store";

const ResourcePageDataHandler = new (class extends AbstractPageDataProvider<
  IResource,
  BookingResourceControllerFindAllByPartnerIdParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: BookingResourceControllerFindAllByPartnerIdParamsGen): Promise<IPageViewModel<IResource>> {
    const res = await resourceService.findBookingsByPartner(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IResource[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "resource",
  store
})
export class ResourceStore extends PaginatedBaseStore<IResource, BookingResourceControllerFindAllByPartnerIdParamsGen> {
  protected _data = ResourceDataAccessLayer;
  protected _pageProvider = ResourcePageDataHandler;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Resource.filterables;

  get activeItems() {
    return this.entities.filter(e => e.isActive);
  }
}

export const ResourceModule = getModule(ResourceStore);
