








































































































import { emailRule, phoneRuleOptional } from "@/lib/rules/contactRule";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IInspection } from "@/models/inspection.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import { Attendee, IAttendee } from "@/models/attendee";
import AttendeeForm from "./AttendeeForm.vue";
import { IHandover } from "@/models/handover.entity";

@Component({
  components: {
    ConfirmActionDialog,
    ContextMenu,
    AttendeeForm
  }
})
export default class InspectionUserTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  inspection!: IInspection;

  @Prop()
  handover!: IHandover;

  isUserEditDialogActive = false;

  isUserAddDialogActive = false;

  isUserRemoveDialogActive = false;

  isFormValid = false;

  isFormLoading = false;

  editUserIndex = 0;

  removeUserIndex = 0;

  editUser: IAttendee = new Attendee();

  get attendees() {
    return this.inspection.attendees || [];
  }

  get emailRule() {
    return [emailRule()];
  }

  get phoneRuleOptional() {
    return [phoneRuleOptional()];
  }

  openAddUserDialog() {
    this.editUser = new Attendee();
    this.isUserAddDialogActive = true;
  }

  openEditUserDialog(index: number) {
    this.editUserIndex = index;
    this.editUser = new Attendee(this.attendees[index]);
    this.isUserEditDialogActive = true;
  }

  openRemoveUserDialog(index: number) {
    this.removeUserIndex = index;
    this.isUserRemoveDialogActive = true;
  }

  abortAddUserDialog() {
    this.isUserAddDialogActive = false;
  }

  abortEditUserDialog() {
    this.editUserIndex = -1;
    this.isUserEditDialogActive = false;
  }

  abortRemoveUserDialog() {
    this.removeUserIndex = -1;
    this.isUserRemoveDialogActive = false;
  }

  async confirmAddUserDialog() {
    this.isFormLoading = true;

    await this.inspection
      .updatePartial({
        attendees: [...this.attendees, this.editUser]
      })
      .catch(handleError);

    this.isFormLoading = false;
    this.isUserAddDialogActive = false;
  }

  async confirmEditUserDialog() {
    const valid = (this.$refs.editForm as any).form.validate();
    if (!valid) {
      throw new Error("Invalid");
    }

    this.isFormLoading = true;

    const attendees = [...this.attendees];
    attendees[this.editUserIndex] = this.editUser;

    await this.inspection
      .updatePartial({
        attendees
      })
      .catch(handleError);

    this.isFormLoading = false;
    this.isUserEditDialogActive = false;
  }

  async confirmRemoveUserDialog() {
    this.isFormLoading = true;

    const attendees = [...this.attendees];
    attendees.splice(this.removeUserIndex, 1);

    await this.inspection
      .updatePartial({
        attendees
      })
      .catch(handleError);

    this.isFormLoading = false;
    this.isUserRemoveDialogActive = false;
  }
}
