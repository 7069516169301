






import { Component, Vue } from "vue-property-decorator";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import PartnerReportInitializeCard from "@/components/partner/PartnerReportInitializeCard.vue";

@Component({
  components: {
    LayoutSimple,
    PartnerReportInitializeCard
  }
})
export default class PartnerReportInitialize extends Vue {
  isGoHomeOnBack = false;

  async mounted() {
    if (this.$route.query.isGoHomeOnBack === "true") {
      this.isGoHomeOnBack = true;
    }
  }
}
