
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { handleError } from "@/lib/utility/handleError";
import { MrfiktivCreateActivityLogDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { EventModule } from "@/store/modules/event.store";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleActivityLogModule } from "@/store/modules/vehicle-activity-log.store";
import { VehicleEventModule } from "@/store/modules/vehicle-event.store";
import { Component, Vue } from "vue-property-decorator";

/**
 * Mixin with stuff shared by views that display details of a ticket
 */
@Component({
  components: {},
  filters: {}
})
export default class FleetVehicleEventCrudMixin extends Vue {
  event: IEventUIDto | null = null;

  loadingUpdate = false;
  isLoadingEvents = false;

  /**
   * Updates an event
   * @param event the event to update
   * @param vehicleId the vehicle assigned to it
   */
  async updateEvent(event: IEventUIDto | null, vehicleId: string, refreshDate: Date) {
    if (!event) {
      this.$log.debug("updateEvent", undefined);
      return;
    }

    if (!vehicleId) {
      this.$log.debug("updateEvent", undefined);
      return;
    }

    this.isLoadingEvents = true;

    try {
      this.$log.debug("updateEvent", event);

      if (event.isVirtual) {
        this.$log.debug("Virtual Instance Found");

        if (!event.recurringEventId || !event.originalStart) {
          throw new Error("Recurring Event Id or Original Start is missing");
        }

        // We have to delete the virtual instance and create a new one
        const deletePromise = VehicleEventModule.delete({
          partnerId: PartnerModule.partner._id,
          vehicleId: vehicleId,
          eventId: event.recurringEventId,
          start: event.originalStart
        });

        const createPromise = VehicleEventModule.update({
          partnerId: PartnerModule.partner._id,
          vehicleId: vehicleId,
          eventId: event.recurringEventId,
          start: event.originalStart,
          data: event.createDto
        });

        await Promise.all([deletePromise, createPromise]);
      } else {
        if (!event.id) {
          throw new Error("Event Id missing");
        }

        if (event.isRecurringRoot) {
          this.$log.debug("Recurring Root Event Found");

          await EventModule.update({
            partnerId: PartnerModule.partner._id,
            eventId: event.id,
            data: event.createDto
          });
        } else {
          this.$log.debug("Event Instance Found");

          await EventModule.update({
            partnerId: PartnerModule.partner._id,
            eventId: event.id,
            data: event.createDto
          });
        }
      }

      // FIXME: We need to add an activity too.

      this.$toast.success("👍");
      this.event = null;
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingEvents = false;
    }

    await this.refreshEvents(vehicleId, refreshDate);
  }

  async deleteEvent(event: IEventUIDto | IEventUIDto, vehicleId: string, refreshDate?: Date) {
    this.isLoadingEvents = true;

    try {
      if (event.id) {
        this.$log.debug("Event Instance Found");

        await EventModule.delete({
          partnerId: PartnerModule.partner._id,
          eventId: event.id
        });

        // Also delete the virtual instance for a recurring event instance
        if (event.recurringEventId) {
          await VehicleEventModule.delete({
            partnerId: PartnerModule.partner._id,
            vehicleId: vehicleId,
            eventId: event.recurringEventId,
            start: event.start
          });
        }
      } else if (event.recurringEventId) {
        this.$log.debug("Virtual Instance Found");
        await VehicleEventModule.delete({
          partnerId: PartnerModule.partner._id,
          vehicleId: vehicleId,
          eventId: event.recurringEventId,
          start: event.start
        });
      } else {
        this.$log.debug("Didn't do anything", event);
      }

      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingEvents = false;
    }
    if (!refreshDate) {
      return;
    }
    await this.refreshEvents(vehicleId, refreshDate);
  }
  /**
   * Create Calender Event
   */
  async createEvent(event: IEventUIDto, vehicleId: string, refreshDate: Date) {
    this.isLoadingEvents = true;
    try {
      const createdEvent = await VehicleEventModule.create({
        partnerId: PartnerModule.partner._id,
        vehicleId: vehicleId,
        data: event.createDto
      });
      await this.createActivity(createdEvent, vehicleId);

      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingEvents = false;
    }
    await this.refreshEvents(vehicleId, refreshDate);
  }

  /**
   * Create Activity
   */
  async createActivity(event: IEventUIDto, vehicleId: string) {
    if (event && event.id) {
      try {
        const data: MrfiktivCreateActivityLogDtoGen = {
          source: {
            refType: BackendResourceEnum.VEHICLE,
            refId: vehicleId
          },
          target: [{ refType: BackendResourceEnum.EVENT, refId: event.id }],
          actionType: ActionEnum.CREATE,
          comment: event.summary
        };
        const activity = await VehicleActivityLogModule.create({ partnerId: PartnerModule.partner._id, data: data });
        VehicleActivityLogModule.addToList(activity);
      } catch (error) {
        handleError(error);
      }
    }
  }

  async refreshEvents(vehicleId: string, refreshDate: Date) {
    await FleetAggregationModule.removeEventsByVehicle(vehicleId);
    await Promise.all([this.loadEvents(vehicleId, refreshDate), this.loadRecurringEvents(vehicleId)]);
  }

  /**
   * Load Events for the upcoming year
   */
  async loadEvents(vehicleId: string, refreshDate: Date) {
    this.isLoadingEvents = true;

    const from = new Date(refreshDate.getFullYear(), refreshDate.getMonth() - 1).getTime();
    const to = new Date(refreshDate.getFullYear(), refreshDate.getMonth() + 2).getTime();

    try {
      await VehicleEventModule.listDocuments({
        partnerId: PartnerModule.partner._id,
        vehicleId: vehicleId,
        from,
        to
      });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("views.fleet.EventCard.error"));
    } finally {
      this.isLoadingEvents = false;
    }
  }

  /**
   * Load all recurring events
   */
  async loadRecurringEvents(vehicleId: string) {
    this.isLoadingEvents = true;
    try {
      await VehicleEventModule.getRecurringEvents({
        partnerId: PartnerModule.partner._id,
        vehicleId: vehicleId
      });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("views.fleet.EventCard.error"));
    } finally {
      this.isLoadingEvents = false;
    }
  }
}
