












import PartnerManageStepperStepLayout from "@/components/partner/PartnerManageStepperStepLayout.vue";
import PartnerManageStepperMixin from "@/mixins/PartnerManageStepperMixin.vue";
import { ManageStepperEnum } from "@/store/enum/partner/manage-stepper.enum";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { PartnerManageStepperStepLayout }
})
export default class PartnerManageStepperSlotStep extends PartnerManageStepperMixin {
  @Prop()
  context!: ManageStepperEnum;

  @Prop()
  preHook?: () => Promise<boolean>;

  @Prop()
  postHook?: () => Promise<boolean>;

  mounted() {
    this.setLoading(false);
    this.preHook?.();
  }

  get goNextButtonIcon() {
    return "mdi-arrow-right";
  }

  async rightButton() {
    await this.postHook?.();

    this.goToNext();
  }
}
