






import FleetVehicleTable from "@/components/fleet/FleetVehicleTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: { TheLayoutPortal, FleetVehicleTable }
})
export default class FleetVehicleListView extends PartnerFallbackMixin {
  async loadAll() {
    await VehicleModule.getAll({ partnerId: PartnerModule.partner._id });
  }

  async mounted() {
    try {
      await this.trySetByRouteOrDefault();

      this.loadAll();
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    }
  }
}
