import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IPartnerMessage, PartnerMessage } from "@/models/partner-message.entity";
import {
  MrfiktivInboxDtoGen,
  MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { PartnerMessageDataAccessLayer } from "./access-layers/partner-message.access-layer";
import { PaginationFilterListElement, PaginationFilterOperationEnum } from "./base-pagination.store";
import { PartnerMessageStore } from "./message-pagination.store";
import { PartnerMessagePageDataProvider } from "./page-data-provider/partner-message.page-data-provider";
import { PartnerModule } from "./partner";

@Module({
  dynamic: true,
  namespaced: true,
  name: "inboxes",
  store
})
export class InboxStore extends VuexModule {
  private _inboxes: { [key: string]: PartnerMessageStore } = {};

  private _loading = false;

  get loading() {
    return this._loading;
  }

  @Mutation
  private _mutateLoading(loading: boolean) {
    this._loading = loading;
  }

  /**
   * A list of all inboxes known to the partner
   */
  get inboxes() {
    const inboxWithMeta: (MrfiktivInboxDtoGen & { module: PartnerMessageStore })[] = [];
    for (const key of Object.keys(this._inboxes)) {
      const meta = PartnerModule.partner?.settings?.inboxes?.find(i => i.identifier === key);
      if (meta) {
        inboxWithMeta.push({
          ...meta,
          module: this._inboxes[key]
        });
      }
    }

    return inboxWithMeta;
  }

  @Mutation
  private _mutateSetInboxes(inboxes: { [key: string]: PartnerMessageStore }) {
    this._inboxes = inboxes;
  }

  @Action
  private async initializeModule(params: { inbox: PartnerMessageStore; inboxIdentifier: string }) {
    params.inbox.setHiddenFilters([
      new PageFilterElement({
        key: "to",
        operation: PaginationFilterOperationEnum.EQUAL,
        value: params.inboxIdentifier
      })
    ]);

    await params.inbox.fetchFirstPage({ partnerId: PartnerModule.partner?.id });
  }

  /**
   * Initializes a local submodule for each inbox
   */
  @Action
  async reset() {
    this.context.commit("_mutateLoading", true);
    const inboxes: { [key: string]: PartnerMessageStore } = {};

    const getModuleToRegister = (inboxName: string) => {
      class PartnerMessageStore extends PaginatedBaseStore<
        IPartnerMessage,
        MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen
      > {
        _data = PartnerMessageDataAccessLayer;
        _pageProvider = PartnerMessagePageDataProvider;
        _pager = new PageDataHandler(this._data, this._pageProvider);

        protected _isLoadAll = true;
        filterOptions: PaginationFilterListElement[] = PartnerMessage.filterables.map(
          f => new PaginationFilterListElement(f)
        );
      }

      const x = Module({ namespaced: true, name: "inboxes/" + inboxName, store })(PartnerMessageStore);

      return x;
    };

    Vue.$log.debug("reset @ InboxStore. Resetting Inbox Modules");
    for (const inbox of PartnerModule.partner?.settings?.inboxes ?? []) {
      Vue.$log.debug(`Registering Inbox ${inbox.identifier}`);

      store.registerModule(["inboxes", inbox.identifier], getModuleToRegister(inbox.identifier) as any);
    }

    Vue.$log.debug("Making Inbox accessible in inbox store");
    const initializingPromises = [];
    for (const inbox of PartnerModule.partner?.settings?.inboxes ?? []) {
      const identifier = inbox.identifier;
      const inboxModule = (store as any)._modules?.root?._children?.["inboxes"];
      const children = inboxModule?._children;
      const inboxMessageStore = children?.[identifier];
      const inboxMessageStoreRawModule = inboxMessageStore?._rawModule;
      if (inboxMessageStoreRawModule) {
        const inboxMessageModule = getModule(inboxMessageStoreRawModule) as PartnerMessageStore;
        initializingPromises.push(
          this.initializeModule({
            inbox: inboxMessageModule,
            inboxIdentifier: identifier
          })
        );

        inboxes[identifier] = inboxMessageModule;
        this.context.commit("_mutateSetInboxes", inboxes);
      }

      Vue.$log.debug(`Inbox ${identifier}: ${inboxes[identifier] ? "check" : "not found"}`);
    }

    await Promise.all(initializingPromises);
    this.context.commit("_mutateLoading", false);
    Vue.$log.debug("Done resetting Inbox Modules");
  }
}

export const InboxModule = getModule(InboxStore);
