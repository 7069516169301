/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AinonymizerAnonymizationControllerGetParamsGen,
  AinonymizerAnonymizationViewModelGen,
  AinonymizerCreateAnonymizationConfigDtoGen,
  AinonymizerExceptionViewmodelGen,
  AinonymizerPageViewModelGen,
  AinonymizerUpdateAnonymizationDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Anonymization<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags anonymization
   * @name AnonymizationControllerCreate
   * @summary Schedules an anonymization taks
   * @request POST:/partner/{partnerId}/anonymization
   * @secure
   * @response `201` `AinonymizerAnonymizationViewModelGen` Anonymization scheduled
   * @response `400` `AinonymizerExceptionViewmodelGen` Bad Request
   * @response `401` `AinonymizerExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `AinonymizerExceptionViewmodelGen` Forbidden.
   * @response `404` `AinonymizerExceptionViewmodelGen` Not Found
   * @response `500` `AinonymizerExceptionViewmodelGen` Internal Server Error
   */
  anonymizationControllerCreate = (
    partnerId: string,
    data: AinonymizerCreateAnonymizationConfigDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<AinonymizerAnonymizationViewModelGen, AinonymizerExceptionViewmodelGen>({
      path: `/partner/${partnerId}/anonymization`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags anonymization
   * @name AnonymizationControllerGet
   * @summary Gets all anonymization tasks from a given partner
   * @request GET:/partner/{partnerId}/anonymization
   * @secure
   * @response `200` `(AinonymizerPageViewModelGen & { data?: (AinonymizerAnonymizationViewModelGen)[] })`
   * @response `400` `AinonymizerExceptionViewmodelGen` Bad Request
   * @response `401` `AinonymizerExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `AinonymizerExceptionViewmodelGen` Forbidden.
   * @response `404` `AinonymizerExceptionViewmodelGen` Not Found
   * @response `500` `AinonymizerExceptionViewmodelGen` Internal Server Error
   */
  anonymizationControllerGet = (
    { partnerId, ...query }: AinonymizerAnonymizationControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      AinonymizerPageViewModelGen & { data?: AinonymizerAnonymizationViewModelGen[] },
      AinonymizerExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/anonymization`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags anonymization
   * @name AnonymizationControllerGetOne
   * @summary Gets a particular anonymization task from a given partner
   * @request GET:/partner/{partnerId}/anonymization/{id}
   * @secure
   * @response `200` `AinonymizerAnonymizationViewModelGen` anonymization task returned
   * @response `400` `AinonymizerExceptionViewmodelGen` Bad Request
   * @response `401` `AinonymizerExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `AinonymizerExceptionViewmodelGen` Forbidden.
   * @response `404` `AinonymizerExceptionViewmodelGen` Not Found
   * @response `500` `AinonymizerExceptionViewmodelGen` Internal Server Error
   */
  anonymizationControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<AinonymizerAnonymizationViewModelGen, AinonymizerExceptionViewmodelGen>({
      path: `/partner/${partnerId}/anonymization/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags anonymization
   * @name AnonymizationControllerUpdateOne
   * @summary Updates anonymization
   * @request PUT:/partner/{partnerId}/anonymization/{id}
   * @secure
   * @response `200` `AinonymizerAnonymizationViewModelGen` anonymization task returned
   * @response `400` `AinonymizerExceptionViewmodelGen` Bad Request
   * @response `401` `AinonymizerExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `AinonymizerExceptionViewmodelGen` Forbidden.
   * @response `404` `AinonymizerExceptionViewmodelGen` Not Found
   * @response `500` `AinonymizerExceptionViewmodelGen` Internal Server Error
   */
  anonymizationControllerUpdateOne = (
    partnerId: string,
    id: string,
    data: AinonymizerUpdateAnonymizationDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<AinonymizerAnonymizationViewModelGen, AinonymizerExceptionViewmodelGen>({
      path: `/partner/${partnerId}/anonymization/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
