var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: "trash ".concat(_vm.disabled ? 'disabled' : '', " mb-n1")
  }, [_c('span'), _c('i')]);
}
var staticRenderFns = []

export { render, staticRenderFns }