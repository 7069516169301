import { permissionGuard } from "@/auth/permissionAuthGuard";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import FleetContractDetailView from "@/views/fleet/FleetContractDetailView.vue";
import FleetContractFormView from "@/views/fleet/FleetContractFormView.vue";
import FleetDriverDetailView from "@/views/fleet/FleetDriverDetailView.vue";
import FleetDriverListView from "@/views/fleet/FleetDriverListView.vue";
import FleetHomeView from "@/views/fleet/FleetHomeView.vue";
import FleetImportView from "@/views/fleet/FleetImportView.vue";
import FleetPoolDetailView from "@/views/fleet/FleetPoolDetailView.vue";
import FleetPoolListView from "@/views/fleet/FleetPoolListView.vue";
import FleetVehicleDetailView from "@/views/fleet/FleetVehicleDetailView.vue";
import FleetVehicleListView from "@/views/fleet/FleetVehicleListView.vue";
import VehicleDetailView from "@/views/fleet/VehicleDetailView.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/fleet",
    name: "FleetHome",
    component: FleetHomeView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-garage-open-variant",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  },
  {
    path: "/partner/:partnerId/fleet/:tab",
    name: "FleetHomeTab",
    component: FleetHomeView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/fleet/import",
    name: "FleetImportView",
    component: FleetImportView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.CREATE,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-database-import-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partner/:partnerId/ticket",
    name: "FleetHomeTicket",
    component: FleetHomeView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/vehicle",
    name: "VehicleList",
    component: FleetVehicleListView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-car-multiple",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partner/:partnerId/vehicle/:vehicleId",
    name: "VehicleDetailView",
    component: VehicleDetailView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/vehicle/:vehicleId/:tab",
    name: "VehicleDetailTab",
    component: FleetVehicleDetailView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/fleet/vehicle/:vehicleId/",
    name: "FleetVehicleDetail",
    component: FleetVehicleDetailView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/fleet/vehicle/:vehicleId/:tab",
    name: "FleetVehicleDetailTab",
    component: FleetVehicleDetailView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/vehicle/:vehicleId/contract/:contractId",
    name: "FleetContractDetailView",
    component: FleetContractDetailView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/vehicle/:vehicleId/contract/:contractId/detail",
    name: "FleetContractFormView",
    component: FleetContractFormView,
    permission: {
      resource: ResourceEnum.VEHICLE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/driver",
    name: "FleetDriverListView",
    component: FleetDriverListView,
    permission: {
      resource: ResourceEnum.DRIVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-card-account-details",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partner/:partnerId/driver/:driverId",
    name: "DriverDetail",
    component: FleetDriverDetailView,
    permission: {
      resource: ResourceEnum.DRIVER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/pool",
    name: "FleetPoolListView",
    component: FleetPoolListView,
    permission: {
      resource: ResourceEnum.POOL,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.FLEET,
      icon: "mdi-car-3-plus",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partner/:partnerId/pool/:poolId",
    name: "FleetPoolDetailView",
    component: FleetPoolDetailView,
    permission: {
      resource: ResourceEnum.POOL,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];

const router = new VueRouter({
  routes,
  base: ""
});

router.beforeEach(permissionGuard);

export default router;

export const FleetRoutes = routes;
