















































import PaginatedTable from "@/components/utility/PaginatedTable.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { ITicket } from "@/models/ticket.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TicketCreateDialog from "./TicketCreateDialog.vue";
import { VChip } from "vuetify/lib/components";

@Component({
  components: {
    PaginatedTable,
    Tooltip,
    ConfirmActionDialog,
    TicketCreateDialog,
    VChip
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class DueDateChip extends Vue {
  /**
   * filter tickets for a match with any of the given references
   */
  @Prop()
  ticket!: ITicket;

  /**
   * filter tickets for a match with any of the given references
   */
  @Prop({ default: true })
  chip!: boolean;

  due(due: string) {
    if (!due) {
      return "";
    }

    return new Date(due);
  }

  get isChip() {
    if (this.chip) {
      return "v-chip";
    }
    return "span";
  }
  /**
   * end of today
   */
  get eod() {
    const date = new Date();
    date.setUTCHours(23, 59, 59, 59);

    return date;
  }

  /**
   * beginning of today
   */
  get bod() {
    const date = new Date();
    date.setUTCHours(0, 0, 0, 0);

    return date;
  }

  /**
   * beginning of yesterday
   */
  get yesterday() {
    const date = new Date();
    date.setUTCHours(0, 0, 0, 0);
    return date.setDate(date.getDate() - 1);
  }

  /**
   * end of tomorrow
   */
  get totomorrow() {
    const date = new Date();
    date.setUTCHours(23, 59, 59, 59);
    return date.setDate(date.getDate() + 1);
  }

  isOverdue(ticket: ITicket) {
    if (ticket.due && ticket.state === "open") {
      return new Date(ticket.due) < new Date();
    }

    return false;
  }
}
