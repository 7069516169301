var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attr = _ref.attr;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "data-test-create-setting": ""
          },
          on: {
            "click": _vm.open
          }
        }, 'v-btn', attr, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManagerList.createNewSetting")))])]), _c('v-dialog', {
    attrs: {
      "max-width": "1000px"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManageInitializeDialog.title")))])]), _c('v-divider'), _c('v-card-actions', {
    staticClass: "pl-8 pr-8"
  }, [_c('v-form', {
    staticStyle: {
      "width": "100%"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.createSetting.apply(null, arguments);
      }
    },
    model: {
      value: _vm.formValid,
      callback: function callback($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 pb-2"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageInitializeDialog.setKey")) + " ")])]), _c('v-row', [_c('v-combobox', {
    attrs: {
      "rules": _vm.keyRules,
      "items": _vm.items,
      "append-icon": _vm.items.length === 0 ? '' : 'mdi-menu-down',
      "type": "text",
      "label": _vm.$t('components.partner.PartnerManageInitializeDialog.key'),
      "outlined": "",
      "clearable": "",
      "data-test-key-input": ""
    },
    model: {
      value: _vm.key,
      callback: function callback($$v) {
        _vm.key = $$v;
      },
      expression: "key"
    }
  })], 1), _c('v-row', {
    staticClass: "pb-2"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageInitializeDialog.setValue")) + " ")])]), _c('v-row', [_c('v-text-field', {
    attrs: {
      "rules": _vm.valueRules,
      "type": "text",
      "label": _vm.$t('components.partner.PartnerManageInitializeDialog.value'),
      "outlined": "",
      "data-test-value-input": ""
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('v-checkbox', {
    staticClass: "mt-n3",
    attrs: {
      "label": _vm.$t('components.partner.PartnerManageInitializeDialog.setEncryption'),
      "data-test-encryption-option": ""
    },
    model: {
      value: _vm.isEncrypted,
      callback: function callback($$v) {
        _vm.isEncrypted = $$v;
      },
      expression: "isEncrypted"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-n3",
    attrs: {
      "label": _vm.$t('components.partner.PartnerManageInitializeDialog.isPublic'),
      "data-test-public-option": ""
    },
    model: {
      value: _vm.isPublic,
      callback: function callback($$v) {
        _vm.isPublic = $$v;
      },
      expression: "isPublic"
    }
  }), _c('v-flex', {
    staticClass: "d-flex "
  }, [_c('v-btn', {
    staticClass: "mb-4 pa-2",
    attrs: {
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageInitializeDialog.return")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "mb-4 pa-2",
    attrs: {
      "outlined": "",
      "text": "",
      "type": "submit",
      "disabled": !_vm.formValid || !_vm.key || !_vm.value,
      "data-test-submit-button": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageInitializeDialog.submit")) + " ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }