/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { BookingClientProvider } from "../booking-http-client.provider";
import { Booking } from "../v1/Booking";
import {
  BookingBookingControllerFindAllByPartnerIdParamsGen,
  BookingBookingViewModelGen,
  BookingCreateBookingDtoGen,
  BookingPageViewModelGen,
  BookingUpdateBookingDtoGen
} from "../v1/data-contracts";
import {
  BookingBookingControllerDeleteOneParamsGen,
  BookingBookingControllerUpdateParamsGen,
  BookingBookingDetailViewModelGen
} from "./../v1/data-contracts";

/**
 * Service to talk to the Booking endpoint.
 */
export class BookingService {
  private readonly booking: Booking;

  constructor(clientProvider: BookingClientProvider) {
    this.booking = new Booking(clientProvider.client());
  }

  /**
   * Gets available booking slots for a service for a partner
   *
   * @param query
   * @returns
   */
  async findAllByPartner(
    query: BookingBookingControllerFindAllByPartnerIdParamsGen
  ): Promise<BookingPageViewModelGen & { data?: BookingBookingControllerFindAllByPartnerIdParamsGen[] }> {
    return (await this.booking.bookingControllerFindAllByPartnerId(query)).data;
  }

  /**
   *  Gets a particular booking for a given partner
   *
   * @param partnerId
   * @param id
   * @returns
   */
  async findOneByPartner(partnerId: string, id: string): Promise<BookingBookingDetailViewModelGen> {
    return (await this.booking.bookingControllerFindOneByPartnerId(partnerId, id)).data;
  }

  /**
   *  Replaces a particular booking for a given partner
   *
   * @param partnerId
   * @param resourceId
   * @param data
   * @returns
   */
  async createOneByPartner(
    partnerId: string,
    resourceId: string,
    data: BookingCreateBookingDtoGen
  ): Promise<BookingBookingViewModelGen> {
    return (await this.booking.resourceBookingControllerCreate(partnerId, resourceId, data)).data;
  }

  /**
   *  Replaces a particular booking for a given partner
   *
   * @param query
   * @param data
   * @returns
   */
  async updateOneByPartner(
    query: BookingBookingControllerUpdateParamsGen,
    data: BookingUpdateBookingDtoGen
  ): Promise<BookingBookingViewModelGen> {
    return (await this.booking.bookingControllerUpdate({ ...query }, data)).data;
  }

  /**
   *  Remove a booking for a given partner
   *
   * @param query
   * @returns
   */
  async removeOneByPartner(query: BookingBookingControllerDeleteOneParamsGen): Promise<BookingBookingViewModelGen> {
    return (await this.booking.bookingControllerDeleteOne({ ...query })).data;
  }
}

export default new BookingService(new BookingClientProvider());
