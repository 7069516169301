













































import { SettingModule } from "@/store/modules/setting.store";
import { Component, Watch, Prop } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";
import { valueRule } from "@/lib/rules/valueRule";
import PartnerManageDeleteSetting from "@/components/partner/PartnerManageDeleteSetting.vue";
import PartnerManageEditSetting from "@/components/partner/PartnerManageEditSetting.vue";
import PartnerManageStepperMixin from "@/mixins/PartnerManageStepperMixin.vue";
import PartnerManageStepperStepLayout from "@/components/partner/PartnerManageStepperStepLayout.vue";

@Component({
  components: { PartnerManageEditSetting, PartnerManageDeleteSetting, PartnerManageStepperStepLayout }
})
export default class PartnerManageStepperPredefinedSettingStep extends PartnerManageStepperMixin {
  @Prop()
  settingKey!: string;

  @Prop({ default: false })
  forceEncrypt?: boolean;

  /**
   * The rules for value input field
   */
  @Prop({ default: [requiredRule(), valueRule()] })
  rules?: ((value: any) => string | boolean)[];

  /**
   * To make input a dropdown
   */
  @Prop()
  items?: any[];

  /**
   * is entered setting value valid
   */
  valid = false;

  /**
   * is encryption activated
   */
  editIsEncrypted = this.forceEncrypt;

  /**
   * value in input field
   */
  editValue = "";

  /**
   * is edit active
   */
  editActive = false;

  /**
   * a setting with the same key already exists
   */
  isPreloaded = false;

  /**
   * a setting was deleted
   */
  isDeleted = false;

  /**
   * check if user can use input value field
   */
  get isInputDisabled() {
    if (this.isDeleted) {
      return false;
    }
    if (this.partnerSetting?.isEncrypted) {
      return true;
    }
    if (this.isPreloaded && this.editActive) {
      return false;
    }
    if (this.isPreloaded) {
      return true;
    }
    return false;
  }

  /**
   * check if user can access edit mode
   * basically thats the case when the setting is preloaded and not encrypted
   */
  get isEditOptionAvailable() {
    if (this.isDeleted) {
      return false;
    }
    if (this.partnerSetting?.isEncrypted) {
      return false;
    }
    if (this.isPreloaded) {
      return true;
    }
    return false;
  }

  /**
   * check if user can delete setting
   */

  get isDeleteOptionAvailable() {
    if (this.isDeleted) {
      return false;
    }
    if (this.partnerSetting?.isEncrypted) {
      return true;
    }
    return false;
  }

  /**
   * get current setting
   */
  get partnerSetting() {
    return SettingModule.partnerSetting;
  }

  /**
   * check if prefilled setting is encrypted
   */
  get isEncrypted() {
    return this.partnerSetting?.isEncrypted;
  }

  /**
   * reset form when edit is aborted
   */
  @Watch("editActive")
  resetForm() {
    this.editIsEncrypted = this.partnerSetting?.isEncrypted || this.forceEncrypt;
    this.editValue = this.partnerSetting?.value || "";
  }

  onDelete() {
    this.prefill();
    this.isPreloaded = false;
    this.isDeleted = true;
  }

  /**
   * handles form input
   * creates new setting if no setting was loaded before
   * updates existing setting otherwise
   */
  async endStep() {
    this.setLoading(true);
    const settingDto = {
      key: this.settingKey,
      value: this.editValue,
      isEncrypted: this.forceEncrypt || this.editIsEncrypted
    };
    if (!this.isPreloaded) {
      // create new
      await SettingModule.handleCreateSetting(settingDto);
      if (SettingModule.duplicateSettingKey) {
        //this should not happen thooo
        //but if it does and for some reason the setting was not loaded even tho it is in the database show error and give user another chance to update it
        this.$toast.error(this.$t("components.partner.PartnerManageDetailCard.duplicateSettingKey"));
        await this.loadSetting();
        this.setLoading(false);
        return;
      }
    }
    if (
      (this.editValue !== this.partnerSetting?.value || this.editIsEncrypted !== this.partnerSetting?.isEncrypted) &&
      !this.partnerSetting?.isEncrypted
    ) {
      // update existing
      await SettingModule.updateSetting(settingDto);
    }
    this.editActive = false;
    this.isDeleted = false;
    this.goToNext();
  }

  /**
   * load and prefill the current setting of the step
   */
  async mounted() {
    await this.loadSetting();
  }

  /**
   * loads and fills the setting required in current step
   */
  async loadSetting() {
    SettingModule.clearPartnerSetting();
    await SettingModule.getSettingByKey({ key: this.settingKey });
    this.setLoading(false);
    this.prefill();
  }

  /**
   * prefills form with active setting if available
   * activates edit mode if setting is unavailable
   */
  prefill() {
    if (this.partnerSetting && this.partnerSetting.key) {
      this.isPreloaded = true;
      if (this.partnerSetting.isEncrypted) {
        this.editValue = "************";
      } else {
        this.editValue = this.partnerSetting?.value || "";
      }
      this.editIsEncrypted = false || this.forceEncrypt;
    } else {
      this.isPreloaded = false;
      this.editActive = true;
    }
  }
}
