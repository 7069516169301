






































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CustomFieldDetailCard from "@/components/report/CustomFieldDetailCard.vue";
import CustomFieldMixin from "@/components/report/CustomFieldMixin.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    CustomFieldDetailCard,
    LatestEntriesCardEmpty
  }
})
export default class CustomFieldDetail extends mixins(PartnerFallbackMixin, CustomFieldMixin) {
  get customFieldId() {
    return this.$route.params.customFieldId;
  }

  async mounted() {
    await Promise.all([this.trySetByRouteOrDefault(), this.loadCustomField()]);
  }

  onBack() {
    new GoToHelper(this.$router).goToCustomFieldList(this.partnerId);
  }

  async loadCustomField() {
    try {
      this.isLoading = true;
      this.customField = await CustomFieldModule.getOne({
        partnerId: this.partnerId,
        customFieldId: this.customFieldId
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
