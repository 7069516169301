















import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import ContextDropDownMenu, { IDropdownMenuActionGroup } from "@/components/utility/ContextDropDownMenu.vue";
import { MrfiktivProjectCustomViewViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { ContextDropDownMenu, ConfirmActionDialog }
})
export default class ProjectCustomViewContextMenu extends Vue {
  @Prop()
  customView!: MrfiktivProjectCustomViewViewmodelGen;

  customViewFormDialog = false;

  isDeleteDialogActive = false;

  actions: IDropdownMenuActionGroup[] = [
    {
      group: [
        {
          text: "Reihenfolge ändern",
          icon: "mdi-order-numeric-descending",
          disabled: false,
          action: this.openOrderDialog
        },
        {
          text: "Bearbeiten",
          icon: "mdi-pencil",
          disabled: false,
          action: this.openUpdateDialog
        },
        {
          text: "Ansicht duplizieren",
          icon: "mdi-content-copy",
          disabled: false,
          action: this.duplicateCustomView
        }
      ],
      divider: true
    },
    {
      group: [
        {
          text: "Löschen",
          icon: "mdi-trash-can-outline",
          color: "red",
          action: this.openDeleteDialog
        }
      ]
    }
  ];

  openDeleteDialog() {
    this.isDeleteDialogActive = true;
  }

  openUpdateDialog() {
    this.$emit("update", true);
  }

  openOrderDialog() {
    this.$emit("reorder", true);
  }

  duplicateCustomView() {
    this.$emit("createDuplicate", this.customView);
  }

  updateView() {
    this.$toast.info("Emit update from custom view");
    this.$emit("update", this.customView);
  }

  async deleteCustomView() {
    this.$emit("delete", this.customView.id);
  }
}
