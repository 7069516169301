
































import AvailabilityForm from "@/components/booking/AvailabilityForm.vue";
import Debug from "@/components/utility/Debug.vue";
import { ResourceUIDto } from "@/lib/dto/resource-ui.dto";
import { UpdateAvailabilityDto } from "@/lib/dto/update-availability.dto";
import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { AvailabilityMapper } from "@/lib/utility/booking/availabilityMapper";
import { daysOfTheWeekMap, DaysOfTheWeekOrder } from "@/lib/utility/daysOfTheWeekHelper";
import { deepCopy } from "@/lib/utility/deep-copy";
import { BookingAvailabilityGen, BookingCreateResourceDtoGen } from "@/services/booking/v1/data-contracts";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IResource } from "@/models/resource.entity";

@Component({
  components: { AvailabilityForm, Debug }
})
export default class ResourceForm extends Vue {
  isValid = false;
  isDisabled = true;

  @Prop({ default: false })
  isCreate!: boolean;

  @Prop()
  value!: IResource | BookingCreateResourceDtoGen;

  @Prop({ default: false })
  isLoading!: boolean;

  resourceDto = new ResourceUIDto(this.value);
  // Availability as is originally created
  availability: BookingAvailabilityGen[] = [];
  // cast to any as it complains...
  weekDays: IterableIterator<WeekDaysEnum> = [] as any;

  // default start time and end time. TODO: Discuss if needed
  defaultStartTimeEndTime: UpdateAvailabilityDto = new UpdateAvailabilityDto();

  @Watch("resourceDto", { deep: true })
  emitUpdated() {
    this.$emit("input", this.resourceDto.toDto());
  }

  @Watch("isValid")
  emitIsValid() {
    this.$emit("valid-change", this.isValid);
  }

  mounted() {
    this.resourceDto = new ResourceUIDto(this.value);
    this.weekDays = daysOfTheWeekMap.keys();

    /**
     * Sort the availabilities array Monday to Sunday
     * It comes from server as Sunday to Saturday
     */
    const availabilitySortedMondaySunday = AvailabilityMapper.getAvailabilitySorted(
      this.resourceDto.availability,
      DaysOfTheWeekOrder.Javascript,
      DaysOfTheWeekOrder.Normal
    );

    this.availability = deepCopy(availabilitySortedMondaySunday);
  }

  /**
   * Save availability table.
   * Availability array contains days and times. Day is coded as the index of the array.
   * So, 0 will be Monday, 1 Tuesday,...6 Sunday.
   * @param availability
   */
  onAvailabilityChange(availability: BookingAvailabilityGen[]) {
    /**
     * Order the availability array as Sunday to Saturday.
     * After edit, the availability should always be Sunday to Saturday.
     */
    const availabilitySortedSundaySaturday = AvailabilityMapper.getAvailabilitySorted(
      availability,
      DaysOfTheWeekOrder.Normal,
      DaysOfTheWeekOrder.Javascript
    );

    this.resourceDto.availability = deepCopy(availabilitySortedSundaySaturday);
  }

  save(): ResourceUIDto {
    this.$emit("save", this.resourceDto);
    this.isDisabled = true;

    return this.resourceDto;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get disabled() {
    return this.isLoading;
  }
}
