















































import RulesMixin from "@/mixins/RulesMixin.vue";
import { ITicket } from "@/models/ticket.entity";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import ProjectGoToMixin, { ProjectGoToHelper, ProjectRouteNames } from "@/views/project/mixins/ProjectGoToMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import CostCreateDialog from "@/components/cost/CostCreateDialog.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    ConfirmActionDialog,
    CostCreateDialog
  },
  filters: {}
})
export default class TicketSideCardActions extends mixins(RulesMixin, ProjectGoToMixin, PermissionMixin) {
  @Prop()
  private value!: ITicket;

  @Prop()
  viewId!: string;

  @Prop()
  projectId!: string;

  isCostCreateDialogActive = false;

  isDeleteDialogActive = false;

  copied = false;

  get refs() {
    return [
      {
        refType: BackendResourceEnum.TICKET,
        refId: this.ticket.id
      },
      ...(this.ticket.refs ?? [])
    ];
  }

  get partnerId() {
    return this.value.partnerId;
  }

  get actions() {
    return [
      {
        icon: "mdi-open-in-new",
        text: this.$t("project.ticket.actions.openInNewTab"),
        color: "",
        action: () =>
          this.goTo.ticketDetail({
            number: this.ticket.number,
            partnerId: this.ticket.partnerId,
            newTab: true
          }),
        condition: !this.isCurrentPageTicketDetail && !this.isTicketFromProject
      },
      {
        icon: "mdi-open-in-new",
        text: this.$t("project.ticket.actions.viewInProject"),
        color: "",
        action: () =>
          this.goTo.ticketOrProjectTicketDetail({
            number: this.ticket.number,
            partnerId: this.ticket.partnerId,
            projectId: this.ticket.projectId ?? "",
            viewId: this.viewId
          }),
        condition:
          (this.isCurrentPageTicketDetail && this.ticket.projectId) ||
          (!this.isCurrentPageTicketDetail && this.isTicketFromProject)
      },
      {
        icon: "mdi-content-copy",
        text: this.$t("project.ticket.actions.copyLink"),
        color: "",
        action: this.copyLinkToClipboard,
        condition: true,
        success: this.copied
      },
      {
        icon: "mdi-file-edit-outline",
        text: this.$t("project.ticket.actions.markDraft"),
        color: "",
        action: () => this.updateTicketStatus(TicketStatusEnum.DRAFT),
        condition: this.ticket.state === TicketStatusEnum.OPEN
      },
      {
        icon: "mdi-file-edit-outline",
        text: this.$t("project.ticket.actions.openTask"),
        color: "",
        action: () => this.updateTicketStatus(TicketStatusEnum.OPEN),
        condition: this.ticket.state === TicketStatusEnum.DRAFT || this.ticket.state === TicketStatusEnum.CLOSED
      },
      {
        icon: "mdi-cash-multiple",
        text: this.$t("cost.createCost").toString(),
        color: "",
        action: () => (this.$refs.costCreateDialog as CostCreateDialog).show(),
        condition: this.canCreateCost
      },
      {
        icon: "mdi-trash-can-outline",
        text: this.$t("delete"),
        color: "red",
        action: this.startDelete,
        condition: true
      }
    ];
  }

  get ticket() {
    return this.value;
  }

  get TicketStatusEnum() {
    return TicketStatusEnum;
  }

  get isCurrentPageTicketDetail() {
    const routeName = this.$route.name;

    return (
      routeName &&
      [ProjectRouteNames.TICKET_DETAIL, ProjectRouteNames.PROJECT_TICKET_DETAIL].includes(
        routeName as ProjectRouteNames
      )
    );
  }

  get isCurrentPageProject() {
    return !!this.$route.params.projectId;
  }

  get isTicketFromProject() {
    return this.ticket.projectId && this.$route.params.projectId;
  }

  get showOpenInTicketDetail() {
    return !this.$route.params.ticketNumber && !this.projectId;
  }

  get isProjectTicketSideCard() {
    const routeName = this.$route.name;

    return routeName && this.$route.query.ticketNumber && this.$route.params.projectId;
  }

  copyLinkToClipboard() {
    try {
      const partnerId = this.ticket.partnerId;
      const projectId = this.projectId ?? "";
      const ticketNumber = this.ticket.number.toString();
      const viewId = this.viewId;

      const baseUrl = window.location.origin;

      let route = ProjectGoToHelper.locations.ticketDetail(partnerId, ticketNumber);
      if (projectId) {
        route = ProjectGoToHelper.locations.projectTicketDetail(partnerId, projectId, viewId, ticketNumber);
      }

      const url = this.$router.resolve(route).href;

      navigator.clipboard.writeText(`${baseUrl}/${url}`);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    } catch (error) {
      this.$toast.error(this.$t("components.partner.PartnerReportInitializeCard.error"));
    }
  }

  updateTicketStatus(status: TicketStatusEnum) {
    this.$emit("updateTicketStatus", status);
  }

  startDelete() {
    this.isDeleteDialogActive = true;
  }

  deleteTicket() {
    this.$emit("deleteTicket", this.ticket);
    this.isDeleteDialogActive = false;
    this.$emit("close");
  }
}
