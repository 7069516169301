var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.availability && _vm.availability.length > 0 ? _c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_vm._l(_vm.weekDays, function (weekDay, index) {
    return [_c('availability-form-field', {
      key: weekDay,
      attrs: {
        "availability": _vm.items[index],
        "weekDay": weekDay
      },
      on: {
        "change": _vm.changeAvailability
      }
    })];
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }