var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.vehicle && _vm.vehicle.id ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-subheader', {
    staticClass: "mt-4"
  }, [_vm._v(" Über dieses Fahrzeug "), _c('v-spacer')], 1), _c('v-simple-table', [_c('tbody', _vm._l(_vm.items, function (item, idx) {
    return _c('tr', {
      key: idx
    }, [_c('td', [_vm._v(_vm._s(_vm.$t(item.text)))]), _c('td', [_vm._v(_vm._s(item.value))])]);
  }), 0)]), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.DRIVER
    }
  }, [_c('v-divider', {
    staticClass: "mt-4"
  }), _c('fleet-vehicle-driver-detail-table', {
    attrs: {
      "vehicle": _vm.vehicle,
      "loading": _vm.loading
    },
    on: {
      "change": _vm.updateDrivers
    }
  })], 1), _c('v-divider', {
    staticClass: "mt-4"
  }), _c('label-component', {
    attrs: {
      "labels": _vm.tags,
      "loading": _vm.loading
    },
    on: {
      "update": _vm.updateLabels
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }