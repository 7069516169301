var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table-wrapper', {
    attrs: {
      "showSelect": true,
      "hideSearch": true,
      "selectedItems": _vm.selected,
      "allItems": _vm.items,
      "headers": _vm.headers,
      "itemKey": "id",
      "itemsPerPage": 5,
      "dense": _vm.dense,
      "loading": _vm.loading,
      "isClickableRow": true
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "click:row": _vm.clickRow
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v(" " + _vm._s(_vm.$t("components.fleet.FleetVehicleDetailHomeCard.overdueTickets")) + " ")])];
      },
      proxy: true
    }, {
      key: "selectActions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "ma-1",
          attrs: {
            "elevation": "0",
            "color": "primary"
          },
          on: {
            "click": _vm.selectedAction
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("acknowledge")) + " ")])];
      },
      proxy: true
    }, {
      key: "item.number",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "text-truncate"
        }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s("#".concat(item.number)) + " " + _vm._s(_vm.$t("project.ticket.ticketOpenedByAt", {
          date: _vm.simpleDoubleDigitDate(item.timestamp.created),
          user: _vm.userName(item.userId)
        })) + " ")])], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }