var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_c('a', {
    domProps: {
      "textContent": _vm._s("".concat(_vm.$t('components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.leasingDetails.title.leasing'), ": ").concat(_vm.item.number))
    },
    on: {
      "click": _vm.emitOpenDetail
    }
  })]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.item.startDate)) + " - " + _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.item.endDate)) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }