import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/VuexPlugin";
import { MrfiktivAnalyticsCountGen, MrfiktivBaseChartItemViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import analyticsService from "@/services/mrfiktiv/services/analyticsService";
import { ServiceEnum } from "@/lib/enum/service.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "admin-analytics",
  store
})
export class AdminCountAnalyticsStore extends VuexModule {
  private _dateRange: string[] = [
    new Date().toISOString(),
    new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()
  ];

  get dateRange(): string[] {
    return this._dateRange;
  }

  get from(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return tempo;
  }

  get to(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(20, 59, 59, 999)).toISOString();
    return tempo;
  }

  private _count: MrfiktivAnalyticsCountGen = {
    count: 0
  };

  private _countAndGroupPerPartner: MrfiktivBaseChartItemViewmodelGen | null = null;

  private _countAndGroupPerPartnerAndDateRange: MrfiktivBaseChartItemViewmodelGen | null = null;

  get count() {
    return this._count;
  }

  get countAndGroupByPartnerAndDate() {
    return this._countAndGroupPerPartnerAndDateRange;
  }

  get countAndGroupByPartner() {
    return this._countAndGroupPerPartner;
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  @Action
  async getCount(data: { selectedService: ServiceEnum }) {
    const res = await analyticsService.count(data.selectedService, this.from, this.to);

    this.context.commit("_mutateCount", res);
  }

  @Mutation
  _mutateCount(data: MrfiktivAnalyticsCountGen) {
    this._count = data;
  }

  @Action
  async getCountAndGroupByPartner(data: { selectedService: ServiceEnum }) {
    const res = await analyticsService.countAndGroupByPartner(data.selectedService);

    this.context.commit("_mutateCountAndGroupByPartner", res);
  }

  @Mutation
  _mutateCountAndGroupByPartner(data: MrfiktivBaseChartItemViewmodelGen) {
    this._countAndGroupPerPartner = data;
  }

  @Action
  async getCountAndGroupPerPartnerAndDateRange(data: { selectedService: ServiceEnum }) {
    const res = await analyticsService.countAndGroupByPartner(data.selectedService, this.from, this.to);

    this.context.commit("_mutateCountAndGroupPerPartnerAndDateRange", res);
  }

  @Mutation
  _mutateCountAndGroupPerPartnerAndDateRange(data: MrfiktivBaseChartItemViewmodelGen) {
    this._countAndGroupPerPartnerAndDateRange = data;
  }
}

export const AdminCountAnalyticsModule = getModule(AdminCountAnalyticsStore);
