var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": "Fahrzeugschein"
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "justify": "center"
    }
  }, [_c('FahrzeugscheinImage')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('FahrzeugscheinDetail')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }