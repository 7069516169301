import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { ILocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.interface";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import eventService from "@/services/mrfiktiv/services/eventService";
import vehicleEventService from "@/services/mrfiktiv/services/vehicleEventService";
import { MrfiktivEventControllerListParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { BaseStore } from "../base.store";
import { EventDataAccessLayer } from "./access-layers/event.access-layer";
import { FleetAggregationModule } from "./fleet-aggregation.store";

/**
 * Store to handle all events in a time frame. @see RecurringEventStore
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "all-event-list",
  store
})
export class AllListEventStore extends BaseStore<IEventUIDto> {
  protected _data: ILocalDataAccessLayer<IEventUIDto, string> = EventDataAccessLayer;

  protected _dateRange: string[] = [
    new Date().toISOString(),
    new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString()
  ];

  vehicleId = "";

  filterOptions = EventUIDto.filterables;

  get dateRange(): string[] {
    return this._dateRange;
  }

  /**
   * makes sure that date range is always included in the filters and sets the date range
   */
  @Mutation
  mutateExtendedFilters(filters: IPageFilterElement[]) {
    const vehicleFilter = filters.find(f => f.key === "vehicleId" && f.operation === PageFilterOperationEnum.EQUAL);
    if (vehicleFilter) {
      this.vehicleId = vehicleFilter.value as string;
    } else {
      this.vehicleId = "";
    }

    const lowerBoundDate = filters.find(
      f =>
        f.key === "startDate" &&
        [
          PageFilterOperationEnum.GREATER,
          PageFilterOperationEnum.GREATER_EQUAL,
          PageFilterOperationEnum.EQUAL
        ].includes(f.operation as PageFilterOperationEnum)
    );
    if (lowerBoundDate) {
      const date = new Date(lowerBoundDate.value as string).toISOString();
      this._dateRange.splice(0, 1, date);
    } else {
      filters.push(
        new PageFilterElement({
          key: "startDate",
          operation: PageFilterOperationEnum.GREATER_EQUAL,
          value: this._dateRange[0].slice(0, 10)
        })
      );
    }

    const upperBoundDate = filters.find(
      f =>
        f.key === "startDate" &&
        [PageFilterOperationEnum.LESS, PageFilterOperationEnum.LESS_EQUAL, PageFilterOperationEnum.EQUAL].includes(
          f.operation as PageFilterOperationEnum
        )
    );
    if (upperBoundDate) {
      const date = new Date(upperBoundDate.value as string).toISOString();
      this._dateRange.splice(1, 1, date);
    } else {
      filters.push(
        new PageFilterElement({
          key: "startDate",
          operation: PageFilterOperationEnum.LESS_EQUAL,
          value: this._dateRange[1].slice(0, 10)
        })
      );
    }

    if (upperBoundDate) {
      this._dateRange[1] = upperBoundDate.value as string;
    }

    this.filters.splice(0, this.filters.length, ...filters);
  }

  @Action
  setFilters(filters: IPageFilterElement[]) {
    this.context.commit("mutateExtendedFilters", filters);
    return this;
  }

  @Action
  setFilter(filters: IPageFilterElement[]) {
    this.context.commit("mutateExtendedFilters", filters);
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  @Action
  async fetchAll(data: MrfiktivEventControllerListParamsGen) {
    let res;

    if (!this.vehicleId) {
      res = await eventService.listAll({ partnerId: data.partnerId, from: data.from, to: data.to });
    } else {
      res = await vehicleEventService.listAll({
        partnerId: data.partnerId,
        from: data.from,
        to: data.to,
        vehicleId: this.vehicleId
      });
    }

    const events = res.map(e => new EventUIDto(e));

    for (const event of events) {
      this._data.set(event);
    }

    Vue.$log.debug("Paged events. Parsing for fleet ", events?.length);
    FleetAggregationModule.parseEvents(events ?? []);

    return res;
  }
}

export const AllEventListModule = getModule(AllListEventStore);
