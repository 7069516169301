






















































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { SettingModule } from "@/store/modules/setting.store";
import { Component } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";
import { valueRule } from "@/lib/rules/valueRule";
import { keyRule } from "@/lib/rules/keyRule";

@Component({})
export default class PartnerManageInitializeDialog extends DarkModeHighlightMixin {
  dialog = false;
  key = "";
  value = "";
  isEncrypted = false;
  isPublic = false;
  formValid = false;

  get items() {
    //TODO: Get list of well defined settings from Backend
    return [];
  }

  /**
   * Returns rules for value input field
   */
  get valueRules() {
    return [requiredRule(), valueRule()];
  }

  /**
   * Returns rules for value input field
   */
  get keyRules() {
    return [requiredRule(), keyRule()];
  }

  /**
   * Submit form event handler
   * creates a new setting
   */
  async createSetting() {
    this.dialog = false;

    const createPartnerSettingDto = {
      key: this.key,
      value: this.value,
      isEncrypted: this.isEncrypted,
      isActive: false,
      isPublic: this.isPublic
    };

    await SettingModule.handleCreateSetting(createPartnerSettingDto);
    if (SettingModule.duplicateSettingKey) {
      this.$toast.error(this.$t("components.partner.PartnerManageInitializeDialog.duplicateKey"));
      const duplicate = SettingModule.allPartnerSettings.find((setting: any) => setting.key === String(this.key));
      if (duplicate) {
        SettingModule.setPartnerSetting(duplicate);
      }

      return;
    }
    await SettingModule.getSettings();
  }

  /**
   * open the dialog
   */
  open() {
    this.dialog = true;
  }

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
  }
}
