import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { Anonymization, IAnonymization } from "@/models/anonymization.entity";
import anonymizationService from "@/services/ainonymizer/services/anonymizationService";
import { AinonymizerAnonymizationControllerGetParamsGen } from "@/services/ainonymizer/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { AnonymizationDataAccessLayer } from "./access-layers/anonymization.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

const AnonymizationPageDataProvider = new (class extends AbstractPageDataProvider<
  IAnonymization,
  AinonymizerAnonymizationControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: AinonymizerAnonymizationControllerGetParamsGen): Promise<IPageViewModel<IAnonymization>> {
    const res = await anonymizationService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IAnonymization[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "anonymization",
  store
})
export class AnonymizationPaginationStore extends PaginatedBaseStore<
  IAnonymization,
  AinonymizerAnonymizationControllerGetParamsGen
> {
  protected _data = AnonymizationDataAccessLayer;
  protected _pageProvider = AnonymizationPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Anonymization.filterables;
}

export const AnonymizationModule = getModule(AnonymizationPaginationStore);
