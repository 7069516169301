var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.newItems.length ? _c('v-menu', {
    attrs: {
      "open-on-hover": "",
      "bottom": "",
      "right": "",
      "disabled": !_vm.newItems.length
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('tr', _vm._g(_vm._b({}, 'tr', attrs, false), on), [_c('td', {
          staticStyle: {
            "text-align": "right"
          }
        }, [_c('b', [_vm._v(" " + _vm._s(_vm.newItems.length) + " ")])]), _c('td', {
          staticClass: "pl-1"
        }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTable.newDamages")) + " ")])])])];
      }
    }], null, false, 4098099386),
    model: {
      value: _vm.isNewMenu,
      callback: function callback($$v) {
        _vm.isNewMenu = $$v;
      },
      expression: "isNewMenu"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTable.newDamage")) + " ")]), _c('div', {
    staticStyle: {
      "max-height": "400px",
      "max-width": "300px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.newItems, function (item, index) {
    return _c('div', {
      key: 'FleetHomeVehicleTableDamageListNEW' + item._id
    }, [_c('v-list-item', {
      attrs: {
        "disabled": _vm.loading
      },
      on: {
        "click": function click($event) {
          return _vm.setReport(item);
        }
      }
    }, [_c('refs-report', {
      attrs: {
        "item": item
      },
      on: {
        "openDetail": function openDetail($event) {
          return _vm.setReport(item);
        }
      }
    })], 1), index !== _vm.newItems.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 0)], 1)], 1) : _vm._e(), _vm.inProgressItems.length ? _c('v-menu', {
    attrs: {
      "open-on-hover": "",
      "bottom": "",
      "right": "",
      "disabled": !_vm.inProgressItems.length
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('tr', _vm._g(_vm._b({}, 'tr', attrs, false), on), [_c('td', {
          staticStyle: {
            "text-align": "right"
          }
        }, [_c('b', [_vm._v(" " + _vm._s(_vm.inProgressItems.length) + " ")])]), _c('td', {
          staticClass: "pl-1"
        }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTable.inProgressDamages")) + " ")])])])];
      }
    }], null, false, 2569073242),
    model: {
      value: _vm.isInProgressMenu,
      callback: function callback($$v) {
        _vm.isInProgressMenu = $$v;
      },
      expression: "isInProgressMenu"
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-height": "400px",
      "max-width": "300px",
      "overflow-y": "auto"
    },
    attrs: {
      "outlined": "",
      "loading": _vm.loading
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTable.inProgressDamages")) + " ")]), _c('div', {
    staticStyle: {
      "max-height": "400px",
      "max-width": "300px",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.inProgressItems, function (item, index) {
    return _c('div', {
      key: 'FleetHomeVehicleTableDamageListPROGRESS' + item._id
    }, [_c('v-list-item', {
      attrs: {
        "disabled": _vm.loading
      },
      on: {
        "click": function click($event) {
          return _vm.setReport(item);
        }
      }
    }, [_c('refs-report', {
      attrs: {
        "item": item
      },
      on: {
        "openDetail": function openDetail($event) {
          return _vm.setReport(item);
        }
      }
    })], 1), index !== _vm.inProgressItems.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 0)], 1)], 1) : _vm._e(), _vm.isSideCard ? _c('partner-report-detail-side-card', {
    key: 'partner-report-detail-side-card-damage-list' + (_vm.report ? _vm.report.id : 'null'),
    attrs: {
      "loading": _vm.loading,
      "report": _vm.report
    },
    on: {
      "close": function close($event) {
        _vm.isSideCard = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }