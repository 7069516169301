














import ExcelImport from "@/components/utility/ExcelImport.vue";
import TableWrapper from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { excelDateToJSDate, numberValidation } from "@/lib/excelImportTransformation/transformers";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import { IExcelImportConfig } from "@/lib/interfaces/excel-import.interface";

@Component({
  components: {
    TheLayoutPortal,
    ExcelImport,
    TableWrapper
  }
})
export default class ExcelImportPlayground extends PartnerFallbackMixin {
  foundPartner = false;
  loading = false;

  importedItems = [];
  headers = [];

  progress = 0;

  importData(data: any) {
    this.loading = true;
    this.importedItems = data.data;
    this.headers = data.headers;
    this.progress = 100;
    this.loading = false;
  }

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  config: { [key: string]: IExcelImportConfig } = {
    chargingStationId: {
      label: "chargingStationIdLabel",
      hint: "Sollte eine MongoDB id sein",
      required: true,
      import: true,
      originColumnNameInExcelFile: "",
      transform: value => value.toString()
    },
    chargingStationName: {
      label: "chargingStationNameLabel",
      hint: "Muss als Datum in der Exceltabelle sein",
      required: true,
      import: true,
      originColumnNameInExcelFile: "",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    geo: {
      label: "Geokoordinaten",
      hint: "Muss als Zahl",
      required: false,
      import: true,
      originColumnNameInExcelFile: "",
      transform: value => numberValidation(value)
    }
  };

  get partner() {
    return PartnerModule.partner;
  }

  async mounted() {
    this.loading = true;
    try {
      this.foundPartner = await this.trySetByRouteOrDefault();
      await PartnerModule.getAllReportsForPartner();
      await PartnerModule.getAllFormsForPartner();
    } catch {
      this.$toast.error("Ooops... Fehler beim Laden der Anwendung!");
    } finally {
      this.loading = false;
    }
  }
}
