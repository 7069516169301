import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateWebhookConfigDtoGen,
  MrfiktivUpdateWebhookConfigDtoGen,
  MrfiktivWebhookConfigControllerGetParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { WebhookConfig } from "@/services/mrfiktiv/v1/WebhookConfig";

/**
 * Communicate with webhook config API
 */
class WebhookConfigService {
  mrfiktivProxy: WebhookConfig;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new WebhookConfig(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an webhookConfig
   */
  async create(partnerId: string, data: MrfiktivCreateWebhookConfigDtoGen) {
    const webhookConfig = (await this.mrfiktivProxy.webhookConfigControllerCreate(partnerId, data)).data;

    return webhookConfig;
  }

  /**
   * Get all webhookConfig
   */
  async get(data: MrfiktivWebhookConfigControllerGetParamsGen) {
    const webhookConfigs = (await this.mrfiktivProxy.webhookConfigControllerGet(data)).data;

    return webhookConfigs;
  }

  /**
   * Get a cuwebhookConfigstom
   */
  async getOne(partnerId: string, webhookConfigId: string) {
    const webhookConfig = (await this.mrfiktivProxy.webhookConfigControllerGetOne(partnerId, webhookConfigId)).data;

    return webhookConfig;
  }

  /**
   * Update a webhookConfig
   */
  async update(partnerId: string, webhookConfigId: string, data: MrfiktivUpdateWebhookConfigDtoGen) {
    const webhookConfig = (await this.mrfiktivProxy.webhookConfigControllerUpdate(partnerId, webhookConfigId, data))
      .data;

    return webhookConfig;
  }

  /**
   * Delete a webhookConfig
   */
  async delete(partnerId: string, webhookConfigId: string) {
    const webhookConfig = (await this.mrfiktivProxy.webhookConfigControllerRemove(partnerId, webhookConfigId)).data;

    return webhookConfig;
  }
}

/**
 * Communicate with webhookConfig API
 */
export default new WebhookConfigService(new MrfiktivHttpClientProvider());
