





















































import DropAreaMulti from "@/components/files/DropAreaMulti.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Anonymization, IAnonymization } from "@/models/anonymization.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MHeader from "../utility/mmmint/MHeader.vue";

@Component({
  components: {
    MHeader,
    ConfirmActionDialog,
    DropAreaMulti
  }
})
export default class CreateAnonymizationDialog extends mixins(PartnerFallbackMixin) {
  @Prop({ default: false })
  isDialogActive!: boolean;

  loading = false;
  loadingProgress = 0;
  anonymization = new Anonymization({});
  anonymizations: IAnonymization[] = [];
  files: File[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get noImages() {
    if (!this.files.length) {
      return true;
    }
    return false;
  }

  createDto() {
    this.anonymizations = [];

    for (const file of this.files) {
      const createAnonymization = new Anonymization({ partnerId: this.partnerId, config: this.anonymization.config });
      createAnonymization.file = file;
      this.anonymizations.push(createAnonymization);
    }
  }

  async submitAnonymization() {
    this.loading = true;
    this.loadingProgress = 0;

    const increment = 100 / this.anonymizations.length;
    for (const anonymization of this.anonymizations) {
      try {
        await anonymization.create();
      } catch (error) {
        handleError(error);
      } finally {
        this.loadingProgress = this.loadingProgress + increment;
      }
    }
    this.anonymizations = [];
    this.files = [];
    this.loading = false;
    this.loadingProgress = 0;
    this.dialogModel = false;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }
}
