var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    directives: [{
      name: "resize",
      rawName: "v-resize",
      value: _vm.onResize,
      expression: "onResize"
    }],
    attrs: {
      "width": !_vm.fullscreenBreakpoint ? '1200px' : '100%',
      "loading": _vm.loading,
      "hideTitle": true,
      "hideButton": false,
      "fullscreen": _vm.fullscreenBreakpoint,
      "absolute": true
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "pa-1",
    style: "height: calc(".concat(_vm.height, "px - ").concat(_vm.fullscreenBreakpoint ? '4px' : '68px', "); overflow-y: auto; overflow-x:hidden")
  }, [_vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line,list-item-two-line ,list-item-two-line"
    }
  }) : _vm.ticket ? _c('ticket-detail-card', {
    attrs: {
      "loading": _vm.loadingUpdate,
      "partnerId": _vm.partnerId,
      "projectId": _vm.projectId,
      "viewId": _vm.viewId
    },
    on: {
      "update": function update(v) {
        return _vm.$emit('update', v);
      },
      "updateReferences": function updateReferences(v) {
        return _vm.$emit('updateReferences', v);
      },
      "updateCustomFieldValues": function updateCustomFieldValues(v) {
        return _vm.$emit('updateCustomFieldValues', v);
      },
      "updateTicketStatus": function updateTicketStatus(v) {
        return _vm.$emit('updateTicketStatus', v);
      },
      "deleteTicket": function deleteTicket(v) {
        return _vm.$emit('deleteTicket', v);
      },
      "update:assignees": function updateAssignees(assignees) {
        return _vm.$emit('update', {
          assignees: assignees
        });
      },
      "delete:assignees": function deleteAssignees(v) {
        return _vm.$emit('delete:assignees', v);
      },
      "add:assignees": function addAssignees(v) {
        return _vm.$emit('add:assignees', v);
      },
      "confirm": _vm.confirm
    },
    model: {
      value: _vm.ticket,
      callback: function callback($$v) {
        _vm.ticket = $$v;
      },
      expression: "ticket"
    }
  }) : _c('latest-entries-card-empty')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }