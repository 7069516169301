var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ticket ? _c('ticket-side-card', {
    attrs: {
      "loading": _vm.loadingUpdate,
      "partnerId": _vm.partnerId,
      "projectId": _vm.projectId,
      "viewId": _vm.viewId
    },
    on: {
      "close": _vm.closeSideCard,
      "update": _vm.onUpdateTicket,
      "updateTicketStatus": _vm.onUpdateTicketStatus,
      "updateCustomFieldValues": _vm.onUpdateCustomFieldValues,
      "updateReferences": _vm.onUpdateTicket,
      "deleteTicket": _vm.onDeleteTicket,
      "delete:assignees": _vm.createDeleteAssigneeActivity,
      "add:assignees": _vm.createAddAssigneeActivity
    },
    model: {
      value: _vm.ticket,
      callback: function callback($$v) {
        _vm.ticket = $$v;
      },
      expression: "ticket"
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }