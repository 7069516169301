var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.dialogModel,
      "title": _vm.$t('views.fleet.FleetHomeVehicleTable.addVehicle'),
      "rightDisabled": _vm.currentStep === _vm.STEPS.ADD && (!_vm.isFormValid || !_vm.createVehicleDto.displayName),
      "leftText": _vm.$t('close'),
      "rightText": _vm.currentStep === _vm.STEPS.ADD ? _vm.$t('create') : _vm.$t('views.fleet.FleetHomeVehicleTable.anotherVehicle'),
      "loading": _vm.loading,
      "rightLoading": _vm.loading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialogModel = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialogModel = $event;
      },
      "leftClick": _vm.close,
      "rightClick": _vm.rightClick,
      "close": _vm.close
    }
  }, [_c('v-stepper', {
    staticClass: "pa-0",
    attrs: {
      "flat": ""
    },
    model: {
      value: _vm.currentStep,
      callback: function callback($$v) {
        _vm.currentStep = $$v;
      },
      expression: "currentStep"
    }
  }, [_c('v-stepper-content', {
    key: _vm.STEPS.ADD,
    staticClass: "pa-0",
    attrs: {
      "step": _vm.STEPS.ADD
    }
  }, [_c('v-card-text', [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('div', {
    staticClass: "mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.displayNameHint")) + " ")]), _c('v-text-field', {
    staticClass: "pt-1",
    attrs: {
      "label": _vm.$t('views.fleet.FleetVehicleListView.displayName'),
      "rules": _vm.requiredRule,
      "outlined": "",
      "required": "",
      "persistent-hint": ""
    },
    model: {
      value: _vm.createVehicleDto.displayName,
      callback: function callback($$v) {
        _vm.$set(_vm.createVehicleDto, "displayName", $$v);
      },
      expression: "createVehicleDto.displayName"
    }
  }), !_vm.isTrain ? _c('div', [_c('div', {
    staticClass: "mt-1"
  }, [_vm._v(_vm._s(_vm.$t("views.fleet.FleetVehicleListView.numberplateHint")))]), _c('numberplate-form', {
    staticClass: "pt-2",
    attrs: {
      "countryCode": _vm.countryCode
    },
    model: {
      value: _vm.createVehicleDto.numberplate,
      callback: function callback($$v) {
        _vm.$set(_vm.createVehicleDto, "numberplate", $$v);
      },
      expression: "createVehicleDto.numberplate"
    }
  }), _vm.debug ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('views.fleet.FleetVehicleListView.numberplate'),
      "outlined": "",
      "persistent-hint": ""
    },
    model: {
      value: _vm.createVehicleDto.numberplate,
      callback: function callback($$v) {
        _vm.$set(_vm.createVehicleDto, "numberplate", $$v);
      },
      expression: "createVehicleDto.numberplate"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mt-1"
  }, [_vm._v(_vm._s(_vm.$t("views.fleet.FleetVehicleListView.identificationnumberHint")))]), _c('v-text-field', {
    staticClass: "pt-1",
    attrs: {
      "label": _vm.$t('views.fleet.FleetVehicleListView.identificationnumber'),
      "rules": _vm.vinRuleLength,
      "outlined": "",
      "persistent-hint": "",
      "suffix": _vm.getChecksumText,
      "counter": "17",
      "clearable": ""
    },
    model: {
      value: _vm.createVehicleDto.identificationnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.createVehicleDto, "identificationnumber", $$v);
      },
      expression: "createVehicleDto.identificationnumber"
    }
  })], 1) : _vm._e()], 1)], 1), _c('debug', {
    attrs: {
      "debug": _vm.createVehicleDto
    }
  })], 1), _c('v-stepper-content', {
    key: _vm.STEPS.SUCCESS,
    attrs: {
      "step": _vm.STEPS.SUCCESS
    }
  }, _vm._l(_vm.created, function (vehicle) {
    return _c('refs-vehicle', {
      key: vehicle.id,
      attrs: {
        "item": vehicle
      },
      on: {
        "openDetail": function openDetail($event) {
          return _vm.goToVehicleDetail(vehicle);
        }
      }
    });
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }