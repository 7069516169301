/**
 * Calendar event interface
 *
 * see `import { CalendarEventExtended } from "vuetify"`.
 * Can be extended to add properties, see `PartnerBookingCalendar`: `BookingCalendarEvent`
 */
export interface CalendarEvent<T> {
  /**
   * Set property of event’s displayed name
   */
  name: string;

  /**
   * Sets property of event's start timestamp. Timestamp can be ignored (all day event) by setting `timed` to false.
   * It must be a Date, number of milliseconds since Epoch, or a string in the format of YYYY-MM-DD or YYYY-MM-DD hh:mm. Zero-padding is optional and seconds are ignored.
   */
  start: Date | number | string;

  /**
   * Set property of event’s end timestamp.
   * It must be a Date, number of milliseconds since Epoch, or a string in the format of YYYY-MM-DD or YYYY-MM-DD hh:mm. Zero-padding is optional and seconds are ignored.
   */
  end?: Date | number | string | undefined;

  /**
   * If Dates or milliseconds are used as the start or end timestamp of an event, if true its not an all day event.
   */
  timed?: boolean;

  /**
   * Applies specified color to the control - it can be the name of material color (for example success or purple) or css color (#033 or rgba(255, 0, 0, 0.5)). You can find a list of built-in classes on the colors page.
   */
  color?: string;

  /**
   * Specifies what categories to display in the category view
   */
  category?: string;

  /**
   * The associated generic data to the event.
   */
  data: T[];
}

/**
 * Enum for calendar display
 */
export enum CalendarTypeEnum {
  /**
   * Display day view
   */
  DAY = "day",

  /**
   * Display week view
   */
  WEEK = "week",

  /**
   * Display month view
   */
  MONTH = "month",

  /**
   * Display category view
   */
  CATEGORY = "category"
}

/**
 * Data passed by "change" event of vuetify Calendar
 * Start refers to the from date shown in the calendar, end is the to date in the calendar.
 * If the calendar is week, from 1st to 7th, start is 1st, end 7th. Same goes for month and other calendar types
 * @see https://v2.vuetifyjs.com/en/api/v-calendar/#events
 */
export interface CalendarChangeEventData {
  start: CalendarTime;
  end: CalendarTime;
}

/**
 * Calendar time object passed from v-calendar
 */
export interface CalendarTime {
  date: string;
  time: string;
  year: number;
  month: number;
  day: number;
  weekday: number;
  hour: number;
  minute: number;
  hasDay: boolean;
  hasTime: boolean;
  past: boolean;
  present: boolean;
  future: boolean;
}
