var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "flat": "",
      "outlined": ""
    }
  }, [_c('template', {
    slot: "progress"
  }, [_c('v-progress-linear', {
    attrs: {
      "color": _vm.loadingColor,
      "height": "3",
      "indeterminate": ""
    }
  })], 1), _c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.cards.LatestFormEntriesCard.title")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-xs text--disabled cursor-pointer",
    attrs: {
      "text": "",
      "to": _vm.formListRoute
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.cards.LatestFormEntriesCard.button")) + " ")])], 1), _c('v-divider'), _c('v-card-text', [_vm.latestForms.length > 0 ? _c('latest-entries-card-list', {
    attrs: {
      "inputs": _vm.latestForms
    },
    on: {
      "click": function click(d) {
        return _vm.route(d.id);
      }
    }
  }) : _c('latest-entries-card-empty')], 1)], 2) : _c('v-card', [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, divider, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }