




















import Debug from "@/components/utility/Debug.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, Debug }
})
export default class PartnerSettings extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  tab = this.preselectedTab;

  readonly tabs = ["templates", "settings", "faqs"];

  get preselectedTab(): number {
    const preselected = this.$route.params.tab;

    if (!preselected) {
      return 0;
    }

    const tab = this.tabs.indexOf(preselected);

    if (tab === -1) {
      return 0;
    }

    return tab;
  }

  get debug() {
    return this.$store.state.config.debug;
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  get items() {
    const items: IMenuListItem[] = [];

    items.push({
      icon: "mdi-email-edit-outline",
      text: "navigation.ThePartnerList.setting.templates",
      link: `/partner/${this.partnerId}/template`,
      isEnabled: FeatureModule.isTemplateEnabled,
      permission: {
        resource: ResourceEnum.TEMPLATE,
        action: ActionEnum.READ,
        field: this.partnerId
      }
    });

    items.push({
      icon: "mdi-file-cog-outline",
      text: "components.partner.PartnerManagerList.title",
      link: `/partners/${this.partnerId}/manage`,
      isEnabled: FeatureModule.isPartnerManagerEnabled,
      permission: {
        resource: ResourceEnum.SETTING_PARTNER_INTERNAL,
        action: ActionEnum.READ,
        field: this.partnerId
      }
    });

    items.push({
      icon: "mdi-swap-vertical-bold",
      text: "navigation.ThePartnerList.setting.operations",
      link: `/partners/${this.partnerId}/operation`,
      isEnabled: true,
      permission: {
        resource: ResourceEnum.OPERATION,
        action: ActionEnum.READ,
        field: this.partnerId
      }
    });

    items.push({
      icon: "mdi-cog-clockwise",
      text: "navigation.ThePartnerList.setting.activityLog",
      link: `/partners/${this.partnerId}/activity-log`,
      isEnabled: true,
      permission: {
        resource: ResourceEnum.PARTNER,
        action: ActionEnum.READ,
        field: this.partnerId
      }
    });

    items.push({
      icon: "mdi-comment-question-outline",
      text: "navigation.ThePartnerList.setting.faq",
      link: `/partners/${this.partnerId}/faq`,
      isEnabled: true,
      permission: {
        resource: ResourceEnum.PARTNER,
        action: ActionEnum.READ,
        field: this.partnerId
      }
    });

    return items.filter(i => i.isEnabled);
  }
}
