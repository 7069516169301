



















































import { Component, Vue, Prop } from "vue-property-decorator";
import { BookingCustomerFieldConfigurationDtoGen } from "@/services/booking/v1/data-contracts";
@Component({
  components: {}
})
export default class BookingCustomerFieldConfiguration extends Vue {
  @Prop()
  customerFieldConfig!: BookingCustomerFieldConfigurationDtoGen[];
}
