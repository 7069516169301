/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { BookingClientProvider } from "../booking-http-client.provider";
import {
  BookingCreateResourceDtoGen,
  BookingPageViewModelGen,
  BookingResourceControllerFindAllByPartnerIdParamsGen,
  BookingResourceViewModelGen,
  BookingUpdateResourceDtoGen
} from "../v1/data-contracts";
import { Resource } from "../v1/Resource";

/**
 * Service to talk to the Booking endpoint.
 */
export class ResourceService {
  private readonly resource: Resource;

  constructor(clientProvider: BookingClientProvider) {
    this.resource = new Resource(clientProvider.client());
  }

  /**
   * Creates a resource for a given partner
   *
   * @param partnerId
   * @param data
   * @returns
   */
  async create(partnerId: string, data: BookingCreateResourceDtoGen): Promise<BookingResourceViewModelGen> {
    return (await this.resource.resourceControllerCreate(partnerId, data)).data;
  }

  /**
   * Gets all bookings for a given partner
   *
   * @param query
   * @returns
   */
  async findBookingsByPartner(
    query: BookingResourceControllerFindAllByPartnerIdParamsGen
  ): Promise<BookingPageViewModelGen & { data?: BookingResourceViewModelGen[] }> {
    return (await this.resource.resourceControllerFindAllByPartnerId(query)).data;
  }

  /**
   * Gets a particular resource from a given partner
   *
   * @param partnerId
   * @param id
   * @returns
   */
  async findResourceByPartner(partnerId: string, id: string): Promise<BookingResourceViewModelGen> {
    return (await this.resource.resourceControllerFindOneByPartnerId(partnerId, id)).data;
  }

  /**
   * Replaces a resource for a given partner
   *
   * @param partnerId
   * @param id
   * @param data
   * @returns
   */
  async replaceOneByPartnerId(
    partnerId: string,
    id: string,
    data: BookingUpdateResourceDtoGen
  ): Promise<BookingResourceViewModelGen> {
    return (await this.resource.resourceControllerReplace(partnerId, id, data)).data;
  }

  /**
   * Deletes a resource for a given partner
   *
   * @param part
   * nerId
   * @param id
   * @returns
   */
  async removeResource(partnerId: string, id: string): Promise<BookingResourceViewModelGen> {
    return (await this.resource.resourceControllerRemove(partnerId, id)).data;
  }
}

export default new ResourceService(new BookingClientProvider());
