






























































import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { $t } from "@/lib/utility/t";
import { BackendResourceEnum as R } from "@/store/enum/authResourceEnum";
import { ActionEnum as A } from "@/store/enum/authActionEnum";
import { WebhookTypeEnum as T } from "@/lib/enum/webhook-type.enum";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: {}
})
export default class WebhookConfigCard extends mixins(Vue) {
  @Prop()
  loading!: boolean;

  @Prop()
  resource!: R;

  @Prop()
  action!: A;

  @Prop()
  type!: T;

  readonly viewModelMap = new Map([
    [R.ANONYMIZATION, { url: "https://api.mmmint.ai/ainonymizer/v1/docs/", name: "AnonymizationViewModel" }],
    [R.REPORT, { url: "https://api.mrfiktiv.com/v1/docs/", name: "ReportViewModel" }]
  ]);

  isMenu = false;

  get requiredRule() {
    return [requiredRule()];
  }

  get selectedResource() {
    return this.resource;
  }

  set selectedResource(value) {
    this.$emit("update:resource", value);
  }

  get selectedAction() {
    return this.action;
  }

  set selectedAction(value) {
    this.$emit("update:action", value);
  }

  get selectedType() {
    return this.type;
  }

  set selectedType(value) {
    this.$emit("update:type", value);
  }

  get selected() {
    return [`${this.selectedResource},${this.selectedAction},${this.selectedType}`];
  }

  set selected(value) {
    if (!value) return;

    const [resource, action, type] = value[0].split(",") as [R, A, T];

    this.selectedResource = resource;
    this.selectedAction = action;
    this.selectedType = type;

    this.$emit("update");
  }

  keyify(resource: R, action: A | "" = "", type: T | "" = "") {
    return `${resource},${action},${type}`;
  }

  itemIdAndName(resource: R, action: A | "" = "", type: T | "" = "") {
    let name = $t(`BackendResourceEnum.${resource}`);
    if (action) name = $t(`ActionEnum.${action}`);
    if (type) name = $t(`WebhookTypeEnum.${type}`);

    return {
      id: this.keyify(resource, action, type),
      name: name
    };
  }

  get selectedName() {
    let name = "";
    if (this.resource) name += $t(`BackendResourceEnum.${this.resource}`);
    if (this.action) name += " - " + $t(`ActionEnum.${this.action}`);
    if (this.type) name += " - " + $t(`WebhookTypeEnum.${this.type}`);

    return name;
  }

  /**
   *
   */
  items = [
    {
      ...this.itemIdAndName(R.ANONYMIZATION),
      children: [
        {
          ...this.itemIdAndName(R.ANONYMIZATION, A.UPDATE),
          children: [
            {
              ...this.itemIdAndName(R.ANONYMIZATION, A.UPDATE, T.FINISHED),
              children: []
            }
          ]
        }
      ]
    },
    {
      ...this.itemIdAndName(R.REPORT),
      children: [
        {
          ...this.itemIdAndName(R.REPORT, A.CREATE),
          children: []
        }
      ]
    }
  ];
}
