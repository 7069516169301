var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_vm.suggestions ? _c('v-combobox', {
    attrs: {
      "items": _vm.suggestions,
      "clearable": "",
      "item-text": "companyName",
      "item-value": "companyName",
      "outlined": "",
      "counter": 15,
      "label": _vm.$t('objects.attendee.companyName'),
      "rules": _vm.requiredRule
    },
    on: {
      "input": _vm.handleInput
    },
    model: {
      value: _vm.value.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "companyName", $$v);
      },
      expression: "value.companyName"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.attendee.firstName'),
      "outlined": ""
    },
    model: {
      value: _vm.value.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "firstName", $$v);
      },
      expression: "value.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.attendee.lastName'),
      "outlined": ""
    },
    model: {
      value: _vm.value.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "lastName", $$v);
      },
      expression: "value.lastName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.attendee.email'),
      "rules": _vm.emailRuleOptional,
      "outlined": ""
    },
    model: {
      value: _vm.value.email,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "email", $$v);
      },
      expression: "value.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.attendee.phone'),
      "rules": _vm.phoneRuleOptional,
      "outlined": ""
    },
    model: {
      value: _vm.value.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "phone", $$v);
      },
      expression: "value.phone"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }