var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isDialogActiveNotProp ? _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.title,
      "isDialogActive": _vm.isDialogActiveNotProp,
      "leftLoading": _vm.isCreateContractLoading,
      "rightLoading": _vm.isCreateContractLoading,
      "rightDisabled": _vm.isNextDisabled,
      "leftText": _vm.leftText,
      "rightText": _vm.rightText
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActiveNotProp = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActiveNotProp = $event;
      },
      "leftClick": _vm.close,
      "rightClick": _vm.next,
      "close": _vm.close
    }
  }, [_c('div', {
    staticStyle: {
      "max-height": "calc(100vh - 290px)",
      "overflow-y": "scroll"
    }
  }, [_c('v-stepper', {
    attrs: {
      "elevation": 0
    },
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-items', {
    staticClass: "pa-0"
  }, [_vm.createContractType === _vm.ContractTypeEnum.LEASING ? _c('v-stepper-content', {
    staticClass: "pa-0",
    attrs: {
      "step": _vm.STEP.INPUT_DATA
    }
  }, [_c('v-form', {
    staticClass: "mt-3",
    model: {
      value: _vm.isNewContractInputValid,
      callback: function callback($$v) {
        _vm.isNewContractInputValid = $$v;
      },
      expression: "isNewContractInputValid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": true,
      "label": _vm.i18n['title'],
      "rules": _vm.requiredRule
    },
    model: {
      value: _vm.newContract.title,
      callback: function callback($$v) {
        _vm.$set(_vm.newContract, "title", $$v);
      },
      expression: "newContract.title"
    }
  }), _c('template-editor', {
    staticClass: "mb-12",
    staticStyle: {
      "height": "180px"
    },
    attrs: {
      "outlined": true,
      "label": _vm.i18n['description']
    },
    model: {
      value: _vm.newContract.description,
      callback: function callback($$v) {
        _vm.$set(_vm.newContract, "description", $$v);
      },
      expression: "newContract.description"
    }
  })], 1)], 1) : _vm._e(), _c('v-stepper-content', {
    staticClass: "pa-0",
    attrs: {
      "step": _vm.STEP.SUCCESS
    }
  }, [_c('v-img', {
    staticClass: "mt-8",
    attrs: {
      "align": "center",
      "justify": "center",
      "contain": "",
      "max-height": "250px",
      "src": require("@/assets/undraw_done_re_oak4.svg")
    }
  })], 1)], 1)], 1)], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }