var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ticket-table-card', {
    attrs: {
      "refs": _vm.refs,
      "loadingTickets": _vm.loadingTickets,
      "loadingPartnerUsers": _vm.loadingPartnerUsers,
      "loadingProjects": _vm.loadingProjects,
      "partnerId": _vm.partnerId,
      "updateUrl": false,
      "showVehicleHeader": true
    },
    on: {
      "refresh": _vm.refresh
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }