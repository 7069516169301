/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BookingCreateServiceDtoGen,
  BookingExceptionViewmodelGen,
  BookingPageViewModelGen,
  BookingServiceControllerFindAllByPartnerIdParamsGen,
  BookingServiceViewModelGen,
  BookingUpdateServiceDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Service<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags service
   * @name ServiceControllerCreate
   * @summary Creates a service for a given partner
   * @request POST:/partner/{partnerId}/service
   * @secure
   * @response `201` `BookingServiceViewModelGen` Service created
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  serviceControllerCreate = (partnerId: string, data: BookingCreateServiceDtoGen, params: RequestParams = {}) =>
    this.http.request<BookingServiceViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/service`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags service
   * @name ServiceControllerFindAllByPartnerId
   * @summary Gets all services from a given partner
   * @request GET:/partner/{partnerId}/service
   * @secure
   * @response `200` `(BookingPageViewModelGen & { data?: (BookingServiceViewModelGen)[] })`
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  serviceControllerFindAllByPartnerId = (
    { partnerId, ...query }: BookingServiceControllerFindAllByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingPageViewModelGen & { data?: BookingServiceViewModelGen[] }, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/service`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags service
   * @name ServiceControllerFindOneByPartnerId
   * @summary Gets a particular service from a given partner
   * @request GET:/partner/{partnerId}/service/{id}
   * @secure
   * @response `200` `BookingServiceViewModelGen` Service returned
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  serviceControllerFindOneByPartnerId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<BookingServiceViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/service/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags service
   * @name ServiceControllerReplace
   * @summary Replaces a service for a given partner
   * @request PUT:/partner/{partnerId}/service/{id}
   * @secure
   * @response `200` `BookingServiceViewModelGen` Service details replaced
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  serviceControllerReplace = (
    partnerId: string,
    id: string,
    data: BookingUpdateServiceDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingServiceViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/service/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags service
   * @name ServiceControllerRemove
   * @summary Deletes a service for a given partner
   * @request DELETE:/partner/{partnerId}/service/{id}
   * @secure
   * @response `200` `BookingServiceViewModelGen` Service deleted
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  serviceControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<BookingServiceViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/service/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
