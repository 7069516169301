








import SharedContentMixin from "@/components/partner/SharedContentMixin.vue";
import SharedContentTable from "@/components/partner/SharedContentTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    SharedContentTable
  }
})
export default class SharedContentView extends SharedContentMixin {}
