




































































import PartnerFormDetailInfoCard from "@/components/partner/PartnerFormDetailInfoCard.vue";
import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { ContactCustomerEnum } from "@/lib/enum/ContactCustomer.enum";
import { detailedDate, simpleDate } from "@/lib/utility/date-helper";
import { downloadUrl } from "@/lib/utility/downloadFileFunc";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import TemplateDialog from "../template/TemplateDialog.vue";

@Component({
  components: {
    PartnerFormDetailInfoCard,
    ContactCustomerDialog,
    TemplateDialog
  }
})
export default class PartnerFormDetail extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  get partner() {
    return PartnerModule.partner;
  }

  get form() {
    return PartnerModule.form;
  }

  get forms() {
    return PartnerModule.forms;
  }

  get numberOfImages() {
    return PartnerModule?.form?.attachments?.length + (PartnerModule?.form?.registration ? 1 : 0);
  }

  get date() {
    if (this.form.timestamps && this.form.timestamps.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.form.timestamps.created, locale);
    }
    return detailedDate(Date.now().toString());
  }

  get dateSimple() {
    if (PartnerModule.report?.timestamps?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return simpleDate(PartnerModule.report?.timestamps?.created, locale);
    }
    return "";
  }

  get resource() {
    return ContactCustomerEnum.FORM;
  }

  get templateDialogMethods() {
    return {
      context: {
        partner: this.partner,
        form: this.form
      },
      to: [this.form.contact?.email || ""],
      from: [this.partner]
    };
  }

  get isTemplateActive() {
    return FeatureModule.isTemplateEnabled;
  }

  toDetailView() {
    this.$router.push({ name: "PartnerFormsDetailView", params: { formId: PartnerModule.form._id } });
  }

  async downloadExport() {
    await PartnerModule.getExportForFormById(this.form._id);

    downloadUrl(PartnerModule.formExportFile, this.partner.companyName + ".xml");
  }

  /**
   * Set the active entry to the newest entry in the entries available.
   */
  async setToLatestEntry() {
    await PartnerModule.getFormByIdForPartner(PartnerModule.forms[0]._id);
  }
}
