




















import { Component, Vue, Prop } from "vue-property-decorator";
import Card from "@/components/utility/Card.vue";
import ComboBox from "@/components/utility/ComboBox.vue";

@Component({
  components: { Card, ComboBox }
})
export default class UpdatableCard extends Vue {
  @Prop()
  title!: string;

  @Prop({ default: true })
  disabled!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  click() {
    this.$emit("click");
  }
}
