






















































import ImageInput from "@/components/files/ImageInput.vue";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { ITicket } from "@/models/ticket.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgReferenceGen } from "@/services/thg/v1/data-contracts";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { TicketModule } from "@/store/modules/ticket.store";
import { Component, Prop } from "vue-property-decorator";
import ActivityCard, { ActivityCardConfig } from "../thg/ActivityCard.vue";

@Component({
  components: { ActivityCard, ImageInput },
  filters: {}
})
export default class TicketSideCardActivities extends RulesMixin {
  @Prop()
  private value!: ITicket;

  get TicketStatusEnum() {
    return TicketStatusEnum;
  }

  get ticket() {
    return this.value;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get closedTicketColor() {
    return TicketModule.ticketStateMap.get(TicketStatusEnum.CLOSED)?.color;
  }

  get activitySource(): MrfiktivReferenceGen | ThgReferenceGen {
    return {
      refId: this.ticket.id ?? "",
      refType: ResourceEnum.TICKET
    };
  }

  get config(): ActivityCardConfig {
    return { comment: true, mail: false, document: true, image: true, setting: true };
  }

  updateTicketStatus(status: TicketStatusEnum) {
    this.$emit("updateTicketStatus", status);
  }

  uploadImage() {
    if (this.$refs.uploader) {
      (this.$refs.uploader as ImageInput).click();
    }

    this.$log.debug(this.$refs);
  }
}
