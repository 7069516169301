










































import Debug from "@/components/utility/Debug.vue";
import MDetailTable, { DetailTableTypeEnum, IMDetailTableConfig } from "@/components/utility/mmmint/MDetailTable.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { flattenObject } from "@/lib/flatten-object";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivDaWebhookViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { DaSettingsKeys } from "@/store/enum/manage/da-setting-keys.enum";
import { DaWebhookModule } from "@/store/modules/da-webhook.store";
import { PartnerModule } from "@/store/modules/partner";
import { SettingModule } from "@/store/modules/setting.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    PaginatedTable,
    SideCard,
    MDetailTable,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerDaTableView extends PartnerFallbackMixin {
  readonly store = DaWebhookModule;

  title = this.$t("DA Webhook");

  groupBy = false;
  isLoading = false;

  selectedItem: MrfiktivDaWebhookViewModelGen | null = null;
  showSideCard = false;

  async mounted() {
    await this.load();
  }

  async load() {
    this.isLoading = true;
    try {
      await this.trySetByRouteOrDefault();
      await this.settingStore.getSettingsByPartnerId(this.partnerId);

      await DaWebhookModule.fetchFirstPage({ partnerId: this.partnerId });
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [
      {
        text: "updated_at",
        value: "object.attributes.updated_at"
      },
      {
        text: "event",
        value: "event"
      },
      {
        text: "state",
        value: "data.dossier.state"
      },
      {
        text: "internal state",
        value: "data.dossier.internalState"
      },
      {
        text: "dossier",
        value: "data.dossier.id"
      },
      {
        text: "license_plate",
        value: "data.dossier.vehicle.license_plate"
      },

      {
        text: "first_name",
        value: "data.dossier.contact.first_name"
      },
      {
        text: "last_name",
        value: "data.dossier.contact.last_name"
      },
      {
        text: "company",
        value: "data.dossier.contact.company"
      },
      {
        text: "email",
        value: "data.dossier.contact.email"
      },
      {
        text: "mobile_number",
        value: "data.dossier.contact.mobile_number"
      },
      {
        text: "external_ref",
        value: "data.dossier.orders.external_ref"
      },
      {
        text: "insurer_reference",
        value: "data.dossier.orders.insurer_reference"
      },
      {
        text: "insurer name",
        value: "data.dossier.orders.insurer.name"
      },
      {
        text: "auto_repair_manager name",
        value: "data.dossier.orders.auto_repair_manager.name"
      },
      {
        text: "auto_repair_manager_reference",
        value: "data.dossier.orders.auto_repair_manager_reference"
      },
      {
        text: "vehicle_identification_number",
        value: "data.dossier.vehicle.vehicle_identification_number"
      },
      {
        text: "id",
        value: "object.attributes.id"
      }
    ];

    return headers;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get selectedItemFlat() {
    return flattenObject(this.selectedItem);
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    return Object.keys(flattenObject(this.selectedItemFlat)).map(key => {
      return {
        key: key,
        type: DetailTableTypeEnum.PROPERTY
      } as IMDetailTableConfig;
    });
  }

  get settingStore() {
    return SettingModule;
  }

  get publicStatus() {
    return Number(
      this.settingStore.allPartnerSettings.find(s => s.key === DaSettingsKeys.CUSTOMER_STATUS_CATEGORY_ID)?.value
    );
  }

  get internalStatus() {
    return Number(
      this.settingStore.allPartnerSettings.find(s => s.key === DaSettingsKeys.INTERNAL_STATUS_CATEGORY_ID)?.value
    );
  }

  findStates(states?: any[]) {
    return states?.find(s => s.category_id === this.publicStatus);
  }

  stateName(item?: any): string {
    return (
      item?.data.dossier.states?.find((s: { category_id: number }) => s.category_id === this.publicStatus)?.name ?? ""
    );
  }

  findInternalStates(states?: any[]) {
    return states?.find(s => s.category_id === this.internalStatus);
  }

  internalStateName(item?: any): string {
    return (
      item?.data.dossier.states?.find((s: { category_id: number }) => s.category_id === this.internalStatus)?.name ?? ""
    );
  }
}
