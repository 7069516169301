var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "predefinedFilter": _vm.predefinedFilter,
      "selectedItems": _vm.selectedItems,
      "showSelect": true
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "selectActions",
      fn: function fn() {
        return [_vm.selectedItems.length ? _c('span', [_c('span', {
          staticClass: "caption"
        }, [_vm._v(_vm._s(_vm.selectedItems.length) + " " + _vm._s(_vm.$t("selected")))]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('anonymization-download-dialog', {
          attrs: {
            "items": _vm.selectedItems
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "bodyActions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "color": "info",
            "outlined": "",
            "disabled": _vm.isReloading
          },
          on: {
            "click": _vm.reload
          }
        }, [_vm._v(_vm._s(_vm.$t("common.verbs.refresh")))])];
      },
      proxy: true
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")];
      }
    }, {
      key: "item.totalTime",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: item.totalTime,
            expression: "item.totalTime"
          }]
        }, [_vm._v(" " + _vm._s(item.totalTime) + " " + _vm._s(_vm.$t("seconds")))])];
      }
    }, {
      key: "item.calculationTime",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: item.calculationTime,
            expression: "item.calculationTime"
          }]
        }, [_vm._v(" " + _vm._s(item.calculationTime) + " " + _vm._s(_vm.$t("seconds")))])];
      }
    }, {
      key: "item.userId",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('assignee-preview', {
          attrs: {
            "value": [item.userId]
          }
        })];
      }
    }])
  }), _vm.selectedItem ? _c('anonymization-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": function close() {
        return _vm.setSelectedItem(null);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }