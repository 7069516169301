
















































import { Component, Prop } from "vue-property-decorator";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import Card from "../utility/Card.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: { Card }
})
export default class CustomerBasicInfoCard extends DarkModeHighlightMixin {
  /** Change the user/customer detail page */
  @Prop({ default: "CustomerAccountDetailView" })
  detailsComponentName!: string;

  /**
   * Show go to details button
   */
  @Prop({ default: true })
  navigateToDetails!: boolean;

  @Prop({ default: "" })
  customerAccountId!: string;

  @Prop()
  createdOn!: string;

  @Prop()
  isActive!: boolean;

  @Prop({ default: "" })
  userId!: string;

  @Prop({ default: false })
  isMarketingOptIn!: boolean;

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get createdOnFormatted() {
    return this.$t("components.partner.CustomerBasicInfoCard.createdOn", {
      date: simpleDate(this.createdOn)
    });
  }
}
