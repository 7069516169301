var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    key: 'fleet-home-vehicle-table' + _vm.monthMethods.calendarKey,
    attrs: {
      "outlined": "",
      "elevation": 0
    }
  }, [_c('v-card-title', [_c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "icon": "",
      "loading": _vm.loadingEvents,
      "data-test-prev-month": ""
    },
    on: {
      "click": _vm.monthMethods.prevMonth
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "icon": "",
      "loading": _vm.loadingEvents,
      "data-test-next-month": ""
    },
    on: {
      "click": _vm.monthMethods.nextMonth
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1), _c('span', {
    domProps: {
      "textContent": _vm._s(_vm.monthMethods.getCalendarTitle())
    }
  }), _c('v-spacer'), _c('debug', [_vm._v(" sortBy " + _vm._s(_vm.sortBy) + " reverse" + _vm._s(_vm.reverse) + " ")]), _c('span', {
    staticClass: "text-caption"
  }, _vm._l([_vm.BackendResourceEnum.VEHICLE, _vm.BackendResourceEnum.EVENT, _vm.BackendResourceEnum.TICKET, _vm.BackendResourceEnum.REPORT], function (resource, index) {
    return _c('span', {
      key: 'counter' + resource
    }, [_c('v-btn', {
      attrs: {
        "x-small": "",
        "dense": "",
        "text": "",
        "disabled": !_vm.count[resource]
      },
      domProps: {
        "textContent": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.summary.' + resource.toLowerCase(), {
          count: _vm.count[resource]
        }))
      },
      on: {
        "click": function click($event) {
          return _vm.setSortTable(resource);
        }
      }
    }), _vm.count[resource] ? _c('span', {
      staticClass: "ml-n2 mr-2"
    }, [_vm.sortBy === resource ? _c('v-icon', {
      attrs: {
        "x-small": ""
      },
      domProps: {
        "textContent": _vm._s(_vm.reverse ? 'mdi-arrow-up' : 'mdi-arrow-down')
      }
    }) : _vm._e()], 1) : _vm._e(), index !== 3 ? _c('span', {
      domProps: {
        "textContent": _vm._s('|')
      }
    }) : _vm._e()], 1);
  }), 0)], 1), _vm.loadingEvents || !_vm.initialized ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": true
    }
  }) : _c('v-divider'), !_vm.initialized ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-three-line"
    }
  }) : _vm.vehicleAggregations.length > 0 ? _c('div', _vm._l(_vm.vehicleAggregations.slice(_vm.page * 10, _vm.page * 10 + 10), function (vehicleAggregation, index) {
    return _c('div', {
      key: 'vehicleAggregation' + vehicleAggregation.vehicle.id
    }, [_c('debug', [_vm._v(" " + _vm._s(vehicleAggregation.getAmountOfEventsAndTicketsForMonth(_vm.monthMethods.currentMonthYear())))]), _c('fleet-home-vehicle-table-row', {
      attrs: {
        "monthMethods": _vm.monthMethods,
        "vehicleAggregation": vehicleAggregation
      },
      on: {
        "openTicketSideCard": _vm.openTicketSideCard,
        "openEventSideCard": function openEventSideCard($event) {
          return _vm.$emit('openEventSideCard', $event);
        }
      }
    }), index !== _vm.vehicleAggregations.length ? _c('v-divider', {
      staticClass: "mx-4"
    }) : _vm._e()], 1);
  }), 0) : _vm.initialLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-three-line"
    }
  }) : _c('div', [_c('v-card', {
    staticClass: "fill-height py-8",
    attrs: {
      "fluid": "",
      "flat": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
      "max-height": "150px",
      "contain": ""
    }
  }), _c('v-card-text', {
    staticClass: "text-center text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCard.noEventsInCurrentMonth")) + " ")]), _c('v-card-actions', {
    staticClass: "justify-center"
  })], 1)], 1), Math.ceil(_vm.vehicleAggregations.length / 10) ? _c('v-card-title', [_c('v-spacer'), _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "icon": "",
      "small": "",
      "loading": _vm.loadingEvents,
      "disabled": _vm.page - 1 === -1
    },
    on: {
      "click": function click($event) {
        _vm.page--;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "icon": "",
      "small": "",
      "loading": _vm.loadingEvents,
      "disabled": _vm.page + 1 === Math.ceil(_vm.vehicleAggregations.length / 10)
    },
    on: {
      "click": function click($event) {
        _vm.page++;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1), _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" " + _vm._s(_vm.page + 1) + " / " + _vm._s(Math.ceil(_vm.vehicleAggregations.length / 10)) + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }