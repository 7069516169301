



















































































import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IAddressWithGeo } from "@/models/address.entity";
import { IInspection } from "@/models/inspection.entity";
import { ConfigModule } from "@/store/modules/config";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { VSkeletonLoader } from "vuetify/lib";
import TemplateEditor from "../template/TemplateEditor.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import CopyClipboardText from "../utility/CopyClipboardText.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import AddressForm from "./AddressForm.vue";

const PartnerMap = () => ({
  component: import(/* webpackPrefetch: true  */ /* webpackChunkName: "map" */ "@/components/form/PartnerMap.vue"),
  loading: VSkeletonLoader
});

@Component({
  components: {
    PartnerMap,
    CopyClipboardText,
    AddressForm,
    TimelineCard,
    TemplateEditor,
    ConfirmActionDialog
  },
  filters: { detailedDate }
})
export default class InspectionCard extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: IInspection;

  isAddressValid = false;

  isEditAddressMenu = false;

  isEditDescriptionMenu = false;

  isLoadingSaveAddress = false;

  isLoadingSaveDescription = false;

  addressCopy: IAddressWithGeo = {
    street: this.value.address.street,
    zip: this.value.address.zip,
    city: this.value.address.city,
    geo: this.value.address.geo,
    state: this.value.address.state
  };

  descriptionCopy = "";

  get color() {
    return ConfigModule.color.primary;
  }

  navigateTo() {
    const address = `${this.value.address.street}, ${this.value.address.city}, ${this.value.address.zip}`;
    const url = `https://www.google.com/maps/dir//${encodeURIComponent(address)}`;

    // Open Google Maps in a new browser tab
    window.open(url, "_blank");
  }

  get isMail() {
    return !!this.value.attendees;
  }

  get mails() {
    return this.value.attendees.map(u => u.email).join("; ");
  }

  get startDate() {
    if (!this.value.start) {
      return "";
    }

    return detailedDate(new Date(this.value.start).toString());
  }

  get endDate() {
    if (!this.value.end) {
      return "";
    }

    return detailedDate(new Date(this.value.end).toString());
  }

  get coordinates() {
    return this.value.address?.geo || undefined;
  }

  get addressText() {
    return `${this.value.address.street}, ${this.value.address.zip} ${this.value.address.city}`;
  }

  abortEditAddress() {
    this.isEditAddressMenu = false;
    this.addressCopy = {
      street: this.value.address.street,
      zip: this.value.address.zip,
      city: this.value.address.city,
      geo: this.value.address.geo,
      state: this.value.address.state
    };
  }

  async saveEditAddress() {
    this.isLoadingSaveAddress = true;

    await this.value
      .updatePartial({
        address: this.addressCopy
      })
      .catch(handleError);

    this.isLoadingSaveAddress = false;
    this.isEditAddressMenu = false;
  }

  openEditAddressMenu() {
    this.addressCopy = {
      street: this.value.address.street,
      zip: this.value.address.zip,
      city: this.value.address.city,
      geo: this.value.address.geo,
      state: this.value.address.state
    };
    this.isEditAddressMenu = true;
  }

  abortEditDescription() {
    this.isEditDescriptionMenu = false;
    this.descriptionCopy = this.value.description;
  }

  async saveEditDescription() {
    this.isLoadingSaveDescription = true;

    await this.value
      .updatePartial({
        description: this.descriptionCopy
      })
      .catch(handleError);

    this.isLoadingSaveDescription = false;
    this.isEditDescriptionMenu = false;
  }

  openEditDescriptionMenu() {
    this.descriptionCopy = this.value.description;
    this.isEditDescriptionMenu = true;
  }
}
