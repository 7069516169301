
















import AnonymizationTable from "@/components/anonymization/AnonymizationTable.vue";
import ApiAnonymizationDialog from "@/components/anonymization/ApiAnonymizationDialog.vue";
import CreateAnonymizationDialog from "@/components/anonymization/CreateAnonymizationDialog.vue";
import DropAreaMulti from "@/components/files/DropAreaMulti.vue";
import Debug from "@/components/utility/Debug.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IAnonymization } from "@/models/anonymization.entity";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    AnonymizationTable,
    Debug,
    TheLayoutPortal,
    MHeader,
    MActionList,
    MDetailViewGrid,
    DropAreaMulti,
    CreateAnonymizationDialog,
    ApiAnonymizationDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class AnonymizationTableView extends mixins(PartnerFallbackMixin) {
  loadingPartnerUsers = false;

  activeTab = 0;
  dialog = false;
  apiDialog = false;
  showSideCard = false;

  selectedItem?: IAnonymization;

  openSideCard(item: IAnonymization) {
    this.selectedItem = item;
    this.showSideCard = true;
  }

  get tabs(): IAction[] {
    return [
      { text: "Start", key: "start", icon: "mdi-table" },
      { text: "Ergebnisse", key: "view1", icon: "mdi-table" }
    ];
  }

  get actions() {
    return [
      {
        text: this.$t("anonymization.create.title"),
        key: "primaryAction",
        icon: "mdi-incognito"
      },
      {
        text: this.$t("anonymization.api.titleIII"),
        key: "secondaryAction",
        icon: "mdi-api"
      }
    ];
  }

  processAction(action: IAction) {
    switch (action.key) {
      case "primaryAction":
        this.dialog = true;
        break;
      case "secondaryAction":
        this.apiDialog = true;
        break;
    }
  }

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    return crumbs;
  }

  async mounted() {
    this.trySetByRouteOrDefault();
  }
}
