var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table-wrapper', {
    attrs: {
      "controlElements": _vm.controlElements,
      "emptyItem": _vm.emptyMileage,
      "headers": _vm.headers,
      "allItems": _vm.allMileages,
      "selectedItems": _vm.selected,
      "sortBy": "date"
    },
    on: {
      "removeItem": _vm.removeItem,
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "elevation": 0,
            "color": "info"
          },
          domProps: {
            "textContent": _vm._s(_vm.$t('add'))
          },
          on: {
            "click": function click($event) {
              _vm.isMileageCreateDialogActive = true;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "item.mileage",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(Number(item.mileage).toLocaleString("de-DE")) + " ")];
      }
    }])
  }), _c('create-mileage-dialog', {
    ref: "CreateMileageDialog",
    attrs: {
      "vehicle": _vm.vehicle,
      "isDialogActive": _vm.isMileageCreateDialogActive
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isMileageCreateDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isMileageCreateDialogActive = $event;
      }
    }
  }), _vm.removeMileageDialog ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.removeMileageDialog,
      "title": _vm.i18n['removeMileage'],
      "leftText": _vm.$t('cancel'),
      "rightText": _vm.$t('delete')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.removeMileageDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.removeMileageDialog = $event;
      },
      "leftClick": _vm.abortRemoval,
      "rightClick": _vm.remove
    }
  }, [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.i18n["mileage"]) + ": " + _vm._s(_vm.deleteMileage.mileage) + " ")]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.i18n["readDate"]) + ": " + _vm._s(_vm.deleteMileage.date) + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }