var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('cost-table', {
    attrs: {
      "selectedItems": _vm.selectedItems,
      "partnerId": _vm.partnerId,
      "showVehicleHeader": true
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('cost-chip', {
          staticClass: "ml-2",
          attrs: {
            "value": {
              expenseOrIncome: _vm.total < 0 ? _vm.CostTypeEnum.EXPENSE : _vm.CostTypeEnum.INCOME,
              total: _vm.total,
              totalReadable: (_vm.total || 0).toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR'
              })
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }