var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-row', {
    attrs: {
      "align-content": "space-between"
    }
  }), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('views.booking.BookingForm.resourceId') + ' *',
      "readonly": _vm.disabled,
      "hint": _vm.$t('views.booking.BookingForm.resourceHint'),
      "persistent-hint": "",
      "items": _vm.resources,
      "item-text": "name",
      "item-value": "id"
    },
    model: {
      value: _vm.value.resourceId,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "resourceId", $$v);
      },
      expression: "value.resourceId"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n4",
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('views.booking.BookingForm.date') + ' *',
      "readonly": _vm.disabled,
      "type": "date"
    },
    model: {
      value: _vm.value.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "startDate", $$v);
      },
      expression: "value.startDate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n4",
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('views.booking.BookingForm.startTime') + ' *',
      "readonly": _vm.disabled,
      "type": "time"
    },
    model: {
      value: _vm.value.startTime,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "startTime", $$v);
      },
      expression: "value.startTime"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n4",
    attrs: {
      "outlined": "",
      "rules": _vm.requiredRule,
      "label": _vm.$t('views.booking.BookingForm.endTime') + ' *',
      "readonly": _vm.disabled,
      "type": "time"
    },
    model: {
      value: _vm.value.endTime,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "endTime", $$v);
      },
      expression: "value.endTime"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": _vm.$t('views.booking.BookingForm.notes'),
      "readonly": _vm.disabled,
      "hint": _vm.$t('views.booking.BookingForm.notesHint'),
      "persistent-hint": ""
    },
    model: {
      value: _vm.value.bookingInformation.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.value.bookingInformation, "notes", $$v);
      },
      expression: "value.bookingInformation.notes"
    }
  })], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }