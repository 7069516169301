



















import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
  filters: { simpleDoubleDigitDate }
})
export default class EventRecurring extends Vue {
  @Prop()
  value!: IEventUIDto;
}
