







import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TrashCanIcon extends Vue {
  @Prop()
  disabled!: boolean;
}
