































































import { Component, Prop } from "vue-property-decorator";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import { FormEntity } from "@/models/formEntity";
import { CalendarEvent } from "@/lib/utility/calendarEvent";
import { IReport } from "@/models/report.entity";

@Component({
  components: { ContextMenu }
})
export default class DashboardCalendar extends DarkModeHighlightMixin {
  @Prop()
  reports!: IReport[];

  @Prop()
  forms!: FormEntity[];

  value = "";

  isReportDatePreference = true;
  isReportCreated = true;

  selectedEvent: Partial<CalendarEvent<IReport>> | null = null;

  get i18n() {
    return this.$t("components.dashboard.DashboardCalendar");
  }

  get events() {
    const events: CalendarEvent<IReport>[] = [];

    if (this.isReportCreated) {
      events.push(...this.mapReportsToEvents({ dateType: "created", color: "purple" }));
    }

    if (this.isReportDatePreference) {
      events.push(...this.mapReportsToEvents({ dateType: "preference", color: "blue" }));
    }

    return events;
  }

  mapReportsToEvents(eventSettings: { dateType: "created" | "preference"; color: string }): CalendarEvent<IReport>[] {
    return this.reports.map(report => {
      const startDate =
        eventSettings.dateType === "created" ? new Date(report.timestamps?.created) : new Date(report.datePreference);

      return {
        name: report.customerName,
        start: startDate,
        timed: true,
        data: [report]
      };
    });
  }

  prevMonth() {
    (this.$refs.calendar as any)?.prev();
  }

  nextMonth() {
    (this.$refs.calendar as any)?.next();
  }

  onEventClick(calendarEvent: { event: CalendarEvent<IReport> }) {
    this.selectedEvent = calendarEvent?.event;
  }

  goToReport(report: Partial<IReport>) {
    this.$router.push({ path: `/partners/${report?.partnerId}/report/${report?._id}?from=home` });
  }

  clearSelectedEvent() {
    this.selectedEvent = null;
  }
}
