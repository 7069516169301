import { AinonymizerHttpClientProvider } from "../ainonymizer-http-client.provider";
import { Anonymization } from "../v1/Anonymization";
import {
  AinonymizerAnonymizationControllerGetParamsGen,
  AinonymizerCreateAnonymizationConfigDtoGen,
  AinonymizerUpdateAnonymizationDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with credential API
 */
class AnonymizationService {
  proxy: Anonymization;

  /**
   * Construct service
   */
  constructor(httpClientProvider: AinonymizerHttpClientProvider) {
    this.proxy = new Anonymization(httpClientProvider.client());
  }

  /**
   * Create an credential
   */
  async create(partnerId: string, data: AinonymizerCreateAnonymizationConfigDtoGen) {
    const c = (await this.proxy.anonymizationControllerCreate(partnerId, data)).data;

    return c;
  }

  /**
   * Get all credentials
   */
  async get(data: AinonymizerAnonymizationControllerGetParamsGen) {
    const c = (await this.proxy.anonymizationControllerGet(data)).data;

    return c;
  }

  /**
   * Get a credential
   */
  async getOne(partnerId: string, id: string) {
    const c = (await this.proxy.anonymizationControllerGetOne(partnerId, id)).data;

    return c;
  }

  /**
   * Update a credential
   */
  async update(partnerId: string, id: string, data: AinonymizerUpdateAnonymizationDtoGen) {
    const c = (await this.proxy.anonymizationControllerUpdateOne(partnerId, id, data)).data;

    return c;
  }
}

/**
 * Communicate with anonymization API
 */
export default new AnonymizationService(new AinonymizerHttpClientProvider());
