





























import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import * as XLSX from "xlsx";

/**
 * For excample usage see ExcelImportPlayground
 */
@Component({ components: { ConfirmActionDialog } })
export default class ExcelImportReport extends Vue {
  @Prop()
  totalItems!: number;

  @Prop()
  importedItems!: any[];

  @Prop()
  failedImports!: any[];

  createErrorReport() {
    const worksheet = XLSX.utils.json_to_sheet(this.failedImports);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Fehlerbericht");
    XLSX.writeFile(workbook, "import-error" + new Date().toISOString() + ".xlsx", {
      compression: true
    });
  }
}
