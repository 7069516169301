















import { Component, Vue } from "vue-property-decorator";
import FahrzeugscheinDetail from "@/components/fahrzeugschein/FahrzeugscheinDetail.vue";
import FahrzeugscheinImage from "@/components/fahrzeugschein/FahrzeugscheinImage.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";

@Component({
  components: {
    LayoutSimple,
    FahrzeugscheinDetail,
    FahrzeugscheinImage
  }
})
export default class FahrzeugscheinComponent extends Vue {}
