












import ReportImageGallery from "@/components/report/ReportImageGallery.vue";
import FileDownload from "@/components/utility/FileDownload.vue";
import { mapBaseImageToDownloadFile } from "@/lib/utility/map-base-image-to-download-file";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IBaseImage } from "@/models/caseEntity";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { IDownloadFile } from "../utility/interface/download-file.interface";

@Component({
  components: {
    ReportImageGallery,
    FileDownload,
    Card
  }
})
export default class PartnerFormDetailImageCard extends DarkModeHighlightMixin {
  @Prop({})
  images!: IBaseImage[];

  @Prop({})
  registration!: IBaseImage;

  get downloadableFiles(): IDownloadFile[] {
    const imagesToDownload: IDownloadFile[] = [];

    if (this.registration) {
      mapBaseImageToDownloadFile(imagesToDownload, [this.registration], "fahrzeugschein");
    }
    if (this.images) {
      mapBaseImageToDownloadFile(imagesToDownload, this.images, "anhang");
    }

    return imagesToDownload;
  }

  get mapToImage() {
    const images: any[] = [];
    if (this.registration) {
      this.imageMapper(images, [this.registration], "Fahrzeugschein");
    }
    if (this.images) {
      this.imageMapper(images, this.images, "Anhang");
    }

    return images;
  }

  private imageMapper(imageItemList: any[], reportImages: IBaseImage[], title: string) {
    for (const image of reportImages) {
      const item = {
        src: image.url,
        thumbnail: image.url,
        w: 0,
        h: 0,
        title: title
      };
      imageItemList.push(item);
    }

    return imageItemList;
  }
}
