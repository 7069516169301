export enum FleetTabs {
  HOME = "home",
  ITINERARY = "itinerary",
  VEHICLE = "vehicle",
  TICKET = "ticket",
  COST = "cost",
  EVENT = "event",
  DRIVER = "driver",
  POOL = "pool",
  ANALYTICS = "analytics",
  HANDOVER = "handover"
}
