/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCustomerAccountControllerFindAllByPartnerIdParamsGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerCustomerUserViewModelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class CustomerAccountService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Customer Account Service
   * @name CustomerAccountControllerFindAllByPartnerId
   * @summary Gets all customer accounts for partner
   * @request GET:/partner/{partnerId}/customer-account
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPartnerCustomerUserViewModelGen)[] })`
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  customerAccountControllerFindAllByPartnerId = (
    { partnerId, ...query }: MrfiktivCustomerAccountControllerFindAllByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivPartnerCustomerUserViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/customer-account`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Customer Account Service
   * @name CustomerAccountControllerFindOneByUserId
   * @summary Gets a customer account by customer account id
   * @request GET:/partner/{partnerId}/customer-account/{id}
   * @secure
   * @response `200` `MrfiktivPartnerCustomerUserViewModelGen` Customer account returned
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  customerAccountControllerFindOneByUserId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerCustomerUserViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/customer-account/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
