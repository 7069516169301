var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "imageContainer",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-img', {
    staticClass: "fahrzeugscheinImage zoomOff",
    attrs: {
      "max-height": "800",
      "contain": "",
      "lazy-src": require("@/assets/registration_form_schema.svg"),
      "src": _vm.fahrzeugscheinImage
    },
    on: {
      "click": _vm.zoomImage
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "fill-height ma-0",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-progress-circular', {
          attrs: {
            "indeterminate": "",
            "color": "grey lighten-5"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "fab": "",
      "rounded": ""
    },
    on: {
      "click": _vm.rotateImage
    }
  }, [_c('v-icon', [_vm._v("mdi-crop-rotate")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }