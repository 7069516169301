


































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IAnonymization } from "@/models/anonymization.entity";
import { saveAs } from "file-saver";
import JsZip, { JSZipGeneratorOptions } from "jszip";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class AnonymizationDownloadDialog extends DarkModeHighlightMixin {
  @Prop()
  items!: IAnonymization[];

  dialog = false;

  /**
   * If a download failes it is stored in failedDownloads to give the user feedback of it.
   */
  failedDownloads: { item: IAnonymization; type: string; reason: any }[] = [];

  /**
   * Downloadoptions: Specifies wether the zip should be compressed or not
   */
  compression = true;

  /**
   * Flag to abort the download
   */
  stopDownload = false;

  /**
   * Flag to say that the download is started
   */
  isDownloadStarted = false;

  /**
   * Progress of the Download (progress 10 means that 10 images are downloaded)
   */
  progress = 0;

  /**
   * Specifies how many images have to be downloaded in total
   */
  numberOfDownloads = 0;

  /**
   * Is calculatet by progress / numberofDownloads * 100
   */
  relativeProgress = 0;

  /**
   * Flag to specify if the download is finished
   */
  isFinished = true;

  /**
   * Flag to specify if the download is finished
   */
  isCreatingZip = false;

  /**
   * Message to give the user more details about the download
   */
  message = "";

  /**
   * Headers for the table (error store)
   */
  get headers() {
    return [
      { text: "ID", value: "item.id" },
      { text: "Type", value: "type" },
      { text: "Ursache", value: "reason" }
    ];
  }

  /**
   * Set Default values
   */
  initialize() {
    this.progress = 0;
    this.numberOfDownloads = 0;
    this.relativeProgress = 0;
    this.message = "";
    this.isDownloadStarted = false;
    this.isFinished = false;
    this.failedDownloads = [];
  }

  /**
   * Stops the download on trigger
   */
  abortDownload() {
    this.stopDownload = true;
    this.initialize();
  }

  /**
   * Downloads registration Documents as images and zip them using this.saveZip
   */
  async downloadImages() {
    this.message = "Initialize Download";
    this.isDownloadStarted = true;
    this.isFinished = false;
    let images: { name: string; data: Blob }[] = [];
    let batchSize = 0;

    this.numberOfDownloads = this.items.length;
    for (const item of this.items) {
      if (this.stopDownload == true) {
        break;
      }
      try {
        this.message = "Download image from item with id: " + item.id;
        await item.fetch();

        if (!item?.anonymizedImage?.url) {
          this.failedDownloads.unshift({ item: item, type: "warning", reason: "No image found" });
          this.updateRelativeProgress();
          continue;
        }

        const resp = await fetch(item?.anonymizedImage?.url).then(resp => resp);
        if (resp.status >= 300) {
          this.failedDownloads.unshift({
            item: item,
            type: "warning",
            reason: resp.status + ": " + resp.statusText
          });
          this.updateRelativeProgress();
          continue;
        }
        images.push({ name: item.downloadFileName, data: await resp.blob() });
      } catch (error) {
        this.failedDownloads.unshift({ item: item, type: "error", reason: error });
        this.updateRelativeProgress();

        continue;
      }

      batchSize = batchSize + 1;
      this.updateRelativeProgress();
    }

    await this.saveZip(images);
    images = [];
    batchSize = 0;
    this.$log.info("Saved zip.");
    this.isCreatingZip = false;

    this.isFinished = true;
    this.message = "Download finished.";
  }

  /**
   *
   * Creates a zip file
   *
   * @param files
   */
  async saveZip(files: { name: string; data: Blob }[]) {
    this.isCreatingZip = true;
    this.message = "Create Zip and save to device.";
    const zip = JsZip();

    for (const file of files) {
      zip.file(file.name, file.data);
    }
    const options: JSZipGeneratorOptions<"blob"> = {
      type: "blob"
    };

    if (this.compression) {
      options["compression"] = "DEFLATE";
      options["compressionOptions"] = { level: 9 };
    }

    const zipBlob = await zip.generateAsync(options);
    const currentDate = new Date().toISOString();
    const fileName = `images-${currentDate}.zip`;

    return saveAs(zipBlob, fileName);
  }

  /**
   * updates the relative progress
   */
  updateRelativeProgress() {
    this.progress = this.progress + 1;
    this.relativeProgress = (this.progress / this.numberOfDownloads) * 100;
  }

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
    this.initialize();
  }
}
