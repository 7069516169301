import { render, staticRenderFns } from "./FahrzeugscheinImage.vue?vue&type=template&id=33991beb&scoped=true&"
import script from "./FahrzeugscheinImage.vue?vue&type=script&lang=ts&"
export * from "./FahrzeugscheinImage.vue?vue&type=script&lang=ts&"
import style0 from "./FahrzeugscheinImage.vue?vue&type=style&index=0&id=33991beb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33991beb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VContainer,VIcon,VImg,VProgressCircular,VRow})
