var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('excel-import', {
    attrs: {
      "config": _vm.config,
      "btnText": _vm.text || _vm.$t('update'),
      "loading": _vm.loadingImport,
      "outlined": _vm.outlined,
      "small": _vm.small
    },
    on: {
      "uploadData": _vm.importData
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs,
            reset = _ref.reset;
        return [_vm._t("activator", null, null, {
          on: on,
          attrs: attrs,
          reset: reset
        })];
      }
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var data = _ref2.data;
        return [_c('v-alert', {
          staticClass: "ma-4",
          attrs: {
            "value": true,
            "type": "success",
            "border": "left"
          }
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "grow"
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingCreateBatch.success", {
          success: _vm.dataImports.length,
          all: data.length
        })) + " ")])], 1)], 1), _vm.failedImports.length ? _c('v-alert', {
          staticClass: "ma-4",
          attrs: {
            "value": true,
            "type": "error",
            "border": "left"
          }
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "grow"
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgMeterReadingCreateBatch.error", {
          value: _vm.failedImports.length
        })) + " ")])], 1)], 1) : _vm._e()];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }