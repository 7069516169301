import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IWebhookConfig, WebhookConfig } from "@/models/webhook-config.entity";

export const WebhookConfigDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IWebhookConfig> {
  protected mapEntity(entity: IWebhookConfig): IWebhookConfig {
    return new WebhookConfig(entity);
  }

  getIdentifier(entity: IWebhookConfig): string {
    return entity.id;
  }

  mapsConfig = {};
})();
