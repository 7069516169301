import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { handleError } from "@/lib/utility/handleError";
import resourceService from "@/services/booking/services/resourceService";
import { BookingResourceViewModelGen } from "@/services/booking/v1/data-contracts";
import { Availability, IAvailability } from "./service.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { ResourceDataAccessLayer } from "@/store/modules/access-layers/resource.access-layer";

@IsFilterable
class ResourceBase implements IEntity<IResource>, BookingResourceViewModelGen {
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.values.id"
  })
  get _id() {
    return this.id;
  }

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.values.name"
  })
  name: string;

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.values.isActive"
  })
  isActive: boolean;
  partnerId: string;
  timestamp: ITimestamp;
  availability: IAvailability[];

  constructor(data?: Partial<BookingResourceViewModelGen>) {
    this.id = data?.id ?? "";
    this.partnerId = data?.partnerId ?? "";
    this.name = data?.name ?? "";
    this.isActive = data?.isActive ?? false;
    this.timestamp = new Timestamp(data?.timestamp);
    this.availability = (data?.availability ?? []).map(a => new Availability(a));
  }

  private map(data?: BookingResourceViewModelGen) {
    this.id = data?.id ?? this.id;
    this.partnerId = data?.partnerId ?? "";
    this.name = data?.name ?? "";
    this.isActive = data?.isActive ?? false;
    this.timestamp = new Timestamp(data?.timestamp);
    this.availability = (data?.availability ?? []).map(a => new Availability(a));
  }

  async create(): Promise<this> {
    try {
      const resource = await resourceService.create(this.partnerId, {
        availability: this.availability,
        isActive: this.isActive,
        name: this.name
      });

      this.map(resource);

      ResourceDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async fetch(): Promise<this> {
    try {
      const resource = await resourceService.findResourceByPartner(this.partnerId, this.id);

      this.map(resource);

      ResourceDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async delete(): Promise<void> {
    try {
      const service = await resourceService.removeResource(this.partnerId, this.id);

      this.map(service);

      ResourceDataAccessLayer.delete(this);
    } catch (e) {
      handleError(e);
    }
  }

  async replaceOneByPartnerId(): Promise<this> {
    try {
      const resource = await resourceService.replaceOneByPartnerId(this.partnerId, this.id, {
        availability: this.availability,
        isActive: this.isActive,
        name: this.name
      });

      this.map(resource);

      ResourceDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }
}

type IResource = ResourceBase;
const Resource = Filter.createForClass(ResourceBase);

export { IResource, Resource };
