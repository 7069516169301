import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IWebhookConfig, WebhookConfig } from "@/models/webhook-config.entity";
import webhookConfigService from "@/services/ainonymizer/services/webhook-config.service";
import { MrfiktivWebhookConfigControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { WebhookConfigDataAccessLayer } from "./access-layers/webhook-config.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

const WebhookConfigPageDataProvider = new (class extends AbstractPageDataProvider<
  IWebhookConfig,
  MrfiktivWebhookConfigControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivWebhookConfigControllerGetParamsGen): Promise<IPageViewModel<IWebhookConfig>> {
    const res = await webhookConfigService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IWebhookConfig[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "webhookConfig",
  store
})
export class WebhookConfigPaginationStore extends PaginatedBaseStore<
  IWebhookConfig,
  MrfiktivWebhookConfigControllerGetParamsGen
> {
  protected _data = WebhookConfigDataAccessLayer;
  protected _pageProvider = WebhookConfigPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = WebhookConfig.filterables;
}

export const WebhookConfigModule = getModule(WebhookConfigPaginationStore);
