/**
 * Enum to control transparency for HEX colors.
 * This is added at the end of the color, like so
 * #FFB3CB99 <- 99 at the end means 60% transparency
 * 100% — FF
 * 95% — F2
 * 90% — E6
 * 85% — D9
 * 80% — CC
 * 75% — BF
 * 70% — B3
 * 65% — A6
 * 60% — 99
 * 55% — 8C
 * 50% — 80
 * 45% — 73
 * 40% — 66
 * 35% — 59
 * 30% — 4D
 * 25% — 40
 * 20% — 33
 * 15% — 26
 * 10% — 1A
 * 5% — 0D
 * 0% — 00
 */
export enum HexTransparency {
  Full = "FF",
  NinetyFive = "F2",
  Ninety = "E6",
  EightyFive = "D9",
  Eighty = "CC",
  SeventyFive = "BF",
  Seventy = "B3",
  SixtyFive = "A6",
  Sixty = "99",
  FiftyFive = "8C",
  Fifty = "80",
  FortyFive = "73",
  Forty = "66",
  ThirtyFive = "59",
  Thirty = "4D",
  TwentyFive = "40",
  Twenty = "33",
  Fifteen = "26",
  Ten = "1A",
  Five = "0D",
  Zero = "00"
}
