



















































































































import Debug from "@/components/utility/Debug.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import { Component } from "vue-property-decorator";
import ActivityCard from "../thg/ActivityCard.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsSelected from "../utility/RefsSelected.vue";
import RefsUser from "../utility/RefsUser.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailTable from "../utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IAlert } from "../utility/mmmint/MHeader.vue";
import EventCard from "./EventCard.vue";
import MDetailForm from "../utility/mmmint/MDetailForm.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { setNestedObjectValues } from "@/lib/objectPath-helper";
import EventRecurring from "./EventRecurring.vue";
import { IMDetailFormConfig, MDetailFormConfig } from "@/lib/formable";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";

enum KEYS {
  SUMMARY = "summary",
  DESCRIPTION = "description",
  START_DATE = "startString",
  FREQUENCY = "ruleOptions.freq",
  INTERVAL = "ruleOptions.interval",
  LIMIT = "limit",
  COUNT = "ruleOptions.count",
  UNTIL = "untilString"
}

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    MActionList,
    MDetailTable,
    MDetailForm,
    ActivityCard,
    RefsSelected,
    RefsUser,
    ConfirmActionDialog,
    Debug,
    EventRecurring
  },
  filters: { simpleDoubleDigitDate }
})
export default class EventForm extends EventCard {
  isWarnRecurringChangeDialog = false;

  get recurring() {
    return {
      text: $t("project.ticket.actions.toOverview"),
      key: this.DETAIL,
      icon: "mdi-open-in-new"
    };
  }

  get config(): IMDetailFormConfig[] {
    const config: IMDetailFormConfig[] = [
      {
        category: $t("common.nouns.appointment"),
        key: KEYS.SUMMARY,
        type: DetailFormComponentsEnum.TEXT_FIELD,
        searchKeywords: [$t("views.fleet.EventCreateDialog.summary"), $t("common.nouns.appointment")],
        model: this.value.summary,
        props: {
          label: $t("views.fleet.EventCreateDialog.summary"),
          rules: this.required
        }
      },
      {
        category: $t("common.nouns.appointment"),
        key: KEYS.DESCRIPTION,
        type: DetailFormComponentsEnum.TEXT_AREA,
        searchKeywords: [$t("views.fleet.EventCreateDialog.description"), $t("common.nouns.appointment")],
        model: this.value.description,
        props: {
          label: $t("views.fleet.EventCreateDialog.description")
        }
      },
      {
        category: $t("common.nouns.appointment"),
        key: KEYS.START_DATE,
        type: DetailFormComponentsEnum.TEXT_FIELD,
        searchKeywords: [$t("views.fleet.EventCreateDialog.start"), $t("common.nouns.appointment")],
        model: this.value.startString,
        props: {
          label: $t("views.fleet.EventCreateDialog.start"),
          rules: this.required,
          type: "date",
          disabled: this.value.isRecurring && !this.value.isVirtual
        }
      }
    ];

    if (this.value.isRecurring && this.value.ruleOptions) {
      config.push(
        {
          category: $t("components.fleet.FleetHomeCalendar.recurring"),
          key: KEYS.FREQUENCY,
          type: DetailFormComponentsEnum.SELECT_FIELD,
          searchKeywords: [
            $t("views.fleet.EventCreateDialog.recurring"),
            $t("components.fleet.FleetHomeCalendar.recurring")
          ],
          model: this.value.ruleOptions.freq,
          props: {
            label: $t("views.fleet.EventCreateDialog.recurring"),
            rules: this.required,
            disabled: this.value.isRecurring && !this.value.isVirtual,
            items: this.value.freqItems,
            itemText: "text",
            itemValue: "value"
          }
        },
        {
          category: $t("components.fleet.FleetHomeCalendar.recurring"),
          key: KEYS.INTERVAL,
          type: DetailFormComponentsEnum.SELECT_FIELD,
          searchKeywords: [
            $t("views.fleet.EventCreateDialog.interval"),
            $t("components.fleet.FleetHomeCalendar.recurring")
          ],
          model: this.value.ruleOptions.interval,
          props: {
            label: $t("views.fleet.EventCreateDialog.interval"),
            rules: this.required,
            disabled: this.value.isRecurring && !this.value.isVirtual,
            items: Array.from({ length: 99 }, (v, i) => i + 1)
          }
        }
      );

      config.push({
        category: $t("components.fleet.FleetHomeCalendar.recurring"),
        key: KEYS.LIMIT,
        type: DetailFormComponentsEnum.SELECT_FIELD,
        searchKeywords: [
          $t("views.fleet.EventCreateDialog.recurringEnd"),
          $t("components.fleet.FleetHomeCalendar.recurring")
        ],
        model: this.value.limit,
        props: {
          label: $t("views.fleet.EventCreateDialog.recurringEnd"),
          rules: this.required,
          items: this.value.repeatItems,
          itemText: "text",
          itemValue: "value"
        }
      });

      config.push({
        category: $t("components.fleet.FleetHomeCalendar.recurring"),
        key: KEYS.COUNT,
        type: DetailFormComponentsEnum.SELECT_FIELD,
        searchKeywords: [
          $t("views.fleet.EventCreateDialog.recurringRepeats"),
          $t("components.fleet.FleetHomeCalendar.recurring")
        ],
        model: this.value.ruleOptions.count,
        props: {
          label: $t("views.fleet.EventCreateDialog.recurringRepeats"),
          rules: this.required,
          disabled: this.value.isRecurring && !this.value.isVirtual,
          items: Array.from({ length: 99 }, (v, i) => i + 1)
        },
        condition: {
          key: KEYS.LIMIT,
          value: "count"
        }
      });

      config.push({
        category: $t("components.fleet.FleetHomeCalendar.recurring"),
        key: KEYS.UNTIL,
        type: DetailFormComponentsEnum.TEXT_FIELD,
        searchKeywords: [
          $t("views.fleet.EventCreateDialog.recurringEnd"),
          $t("components.fleet.FleetHomeCalendar.recurring")
        ],
        model: this.value.untilString,
        props: {
          label: $t("views.fleet.EventCreateDialog.recurringEnd"),
          rules: this.required,
          disabled: this.value.isRecurring && !this.value.isVirtual,
          type: "date"
        },
        condition: {
          key: KEYS.LIMIT,
          value: "until"
        }
      });
    }

    return config;
  }

  get required() {
    return [requiredRule()];
  }

  get breadCrumbs() {
    const breadCrumbs = super.baseCrumbs;

    breadCrumbs.push({
      text: this.value.isRecurringRoot ? $t("common.nouns.series") : $t("common.nouns.appointment"),
      disabled: false,
      exact: true,
      to: {
        name: "EventDetailView",
        params: { partnerId: this.partner.id, eventId: this.value.id as string }
      }
    });

    breadCrumbs.push({
      text: $t("common.nouns.detail"),
      disabled: true,
      exact: true,
      to: {
        name: "EventDetailView",
        params: { partnerId: this.partner.id, eventId: this.value.id as string }
      }
    });

    return breadCrumbs;
  }

  get actions() {
    const actions: IAction[] = [];

    if (!this.value.isRecurringRoot && !this.value.ack) {
      actions.push(this.actionRecord.acknowledge);
    }
    if (this.value.recurringEventId) {
      actions.push(this.actionRecord.viewRecurring);
    }
    actions.push(this.recurring);
    actions.push(this.actionRecord.deleteEvent);

    return actions;
  }

  get alerts(): IAlert[] {
    const breadcrumbs: IAlert[] = [];

    if (!this.value.isRecurringRoot && !this.value.ack && this.isOverdue) {
      breadcrumbs.push({
        text: $t("overdue"),
        type: "error",
        action: this.actionRecord.acknowledge
      });
    }

    if (this.value.isRecurringRoot) {
      breadcrumbs.push({
        text: $t("views.fleet.EventCard.recurringChangeWarningInCaseOfChanges"),
        type: "warning"
      });
    }

    return breadcrumbs;
  }

  abortChanges() {
    const config = new MDetailFormConfig(this.config);
    for (const key of Object.values(KEYS)) {
      config.configValueByKey = { key: key, value: this.value[key] };
    }
  }

  syncChanges() {
    const config = new MDetailFormConfig(this.config);
    for (const key of Object.values(KEYS)) {
      setNestedObjectValues(this.value, key, config.getConfigValueByKey(key));
    }

    // Setting limit last removes values that are not important for the limit (e.g. Until needs no count)
    this.value.limit = config.getConfigValueByKey(KEYS.LIMIT);

    this.$emit("input", this.value);
    this.isWarnRecurringChangeDialog = false;
  }

  trySyncChanges() {
    if (this.value.isRecurring) {
      this.isWarnRecurringChangeDialog = true;
    } else {
      this.syncChanges();
    }
  }
}
