/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * The keys of the settings for the dealer desk setup
 */
export enum DealerDeskSettingsKeys {
  /**
   * Setting to enable or disable the automatic import
   */
  AUTO_IMPORT = "DEALERDESK_AUTO_IMPORT",

  /**
   * The dealer desk email that a report should be sent to
   * @example dealer@desk.deal
   */
  MAIL = "DEALERDESK_MAIL"
}
