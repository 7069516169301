













































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import ProjectForm from "@/components/project/ProjectForm.vue";
import TicketDetailCard from "@/components/project/TicketDetailCard.vue";
import CustomFieldConfigurationForm from "@/components/report/CustomFieldConfigurationForm.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { MrfiktivCustomFieldListElementViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ProjectCrudMixin from "./mixins/ProjectCrudMixin.vue";
import ProjectGoToMixin from "./mixins/ProjectGoToMixin.vue";
import ProjectInitializeListsMixin from "./mixins/ProjectInitializeListsMixin.vue";
import { Project } from "@/models/project.entity";
import { CustomFieldListElement } from "@/models/custom-field-list-element.entity";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: {
    MHeader,
    TheLayoutPortal,
    TicketDetailCard,
    Debug,
    LatestEntriesCardEmpty,
    ProjectForm,
    MDetailViewGrid,
    MActionList,
    CustomFieldConfigurationForm,
    ConfirmActionDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ProjectDetailView extends mixins(
  RulesMixin,
  ProjectInitializeListsMixin,
  ProjectGoToMixin,
  ProjectCrudMixin,
  PartnerFallbackMixin,
  DarkModeHighlightMixin
) {
  readonly DELETE_ACTION = "deleteAction";
  readonly VIEW_TICKETS = "viewTickets";
  readonly VIEW_PROJECTS = "viewProjects";
  readonly EDIT_ACTION = "editAction";

  isEditTitle = false;
  isEditDescription = false;
  isEditCustomFields = false;
  isDeleteDialog = false;
  isCustomFieldValid = true;

  isUpdateDescription = false;
  isUpdateCustomFieldConfig = false;
  isUpdateTitle = false;

  projectTitle = "";
  projectDescription = "";
  projectCustomFieldConfig: MrfiktivCustomFieldListElementViewModelGen[] = [];

  get createdDate() {
    if (!this.project?.timestamp.created) return "";
    return simpleDoubleDigitDate(this.project?.timestamp.created);
  }

  get subtitle() {
    if (this.isEditTitle) {
      return "";
    }
    if (!this.project) {
      return "...";
    }

    return this.$t("project.project.createdOn", { date: simpleDoubleDigitDate(this.project.timestamp.created) });
  }

  get breadCrumbList() {
    return [
      this.breadCrumbs.ProjectTable(this.partnerId),
      this.breadCrumbs.ProjectDetail(this.partnerId, this.projectId),
      this.breadCrumbs.ProjectDetailSetting(this.partnerId, this.projectId)
    ];
  }

  get detailActions() {
    return [
      {
        text: this.$t("project.project.viewProjectTickets").toString(),
        key: this.VIEW_TICKETS,
        icon: "mdi-open-in-new"
      },
      {
        text: this.$t("project.project.viewProjects").toString(),
        key: this.VIEW_PROJECTS,
        icon: "mdi-open-in-new"
      },
      {
        text: this.$t("project.project.delete").toString(),
        key: this.DELETE_ACTION,
        icon: "mdi-delete-outline",
        color: "red"
      }
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push({
      text: this.$t("project.ticket.editTitle").toString(),
      key: "title",
      icon: "mdi-pencil",
      color: "",
      exec: this.startUpdateProjectTitle
    });

    return actions;
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
    this.projectId = this.$route.params.projectId;
  }

  async mounted() {
    this.$log.debug("initializeProjectLists@ProjectDetailView.vue");
    this.loadingProject = true;
    await this.trySetByRouteOrDefault();
    await this.initializeProjectLists(this.partnerId);
    await this.getProject(this.projectId);
  }

  async processAction(action: IAction) {
    switch (action.key) {
      case this.DELETE_ACTION:
        this.startDeleteProject();
        break;
      case this.VIEW_TICKETS:
        this.goTo.projectCustomView({ partnerId: this.partnerId, projectId: this.projectId, viewId: "0" });
        break;
      case this.VIEW_PROJECTS:
        this.goTo.projectTable({ partnerId: this.partnerId });
        break;
    }
  }

  startDeleteProject() {
    this.isDeleteDialog = true;
  }

  startUpdateProjectTitle() {
    if (!this.project) return;
    this.projectTitle = this.project.title;
    this.isEditTitle = true;
  }

  startUpdateSubtitle() {
    if (!this.project) return;
    this.projectDescription = this.project.description ?? "";
    this.isEditDescription = true;
  }

  startUpdateCustomFields() {
    if (!this.project) return;
    this.projectCustomFieldConfig.splice(0);
    this.projectCustomFieldConfig.push(...this.project.configuration.customFieldConfig);
    this.isEditCustomFields = true;
  }

  cancelUpdateCustomFields() {
    if (!this.project) return;
    this.projectCustomFieldConfig.splice(0);
    this.projectCustomFieldConfig.push(...this.project.configuration.customFieldConfig);
    this.isEditCustomFields = false;
  }

  cancelUpdateTitle() {
    if (!this.project) return;
    this.projectTitle = this.project.title;
    this.isEditTitle = false;
  }

  cancelUpdateDescription() {
    if (!this.project) return;
    this.projectDescription = this.project.description ?? "";
    this.isEditDescription = false;
  }

  async updateProjectTitle() {
    if (!this.project) return;
    this.isUpdateTitle = true;
    await this.updateProject(new Project({ ...(this.project ?? {}), title: this.projectTitle }));
    this.project.title = this.projectTitle;
    this.isEditTitle = false;
    this.isUpdateTitle = false;
  }

  async updateProjectDescription() {
    if (!this.project) return;
    this.isUpdateDescription = true;
    await this.updateProject(new Project({ ...(this.project ?? {}), description: this.projectDescription }));
    this.project.description = this.projectDescription;
    this.isEditDescription = false;
    this.isUpdateDescription = false;
  }

  async updateCustomFields() {
    if (!this.project) return;
    this.isUpdateCustomFieldConfig = true;
    await this.updateProject(
      new Project({
        ...(this.project ?? {}),
        configuration: { ...this.project.configuration, customFieldConfig: this.projectCustomFieldConfig }
      })
    );
    this.project.configuration.customFieldConfig.splice(0);
    this.project.configuration.customFieldConfig.push(
      ...this.projectCustomFieldConfig.map(c => new CustomFieldListElement(c))
    );
    this.isEditCustomFields = false;
    this.isUpdateCustomFieldConfig = false;
  }

  async onDeleteProject() {
    if (!this.project) return;
    await this.deleteProject(this.project);
    this.goTo.projectTable({ partnerId: this.partnerId });
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.partnerId);
  }
}
