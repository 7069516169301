






import PartnerManageStepper from "@/components/partner/PartnerManageStepper.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { IPartnerManageStepperStep } from "@/lib/interfaces/partner/partnerManageStepperStep.interface";
import { emailRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { DealerDeskSettingsKeys } from "@/store/enum/manage/dealer-desk-setting-keys.enum";
import { ManageStepperDescriptionTypes } from "@/store/enum/partner/manage-stepper-description-type.enum";
import { ManageStepperScreenTypes } from "@/store/enum/partner/manage-stepper-screen-types.enum";
import { SettingModule } from "@/store/modules/setting.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    PartnerManageStepper,
    LayoutSimple
  }
})
export default class extends Vue {
  mounted() {
    SettingModule.clearPartnerSetting();
  }

  /**
   * steps of DA Stepper
   */
  get steps(): IPartnerManageStepperStep[] {
    return [
      {
        type: ManageStepperScreenTypes.DESCRIPTION,
        descriptionType: ManageStepperDescriptionTypes.DEALER_DESK_SETUP_ASSISTANT
      },
      {
        type: ManageStepperScreenTypes.PREDEFINED_SETTING,
        key: DealerDeskSettingsKeys.MAIL,
        rules: [emailRule(), requiredRule()]
      },
      {
        type: ManageStepperScreenTypes.PREDEFINED_SETTING,
        key: DealerDeskSettingsKeys.AUTO_IMPORT,
        rules: [requiredRule()],
        items: ["true", "false"]
      },
      { type: ManageStepperScreenTypes.DESCRIPTION, descriptionType: ManageStepperDescriptionTypes.END }
    ];
  }
}
