var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ma-1"
  }, [_vm.vehicleAggregation.vehicle.state !== _vm.VehicleStateEnum.ACTIVE ? _c('v-alert', {
    staticClass: "my-4 mx-2",
    attrs: {
      "color": _vm.statusColor,
      "border": "left"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-alert")]), _vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state.".concat(_vm.vehicleAggregation.vehicle.state))) + " "), _c('v-spacer'), _vm.vehicleAggregation.vehicle.state === _vm.VehicleStateEnum.DELETED || _vm.vehicleAggregation.vehicle.state === _vm.VehicleStateEnum.INACTIVE ? _c('fleet-vehicle-detail-home-card-archived-dialog', {
    attrs: {
      "vehicle": _vm.vehicleAggregation.vehicle
    }
  }) : _vm._e()], 1)]) : _vm._e(), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.TICKET,
      "field": _vm.vehicleAggregation.vehicle.partnerId
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-card', {
    staticClass: "ma-2 pa-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "three-line": ""
    },
    on: {
      "click": _vm.goToTicket
    }
  }, [_c('v-list-item-content', [_c('div', {
    staticClass: "text-overline mb-4"
  }, [_vm._v(" " + _vm._s(_vm.i18n["tickets"]) + " ")]), _c('v-list-item-title', {
    staticClass: "text-h5 mb-1"
  }, [_vm._v(" " + _vm._s(_vm.ticketsCount) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTable.openTickets")) + " ")])], 1), _c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "alt": "Schäden",
      "src": require("@/assets/undraw_done_re_oak4.svg"),
      "contain": ""
    }
  })], 1)], 1)], 1)], 1)], 1), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.EVENT,
      "field": _vm.vehicleAggregation.vehicle.partnerId
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-card', {
    staticClass: "ma-2 pa-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "three-line": ""
    },
    on: {
      "click": _vm.goToEvent
    }
  }, [_c('v-list-item-content', [_c('div', {
    staticClass: "text-overline mb-4"
  }, [_vm._v(" " + _vm._s(_vm.i18n["events"]) + " ")]), _c('v-list-item-title', {
    staticClass: "text-h5 mb-1"
  }, [_vm._v(" " + _vm._s(_vm.eventsOverdueCount) + " ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.i18n["overdueEvents"]))])], 1), _c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "alt": "Schäden",
      "src": require("@/assets/undraw_booking_re_gw4j.svg"),
      "contain": ""
    }
  })], 1)], 1)], 1)], 1)], 1), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.REPORT,
      "field": _vm.vehicleAggregation.vehicle.partnerId
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "xl": "4"
    }
  }, [_c('v-card', {
    staticClass: "ma-2 pa-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "three-line": ""
    },
    on: {
      "click": _vm.goToReport
    }
  }, [_c('v-list-item-content', [_c('div', {
    staticClass: "text-overline mb-4"
  }, [_vm._v(" " + _vm._s(_vm.i18n["damages"]) + " ")]), _c('v-list-item-title', {
    staticClass: "text-h5 mb-1"
  }, [_vm._v(" " + _vm._s(_vm.reportCount) + " ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.i18n["openDamages"]))])], 1), _c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "alt": "Schäden",
      "src": require("@/assets/undraw_towing_6yy4.svg"),
      "contain": ""
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.nonExpiredContractDeadlines.length > 0 ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "ma-2 pa-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "three-line": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goToContract(undefined);
      }
    }
  }, [_c('v-list-item-content', [_c('div', {
    staticClass: "text-overline mb-4"
  }, [_vm._v(" " + _vm._s(_vm.i18n["contracts"]) + " ")]), _c('v-list-item-title', {
    staticClass: "text-h5 mb-1"
  }, [_vm._v(" " + _vm._s(_vm.simpleDate(_vm.nonExpiredContractDeadlines[_vm.nonExpiredContractDeadlines.length - 1].endDate)) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.i18n["contractTimeTitle"]) + " ")])], 1), _c('v-list-item-avatar', {
    attrs: {
      "tile": "",
      "size": "100"
    }
  }, [_c('v-img', {
    attrs: {
      "alt": "Schäden",
      "src": require("@/assets/undraw_terms_re_6ak4 (1).svg"),
      "contain": ""
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.MILEAGE,
      "field": _vm.vehicleAggregation.vehicle.partnerId
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fleet-vehicle-detail-home-card-mileage-statistic', {
    attrs: {
      "vehicle": _vm.vehicleAggregation.vehicle,
      "contractDeadlines": _vm.nonExpiredContractDeadlines
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }