
import { handleError } from "@/lib/utility/handleError";
import { IProject, Project } from "@/models/project.entity";
import { Component, Vue } from "vue-property-decorator";

/**
 * Mixin with stuff shared by views that display details of a ticket
 */
@Component({
  components: {},
  filters: {}
})
export default class ProjectCrudMixin extends Vue {
  project: IProject | null = null;

  loadingUpdate = false;
  loadingSideCard = false;
  loadingProject = false;

  partnerId = "";
  projectId = "";

  async getProject(projectId?: string) {
    if (!projectId) {
      projectId = this.projectId;
    }

    try {
      this.loadingProject = true;
      this.loadingSideCard = true;
      this.project = await new Project({ id: projectId, partnerId: this.partnerId }).fetch();
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingSideCard = false;
      this.loadingProject = false;
    }
  }

  /**
   * Method to updated project
   * @param {Project} project the information of the project to update
   */
  async updateProject(project: IProject) {
    try {
      this.project = await project.update();
      this.loadingSideCard = true;
      this.loadingUpdate = true;
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingSideCard = false;
      this.loadingUpdate = false;
    }
  }

  async deleteProject(project: IProject) {
    try {
      this.loadingSideCard = true;
      this.loadingUpdate = true;

      await project.delete();
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingSideCard = false;
      this.loadingUpdate = false;
    }
  }
}
