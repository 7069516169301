var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mb-6",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _vm._t("options"), _c('v-spacer'), _vm.isProgressShown ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-progress-circular', _vm._g({
          attrs: {
            "value": (_vm.currentStep - 1) / (_vm.totalSteps - 1) * 100,
            "color": "blue-grey"
          }
        }, on))];
      }
    }], null, false, 715337879)
  }, [_c('span', [_vm._v(_vm._s(_vm.currentStep) + " / " + _vm._s(_vm.totalSteps))])]) : _vm._e()], 2), _c('v-card-actions', [_vm._t("default")], 2), _c('v-flex', {
    staticClass: "mt-2",
    attrs: {
      "d-flex": ""
    }
  }, [_vm.leftFunction ? _c('span', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "color": "primary",
            "icon": "",
            "x-large": "",
            "outlined": ""
          },
          on: {
            "click": _vm.leftFunction
          }
        }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.leftButtonIcon))])], 1)];
      }
    }], null, false, 2446339126)
  }, [_c('span', [_vm._v(_vm._s(_vm.leftButtonLabel || _vm.$t("components.partner.PartnerManageStepper.StepLayout.previous")))])])], 1) : _vm._e(), _c('v-spacer'), _vm.centerButtonFunction ? _c('span', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "disabled": _vm.centerButtonDisabled,
            "color": "primary",
            "icon": "",
            "x-large": "",
            "outlined": ""
          },
          on: {
            "click": _vm.centerButtonFunction
          }
        }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.centerButtonIcon))])], 1)];
      }
    }], null, false, 265017067)
  }, [_c('span', [_vm._v(_vm._s(_vm.centerButtonLabel || _vm.$t("components.partner.PartnerManageStepper.StepLayout.refresh")))])])], 1) : _vm._e(), _c('v-spacer'), _vm.rightFunction ? _c('span', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "disabled": _vm.rightButtonDisabled,
            "color": _vm.isEndStep ? 'success' : 'primary',
            "icon": "",
            "x-large": "",
            "outlined": "",
            "data-test-right-button": ""
          },
          on: {
            "click": _vm.rightFunction
          }
        }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.isEndStep ? "mdi-check-circle-outline" : _vm.rightButtonIcon))])], 1)];
      }
    }], null, false, 168820039)
  }, [_c('span', [_vm._v(_vm._s(_vm.rightButtonLabel || _vm.isEndStep ? _vm.$t("components.partner.PartnerManageStepper.StepLayout.end") : _vm.$t("components.partner.PartnerManageStepper.StepLayout.continue")))])])], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }