
























import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { Component, Prop, Vue } from "vue-property-decorator";
import RefsEvent from "../utility/RefsEvent.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: {
    RefsEvent
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class EventCardRecurringList extends Vue {
  @Prop()
  value!: IEventUIDto;

  events: IEventUIDto[] = [];

  isLoading = false;

  timeFrame = {
    start: 0,
    end: 0
  };

  readonly timeFrameSize = 1000 * 60 * 60 * 24 * 180; // 180 days

  async mounted() {
    this.isLoading = true;
    this.timeFrame.start = this.value.start;
    this.timeFrame.end = this.timeFrame.start + this.timeFrameSize;
    this.events.push(...(await this.value.listRecurringEvents(this.timeFrame.start, this.timeFrame.end)));
    this.isLoading = false;
  }

  async loadMore() {
    this.isLoading = true;
    this.timeFrame.start += this.timeFrameSize;
    this.timeFrame.end = this.timeFrame.start + this.timeFrameSize;
    this.events.push(...(await this.value.listRecurringEvents(this.timeFrame.start, this.timeFrame.end)));
    this.isLoading = false;
  }

  goToEventDetail() {
    if (!this.value?.id) return;

    new GoToHelper(this.$router).goToEventDetail(this.value.partnerId, this.value.id, true);
  }
}
