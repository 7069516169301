var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "loading": _vm.loadingProjects || _vm.loadingSideCard,
      "controlElements": _vm.controlElements
    },
    on: {
      "click:row": _vm.goToProjectDetail
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('project-create-dialog', {
          ref: "projectCreateDialog",
          on: {
            "created": _vm.goToProjectDetail
          }
        })];
      },
      proxy: true
    }, {
      key: "item.title",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-list-item', {
          staticClass: "pl-0",
          attrs: {
            "two-line": ""
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "pa-0 text-truncate"
        }, [_vm._v(" " + _vm._s(item.title) + " ")]), !_vm.isMobile ? _c('v-list-item-subtitle', {
          staticClass: "pa-0 text-truncate",
          staticStyle: {
            "max-width": "450px"
          }
        }, [_vm._v(" " + _vm._s(item.description) + " ")]) : _vm._e()], 1)], 1)];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamp.created)) + " ")];
      }
    }])
  }, [_c('template', {
    slot: "onboarding"
  }, [_c('v-card', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "750px"
    },
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('project.project.noProjectsTitle'))
    }
  }), _c('v-spacer')], 1), _c('v-card-subtitle', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('project.project.noProjectDescriptionI'))
    }
  }), _c('a', {
    on: {
      "click": _vm.goToCustomFields
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('project.project.noProjectDescriptionII'))
    }
  })]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('project.project.noProjectDescriptionIII'))
    }
  })]), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.PROJECT
    }
  }, [_c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.$refs.projectCreateDialog.dialogActive = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("getStarted")) + " ")])], 1)], 1)], 1)], 1)], 2), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "rightText": _vm.$t('delete')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.deleteItem
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }