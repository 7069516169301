var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('div', {
    staticClass: "ms-16 mt-2"
  }), _c('v-sheet', {
    staticClass: "mb-3 mr-2",
    attrs: {
      "max-width": "450px"
    }
  }, [_c('v-sparkline', {
    attrs: {
      "value": _vm.lastMonthCount,
      "labels": _vm.lastMonthLabels,
      "color": _vm.sparkLineColor,
      "height": "100",
      "padding": "24",
      "stroke-linecap": "round"
    }
  })], 1), _c('p', {
    staticClass: "text-xs ms-7 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.cards.StatisticsSparkLineCard.p")) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }