var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('partner-manage-stepper-step-layout', {
    attrs: {
      "title": _vm.$t('components.partner.PartnerManageInitializeDialog.newSetting'),
      "currentStep": _vm.currentStep,
      "totalSteps": _vm.totalSteps,
      "leftButtonFunction": _vm.goToNext,
      "leftButtonLabel": _vm.$t('components.partner.PartnerManageStepper.StepLayout.skip'),
      "leftButtonIcon": "mdi-cancel",
      "centerButtonIcon": "mdi-plus",
      "centerButtonFunction": function centerButtonFunction() {
        return _vm.createSetting(_vm.addSetting);
      },
      "centerButtonLabel": _vm.$t('components.partner.PartnerManageStepper.StepLayout.addSetting'),
      "centerButtonDisabled": !_vm.formValid,
      "rightButtonFunction": _vm.nextStep
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "flat": "",
      "focusable": "",
      "value": _vm.currentSetting
    }
  }, _vm._l(_vm.settings, function (setting, i) {
    return _c('v-expansion-panel', {
      key: i,
      attrs: {
        "flat": ""
      }
    }, [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(_vm.title(i)) + " ")]), _c('v-expansion-panel-content', [_vm.loaded && i === _vm.currentSetting ? _c('v-form', {
      staticClass: "pa-3",
      staticStyle: {
        "width": "100%"
      },
      model: {
        value: _vm.formValid,
        callback: function callback($$v) {
          _vm.formValid = $$v;
        },
        expression: "formValid"
      }
    }, [_c('v-row', {
      staticClass: "mt-3 pb-2"
    }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageInitializeDialog.setKey")) + " ")]), _c('v-row', [_c('v-combobox', {
      attrs: {
        "rules": _vm.keyRules,
        "items": _vm.items,
        "append-icon": _vm.items.length === 0 ? '' : 'mdi-menu-down',
        "label": _vm.$t('components.partner.PartnerManageInitializeDialog.key'),
        "outlined": "",
        "clearable": "",
        "data-test-custom-key": ""
      },
      model: {
        value: _vm.key,
        callback: function callback($$v) {
          _vm.key = $$v;
        },
        expression: "key"
      }
    })], 1), _c('v-row', {
      staticClass: "pb-2"
    }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageInitializeDialog.setValue")) + " ")]), _c('v-row', [_c('v-text-field', {
      attrs: {
        "rules": _vm.valueRules,
        "label": _vm.$t('components.partner.PartnerManageInitializeDialog.value'),
        "outlined": "",
        "clearable": "",
        "data-test-custom-value": ""
      },
      model: {
        value: _vm.value,
        callback: function callback($$v) {
          _vm.value = $$v;
        },
        expression: "value"
      }
    })], 1), _c('v-row', [_c('v-checkbox', {
      staticClass: "align-self-center ma-0",
      attrs: {
        "label": _vm.$t('components.partner.PartnerManageInitializeDialog.setEncryption')
      },
      model: {
        value: _vm.isEncrypted,
        callback: function callback($$v) {
          _vm.isEncrypted = $$v;
        },
        expression: "isEncrypted"
      }
    }), _c('v-spacer'), _c('v-tooltip', {
      staticClass: "align-self-center ma-0",
      attrs: {
        "top": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": ""
            },
            on: {
              "click": _vm.deleteUnsavedSetting
            }
          }, on), [_c('v-icon', {
            staticClass: "ma-auto mt-1 align-self-center"
          }, [_vm._v("mdi-trash-can-outline")])], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManageDetailCard.delete")) + " ")])])], 1)], 1) : i !== _vm.currentSetting ? _c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "flex-grow-1 flex-shrink-0"
    }, [_c('v-text-field', {
      staticClass: "mt-2 mb-n8 pr-4",
      attrs: {
        "label": _vm.$t('components.partner.PartnerManageDetailCard.value'),
        "disabled": true,
        "value": _vm.getValue(i),
        "outlined": ""
      }
    })], 1), _c('v-col', {
      staticClass: "flex-grow-0 flex-shrink-1 align-self-center"
    }, [_c('v-tooltip', {
      attrs: {
        "top": "",
        "nudge-right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on;
          return [_c('v-btn', _vm._g({
            attrs: {
              "icon": ""
            },
            on: {
              "click": function click($event) {
                return _vm.deleteSetting(i);
              }
            }
          }, on), [_c('v-icon', {
            staticClass: "ma-auto mt-2"
          }, [_vm._v("mdi-trash-can-outline")])], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManageDetailCard.delete")) + " ")])])], 1)], 1) : !_vm.loaded ? _c('v-skeleton-loader', {
      staticClass: "mx-auto",
      attrs: {
        "type": "card-heading, list-item-two-line, list-item-two-line"
      }
    }) : _vm._e()], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }