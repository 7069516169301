





















import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { Credential, ICredential } from "@/models/credential.entity";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import CredentialForm from "./CredentialForm.vue";

@Component({
  components: {
    ConfirmActionDialog,
    CredentialForm
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CredentialUpdateDialog extends mixins(PermissionMixin, RulesMixin) {
  @Prop()
  private value!: ICredential;

  @Prop({ default: false })
  isDialogActive!: boolean;

  get credential() {
    return this.value;
  }

  set credential(credential: ICredential) {
    this.$emit("input", credential);
  }

  isLoading = false;

  isValid = false;

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  /**
   *
   */
  async update() {
    try {
      this.isLoading = true;

      if (!this.credential) {
        return;
      }

      await this.credential.update();

      this.$toast.success("👍");

      this.dialogModel = false;

      this.$emit("created", this.credential);

      // Reset
      this.credential = new Credential({ partnerId: PartnerModule.partner._id });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
