var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('excel-import', {
    attrs: {
      "config": _vm.config,
      "btnText": _vm.text || _vm.$t('common.verbs.import'),
      "loading": _vm.loadingImport,
      "outlined": _vm.outlined,
      "small": _vm.small
    },
    on: {
      "uploadData": _vm.importData
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var data = _ref.data;
        return [_c('v-alert', {
          staticClass: "ma-4",
          attrs: {
            "value": true,
            "type": "success",
            "border": "left"
          }
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "grow"
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationCreateBatchView.success", {
          success: _vm.dataImports.length,
          all: data.length
        })) + " ")])], 1)], 1), _vm.failedImports.length ? _c('v-alert', {
          staticClass: "ma-4",
          attrs: {
            "value": true,
            "type": "error",
            "border": "left"
          }
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "grow"
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationCreateBatchView.error", {
          value: _vm.failedImports.length
        })) + " ")]), _c('v-col', {
          staticClass: "shrink"
        }, [_c('v-btn', {
          attrs: {
            "small": "",
            "elevation": "0"
          },
          on: {
            "click": _vm.createErrorReport
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationCreateBatchView.errorReport")) + " ")])], 1)], 1)], 1) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }