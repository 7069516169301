var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-row', {
    attrs: {
      "align-content": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('views.booking.ResourceForm.displayName') + ' *',
      "rules": _vm.requiredRule,
      "readonly": _vm.disabled,
      "hint": _vm.$t('views.booking.ResourceForm.displayNameHint'),
      "outlined": "",
      "persistent-hint": ""
    },
    model: {
      value: _vm.resourceDto.name,
      callback: function callback($$v) {
        _vm.$set(_vm.resourceDto, "name", $$v);
      },
      expression: "resourceDto.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.isMobile ? '' : _vm.$t('views.booking.ResourceForm.switchLabel'),
      "readonly": _vm.disabled
    },
    model: {
      value: _vm.resourceDto.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.resourceDto, "isActive", $$v);
      },
      expression: "resourceDto.isActive"
    }
  })], 1)], 1), _vm.availability && _vm.availability.length > 0 ? _c('availability-form', {
    attrs: {
      "availability": _vm.availability,
      "isLoading": _vm.isLoading
    },
    on: {
      "change": _vm.onAvailabilityChange
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }