var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('partner-manage-stepper-step-layout', {
    attrs: {
      "title": _vm.$t("components.partner.PartnerManageStepper.DescriptionStep.".concat(_vm.descriptionType, ".title")),
      "currentStep": _vm.currentStep,
      "totalSteps": _vm.totalSteps,
      "leftButtonFunction": _vm.goToPrevious,
      "rightButtonFunction": _vm.goToNext
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManageStepper.DescriptionStep.".concat(_vm.descriptionType, ".description"))))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }