










































































import AvailabilityForm from "@/components/booking/AvailabilityForm.vue";
import ResourceList from "@/components/booking/ResourceList.vue";
import { timeFormatRule } from "@/lib/rules/dateRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IBooking } from "@/models/booking.entity";
import { BookingAvailabilityGen, BookingServiceViewModelGen } from "@/services/booking/v1/data-contracts";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IResource } from "@/models/resource.entity";

@Component({
  components: { AvailabilityForm, ResourceList }
})
export default class BookingForm extends Vue {
  isValid = false;
  isDisabled = true;

  @Prop({ default: false })
  isCreate!: boolean;

  @Prop()
  value!: IBooking;

  @Prop({ default: false })
  isLoading!: boolean;

  @Prop()
  services?: BookingServiceViewModelGen[];

  @Prop()
  resources?: IResource[];

  @Prop()
  availability?: BookingAvailabilityGen[];

  @Watch("isValid")
  emitIsValid() {
    this.$emit("valid", this.isValid);
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get timeFormatRule() {
    return [timeFormatRule()];
  }

  get disabled() {
    return this.isLoading;
  }
}
