



















import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivCreatePoolDtoGen, MrfiktivPoolViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { PoolModule } from "@/store/modules/pool.store";
import { Component } from "vue-property-decorator";
import TableWrapper, { IControlElements } from "../utility/TableWrapper.vue";
import FleetVehicleImportDialog from "./FleetVehicleImportDialog.vue";

@Component({
  components: { TableWrapper, FleetVehicleImportDialog }
})
export default class FleetPoolTable extends DarkModeHighlightMixin {
  rules = [requiredRule("")];

  isLoading = false;

  selected: MrfiktivPoolViewModelGen[] = [];

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        action: this.selectPool
      }
    ];
  }
  get headers() {
    return [
      {
        text: this.i18n["name"],
        align: "left",
        value: "name",
        placeholder: "Name",
        rules: this.rules
      },
      { text: "", value: "controls", align: "right", sortable: false }
    ];
  }

  get emptyPool() {
    return { name: "", vehicles: [] };
  }

  get allPools() {
    return PoolModule.pools;
  }

  get i18n() {
    return this.$t("views.fleet.FleetPoolListView");
  }

  async loadAll() {
    this.isLoading = true;
    await PoolModule.getAll({ partnerId: PartnerModule.partner._id }).finally(() => {
      this.isLoading = false;
    });
  }

  async confirmNewPools(newPools: MrfiktivCreatePoolDtoGen[]) {
    if (newPools.length >= 10) {
      const error = new Error(this.i18n["tooManyError"]);
      this.$toast.error(error);
      throw error;
    }

    const promises = [];

    for (const pool of newPools) {
      const promise = PoolModule.create({ partnerId: PartnerModule.partner._id, data: pool });

      promises.push(promise);
    }

    /**
     * Await all and display success based on amount of drivers created.
     */
    await Promise.all(promises)
      .then(pools => {
        if (pools.length >= 3) {
          this.$toast.success(`Created ${pools.length} Drivers`);
        } else {
          for (const pool of pools) {
            this.$toast.success(`Created ${pool.name}`);
          }
        }
      })
      .catch(e => {
        throw e;
      });

    this.loadAll();
  }

  selectPool(pool: MrfiktivPoolViewModelGen) {
    if (!pool.id) {
      return;
    }

    this.$router.push({
      name: "FleetPoolDetailView",
      params: {
        partnerId: PartnerModule.partner._id,
        poolId: pool.id
      }
    });
  }
}
