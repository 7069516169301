var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": _vm.pool ? _vm.pool.name : _vm.i18n['title']
    }
  }, [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, divider, list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line"
    }
  }) : _vm.pool ? _c('v-container', {
    staticClass: "wrapper"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.pool.name) + " "), _c('v-spacer'), _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "icon": "",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.loadPool
    }
  }, [_c('v-icon', [_vm._v("mdi-refresh")])], 1)], 1), _c('updatable-card', {
    attrs: {
      "title": _vm.i18n['vehicles'],
      "loading": _vm.loading,
      "disabled": _vm.isDisabled
    },
    on: {
      "click": function click($event) {
        _vm.isDisabled = !_vm.isDisabled;
      }
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c('v-select', {
          staticClass: "mb-n6",
          attrs: {
            "label": _vm.i18n['vehicles'],
            "items": _vm.vehicles,
            "multiple": true,
            "disabled": _vm.isDisabled,
            "outlined": "",
            "item-value": "id",
            "item-text": "displayName"
          },
          on: {
            "blur": _vm.updatePool
          },
          model: {
            value: _vm.pool.vehicles,
            callback: function callback($$v) {
              _vm.$set(_vm.pool, "vehicles", $$v);
            },
            expression: "pool.vehicles"
          }
        })];
      },
      proxy: true
    }])
  }), _vm.pool.vehicles ? _c('v-row', _vm._l(_vm.pool.vehicles, function (vehicle) {
    var _vm$vehicleById, _vm$vehicleById2, _vm$vehicleById3;

    return _c('v-col', {
      key: vehicle
    }, [_c('v-card', {
      attrs: {
        "flat": "",
        "outlined": ""
      }
    }, [_c('v-card-title', [_vm._v(_vm._s((_vm$vehicleById = _vm.vehicleById(vehicle)) === null || _vm$vehicleById === void 0 ? void 0 : _vm$vehicleById.displayName) + " "), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.selectVehicle(vehicle);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-car")])], 1)], 1), _c('v-card-subtitle', [_vm._v(_vm._s((_vm$vehicleById2 = _vm.vehicleById(vehicle)) === null || _vm$vehicleById2 === void 0 ? void 0 : _vm$vehicleById2.numberplate) + " | " + _vm._s((_vm$vehicleById3 = _vm.vehicleById(vehicle)) === null || _vm$vehicleById3 === void 0 ? void 0 : _vm$vehicleById3.identificationnumber))]), _c('debug', {
      attrs: {
        "debug": _vm.vehicleById(vehicle)
      }
    })], 1)], 1);
  }), 1) : _vm._e()], 1) : _c('latest-entries-card-empty')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }