var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "controlElements": _vm.controlElements,
      "predefinedFilter": _vm.predefinedFilter,
      "groupBy": 'startDate',
      "groupDesc": true,
      "selectedItems": _vm.selectedItemsLocal,
      "showSelect": true
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItemsLocal = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItemsLocal = $event;
      },
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "group.header",
      fn: function fn(_ref) {
        var group = _ref.group,
            toggle = _ref.toggle,
            isOpen = _ref.isOpen;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          },
          on: {
            "click": toggle
          }
        }, [_vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(group)) + " "), _c('v-spacer'), isOpen ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)];
      }
    }, {
      key: "selectActions",
      fn: function fn() {
        return [_vm.selectedItems.length ? _c('span', [_c('span', {
          staticClass: "caption"
        }, [_vm._v(_vm._s(_vm.selectedItems.length) + " " + _vm._s(_vm.$t("selected")))]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "color": "error",
            "elevation": "0"
          },
          on: {
            "click": _vm.onClickDelete
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("views.booking.BookingTable.delete")) + " ")])], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "bodyActions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "elevation": "0"
          },
          on: {
            "click": _vm.onClickCreate
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("views.booking.BookingTable.create")) + " ")]), _vm._t("bodyActions")];
      },
      proxy: true
    }, {
      key: "item.start",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.formatHoursAndMinutes(new Date(item.start))) + " ")];
      }
    }, {
      key: "item.end",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.formatHoursAndMinutes(new Date(item.end))) + " ")];
      }
    }, {
      key: "item.duration",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.displayMsAsMinutesAndHours(new Date(item.duration))) + " ")];
      }
    }, {
      key: "item.customerInformation",
      fn: function fn(_ref5) {
        var _item$customerInforma, _item$customerInforma2;

        var item = _ref5.item;
        return [_vm._v(" " + _vm._s((_item$customerInforma = item.customerInformation) === null || _item$customerInforma === void 0 ? void 0 : _item$customerInforma.firstName) + " " + _vm._s((_item$customerInforma2 = item.customerInformation) === null || _item$customerInforma2 === void 0 ? void 0 : _item$customerInforma2.lastName) + " ")];
      }
    }, {
      key: "item.serviceId",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.getServiceName(item.serviceId)) + " ")];
      }
    }, {
      key: "item.resourceId",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.getResourceName(item.resourceId)) + " ")];
      }
    }, {
      key: "item.isDeleted",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('v-chip', [_vm._v(" " + _vm._s(item.isDeleted ? _vm.$t("yes") : _vm.$t("no")) + " ")])];
      }
    }, {
      key: "item.timestamp",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")];
      }
    }], null, true)
  }), _vm.selectedItem ? _c('booking-side-card', {
    attrs: {
      "value": _vm.selectedItem,
      "resourceName": _vm.resourceName,
      "serviceName": _vm.serviceName,
      "resources": _vm.resourcesList,
      "service": _vm.service
    },
    on: {
      "close": function close() {
        return _vm.setSelectedItem(null);
      },
      "goTo": function goTo($event) {
        return _vm.$emit('goTo', $event);
      },
      "delete": function _delete() {
        return _vm.setSelectedItem(null);
      }
    }
  }) : _vm._e(), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isCreateDialogActive,
      "title": _vm.$t('views.booking.BookingTable.confirmCreateTitle'),
      "subtitle": _vm.$t('views.booking.BookingTable.confirmCreateSubtitle'),
      "leftLoading": _vm.isCreateDialogLoading,
      "rightLoading": _vm.isCreateDialogLoading,
      "rightDisabled": !_vm.isCreateValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isCreateDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isCreateDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isCreateDialogActive = false;
      },
      "rightClick": _vm.createBooking
    }
  }, [_c('booking-form', {
    ref: "bookingForm",
    attrs: {
      "isLoading": _vm.isCreateDialogLoading,
      "services": _vm.servicesList,
      "resources": _vm.resourcesList
    },
    on: {
      "valid": function valid($event) {
        _vm.isCreateValid = $event;
      }
    },
    model: {
      value: _vm.createBookingDto,
      callback: function callback($$v) {
        _vm.createBookingDto = $$v;
      },
      expression: "createBookingDto"
    }
  })], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteAllDialogActive,
      "title": _vm.$t('views.booking.BookingTable.confirmDeleteAllTitle'),
      "subtitle": _vm.$t('views.booking.BookingTable.confirmDeleteAllSubtitle', {
        counter: _vm.selectedItems.length
      }),
      "leftLoading": _vm.isDeleteAllDialogLoading,
      "rightLoading": _vm.isDeleteAllDialogLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteAllDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteAllDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteAllDialogActive = false;
      },
      "rightClick": _vm.deleteBookings
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }