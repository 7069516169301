




































import { handleError } from "@/lib/utility/handleError";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import { Component, Prop, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";
import { CustomViewEntity } from "@/lib/interfaces/custom-view-entity.interface";

@Component({
  components: {
    draggable
  }
})
export default class ProjectCustomViewOrderDialog<Type> extends Vue {
  @Prop()
  project!: CustomViewEntity<Type>;

  @Prop({ default: false })
  isDialogActive!: boolean;

  isLoading = false;

  isChanged = false;

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  tempOrder = [...this.project.configuration.views];

  async onConfirm() {
    try {
      this.isLoading = true;
      await this.project.updatePartial({
        configuration: {
          views: this.tempOrder.map(c => c.dto),
          customFieldConfig: this.project.configuration.customFieldConfig.map(c => ({
            customField: c.customField.id,
            configuration: c.configuration
          })),
          isTemplate: this.project.configuration.isTemplate
        }
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }

    this.dialogModel = false;
  }

  tabIcon(type: ProjectCustomViewTypeEnum) {
    if (type === ProjectCustomViewTypeEnum.BOARD) {
      return "mdi-view-column-outline";
    }
    if (type === ProjectCustomViewTypeEnum.TABLE) {
      return "mdi-table";
    }
    if (type === ProjectCustomViewTypeEnum.CALENDAR) {
      return "mdi-calendar-month-outline";
    }
  }
}
