



















import Debug from "@/components/utility/Debug.vue";
import ErrorCard from "@/components/utility/ErrorCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { ResourceModule } from "@/store/modules/resource.store";
import { Component, Vue } from "vue-property-decorator";
import ServiceForm from "./ServiceForm.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Service, IService } from "@/models/service.entity";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { ServiceModule } from "@/store/modules/service.store";

@Component({
  components: {
    LayoutSimple,
    ServiceForm,
    ErrorCard,
    Debug
  }
})
export default class ServiceDetail extends Vue {
  isLoading = false;
  serviceToUpdate: IService | undefined = undefined;
  service: IService | null = null;

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get serviceId() {
    return this.$route.params.serviceId;
  }

  get allResources() {
    return ResourceModule.paginationList;
  }

  async onBack() {
    await new GoToHelper(this.$router).goToBooking(this.partnerId);
  }

  async mounted() {
    this.isLoading = true;
    try {
      // Fetch service with the passed id, fetch all resources, fetch all custom fields
      await Promise.all([
        new Service({ partnerId: this.partnerId, id: this.serviceId }).fetch(),
        ResourceModule.fetchAll({ partnerId: this.partnerId }),
        CustomFieldModule.fetchAllFromBeginning({ partnerId: this.partnerId })
      ]);

      this.service = ServiceModule.maps.id.get(this.serviceId)?.[0];
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  onUpdateService(service: IService) {
    this.serviceToUpdate = new Service(service);
  }

  async save() {
    if (!this.serviceToUpdate) {
      this.$toast.error("error");
      throw new Error("Not defined");
    }
    this.isLoading = true;

    try {
      /**
       * Service to update is a service entity and should have all the updated info
       */
      await this.serviceToUpdate.replaceOneByPartnerId();
      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
