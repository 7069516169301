import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import poolService from "@/services/mrfiktiv/services/poolService";
import {
  MrfiktivUpdatePoolDtoGen,
  MrfiktivPoolControllerGetAllParamsGen,
  MrfiktivPoolViewModelGen,
  MrfiktivCreatePoolDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "pool",
  store
})
export class PoolStore extends BasePagination<MrfiktivPoolViewModelGen, MrfiktivPoolControllerGetAllParamsGen> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: MrfiktivPoolViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = [
    { key: "name", type: PageFilterTypes.STRING },
    { key: "partnerId", type: PageFilterTypes.STRING },
    { key: "_id", type: PageFilterTypes.OBJECT_ID }
  ].map(f => new PaginationFilterListElement(f));

  @Action
  protected async loadDocuments(query: MrfiktivPoolControllerGetAllParamsGen): Promise<any> {
    return poolService.getAll({ ...query });
  }

  private _pool: MrfiktivPoolViewModelGen | undefined;

  get pool() {
    return this._pool;
  }
  get pools() {
    return this._paginationList;
  }

  @Mutation
  mutatePool(pool: MrfiktivPoolViewModelGen) {
    this._pool = pool;
  }
  @Mutation
  mutatePools(pools: MrfiktivPoolViewModelGen[]) {
    this._paginationList = pools;
  }

  @Action
  async create(data: { partnerId: string; data: MrfiktivCreatePoolDtoGen }) {
    const res = await poolService.create(data.partnerId, data.data);

    this.context.commit("mutatePool", res);

    return res;
  }

  @Action
  async delete(data: { partnerId: string; poolId: string }) {
    const res = await poolService.delete(data.partnerId, data.poolId);

    this.context.commit("mutatePool", res);

    return res;
  }

  @Action
  async getAll(data: { partnerId: string }) {
    await this.fetchAllFromBeginning({
      partnerId: data.partnerId
    });
  }

  @Action
  async getOne(data: { partnerId: string; poolId: string }) {
    const res = await poolService.getOne(data.partnerId, data.poolId);

    this.context.commit("mutatePool", res);

    return res;
  }

  @Action
  async update(data: { partnerId: string; poolId: string; data: MrfiktivUpdatePoolDtoGen }) {
    const res = await poolService.update(data.partnerId, data.poolId, data.data);

    this.context.commit("mutatePool", res);

    return res;
  }
}

export const PoolModule = getModule(PoolStore);
