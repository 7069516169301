















































import TableWrapper, { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { ITicket } from "@/models/ticket.entity";
import { TicketModule } from "@/store/modules/ticket.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ProjectRouteNames } from "@/views/project/mixins/ProjectGoToMixin.vue";
import Tooltip from "../utility/tooltip.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";

@Component({
  components: { TableWrapper, Tooltip },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketOverdueTable extends Vue {
  @Prop()
  loading!: boolean;

  @Prop({ default: false })
  dense!: boolean;

  selected: ITicket[] = [];

  getVehicle(id: string) {
    return VehicleModule.maps.id.get(id)[0];
  }

  get routeName() {
    return ProjectRouteNames.TICKET_DETAIL;
  }

  get items(): ITicket[] {
    return TicketModule.tickets.filter(t => t.isOverdue);
  }

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: this.$t("components.fleet.FleetHomeCalendar.start").toString(),
        value: "due",
        type: "date",
        width: "50px"
      },
      {
        text: "",
        value: "number",
        align: "start",
        sortable: false
      }
    ];
  }

  get width() {
    return this.$vuetify.breakpoint.width;
  }

  user(userId: string) {
    return PartnerUserModule.maps.id.get(userId)[0];
  }

  userName(userId: string) {
    const user = this.user(userId);
    if (!user) {
      return this.$t("timeLine.ActivityTimeLineItemDocument.unkown");
    }

    return `${user.firstName} ${user.lastName}`;
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  selectedAction() {
    this.$emit("selectedAction", this.selected);
    this.selected = [];
  }

  clickRow(clicked: ITicket) {
    this.$emit("click:row", clicked);
  }
}
