






































import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "../utility/SideCard.vue";
import Tooltip from "../utility/tooltip.vue";
import ProjectForm from "./ProjectForm.vue";
import { IProject } from "@/models/project.entity";

@Component({
  components: { ProjectForm, SideCard, Tooltip }
})
export default class ProjectSideCard extends Vue {
  @Prop()
  private value!: IProject;

  @Prop()
  loading!: boolean;

  get project() {
    return this.value;
  }

  set project(project: IProject) {
    this.$emit("input", project);
  }

  /**
   * Are we editing
   */
  isEdit = false;

  /**
   * Is Ticket form valid
   */
  isValid = false;

  /**
   * This is an update form if we have a ticket id, otherwise it is a form to create
   */
  get isUpdate() {
    return !!this.project.id;
  }

  mounted() {
    this.isEdit = !this.isUpdate;
  }

  goToProjectCustomView() {
    this.$emit("goToProjectCustomView", this.project);
  }
}
