var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v("Partner Finder")]), _c('v-card-subtitle', [_vm._v("Find a partner by location")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "loading": _vm.loading,
      "prepend-inner-icon": "mdi-magnify",
      "autofocus": "",
      "hint": _vm.$t('confirmSearchWithEnter'),
      "label": _vm.$t('objects.partner.address')
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.debounceFind.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-slider', {
    staticClass: "pt-6",
    attrs: {
      "label": "Distanz in KM",
      "max": "50",
      "min": "5",
      "step": "5",
      "ticks": "always",
      "tick-size": "5",
      "thumb-size": 24,
      "thumb-label": "always"
    },
    model: {
      value: _vm.distance,
      callback: function callback($$v) {
        _vm.distance = $$v;
      },
      expression: "distance"
    }
  }), _c('v-data-table', {
    attrs: {
      "items": _vm.partners,
      "headers": _vm.headers,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.address",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.address.street) + ", " + _vm._s(item.address.zip) + " " + _vm._s(item.address.city) + " ")];
      }
    }, {
      key: "item.distance",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(Math.round(item.distance * 10) / 10) + " km ")];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }