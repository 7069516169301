import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Credential } from "../v1/Credential";
import { MrfiktivCreateCredentialDtoGen, MrfiktivCredentialControllerGetParamsGen } from "../v1/data-contracts";

/**
 * Communicate with credential API
 */
class CredentialService {
  mrfiktivProxy: Credential;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Credential(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an credential
   */
  async create(partnerId: string, data: MrfiktivCreateCredentialDtoGen) {
    const c = (await this.mrfiktivProxy.credentialControllerCreate(partnerId, data)).data;

    return c;
  }

  /**
   * Get all credentials
   */
  async get(data: MrfiktivCredentialControllerGetParamsGen) {
    const c = (await this.mrfiktivProxy.credentialControllerGet(data)).data;

    return c;
  }

  /**
   * Get a credential
   */
  async getOne(partnerId: string, id: string) {
    const c = (await this.mrfiktivProxy.credentialControllerGetOne(partnerId, id)).data;

    return c;
  }

  /**
   * Update a credential
   */
  async update(partnerId: string, id: string, data: MrfiktivCreateCredentialDtoGen) {
    const c = (await this.mrfiktivProxy.credentialControllerUpdate(partnerId, id, data)).data;

    return c;
  }

  /**
   * Delete a credential
   */
  async delete(partnerId: string, id: string) {
    const c = (await this.mrfiktivProxy.credentialControllerRemove(partnerId, id)).data;

    return c;
  }
}

/**
 * Communicate with credential API
 */
export default new CredentialService(new MrfiktivHttpClientProvider());
