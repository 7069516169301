






import FleetPoolTable from "@/components/fleet/FleetPoolTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { PoolModule } from "@/store/modules/pool.store";
import { Component } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, FleetPoolTable }
})
export default class FleetPoolListView extends PartnerFallbackMixin {
  async loadAll() {
    await PoolModule.getAll({ partnerId: PartnerModule.partner._id });
  }

  async mounted() {
    try {
      await this.trySetByRouteOrDefault();

      await this.loadAll();
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    }
  }
}
