














































































































































import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import {
  MrfiktivEventViewModelGen,
  MrfiktivArchiveVirtualEventDtoGen,
  MrfiktivArchiveEventInstanceDtoGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ITicket } from "@/models/ticket.entity";
import { TicketModule } from "@/store/modules/ticket.store";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { handleError } from "@/lib/utility/handleError";
import { VehicleEventModule } from "@/store/modules/vehicle-event.store";
import RefsEvent from "../utility/RefsEvent.vue";
import RefsTicket from "../utility/RefsTicket.vue";
import { PartnerModule } from "@/store/modules/partner";
import FleetVehicleEventCrudMixin from "./FleetVehicleEventCrudMixin.vue";
import { UserModule } from "@/store/modules/me-user.store";
import { EventModule } from "@/store/modules/event.store";
import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { $t } from "@/lib/utility/t";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import RefsReport from "../utility/RefsReport.vue";
import { ReportModule } from "@/store/modules/report.store";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { IVehicle } from "@/models/vehicle.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";

@Component({
  components: {
    ConfirmActionDialog,
    RefsEvent,
    RefsTicket,
    RefsReport,
    LatestEntriesCardEmpty
  }
})
export default class FleetVehicleDetailHomeCardArchivedDialog extends mixins(
  FleetVehicleEventCrudMixin,
  PermissionMixin
) {
  @Prop()
  vehicle!: IVehicle;

  tab = "events";

  selectedReports: MrfiktivReportViewModelGen[] = [];
  selectedTickets: ITicket[] = [];
  selectedRecurringEvents: MrfiktivEventViewModelGen[] = [];
  selectedOverdueEvents: MrfiktivEventViewModelGen[] = [];
  selectedEvents: MrfiktivEventViewModelGen[] = [];

  isDialogActive = false;
  isTicketsLoading = false;
  isEventsLoading = false;
  isDeleteLoading = false;
  isReportsLoading = false;

  get tabConfig() {
    let tabConfig: Record<
      string,
      {
        title: string;
        subtitle: string;
        list: any[];
        selectedList: any[];
        component: string;
        confirmable: boolean;
        disabled: boolean;
      }
    > = {};

    if (this.can(ActionEnum.READ, ResourceEnum.EVENT) && this.can(ActionEnum.UPDATE, ResourceEnum.EVENT)) {
      tabConfig = {
        ...tabConfig,
        overdueEvents: {
          title: $t("components.fleet.FleetVehicleDetailHomeCard.overdueEvents"),
          subtitle: $t("components.fleet.FleetVehicleDetailHomeCard.overdueEventsArchive"),
          list: this.overdueEvents,
          selectedList: this.selectedOverdueEvents,
          component: "refs-event",
          confirmable: true,
          disabled: !UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.EVENT, this.vehicle.partnerId)
        },
        recurringEvents: {
          title: $t("components.fleet.FleetVehicleDetailHomeCard.recurringEvents"),
          subtitle: $t("components.fleet.FleetVehicleDetailHomeCard.recurringEventsArchive"),
          list: this.recurringEvents,
          selectedList: this.selectedRecurringEvents,
          component: "refs-event",
          confirmable: false,
          disabled: !UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.EVENT, this.vehicle.partnerId)
        },
        upcomingEvents: {
          title: $t("components.fleet.FleetVehicleDetailHomeCard.upcomingEvents"),
          subtitle: $t("components.fleet.FleetVehicleDetailHomeCard.upcomingEventsArchive"),
          list: this.events,
          selectedList: this.selectedEvents,
          component: "refs-event",
          confirmable: true,
          disabled: !UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.EVENT, this.vehicle.partnerId)
        }
      };
    }

    if (this.can(ActionEnum.READ, ResourceEnum.TICKET) && this.can(ActionEnum.UPDATE, ResourceEnum.TICKET)) {
      tabConfig = {
        ...tabConfig,
        openTickets: {
          title: $t("components.fleet.FleetVehicleDetailHomeCard.openTickets"),
          subtitle: $t("components.fleet.FleetVehicleDetailHomeCard.openTicketsArchive"),
          list: this.tickets,
          selectedList: this.selectedTickets,
          component: "refs-ticket",
          confirmable: true,
          disabled: !UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.TICKET, this.vehicle.partnerId)
        }
      };
    }

    if (this.can(ActionEnum.READ, ResourceEnum.REPORT) && this.can(ActionEnum.UPDATE, ResourceEnum.REPORT)) {
      tabConfig = {
        ...tabConfig,
        openReports: {
          title: $t("components.fleet.FleetVehicleDetailHomeCard.openReports"),
          subtitle: $t("components.fleet.FleetVehicleDetailHomeCard.openReportsArchive"),
          list: this.reports,
          selectedList: this.selectedReports,
          component: "refs-report",
          confirmable: true,
          disabled: !UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.REPORT, this.vehicle.partnerId)
        }
      };
    }

    return tabConfig;
  }

  get canArchive() {
    return UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.VEHICLE, this.vehicle.partnerId);
  }

  get confirmButtonText() {
    const count =
      this.selectedReports.length +
      this.selectedEvents.length +
      this.selectedTickets.length +
      this.selectedOverdueEvents.length +
      this.selectedRecurringEvents.length;

    if (count) {
      return `${$t("archive")} (${count})`;
    }

    return $t("archive");
  }

  get overdueEvents() {
    return VehicleEventModule.overdueEvents.filter(e => e.vehicleId === this.vehicle.id && !e.ack && !e.isDeleted);
  }

  get recurringEvents() {
    return VehicleEventModule.recurringEventList.filter(e => {
      const belongsToVehicle = e.vehicleId === this.vehicle.id;
      const isDeleted = e.isDeleted;

      return belongsToVehicle && !isDeleted;
    });
  }

  get events() {
    return VehicleEventModule.events.filter(
      e => e.vehicleId === this.vehicle.id && !e.ack && new Date(e.startDate) > new Date() && !e.isDeleted
    );
  }

  get tickets() {
    return TicketModule.tickets.filter(t =>
      t.refs?.find(r => r.refId === this.vehicle.id && t.state !== TicketStatusEnum.ARCHIVED)
    );
  }

  get reports() {
    return ReportPaginationModule.paginationList.filter(
      r =>
        r.vehicleId === this.vehicle.id &&
        [ProgressStatusEnum.NEW, ProgressStatusEnum.IN_PROGRESS].includes(r.progressStatus as ProgressStatusEnum)
    );
  }

  get isAllSelected() {
    let selected = 0;
    let total = 0;
    for (const config of Object.values(this.tabConfig)) {
      selected += config.selectedList.length;
      total += config.list.length;
    }

    return selected === total;
  }

  async startDialog() {
    this.isDialogActive = true;

    await Promise.all([this.loadTickets(), this.loadEvents(), this.loadReports()]);
  }

  async loadTickets() {
    if (!(this.can(ActionEnum.READ, ResourceEnum.TICKET) && this.can(ActionEnum.UPDATE, ResourceEnum.TICKET))) {
      return;
    }

    this.isTicketsLoading = true;

    TicketModule.setFilter([new PageFilterElement({ key: "refs.refId", operation: "$eq", value: this.vehicle.id })]);
    await TicketModule.fetchAll({ partnerId: this.vehicle.partnerId }).catch(handleError);

    this.isTicketsLoading = false;
  }

  selectedIndex<T extends IEventUIDto | ITicket>(item: T, list: T[]) {
    let found;
    if (!item.id) {
      found = list.findIndex(
        i =>
          (i as IEventUIDto).start === (item as IEventUIDto).start &&
          (i as IEventUIDto).recurringEventId === (item as IEventUIDto).recurringEventId
      );
    } else {
      found = list.findIndex(i => i.id === item.id);
    }

    return found;
  }

  select<T extends IEventUIDto | ITicket>(item: T, list: T[]) {
    const found = this.selectedIndex(item, list) > -1;

    if (!found) {
      list.push(item);
    }
  }

  unselect<T extends IEventUIDto | ITicket>(item: T, list: T[]) {
    const index = this.selectedIndex(item, list);
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  unselectAll<T>(selectedList: T[]) {
    selectedList.splice(0);
  }

  selectAll<T>(selectedList: T[], allItems: T[]) {
    selectedList.splice(0);
    selectedList.push(...allItems);
  }

  unselectAllAll() {
    for (const config of Object.values(this.tabConfig)) {
      this.unselectAll<any>(config.selectedList);
    }
  }

  selectAllAll() {
    for (const config of Object.values(this.tabConfig)) {
      this.selectAll<any>(config.selectedList, config.list);
    }
  }

  async loadReports() {
    if (!(this.can(ActionEnum.READ, ResourceEnum.REPORT) && this.can(ActionEnum.UPDATE, ResourceEnum.REPORT))) {
      return;
    }

    ReportPaginationModule.setFilters([
      new PageFilterElement({
        key: "vehicleId",
        operation: "$eq",
        value: this.vehicle.id
      })
    ]);
    this.isReportsLoading = true;
    await ReportPaginationModule.fetchAll({
      partnerId: this.vehicle.partnerId
    }).catch(handleError);
    this.isReportsLoading = false;
  }

  async loadEvents() {
    if (!(this.can(ActionEnum.READ, ResourceEnum.EVENT) && this.can(ActionEnum.UPDATE, ResourceEnum.EVENT))) {
      return;
    }

    const promises: Promise<any>[] = [];
    promises.push(
      VehicleEventModule.listOverdue({ partnerId: this.vehicle.partnerId, vehicleId: this.vehicle.id }).catch(
        handleError
      )
    );
    promises.push(
      VehicleEventModule.fetchAll({
        partnerId: this.vehicle.partnerId,
        vehicleId: this.vehicle.id
      }).catch(handleError)
    );
    promises.push(
      VehicleEventModule.getRecurringEvents({
        partnerId: this.vehicle.partnerId,
        vehicleId: this.vehicle.id
      }).catch(handleError)
    );

    this.isEventsLoading = true;
    await Promise.all(promises);
    this.isEventsLoading = false;
  }

  async archiveSelection() {
    const promises = [];

    const eventIdentifier: (MrfiktivArchiveVirtualEventDtoGen | MrfiktivArchiveEventInstanceDtoGen)[] = [];

    for (const selectedEvent of [
      ...this.selectedEvents,
      ...this.selectedRecurringEvents,
      ...this.selectedOverdueEvents
    ]) {
      if (selectedEvent.id) {
        eventIdentifier.push({ id: selectedEvent.id });
      } else if (selectedEvent.recurringEventId) {
        eventIdentifier.push({ recurringEventId: selectedEvent.recurringEventId, start: selectedEvent.start });
      }
      FleetAggregationModule.removeEvent(new EventUIDto(selectedEvent));
    }

    if (eventIdentifier.length) {
      promises.push(
        EventModule.archiveMany({
          partnerId: PartnerModule.partner.id,
          dto: eventIdentifier
        }).catch(handleError)
      );
    }
    if (this.selectedTickets.length) {
      promises.push(
        TicketModule.archiveMany({
          partnerId: PartnerModule.partner.id,
          tickets: this.selectedTickets
        }).catch(handleError)
      );
    }
    if (this.selectedReports.length) {
      promises.push(
        ReportModule.archiveManyReports({
          partnerId: PartnerModule.partner.id,
          ids: this.selectedReports.map(r => r.id)
        }).catch(handleError)
      );
    }

    this.isDeleteLoading = true;
    await Promise.all(promises);
    await this.startDialog();
    await FleetAggregationModule.vehicleAggregationMap
      .get(this.vehicle.id)
      ?.initializeAsync({ month: new Date().getMonth(), year: new Date().getFullYear() });

    this.selectedEvents.splice(0);
    this.selectedRecurringEvents.splice(0);
    this.selectedOverdueEvents.splice(0);
    this.selectedTickets.splice(0);
    this.selectedReports.splice(0);
    this.isDeleteLoading = false;
  }
}
