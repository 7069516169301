



























import { GoToHelper } from "@/lib/utility/goToHelper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ApiAnonymizationDialog extends mixins(PartnerFallbackMixin) {
  @Prop({ default: false })
  isDialogActive!: boolean;

  goToHelper = new GoToHelper(this.$router);

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }

  goToCredential() {
    this.goToHelper.goToCredential(this.partnerId);
  }

  goToDocs() {
    window.open("https://api.mmmint.ai/ainonymizer/v1/docs", "_blank");
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }
}
