import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { Handover, IHandover } from "@/models/handover.entity";
import { MrfiktivHandoverControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { HandoverDataAccessLayer } from "./access-layers/handover.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { HandoverPageDataProvider } from "./page-data-provider/handover.page-data-provider";

/**
 * wraps the timed event endpoint into a makeshift event pagination @see RefTypeMap
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "handover",
  store
})
class HandoverStore extends PaginatedBaseStore<IHandover, MrfiktivHandoverControllerFindAllParamsGen> {
  protected _data = HandoverDataAccessLayer;
  protected _pageProvider = HandoverPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Handover.filterables;
}

export const HandoverModule = getModule(HandoverStore);
