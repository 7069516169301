











































































































































































































































import CostChip from "@/components/cost/CostChip.vue";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import DueDateChip from "@/components/project/DueDateChip.vue";
import ProjectCustomViewContextMenu from "@/components/project/ProjectCustomViewContextMenu.vue";
import ProjectCustomViewForm from "@/components/project/ProjectCustomViewForm.vue";
import ProjectCustomViewOrderDialog from "@/components/project/ProjectCustomViewOrderDialog.vue";
import TicketCreateDialog from "@/components/project/TicketCreateDialog.vue";
import TicketSideCard from "@/components/project/TicketSideCard.vue";
import CustomFieldValueChip from "@/components/report/CustomFieldValueChip.vue";
import Calendar from "@/components/utility/Calendar.vue";
import ContextMenu from "@/components/utility/ContextMenu.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import RefsTicket from "@/components/utility/RefsTicket.vue";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { downloadAsXlsx } from "@/lib/download-as-xlsx";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { CalendarEvent, CalendarTypeEnum } from "@/lib/utility/calendarEvent";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { formatHoursAndMinutes, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { deepCopy } from "@/lib/utility/deep-copy";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { ProjectCustomView as ProjectCustomViewClass } from "@/models/project-custom-view.entity";
import { IProject, Project } from "@/models/project.entity";
import { ITicket, Ticket } from "@/models/ticket.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import {
  MrfiktivProjectCustomViewFieldDtoGen,
  MrfiktivProjectCustomViewViewmodelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import { PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { CustomFieldEnum } from "@/store/modules/custom-field.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ProjectModule } from "@/store/modules/project.store";
import { TicketModule } from "@/store/modules/ticket.store";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import CustomProjectTable from "./CustomProjectTable.vue";
import { ProjectCustomViewFieldEnum } from "./enum/ProjectCustomViewFieldEnum";
import { ProjectCustomViewTypeEnum } from "./enum/ProjectCustomViewTypeEnum";
import { predefinedTicketFilter } from "./filter/PredefinedTicketFilter";
import KanbanBoard, { IColumn } from "./KanbanBoard.vue";
import ProjectGoToMixin from "./mixins/ProjectGoToMixin.vue";
import ProjectInitializeListsMixin from "./mixins/ProjectInitializeListsMixin.vue";
import TicketCrudMixin from "./mixins/TicketCrudMixin.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import CustomViewCreateHelper from "@/components/cost/CustomViewCreateHelper.vue";
import { CustomProjectTableHelper, CustomViewExtendedDocument } from "@/lib/utility/project/customProjectTableHelper";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";

@Component({
  components: {
    Calendar,
    KanbanBoard,
    FilterCardPagination,
    TicketSideCard,
    RefsTicket,
    ProjectCustomViewForm,
    TheLayoutPortal,
    CustomProjectTable,
    draggable,
    ProjectCustomViewContextMenu,
    TicketCreateDialog,
    MHeader,
    Debug,
    CustomFieldValueChip,
    ContextMenu,
    ProjectCustomViewOrderDialog,
    DueDateChip,
    Tooltip,
    CostChip,
    RefsVehicle,
    AssigneePreview,
    CustomViewCreateHelper
  }
})
export default class ProjectCustomView extends mixins(
  TicketCrudMixin,
  ProjectGoToMixin,
  ProjectInitializeListsMixin,
  PartnerFallbackMixin
) {
  readonly BackendResourceEnum = BackendResourceEnum;

  SETTINGS_ACTION = "settingsActions";

  /**
   * Specifies the Active Tab and serves as v-model for the v-tab
   * Specifies the active tab config. if e.g. its 3, the active tab is the third one of the views array
   */
  viewId = 0;

  isSideCard = false;

  /**
   * Dialog v-model for custom view creation
   */
  customViewFormDialog = false;

  /**
   * Dialog v-model for custom view update
   */
  customViewFormDialogUpdate = false;

  /**
   * Dialog v-model for custom view order update
   */
  customViewFormDialogOrder = false;

  /**
   * Dialog for creation of Tickets;
   */
  isCreateDialogActive = false;

  /**
   * Loading prop for deletion of a project
   */
  deletingProject = false;

  /**
   * General loading prop for this view
   */
  customViewsLoading = false;

  /**
   * Predefined Filter for the Tickets
   */
  predefinedFilter = predefinedTicketFilter;

  ProjectCustomViewTypeEnum = ProjectCustomViewTypeEnum;

  calendarType = this.isMobile ? CalendarTypeEnum.DAY : CalendarTypeEnum.MONTH;

  asArray(value?: string | string[]): string[] {
    if (!value) {
      return [];
    }

    if (Array.isArray(value)) {
      return value as string[];
    }

    return [value] as string[];
  }

  tableBaseConfig = {
    values: [
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "number"
      },
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "title"
      },
      {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "description"
      }
    ],
    filters: []
  };

  calendarBaseConfig = {
    calendarStart: {
      type: ProjectCustomViewFieldEnum.PROPERTY,
      key: "timestamp.created"
    },
    filters: []
  };

  boardBaseConfig = {
    filters: []
  };

  get title() {
    return this.project?.title ?? "...";
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.TICKET, this.partnerId)) {
      actions.push({
        text: this.$t("project.ticket.createTicket").toString(),
        key: "CREATE_TICKET",
        exec: () => {
          this.isCreateDialogActive = true;
        }
      });
    }

    if (this.tabs[this.viewId] && this.tabs[this.viewId].type === ProjectCustomViewTypeEnum.TABLE) {
      actions.push({
        text: this.$t("common.verbs.download").toString(),
        key: "DOWNLOAD_TICKETS",
        exec: this.downloadTickets
      });
    }

    if (UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.PROJECT, this.partnerId)) {
      actions.push({
        text: this.$t("project.ticket.settings").toString(),
        key: this.SETTINGS_ACTION
      });
    }

    return actions;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [
      this.breadCrumbs.ProjectTable(this.partnerId),
      this.breadCrumbs.ProjectDetail(this.partnerId, this.projectId)
    ];

    return crumbs;
  }

  /**
   * Returns all custom views
   */
  get tabs(): MrfiktivProjectCustomViewViewmodelGen[] {
    return this.project?.configuration.views || [];
  }

  /**
   * Returns the active tab config
   */
  get activeTabConfig() {
    if (!this.tabs.length) {
      // TODO: Show dialog to create custom view or smth
      return undefined;
    }

    if (this.viewId === undefined || this.viewId > this.tabs.length) {
      this.viewId = 0;
    }

    if (!this.tabs[this.viewId]) {
      return undefined;
    }

    return deepCopy(this.tabs[this.viewId]);
  }

  get sortBy() {
    if (!this.activeTabConfig?.sortBy) {
      return null;
    }

    return this.activeTabConfig.sortBy.key;
  }

  get sortDesc() {
    if (!this.activeTabConfig?.sortBy) {
      return null;
    }

    return this.activeTabConfig.sortBy.order === PageOrderEnum.DESCENDING;
  }

  /**
   * Group Table by
   */
  get groupTableBy() {
    if (!this.activeTabConfig?.groupBy) {
      return null;
    }

    return this.activeTabConfig.groupBy.key;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  /**
   * Defines the attribute which is responsible for the columns of the board.
   * E.g it could be {type: "customFields", key: "123...mongoid"}
   *
   * For now it can only be a customField of type singleSelect
   *
   * That means that the columns are all possibles values of the customFields 123...mongoid (the getter "BoarrdColumns" serarches for the different values that this customField can have)
   *
   */
  get boardColumnKey(): string {
    return this.tabs[this.viewId]?.boardColumn?.key?.split("?")[1] ?? "";
  }

  get boardColumnOrder(): string[] {
    return this.tabs[this.viewId]?.boardColumnOrder ?? [];
  }

  /**
   * Searches all values that the boarColumnKey can have.
   * E.g. is the boarColumKey is "Werkstattstatus", the Boardcolums are e.g. "Für Abholung bereit", "In Werkstatt", "Werkstattauftrag beednet"
   */
  get boardColumns() {
    const columns: IColumn[] = [];

    const boardColumnKey = this.boardColumnKey;

    if (boardColumnKey) {
      const config = this.project?.configuration?.customFieldConfig.find(
        config => config.customField.id === boardColumnKey
      );

      if (config?.customField?.configuration?.values) {
        const boardColumnOrder = this.boardColumnOrder;

        if (!boardColumnOrder.length) {
          for (const key of config.customField?.configuration?.values || []) {
            columns.push({
              key: key.value,
              label: key.value,
              color: key.color
            });
          }
        } else {
          for (const orderedValue of boardColumnOrder) {
            const foundKey = config.customField?.configuration?.values.find(f => f.value === orderedValue);
            if (foundKey) {
              columns.push({
                key: foundKey.value,
                label: foundKey.value,
                color: foundKey.color
              });
            }
          }
        }
      }
    }
    return columns;
  }

  /**
   * Returns the current project
   */
  get project() {
    const project = ProjectModule._data.entities.find(e => e.id === this.projectId);

    if (project) {
      return project;
    }
    return undefined;
  }

  get projectCustomFieldIdMap() {
    const projectCustomFields = this.project?.configuration?.customFieldConfig || [];
    const idToKeyMap = new Map();

    projectCustomFields.forEach(item => {
      idToKeyMap.set(item.customField.id, item.customField.key);
    });

    return idToKeyMap;
  }

  get tickets(): ITicket[] {
    return TicketModule.paginationList;
  }

  get events(): CalendarEvent<ITicket>[] {
    const calenderEvents = [];
    if (!this.activeTabConfig) {
      return [];
    }
    for (const event of TicketModule.paginationList) {
      let start: Date | undefined = undefined;
      let end: Date | undefined = undefined;
      /**
       * Type of the custom field of the either `calendarStart` or `calendarEnd` config.
       * In the calendar config type we only store the type as `customField`, not the underlying type
       * of custom field(date, date_time, single_select, etc)
       */
      let customFieldType: CustomFieldEnum | undefined = undefined;

      /**
       * Find correct event start
       */
      if (this.activeTabConfig?.calendarStart?.type === ProjectCustomViewFieldEnum.PROPERTY) {
        if (this.activeTabConfig?.calendarStart?.key === "due") {
          if (event.due) {
            start = new Date(event.due);
          }
        }
        if (this.activeTabConfig?.calendarStart?.key === "timestamp.created") {
          if (event.timestamp.created) {
            start = new Date(event.timestamp.created);
          }
        }
      }
      if (this.activeTabConfig?.calendarStart?.type === ProjectCustomViewFieldEnum.CUSTOM_FIELD) {
        const found = event.values?.find(v => v.id === this.activeTabConfig?.calendarStart?.key.split("?")[1]);
        if (found && found?.value) {
          const foundCustomFieldConfig = this.project?.configuration.customFieldConfig.find(
            customFieldConfigItem => customFieldConfigItem.customField.id === found.id
          );
          customFieldType = foundCustomFieldConfig?.customField.type as CustomFieldEnum;

          start = new Date(found.value as string);
        }
      }

      /**
       * Find correct event end
       */

      if (this.activeTabConfig?.calendarEnd?.type === ProjectCustomViewFieldEnum.PROPERTY) {
        if (this.activeTabConfig?.calendarEnd?.key === "due") {
          if (event.due) {
            end = new Date(event.due);
          }
        }
        if (this.activeTabConfig?.calendarEnd?.key === "timestamp.created") {
          if (event.timestamp.created) {
            end = new Date(event.timestamp.created);
          }
        }
      }

      if (this.activeTabConfig?.calendarEnd?.type === ProjectCustomViewFieldEnum.CUSTOM_FIELD) {
        const found = event.values?.find(v => v.id === this.activeTabConfig?.calendarEnd?.key.split("?")[1]);
        if (found && found?.value) {
          const foundCustomFieldConfig = this.project?.configuration.customFieldConfig.find(
            customFieldConfigItem => customFieldConfigItem.customField.id === found.id
          );
          customFieldType = foundCustomFieldConfig?.customField.type as CustomFieldEnum;

          end = new Date(found.value as string);
        }
      }

      if (start || end) {
        // Determine the actual start and end dates
        let actualStart = start || end;
        let actualEnd = end;

        // Check and swap if necessary
        if (start && end && start > end) {
          actualStart = end;
          actualEnd = start;
        }

        calenderEvents.push({
          name: "#" + event.number + " " + event.title,
          start: actualStart,
          end: actualEnd,
          timed: customFieldType === CustomFieldEnum.DATE_TIME,
          data: [new Ticket(event)]
        } as CalendarEvent<ITicket>);
      }
    }

    return calenderEvents;
  }

  getVehicleById(vehicleId: string) {
    let vehicle = VehicleAccessLayer.maps.id.get(vehicleId)[0];

    if (!vehicle) {
      vehicle = new Vehicle({ id: vehicleId, partnerId: this.partnerId });
      VehicleAccessLayer.set(vehicle);
      vehicle.fetch().catch(this.$log.error);
    }

    return vehicle;
  }

  ticketColor(item: ITicket) {
    if (item.state) {
      return TicketModule.ticketStateMap.get(item.state as TicketStatusEnum)?.color;
    }
  }

  /**
   * Custom Field value lookup
   * @param customConfig
   */
  collectFieldValues(
    item: ITicket,
    customConfig: MrfiktivProjectCustomViewFieldDtoGen[]
  ): CustomViewExtendedDocument[] {
    if (!item.projectId) {
      return [];
    }

    return new CustomProjectTableHelper(
      customConfig,
      Ticket.filterables,
      ProjectModule._data.get(this.projectId)?.configuration?.customFieldConfig ?? [],
      item
    ).getExtendedDocument();
  }

  firstTicketFromEvent(event: CalendarEvent<ITicket>) {
    if (event && event.data && event.data.length) {
      return event.data[0];
    }
  }

  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    if (!user) {
      return this.$t("timeLine.ActivityTimeLineItemDocument.unkown");
    }

    return `${user.firstName} ${user.lastName}`;
  }

  openTicketSideCardFromCalendar(slotScope: { item: CalendarEvent<ITicket>; close: () => void }) {
    const ticket = this.firstTicketFromEvent(slotScope.item);
    if (ticket) {
      this.openTicketSideCard(ticket?.number);
      slotScope.close();
    }
  }

  @Watch("viewId")
  handleViewIdChange() {
    this.ticket = null;
    this.isSideCard = false;

    this.setUrlTo.projectCustomView({
      partnerId: this.partnerId,
      projectId: this.projectId,
      viewId: this.viewId.toString()
    });
  }

  processAction(action: IAction) {
    this.$log.debug(action);

    switch (action.key) {
      case this.SETTINGS_ACTION:
        this.goTo.projectSetting({ partnerId: this.partnerId, projectId: this.projectId });
        break;

      default:
        if (action.exec) {
          action.exec();
        }
        this.$log.warn(action);
        break;
    }
  }

  async loadTicketsForCustomView() {
    if (this.activeTabConfig === undefined) {
      this.$log.error("active tab config not found");
      return;
    }

    this.loadingTickets = true;

    try {
      if (this.projectId) {
        TicketModule.setHiddenFilters([
          new PageFilterElement({ key: "projectId", value: this.projectId, operation: "$eq" })
        ]);
      }

      TicketModule.setFilters(this.activeTabConfig.filters.map(f => new PageFilterElement(f)));

      await this.getTickets();
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingTickets = false;
    }
  }

  downloadTickets() {
    /**
     * Get the correct table - the one we're viewing at the moment.
     * Let the table map the data to the correct format and
     * then download it.
     */
    const refName = `customProjectTable${this.viewId}`;
    const customProjectTableComponent = (this.$refs[refName] as CustomProjectTable<IProject>[])[0];

    this.$log.debug(refName, customProjectTableComponent);

    let ticketsToDownload: Record<string, any>[] = [];

    if (customProjectTableComponent) {
      ticketsToDownload = customProjectTableComponent.mapTicketsToTableData();
    }

    downloadAsXlsx(ticketsToDownload, undefined, false);
  }

  /**
   * Ads new Board an set new tab
   * @param config
   */
  async addNewBoard(newConfig: MrfiktivProjectCustomViewViewmodelGen) {
    try {
      this.loadingTickets = true;
      const tabIndex = this.tabs.findIndex(config => config.id === newConfig.id);
      this.viewId = tabIndex;

      if (this.activeTabConfig) {
        await this.loadTicketsForCustomView();
      }
    } catch (error) {
      handleError(error);
    }

    this.loadingTickets = false;
  }

  openUpdateDialog() {
    this.customViewFormDialogUpdate = true;
  }

  openReorderDialog() {
    this.customViewFormDialogOrder = true;
  }

  async createDuplicateCustomView(customView: MrfiktivProjectCustomViewViewmodelGen) {
    if (!this.project?.configuration?.views) {
      this.$toast("Project configuration not set");
      return;
    }
    this.loadingTickets = true;
    try {
      const project = new Project(deepCopy(this.project));
      if (!project.id) {
        this.$toast("Please try again. Project was not initilized.");
        return;
      }

      const newCustomView = deepCopy(customView);
      newCustomView.title = newCustomView.title + " copy";
      project?.configuration.views.push(new ProjectCustomViewClass(newCustomView));
      const updateProject = await project.update();
      this.addNewBoard(updateProject.configuration.views.slice(-1)[0]);
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingTickets = false;
    }
  }

  async deleteCustomView(customViewId: string) {
    try {
      this.deletingProject = true;
      const project = new Project(this.project);
      const index = project.configuration.views.findIndex(view => view.id === customViewId);
      this.viewId = 0;

      if (index !== -1) {
        // The element was found in the array
        project.configuration.views.splice(index, 1);

        if (project.id) {
          await project.update();
        } else {
          this.$log.error("No Project id");
        }
      } else {
        // Handle the case where the element was not found
        this.$log.error("Custom view not found in the array");
      }
      if (this.tabs.length) {
        this.viewId = 0;
        await this.loadTicketsForCustomView();
      }
    } catch (error) {
      handleError(error);
    }

    this.deletingProject = false;
  }

  tabIcon(item: MrfiktivProjectCustomViewViewmodelGen) {
    if (item.type === ProjectCustomViewTypeEnum.BOARD) {
      return "mdi-view-column-outline";
    }
    if (item.type === ProjectCustomViewTypeEnum.TABLE) {
      return "mdi-table";
    }
    if (item.type === ProjectCustomViewTypeEnum.CALENDAR) {
      return "mdi-calendar-month-outline";
    }
  }

  tabTitleTransformer(item: MrfiktivProjectCustomViewViewmodelGen) {
    if (!item) {
      return "";
    }
    // Returns full Label if tab is active
    if (item.id === this.activeTabConfig?.id) {
      return item.title;
    }

    let title = deepCopy(item.title);
    // Trim the string to 17 characters if it's longer than 20
    if (title.length > 20) {
      title = title.substring(0, 17);
      // Trim trailing spaces
      title = title.trimRight();

      // Add "..." at the end
      title = title + "...";
    }

    return title;
  }

  /**
   * Custom function to sort Ticktes for columns for the Board view
   * @param ticket
   */
  identifierFunction(ticket: ITicket) {
    if (this.boardColumnKey) {
      const value = ticket.values?.find(value => value.id === this.boardColumnKey);
      if (value) {
        return value.value;
      }
    }
  }

  /**
   * Custom function to change tickets that are drag an droped on the board
   * @param ticket
   */
  changeValueFunction(ticket: ITicket, newValue: string, oldValue: string) {
    if (this.boardColumnKey) {
      const value = ticket.values?.find(value => value.id === this.boardColumnKey);
      if (value) {
        if (typeof value.value === "string") {
          value.value = newValue;
        } else if (!value.value.includes(newValue)) {
          const oldIndex = value.value.indexOf(oldValue);
          if (oldIndex > -1) value.value.splice(oldIndex, 1);
          value.value.push(newValue);
        }
      }
    }

    return ticket;
  }

  /**
   * Function to update the ticket after droping it to a new column on the board
   *
   * @param ticket
   */
  async change(ticket: ITicket) {
    const _ticket = new Ticket(ticket);
    try {
      ticket.loading = true;
      await this.updateTicket(_ticket.updateDto, ticket.id, ticket.partnerId);
    } catch (error) {
      handleError(error);
    } finally {
      _ticket.loading = false;
      ticket.loading = false;
    }
  }

  /**
   * Stuff for the pagination and filtering of the Tickets
   */
  getModule() {
    return TicketModule;
  }

  get search() {
    return this.getModule().search;
  }

  set search(search: string | undefined) {
    this.getModule().setSearch(search);
  }

  get paginationFilterList(): PaginationFilterListElement[] {
    return this.getModule().filterOptions;
  }

  get paginationFilter(): IPageFilterElement[] {
    return this.getModule().filters;
  }

  set paginationFilter(filter: IPageFilterElement[]) {
    this.getModule().setFilter(filter);
  }

  async openCalendarDetail(e: CalendarEvent<ITicket>) {
    await this.openTicketSideCard(e.data[0].number);
  }

  /**
   * open ITicket Side Card
   * @param ticket
   */
  async openTicketSideCard(ticketNumber: number) {
    this.isSideCard = true;
    this.ticket = null;

    this.setUrlTo.projectCustomView({
      partnerId: this.partnerId,
      projectId: this.projectId,
      viewId: this.viewId.toString(),
      number: ticketNumber
    });

    await this.getTicketByNumber(ticketNumber);
  }

  /**
   * closes the ticket side card
   */
  closeTicketSideCard() {
    this.isSideCard = false;

    this.setUrlTo.projectCustomView({
      partnerId: this.partnerId,
      projectId: this.projectId,
      viewId: this.viewId.toString()
    });
  }

  /**
   * Deletes a ticket and closes the ticket side card
   * @param ticket
   */
  async onDelete(ticket: ITicket) {
    const success = await this.deleteTicket(ticket);

    if (success) {
      this.closeTicketSideCard();
    }
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
    this.projectId = this.$route.params.projectId;
    this.viewId = Number(this.$route.params.viewId ?? 0);
    this.ticketNumber = this.$route.query.ticketNumber as string;
  }

  /**
   * On mount init project an load tickets for custom view
   */
  async mounted() {
    this.customViewsLoading = true;
    try {
      await this.trySetByRouteOrDefault();
      await this.initializeProjectCustomView(this.partnerId);
      await this.loadTicketsForCustomView();
    } catch (error) {
      handleError(error);
    } finally {
      this.customViewsLoading = false;
    }

    // add filter options for project specific custom fields
    TicketModule.filterOptions.splice(0), TicketModule.filterOptions.push(...TicketModule.originalFilterList);
    for (const customFieldConfig of this.project?.configuration?.customFieldConfig ?? []) {
      let type = PageFilterTypes.CUSTOM_FIELD_VALUE;
      if (
        customFieldConfig.customField.type === CustomFieldEnum.DATE ||
        customFieldConfig.customField.type === CustomFieldEnum.DATE_TIME
      ) {
        type = PageFilterTypes.CUSTOM_FIELD_DATE_VALUE;
      } else if (customFieldConfig.customField.type === CustomFieldEnum.NUMBER) {
        type = PageFilterTypes.CUSTOM_FIELD_NUMBER_VALUE;
      } else if (customFieldConfig.customField.type === CustomFieldEnum.BOOLEAN) {
        type = PageFilterTypes.BOOLEAN;
      }

      TicketModule.filterOptions.push(
        new PaginationFilterListElement({
          key: "values.value",
          specifier: customFieldConfig.customField.id,
          displayName: customFieldConfig.customField.name,
          type: type,
          config: {
            items: customFieldConfig.customField.configuration?.values,
            mapItemToComponent(item) {
              return {
                value: item
              };
            },
            component: customFieldConfig.customField.configuration?.values ? "custom-field-value-chip" : ""
          }
        })
      );
    }

    const ticketNumber = Number(this.$route.query.ticketNumber as string);
    if (!isNaN(ticketNumber)) {
      await this.openTicketSideCard(ticketNumber);
    }
  }

  // identify strings formatted as NNNN-NN-NNTNN:NN
  isDayTimeString(value: string) {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(value);
  }

  getDateTime(value: string) {
    return simpleDoubleDigitDate(value) + " " + formatHoursAndMinutes(new Date(value));
  }

  goToVehicleDetail(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, this.partnerId, VehicleTabs.HOME, true);
  }
}
