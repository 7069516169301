var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('v-list-item', {
    key: 'PREVIOUS-MONTH-HEADER' + _vm.vehicleAggregation.vehicle.id + _vm.previousEvents.length
  }, [[_c('v-list-item-action', [_c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "icon": "",
      "small": "",
      "disabled": !_vm.previousEvents.length
    },
    on: {
      "click": function click($event) {
        _vm.isExpandTop = !_vm.isExpandTop;
      }
    }
  }, [_vm.isExpandTop ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-arrow-expand-down")]) : _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-arrow-expand-up")])], 1)], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_c('small', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTableEventsListItem.dates", {
    count: _vm.previousEvents.length,
    month: _vm.monthMethods.getPrevMonthName()
  })) + " ")])])], 1)]], 2), _vm.isExpandTop ? _c('div', _vm._l(_vm.previousEvents, function (event, index) {
    return _c('div', {
      key: 'fleet-home-vehicle-table-events-list-item-prev' + event.name + event.start + index
    }, [_c('fleet-home-vehicle-table-events-list-item', {
      attrs: {
        "event": event
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('openEventSideCard', event.data[0]);
        }
      }
    })], 1);
  }), 0) : _vm._e()], 1), _c('div', [_c('v-list-item', {
    key: 'CURRENT-MONTH-HEADER' + _vm.vehicleAggregation.vehicle.id + _vm.currentEvents.length
  }, [[_c('v-list-item-action', [_c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "icon": "",
      "small": "",
      "disabled": !_vm.currentEvents.length
    },
    on: {
      "click": function click($event) {
        _vm.isExpand = !_vm.isExpand;
      }
    }
  }, [_vm.isExpand ? _c('v-icon', [_vm._v("mdi-unfold-more-horizontal")]) : _c('v-icon', [_vm._v("mdi-unfold-less-horizontal")])], 1)], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_c('small', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTableEventsListItem.dates", {
    count: _vm.currentEvents.length,
    month: _vm.monthMethods.getCurrMonthName()
  })) + " ")])])], 1)]], 2), _vm.isExpand ? _c('div', _vm._l(_vm.currentEvents, function (event, index) {
    return _c('div', {
      key: 'fleet-home-vehicle-table-events-list-item-curr' + event.name + event.start + index
    }, [_c('fleet-home-vehicle-table-events-list-item', {
      attrs: {
        "event": event
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('openEventSideCard', event.data[0]);
        }
      }
    })], 1);
  }), 0) : _vm._e()], 1), _c('div', [_vm.isExpandedBottom ? _c('div', _vm._l(_vm.nextEvents, function (event, index) {
    return _c('div', {
      key: 'fleet-home-vehicle-table-events-list-item-next' + event.name + event.start + index
    }, [_c('fleet-home-vehicle-table-events-list-item', {
      attrs: {
        "event": event
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('openEventSideCard', event.data[0]);
        }
      }
    })], 1);
  }), 0) : _vm._e(), _c('v-list-item', {
    key: 'NEXT-MONTH-HEADER' + _vm.vehicleAggregation.vehicle.id + _vm.nextEvents.length
  }, [[_c('v-list-item-action', [_c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "icon": "",
      "small": "",
      "disabled": !_vm.nextEvents.length
    },
    on: {
      "click": function click($event) {
        _vm.isExpandedBottom = !_vm.isExpandedBottom;
      }
    }
  }, [_vm.isExpandedBottom ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-arrow-expand-up")]) : _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-arrow-expand-down")])], 1)], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_c('small', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTableEventsListItem.dates", {
    count: _vm.nextEvents.length,
    month: _vm.monthMethods.getNextMonthName()
  })) + " ")])])], 1)]], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }