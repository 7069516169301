var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-list-item', {
    on: {
      "click": _vm.generateQrCodes
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.downloadQr")) + " ")]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.confirmQrDownloadDialogActive,
      "title": _vm.$t('views.fleet.FleetHomeVehicleTable.addVehicle'),
      "rightDisabled": _vm.qrDownloadLoading || _vm.qrLinks.length === 0,
      "leftText": _vm.$t('close'),
      "rightText": _vm.$t('common.verbs.download')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.confirmQrDownloadDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.confirmQrDownloadDialogActive = $event;
      },
      "leftClick": _vm.close,
      "rightClick": _vm.downloadQrCodes,
      "close": _vm.close
    }
  }, [_c('div', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.qrLinks.length ? _vm.$t("components.utility.VehicleMultipleQrCodeDownload.linksGenerated", {
    links: _vm.qrLinks.length,
    vehicles: _vm.vehicles.length
  }) : _vm.$t("components.utility.VehicleMultipleQrCodeDownload.noLinksGenerated")) + " ")]), _vm.invalidVehicles.length ? _c('v-card-subtitle', [_vm._v(_vm._s(_vm.$t("components.utility.VehicleMultipleQrCodeDownload.vehiclesWithNoLinks")))]) : _vm._e(), _vm.qrLinks.length && _vm.invalidVehicles.length ? _c('v-virtual-scroll', {
    staticClass: "overflow-y-auto mb-4",
    attrs: {
      "height": "225",
      "item-height": "75",
      "items": _vm.invalidVehicles
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('refs-vehicle', {
          attrs: {
            "item": item
          },
          on: {
            "openDetail": function openDetail($event) {
              return _vm.goToVehicleDetail(item);
            }
          }
        })];
      }
    }], null, false, 1501130336)
  }) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }