







































import { requiredRule } from "@/lib/rules/requiredRule";
import { IInspection } from "@/models/inspection.entity";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CustomFieldDateTime from "../report/CustomFieldDateTime.vue";
import AddressForm from "./AddressForm.vue";
import TemplateEditor from "../template/TemplateEditor.vue";

@Component({
  components: { TemplateEditor, AddressForm, CustomFieldDateTime }
})
export default class InspectionForm extends Vue {
  @Prop()
  value!: IInspection;

  isValid = false;
  isAddressValid = false;

  @Watch("isValid")
  @Watch("isAddressValid")
  watchIsValid() {
    this.$emit("isValid", this.isValid && this.isAddressValid);
  }

  get requiredRule() {
    return [requiredRule()];
  }
}
