






































import TicketDetailCard from "@/components/project/TicketDetailCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ProjectInitializeListsMixin from "./mixins/ProjectInitializeListsMixin.vue";
import TicketCrudMixin from "./mixins/TicketCrudMixin.vue";
import ProjectGoToMixin from "./mixins/ProjectGoToMixin.vue";
import Debug from "@/components/utility/Debug.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { ITicket } from "@/models/ticket.entity";

@Component({
  components: {
    TheLayoutPortal,
    TicketDetailCard,
    Debug,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketDetailView extends mixins(
  ProjectInitializeListsMixin,
  TicketCrudMixin,
  ProjectGoToMixin,
  PartnerFallbackMixin
) {
  loadingTicket = true;

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
    this.projectId = this.$route.params.projectId;
    this.ticketNumber = this.$route.params.ticketNumber;
    this.viewId = Number(this.$route.params.viewId ?? 0);
  }

  async mounted() {
    this.trySetByRouteOrDefault();
    this.loadingTicket = true;

    this.$log.debug("initializeProjectLists@TicketDetailView.vue");
    await this.initializeProjectLists(this.partnerId);

    const number = Number(this.ticketNumber);
    if (!isNaN(number) && number >= 0) {
      const ticket = await this.getTicketByNumber(number);
      this.ticketId = ticket?.id ?? "";
    }
    this.loadingTicket = false;
  }

  /**
   * Delete ticket and navigate to list of tickets:
   * Either the project ticket table or the ticket table
   * @param ticket ticket object
   */
  async onDeleteTicket(ticket: ITicket) {
    const success = await this.deleteTicket(ticket);

    if (success) {
      this.goTo.ticketOrProjectTicketTable({
        partnerId: this.partnerId,
        projectId: this.projectId,
        viewId: this.viewId.toString(),
        newTab: false
      });
    }
  }
}
