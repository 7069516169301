
















import { Vue, Component } from "vue-property-decorator";
import { SettingModule } from "@/store/modules/setting.store";
import PartnerManageList from "@/components/partner/PartnerManageList.vue";
import PartnerManageDetailCard from "@/components/partner/PartnerManageDetailCard.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";

@Component({
  components: { TheLayoutPortalSplit, PartnerManageList, PartnerManageDetailCard }
})
export default class PartnerManageCard extends Vue {
  get allPartnerSettingsLoaded() {
    return SettingModule.allPartnerSettingsLoaded;
  }
}
