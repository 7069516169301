import { IBooking } from "@/models/booking.entity";

/**
 * Typescript types used in the BookingDetailCard component
 */
export interface BookingGoToActionEmitData {
  action: BookingMDetailActionEnum;
  booking: IBooking;
}

export interface BookingPortalFormEmitInputData {
  booking: IBooking;
  notifyUserForUpdate: boolean;
}

export enum BookingMDetailActionEnum {
  DELETE_BOOKING = "deleteBooking",
  GO_TO_EDIT = "goToEdit",
  GO_TO_OVERVIEW = "goToOverview",
  GO_TO_SERVICE = "goToService",
  GO_TO_RESOURCE = "goToResource",
  // Goes to detail of object from the side card
  OPEN_IN_NEW = "openInNew"
}

export enum BookingFormConfigCategoryEnum {
  BOOKING = "booking"
}

export enum BookingActionRecordIconEnum {
  DELETE = "mdi-trash-can",
  EDIT = "mdi-pencil",
  OPEN_IN_NEW = "mdi-open-in-new"
}
