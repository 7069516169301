import { Module, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/VuexPlugin";
import {
  MrfiktivPartnerCustomerUserViewModelGen,
  MrfiktivCustomerAccountControllerFindAllByPartnerIdParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import customerAccountService from "@/services/shared/customerAccountService";
import { PaginationFilterListElement, BasePagination } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { PartnerModule } from "./partner";
import { ReportModule } from "./report.store";

/**
 * Store for the end user customer account data.
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "customerAccountStore",
  store
})
export class CustomerAccountStore extends BasePagination<
  MrfiktivPartnerCustomerUserViewModelGen,
  MrfiktivCustomerAccountControllerFindAllByPartnerIdParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: MrfiktivPartnerCustomerUserViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;

  filterOptions: PaginationFilterListElement[] = [
    { key: "_id", type: PageFilterTypes.OBJECT_ID },
    { key: "firstName", type: PageFilterTypes.STRING },
    { key: "firstName", type: PageFilterTypes.STRING },
    { key: "userName", type: PageFilterTypes.STRING },
    { key: "countryCode", type: PageFilterTypes.STRING },
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "timestamp.created", type: PageFilterTypes.DATE },
    { key: "isActive", type: PageFilterTypes.BOOLEAN },
    { key: "isMarketingOptIn", type: PageFilterTypes.BOOLEAN }
  ].map(f => new PaginationFilterListElement(f));

  _currentAccount: MrfiktivPartnerCustomerUserViewModelGen | null = null;

  /**
   * All of the accounts of the user across partners
   */
  get accounts() {
    return this._paginationList;
  }

  /**
   * Selected customer account(TODO: remove, always check with API?)
   */
  get currentAccount() {
    return this._currentAccount;
  }

  get partnerId() {
    return ReportModule.partner._id || PartnerModule.partner._id;
  }

  @Mutation
  private _mutateCurrentAccount(account: MrfiktivPartnerCustomerUserViewModelGen) {
    this._currentAccount = account;
  }

  @Action
  protected async loadDocuments(query: MrfiktivCustomerAccountControllerFindAllByPartnerIdParamsGen) {
    const res = await customerAccountService.findAll({ ...query });
    return res;
  }

  @Action
  async getOne(id: string) {
    const account = await customerAccountService.findOne(this.partnerId, id);
    this.context.commit("_mutateCurrentAccount", account);
  }
}

export const CustomerAccountModule = getModule(CustomerAccountStore);
