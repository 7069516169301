




























import { MrfiktivLeasingContractGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";

import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "../utility/SideCard.vue";
import { ContractTypeEnum } from "@/lib/enum/contractType.enum";
import { ISignDocument } from "@/models/sign-document.entity";

@Component({
  components: { SideCard, ContractCard: () => import("./ContractCard.vue") },
  filters: {}
})
export default class ContractSideCard extends mixins(Vue) {
  @Prop()
  value!: MrfiktivLeasingContractGen;

  @Prop()
  loading!: boolean;

  @Prop()
  partnerId!: string;

  @Prop()
  vehicle?: IVehicle;

  @Prop()
  vehicleId!: string;

  @Prop({ default: () => [] })
  documentSuggestions!: ISignDocument[];

  @Prop({ default: false })
  loadingDocumentSuggestions!: boolean;

  readonly ContractTypeEnum = ContractTypeEnum;

  height = document.documentElement.clientHeight;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  onResize() {
    this.height = document.documentElement.clientHeight;
  }
}
