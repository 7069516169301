































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import PartnerReportDetailInfoDateCard from "@/components/partner/PartnerReportDetailInfoDateCard.vue";
import PartnerReportDetailInfoMessageCard from "@/components/partner/PartnerReportDetailInfoMessageCard.vue";
import PartnerRegistrationCard from "@/components/partner/PartnerRegistrationCard.vue";
import PartnerFormDetailImageCard from "@/components/partner/PartnerFormDetailImageCard.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    CustomerContactCard,
    PartnerReportDetailInfoMessageCard,
    PartnerReportDetailInfoDateCard,
    PartnerRegistrationCard,
    PartnerFormDetailImageCard
  }
})
export default class PartnerFormDetailInfoCard extends DarkModeHighlightMixin {
  renderComponent = true;
  registrationUpdateLoading = false;

  get form() {
    return PartnerModule.form;
  }

  get getImages() {
    return PartnerModule.form.attachments;
  }

  get getRegistration() {
    return PartnerModule.form?.registration;
  }

  get getRegistrationImageLink() {
    if (!PartnerModule.form?.registration?.url) {
      return "";
    } else {
      return PartnerModule.form?.registration.url;
    }
  }

  async updateRegistration(registration: RegistrationResultsEntity) {
    this.registrationUpdateLoading = true;
    if (!registration) {
      this.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    const partnerEntity = await PartnerModule.addRegistrationDetailsForForm({
      formId: this.form._id,
      registrationResults: registration
    });

    if (!partnerEntity) {
      this.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    this.registrationUpdateLoading = false;
  }
}
