var render = function () {
  var _this = this,
      _vm$report$handover;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', {
    staticStyle: {
      "overflow-y": "hidden"
    }
  }, [_vm.isLoading ? _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, actions, heading, table-row, list-item-two-line@4"
    }
  })], 1) : _vm.reports && _vm.vehicle ? _c('v-container', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "height": "calc(100vh - 66px)"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('m-header', {
    staticStyle: {
      "max-height": "100px"
    },
    attrs: {
      "title": _vm.title,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      }
    }
  }), _vm.bluePrintImageUrl ? _c('v-row', {
    staticClass: "align-start"
  }, [_c('v-col', [_c('m-image-marker', {
    key: "handoverTourMarker",
    ref: "handoverTourMarker",
    attrs: {
      "maxHeight": 300,
      "src": _vm.bluePrintImageUrl,
      "markers": _vm.markersSorted,
      "deactivated": true
    },
    on: {
      "markerClicked": _vm.markerClicked
    }
  })], 1)], 1) : _c('v-row', [_c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-two-line@4"
    }
  })], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.reportsSorted ? _c('v-col', {
    staticClass: "pa-0 ma-0 flex-grow-1",
    attrs: {
      "cols": _vm.isMobile ? 12 : 3,
      "no-gutters": ""
    }
  }, [!_vm.isImageLoaded ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }) : _vm._e(), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('div', [_vm.reportsSorted.length !== 0 ? _c('v-virtual-scroll', {
    attrs: {
      "items": _vm.reportsSorted,
      "height": _vm.height,
      "item-height": "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [_c('v-list-item', {
          key: item.id,
          staticClass: "item",
          class: index === _vm.reportIndex ? 'v-item--active v-list-item--active' : '',
          attrs: {
            "disabled": !_vm.isImageLoaded
          },
          on: {
            "click": function click($event) {
              return _vm.selectReport(index);
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [item.handover ? _c('span', [_vm._v(" #" + _vm._s(item.handover.number) + " ")]) : _vm._e(), _c('v-chip', {
          attrs: {
            "x-small": "",
            "color": _vm.color(item.progressStatus),
            "elevation": 0,
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerReportDetailChangeProgress.".concat(item.progressStatus))) + " ")]), item.isFixed ? _c('v-chip', {
          attrs: {
            "x-small": "",
            "color": "primary",
            "elevation": 0,
            "dark": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v(" " + _vm._s(item.isFixed ? "mdi-bookmark-check-outline" : "mdi-bookmark-outline") + " ")]), _vm._v(" " + _vm._s(item.isFixed ? _vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isFixed") : _vm.$t("components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.isNotFixed")) + " ")], 1) : _vm._e()], 1), _c('v-list-item-title', [_vm._v(_vm._s(item.title) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(item.message))])], 1)], 1)];
      }
    }], null, false, 766529934)
  }) : _vm._e()], 1)])], 1) : _vm._e(), !_vm.isMobile ? _c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "9"
    }
  }, [!_vm.isImageLoaded ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }) : _vm._e(), _vm.report ? _c('handover-report-card', {
    key: _vm.report.id,
    attrs: {
      "value": _vm.report,
      "showMarker": false,
      "showImage": _vm.isImageLoaded,
      "showActions": true,
      "prevDisabled": !_vm.isImageLoaded || _vm.reportIndex === 0,
      "nextDisabled": !_vm.isImageLoaded || _vm.reportIndex === _vm.reportsSorted.length - 1
    },
    on: {
      "prev": function prev($event) {
        return _vm.selectReport(_vm.reportIndex - 1);
      },
      "next": function next($event) {
        return _vm.selectReport(_vm.reportIndex + 1);
      }
    }
  }) : !_vm.report && _vm.reports.length > 0 ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("common.verbs.select")) + " ")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "elevation": 0,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.selectReport(0);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("start")))])], 1)], 1) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('latest-entries-card-empty')], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "elevation": 0,
      "color": "primary"
    },
    on: {
      "click": _vm.fetchReports
    }
  }, [_vm._v(_vm._s(_vm.$t("common.verbs.refresh")))])], 1)], 1), _c('debug', [_vm._v(" " + _vm._s(_vm.report) + " ")])], 1) : _vm._e()], 1), _vm.isMobile ? _c('v-bottom-sheet', {
    directives: [{
      name: "touch",
      rawName: "v-touch",
      value: _vm.closeOnSwipe,
      expression: "closeOnSwipe"
    }],
    attrs: {
      "subcontainer": ""
    },
    model: {
      value: _vm.isBottomSheetOpen,
      callback: function callback($$v) {
        _vm.isBottomSheetOpen = $$v;
      },
      expression: "isBottomSheetOpen"
    }
  }, [_vm.report ? _c('v-card', {
    directives: [{
      name: "touch",
      rawName: "v-touch",
      value: {
        down: function down() {
          return _this.isBottomSheetOpen = false;
        }
      },
      expression: "{ down: () => (this.isBottomSheetOpen = false) }"
    }],
    staticClass: "pa-0",
    staticStyle: {
      "height": "550px",
      "overflow-y": "auto",
      "overflow-x": "hidden"
    },
    attrs: {
      "flat": ""
    }
  }, [!_vm.isImageLoaded ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "swipe-indicator my-1"
  }), _c('v-card-title', {
    on: {
      "click": function click($event) {
        return _vm.goToReportDetail(_vm.report);
      }
    }
  }, [_vm._v(" #" + _vm._s((_vm$report$handover = _vm.report.handover) === null || _vm$report$handover === void 0 ? void 0 : _vm$report$handover.number) + " " + _vm._s(_vm.report.title) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": !_vm.mapToImage.length,
      "text": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.scrollToImages.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.mapToImage.length) + " "), _c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("mdi-image-outline")])], 1)], 1), _c('div', {
    staticStyle: {
      "height": "429px",
      "overflow-y": "auto",
      "overflow-x": "hidden"
    }
  }, [_vm.report.position && _vm.report.handover ? _c('v-card-subtitle', [_c('m-image-marker', {
    attrs: {
      "deactivated": true,
      "readonly": true,
      "markerSize": "large",
      "src": _vm.report.position.blueprint.image.url,
      "markers": _vm.report.position.coordinates && _vm.report.position.coordinates.x && _vm.report.position.coordinates.y ? [{
        left: _vm.report.position.coordinates.x,
        top: _vm.report.position.coordinates.y,
        text: _vm.report.handover.number
      }] : []
    }
  })], 1) : _vm._e(), _c('partner-report-detail-change-progress', {
    key: _vm.report.id,
    staticClass: "px-4",
    model: {
      value: _vm.report,
      callback: function callback($$v) {
        _vm.report = $$v;
      },
      expression: "report"
    }
  }), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.report.message) + " ")]), _vm.isImageLoaded && _vm.mapToImage && _vm.mapToImage.length > 0 ? _c('v-card-text', {
    staticStyle: {
      "max-height": "80px"
    }
  }, [_c('vue-picture-swipe', {
    attrs: {
      "id": "findingImages",
      "items": _vm.mapToImage,
      "options": {
        shareEl: false,
        rotationOn: true
      }
    }
  })], 1) : _vm._e()], 1), _c('v-card-actions', [_vm.reportIndex > 0 ? _c('v-btn', {
    attrs: {
      "small": "",
      "elevation": "0",
      "disabled": !_vm.isImageLoaded
    },
    on: {
      "click": function click($event) {
        return _vm.selectReport(_vm.reportIndex - 1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": "0",
      "disabled": _vm.reportIndex >= _vm.reportSize - 1 || !_vm.isImageLoaded,
      "color": "primary",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.selectReport(_vm.reportIndex + 1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('tour-handover-finding-dialog', {
    ref: "tourDialog",
    attrs: {
      "vehicles": _vm.vehicles,
      "handover": _vm.handover
    }
  }), _c('create-handover-finding-dialog', {
    ref: "findingCreateDialog",
    attrs: {
      "vehicles": _vm.vehicles,
      "handover": _vm.handover,
      "blueprint": _vm.blueprint
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }