




































































































import { Component, Vue, Prop } from "vue-property-decorator";
import PartnerManageStepperPredefinedSettingStep from "@/components/partner/PartnerManageStepperPredefinedSettingStep.vue";
import PartnerManageStepperCustomSettingStep from "@/components/partner/PartnerManageStepperCustomSettingStep.vue";
import PartnerManageStepperDescriptionStep from "@/components/partner/PartnerManageStepperDescriptionStep.vue";
import PartnerManageStepperVerifyCredentialsStep from "@/components/partner/PartnerManageStepperVerifyCredentialsStep.vue";
import PartnerManageStepperSetupStep from "@/components/partner/PartnerManageStepperSetupStep.vue";
import { ManageStepperScreenTypes } from "@/store/enum/partner/manage-stepper-screen-types.enum";
import { IPartnerManageStepperStep } from "@/lib/interfaces/partner/partnerManageStepperStep.interface";
import PartnerManageStepperSlotStep from "./PartnerManageStepperSlotStep.vue";

/**
 * Read 'ManagerSetupWizard.md' for information on how to use this component and create a new setup-stepper
 */
@Component({
  components: {
    PartnerManageStepperDescriptionStep,
    PartnerManageStepperPredefinedSettingStep,
    PartnerManageStepperSlotStep,
    PartnerManageStepperCustomSettingStep,
    PartnerManageStepperVerifyCredentialsStep,
    PartnerManageStepperSetupStep
  }
})
export default class PartnerManageStepper extends Vue {
  /**
   * steps of setup assitant
   */
  @Prop()
  steps!: IPartnerManageStepperStep[];

  /**
   * show loading animation if true
   */
  loadingActive = false;

  /**
   * current step of setup assitant
   */
  currentStep = 0;

  /**
   * Is screen medium size or bigger
   */
  get isBigScreen() {
    return !(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm);
  }

  /**
   * (de-)activates loading animation
   */
  setLoading(value: boolean) {
    this.loadingActive = value;
  }

  /**
   * open next page in assistant
   */
  async goToNext() {
    this.setLoading(true);
    this.currentStep++;
  }

  /**
   * open previous page in assistant
   */
  async goToPrevious() {
    this.setLoading(true);
    this.currentStep--;
  }

  /**
   * ends stepper and goes back to manager
   */
  end() {
    this.$router.push({ name: "PartnerManage" });
  }

  /**
   * Determines if setting tile is shown
   */
  isStepActive(step: any, index: number, stepType: string) {
    return step.type === ManageStepperScreenTypes[stepType] && this.currentStep === index;
  }
}
