var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    directives: [{
      name: "resize",
      rawName: "v-resize",
      value: _vm.onResize,
      expression: "onResize"
    }],
    attrs: {
      "width": !_vm.fullscreenBreakpoint ? '1200px' : '100%',
      "loading": _vm.loading || _vm.loadingCreateVirtual,
      "hideTitle": true,
      "fullscreen": _vm.fullscreenBreakpoint,
      "absolute": true
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "pa-1",
    style: "height: calc(".concat(_vm.height, "px - ").concat(_vm.fullscreenBreakpoint ? '4px' : '68px', "); overflow-y: auto; overflow-x:hidden")
  }, [_c('event-card', {
    staticClass: "ma-1",
    attrs: {
      "hideBreadcrumbs": true,
      "hideInNewTabButton": false,
      "value": _vm.value,
      "showTitleEdit": true
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      },
      "delete": function _delete($event) {
        return _vm.$emit('delete', $event);
      },
      "goToDetail": function goToDetail($event) {
        return _vm.$emit('goToDetail', $event);
      },
      "goToEdit": function goToEdit($event) {
        return _vm.$emit('goToEdit', $event);
      },
      "acknowledged": _vm.onAcknowledged
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }