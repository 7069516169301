





























































































































































































import MDetailForm from "@/components/utility/mmmint/MDetailForm.vue";
import { $t } from "@/lib/utility/t";
import { CurrencyUnitEnum } from "@/store/enum/thg/currency-unit.enum";
import DocumentSelectionDialog from "@/views/sign/DocumentSelectionDialog.vue";
import { Component } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import MActionList from "../utility/mmmint/MActionList.vue";
import MDetailTable from "../utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "../utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import ContractCard from "./ContractCard.vue";
import ContractCardDocument from "./ContractCardDocument.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IMDetailFormConfig, MDetailFormConfig } from "@/lib/formable";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";

@Component({
  components: {
    LatestEntriesCardEmpty,
    MHeader,
    MDetailForm,
    DocumentSelectionDialog,
    ContextMenu,
    ConfirmActionDialog,
    MDetailViewGrid,
    MDetailTable,
    MActionList,
    ContractCardDocument
  },
  filters: {}
})
export default class ContractForm extends ContractCard {
  readonly KEYS = {
    NUMBER: "number",
    TITLE: "title",
    START_MILEAGE: "startMilage",
    MAX_MILEAGE: "maxMilage",
    FREE_MILEAGE_ALLOWANCE: "freeMileageAllowance",
    MAX_REIBURSED_MILEGE: "maxReimbursedMilage",
    CURRENCY: "currency",
    PURCHASE_PRICE: "purchasePrice",
    BUYBACK_PRICE: "buybackPrice",
    CREDIT_FOR_REIMBURSED_MILAGE: "creditForReimbursedMilage",
    EXCESS_MILEAGE_COSTS: "excessMileageCosts",
    INSURANCE_COST: "insuranceCost",
    FINANCING_COST: "financingCost",
    FUEL_COST: "fuelCost",
    TIRES_COST: "tiresCost",
    MISC_COST: "miscCost",
    START_DATE: "startDate",
    END_DATE: "endDate"
  };

  config: IMDetailFormConfig[] = [
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.generalTitle").toString(),
      key: this.KEYS.TITLE,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.generalTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.title").toString()
      ],
      model: this.value.title,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.title").toString(),
        rules: this.required
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.generalTitle").toString(),
      key: this.KEYS.NUMBER,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.generalTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.number").toString()
      ],
      model: this.value.number,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.number").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.mileageTitle").toString(),
      key: this.KEYS.START_MILEAGE,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.mileageTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.startMilage").toString()
      ],
      model: this.value.startMilage,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.startMilage").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.mileageTitle").toString(),
      key: this.KEYS.MAX_MILEAGE,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.mileageTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.maxMilage").toString()
      ],
      model: this.value.maxMilage,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.maxMilage").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.mileageTitle").toString(),
      key: this.KEYS.FREE_MILEAGE_ALLOWANCE,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.mileageTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.freeMileageAllowance").toString()
      ],
      model: this.value.freeMileageAllowance,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.freeMileageAllowance").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.mileageTitle").toString(),
      key: this.KEYS.MAX_REIBURSED_MILEGE,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.mileageTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.maxReimbursedMilage").toString()
      ],
      model: this.value.maxReimbursedMilage,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.maxReimbursedMilage").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceTitle").toString(),
      key: this.KEYS.PURCHASE_PRICE,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.purchasePrice").toString()
      ],
      model: this.value.purchasePrice,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.purchasePrice").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceTitle").toString(),
      key: this.KEYS.BUYBACK_PRICE,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.buybackPrice").toString()
      ],
      model: this.value.buybackPrice,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.buybackPrice").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceTitle").toString(),
      key: this.KEYS.CREDIT_FOR_REIMBURSED_MILAGE,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.creditForReimbursedMilage").toString()
      ],
      model: this.value.creditForReimbursedMilage,
      props: {
        label: this.$t(
          "components.fleet.FleetVehicleDetailContractTableLeasingInputs.creditForReimbursedMilage"
        ).toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceTitle").toString(),
      key: this.KEYS.EXCESS_MILEAGE_COSTS,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.excessMileageCosts").toString()
      ],
      model: this.value.excessMileageCosts,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.excessMileageCosts").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
      key: this.KEYS.INSURANCE_COST,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.insuranceCost").toString()
      ],
      model: this.value.insuranceCost,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.insuranceCost").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
      key: this.KEYS.FINANCING_COST,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.financingCost").toString()
      ],
      model: this.value.financingCost,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.financingCost").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
      key: this.KEYS.FUEL_COST,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.fuelCost").toString()
      ],
      model: this.value.fuelCost,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.fuelCost").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
      key: this.KEYS.TIRES_COST,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.tiresCost").toString()
      ],
      model: this.value.tiresCost,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.tiresCost").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
      key: this.KEYS.MISC_COST,
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: [
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.priceDetailTitle").toString(),
        this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.miscCost").toString()
      ],
      model: this.value.miscCost,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.miscCost").toString(),
        type: "number"
      }
    },
    {
      category: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.currency").toString(),
      key: this.KEYS.CURRENCY,
      type: DetailFormComponentsEnum.SELECT_FIELD,
      searchKeywords: [this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.currency").toString()],
      model: this.value.currency,
      props: {
        label: this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs.currency").toString(),
        items: Object.values(CurrencyUnitEnum)
      }
    }
  ];

  get required() {
    return [requiredRule()];
  }

  get breadCrumbs() {
    if (this.hideBreadcrumbs) return undefined;
    return [
      ...this.baseCrumbs,
      {
        text: this.$t("common.nouns.contract").toString(),
        exact: true,
        disabled: false,
        to: {
          name: "FleetContractDetailView",
          params: { partnerId: this.partnerId, vehicleId: this.vehicle?.id, contractId: this.value.id }
        }
      },
      {
        text: this.$t("common.nouns.detail").toString(),
        exact: true,
        disabled: true,
        to: {
          name: "FleetContractDetailView",
          params: { partnerId: this.partnerId, vehicleId: this.vehicle?.id, contractId: this.value.id }
        }
      }
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];
    actions.push({
      text: $t("project.ticket.actions.toOverview"),
      key: this.TO_DETAIL_KEY,
      icon: "mdi-open-in-new",
      color: ""
    });
    actions.push({ text: $t("common.verbs.delete"), key: this.DELETE_KEY, icon: "mdi-trash-can", color: "red" });
    return actions;
  }

  processAction(action: IAction) {
    switch (action.key) {
      case this.TO_DETAIL_KEY:
        new GoToHelper(this.$router).goToContractDetail(this.partnerId, this.vehicle?.id, this.value.id);
        break;
      case this.TO_DETAIL_DETAIL:
        new GoToHelper(this.$router).goToContractDetailDetail(
          this.partnerId,
          this.vehicle?.id ?? this.vehicleId,
          this.value.id,
          false
        );
        break;
      case this.DELETE_KEY:
        this.isConfirmDeleteContractDialogActive = true;
        break;
    }
  }

  endDateBiggerThanStartDate(value: any) {
    return value > this.startDateCopy || $t("components.fleet.contract.dateWarning");
  }

  askForConfirmation() {
    if (!this.$refs.contractDetailForm) return;
    (this.$refs.contractDetailForm as MDetailForm<undefined>).changesDetected = true;
  }

  resetStartDate() {
    this.startDateCopy = "";
    this.askForConfirmation();
  }

  resetEndDate() {
    this.endDateCopy = "";
    this.askForConfirmation();
  }

  abortChanges() {
    const config = new MDetailFormConfig(this.config);
    for (const key of Object.values(this.KEYS)) {
      config.configValueByKey = { key: key, value: this.value[key] };
    }
    this.startDateCopy = this.value.startDate ?? "";
    this.endDateCopy = this.value.endDate ?? "";
  }

  syncChanges() {
    const config = new MDetailFormConfig(this.config);
    for (const key of Object.values(this.KEYS)) {
      this.value[key] = config.getConfigValueByKey(key);
    }
    this.value.startDate = this.startDateCopy;
    this.value.endDate = this.endDateCopy;
    this.$emit("save", this.value);
  }
}
