/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * describes the quality of the anonymization
 */
export enum AnonymizationResultQualityEnum {
  /**
   * quality is undetermined. should be used if no faces and or numberplates are recognized
   */
  UNDETERMINED = "Undetermined",

  /**
   * easy to recognizeable faces and numberplates are not blurred
   */
  POOR = "Poor",

  /**
   * result quality is fair but not good. Medium hard to recognizeable faces or numberplates are not blurred
   */
  FAIR = "Fair",

  /**
   * only partial numberpaltes or faces are not covered
   */
  GOOD = "Good",

  /**
   * all numberplates and faces are anonymized
   */
  EXCELLENT = "Excellent"
}
