





















import { Component, Prop } from "vue-property-decorator";
import PartnerManageStepperMixin from "@/mixins/PartnerManageStepperMixin.vue";
import PartnerManageStepperStepLayout from "@/components/partner/PartnerManageStepperStepLayout.vue";
import { SettingModule } from "@/store/modules/setting.store";
import { ManageStepperEnum } from "@/store/enum/partner/manage-stepper.enum";

@Component({
  components: { PartnerManageStepperStepLayout }
})
export default class PartnerManageStepperVerifyCredentialsStep extends PartnerManageStepperMixin {
  @Prop()
  context!: ManageStepperEnum;

  get result() {
    return SettingModule.setupResults;
  }

  get goBackFunction() {
    if (this.result) return undefined;
    return this.goToPrevious;
  }

  get goNextFunction() {
    return this.result ? this.goToNext : this.end;
  }

  get goNextButtonLabel() {
    return this.result
      ? String(this.$t("components.partner.PartnerManageStepper.StepLayout.end"))
      : String(this.$t("components.partner.PartnerManageStepper.StepLayout.end"));
  }

  get goNextButtonIcon() {
    return this.result ? "mdi-arrow-right" : "mdi-cancel";
  }

  /**
   * load and prefill the current setting of the step
   */
  async mounted() {
    this.setLoading(true);

    await SettingModule.setup(this.context);

    this.setLoading(false);
  }

  successful = undefined;
}
