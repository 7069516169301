

















































import { Component, Prop, Vue } from "vue-property-decorator";

export interface IImportOverviewConfig {
  category: string;
  items: {
    title: string;
    description: string;
    component: string;
    icon?: string;
    template?: () => void;
    hasPermission: boolean;
  }[];
}

@Component({
  components: {}
})
export default class ImportOverviewCard extends Vue {
  @Prop({ default: "" })
  title!: string;

  @Prop({ default: "" })
  goBack!: string;

  @Prop()
  importConfig!: IImportOverviewConfig[];

  goBackTo() {
    this.$router.push({ name: this.goBack });
  }

  hasPermissionOnItemInCategory(config: IImportOverviewConfig) {
    for (const item of config.items) {
      if (item.hasPermission) {
        return true;
      }
    }
    return false;
  }
}
