import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { UpdateDto } from "@/lib/utility/data/update-dto.interface";
import {
  MrfiktivMileageGen,
  MrfiktivUpdateVehicleDtoGen,
  MrfiktivUpdateVehicleRegistrationDtoGen,
  MrfiktivVehicleDocumentSchemaGen,
  MrfiktivVehicleViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { UpdateVehicleRegistrationDto } from "./update-vehicle-registration.dto";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import { handleError } from "@/lib/utility/handleError";

@IsFormable
class UpdateVehicle extends UpdateDto<MrfiktivVehicleViewModelGen> implements MrfiktivUpdateVehicleDtoGen {
  /** Vehicle identification Number */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: [
      "common.nouns.configuration",
      "objects.vehicle.identificationnumber",
      "objects.vehicle.registration.identificationnumber"
    ],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.identificationnumber",
      rules: []
    }
  })
  identificationnumber?: string;

  /** Some name for the vehicle */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.displayName"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.displayName",
      rules: []
    }
  })
  displayName?: string;

  /**
   * When was the vehicle comissioned
   * @example 2017-06-07T14:34:08.700Z
   */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.commissioningDate"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.commissioningDate",
      rules: [],
      type: "date"
    }
  })
  commissioningDate?: string;

  /** The status of the vehicle */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.state"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      required: false,
      label: "objects.vehicle.state",
      rules: [],
      items: Object.values(VehicleStateEnum)
    }
  })
  state?: "upcoming" | "active" | "partial" | "inactive" | "deleted";

  /** Is the vehicle currently available */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.available"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.available",
      rules: [],
      items: [true, false],
      type: "boolean"
    }
  })
  available?: boolean;

  /** Tags to group vehicles */
  tags?: string[];

  /** A list of mileages to track usage */
  mileages?: MrfiktivMileageGen[];

  /** A driver assigned to the vehicle. */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.currentDriver"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.currentDriver",
      rules: []
    }
  })
  currentDriver?: string;

  /** A list of drivers assigned to the vehicle. */
  drivers?: string[];

  /** A description field for the vehicle. */
  @FormConfig({
    category: "common.nouns.note",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.note"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.note",
      rules: []
    }
  })
  note?: string;

  /** Identifies if the vehicle is a commercially used vehicle, e.g. a Taxi */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.isCommercial"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.isCommercial",
      rules: [],
      items: [true, false],
      type: "boolean"
    }
  })
  isCommercial?: boolean;

  /** Identifies if the vehicle is a rental vehicle */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.isRental"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.isRental",
      rules: [],
      items: [true, false],
      type: "boolean"
    }
  })
  isRental?: boolean;

  /** identifier of the vehicle */
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: [
      "common.nouns.configuration",
      "objects.vehicle.numberplate",
      "objects.vehicle.registration.numberplate"
    ],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: false,
      label: "objects.vehicle.registration.numberplate",
      rules: []
    }
  })
  numberplate?: string;

  /** documents. */
  documents?: MrfiktivVehicleDocumentSchemaGen[];

  /** Our Registration document */

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.type"],
    type: UpdateVehicleRegistrationDto
  })
  registration?: MrfiktivUpdateVehicleRegistrationDtoGen;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.partnerId"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: true,
      label: "objects.vehicle.partnerId",
      rules: []
    }
  })
  partnerId: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.vehicle.vehicleId"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: true,
      label: "objects.vehicle.vehicleId",
      rules: []
    }
  })
  id: string;

  isUpdateable = true;

  constructor(data: UpdateVehicle) {
    super();
    this.available = data.available;
    this.commissioningDate = data.commissioningDate;
    this.currentDriver = data.currentDriver;
    this.displayName = data.displayName;
    this.documents = data.documents;
    this.drivers = data.drivers;
    this.identificationnumber = data.identificationnumber;
    this.isCommercial = data.isCommercial;
    this.isRental = data.isRental;
    this.mileages = data.mileages;
    this.note = data.note;
    this.numberplate = data.numberplate;
    this.registration = data.registration ? new UpdateVehicleRegistrationDto(data.registration) : undefined;
    this.state = data.state;
    this.tags = data.tags;
    this.partnerId = data.partnerId;
    this.id = data.id;
  }

  async update(silent?: boolean | undefined): Promise<MrfiktivVehicleViewModelGen | undefined> {
    try {
      const dto: MrfiktivUpdateVehicleDtoGen = {
        available: this.available,
        commissioningDate: this.commissioningDate,
        currentDriver: this.currentDriver,
        displayName: this.displayName,
        documents: this.documents,
        drivers: this.drivers,
        identificationnumber: this.identificationnumber,
        isCommercial: this.isCommercial,
        isRental: this.isRental,
        mileages: this.mileages,
        note: this.note,
        numberplate: this.numberplate,
        registration: this.registration,
        state: this.state,
        tags: this.tags
      };

      const res = await vehicleService.update(this.partnerId, this.id, dto);

      return res;
    } catch (e) {
      handleError(e);

      if (!silent) throw e;
    }
  }
}

export const UpdateVehicleDto = Form.createForClass(UpdateVehicle);
