import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { Anonymization, IAnonymization } from "@/models/anonymization.entity";

export const AnonymizationDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IAnonymization> {
  protected mapEntity(entity: IAnonymization): IAnonymization {
    return new Anonymization(entity);
  }

  getIdentifier(entity: IAnonymization): string {
    return entity.id;
  }

  mapsConfig = {};
})();
