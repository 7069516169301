






import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import ResourceTable from "./ResourceTable.vue";

@Component({
  components: {
    TheLayoutPortal,
    ResourceTable
  }
})
export default class ResourceTableView extends PartnerFallbackMixin {
  loading = false;

  async mounted() {
    this.loading = true;
    await this.trySetByRouteOrDefault();
    this.loading = false;
  }
}
