















































import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerFinderModule } from "@/store/modules/partner-link-finder.store";
import { Component } from "vue-property-decorator";
import { debounce } from "debounce";

@Component({
  components: {
    TheLayoutPortal
  }
})
export default class PartnerFinderView extends PartnerFallbackMixin {
  loading = false;
  search = "";
  distance = 100;

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get partners() {
    return PartnerFinderModule.partners;
  }

  get headers() {
    return [
      { text: this.$t("objects.partner.companyName"), value: "companyName" },
      { text: this.$t("objects.partner.address"), value: "address" },
      { text: this.$t("objects.partner.distance"), value: "distance" }
    ];
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
  }

  debounceFind = debounce(this.find, 1500);

  async find() {
    if (!this.search) {
      return;
    }

    if (!this.partnerId) {
      this.$toast.error("Select partner"); // FIXME: Translate
      return;
    }

    this.loading = true;

    try {
      const res = await PartnerFinderModule.findPartners({
        partnerId: this.partnerId,
        searchstring: this.search,
        maxDistance: this.distance
      });

      if (res.length <= 0) {
        this.$toast.warning("Not found"); // FIXME: Translate
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
