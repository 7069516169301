var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "wrapper"
  }, [_vm.form._id ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.form.name))])]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.date) + " "), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.displayToDetailView ? _c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.toDetailView
          }
        }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1) : _vm._e()];
      }
    }], null, false, 2415190056)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerFormDetail.tooltip.toDetailView")) + " ")])]), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.downloadExport
          }
        }, on), [_c('v-icon', [_vm._v("mdi-download-circle-outline")])], 1)];
      }
    }], null, false, 3152893012)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerFormDetail.tooltip.downloadExport")) + " ")])]), _vm.isTemplateActive ? _c('template-dialog', {
    ref: "templateDialog",
    attrs: {
      "partnerId": _vm.partner._id,
      "context": _vm.templateDialogMethods.context,
      "to": _vm.templateDialogMethods.to,
      "from": _vm.templateDialogMethods.from
    }
  }) : _vm._e()], 1), _c('v-card', {
    staticClass: "scrollable virtual-scroller",
    attrs: {
      "flat": ""
    }
  }, [_c('partner-form-detail-info-card')], 1)], 1) : _c('v-card', {
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-no-wrap justify-space-between"
  }, [_c('div', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerFormDetail.else.title")))]), _c('v-card-subtitle', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerFormDetail.else.subtitle")))]), _vm.forms.length > 0 ? _c('v-card-actions', [_c('v-btn', {
    staticClass: "ml-2 mt-5",
    attrs: {
      "outlined": "",
      "rounded": "",
      "small": ""
    },
    on: {
      "click": _vm.setToLatestEntry
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerFormDetail.else.action")) + " ")])], 1) : _vm._e()], 1), _c('v-avatar', {
    staticClass: "ma-3 mr-8",
    attrs: {
      "size": "200",
      "tile": ""
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "src": require("@/assets/undraw/undraw_text_files_au1q.svg")
    }
  })], 1)], 1)]), _vm.forms.length == 0 ? _c('div', {
    staticClass: "emptyDataContainer"
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": require("@/assets/undraw/undraw_no_data_re_kwbl.svg"),
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }], null, false, 3142964100)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerFormDetail.else.noData")))])])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }