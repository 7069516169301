var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "ma-2 pa-2",
    attrs: {
      "outlined": "",
      "min-height": "190"
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isChartShown ? _c('div', [_c('v-card-title', {
    staticClass: "text-overline"
  }, [_vm._v(" " + _vm._s(_vm.i18n["title"]) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.isChartShown = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-right")])], 1)], 1), _vm.contractHelper.mileagePerDay ? _c('v-chart', {
    staticStyle: {
      "min-height": "500px"
    },
    attrs: {
      "loading": false,
      "option": _vm.chartOption,
      "autoresize": ""
    }
  }) : _c('latest-entries-card-empty', {
    staticClass: "mt-n2"
  })], 1) : _vm._e()]), _c('v-slide-x-reverse-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.isChartShown ? _c('div', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.i18n["title"]) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.isChartShown = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1)], 1), _c('v-list', _vm._l(_vm.statisticList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "ml-2 mr-2 mb-n1"
    }, [_c('v-list-item', [_vm._v(" " + _vm._s(item.label)), _c('v-spacer'), _c('span', {
      style: "color:".concat(item.color)
    }, [_vm._v(" " + _vm._s(item.value) + " ")])], 1), index !== _vm.statisticList.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 0)], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }