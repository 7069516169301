































































































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { CostGroup } from "@/models/cost-group.entity";
import { ICost } from "@/models/cost.entity";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { CostGroupModule } from "@/store/modules/cost-group.store";
import { CostModule } from "@/store/modules/cost.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import RefsCostGroup from "../utility/RefsCostGroup.vue";
import RefsCost from "../utility/RefsCost.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import CostCreateDialog from "./CostCreateDialog.vue";
import CostSideCard from "./CostSideCard.vue";
import CostChip from "./CostChip.vue";
import { handleError } from "@/lib/utility/handleError";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import { CostTypeEnum } from "@/lib/enum/cost-type.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import Debug from "@/components/utility/Debug.vue";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import { Vehicle } from "@/models/vehicle.entity";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { IVehicle } from "@/models/vehicle.entity";
import { IReference } from "@/models/reference.entity";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    CostCreateDialog,
    CostSideCard,
    RefsCostGroup,
    RefsCost,
    CostChip,
    Debug,
    RefsVehicle
  }
})
export default class CostTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = CostModule;

  readonly CostTypeEnum = CostTypeEnum;

  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop()
  partnerId!: string;

  @Prop()
  selectedItems!: ICost[];

  @Prop({ default: false })
  showVehicleHeader!: boolean;

  @Prop({ default: () => [] })
  refs!: IReference[];

  selectedItem: ICost | null = null;

  selectedItemForDelete: ICost | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  isCostCreateDialogActive = false;

  get selectedItemsLocal(): ICost[] {
    return this.selectedItems;
  }

  set selectedItemsLocal(value: ICost[]) {
    this.$emit("update:selectedItems", value);
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.cost.title"),
      value: "title"
    });
    if (this.showVehicleHeader) {
      headers.push({
        text: $t("common.nouns.vehicle"),
        value: "refs.refId",
        align: "end",
        sortable: false
      });
    }
    headers.push({
      text: $t("objects.cost.total"),
      value: "total",
      align: "end"
    });
    return headers;
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: $t("report.ThgChargingStationDetailCard.toDetail"),
        action: (selectedItem: ICost) =>
          new CostGoToHelper(this.$router).goToCostDetail({
            costId: selectedItem.id,
            partnerId: selectedItem.partnerId,
            newTab: true
          })
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [];

    return predefinedFilter;
  }

  async getCostGroup(id: string) {
    if (!id) return;
    let costGroup = CostGroupModule.maps.id.get(id)[0];
    if (!costGroup) {
      costGroup = new CostGroup({ partnerId: this.partnerId, id });
      costGroup.fetch();
    }

    return costGroup;
  }

  getVehicleById(vehicleId: string) {
    let vehicle = VehicleAccessLayer.maps.id.get(vehicleId)[0];

    if (!vehicle) {
      vehicle = new Vehicle({ id: vehicleId, partnerId: this.partnerId });
      VehicleAccessLayer.set(vehicle);
      vehicle.fetch().catch(this.$log.error);
    }

    return vehicle;
  }

  goToVehicleDetail(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, VehicleTabs.HOME, true);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  getTotalForItems(items: ICost[]) {
    let total = 0;

    for (const item of items) {
      if (item.expenseOrIncome === CostTypeEnum.EXPENSE) total -= item.absoluteTotal;
      else total += item.absoluteTotal;
    }

    return total;
  }

  setSelectedItem(item: ICost | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  async onDeleteItem(item: ICost) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
