import { VehicleTypes } from "@/store/enum/vehicle-types.enum";
import { IThgVehicleClass } from "../interfaces/thg/thg-vehicle-class.interface";

/**
 * Maps a vehicle class to text and icon
 */
export const vehicleClassMap = new Map<VehicleTypes, IThgVehicleClass>([
  [
    VehicleTypes.M1,
    {
      text: "PKW (M1)",
      icon: "mdi-car"
    }
  ],
  [
    VehicleTypes.N1,
    {
      text: "LNF (N1)",
      icon: "mdi-truck"
    }
  ],
  [
    VehicleTypes.N2,
    {
      text: "SNF (N2)",
      icon: "mdi-truck"
    }
  ],
  [
    VehicleTypes.N3,
    {
      text: "SNF (N3)",
      icon: "mdi-truck"
    }
  ],
  [
    VehicleTypes.M3,
    {
      text: "Bus (M3)",
      icon: "mdi-bus-side"
    }
  ],
  [
    VehicleTypes.O,
    {
      text: "Anhänger (O)",
      icon: "mdi-truck-trailer"
    }
  ],
  [
    VehicleTypes.other,
    {
      text: "Andere",
      icon: "mdi-motorbike"
    }
  ]
]);
