

























import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { IVehicle } from "@/models/vehicle.entity";

import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop, Vue } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import LabelComponent from "../utility/LabelComponent.vue";
import FleetVehicleDetailContextMenu from "./FleetVehicleDetailContextMenu.vue";
import FleetVehicleDriverDetailTable from "./FleetVehicleDriverDetailTable.vue";

@Component({
  components: {
    ContextMenu,
    FleetVehicleDetailContextMenu,
    LabelComponent,
    FleetVehicleDriverDetailTable
  }
})
export default class FleetVehicleDetailCard extends Vue {
  @Prop()
  vehicle!: IVehicle;

  @Prop({ default: false })
  loading!: boolean;

  updateLabels(labels: string[]) {
    this.vehicle.tags = labels;
    this.$emit("change", this.vehicle);
  }

  updateDrivers(vehicle: IVehicle) {
    this.$emit("change", vehicle);
  }

  async deleteVehicle() {
    this.$emit("delete");
  }

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get VehicleStateEnum() {
    return VehicleStateEnum;
  }

  get tags() {
    if (!this.vehicle?.tags) {
      this.vehicle.tags = [];
    }
    return this.vehicle.tags;
  }

  get items() {
    return [
      {
        text: "components.fleet.FleetVehicleDetailVehicleCard.displayName",
        value: this.vehicle?.displayName || ""
      },
      {
        text: "objects.vehicle.registration.numberplate",
        value: this.vehicle?.numberplate || ""
      },
      {
        text: "objects.vehicle.registration.identificationnumber",
        value: this.vehicle?.identificationnumber || ""
      },
      {
        text: "objects.vehicle.registration.manufacturerType",
        value: this.vehicle?.registration?.manufacturerType || ""
      },
      {
        text: "objects.vehicle.registration.manufacturerName",
        value: this.vehicle?.registration?.manufacturerName || ""
      },
      {
        text: "components.fleet.FleetVehicleDetailVehicleCard.table.registrationDate",
        value: `${this.vehicle?.registration?.firstregistrationDay}.${this.vehicle?.registration?.firstregistrationMonth}.${this.vehicle?.registration?.firstregistrationYear}`
      },
      {
        text: "components.fleet.FleetVehicleDetailVehicleCard.notes",
        value: this.vehicle?.note || ""
      }
    ];
  }
}
