






































































































































import { deepCopy } from "@/lib/utility/deep-copy";
import { IVehicle } from "@/models/vehicle.entity";

import { DriverModule } from "@/store/modules/driver.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import DeleteDialog from "../dialog/DeleteDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import LabelComponent from "../utility/LabelComponent.vue";
import FleetVehicleDetailContextMenu from "./FleetVehicleDetailContextMenu.vue";

@Component({
  components: {
    ContextMenu,
    DeleteDialog,
    FleetVehicleDetailContextMenu,
    LabelComponent
  }
})
export default class FleetVehicleDriverDetailTable extends Vue {
  @Prop()
  vehicle!: IVehicle;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "views.fleet.FleetVehicleDriverDetailTable.header" })
  title!: string;

  showActions = false;

  currentDriverMenu = false;
  tmpCurrentDriver = "";

  driversMenu = false;
  temporaryDriverList: string[] = [];

  goToDrivers() {
    this.$router.push({ name: "FleetDriverListView", params: { partnerId: this.partnerId } });
  }

  openDriverMenu() {
    this.temporaryDriverList = deepCopy(this.driversOfVehicle);
    this.currentDriverMenu = false;
    this.driversMenu = true;
  }

  openCurrentDriverMenu() {
    this.tmpCurrentDriver = this.vehicle.currentDriver || "";
    this.currentDriverMenu = true;
  }

  closeMenu() {
    this.driversMenu = false;
    this.currentDriverMenu = false;
  }

  get driversOfVehicle() {
    return this.vehicle?.drivers || ([] as string[]);
  }

  get currentDriver() {
    return this.vehicle.currentDriver || "";
  }

  get drivers() {
    return DriverModule.drivers || [];
  }
  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailVehicleDriversDataCard") || {};
  }

  updateDriver() {
    this.vehicle.drivers = this.temporaryDriverList;
    this.$emit("change", this.vehicle);
    this.closeMenu();
  }

  updateNoDriver() {
    this.vehicle.currentDriver = "";
    this.$emit("change", this.vehicle);
    this.closeMenu();
  }

  updateCurrentDriver() {
    this.vehicle.currentDriver = this.tmpCurrentDriver;
    this.$emit("change", this.vehicle);
    this.closeMenu();
  }

  get partnerId() {
    return PartnerModule?.partner?.id || PartnerModule?.partner?._id;
  }
}
