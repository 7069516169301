





















import PartnerCreateOrUpdateGeneralInfos from "@/components/partner/PartnerCreateOrUpdateGeneralInfos.vue";
import PartnerCreateOrUpdateLandingpageSettigns from "@/components/partner/PartnerCreateOrUpdateLandingpageSettigns.vue";
import PartnerCreateOrUpdateLicense from "@/components/partner/PartnerCreateOrUpdateLicense.vue";
import PartnerCreateOrUpdateReportSettings from "@/components/partner/PartnerCreateOrUpdateReportSettings.vue";
import PartnerCreateOrUpdateSubmitCard from "@/components/partner/PartnerCreateOrUpdateSubmitCard.vue";
import PartnerDetailImage from "@/components/partner/PartnerDetailImage.vue";
import { MrfiktivCreatePartnerDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CreatePartnerDto } from "@/store/models/createPartnerDto";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    PartnerDetailImage,
    PartnerCreateOrUpdateGeneralInfos,
    PartnerCreateOrUpdateLandingpageSettigns,
    PartnerCreateOrUpdateReportSettings,
    PartnerCreateOrUpdateLicense,
    PartnerCreateOrUpdateSubmitCard
  }
})
export default class PartnerCreateCard extends Vue {
  partnerDto: MrfiktivCreatePartnerDtoGen = new CreatePartnerDto().emptyCreatePartnerDto();
}
