var render = function () {
  var _vm$value$contact, _vm$value$contact2, _vm$value;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, divider, list-item-two-line,list-item-two-line, divider"
    }
  }) : _vm.value ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('debug', {
    attrs: {
      "debug": _vm.value
    }
  }), _c('v-card-title', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.value.userName) + " "), _vm.value.countryCode ? _c('span', [_vm._v(" " + _vm._s(_vm._f("getFlagEmoji")(_vm.value.countryCode)) + " ")]) : _vm._e()]), _c('v-card-subtitle', [_c('v-row', {
    staticStyle: {
      "margin": "0 1px 0 1px"
    }
  }, [_vm._v(" " + _vm._s(_vm.value.id) + " "), _c('v-spacer'), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ADMIN) ? _c('v-btn', {
    staticClass: "mr-1",
    attrs: {
      "outlined": "",
      "small": "",
      "color": "info"
    },
    on: {
      "click": _vm.goToAdminUser
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.ThgBillingBatchSelectionItemView.goUser")) + " ")]) : _vm._e(), _c('user-administration-detail-identity-status', {
    attrs: {
      "value": _vm.value
    }
  })], 1)], 1), _c('customer-contact-card', {
    attrs: {
      "customerName": _vm.value.firstName + ' ' + _vm.value.lastName,
      "address": _vm.value.address,
      "email": (_vm$value$contact = _vm.value.contact) === null || _vm$value$contact === void 0 ? void 0 : _vm$value$contact.email,
      "phone": (_vm$value$contact2 = _vm.value.contact) === null || _vm$value$contact2 === void 0 ? void 0 : _vm$value$contact2.phone,
      "isCompany": _vm.value.isCompany,
      "companyName": _vm.value.company,
      "isTaxDeductible": _vm.value.isTaxDeductible,
      "taxnumber": _vm.value.taxnumber
    }
  }), _vm.activitySource ? _c('activity-card', {
    key: 'activity-card' + _vm.partnerId,
    attrs: {
      "partner": _vm.partner,
      "user": _vm.value,
      "source": _vm.activitySource,
      "config": {
        comment: true,
        mail: true
      }
    }
  }) : _vm._e(), !((_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.isArchived()) ? _c('partner-user-danger-zone-card', {
    attrs: {
      "partnerId": _vm.partnerId,
      "selectedUser": _vm.value
    }
  }) : _vm._e(), _c('user-administration-detail-permissions', {
    attrs: {
      "selectedUser": _vm.value,
      "permissions": _vm.value.permission
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }