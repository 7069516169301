
































import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "../utility/SideCard.vue";
import EventCard from "./EventCard.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";

@Component({
  components: { SideCard, EventCard },
  filters: {}
})
export default class EventSideCard extends mixins(Vue) {
  @Prop()
  value!: IEventUIDto;

  @Prop()
  loading!: boolean;

  loadingCreateVirtual = true;

  height = document.documentElement.clientHeight;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  onAcknowledged() {
    this.$emit("acknowledged");
  }

  onResize() {
    this.height = document.documentElement.clientHeight;
  }

  mounted() {
    this.value
      .createVirtual()
      .catch(this.$log.error)
      .then(() => (this.loadingCreateVirtual = false));
  }
}
