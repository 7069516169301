/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * The keys of the settings for the da setup
 */
export enum DaSettingsKeys {
  /**
   * The password of the user.
   *
   * @example XYZ123
   */
  PASSWORD = "DA_PASSWORD",

  /**
   * The username. Must be the email.
   * (Note 1: The user has to be unique in the context of the target instance!)
   * (Note 2: You have to pass the email address of the user to the API!)
   *
   * @example info@mmmint.ai
   */
  USERNAME = "DA_USERNAME",

  /**
   * The center name.
   *
   * In all calls to the DA REST-API the target instance has to be identified by passing the header parameters group and center.
   * A tenant (labeled as group in DA terms) can have multiple sites respectively facilities (labeled center).
   *
   * From the URL: https://werkstattplanung.net/<group>/<center>/kic/public
   *
   * @example demo
   */
  CENTER = "DA_CENTER",

  /**
   * The group name.
   *
   * In all calls to the DA REST-API the target instance has to be identified by passing the header parameters group and center.
   * A tenant (labeled as group in DA terms) can have multiple sites respectively facilities (labeled center).
   *
   * From the URL: https://werkstattplanung.net/<group>/<center>/kic/public
   *
   * @example mmmint
   */
  GROUP = "DA_GROUP",

  /**
   * The default resource id of the DA which needs to be configured in the DA.
   * This resource id will be used when no other resource id is provided.
   *
   * Reference to Resource model. Only searching for specific employees/resources.
   *
   * We recommend that a digital agent specifically for the export is created in the customer instance.
   */
  DEFAULT_RESOURCE = "DA_RESOURCE",

  /**
   * The default event type of the DA which needs to be configured in the DA.
   * This event type id will be used when no other event type id is provided.
   *
   * Reference to event type model. Only searching for specific event types.
   *
   * We recommend that an event type specifically for the export is created in the customer instance.
   */
  DEFAULT_EVENT_TYPE = "DA_EVENT_TYPE",

  DEFAULT_WORKSHOP_SERVICE = "DA_WORKSHOP_SERVICE",

  /**
   * This is the dossiers status category to display to the end user (public status)
   */
  CUSTOMER_STATUS_CATEGORY_ID = "DA_STATUS_CATEGORY_ID",

  /**
   * This is the dossiers status category to display to the user of the portal (internal status)
   */
  INTERNAL_STATUS_CATEGORY_ID = "DA_INTERNAL_STATUS_CATEGORY_ID"
}
