


















































































import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { ITicket } from "@/models/ticket.entity";
import { IVehicle } from "@/models/vehicle.entity";
import {
  VehicleAggregation,
  VehicleAggregationTimeFrame,
  VehicleEventTypeEnum
} from "@/store/modules/fleet-aggregation.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import debounce from "debounce";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IFleetHomeVehicleTableMonths } from "./FleetHomeVehicleTable.vue";
import FleetHomeVehicleTableActions from "./FleetHomeVehicleTableActions.vue";
import FleetHomeVehicleTableDamageList from "./FleetHomeVehicleTableDamageList.vue";
import FleetHomeVehicleTableEvents from "./FleetHomeVehicleTableEvents.vue";
import FleetHomeVehicleTableTickets from "./FleetHomeVehicleTableTickets.vue";

@Component({
  components: {
    FleetHomeVehicleTableActions,
    FleetHomeVehicleTableDamageList,
    FleetHomeVehicleTableTickets,
    FleetHomeVehicleTableEvents
  }
})
export default class FleetHomeVehicleTableRow extends mixins(Vue) {
  @Prop()
  vehicleAggregation!: VehicleAggregation;

  @Prop()
  monthMethods!: IFleetHomeVehicleTableMonths;

  readonly VehicleStateEnum = VehicleStateEnum;

  readonly prev: VehicleAggregationTimeFrame = this.vehicleAggregation.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(
    this.monthMethods.prevMonthYear()
  );

  readonly curr: VehicleAggregationTimeFrame = this.vehicleAggregation.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(
    this.monthMethods.currentMonthYear()
  );

  readonly next: VehicleAggregationTimeFrame = this.vehicleAggregation.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(
    this.monthMethods.nextMonthYear()
  );

  get timeFrameMapKey() {
    return this.vehicleAggregation.vehicleAggregationTimeFrameMap.key;
  }

  ticketCount = 0;

  debounceCount(eventType: VehicleEventTypeEnum) {
    if (eventType !== VehicleEventTypeEnum.TICKET) return;

    debounce(this.count, 200)();
  }

  count() {
    this.ticketCount = 0;
    this.ticketCount += this.prev.aggregation.ticket.size;
    this.ticketCount += this.curr.aggregation.ticket.size;
    this.ticketCount += this.next.aggregation.ticket.size;
  }

  removeListeners() {
    this.curr.$off(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceCount);
  }

  addListeners() {
    this.curr.$on(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceCount);
  }

  mounted() {
    this.count();
    this.removeListeners();
    this.addListeners();
  }

  beforeDestroy() {
    this.removeListeners();
  }

  openTicketSideCard(ticket: ITicket) {
    this.$emit("openTicketSideCard", ticket);
  }

  openVehicleSideCard(vehicle: IVehicle) {
    this.goToVehicle(vehicle);
  }

  statusColor(state: string) {
    if (!state) return "Grey";

    return VehicleModule.stateMap.get(state)?.color || "Grey";
  }

  goToVehicle(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, undefined, true);
  }
}
