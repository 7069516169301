













import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IWebhookConfig, WebhookConfig } from "@/models/webhook-config.entity";
import { WebhookConfigModule } from "@/store/modules/webhook-config.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import WebhookConfigCard from "@/components/webhook/WebhookConfigCard.vue";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    TheLayoutPortal,
    WebhookConfigCard,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class WebhookConfigDetailView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get webhookConfigId(): string {
    return this.$route.params.webhookConfigId;
  }

  isLoading = false;

  webhookConfig: IWebhookConfig | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    const webhookConfig =
      WebhookConfigModule.maps.id.get(this.webhookConfigId)[0] ??
      new WebhookConfig({
        id: this.webhookConfigId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await webhookConfig.fetch();
      this.webhookConfig = webhookConfig;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
