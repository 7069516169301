var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-card', {
    attrs: {
      "loading": _vm.loading,
      "flat": "",
      "outlined": "",
      "tile": ""
    }
  }, [_c('template', {
    slot: "progress"
  }, [_c('v-progress-linear', {
    attrs: {
      "color": _vm.loadingColor,
      "height": "3",
      "indeterminate": ""
    }
  })], 1), _c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": _vm.headerUrl,
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "height": "200px"
    }
  }, [_c('v-card-title', {
    staticClass: "align-start "
  }, [_c('span', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.partner.companyName) + " ")])])], 1)], 2), _c('div', {
    staticClass: "ma-2"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('dashboard-calendar', {
    attrs: {
      "reports": _vm.reports,
      "forms": _vm.forms
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }