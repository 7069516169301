var render = function () {
  var _vm$selectedEvent2, _vm$selectedEvent2$re;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "ma-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.selectedEvent ? _c('div', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.i18n["title"]) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.value = '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.i18n["today"]) + " ")]), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.prevMonth
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.nextMonth
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1), _c('context-menu', [_c('v-checkbox', {
    staticClass: "mt-n1 mb-n1",
    attrs: {
      "color": "success",
      "label": _vm.i18n['reportCreated']
    },
    model: {
      value: _vm.isReportCreated,
      callback: function callback($$v) {
        _vm.isReportCreated = $$v;
      },
      expression: "isReportCreated"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-n1 mb-n1",
    attrs: {
      "color": "success",
      "label": _vm.i18n['reportDatePreference']
    },
    model: {
      value: _vm.isReportDatePreference,
      callback: function callback($$v) {
        _vm.isReportDatePreference = $$v;
      },
      expression: "isReportDatePreference"
    }
  })], 1)], 1), _c('v-calendar', {
    ref: "calendar",
    attrs: {
      "flat": "",
      "type": "month",
      "weekdays": [1, 2, 3, 4, 5, 6, 0],
      "events": _vm.events
    },
    on: {
      "click:event": _vm.onEventClick
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1) : _vm._e()]), _vm.selectedEvent !== null && _vm.selectedEvent.report ? _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.selectedEvent && _vm.selectedEvent.start && _vm.selectedEvent.report && Array.isArray(_vm.selectedEvent.data) ? _c('div', [_c('v-card-title', [_c('a', {
    on: {
      "click": function click($event) {
        var _vm$selectedEvent;

        return _vm.goToReport(((_vm$selectedEvent = _vm.selectedEvent) === null || _vm$selectedEvent === void 0 ? void 0 : _vm$selectedEvent.report) || {});
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$selectedEvent2 = _vm.selectedEvent) === null || _vm$selectedEvent2 === void 0 ? void 0 : (_vm$selectedEvent2$re = _vm$selectedEvent2.report) === null || _vm$selectedEvent2$re === void 0 ? void 0 : _vm$selectedEvent2$re.customerName) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearSelectedEvent();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.selectedEvent.start.toDateString()) + " ")]), _c('v-card-text', [_c('v-list', [_c('p', [_vm._v("Message: " + _vm._s(_vm.selectedEvent.report.message))]), _c('p', [_vm._v("Status: " + _vm._s(_vm.selectedEvent.report.progressStatus))])])], 1)], 1) : _vm._e()]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }