













import ErrorCard from "@/components/utility/ErrorCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { Component, Vue } from "vue-property-decorator";
import ResourceForm from "./ResourceForm.vue";
import { BookingUpdateResourceDtoGen } from "@/services/booking/v1/data-contracts";
import Debug from "@/components/utility/Debug.vue";
import { IResource, Resource } from "@/models/resource.entity";

@Component({
  components: {
    LayoutSimple,
    ResourceForm,
    Debug,
    ErrorCard
  }
})
export default class ResourceDetail extends Vue {
  isLoading = false;

  updated?: BookingUpdateResourceDtoGen | undefined;

  resource: IResource | null = null;

  get title() {
    return this.$t("views.booking.ResourceDetail.title");
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }
  get resourceId() {
    return this.$route.params.resourceId;
  }

  async onBack() {
    await new GoToHelper(this.$router).goToBooking(this.partnerId);
  }

  async mounted() {
    this.isLoading = true;
    this.resource = await new Resource({ partnerId: this.partnerId, id: this.resourceId }).fetch();
    this.isLoading = false;
  }

  async save() {
    if (!this.updated) {
      this.$toast.error("error");
      throw new Error("Not defined");
    }
    this.isLoading = true;

    try {
      await new Resource({
        partnerId: this.partnerId,
        id: this.resourceId,
        ...this.updated
      }).replaceOneByPartnerId();

      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
