var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [!_vm.editActive ? _c('span', [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setEditActive(true);
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-pencil-outline")])], 1)];
      }
    }], null, false, 2733927536)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManageDetailCard.edit")) + " ")])])], 1) : _c('span', [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.showCancelEditDialog
          }
        }, on), [_c('v-icon', [_vm._v("mdi-cancel")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerManageDetailCard.cancelEdit")) + " ")])])], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.submitCancelEditDialog.apply(null, arguments);
      }
    },
    model: {
      value: _vm.cancelEditDialog,
      callback: function callback($$v) {
        _vm.cancelEditDialog = $$v;
      },
      expression: "cancelEditDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "max-width": "500px",
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageDetailCard.cancelDialog")) + " ")]), _c('v-card-actions', [_c('v-flex', {
    staticClass: "d-flex "
  }, [_c('v-btn', {
    staticClass: "mb-4 pa-2",
    attrs: {
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.hideCancelEditDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageDetailCard.return")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "mb-4 pa-2",
    attrs: {
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.submitCancelEditDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageDetailCard.cancel")) + " ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }