


















































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ITicket } from "@/models/ticket.entity";
import {
  VehicleAggregation,
  VehicleAggregationTimeFrame,
  VehicleEventTypeEnum,
  VehicleTicketEvent
} from "@/store/modules/fleet-aggregation.store";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TicketSideCard from "../project/TicketSideCard.vue";
import RefsTicket from "../utility/RefsTicket.vue";

@Component({
  components: { RefsTicket, TicketSideCard }
})
export default class FleetHomeVehicleTableTickets extends mixins(DarkModeHighlightMixin) {
  @Prop()
  vehicleAggregation!: VehicleAggregation;

  @Prop()
  prev!: VehicleAggregationTimeFrame;

  @Prop()
  curr!: VehicleAggregationTimeFrame;

  @Prop()
  next!: VehicleAggregationTimeFrame;

  prevTickets: VehicleTicketEvent[] = [];

  currentTickets: VehicleTicketEvent[] = [];

  nextTickets: VehicleTicketEvent[] = [];

  mounted() {
    this.setPrevTicket();
    this.setCurrTicket();
    this.setNextTicket();
    this.removeListeners();
    this.addListeners();
  }

  beforeDestroy() {
    this.removeListeners();
  }

  onOpenDetail(ticket: ITicket) {
    this.$emit("openTicketSideCard", ticket);
  }

  debounceSetPrevTicket(eventType: VehicleEventTypeEnum) {
    if (eventType !== VehicleEventTypeEnum.TICKET) return;

    debounce(this.setPrevTicket, 200)();
  }

  setPrevTicket() {
    const tickets = Array.from(this.prev.aggregation.ticket.values());
    this.prevTickets.splice(0, this.prevTickets.length, ...tickets);
  }

  debounceSetCurrTicket(eventType: VehicleEventTypeEnum) {
    if (eventType !== VehicleEventTypeEnum.TICKET) return;

    debounce(this.setCurrTicket, 200)();
  }

  setCurrTicket() {
    const tickets = Array.from(this.curr.aggregation.ticket.values());
    this.currentTickets.splice(0, this.currentTickets.length, ...tickets);
  }

  debounceSetNextTicket(eventType: VehicleEventTypeEnum) {
    if (eventType !== VehicleEventTypeEnum.TICKET) return;

    debounce(this.setNextTicket, 200)();
  }

  setNextTicket() {
    const tickets = Array.from(this.next.aggregation.ticket.values());
    this.nextTickets.splice(0, this.nextTickets.length, ...tickets);
  }

  removeListeners() {
    this.prev.$off(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetPrevTicket);
    this.curr.$off(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetCurrTicket);
    this.next.$off(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetNextTicket);
  }

  addListeners() {
    this.prev.$on(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetPrevTicket);
    this.curr.$on(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetCurrTicket);
    this.next.$on(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetNextTicket);
  }
}
