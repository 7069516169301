var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('debug', [_vm._v(" ProjectTableView " + _vm._s(_vm.partnerId) + " " + _vm._s(_vm.projectId) + " " + _vm._s(_vm.ticketNumber) + " ")]), _c('v-container', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.$t('project.ticket.projects'),
      "chips": _vm.chips,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": _vm.processAction
    }
  }), _c('project-table', {
    staticClass: "ma-2",
    attrs: {
      "loadingTickets": _vm.loadingTickets,
      "loadingSideCard": _vm.loadingSideCard,
      "loadingPartnerUsers": _vm.loadingPartnerUsers,
      "loadingProjects": _vm.loadingProjects,
      "partnerId": _vm.partnerId,
      "projectId": _vm.projectId
    },
    on: {
      "refresh": _vm.refresh,
      "delete": _vm.deleteProject,
      "goToProjectSetting": function goToProjectSetting(d) {
        return _vm.goTo.projectSetting({
          partnerId: _vm.partnerId,
          projectId: d.id
        });
      },
      "goToTicketTable": function goToTicketTable(d) {
        return _vm.goTo.ticketTable({
          partnerId: _vm.partnerId
        });
      },
      "goToProjectDetail": function goToProjectDetail(d) {
        return _vm.goTo.projectDetail({
          partnerId: _vm.partnerId,
          projectId: d.id
        });
      },
      "goToProjectCustomView": function goToProjectCustomView(d) {
        return _vm.goTo.projectDetail({
          partnerId: _vm.partnerId,
          projectId: d.id
        });
      }
    }
  }), _c('v-slide-x-reverse-transition', [_vm.isSideCard ? _c('project-side-card', {
    on: {
      "close": function close() {
        return _vm.goTo.projectTable({
          partnerId: _vm.partnerId
        });
      },
      "update": _vm.updateProject,
      "goToProjectCustomView": function goToProjectCustomView(d) {
        return _vm.goTo.projectDetail({
          partnerId: _vm.partnerId,
          projectId: d.id
        });
      }
    },
    model: {
      value: _vm.project,
      callback: function callback($$v) {
        _vm.project = $$v;
      },
      expression: "project"
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }