var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "max-width": "750px"
    }
  }, [_c('v-row', [_c('v-card', {
    attrs: {
      "elevation": 0
    }
  }, [_c('v-card-title', [_c('v-spacer'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.noCustomViewsTitle'))
    }
  }), _c('v-spacer')], 1), _c('v-card-subtitle', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.customViewDescriptionI'))
    }
  }), _c('a', {
    on: {
      "click": function click($event) {
        return _vm.$emit('goToSettings');
      }
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.customViewDescriptionII'))
    }
  })]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.customViewDescriptionIII'))
    }
  }), _c('a', {
    on: {
      "click": _vm.goToCustomFields
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.customViewDescriptionIIII'))
    }
  })]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.customViewDescriptionIIIII'))
    }
  })])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "flat": ""
    },
    on: {
      "click": function click($event) {
        _vm.isProjectTable = !_vm.isProjectTable;
      }
    }
  }, [_vm.infoTab === _vm.ProjectCustomViewTypeEnum.TABLE || _vm.isProjectTable ? _c('div', {
    staticStyle: {
      "background-color": "rgba(200, 200, 200, 0.6)",
      "position": "absolute",
      "height": "100%",
      "width": "100%"
    }
  }, [_vm.isProjectTable ? _c('v-avatar', {
    staticStyle: {
      "z-index": "1",
      "position": "relative",
      "top": "calc(50% - 35px)",
      "left": "calc(50% - 35px)"
    },
    attrs: {
      "color": "primary",
      "size": "70"
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "color": "white",
      "transform": "scale(2)"
    }
  }, [_vm._v(_vm._s(_vm.tablePosition))])], 1) : _vm._e()], 1) : _vm._e(), _c('div', [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "src": require("@/assets/undraw_apps_re_ienc.svg"),
      "width": "155px",
      "height": "155px",
      "contain": ""
    }
  })], 1), _c('v-card-actions', {
    class: _vm.$vuetify.breakpoint.smAndDown ? '' : 'my-2'
  }, [_c('v-spacer'), _c('b', [_vm._v(" " + _vm._s(_vm.$t("project.project.table")) + " ")]), _c('v-spacer')], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "flat": ""
    },
    on: {
      "click": function click($event) {
        _vm.isProjectCalendar = !_vm.isProjectCalendar;
      }
    }
  }, [_vm.isProjectCalendar ? _c('div', {
    staticStyle: {
      "background-color": "rgba(200, 200, 200, 0.6)",
      "position": "absolute",
      "height": "100%",
      "width": "100%"
    }
  }, [_c('v-avatar', {
    staticStyle: {
      "z-index": "1",
      "position": "relative",
      "top": "calc(50% - 35px)",
      "left": "calc(50% - 35px)"
    },
    attrs: {
      "color": "primary",
      "size": "70"
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "color": "white",
      "transform": "scale(2)"
    }
  }, [_vm._v(_vm._s(_vm.calendarPosition))])], 1)], 1) : _vm._e(), _c('div', [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "src": require("@/assets/undraw_calendar_re_ki49.svg"),
      "width": "155px",
      "height": "155px",
      "contain": ""
    }
  })], 1), _c('v-card-actions', {
    class: _vm.$vuetify.breakpoint.smAndDown ? '' : 'my-2'
  }, [_c('v-spacer'), _c('b', [_vm._v(" " + _vm._s(_vm.$t("project.project.calendar")) + " ")]), _c('v-spacer')], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "flat": ""
    },
    on: {
      "click": function click($event) {
        _vm.isProjectBoard = !_vm.isProjectBoard;
      }
    }
  }, [_vm.isProjectBoard ? _c('div', {
    staticStyle: {
      "background-color": "rgba(200, 200, 200, 0.6)",
      "position": "absolute",
      "height": "100%",
      "width": "100%"
    }
  }, [_c('v-avatar', {
    staticStyle: {
      "z-index": "1",
      "position": "relative",
      "top": "calc(50% - 35px)",
      "left": "calc(50% - 35px)"
    },
    attrs: {
      "color": "primary",
      "size": "70"
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "color": "white",
      "transform": "scale(2)"
    }
  }, [_vm._v(_vm._s(_vm.boardPosition))])], 1)], 1) : _vm._e(), _c('div', [_c('v-img', {
    staticClass: "ma-auto",
    attrs: {
      "src": require("@/assets/undraw_scrum_board_re_wk7v.svg"),
      "width": "155px",
      "height": "155px",
      "contain": ""
    }
  })], 1), _c('v-card-actions', {
    class: _vm.$vuetify.breakpoint.smAndDown ? '' : 'my-2'
  }, [_c('v-spacer'), _c('b', [_vm._v(" " + _vm._s(_vm.$t("project.project.board")) + " ")]), _c('v-spacer')], 1)], 1)], 1), _vm.infoTab === _vm.ProjectCustomViewTypeEnum.TABLE ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("customViews.tableDescription")) + " ")])], 1)], 1) : _vm._e(), _vm.infoTab === _vm.ProjectCustomViewTypeEnum.CALENDAR ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("customViews.calendarDescription")) + " ")])], 1)], 1) : _vm._e(), _vm.infoTab === _vm.ProjectCustomViewTypeEnum.BOARD ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, [_c('v-card-text', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.boardDescriptionI'))
    }
  }), _c('a', {
    on: {
      "click": _vm.goToCustomFields
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.boardDescriptionII'))
    }
  })]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('customViews.boardDescriptionIII'))
    }
  })]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("customViews.chooseCustomFieldForBoard")) + " "), _vm._l(_vm.customFields, function (customField, index) {
    return _c('div', {
      key: 'customFieldSelectionBoardMenu' + customField.id
    }, [_c('v-list-item', {
      on: {
        "click": function click($event) {
          return _vm.addBord(customField);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "ml-2 pt-1"
    }, [_vm._v(" " + _vm._s(customField.name) + " ")]), _c('v-list-item-subtitle', {
      staticClass: "ml-2 mb-n2 pt-1"
    }, [_vm._v(" Spalten: "), _vm._l(customField.configuration.values, function (field) {
      return _c('span', {
        key: 'custom-field-value-chip' + field.value,
        staticClass: "ma-1"
      }, [_c('custom-field-value-chip', {
        attrs: {
          "color": field.color,
          "dark": field.dark,
          "value": field
        }
      })], 1);
    })], 2)], 1), _vm.selectedCustomField.includes(customField.id) ? _c('v-list-item-action', [_c('v-icon', [_vm._v("mdi-check")])], 1) : _vm._e()], 1), index !== _vm.customFields.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), !_vm.customFields.length ? _c('div', {
    staticClass: "pa-2"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('project.project.noSingleSelectAvailableI'))
    }
  }), _c('a', {
    on: {
      "click": _vm.goToCustomFields
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('project.project.noSingleSelectAvailableII'))
    }
  })]), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('project.project.noSingleSelectAvailableIII'))
    }
  })]) : _vm._e()], 2)], 1)], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-card', {
    attrs: {
      "elevation": 0
    }
  }, [_c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": !(_vm.isProjectBoard || _vm.isProjectCalendar || _vm.isProjectTable),
      "color": "info",
      "loading": _vm.isAddViewDialogLoading
    },
    on: {
      "click": _vm.confirmCreation
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirmSelection")) + " ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }