var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-select', {
    attrs: {
      "loading": _vm.loading,
      "eager": "",
      "items": _vm.items,
      "item-text": "companyName",
      "item-value": "_id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": _vm.loading ? _vm.loadingText : _vm.selectPartnersText,
      "flat": "",
      "solo": "",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [index === 0 ? _c('span', [_vm._v(_vm._s(item.companyName))]) : _vm._e(), index === 1 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedPartnerIds.length - 1) + " others) ")]) : _vm._e()];
      }
    }, {
      key: "prepend-item",
      fn: function fn() {
        return [_c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "mousedown": function mousedown($event) {
              $event.preventDefault();
            },
            "click": _vm.selectAllPartners
          }
        }, [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "label": null
          },
          model: {
            value: _vm.selectAll,
            callback: function callback($$v) {
              _vm.selectAll = $$v;
            },
            expression: "selectAll"
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.selectAllText) + " ")])], 1)], 1), _c('v-divider', {
          staticClass: "mt-2"
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedPartnerIds,
      callback: function callback($$v) {
        _vm.selectedPartnerIds = $$v;
      },
      expression: "selectedPartnerIds"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }