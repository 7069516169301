

































































































































































































































































import {
  DateVuetifyHelper,
  detailedDateWithDay,
  formatHoursAndMinutes,
  simpleDoubleDigitDate
} from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import { CalendarEvent, CalendarTypeEnum } from "@/lib/utility/calendarEvent";
import AnalyticsDateRangeSelectorDialog from "../analytics/AnalyticsDateRangeSelectorDialog.vue";
import Debug from "./Debug.vue";

@Component({
  components: {
    TableWrapper,
    ContextMenu,
    AnalyticsDateRangeSelectorDialog,
    Debug
  },
  filters: { simpleDoubleDigitDate, detailedDateWithDay, formatHoursAndMinutes }
})
export default class Calendar<T> extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: () => [] })
  events!: CalendarEvent<T>[];

  @Prop()
  headers!: ITableWrapperHeader[];

  @Prop()
  hideExpand?: boolean;

  @Prop()
  categories?: string[];

  @Prop()
  groupBy?: string;

  @Prop({
    default: CalendarTypeEnum.WEEK
  })
  private type!: CalendarTypeEnum;

  calenderFocus = new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString();

  eventThreshold = this.calenderFocus;

  showCalendar = true;

  selectedOpen = false;

  selectedElement: EventTarget | null = null;

  selectedEvent: CalendarEvent<T> | null = null;

  dateRange = [new Date().toISOString(), new Date().toISOString()];

  /**
   * Used for min of date range picker which includes min, hence +1
   */
  get beginningOfPreviousMonthPlusOne() {
    const focus = new Date(this.focus);
    focus.setUTCHours(0, 0, 0, 0);

    const beginngingOfMonth = new Date(focus.getFullYear(), focus.getMonth() - 1, 2);
    beginngingOfMonth.setUTCHours(0, 0, 0, 0);

    return beginngingOfMonth.toISOString();
  }

  /**
   * Used for min of date range picker which excludes max, hence +1
   */
  get endOfNextMonthPlusOne() {
    const focus = new Date(this.focus);
    focus.setUTCHours(0, 0, 0, 0);

    const endOfNextMonth = new Date(focus.getFullYear(), focus.getMonth() + 2, 1);
    endOfNextMonth.setUTCHours(0, 0, 0, 0);

    return endOfNextMonth.toISOString();
  }

  get tableEvents() {
    const tableEvents = this.events.filter(e => {
      const start = new Date(this.dateRange[0]);
      start.setUTCHours(0, 0, 0, 0);

      const end = new Date(this.dateRange[1]);
      end.setUTCHours(0, 0, 0, 0);

      const event = new Date(e.start);
      event.setUTCHours(0, 0, 0, 0);

      const isEventBetweenStartAndEnd = start.getTime() <= event.getTime() && event.getTime() <= end.getTime();
      const isEventAfterStart = start.getTime() === event.getTime();

      return isNaN(end.getTime()) ? isEventAfterStart : isEventBetweenStartAndEnd;
    });

    return tableEvents;
  }

  get firstTime() {
    // TODO: Fix this to use the very first start date of service

    return "07:00";
  }

  get i18n() {
    return this.$t("components.booking.PartnerBookingCalendar");
  }

  get calendarTypes() {
    const types = [CalendarTypeEnum.MONTH, CalendarTypeEnum.WEEK, CalendarTypeEnum.DAY];

    if (this.categories && this.categories.length >= 1) {
      types.push(CalendarTypeEnum.CATEGORY);
    }

    return types;
  }

  get calendarType() {
    return this.type;
  }

  set calendarType(type: CalendarTypeEnum) {
    this.$emit("update:type", type);
  }

  /**
   * Get the Calender Title: Month YYYY
   */
  get calenderTitle() {
    const date = new Date(this.calenderFocus);
    return date.toLocaleString("default", { month: "long", year: "numeric" });
  }

  /**
   * Get the current calender focus as YYYY-MM-DD
   */
  get focus() {
    return new DateVuetifyHelper(this.calenderFocus).date;
  }

  /**
   * Set the current calender focus from ISO-String
   */
  set focus(date) {
    const newDate = new DateVuetifyHelper(this.calenderFocus);
    newDate.date = date;
    this.calenderFocus = newDate.isoDate;
    this.$emit("input", newDate.originalDate);
    this.setTable();
  }

  get upcomingEvents() {
    const eventThreshold = new Date(this.eventThreshold);
    const filtered = this.events.filter(e => {
      if (e.end) {
        return new Date(e.end) >= eventThreshold;
      }
      if (e.start) {
        return new Date(e.start) >= eventThreshold;
      }

      return false;
    });
    const sorted = filtered.sort((a, b) => (a.start > b.start ? 1 : -1));
    return sorted;
  }

  get calendarStyle() {
    const base = "height: calc(100vh - 60px); max-height: 650px; ";

    if (this.type === CalendarTypeEnum.MONTH) {
      return base;
    }

    return base + "overflow-y: auto;";
  }

  get thresholdMonthEnd() {
    const eventThreshold = new Date(this.eventThreshold);
    const followingMonth = new Date(eventThreshold.getFullYear(), eventThreshold.getMonth() + 1);

    const month = this.$t("time.months." + followingMonth.getMonth());

    return month;
  }

  setDateRange(dateRage: string[]) {
    this.dateRange.splice(0, 2, ...dateRage);
  }

  /**
   * Sets date range for table when switching to it from month/week/day view of calendar
   */
  setTable() {
    const calendarFocus = new Date(this.focus);
    switch (this.type) {
      case CalendarTypeEnum.MONTH: {
        const beginngingOfMonth = new Date(calendarFocus.getFullYear(), calendarFocus.getMonth(), 2);
        beginngingOfMonth.setUTCHours(0, 0, 0, 0);
        const endOfMonth = new Date(calendarFocus.getFullYear(), calendarFocus.getMonth() + 1);
        endOfMonth.setUTCHours(0, 0, 0, 0);

        this.dateRange.splice(
          0,
          2,
          beginngingOfMonth.toISOString().slice(0, 10),
          endOfMonth.toISOString().slice(0, 10)
        );
        break;
      }
      case CalendarTypeEnum.WEEK: {
        const offSet = (calendarFocus.getDay() + 6) % 7;
        const beginngingOfWeek = new Date(
          calendarFocus.getFullYear(),
          calendarFocus.getMonth(),
          calendarFocus.getDate() - offSet + 1
        );
        beginngingOfWeek.setUTCHours(0, 0, 0, 0);

        const endOfWeek = new Date(beginngingOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);
        endOfWeek.setUTCHours(0, 0, 0, 0);

        this.dateRange.splice(0, 2, beginngingOfWeek.toISOString().slice(0, 10), endOfWeek.toISOString().slice(0, 10));
        break;
      }

      case CalendarTypeEnum.DAY: {
        const day = new Date(this.calenderFocus);
        day.setUTCHours(0, 0, 0, 0);

        this.dateRange.splice(0, 2, day.toISOString().slice(0, 10), day.toISOString().slice(0, 10));

        break;
      }
    }
  }

  toggleCalendarTable() {
    this.showCalendar = !this.showCalendar;
    if (!this.showCalendar) {
      this.setTable();
    }
  }

  resetFocus() {
    const today = new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString();
    this.focus = today;
    this.eventThreshold = this.focus;
    this.$emit("resetFocus", today);
  }

  openDetail() {
    this.$emit("click:detail", this.selectedEvent);
    this.selectedOpen = false;
  }

  mapEventToRefsEvent(event: CalendarEvent<T>): T[] {
    return event.data;
  }

  openEventPreview(nativeEvent: any) {
    const open = () => {
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    this.selectedElement = nativeEvent.target;
  }

  clickEvent(data: { nativeEvent: any; event: any }) {
    this.$emit("click:event", data.event);
    this.openEventPreview(data.nativeEvent);
    this.selectedEvent = data.event;
    data.nativeEvent.stopPropagation();
  }

  clickUpcomingEvent(event: CalendarEvent<T>, clickEvent: MouseEvent) {
    this.$emit("click:upcoming", { event });
    this.openEventPreview(clickEvent);
    this.selectedEvent = event;
  }

  clickDateSelector(dateString: string) {
    const date = new Date(dateString);
    this.focus = date.toISOString();
    (this.$refs.calendar as Vue & { scrollToTime: (format: any) => any })?.scrollToTime(formatHoursAndMinutes(date));
    this.eventThreshold = this.focus;
  }

  setCalendarType(item: CalendarTypeEnum) {
    this.showCalendar = true;
    this.calendarType = item;
  }

  onMoreClick(event: any) {
    this.$log.debug(event);

    this.focus = new Date(event.date).toISOString();
    this.eventThreshold = this.focus;

    this.calendarType = CalendarTypeEnum.DAY;
  }

  mounted() {
    (this.$refs.calendar as Vue & { scrollToTime: (format: any) => any })?.scrollToTime(this.firstTime);
  }

  goToBookingSetting() {
    new GoToHelper(this.$router).goToBookingSetting();
  }

  prevMonth() {
    /**
     * Not using: (this.$refs.calendar as any)?.prev();
     * because by default prev goes to last day of previous interval. In the calendar context the first day of the interval makes more sense however (consider upcoming list)
     */

    switch (this.calendarType) {
      case CalendarTypeEnum.MONTH: {
        const beginningOfPreviousMonth = new Date(this.focus);
        beginningOfPreviousMonth.setMonth(beginningOfPreviousMonth.getMonth() - 1);
        beginningOfPreviousMonth.setDate(1);
        this.focus = beginningOfPreviousMonth.toISOString();
        this.eventThreshold = this.focus;
        break;
      }

      case CalendarTypeEnum.WEEK: {
        const beginningOfPreviousWeek = new Date(this.focus);
        beginningOfPreviousWeek.setDate(
          beginningOfPreviousWeek.getDate() - ((beginningOfPreviousWeek.getDay() + 6) % 7) - 7
        );
        this.focus = beginningOfPreviousWeek.toUTCString();
        this.eventThreshold = this.focus;
        break;
      }

      case CalendarTypeEnum.DAY: {
        const previousDay = new Date(this.focus);
        previousDay.setDate(previousDay.getDate() - 1);
        this.focus = previousDay.toISOString();
        this.eventThreshold = this.focus;
        break;
      }
    }

    this.$emit("prev", this.calenderFocus);
  }

  nextMonth() {
    (this.$refs.calendar as any)?.next();
    this.$emit("next", this.calenderFocus);
    this.eventThreshold = this.focus;
  }
}
