import eventService from "@/services/mrfiktiv/services/eventService";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { FleetAggregationModule } from "./fleet-aggregation.store";
import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import vehicleEventService from "@/services/mrfiktiv/services/vehicleEventService";
import { BaseStore } from "../base.store";
import { ILocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.interface";
import { EventDataAccessLayer } from "./access-layers/event.access-layer";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";

/**
 * Store to handle all events in a time frame. @see RecurringEventStore
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "overdue-event-list",
  store
})
export class OverdueListEventStore extends BaseStore<IEventUIDto> {
  protected _data: ILocalDataAccessLayer<IEventUIDto, string> = EventDataAccessLayer;

  protected _dateRange: string[] = [
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString(),
    new Date().toISOString()
  ];

  hiddenFilter = [
    new PageFilterElement({
      key: "isOverdue",
      value: true as any
    })
  ];

  vehicleId = "";

  filterOptions = EventUIDto.filterables;

  get dateRange(): string[] {
    return this._dateRange;
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  /**
   * makes sure that date range is always included in the filters and sets the date range
   */
  @Mutation
  mutateExtendedFilters(filters: IPageFilterElement[]) {
    const vehicleFilter = filters.find(f => f.key === "vehicleId" && f.operation === PageFilterOperationEnum.EQUAL);
    if (vehicleFilter) {
      this.vehicleId = vehicleFilter.value as string;
    } else {
      this.vehicleId = "";
    }
    this.filters.splice(0, this.filters.length, ...filters);
  }

  @Action
  setFilters(filters: IPageFilterElement[]) {
    this.context.commit("mutateExtendedFilters", filters);
    return this;
  }

  @Action
  setFilter(filters: IPageFilterElement[]) {
    this.context.commit("mutateExtendedFilters", filters);
  }

  @Action
  async fetchAll(data: { partnerId: string }) {
    let res;

    if (!this.vehicleId) {
      res = await eventService.listOverdue(data.partnerId);
    } else {
      res = await vehicleEventService.listOverdue(data.partnerId, this.vehicleId);
    }

    const events = res.map(e => new EventUIDto(e));

    for (const event of events) {
      this._data.set(event);
    }

    Vue.$log.debug("Paged events. Parsing for fleet ", events?.length);
    FleetAggregationModule.parseEvents(events ?? []);

    return res;
  }
}

export const OverdueEventListModule = getModule(OverdueListEventStore);
