






































import { detailedDateWithDay, simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { EventModule } from "@/store/modules/event.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component } from "vue-property-decorator";
import SideCard from "../utility/SideCard.vue";
import TableWrapper, { IControlElements } from "../utility/TableWrapper.vue";
import FleetEventMenuCard from "./FleetEventMenuCard.vue";
import FleetVehicleMenuCard from "./FleetVehicleMenuCard.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";

@Component({
  components: {
    TableWrapper,
    SideCard,
    FleetEventMenuCard,
    FleetVehicleMenuCard
  },
  filters: { simpleDate, detailedDateWithDay }
})
export default class FleetEventTable extends DarkModeHighlightMixin {
  isLoading = false;

  selected: IEventUIDto[] = [];

  goToFleetImport() {
    this.$router.push({ name: "FleetImportView" });
  }

  /**
   * Selected Event
   * Used to display event in menu if you click on a row
   */
  selectedEvent: IEventUIDto | null = null;

  clearSelectedEvent() {
    this.selectedEvent = null;
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        action: this.selectVehicle
      }
    ];
  }

  get headers() {
    return [
      {
        text: this.i18n["start"],
        align: "start",
        value: "start",
        width: 220
      },
      {
        text: this.i18n["vehicleId"],
        align: "start",
        value: "vehicleId"
      },
      {
        text: this.i18n["summary"],
        align: "start",
        value: "summary"
      },

      { text: "", value: "controls", align: "right", sortable: false }
    ];
  }

  /**
   * Returns all future events where we have a vehicle id and the vehicle exists
   */
  get all() {
    return EventModule.events.filter(
      x => x.start >= new Date().getTime() && x.vehicleId && VehicleModule.maps.id.get(x.vehicleId)[0]
    );
  }

  get i18n() {
    return this.$t("views.fleet.FleetVehicleDetailView");
  }

  async loadAll() {
    this.isLoading = true;
    await EventModule.fetchAll({ partnerId: PartnerModule.partner._id }).finally(() => {
      this.isLoading = false;
    });
  }

  getVehicle(id: string) {
    return VehicleModule.maps.id.get(id)[0];
  }

  selectVehicle(event: IEventUIDto) {
    if (!event.vehicleId) {
      return;
    }

    this.$router.push({
      name: "VehicleDetail",
      params: {
        partnerId: PartnerModule.partner._id,
        vehicleId: event.vehicleId
      }
    });
  }
}
