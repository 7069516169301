













import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import ProjectInitializeListsMixin from "@/views/project/mixins/ProjectInitializeListsMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import TicketTableCard from "../project/TicketTableCard.vue";

@Component({
  components: { TicketTableCard }
})
export default class FleetTicketTable extends mixins(Vue, ProjectInitializeListsMixin) {
  get refs(): MrfiktivReferenceGen[] {
    return [];
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
  }

  async mounted() {
    await this.initializeProjectLists(this.partnerId);
  }
}
