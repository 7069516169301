var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', _vm._l(_vm.inputs, function (data, index) {
    return _c('v-list-item', {
      key: index,
      class: "d-flex px-0 ".concat(index > 0 ? 'mt-4' : ''),
      on: {
        "click": function click($event) {
          return _vm.click(data);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-center flex-grow-1 flex-wrap"
    }, [_c('div', {
      staticClass: "me-auto pe-2"
    }, [_c('h4', {
      staticClass: "font-weight-semibold text-truncate"
    }, [_vm._v(" " + _vm._s(data.title) + " ")]), _c('span', {
      staticClass: "text-xs text-truncate"
    }, [_vm._v(_vm._s(data.subtitle))])]), _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }