/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateCredentialDtoGen,
  MrfiktivCredentialControllerGetParamsGen,
  MrfiktivCredentialViewModelGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Credential<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags credential
   * @name CredentialControllerCreate
   * @summary Creates a credential for a given partner
   * @request POST:/partner/{partnerId}/credential
   * @secure
   * @response `201` `MrfiktivCredentialViewModelGen` Credential created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  credentialControllerCreate = (partnerId: string, data: MrfiktivCreateCredentialDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivCredentialViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/credential`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags credential
   * @name CredentialControllerGet
   * @summary Gets all credentials from a given partner
   * @request GET:/partner/{partnerId}/credential
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivCredentialViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  credentialControllerGet = (
    { partnerId, ...query }: MrfiktivCredentialControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivCredentialViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/credential`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags credential
   * @name CredentialControllerUpdate
   * @summary Update credential for a given partner
   * @request PUT:/partner/{partnerId}/credential/{id}
   * @secure
   * @response `200` `MrfiktivCredentialViewModelGen` Updated credential document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  credentialControllerUpdate = (
    partnerId: string,
    id: string,
    data: MrfiktivCreateCredentialDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivCredentialViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/credential/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags credential
   * @name CredentialControllerGetOne
   * @summary Gets a particular credential from a given partner
   * @request GET:/partner/{partnerId}/credential/{id}
   * @secure
   * @response `200` `MrfiktivCredentialViewModelGen` credential returned
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  credentialControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCredentialViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/credential/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags credential
   * @name CredentialControllerRemove
   * @summary Deletes a credential for a given partner
   * @request DELETE:/partner/{partnerId}/credential/{id}
   * @secure
   * @response `200` `MrfiktivCredentialViewModelGen` credential deleted
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  credentialControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCredentialViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/credential/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
