





























import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import BarChartCard from "@/components/analytics/BarChartCard.vue";
import TimeSeriesLineChartCard, { SeriesLineChartOptions } from "@/components/analytics/TimeSeriesLineChartCard.vue";
import DashboardStatisticsList from "@/components/dashboard/DashboardStatisticsList.vue";
import { Component, Prop } from "vue-property-decorator";
import { ReportControlAnalyticsModule } from "@/store/modules/report-control-analytics.store";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import StatisticsCardRow, { IStatisticRowElement } from "../utility/StatisticsCardRow.vue";

@Component({
  components: {
    AnalyticsDateRangeSelectorDialog,
    DashboardStatisticsList,
    TimeSeriesLineChartCard,
    BarChartCard,
    StatisticsCardRow
  }
})
export default class ReportControlAnalyticsDashboard extends PartnerFallbackMixin {
  loading = false;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  dataHandler = {
    trendAnalysisDataForReportControl: {
      getData: async () => {
        await ReportControlAnalyticsModule.getTrendAnalysisDataForReportControl();
      },
      isLoading: false
    },
    forwardedReportsPerPartner: {
      getData: async () => {
        await ReportControlAnalyticsModule.getForwardedReportCountsByPartner();
      },
      isLoading: false
    },
    totalForwardedReportCount: {
      getData: async () => {
        await ReportControlAnalyticsModule.getTotalForwardedReportCount();
      }
    }
  };

  get reportsCountData() {
    return ReportControlAnalyticsModule.totalForwardedReportCount.count;
  }

  get forwardedReportsPerPartnerData() {
    return ReportControlAnalyticsModule.forwardedReportCountsByPartner.partnerDistribution;
  }

  get trendAnalysisDataForReportControlData() {
    return ReportControlAnalyticsModule.trendAnalysisDataForReportControl.data;
  }

  get dates() {
    return ReportControlAnalyticsModule.dateRange;
  }

  get rowData(): IStatisticRowElement[] {
    return [
      {
        icon: "mdi-car-wrench",
        title: "views.portal.ReportControlAnalyticsDashboard.statisticTitle",
        color: "primary",
        data: this.reportsCountData.toString()
      }
    ];
  }

  // Charts
  get trendData(): SeriesLineChartOptions[] {
    const trendData: SeriesLineChartOptions[] = [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: ReportControlAnalyticsModule.trendAnalysisDataForReportControl.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: ReportControlAnalyticsModule.trendAnalysisDataForReportControl.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: ReportControlAnalyticsModule.trendAnalysisDataForReportControl.data.thirtyDayTrend
      }
    ];

    return trendData;
  }

  async mounted() {
    this.loading = true;
    try {
      await this.getData();
    } catch {
      this.$toast.error("Ooops... Fehler beim Laden der Anwendung!");
    } finally {
      this.loading = false;
    }
  }

  async getData() {
    Object.keys(this.dataHandler).forEach(async key => {
      this.dataHandler[key].isLoading = true;

      try {
        await this.dataHandler[key].getData();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    });
  }

  async setDateRange(dateRange: string[]) {
    ReportControlAnalyticsModule.setDateRange(dateRange);
    await this.getData();
  }
}
