































































































































import { detailedDate, detailedDateWithDay, simpleDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { IVehicle } from "@/models/vehicle.entity";
import { VehicleEvent, VehicleEventTypeEnum } from "@/store/modules/fleet-aggregation.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import RefsContract from "../utility/RefsContract.vue";
import RefsList from "../utility/RefsList.vue";
import RefsReport from "../utility/RefsReport.vue";
import RefsTicket from "../utility/RefsTicket.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";
import DeleteDialog from "../dialog/DeleteDialog.vue";
import EventCardAcknowledgementDialog from "../event/EventCardAcknowledgementDialog.vue";

@Component({
  components: {
    RefsList,
    RefsTicket,
    RefsReport,
    RefsContract,
    RefsVehicle,
    DeleteDialog,
    EventCardAcknowledgementDialog
  },
  filters: { simpleDoubleDigitDate, simpleDate, detailedDate, detailedDateWithDay }
})
export default class FleetVehicleDetailEventCardCalendarDetail extends mixins(Vue) {
  @Prop()
  value!: VehicleEvent & { vehicle: IVehicle };

  @Prop({ default: false })
  showAction!: boolean;

  @Prop({ default: true })
  showVehicle!: boolean;

  @Ref("eventCardAcknowledgementDialog")
  eventCardAcknowledgementDialog!: EventCardAcknowledgementDialog;

  readonly TYPES = VehicleEventTypeEnum;

  onAcknowledged() {
    this.$emit("acknowledged");
  }

  getAckUser(by: string) {
    return PartnerUserModule.maps.id.get(by)[0];
  }

  acknowledge() {
    this.eventCardAcknowledgementDialog.open();
  }
}
