























































































import RefsSelectionByType from "@/components/utility/RefsSelectionByType.vue";
import { HandoverStateEnum } from "@/lib/enum/handover-state.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import CompanyForm from "./CompanyForm.vue";
import Debug from "../utility/Debug.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import TemplateEditor from "../template/TemplateEditor.vue";
import CustomFieldDateTime from "../report/CustomFieldDateTime.vue";
import { Company } from "@/models/company.entity";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import RefsVehicle from "../utility/RefsVehicle.vue";

@Component({
  components: {
    ConfirmActionDialog,
    RefsSelectionByType,
    CompanyForm,
    CustomFieldDateTime,
    TemplateEditor,
    Debug,
    RefsVehicle
  }
})
export default class CreateHandoverDialog extends mixins(PartnerFallbackMixin, PermissionMixin) implements IDialog {
  @Prop()
  partnerId!: string;

  @Prop({ default: [] })
  vehicles?: IVehicle[];

  handover: IHandover = new Handover(this._empty);

  selectedVehicles: IVehicle[] = this.vehicles ?? [];

  isCreateLoading = false;
  isDialogActive = false;

  isHandoverValid = false;
  isCompanyValid = false;

  get requiredRule() {
    return [requiredRule()];
  }

  get isValid() {
    return this.isHandoverValid;
  }

  get refs() {
    return this.selectedVehicles.map(v => ({ refId: v.id, refType: ResourceEnum.VEHICLE }));
  }

  private get _empty(): Partial<IHandover> {
    return {
      partnerId: this.partnerId,
      state: HandoverStateEnum.OPEN,
      vehicleIds: this.vehicles?.map(v => v.id)
    };
  }

  async open() {
    for (const vehicle of this.vehicles ?? []) {
      this.setVehicle(vehicle);
    }

    this.isDialogActive = true;
    this.$nextTick(() => {
      (this.$refs.titleTextField as any)?.focus();
    });
  }

  close() {
    this.isDialogActive = false;
  }

  setVehicle(selected: IVehicle) {
    this.selectedVehicles.push(selected);
    this.handover.vehicleIds.push(selected.id);

    const vehicleNames = this.selectedVehicles.map(v => v.displayName);
    if (this.handover.title === this.buildNameSuggestion(vehicleNames.slice(0, -1))) {
      this.handover.title = this.buildNameSuggestion(vehicleNames);
    }
  }

  removeVehicle(index: number) {
    const vehicleNames = this.selectedVehicles.map(v => v.displayName);
    this.selectedVehicles.splice(index, 1);
    this.handover.vehicleIds.splice(index, 1);

    if (this.handover.title === this.buildNameSuggestion(vehicleNames)) {
      vehicleNames.splice(index, 1);
      this.handover.title = this.buildNameSuggestion(vehicleNames);
    }
  }

  buildNameSuggestion(nameBits: (string | undefined)[]) {
    return nameBits.filter(n => !!n).join(", ");
  }

  addCompany() {
    this.handover.parties.push(new Company());
  }

  removeCompany(index: number) {
    this.handover.parties.splice(index, 1);
  }

  async create() {
    try {
      this.isCreateLoading = true;
      const created = await this.handover.create();
      this.$toast("👍");
      new GoToHelper(this.$router).goToHandoverDetail(created.id, created.partnerId, false);
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateLoading = false;
      this.isDialogActive = false;
    }
  }
}
