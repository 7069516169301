var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isLoading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), _vm._l(_vm.events, function (event) {
    return _c('v-card', {
      key: event.start,
      staticClass: "my-1",
      attrs: {
        "outlined": ""
      }
    }, [_c('refs-event', {
      attrs: {
        "item": event,
        "small": true,
        "confirmable": true
      },
      on: {
        "click": _vm.goToEventDetail,
        "openDetail": _vm.goToEventDetail
      }
    })], 1);
  }), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.isLoading,
      "x-small": "",
      "text": "",
      "elevation": 0
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v(" (" + _vm._s(_vm._f("simpleDoubleDigitDate")(new Date(_vm.value.start).toISOString())) + " - " + _vm._s(_vm._f("simpleDoubleDigitDate")(new Date(_vm.timeFrame.end).toISOString())) + ") " + _vm._s(_vm.$t("components.partner.PartnerReportList.loadMore")) + " ")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }