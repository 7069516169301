






































import TableWrapper, { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import { MrfiktivMileageGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";

import { Component, Prop, Vue } from "vue-property-decorator";
import CreateMileageDialog from "./CreateMileageDialog.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { TableWrapper, CreateMileageDialog, ConfirmActionDialog }
})
export default class FleetVehicleDetailVehicleMileageDataCard extends Vue {
  @Prop()
  vehicle!: IVehicle;

  @Prop()
  loading!: boolean;

  isMileageCreateDialogActive = false;

  deleteMileage: MrfiktivMileageGen | undefined;

  isDisabled = true;

  mileageDialog = false;

  removeMileageDialog = false;

  mileages = this.vehicle.mileages || [];

  selected: MrfiktivMileageGen[] = [];

  remove() {
    const index = this.mileages?.findIndex(v => {
      return simpleDate(v.date) === simpleDate(this.deleteMileage?.date || "");
    });

    if (index !== -1) {
      this.mileages.splice(index, 1);
    }
    this.abortRemoval();
    this.emitRefresh();
  }

  abortRemoval() {
    this.removeMileageDialog = false;
    this.deleteMileage = undefined;
  }

  openDeleteDialog(mileage: MrfiktivMileageGen) {
    this.deleteMileage = mileage;
    this.removeMileageDialog = true;
  }

  onBack() {
    this.mileageDialog = false;
  }

  get controlElements(): IControlElements[] {
    return [{ icon: "mdi-trash-can", action: this.openDeleteDialog, text: this.i18n["remove"] }];
  }

  get allMileages() {
    return this.mileages.map(m => {
      return { mileage: m.mileage, date: m.date.substring(0, 10) };
    });
  }

  set allMileages(mileages: MrfiktivMileageGen[]) {
    this.mileages = mileages.map(m => {
      return { mileage: Number(m.mileage), date: new Date(m.date).toISOString() };
    });
    this.emitUpdate();
  }

  get emptyMileage() {
    return { date: "", mileage: 0 };
  }

  get headers(): ITableWrapperHeader[] {
    return [
      { text: this.i18n["date"], value: "date", placeholder: "", type: "date" },
      { text: this.i18n["mileage"], value: "mileage", placeholder: "", type: "number" },
      { text: "", align: "end", value: "controls", sortable: false }
    ];
  }

  get lastMileage(): MrfiktivMileageGen {
    if (!this.allMileages || this.allMileages.length <= 0) {
      return { date: new Date().toDateString(), mileage: 0 };
    }

    return this.allMileages[this.allMileages.length - 1];
  }

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailVehicleMileageDataCard") || {};
  }

  beforeMount() {
    if (!this.vehicle.mileages) {
      this.vehicle.mileages = [];
    }
    this.mileages = this.vehicle.mileages ?? [];
  }

  openDialog() {
    this.mileageDialog = true;
  }

  removeItem(removeItem: MrfiktivMileageGen) {
    const index = this.mileages.findIndex(m => m === removeItem);

    this.allMileages.splice(index, 1);
  }

  emitRefresh() {
    this.$emit("refresh");
  }

  emitUpdate() {
    this.$emit("change", this.vehicle);
  }
}
