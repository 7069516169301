








import CustomerAccountDetail from "@/components/partner/CustomerAccountDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { handleError } from "@/lib/utility/handleError";
import { CustomerAccountModule } from "@/store/modules/customer-account.store";

@Component({
  components: {
    LayoutSimple,
    CustomerAccountDetail
  }
})
export default class CustomerAccountDetailView extends Vue {
  isLoading = false;

  get customerAccountId() {
    return this.$route.params.customerAccountId;
  }

  get customerAccount() {
    return CustomerAccountModule.currentAccount;
  }

  async mounted() {
    this.isLoading = true;
    try {
      await CustomerAccountModule.getOne(this.customerAccountId);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
