

















































import FilterCard from "@/components/filter/FilterCard.vue";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { TimeStampEntity } from "@/models/timestampEntity";
import { MrfiktivPartnerMessageViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgPartnerMessageViewModelGen } from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TheLayoutPortalList from "../../layouts/TheLayoutPortalList.vue";
import TemplateDialog from "../template/TemplateDialog.vue";
import Tooltip from "../utility/tooltip.vue";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import { avatar } from "@/lib/utility/mail-helper";

@Component({
  components: {
    FilterCard,
    Tooltip,
    TheLayoutPortalList,
    AssigneePreview,
    TemplateDialog
  }
})
export default class PartnerMessageListPaginated extends mixins(
  DarkModeHighlightMixin,
  PartnerFallbackMixin,
  PermissionMixin
) {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({
    default: "components.PartnerMessageListPaginated.title"
  })
  title!: string;

  @Prop({})
  store!: PaginatedBaseStore<any, any>;

  isLoadingMore = false;

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  displayName(item: MrfiktivPartnerMessageViewModelGen): string {
    if (item.folder === "outbox") {
      return item.to;
    }

    if (item.from) {
      return item.from;
    }

    return "";
  }

  avatar(item: MrfiktivPartnerMessageViewModelGen): string {
    const mail = this.displayName(item);

    return avatar(mail);
  }

  getModule() {
    return this.store;
  }

  async set(item: any) {
    this.$emit("set", item);
  }

  async refresh() {
    this.loading = true;

    try {
      await this.store.fetchFirstPage({
        partnerId: PartnerModule.partner._id
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      await this.store.fetchNextPage({
        partnerId: PartnerModule.partner._id
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingMore = false;
    }
  }

  /**
   * Some methods for the presentation of items in the list
   */
  itemFunctions = {
    body: (item: any) => {
      let body = item.content.body.replace("<br><br><br>", " ");
      body = item.content.body.replace("<br><br>", " ");
      body = body.replace("<br>", " ");
      return body.replace(/<[^>]+>/g, "");
    },
    date: (item: MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen) => {
      return simpleDate((item.timestamp as TimeStampEntity).created);
    }
  };
}
