var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "title": _vm.$t('views.booking.ResourceTable.title'),
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "controlElements": _vm.controlElements,
      "headers": _vm.headers,
      "showSelect": true,
      "selectedItems": _vm.selectedItems
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "headerActions",
      fn: function fn() {
        return [_c('v-btn', {
          on: {
            "click": function click($event) {
              _vm.isCreateDialogActive = true;
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("views.booking.ResourceTable.create")))])];
      },
      proxy: true
    }, {
      key: "selectActions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "ma-1",
          attrs: {
            "outlined": "",
            "color": "error"
          },
          on: {
            "click": _vm.openDeleteAllDialog
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("views.booking.ResourceTable.delete")) + " ")])];
      },
      proxy: true
    }, {
      key: "item.isActive",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "color": _vm.getStateColor(item)
          }
        }, [_vm._v(" " + _vm._s(item.isActive ? "Active" : "InActive") + " ")])];
      }
    }, {
      key: "item.availability",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm._f("displayAvailableWeekDaysAsShortString")(item.availability)) + " ")];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamp.created)) + " ")];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'ResourceDetail',
              params: {
                partnerId: item.partnerId,
                resourceId: item.id
              }
            }
          }
        }, [_vm._v(_vm._s(item.name))])];
      }
    }])
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('views.booking.ResourceTable.confirmDeleteTitle'),
      "subtitle": _vm.$t('views.booking.ResourceTable.confirmDeleteSubtitle'),
      "leftLoading": _vm.isDeleteDialogLoading,
      "rightLoading": _vm.isDeleteDialogLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.deleteResource
    }
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteAllDialogActive,
      "title": _vm.$t('views.booking.ResourceTable.confirmDeleteAllTitle'),
      "subtitle": _vm.$t('views.booking.ResourceTable.confirmDeleteAllSubtitle', {
        counter: _vm.selectedItems.length
      }),
      "leftLoading": _vm.isDeleteAllDialogLoading,
      "rightLoading": _vm.isDeleteAllDialogLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteAllDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteAllDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteAllDialogActive = false;
      },
      "rightClick": _vm.deleteResources
    }
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isCreateDialogActive,
      "title": _vm.$t('views.booking.ResourceTable.confirmCreateTitle'),
      "subtitle": _vm.$t('views.booking.ResourceTable.confirmCreateSubtitle'),
      "leftLoading": _vm.isCreateDialogLoading,
      "rightDisabled": !_vm.isValid,
      "rightLoading": _vm.isCreateDialogLoading,
      "width": "850"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isCreateDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isCreateDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isCreateDialogActive = false;
      },
      "rightClick": _vm.createResource
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "flat": "",
      "width": "850"
    }
  }, [_c('resource-form', {
    attrs: {
      "isLoading": _vm.isCreateDialogLoading,
      "value": _vm.resourceDto,
      "isCreate": true
    },
    on: {
      "valid-change": function validChange($event) {
        _vm.isValid = $event;
      },
      "input": function input($event) {
        _vm.resourceDto = $event;
      }
    }
  })], 1)], 1), _vm.isUpdateDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isUpdateDialogActive,
      "persistent": true,
      "hideClose": true,
      "title": _vm.$t('views.booking.ResourceTable.confirmCreateTitle'),
      "subtitle": _vm.$t('views.booking.ResourceTable.confirmCreateSubtitle'),
      "leftLoading": _vm.isUpdateDialogLoading,
      "rightDisabled": !_vm.isValid,
      "rightLoading": _vm.isUpdateDialogLoading,
      "width": "850"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isUpdateDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isUpdateDialogActive = $event;
      },
      "leftClick": _vm.closeUpdateResourceDialog,
      "rightClick": _vm.updateResource
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "flat": "",
      "width": "850"
    }
  }, [_c('resource-form', {
    attrs: {
      "isLoading": _vm.isUpdateDialogLoading,
      "value": _vm.updateResourceDto,
      "isCreate": true
    },
    on: {
      "valid-change": function validChange($event) {
        _vm.isValid = $event;
      },
      "input": function input($event) {
        _vm.updateResourceDto = $event;
      }
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }