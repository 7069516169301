


































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FleetDriverDetailDriverCard from "@/components/fleet/FleetDriverDetailDriverCard.vue";
import FleetVehicleDetailContractTable from "@/components/fleet/FleetVehicleDetailContractTable.vue";
import FleetVehicleDetailDocumentCard from "@/components/fleet/FleetVehicleDetailDocumentCard.vue";
import Card from "@/components/utility/Card.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivDriverViewModelGen, MrfiktivUpdateDriverDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { DriverModule } from "@/store/modules/driver.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    LayoutSimple,
    Card,
    FleetDriverDetailDriverCard,
    FleetVehicleDetailDocumentCard,
    FleetVehicleDetailContractTable,
    LatestEntriesCardEmpty
  }
})
export default class FleetDriverDetailView extends PartnerFallbackMixin {
  loading = false;
  loadingUpdate = false;

  tab = "tab";
  tab0 = "details";
  tab1 = "documents";

  get i18n() {
    return this.$t("views.fleet.FleetDriverDetailView");
  }

  get items() {
    const items = [this.tab0];

    items.push(this.tab1);

    return items;
  }

  driver: MrfiktivDriverViewModelGen | undefined;

  async loadDriver() {
    try {
      this.loading = true;
      await this.trySetByRouteOrDefault();
      const driver = await DriverModule.getOne({
        partnerId: PartnerModule.partner._id,
        driverId: this.$route.params.driverId
      });
      this.driver = driver;
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loading = false;
    }
  }

  async updateDriver(driver: MrfiktivDriverViewModelGen, refresh = false) {
    try {
      const updateDto: MrfiktivUpdateDriverDtoGen = {
        firstName: driver.firstName,
        lastName: driver.lastName,
        startDate: driver.startDate,
        endDate: driver.endDate,
        contact: driver.contact,
        driverLicenseCheck: driver.driverLicenseCheck,
        driverUvvCheck: driver.driverUvvCheck
      };

      this.loadingUpdate = true;

      const updated = await DriverModule.update({
        partnerId: PartnerModule.partner._id,
        driverId: driver.id,
        data: updateDto
      });
      this.driver = updated;
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loadingUpdate = false;
      if (refresh) {
        await this.loadDriver();
      }
    }
  }

  async mounted() {
    await this.loadDriver();
  }
}
