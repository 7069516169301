







































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PortalBankingDialog from "@/components/partner/PortalBankingDialog.vue";
import UserAdministrationResendInvitationDialog from "@/components/partner/UserAdministrationResendInvitationDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IPartnerUser } from "@/models/user.entity";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    CustomerContactCard,
    PortalBankingDialog,
    LatestEntriesCardEmpty,
    Card,
    UserAdministrationResendInvitationDialog,
    ConfirmActionDialog
  }
})
export default class PartnerUserDangerZoneCard extends DarkModeHighlightMixin {
  @Prop()
  partnerId?: string;

  @Prop()
  selectedUser!: IPartnerUser;

  /**
   * Confirm action dialogs
   */
  isUnsubscribeDialogActive = false;
  isLoadingUnsubscribe = false;
  isDeleteDialogActive = false;

  /**
   * Loading if a user is deleted
   */
  isLoadingDeletion = false;

  /**
   * deletion is valid if user name and user id matches (asked in confirm action dialog)
   */
  isDeletionValid = false;

  /**
   * value for validation that userId matches selected user id
   */
  userIdToDelete = "";

  /**
   * value for validation that unser name matches selected user name
   */
  userNameToDelete = "";

  /**
   * required rule
   */
  get requiredRule() {
    return [requiredRule()];
  }

  /**
   * checks if userId and username matches
   */
  get deletionParamsValid(): boolean {
    return (
      this.isDeletionValid &&
      this.userIdToDelete === this.selectedUser?._id &&
      this.userNameToDelete === this.selectedUser?.userName
    );
  }

  /**
   * Open delete Dialog (and fetch ghg if app context is ghg)
   */
  async openDeleteDialog() {
    this.isDeleteDialogActive = true;
  }

  /**
   * Deletes a user
   */
  async deleteUser() {
    this.isLoadingDeletion = true;

    try {
      await this.selectedUser?.delete();
      this.$toast.success("Nutzer wurde erfolgreich gelöscht.");
      this.$router.push({ name: "PartnerUserList" });
    } catch (error) {
      handleError(error);
    } finally {
      this.isDeleteDialogActive = false;
      this.isLoadingDeletion = false;
    }
  }

  /**
   * headers for thg table
   */
  get headers() {
    return [
      {
        text: "ID",
        value: "id"
      },
      {
        text: "Status",
        value: "status"
      }
    ];
  }
}
