var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "absolute": true,
      "fullscreen": _vm.$vuetify.breakpoint.mobile,
      "width": "800px",
      "loading": _vm.isLoading,
      "hideTitle": true
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-container', {
    staticClass: "pb-10 mb-10"
  }, [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-two-line"
    }
  }) : _c('snapshot-detail', {
    attrs: {
      "hideBreadCrumbs": true
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }