var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', [_vm.driver ? _c('v-container', {
    staticClass: "wrapper"
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4"
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.driver.firstName) + " " + _vm._s(_vm.driver.lastName))]), _c('v-spacer')], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(new Date(_vm.driver.startDate).toLocaleDateString()) + " ")])], 1), _c('v-tabs', {
    attrs: {
      "fixed-tabs": ""
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tabs-slider', {
    attrs: {
      "color": "yellow"
    }
  }), _vm._l(_vm.items, function (item) {
    return _c('v-tab', {
      key: item
    }, [_vm._v(" " + _vm._s(_vm.i18n[item]) + " ")]);
  })], 2), _c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-tab-item', {
      key: item
    }, [item === _vm.tab0 ? _c('fleet-driver-detail-driver-card', {
      attrs: {
        "driver": _vm.driver
      },
      on: {
        "change": _vm.updateDriver
      }
    }) : _vm._e(), item === _vm.tab1 ? _c('fleet-vehicle-detail-document-card') : _vm._e()], 1);
  }), 1)], 1) : _vm.loading ? _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, divider, list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line"
    }
  }) : _c('latest-entries-card-empty')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }