




























































import { Component, Prop } from "vue-property-decorator";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { MrfiktivUpdateVehicleDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";

import { handleError } from "@/lib/utility/handleError";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { mixins } from "vue-class-component";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import AEicon from "../utility/AEicon.vue";

@Component({
  components: {
    ConfirmActionDialog,
    AEicon
  },
  filters: { simpleDoubleDigitDate }
})
export default class CreateMileageDialog extends mixins(RulesMixin) {
  @Prop()
  vehicle!: IVehicle;

  @Prop()
  private isDialogActive!: boolean;
  isValid = false;

  date: string = new Date().toISOString().slice(0, 10);

  mileage: number | string = "";

  loading = false;

  get isDialogActiveNotProp() {
    return this.isDialogActive;
  }

  set isDialogActiveNotProp(value: boolean) {
    this.$emit("update:isDialogActive", value);
  }

  get latestMileage() {
    return this.vehicle.mileages?.sort((a, b) => (a.date > b.date ? -1 : 1))[0];
  }

  beforeMount() {
    if (!this.vehicle.mileages) {
      this.vehicle.mileages = [];
    }
  }

  close() {
    this.isDialogActiveNotProp = false;
    this.mileage = "";
    this.date = new Date().toISOString().slice(0, 10);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  async addMileage() {
    const partnerId = this.vehicle.partnerId;
    const vehicleId = this.vehicle.id;
    const mileages = this.vehicle.mileages ?? [];

    if (!this.mileage) return;
    if (!this.date) return;

    mileages.push({ date: this.date, mileage: Number(this.mileage) });

    const data: MrfiktivUpdateVehicleDtoGen = { mileages };

    try {
      this.loading = true;
      const updated = await VehicleModule.update({
        partnerId,
        vehicleId,
        data
      });
      if (!this.vehicle.mileages) {
        this.vehicle.mileages = [];
      }
      this.vehicle.mileages.splice(0, this.vehicle.mileages.length, ...(updated.mileages ?? []));
      this.isDialogActiveNotProp = false;
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }
}
