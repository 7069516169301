import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Fahrzeugschein from "@/views/Fahrzeugschein.vue";
import FahrzeugscheinComponent from "@/components/fahrzeugschein/FahrzeugscheinComponent.vue";
import FahrzeugscheinUpload from "@/components/fahrzeugschein/FahrzeugscheinUpload.vue";
import { permissionGuard } from "@/auth/permissionAuthGuard";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Fahrzeugschein",
    component: Fahrzeugschein
  },
  {
    path: "/new",
    name: "FahrzeugscheinNew",
    component: FahrzeugscheinUpload
  },

  {
    path: "/:id",
    name: "FahrzeugscheinComponent",
    component: FahrzeugscheinComponent
  }
];

const router = new VueRouter({
  routes,
  base: "/"
});

router.beforeEach(permissionGuard);

export default router;
