import { ITableWrapperHeader } from "@/lib/types/tableWrapper";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { ICustomViewableEntity } from "@/lib/interfaces/custom-viewable-entity.interface";
import { IsCustomViewable } from "@/lib/interfaces/is-custom-viewable.interface";
import { getNestedObjectValues } from "@/lib/objectPath-helper";
import { transformBooleanToText } from "@/lib/utility/boolean-helper";
import { $t } from "@/lib/utility/t";
import { ICustomFieldListElement } from "@/models/custom-field-list-element.entity";
import {
  MrfiktivCustomFieldListElementViewModelGen,
  MrfiktivProjectCustomViewFieldDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { CustomFieldEnum } from "@/store/modules/custom-field.store";
import { TicketModule } from "@/store/modules/ticket.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";
import { ICustomFieldValue } from "@/models/custom-field-value.entity";

export type CustomViewDataTableHeader = ITableWrapperHeader & {
  customFieldConfig?: MrfiktivCustomFieldListElementViewModelGen;
  ref?: BackendResourceEnum;
};

export type CustomViewExtendedDocument = {
  id: string;
  label: string;
  description: string;
  value: string | string[];
  color?: string;
  dark?: boolean;
  style?: string;
};

/**
 *
 */
export class CustomProjectTableHelper<Type extends ICustomViewableEntity<IsCustomViewable, any>> {
  /**
   *
   */
  item?: Type;

  /**
   *
   */
  customConfig!: MrfiktivProjectCustomViewFieldDtoGen[];

  filterOptions: PaginationFilterListElement[] = [];

  customFieldConfigArray: ICustomFieldListElement[];

  constructor(
    customConfig: MrfiktivProjectCustomViewFieldDtoGen[],
    filterOptions: PaginationFilterListElement[],
    customFieldConfigArray: ICustomFieldListElement[],
    item?: Type
  ) {
    this.item = item;
    this.filterOptions = filterOptions;
    this.customConfig = customConfig;
    this.customFieldConfigArray = customFieldConfigArray;
  }

  get headers(): CustomViewDataTableHeader[] {
    const headers: CustomViewDataTableHeader[] = [];

    if (!this.customConfig) {
      return headers;
    }

    for (const field of this.customConfig) {
      const propKey = field.key.split("?")[0];
      const refTypeOrCustomFieldId = field.key.split("?")[1];

      switch (field.type) {
        case ProjectCustomViewFieldEnum.CUSTOM_FIELD: {
          const config = this.getCustomFieldConfig(refTypeOrCustomFieldId);

          if (config) {
            switch (config.customField.type) {
              case CustomFieldEnum.BOOLEAN: {
                headers.push({
                  text: config.customField.label,
                  value: propKey + "?" + refTypeOrCustomFieldId,
                  width: "150",
                  customFieldConfig: config
                });

                break;
              }

              case CustomFieldEnum.DATE: {
                headers.push({
                  text: config.customField.label,
                  value: propKey + "?" + refTypeOrCustomFieldId,
                  width: "150",
                  customFieldConfig: config,
                  sort: this.dateSort
                });

                break;
              }

              case CustomFieldEnum.DATE_TIME: {
                headers.push({
                  text: config.customField.label,
                  value: propKey + "?" + refTypeOrCustomFieldId,
                  width: "150",
                  customFieldConfig: config,
                  sort: this.dateSort
                });

                break;
              }

              case CustomFieldEnum.NUMBER: {
                headers.push({
                  text: config.customField.label,
                  value: propKey + "?" + refTypeOrCustomFieldId,
                  customFieldConfig: config
                });

                break;
              }

              default: {
                headers.push({
                  text: config.customField.label,
                  value: propKey + "?" + refTypeOrCustomFieldId,
                  customFieldConfig: config
                });

                break;
              }
            }
          }

          break;
        }

        case ProjectCustomViewFieldEnum.REF: {
          if (refTypeOrCustomFieldId) {
            headers.push({
              text: $t(`BackendResourceEnum.${refTypeOrCustomFieldId}`),
              value: propKey + "?" + refTypeOrCustomFieldId,
              width: "200",
              ref: refTypeOrCustomFieldId as BackendResourceEnum
            });
          }

          break;
        }

        default: {
          const filterOption = this.filterOptions?.find(f => f.key === propKey);

          if (!filterOption) {
            continue;
          }

          headers.push({
            text: $t(filterOption?.displayName ?? propKey),
            value: propKey,
            width: filterOption?.width
          });
          break;
        }
      }
    }

    return headers;
  }

  /**
   * Sorts two dates
   * @param a the first element
   * @param b the second element
   * @returns when both undefined append as equal, when comparer undefined append, sort ASC otherwise
   */
  dateSort(a: CustomViewExtendedDocument, b: CustomViewExtendedDocument) {
    if (!a?.value && !b?.value) {
      return 0;
    }

    if (!b?.value) {
      return 1;
    }

    if (!a?.value) {
      return -1;
    }

    return new Date(a.value as string).getTime() - new Date(b.value as string).getTime();
  }

  getCustomFieldConfig(customFieldId: string) {
    return this.customFieldConfigArray?.find(c => c.customField.id === customFieldId);
  }

  /**
   * adds custom properties to item, e.g custom field with key 1234 is "open"
   * {..., 1234: "open"}
   *
   * also returns the added fields as array for the table;
   *
   * @returns
   */
  getExtendedDocument(): CustomViewExtendedDocument[] {
    if (!this.item) {
      return [];
    }
    const fields: CustomViewExtendedDocument[] = [];

    if (!this.customConfig) {
      return fields;
    }
    for (const config of this.customConfig) {
      /**
       * CustomFields lookup
       * If custom fields are configure, searches for the custom field if its set in the specific ticket
       * checks for different types of ticket
       * Transforms
       */
      if (config.type === ProjectCustomViewFieldEnum.CUSTOM_FIELD) {
        const found = this.item.values?.find(field => {
          const id = config.key.split("?")[1];
          return field.id === id;
        });
        if (found && found?.value) {
          const customField = this.getCustomFieldConfig(found.id);

          switch (customField?.customField.type) {
            case CustomFieldEnum.DATE: {
              const item = {
                id: found.id,
                label: this.getCustomFieldLabel(found),
                description: this.getCustomFieldLabel(found),
                value: found.value,
                color: undefined,
                dark: true,
                style: undefined
              };

              this.item[config.key] = item;
              fields.push(item);
              break;
            }

            case CustomFieldEnum.DATE_TIME: {
              const item = {
                id: found.id,
                label: this.getCustomFieldLabel(found),
                description: this.getCustomFieldLabel(found),
                value: found.value,
                color: undefined,
                dark: true,
                style: undefined
              };

              this.item[config.key] = item;
              fields.push(item);
              break;
            }
            case CustomFieldEnum.SINGLE_SELECT: {
              const item = {
                id: found.id,
                label: this.getCustomFieldLabel(found),
                description: this.getCustomFieldLabel(found),
                color: this.getCustomFieldColor(found)?.color,
                dark: true,
                style: this.getCustomFieldColor(found)?.style,
                value: found.value || ""
              };

              this.item[config.key] = item;
              fields.push(item);

              break;
            }
            case CustomFieldEnum.MULTI_SELECT: {
              const item = {
                id: found.id,
                label: this.getCustomFieldLabel(found),
                description: this.getCustomFieldLabel(found),
                color: this.getCustomFieldColor(found)?.color,
                dark: true,
                style: this.getCustomFieldColor(found)?.style,
                value: found.value || ""
              };

              this.item[config.key] = item;
              fields.push(item);

              break;
            }
            case CustomFieldEnum.BOOLEAN: {
              const item = {
                id: found.id,
                label: this.getCustomFieldLabel(found),
                description: this.getCustomFieldLabel(found),
                dark: false,
                color: undefined,
                style: undefined,
                value: transformBooleanToText(found.value as string) || ""
              };

              this.item[config.key] = item;
              fields.push(item);
              break;
            }
            default: {
              const item = {
                id: found.id,
                label: this.getCustomFieldLabel(found),
                description: this.getCustomFieldLabel(found),
                dark: false,
                color: undefined,
                style: undefined,
                value: found.value || ""
              };
              if (item) {
                this.item[config.key] = item;
                fields.push(item);
              }

              break;
            }
          }
        }
      }
      if (config.type === ProjectCustomViewFieldEnum.REF) {
        if (config.key === BackendResourceEnum.VEHICLE) {
          /**
           * Filter all refs since there are potentially more refs
           */
          const refs = this.item.refs?.filter(ref => ref.refType === BackendResourceEnum.VEHICLE);
          if (refs) {
            for (const vehicle of refs) {
              const foundVehicle = VehicleModule.vehicles.find(item => item.id === vehicle.refId);
              if (foundVehicle) {
                const item = {
                  id: foundVehicle.id,
                  label: "Fahrzeug",
                  description: "Fahrzeug",
                  dark: true,
                  color: "blue",
                  style: undefined,
                  value: foundVehicle.displayName || foundVehicle.numberplate
                };

                this.item[config.key] = item;
                fields.push(item);
              }
            }
          }
        }
      }

      /**
       * Only push attribute values to array since the extended item already hast state etc
       */
      if (config.type === ProjectCustomViewFieldEnum.PROPERTY) {
        if (config.key === "state") {
          const value = getNestedObjectValues(this.item, config.key).toString();
          if (value) {
            const item = {
              id: value,
              label: "Status",
              description: "Status",
              dark: true,
              color: TicketModule.ticketStateMap.get(value as TicketStatusEnum)?.color || "blue-grey lighten-4",
              style: undefined,
              value: $t("project.ticketState." + value)
            };

            fields.push(item);
          }
        }
      }
    }
    return fields;
  }

  getCustomFieldLabel(customField: ICustomFieldValue): string {
    const config = this.getCustomFieldConfig(customField.id);
    if (!config) {
      return "";
    }
    return config.customField.label;
  }

  getCustomFieldColor(customField: ICustomFieldValue): { color: string; style: string } | undefined {
    const config = this.getCustomFieldConfig(customField.id);
    if (!config) {
      return undefined;
    }

    const value = config.customField.configuration?.values.filter(value => value.value === customField.value);
    if (value && value.length) {
      return { color: value[0].color, style: "color:white" };
    }
  }
}
