



































import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    MHeader: MHeader
  }
})
export default class DesignGuidelines extends PartnerFallbackMixin {
  foundPartner = false;
  loading = false;

  get actions() {
    return [
      {
        text: "Primary",
        key: "primaryAction",
        icon: "mdi-cog-outline"
      },
      {
        text: "Secondary",
        key: "secondaryAction",
        icon: "mdi-cog-outline"
      },
      {
        text: "Third Action",
        key: "thirdAction",
        icon: "mdi-cog-outline"
      },
      {
        text: "Fourth Action",
        key: "fourthAction",
        icon: "mdi-delete-outline",
        color: "red"
      }
    ];
  }

  get breadCrumbs() {
    return [
      {
        text: "Design Guidelines",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelines",
          params: {}
        }
      }
    ];
  }

  processAction(action: { text: string; key: string }) {
    this.$toast("Action clicked: " + action.text);
  }

  goToHeaderExampleWithChips() {
    this.$router.push({ name: "DesignGuidelinesHeaderExample" });
  }

  goToMobileOptimizedPortal() {
    this.$router.push({ name: "DesignGuidelinesPortalMobileOptimizedExample" });
  }
}
