var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.hideButton ? _c('v-btn', {
    attrs: {
      "elevation": 0
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]) : _vm._e(), _vm.isDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "width": "1050",
      "persistent": true,
      "supressKeyboardActions": true,
      "title": _vm.$t('blueprint.create.title'),
      "isDialogActive": _vm.isDialogActive,
      "rightText": _vm.$t('create'),
      "loading": _vm.isLoading,
      "fullscreen": _vm.fullscreen,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.blueprints.length || !_vm.valid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.onLeftClick,
      "close": function close($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.onRightClick
    }
  }, [!_vm.blueprints.length && !_vm.isSelectVehicle ? _c('div', [_c('drop-area-multi', {
    staticClass: "my-2",
    attrs: {
      "files": _vm.files,
      "maxFiles": 10,
      "loading": _vm.isLoading,
      "loadingProgress": _vm.loadingProgress,
      "text": _vm.$t('blueprint.create.dragOver'),
      "dragoverText": _vm.$t('blueprint.create.dragOver')
    },
    on: {
      "droped": _vm.createDto,
      "update:files": function updateFiles($event) {
        _vm.files = $event;
      }
    }
  }), _c('div', {
    staticClass: "mb-3 mt-2",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _vm._v(" " + _vm._s(_vm.$t("blueprint.create.or")) + " "), _c('v-spacer')], 1), _c('v-btn', {
    attrs: {
      "block": "",
      "elevation": 0,
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.isSelectVehicle = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("blueprint.create.fromOtherVehicle")) + " ")])], 1) : _vm._e(), _vm.isSelectVehicle ? _c('div', [!_vm.selectedVehicle ? _c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("blueprint.create.selectVehicle")) + " ")]) : _vm._e(), !_vm.selectedVehicle ? _c('refs-selection-by-type', {
    attrs: {
      "partnerId": _vm.partnerId,
      "refType": _vm.BackendResourceEnum.VEHICLE,
      "showAsMenu": false,
      "selected": _vm.selectedVehicle ? [{
        refId: _vm.selectedVehicle.id,
        refType: _vm.BackendResourceEnum.VEHICLE
      }] : []
    },
    on: {
      "select": _vm.selectVehicle
    }
  }) : _vm._e(), _vm.fetchingSelectedVehicle ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : !_vm.blueprints.length && _vm.selectedVehicle ? _c('div', [_c('v-row', [_c('v-spacer'), _c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": require("@/assets/undraw/undraw_no_data_re_kwbl.svg"),
      "height": "100",
      "width": "200",
      "contain": ""
    }
  }), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "ma-1"
  }, [_c('v-spacer'), _vm._v(" "), _c('b', [_vm._v(" Keine Dokumente verfügbar ")]), _vm._v(" "), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mb-1"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.selectedVehicle = null;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("blueprint.create.pickAnotherVehicle")) + " ")]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "mt-1"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "small": ""
    },
    on: {
      "click": function click() {
        _vm.selectedVehicle = null;
        _vm.isSelectVehicle = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("blueprint.create.uploadImages")) + " ")]), _c('v-spacer')], 1)], 1) : _vm._e()], 1) : _vm._e(), _c('v-form', {
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, _vm._l(_vm.blueprints, function (blueprint, index) {
    var _blueprint$file;

    return _c('div', {
      key: index,
      staticClass: "blueprint-form my-4"
    }, [_c('v-row', {
      staticClass: "mb-8 mx-1",
      attrs: {
        "align": "center",
        "justify": "space-between"
      }
    }, [_c('span', {
      staticClass: "title"
    }, [_vm._v(_vm._s((_blueprint$file = blueprint.file) === null || _blueprint$file === void 0 ? void 0 : _blueprint$file.name))]), _c('v-btn', {
      attrs: {
        "outlined": "",
        "color": "error"
      },
      on: {
        "click": function click($event) {
          return _vm.removeItem(index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("remove")))])], 1), _c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "d-flex align-items-start",
      attrs: {
        "cols": "auto"
      }
    }, [_c('image-dialog', {
      attrs: {
        "cardClass": "mb-4 mr-4",
        "src": blueprint.imageSrc,
        "title": index + 1
      }
    })], 1), _c('v-col', {
      staticClass: "d-flex flex-grow-1"
    }, [_c('v-row', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-text-field', {
      staticClass: "mb-3",
      attrs: {
        "label": _vm.$t('blueprint.name'),
        "rules": _vm.requiredRule,
        "outlined": ""
      },
      model: {
        value: blueprint.name,
        callback: function callback($$v) {
          _vm.$set(blueprint, "name", $$v);
        },
        expression: "blueprint.name"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-select', {
      staticClass: "mb-3",
      attrs: {
        "items": _vm.blueprintTypeItems,
        "item-value": "key",
        "item-text": "text",
        "label": _vm.$t('blueprint.type'),
        "outlined": ""
      },
      model: {
        value: blueprint.type,
        callback: function callback($$v) {
          _vm.$set(blueprint, "type", $$v);
        },
        expression: "blueprint.type"
      }
    })], 1), blueprint.location ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, _vm._l(blueprint.location || [], function (location, index) {
      return _c('v-row', {
        key: index,
        attrs: {
          "dense": ""
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "8"
        }
      }, [_c('v-text-field', {
        attrs: {
          "label": _vm.$t('blueprint.create.locationCount', {
            index: index + 1
          }),
          "dense": "",
          "outlined": ""
        },
        model: {
          value: blueprint.location[index],
          callback: function callback($$v) {
            _vm.$set(blueprint.location, index, $$v);
          },
          expression: "blueprint.location[index]"
        }
      })], 1), _c('v-col', {
        attrs: {
          "cols": "4"
        }
      }, [_c('v-btn', {
        attrs: {
          "outlined": "",
          "color": "error"
        },
        on: {
          "click": function click($event) {
            return _vm.removeLocationItem(blueprint, index);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")])], 1)], 1);
    }), 1) : _vm._e(), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-btn', {
      staticClass: "mb-3",
      attrs: {
        "outlined": "",
        "color": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.addLoaction(blueprint);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("blueprint.create.addLocation")) + " ")])], 1), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-textarea', {
      staticClass: "mb-3",
      attrs: {
        "label": _vm.$t('blueprint.description'),
        "outlined": ""
      },
      model: {
        value: blueprint.description,
        callback: function callback($$v) {
          _vm.$set(blueprint, "description", $$v);
        },
        expression: "blueprint.description"
      }
    })], 1)], 1)], 1)], 1)], 1);
  }), 0)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }