var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('debug', [_vm._v(" TicketDetailView " + _vm._s(_vm.partnerId) + " " + _vm._s(_vm.projectId) + " " + _vm._s(_vm.ticketNumber) + " ")]), _c('v-container', [_vm.loadingProject ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.project ? _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.project.title,
      "breadCrumbs": _vm.breadCrumbList,
      "actions": _vm.isEditTitle ? [] : _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      },
      "alertClicked": function alertClicked($event) {
        return $event.exec();
      }
    },
    scopedSlots: _vm._u([_vm.isEditTitle ? {
      key: "title",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mt-1 mb-n1",
          attrs: {
            "label": _vm.$t('project.project.title'),
            "outlined": "",
            "loading": _vm.isUpdateTitle,
            "readonly": _vm.isUpdateTitle
          },
          model: {
            value: _vm.projectTitle,
            callback: function callback($$v) {
              _vm.projectTitle = $$v;
            },
            expression: "projectTitle"
          }
        }), _c('div', {
          staticClass: "mt-n6"
        }, [_c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info"
          },
          on: {
            "click": _vm.updateProjectTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info",
            "outlined": ""
          },
          on: {
            "click": _vm.cancelUpdateTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }), !_vm.loadingUpdate ? _c('v-divider') : _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('m-action-list', {
          attrs: {
            "divider": false,
            "actions": _vm.detailActions
          },
          on: {
            "actionClicked": _vm.processAction
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-subtitle', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('small', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("createdOn", {
    date: _vm.createdDate
  })) + " ")]), _c('v-spacer'), !_vm.isEditDescription ? _c('v-btn', {
    staticClass: "mb-n2",
    attrs: {
      "x-small": "",
      "elevation": 0,
      "text": ""
    },
    on: {
      "click": _vm.startUpdateSubtitle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]) : _vm._e()], 1), _c('v-card-text', {
    staticClass: "my-n4"
  }, [!_vm.isEditDescription ? _c('v-textarea', {
    staticStyle: {
      "height": "180px"
    },
    attrs: {
      "readonly": true,
      "outlined": true
    },
    model: {
      value: _vm.project.description,
      callback: function callback($$v) {
        _vm.$set(_vm.project, "description", $$v);
      },
      expression: "project.description"
    }
  }) : _c('v-textarea', {
    attrs: {
      "label": _vm.$t('project.project.description'),
      "required": "",
      "rules": _vm.requiredRule,
      "outlined": ""
    },
    model: {
      value: _vm.projectDescription,
      callback: function callback($$v) {
        _vm.projectDescription = $$v;
      },
      expression: "projectDescription"
    }
  })], 1), _vm.isEditDescription ? _c('v-card-subtitle', {
    staticClass: "mt-n10"
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "elevation": "0",
      "color": "info",
      "disabled": !_vm.projectDescription,
      "loading": _vm.isUpdateDescription
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('save'))
    },
    on: {
      "click": _vm.updateProjectDescription
    }
  }), _c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "info",
      "loading": _vm.isUpdateDescription
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('discard'))
    },
    on: {
      "click": _vm.cancelUpdateDescription
    }
  })], 1) : _vm._e()], 1), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [!_vm.isEditCustomFields ? _c('div', [_c('v-card-subtitle', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.CustomFieldList.customFields")) + " "), _c('v-spacer'), _c('v-btn', {
    staticClass: "mb-n2",
    attrs: {
      "x-small": "",
      "elevation": 0,
      "text": ""
    },
    on: {
      "click": _vm.startUpdateCustomFields
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")])], 1), _vm.project.configuration.customFieldConfig.length ? _c('custom-field-configuration-form', {
    attrs: {
      "valid": _vm.isCustomFieldValid,
      "disabled": true
    },
    on: {
      "update:valid": function updateValid($event) {
        _vm.isCustomFieldValid = $event;
      }
    },
    model: {
      value: _vm.project.configuration.customFieldConfig,
      callback: function callback($$v) {
        _vm.$set(_vm.project.configuration, "customFieldConfig", $$v);
      },
      expression: "project.configuration.customFieldConfig"
    }
  }) : _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("project.project.selectCustomFieldProjectExplanationI")) + " "), _c('a', {
    on: {
      "click": _vm.goToCustomFields
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('cost.selectCustomFieldProjectExplanationII'))
    }
  })]), _vm._v(" " + _vm._s(_vm.$t("project.project.selectCustomFieldProjectExplanationIII")) + " ")])], 1) : _c('div', [_c('v-card-subtitle', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.CustomFieldList.customFields")) + " ")]), _c('custom-field-configuration-form', {
    attrs: {
      "valid": _vm.isCustomFieldValid,
      "disabled": false
    },
    on: {
      "update:valid": function updateValid($event) {
        _vm.isCustomFieldValid = $event;
      }
    },
    model: {
      value: _vm.projectCustomFieldConfig,
      callback: function callback($$v) {
        _vm.projectCustomFieldConfig = $$v;
      },
      expression: "projectCustomFieldConfig"
    }
  }), _c('v-card-subtitle', {
    staticClass: "mt-n10"
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "elevation": "0",
      "color": "info",
      "disabled": !_vm.isCustomFieldValid,
      "loading": _vm.isUpdateCustomFieldConfig
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('save'))
    },
    on: {
      "click": _vm.updateCustomFields
    }
  }), _c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "info",
      "loading": _vm.isUpdateCustomFieldConfig
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('discard'))
    },
    on: {
      "click": _vm.cancelUpdateCustomFields
    }
  })], 1)], 1)])], 1)], 1) : _c('div', [_c('latest-entries-card-empty'), _c('v-card-title', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s('View Projects')
    },
    on: {
      "click": function click($event) {
        return _vm.goTo.projectTable({
          partnerId: _vm.partnerId
        });
      }
    }
  }), _c('v-spacer')], 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "rightText": _vm.$t('project.project.delete'),
      "title": _vm.$t('project.project.deleteTitle'),
      "isDialogActive": _vm.isDeleteDialog
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialog = false;
      },
      "rightClick": _vm.onDeleteProject
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }