



































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PartnerEntity } from "@/models/partnerEntity";

@Component({})
export default class AnalyticsPartnerMultiselect extends Vue {
  @Prop()
  items!: PartnerEntity[];
  @Prop()
  preselectItems!: boolean;
  @Prop({ default: false })
  loading!: boolean;

  readonly SET_PARTNERS = "setPartners";
  selectAll = false;
  selectedPartnerIds: string[] = [];

  get loadingText() {
    return String(this.$t("components.analytics.AnalyticsPartnerMultiselect.loading"));
  }

  get selectAllText() {
    return String(this.$t("components.analytics.AnalyticsPartnerMultiselect.selectAll"));
  }

  get selectPartnersText() {
    return String(this.$t("components.analytics.AnalyticsPartnerMultiselect.selectPartners"));
  }

  @Watch("items")
  preselectAllPartnersAtFirst() {
    if (this.preselectItems) {
      this.selectAllPartners();
    }
  }

  @Watch("selectedPartnerIds")
  setPartners() {
    this.$emit(this.SET_PARTNERS, this.selectedPartnerIds);
  }

  selectAllPartners() {
    if (this.selectedPartnerIds.length === this.items.length) {
      this.selectedPartnerIds = [];
      this.selectAll = false;
    } else {
      this.selectedPartnerIds = this.items.map(item => item._id);
      this.selectAll = true;
    }
  }
}
