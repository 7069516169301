
























import { Component, Prop, Vue } from "vue-property-decorator";
import { IVehicle } from "@/models/vehicle.entity";

import PieChartCard from "../analytics/PieChartCard.vue";
import { EChartsOption } from "echarts/types/dist/shared";
import { ConfigModule } from "@/store/modules/config";
import VChart from "vue-echarts";
import CardIcon from "../utility/CardIcon.vue";
import ContextMenu from "../utility/ContextMenu.vue";

interface ICheckbox {
  label: string;
  value: boolean;
}
@Component({
  components: { PieChartCard, VChart, CardIcon, ContextMenu }
})
export default class FleetHomeVehicleDistribution extends Vue {
  @Prop()
  vehicles!: IVehicle[];

  transmissionCheckbox = { label: "transmission", value: true };
  classCheckbox = { label: "class", value: true };
  engineCheckbox = { label: "engine", value: true };
  undefinedCheckbox = { label: "hideUndefined", value: true };
  checkboxes = [this.transmissionCheckbox, this.classCheckbox, this.engineCheckbox, this.undefinedCheckbox];

  createSliceKey(oldKey: string, label: string, newKey?: string) {
    let keySuffix = "";

    if (newKey) {
      keySuffix = `${this.i18n[label]}: ${newKey}`;
    } else {
      keySuffix = `${this.i18n[label]}: -`;
    }

    if (oldKey && keySuffix) {
      return `${oldKey}\n${keySuffix}`;
    } else if (oldKey) {
      return oldKey;
    } else if (keySuffix) {
      return keySuffix;
    } else {
      return "";
    }
  }

  bakePieSlice(
    checkbox: ICheckbox,
    bakedSlices: Map<string, IVehicle[]>,
    keyFromItem: (item: IVehicle) => string | undefined
  ) {
    if (checkbox.value) {
      const unbakedSlices = new Map<string, IVehicle[]>();

      for (const key of bakedSlices.keys()) {
        bakedSlices.get(key)?.forEach(item => {
          const itemKey = keyFromItem(item);

          if (itemKey || !this.undefinedCheckbox.value) {
            const newKey = this.createSliceKey(key, checkbox.label, itemKey);

            const foundSlices = unbakedSlices.get(newKey);

            unbakedSlices.set(newKey, [...(foundSlices || []), item]);
          }
        });
      }

      return unbakedSlices;
    }

    return bakedSlices;
  }

  get pieSlices() {
    let bakedSlices = new Map<string, IVehicle[]>([["", this.vehicles]]);

    bakedSlices = this.bakePieSlice(this.transmissionCheckbox, bakedSlices, (item: IVehicle) =>
      item.registration?.transmission?.toLowerCase()
    );
    bakedSlices = this.bakePieSlice(this.classCheckbox, bakedSlices, (item: IVehicle) =>
      item.registration?.vehicleClass?.toLowerCase()
    );
    bakedSlices = this.bakePieSlice(this.engineCheckbox, bakedSlices, (item: IVehicle) =>
      item.registration?.driveTyp?.toLowerCase()
    );

    const pie = [];
    for (const key of bakedSlices.keys()) {
      pie.push({ name: key, value: (bakedSlices.get(key) || []).length });
    }

    if (pie[0]?.name === "") {
      pie[0].name = "all";
    }

    return pie;
  }

  get i18n() {
    return this.$t("components.fleet.FleetHomeVehicleDistribution");
  }

  get chartOption(): EChartsOption {
    return {
      color: ConfigModule.color.analyticsBrandColors,
      tooltip: {
        trigger: "item",
        formatter: "{b} <br> {c} ({d}%)",
        position: "bottom"
      },
      series: [
        {
          name: " ",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2
          },
          data: this.pieSlices
        }
      ]
    };
  }
}
