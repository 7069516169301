






















import WebhookConfigTable from "@/components/webhook/WebhookConfigTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { WebhookGoToHelper } from "@/lib/utility/webhook.go-to-helper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { WebhookConfigModule } from "@/store/modules/webhook-config.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import { IWebhookConfig, WebhookConfig } from "@/models/webhook-config.entity";
import WebhookConfigCreateDialog from "@/components/webhook/WebhookConfigCreateDialog.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";

@Component({
  components: {
    WebhookConfigTable,
    WebhookConfigCreateDialog,
    TheLayoutPortal,
    MHeader,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class WebhookConfigTableView extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Ref("webhookConfigTable")
  webhookConfigTable!: WebhookConfigTable;

  @Ref("webhookConfigCreateDialog")
  webhookConfigCreateDialog!: WebhookConfigCreateDialog;

  readonly AMOUNT_WEBHOOK_CONFIG = "amountWebhookConfig";

  get breadCrumbs() {
    const locations = WebhookGoToHelper.locations;

    return {
      WebhookConfigTable: (partnerId: string) => {
        return {
          text: $t("webhook.webhookConfigTable").toString(),
          exact: true,
          to: locations.webhookConfigTable({ partnerId })
        };
      },
      CostDetail: (partnerId: string, webhookConfigId: string) => {
        return {
          text: $t("webhook.detail").toString(),
          exact: true,
          to: locations.webhookConfigDetail({ partnerId, webhookConfigId })
        };
      }
    };
  }

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(this.breadCrumbs.WebhookConfigTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    return [
      {
        text: `${WebhookConfigModule.paginationList.length} ${this.$t("webhook.webhookConfigTable")}`,
        key: this.AMOUNT_WEBHOOK_CONFIG,
        disabled: true
      }
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (this.can(this.ActionEnum.CREATE, this.ResourceEnum.WEBHOOK_CONFIG)) {
      actions.push({
        key: "create",
        text: $t("create"),
        exec: () => this.webhookConfigCreateDialog.show()
      });
    }

    return actions;
  }

  async mounted() {
    this.trySetByRouteOrDefault();

    const webhookConfigId = this.$route.query.webhookConfigId as string;
    if (webhookConfigId) {
      const webhookConfig = new WebhookConfig({ partnerId: this.partnerId, id: webhookConfigId });
      this.webhookConfigTable?.setSelectedItem(webhookConfig);
    }
  }

  openSideCard(webhookConfig: IWebhookConfig) {
    new WebhookGoToHelper(this.$router).setUrl(
      WebhookGoToHelper.locations.webhookConfigTable({
        partnerId: this.partnerId,
        query: webhookConfig ? { webhookConfigId: webhookConfig.id } : {}
      })
    );
  }
}
