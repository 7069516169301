var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', {
    attrs: {
      "logo": _vm.logo
    }
  }, [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "fore-ground",
    attrs: {
      "md": "12",
      "lg": "10",
      "xl": "8"
    }
  }, [_vm.loading ? _c('v-card', {
    staticClass: "mx-4  mt-4",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "ma-4",
    attrs: {
      "type": "title, paragraph, paragraph"
    }
  })], 1) : _vm.expired ? _c('shared-content-access-expired', {
    attrs: {
      "partner": _vm.portalPartner
    }
  }) : _vm.sharedContent ? _c('shared-content-access-description', {
    attrs: {
      "partner": _vm.partner,
      "user": _vm.user,
      "sharedContent": _vm.sharedContent
    }
  }) : _vm._e(), _vm.sharedContent ? _c('v-timeline', {
    staticClass: "mx-4 mb-8",
    attrs: {
      "dense": "",
      "clipped": ""
    }
  }, [_vm._l(_vm.sharedContent.shared, function (access, index) {
    return _c('div', {
      key: "".concat(access.refId, "-").concat(access.refType)
    }, [access.refType === _vm.ResourceEnum.REPORT ? _c('report-base-time-line', {
      attrs: {
        "report": access.document
      }
    }) : access.refType === _vm.ResourceEnum.DOCUMENT ? _c('activity-time-line-item-document-card', {
      attrs: {
        "isLoading": false,
        "document": access.document
      }
    }) : _vm._e(), index !== _vm.sharedContent.shared.length - 1 ? _c('v-divider', {
      staticClass: "my-8"
    }) : _vm._e()], 1);
  }), _c('v-timeline-item', {
    staticClass: "no-print",
    attrs: {
      "hide-dot": ""
    }
  }), _c('tooltip', {
    staticClass: "no-print",
    attrs: {
      "text": _vm.$t('views.PartnerSharedReport.printHint')
    }
  }, [_c('v-btn', {
    staticClass: "no-print",
    attrs: {
      "elevation": "0",
      "color": "info"
    },
    on: {
      "click": _vm.print
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.print")) + " "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v("mdi-printer-outline")])], 1)], 1)], 2) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }