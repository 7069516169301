var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "bottom": "",
      "offset-y": "",
      "offset-x": _vm.$vuetify.breakpoint.smAndDown,
      "max-width": _vm.maxWidth
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-row', {
          staticClass: "pa-3",
          staticStyle: {
            "flex-wrap": "nowrap"
          }
        }, [_c('v-btn', {
          staticClass: "main-btn flex-grow-1",
          attrs: {
            "color": "primary",
            "dark": "",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              return _vm.actionClicked(_vm.selected);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.selected.text) + " ")]), _c('v-btn', _vm._g({
          staticClass: "actions-btn flex-grow-0 flex-shrink-0",
          attrs: {
            "color": "primary",
            "dark": "",
            "elevation": "0"
          }
        }, on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-menu-down")])], 1)], 1)];
      }
    }])
  }, [_c('v-list', _vm._l(_vm.actions, function (item, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "three-line": ""
      },
      on: {
        "click": function click($event) {
          _vm.selected = item;
        }
      }
    }, [_c('v-list-item-icon', [item.icon ? _c('v-icon', {
      attrs: {
        "color": item.color
      }
    }, [_vm._v(" " + _vm._s(item.icon) + " ")]) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.text))]), item.description ? _c('v-list-item-subtitle', [_vm._v(_vm._s(item.description))]) : _vm._e()], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }