var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "mr-2"
  }, [_vm.ticket.due && _vm.due(_vm.ticket.due) < _vm.eod && _vm.due(_vm.ticket.due) > _vm.bod ? _c(_vm.isChip, {
    tag: "span",
    class: 'orange--text',
    style: _vm.isOverdue(_vm.ticket) ? 'text-color:blue;' : '',
    attrs: {
      "outlined": "",
      "x-small": "",
      "color": "info"
    }
  }, [_vm.chip ? _c('v-icon', {
    attrs: {
      "x-small": "",
      "left": ""
    }
  }, [_vm._v("mdi-calendar")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.today")) + " ")], 1) : _vm.totomorrow && _vm.ticket.due && _vm.due(_vm.ticket.due) < _vm.totomorrow && _vm.due(_vm.ticket.due) > new Date() ? _c(_vm.isChip, {
    tag: "span",
    attrs: {
      "outlined": "",
      "x-small": ""
    }
  }, [_vm.chip ? _c('v-icon', {
    attrs: {
      "x-small": "",
      "left": ""
    }
  }, [_vm._v("mdi-calendar")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.tomorrow")) + " ")], 1) : _vm.ticket.due && _vm.due(_vm.ticket.due) > _vm.yesterday && _vm.due(_vm.ticket.due) < new Date() ? _c(_vm.isChip, {
    tag: "span",
    class: _vm.isOverdue(_vm.ticket) ? 'red--text' : '',
    attrs: {
      "outlined": "",
      "x-small": "",
      "color": _vm.isOverdue(_vm.ticket) ? 'error' : 'default'
    }
  }, [_vm.chip ? _c('v-icon', {
    attrs: {
      "x-small": "",
      "left": ""
    }
  }, [_vm._v("mdi-calendar")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("components.analytics.AnalyticsDateRangeSelectorDialog.predefinedRanges.yesterday")) + " ")], 1) : _vm.ticket.due ? _c(_vm.isChip, {
    tag: "span",
    class: _vm.isOverdue(_vm.ticket) ? 'red--text' : '',
    attrs: {
      "outlined": "",
      "x-small": "",
      "color": _vm.isOverdue(_vm.ticket) ? 'error' : 'default'
    }
  }, [_vm.chip ? _c('v-icon', {
    attrs: {
      "x-small": "",
      "left": ""
    }
  }, [_vm._v("mdi-calendar")]) : _vm._e(), _vm._v(_vm._s(_vm._f("simpleDoubleDigitDate")(_vm.ticket.due)) + " ")], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }