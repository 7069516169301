
















import { detailedDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ISnapshot } from "@/models/snapshot.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import SideCard from "../utility/SideCard.vue";
import SnapshotDetail from "./SnapshotDetail.vue";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    SideCard,
    SnapshotDetail,
    Debug
  },
  filters: { detailedDate }
})
export default class SnapshotSideCard extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: ISnapshot;

  isLoading = false;

  async mounted() {
    this.isLoading = true;
    await this.value.fetch().catch(handleError);
    this.isLoading = false;
  }
}
