import { ContractTypeEnum } from "@/lib/enum/contractType.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CreateDto } from "@/lib/utility/data/create-dto.interface";
import { MrfiktivCreateLeasingContractDtoGen, MrfiktivLeasingContractGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CurrencyUnitEnum } from "@/store/enum/thg/currency-unit.enum";
import { VehicleModule } from "@/store/modules/vehicle.store";

@IsFormable
class CreateContract extends CreateDto<MrfiktivLeasingContractGen> implements ICreateContractDto {
  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.title"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: true,
      label: "objects.contract.title",
      rules: [requiredRule()]
    }
  })
  title: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.description"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.contract.description"
    }
  })
  description?: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.number"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.contract.number"
    }
  })
  number?: string | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.contractType"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      required: true,
      label: "objects.contract.contractType",
      rules: [requiredRule()],
      items: Object.values(ContractTypeEnum)
    }
  })
  contractType: ContractTypeEnum;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.startDate"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "date",
      label: "objects.contract.startDate"
    }
  })
  startDate?: string | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.endDate"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "date",
      label: "objects.contract.endDate"
    }
  })
  endDate?: string | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.currency"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      label: "objects.contract.currency",
      items: Object.values(CurrencyUnitEnum)
    }
  })
  currency?: CurrencyUnitEnum | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.startMilage"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.startMilage"
    }
  })
  startMilage?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.maxMilage"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.maxMilage"
    }
  })
  maxMilage?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.maxReimbursedMilage"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.maxReimbursedMilage"
    }
  })
  maxReimbursedMilage?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.creditForReimbursedMilage"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.creditForReimbursedMilage"
    }
  })
  creditForReimbursedMilage?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.freeMileageAllowance"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.freeMileageAllowance"
    }
  })
  freeMileageAllowance?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.excessMileageCosts"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.excessMileageCosts"
    }
  })
  excessMileageCosts?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.purchasePrice"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.purchasePrice"
    }
  })
  purchasePrice?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.buybackPrice"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.buybackPrice"
    }
  })
  buybackPrice?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.financingCost"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.financingCost"
    }
  })
  financingCost?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.fuelCost"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.fuelCost"
    }
  })
  fuelCost?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.insuranceCost"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.insuranceCost"
    }
  })
  insuranceCost?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.miscCost"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.miscCost"
    }
  })
  miscCost?: number | undefined;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.contract.tiresCost"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      type: "number",
      label: "objects.contract.tiresCost"
    }
  })
  tiresCost?: number | undefined;

  partnerId: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "common.nouns.vehicle"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      required: true,
      label: "common.nouns.vehicle"
    }
  })
  vehicleId: string;

  vehicleDisplayName: string;

  constructor(
    contract?: Partial<
      | ICreateContractDto
      | (MrfiktivLeasingContractGen & {
          partnerId: string;
          vehicleId: string;
          vehicleDisplayName: string;
        })
    >
  ) {
    super();
    this.number = contract?.number;
    this.title = contract?.title ?? "";
    this.contractType = (contract?.contractType as ContractTypeEnum) ?? ContractTypeEnum.LEASING;
    this.description = contract?.description;
    this.buybackPrice = contract?.buybackPrice;
    this.creditForReimbursedMilage = contract?.creditForReimbursedMilage;
    this.currency = contract?.currency as CurrencyUnitEnum;
    this.endDate = contract?.endDate;
    this.excessMileageCosts = contract?.excessMileageCosts;
    this.financingCost = contract?.financingCost;
    this.freeMileageAllowance = contract?.freeMileageAllowance;
    this.fuelCost = contract?.fuelCost;
    this.insuranceCost = contract?.insuranceCost;
    this.maxMilage = contract?.maxMilage;
    this.maxReimbursedMilage = contract?.maxReimbursedMilage;
    this.miscCost = contract?.miscCost;
    this.purchasePrice = contract?.purchasePrice;
    this.startMilage = contract?.startMilage;
    this.startDate = contract?.startDate;
    this.tiresCost = contract?.tiresCost;
    this.partnerId = contract?.partnerId ?? "";
    this.vehicleId = contract?.vehicleId ?? "";
    this.vehicleDisplayName = contract?.vehicleDisplayName ?? "";
  }

  async create(): Promise<MrfiktivLeasingContractGen> {
    const dto = {
      title: this.title,
      description: this.description,
      contractType: this.contractType,
      number: this.number,
      startDate: this.startDate,
      endDate: this.endDate,
      currency: this.currency,
      startMilage: this.startMilage,
      maxMilage: this.maxMilage,
      maxReimbursedMilage: this.maxReimbursedMilage,
      creditForReimbursedMilage: this.creditForReimbursedMilage,
      freeMileageAllowance: this.freeMileageAllowance,
      excessMileageCosts: this.excessMileageCosts,
      purchasePrice: this.purchasePrice,
      buybackPrice: this.buybackPrice,
      financingCost: this.financingCost,
      fuelCost: this.fuelCost,
      insuranceCost: this.insuranceCost,
      miscCost: this.miscCost,
      tiresCost: this.tiresCost
    };

    const vehicle = await VehicleModule.createContract({
      partnerId: this.partnerId,
      vehicleId: this.vehicleId,
      data: dto
    });

    if (!vehicle.contracts) {
      throw new Error("No contract created");
    }

    return vehicle.contracts[vehicle.contracts.length - 1];
  }
}

type ICreateContractDto = MrfiktivCreateLeasingContractDtoGen & {
  partnerId: string;
  vehicleId: string;
  vehicleDisplayName: string;
};
const CreateContractDto = Form.createForClass(CreateContract);

export { ICreateContractDto, CreateContractDto };
