/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateDriverDtoGen,
  MrfiktivDriverControllerGetAllParamsGen,
  MrfiktivDriverViewModelGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateDriverDtoGen,
  MrfiktivUpdateDriverProofDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Driver<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags driver
   * @name DriverControllerCreate
   * @summary (DRIVER-CREATE) Create a driver
   * @request POST:/partner/{partnerId}/driver
   * @secure
   * @response `201` `MrfiktivDriverViewModelGen` Id of created operation
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  driverControllerCreate = (partnerId: string, data: MrfiktivCreateDriverDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivDriverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/driver`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags driver
   * @name DriverControllerGetAll
   * @summary (DRIVER-READ) Get all drivers
   * @request GET:/partner/{partnerId}/driver
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivDriverViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  driverControllerGetAll = (
    { partnerId, ...query }: MrfiktivDriverControllerGetAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivDriverViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/driver`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags driver
   * @name DriverControllerGetOne
   * @summary (DRIVER-READ) Get a driver by id
   * @request GET:/partner/{partnerId}/driver/{driverId}
   * @secure
   * @response `200` `MrfiktivDriverViewModelGen` Id of created operation
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  driverControllerGetOne = (partnerId: string, driverId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivDriverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/driver/${driverId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags driver
   * @name DriverControllerUpdate
   * @summary (DRIVER-UPDATE) Update a driver
   * @request PUT:/partner/{partnerId}/driver/{driverId}
   * @secure
   * @response `200` `MrfiktivDriverViewModelGen` the updated driver
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  driverControllerUpdate = (
    partnerId: string,
    driverId: string,
    data: MrfiktivUpdateDriverDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivDriverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/driver/${driverId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags driver
   * @name DriverControllerDelete
   * @summary (DRIVER-DELETE) Delete a driver
   * @request DELETE:/partner/{partnerId}/driver/{driverId}
   * @response `200` `MrfiktivDriverViewModelGen` the deleted driver
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  driverControllerDelete = (partnerId: string, driverId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivDriverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/driver/${driverId}`,
      method: "DELETE",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags driver
   * @name DriverControllerUpdateProof
   * @summary (DRIVER-UPDATE) Update a drivers license plate
   * @request PUT:/partner/{partnerId}/driver/{driverId}/proof
   * @secure
   * @response `200` `MrfiktivDriverViewModelGen` the updated driver
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  driverControllerUpdateProof = (
    partnerId: string,
    driverId: string,
    data: MrfiktivUpdateDriverProofDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivDriverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/driver/${driverId}/proof`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
}
