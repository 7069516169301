var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    ref: "titleTextField",
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.inspection.title')
    },
    model: {
      value: _vm.value.title,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "title", $$v);
      },
      expression: "value.title"
    }
  }), _c('template-editor', {
    ref: "templateEditor",
    staticStyle: {
      "height": "250px"
    },
    attrs: {
      "readOnly": false,
      "label": _vm.$t('objects.inspection.description')
    },
    model: {
      value: _vm.value.description,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "description", $$v);
      },
      expression: "value.description"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('custom-field-date-time', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.inspection.start')
      }
    },
    model: {
      value: _vm.value.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "startDate", $$v);
      },
      expression: "value.startDate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('custom-field-date-time', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.inspection.end')
      }
    },
    model: {
      value: _vm.value.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "endDate", $$v);
      },
      expression: "value.endDate"
    }
  })], 1)], 1), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("objects.inspection.address")))]), _c('div', {
    staticClass: "subtitle mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("objects.inspection.addressHint")) + " ")]), _c('address-form', {
    attrs: {
      "subheader": _vm.$t('objects.inspection.address')
    },
    on: {
      "isValid": function isValid($e) {
        return _vm.isAddressValid = $e;
      }
    },
    model: {
      value: _vm.value.address,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "address", $$v);
      },
      expression: "value.address"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }