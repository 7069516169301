


























import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { ISnapshot } from "@/models/snapshot.entity";
import { HandoverSnapshotModule } from "@/store/modules/handover-snapshot.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import { SIGN_COLOR_BY_STATUS } from "@/views/sign/SignRequestTable.vue";
import AssigneePreview from "../utility/AssigneePreview.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";

@Component({
  components: { TableWrapper, AssigneePreview },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class SnapshotTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  handover!: IHandover;

  get snapshots(): ISnapshot[] {
    return HandoverSnapshotModule.filteredAndSorted || [];
  }

  search = "";

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push(
      { text: this.$t("sign.SignRequestTable.status").toString(), align: "start", value: "status", width: 50 },
      {
        text: this.$t("project.ticket.number").toString(),
        value: "number",
        align: "start",
        width: 100
      },
      {
        text: $t("snapshot.userId"),
        value: "userId",
        align: "start",
        width: "200px"
      },
      { text: this.$t("sign.SignRequestTable.created").toString(), align: "start", value: "timestamp.created" },
      { text: this.$t("sign.SignRequestTable.modified").toString(), align: "start", value: "timestamp.lastModified" }
    );

    return headers;
  }

  get colorByStatus() {
    return SIGN_COLOR_BY_STATUS;
  }
}
