/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * The keys of the settings for the ksr setup
 */
export enum KsrSettingsKeys {
  /**
   * The key of the partner. Required for authentication
   *
   * @example xxxxxxx-xxxxxxxxx-xxxx-xxxxx
   */
  "PARTNER_KEY" = "KSR_PARTNER_KEY",

  /**
   * The partner key
   *
   * @example xxxxxx-xxxxx-xxxxxx-xxxxxx
   */
  "USERNAME" = "KSR_USERNAME",

  /**
   * The password of the user
   *
   * @example passwort123
   */
  "PASSWORD" = "KSR_PASSWORD",

  /**
   * The customer osa id for which the partner is requesting access.
   *
   * @example OsaId: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxx
   */
  "CUSTOMER_OSA_ID" = "KSR_CUSTOMER_OSA_ID",

  /**
   * The id of the company for which reports are managed in the ksr system
   */
  "COMPANY_ID" = "KSR_COMPANY_ID"
}
