var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-base', {
    attrs: {
      "displayFooter": false
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('PartnerCreateCard')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }