




import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import StatisticsCardRow, { IStatisticRowElement } from "../utility/StatisticsCardRow.vue";

@Component({
  components: { StatisticsCardRow }
})
export default class FleetHomeStatisticsList extends Vue {
  @Prop()
  loading!: boolean;

  get activeVehicles() {
    return (Array.from(FleetAggregationModule.vehicleAggregationMap.values()) ?? []).filter(
      v => v.vehicle.state === VehicleStateEnum.ACTIVE
    ).length;
  }

  get endOfNextMonth() {
    return new Date(new Date().getFullYear(), new Date().getMonth() + 2);
  }

  get startOfPreviousMonth() {
    return new Date(new Date().getFullYear(), new Date().getMonth() - 1);
  }

  get fleetEvents() {
    return FleetAggregationModule.getEvents({ year: new Date().getFullYear(), month: new Date().getMonth() });
  }

  get hus() {
    return (this.fleetEvents.mainInspection ?? []).filter(
      d => d.start <= this.endOfNextMonth && d.start > this.startOfPreviousMonth
    ).length;
  }

  get upcomingContractEnds() {
    return (this.fleetEvents.leasingEnd ?? []).filter(
      d => d.start <= this.endOfNextMonth && d.start > this.startOfPreviousMonth
    ).length;
  }

  get upcomingContractStarts() {
    return (this.fleetEvents.leasingStart ?? []).filter(
      d => d.start <= this.endOfNextMonth && d.start > this.startOfPreviousMonth
    ).length;
  }

  get rowData(): IStatisticRowElement[] {
    return [
      {
        icon: "mdi-car-convertible",
        title: "components.fleet.FleetHomeStatisticsList.vehicles",
        color: "primary",
        data: this.activeVehicles.toString(),
        tooltip: "components.fleet.FleetHomeStatisticsList.active"
      },
      {
        icon: "mdi-car-wrench",
        title: "components.fleet.FleetHomeStatisticsList.inspections",
        color: "primary",
        data: this.hus.toString(),
        tooltip: "components.fleet.FleetHomeStatisticsList.next30DaysOrPassed"
      },
      {
        icon: "mdi-calendar-alert",
        title: "components.fleet.FleetHomeStatisticsList.upcomingContractEnds",
        color: "primary",
        data: this.upcomingContractEnds.toString(),
        tooltip: "components.fleet.FleetHomeStatisticsList.next30DaysOrPassed"
      },
      {
        icon: "mdi-calendar-plus",
        title: "components.fleet.FleetHomeStatisticsList.upcomingContractStarts",
        color: "primary",
        data: this.upcomingContractStarts.toString(),
        tooltip: "components.fleet.FleetHomeStatisticsList.next30DaysOrPassed"
      }
    ];
  }
}
