




import { Component, Prop, Vue } from "vue-property-decorator";
import StatisticsCardRow, { IStatisticRowElement } from "../utility/StatisticsCardRow.vue";
import { UserModule } from "@/store/modules/me-user.store";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: { StatisticsCardRow }
})
export default class DashboardStatisticsList extends Vue {
  @Prop()
  reports!: number[];

  @Prop()
  forms!: number[];

  @Prop()
  loading!: boolean;

  get i18n() {
    return this.$t("components.dashboard.DashboardStatisticsList") || {};
  }

  get rowData(): IStatisticRowElement[] {
    const row: IStatisticRowElement[] = [];

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, PartnerModule.partner.id)) {
      row.push({
        icon: "mdi-car-wrench",
        title: this.i18n["reports"],
        color: "primary",
        data: this.reports.toString()
      });
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.FORM, PartnerModule.partner.id)) {
      row.push({
        icon: "mdi-car",
        title: this.i18n["forms"],
        color: "primary",
        data: this.forms.toString()
      });
    }

    return row;
  }
}
