var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.UPDATE,
      "a": _vm.ResourceEnum.VEHICLE
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', [_vm.isDisabled ? _c('v-btn', {
    staticClass: " mr-5",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.isDisabled = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.edit")) + " ")]) : _vm._e(), !_vm.isDisabled ? _c('v-btn', {
    staticClass: " mr-5",
    attrs: {
      "color": "primary",
      "outlined": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.abort
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("abort")) + " ")]) : _vm._e(), !_vm.isDisabled ? _c('v-btn', {
    staticClass: " mr-5",
    attrs: {
      "color": "success",
      "elevation": "0"
    },
    on: {
      "click": _vm.emitVehicle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-form', {
    staticClass: "mb-10",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-select', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.i18n['state'],
      "loading": _vm.loading,
      "items": _vm.vehicleStates,
      "outlined": "",
      "disabled": _vm.isDisabled
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state." + item)) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state." + item)) + " ")];
      }
    }]),
    model: {
      value: _vm.currentVehicle.state,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle, "state", $$v);
      },
      expression: "currentVehicle.state"
    }
  }), _c('v-text-field', {
    staticClass: " mt-4",
    attrs: {
      "label": _vm.i18n['displayName'],
      "outlined": "",
      "required": "",
      "rules": _vm.requiredRule,
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.currentVehicle.displayName,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle, "displayName", $$v);
      },
      expression: "currentVehicle.displayName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.i18n['numberplate'],
      "outlined": "",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.currentVehicle.numberplate,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle, "numberplate", $$v);
      },
      expression: "currentVehicle.numberplate"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.i18n['identificationnumber'],
      "outlined": "",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.currentVehicle.identificationnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle, "identificationnumber", $$v);
      },
      expression: "currentVehicle.identificationnumber"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": _vm.i18n['vehicleClass'],
      "outlined": "",
      "disabled": _vm.isDisabled,
      "items": _vm.vehicleClasses,
      "item-text": "text",
      "item-value": "value"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.getVehicleClass(item)) + " ")];
      }
    }]),
    model: {
      value: _vm.currentVehicle.registration.vehicleClass,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle.registration, "vehicleClass", $$v);
      },
      expression: "currentVehicle.registration.vehicleClass"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": _vm.i18n['driveTyp'],
      "outlined": "",
      "disabled": _vm.isDisabled,
      "items": _vm.driveTypes,
      "item-text": "text",
      "item-value": "value",
      "hint": _vm.i18n['driveTypeHint'],
      "persistent-hint": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.getDriveType(item)) + " (" + _vm._s(item) + ") ")];
      }
    }]),
    model: {
      value: _vm.currentVehicle.registration.driveTyp,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle.registration, "driveTyp", $$v);
      },
      expression: "currentVehicle.registration.driveTyp"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.i18n['registrationDate'],
      "date": "",
      "type": "date",
      "outlined": "",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.registrationDate,
      callback: function callback($$v) {
        _vm.registrationDate = $$v;
      },
      expression: "registrationDate"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.i18n['manufacturerName'],
      "outlined": "",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.currentVehicle.registration.manufacturerName,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle.registration, "manufacturerName", $$v);
      },
      expression: "currentVehicle.registration.manufacturerName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.i18n['model'],
      "outlined": "",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.currentVehicle.registration.manufacturerType,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle.registration, "manufacturerType", $$v);
      },
      expression: "currentVehicle.registration.manufacturerType"
    }
  }), _c('v-select', {
    attrs: {
      "label": _vm.i18n['transmission'],
      "items": _vm.transmissions,
      "outlined": "",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.currentVehicle.registration.transmission,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle.registration, "transmission", $$v);
      },
      expression: "currentVehicle.registration.transmission"
    }
  }), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.i18n['notes'],
      "outlined": "",
      "disabled": _vm.isDisabled
    },
    model: {
      value: _vm.currentVehicle.note,
      callback: function callback($$v) {
        _vm.$set(_vm.currentVehicle, "note", $$v);
      },
      expression: "currentVehicle.note"
    }
  }), _c('combo-box', {
    attrs: {
      "label": _vm.i18n['tags'],
      "items": _vm.allTags,
      "prefill": _vm.currentVehicle.tags,
      "isRequired": false,
      "multiple": true,
      "disabled": _vm.isDisabled
    },
    on: {
      "changeSelection": function changeSelection(v) {
        return _vm.currentVehicle.tags = v;
      }
    }
  })], 1), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.DELETE,
      "a": _vm.ResourceEnum.VEHICLE
    }
  }, [_vm.isDisabled ? _c('delete-dialog', {
    attrs: {
      "loading": _vm.loading,
      "title": _vm.$t('components.fleet.FleetVehicleDetailContextMenu.confirmDeletionTitle'),
      "text": _vm.$t('components.fleet.FleetVehicleDetailContextMenu.reasonText')
    },
    on: {
      "delete": _vm.confirmDeletion
    }
  }) : _vm._e()], 1), !_vm.isDisabled ? _c('v-bottom-navigation', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0"
    }
  }, [_c('v-spacer'), _vm.$vuetify.breakpoint.smAndUp ? _c('v-card-title', {
    staticClass: "mr-4"
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t("components.profile.EditProfileDialog.saveQuestion")) + " ")])]) : _vm._e(), _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "loading": _vm.loading,
      "outlined": "",
      "large": "",
      "elevation": "0",
      "color": "warning"
    },
    on: {
      "click": _vm.abort
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("abort")) + " ")]), _c('v-btn', {
    staticClass: "mr-10 white--text",
    style: _vm.$vuetify.breakpoint.smAndUp ? 'min-width: 400px' : '',
    attrs: {
      "disabled": !_vm.isValid,
      "loading": _vm.loading,
      "x-large": "",
      "color": "success",
      "elevation": "0"
    },
    on: {
      "click": _vm.emitVehicle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }