











































import ActivityTimeLineItemDocumentCard from "@/components/partner/ActivityTimeLineItemDocumentCard.vue";
import ReportBaseTimeLine from "@/components/partner/ReportBaseTimeLine.vue";
import SharedContentAccessDescription from "@/components/partner/SharedContentAccessDescription.vue";
import SharedContentAccessExpired from "@/components/partner/SharedContentAccessExpired.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { SharedContentAccess, ISharedContentAccess } from "@/models/shared-content-access.entity";

@Component({
  components: {
    TheLayoutPortal,
    ReportBaseTimeLine,
    Tooltip,
    SharedContentAccessDescription,
    SharedContentAccessExpired,
    ActivityTimeLineItemDocumentCard
  }
})
export default class PartnerSharedContentAccessDetail extends Vue {
  loading = false;

  expired = false;

  sharedContent: ISharedContentAccess | null = null;

  get partnerId() {
    return this.$route.params.partnerId;
  }
  get sharedContentId() {
    return this.$route.params.sharedContentId;
  }
  get accessToken() {
    return this.$route.params.accessToken;
  }

  get portalPartner() {
    return PartnerModule.partner;
  }

  get partner() {
    return this.sharedContent?.partner;
  }

  get user() {
    return this.sharedContent?.user;
  }

  get logo() {
    return this.partner?.settings?.reportSettings?.logoUrl || "";
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get date() {
    const validBy = this.sharedContent?.validBy;
    if (validBy) {
      return simpleDate(validBy);
    } else {
      return "unbegrenzt";
    }
  }

  print() {
    window.print();
  }

  async mounted() {
    this.loading = true;
    this.expired = false;
    try {
      PartnerModule.getPartnerById(this.$route.params.partnerId);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim Laden des Partners");
    }

    try {
      this.loading = true;
      this.sharedContent = await new SharedContentAccess(
        this.partnerId,
        this.sharedContentId,
        this.accessToken
      ).fetch();
    } catch (e) {
      if ((e as any).message === "Sharing document expired") {
        this.expired = true;
      } else {
        handleError(e);
      }
    } finally {
      this.loading = false;
    }
  }
}
