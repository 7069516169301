


















































import { ContractTypeEnum } from "@/lib/enum/contractType.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { LeasingContract } from "@/models/leasingContract";
import { MrfiktivCreateLeasingContractDtoGen, MrfiktivLeasingContractGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import AEicon from "../utility/AEicon.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

enum STEP {
  INPUT_DATA,
  SUCCESS
}

@Component({
  components: {
    ConfirmActionDialog,
    AEicon,
    TemplateEditor
  },
  filters: { simpleDoubleDigitDate }
})
export default class ContractCreateDialog extends mixins(RulesMixin) {
  @Prop()
  vehicle!: IVehicle;

  @Prop()
  private isDialogActive!: boolean;

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailContractTableLeasingInputs") || {};
  }

  readonly ContractTypeEnum = ContractTypeEnum;

  readonly STEP = STEP;

  hideDetailFields = true;

  isValid = false;

  createContractType = ContractTypeEnum.LEASING;

  newContract: LeasingContract = {
    id: "",
    title: "",
    description: "",
    contractType: ContractTypeEnum.LEASING,
    number: "",
    startDate: undefined,
    endDate: undefined
  };

  isNewContractInputValid = false;

  isCreateContractLoading = false;

  step = STEP.INPUT_DATA;

  createdContract: MrfiktivLeasingContractGen | null = null;

  get contractTypes() {
    return Object.values(ContractTypeEnum);
  }

  get title() {
    switch (this.step) {
      case STEP.INPUT_DATA:
        return this.$t("components.fleet.FleetVehicleDetailContractTable.createContractDialogTitle");
      case STEP.SUCCESS:
        return this.$t("components.fleet.FleetVehicleDetailContractTable.successfullyCreated");
      default:
        return "";
    }
  }

  get leftText() {
    switch (this.step) {
      case STEP.INPUT_DATA:
        return this.$t("close");
      case STEP.SUCCESS:
        return this.$t("close");
      default:
        return "";
    }
  }

  get rightText() {
    switch (this.step) {
      case STEP.INPUT_DATA:
        return this.$t("components.fleet.FleetVehicleDetailContractTable.proceed");
      case STEP.SUCCESS:
        return this.$t("common.verbs.edit");
      default:
        return "";
    }
  }

  get isNextDisabled() {
    if (this.step === STEP.INPUT_DATA && !this.isNewContractInputValid) return true;

    if (this.step === STEP.SUCCESS && !this.createdContract) return true;

    return false;
  }

  get isDialogActiveNotProp() {
    return this.isDialogActive;
  }

  set isDialogActiveNotProp(value: boolean) {
    this.$emit("update:isDialogActive", value);
  }

  close() {
    this.isDialogActiveNotProp = false;
    this.step = STEP.INPUT_DATA;
  }

  async next() {
    switch (this.step) {
      case STEP.INPUT_DATA: {
        await this.createContract();
        break;
      }
      case STEP.SUCCESS: {
        if (!this.createdContract) return;
        new GoToHelper(this.$router).goToContractDetailDetail(
          this.vehicle.partnerId,
          this.vehicle.id,
          this.createdContract.id
        );

        this.step = STEP.INPUT_DATA;
        break;
      }
    }
  }

  async createContract() {
    try {
      if (!this.newContract) return;

      this.isCreateContractLoading = false;
      const mrfiktivCreateLeasingContractDtoGen: MrfiktivCreateLeasingContractDtoGen = {
        title: this.newContract.title,
        description: this.newContract.description,
        contractType: this.newContract.contractType,
        number: this.newContract.number
      };

      const updated = await VehicleModule.createContract({
        partnerId: PartnerModule.partner._id,
        vehicleId: this.vehicle.id,
        data: mrfiktivCreateLeasingContractDtoGen
      });

      if (!this.vehicle.contracts) {
        this.vehicle.contracts = [];
      }
      const contract = this.vehicle.contracts[this.vehicle.contracts?.length - 1];
      this.createdContract = contract;
      this.vehicle.contracts.splice(0, this.vehicle.contracts.length, ...(updated.contracts || []));
      this.step = STEP.SUCCESS;
      this.newContract.title = "";
      this.newContract.description = "";
    } catch (e) {
      handleError(e);
    } finally {
      this.isCreateContractLoading = false;
      this.$emit("refresh");
    }
  }
}
