















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { isNotEmptyArrayRule } from "@/lib/rules/isNotEmptyArray";
import { IResource } from "@/models/resource.entity";

@Component({
  components: {}
})
export default class ResourceList extends Vue {
  @Prop()
  isCreate!: boolean;
  @Prop()
  allResources!: IResource[];
  @Prop()
  selectedResources!: string[];

  selectedResourcesModel: string[] = [];

  @Watch("selectedResourcesModel", { deep: true })
  emitSelectedResources() {
    this.$emit("change", this.selectedResourcesModel);
  }

  mounted() {
    this.selectedResourcesModel = this.selectedResources;
  }

  get arrayNotEmptyRule() {
    return [isNotEmptyArrayRule()];
  }
}
