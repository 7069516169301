
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

/**
 * Mixins with stuff shared by the project and ticket table
 */
@Component({
  components: {},
  filters: {}
})
export default class ProjectTableMixin extends mixins(Vue) {
  loadingTickets = false;
  loadingPartnerUsers = false;
  loadingProjects = false;

  // ---- Side card state ----

  loadingSideCard = false;
  isSideCard = false;

  // ---- Route info ----

  partnerId = "";
  projectId = "";
  ticketNumber = "";

  // ---- Fín ----
}
