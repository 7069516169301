



























































































import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ITicket } from "@/models/ticket.entity";
import {
  AggregationTimeFrame,
  FleetAggregationModule,
  VehicleAggregation
} from "@/store/modules/fleet-aggregation.store";
import TicketCrudMixin from "@/views/project/mixins/TicketCrudMixin.vue";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import FleetHomeVehicleTableRow from "./FleetHomeVehicleTableRow.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import Debug from "../utility/Debug.vue";

export interface IFleetHomeVehicleTableMonths {
  calendarFocus: Date;
  calendarKey: string;
  prevMonthYear: () => AggregationTimeFrame;
  currentMonthYear: () => AggregationTimeFrame;
  nextMonthYear: () => AggregationTimeFrame;
  getCalendarTitle: () => string;
  prevMonth: () => void;
  nextMonth: () => void;
  getCurrMonthName: () => string;
  getPrevMonthName: () => string;
  getNextMonthName: () => string;
}

@Component({
  components: {
    FleetHomeVehicleTableRow,
    Debug
  }
})
export default class FleetHomeVehicleTable extends mixins(DarkModeHighlightMixin, TicketCrudMixin) {
  @Prop({ default: false })
  initialLoading?: boolean;

  @Prop({ default: false })
  loadingEvents?: boolean;

  readonly BackendResourceEnum = BackendResourceEnum;

  initialized = false;

  vehicleAggregations: VehicleAggregation[] = [];

  page = 0;

  reverse = false;

  sortBy = "";

  monthMethods: IFleetHomeVehicleTableMonths = {
    calendarFocus: new Date(),
    calendarKey: "",
    currentMonthYear: () => {
      return { month: this.monthMethods.calendarFocus.getMonth(), year: this.monthMethods.calendarFocus.getFullYear() };
    },
    prevMonthYear: () => {
      const prevMonth = new Date(
        this.monthMethods.calendarFocus.getFullYear(),
        this.monthMethods.calendarFocus.getMonth() - 1
      );
      return {
        month: prevMonth.getMonth(),
        year: prevMonth.getFullYear()
      };
    },
    nextMonthYear: () => {
      const nextMonth = new Date(
        this.monthMethods.calendarFocus.getFullYear(),
        this.monthMethods.calendarFocus.getMonth() + 1
      );
      return {
        month: nextMonth.getMonth(),
        year: nextMonth.getFullYear()
      };
    },
    getCalendarTitle: () => {
      return this.monthMethods.calendarFocus.toLocaleString("default", { month: "long", year: "numeric" });
    },
    prevMonth: async () => {
      this.monthMethods.calendarFocus.setMonth(this.monthMethods.calendarFocus.getMonth() - 1);
      this.monthMethods.calendarKey = this.monthMethods.calendarFocus.toISOString();
      this.updateEventList();
    },
    nextMonth: () => {
      this.monthMethods.calendarFocus.setMonth(this.monthMethods.calendarFocus.getMonth() + 1);
      this.monthMethods.calendarKey = this.monthMethods.calendarFocus.toISOString();
      this.updateEventList();
    },
    getCurrMonthName: () => {
      const month = this.monthMethods.calendarFocus.getMonth();
      return this.$t("time.months." + month).toString();
    },
    getPrevMonthName: () => {
      const month = this.monthMethods.calendarFocus.getMonth() - 1;
      return this.$t("time.months." + month).toString();
    },
    getNextMonthName: () => {
      const month = this.monthMethods.calendarFocus.getMonth() + 1;
      return this.$t("time.months." + month).toString();
    }
  };

  get count() {
    if (!this.initialized) {
      return {
        [BackendResourceEnum.VEHICLE]: 0,
        [BackendResourceEnum.REPORT]: 0,
        [BackendResourceEnum.TICKET]: 0,
        [BackendResourceEnum.EVENT]: 0
      };
    }

    const current = this.monthMethods.currentMonthYear();

    let vehicles = 0;
    let reports = 0;
    let tickets = 0;
    let events = 0;

    for (const vehicleAggregation of this.vehicleAggregations) {
      const lengths = vehicleAggregation.getAmountOfEventsAndTicketsForMonth(current);
      if (lengths.total === 0) continue;
      vehicles++;
      reports += vehicleAggregation.reports.length;
      tickets += lengths.tickets;
      events += lengths.events;
    }

    return {
      [BackendResourceEnum.VEHICLE]: vehicles,
      [BackendResourceEnum.REPORT]: reports,
      [BackendResourceEnum.TICKET]: tickets,
      [BackendResourceEnum.EVENT]: events
    };
  }

  get VehicleStateEnum() {
    return VehicleStateEnum;
  }

  get vehicleAggregationMapKey() {
    return FleetAggregationModule.vehicleAggregationMapKey;
  }

  mounted() {
    if (!this.initialLoading) {
      this.updateVehicleAggregations(true);
    }
  }

  sortVehicleAggregations(aggregations: VehicleAggregation[]) {
    const inverse = this.reverse ? -1 : 1;

    const currentMonth = this.monthMethods.currentMonthYear();
    switch (this.sortBy) {
      case BackendResourceEnum.EVENT: {
        aggregations.sort((a, b) => {
          const aLengths = a.getAmountOfEventsAndTicketsForMonth(currentMonth);
          const bLengths = b.getAmountOfEventsAndTicketsForMonth(currentMonth);

          const eventDifference = bLengths.events - aLengths.events;
          if (eventDifference !== 0) {
            return eventDifference * inverse;
          }

          const ticketDifference = bLengths.tickets - aLengths.tickets;
          if (ticketDifference !== 0) {
            return ticketDifference * inverse;
          }

          const reportDifference = b.reports.length - a.reports.length;
          if (reportDifference !== 0) {
            return reportDifference * inverse;
          }

          return 0;
        });
        break;
      }
      case BackendResourceEnum.TICKET: {
        aggregations.sort((a, b) => {
          const aLengths = a.getAmountOfEventsAndTicketsForMonth(currentMonth);
          const bLengths = b.getAmountOfEventsAndTicketsForMonth(currentMonth);

          const ticketDifference = bLengths.tickets - aLengths.tickets;
          if (ticketDifference !== 0) {
            return ticketDifference * inverse;
          }

          const eventDifference = bLengths.events - aLengths.events;
          if (eventDifference !== 0) {
            return eventDifference * inverse;
          }

          const reportDifference = b.reports.length - a.reports.length;
          if (reportDifference !== 0) {
            return reportDifference * inverse;
          }

          return 0;
        });
        break;
      }
      case BackendResourceEnum.REPORT: {
        aggregations.sort((a, b) => {
          const aLengths = a.getAmountOfEventsAndTicketsForMonth(currentMonth);
          const bLengths = b.getAmountOfEventsAndTicketsForMonth(currentMonth);

          const reportDifference = b.reports.length - a.reports.length;
          if (reportDifference !== 0) {
            return reportDifference * inverse;
          }

          const eventDifference = bLengths.events - aLengths.events;
          if (eventDifference !== 0) {
            return eventDifference * inverse;
          }

          const ticketDifference = bLengths.tickets - aLengths.tickets;
          if (ticketDifference !== 0) {
            return ticketDifference * inverse;
          }

          return 0;
        });
        break;
      }
      default: {
        aggregations.sort((a, b) => {
          const aLengths = a.getAmountOfEventsAndTicketsForMonth(currentMonth);
          const bLengths = b.getAmountOfEventsAndTicketsForMonth(currentMonth);

          const totalDifference = bLengths.total - aLengths.total;
          if (totalDifference !== 0) {
            return totalDifference * inverse;
          }

          const eventDifference = bLengths.events - aLengths.events;
          if (eventDifference !== 0) {
            return eventDifference * inverse;
          }

          const ticketDifference = bLengths.tickets - aLengths.tickets;
          if (ticketDifference !== 0) {
            return ticketDifference * inverse;
          }

          const reportDifference = b.reports.length - a.reports.length;
          if (ticketDifference !== 0) {
            return reportDifference * inverse;
          }

          return 0;
        });
        break;
      }
    }

    this.vehicleAggregations.splice(0, this.vehicleAggregations.length, ...aggregations);
  }

  /**
   * Updates the vehicleAggregations array with the current month's aggregations.
   */
  updateVehicleAggregations(sort?: boolean) {
    // get aggregations for this month
    const aggregations = Array.from(FleetAggregationModule.vehicleAggregationMap.values()).filter(
      a => a.getAmountOfEventsAndTicketsForMonth(this.monthMethods.currentMonthYear()).total > 0
    );

    if (!sort) {
      // order should not be updated: Make sure to keep the order of the previous vehicleAggregations and only add new ones and replace changed once in their position

      // remove those this.vehicleAggregations that are not in aggregations
      for (let i = this.vehicleAggregations.length - 1; i >= 0; i--) {
        if (!aggregations.find(a => a.vehicle.id === this.vehicleAggregations[i].vehicle.id)) {
          this.vehicleAggregations.splice(i, 1);
        }
      }

      // replace those this.vehicleAggregations that are in aggregations
      for (const aggregation of aggregations) {
        const index = this.vehicleAggregations.findIndex(a => a.vehicle.id === aggregation.vehicle.id);
        if (index !== -1) {
          this.vehicleAggregations.splice(index, 1, aggregation);
        }
      }

      // add those aggregations that are not in this.vehicleAggregations
      for (const aggregation of aggregations) {
        if (!this.vehicleAggregations.find(a => a.vehicle.id === aggregation.vehicle.id)) {
          this.vehicleAggregations.push(aggregation);
        }
      }
    } else {
      this.sortVehicleAggregations(aggregations);
    }

    // set flag indicating that the component has been loaded at least once
    this.initialized = true;
  }

  debounceUpdateVehicleAggregation = debounce(this.updateVehicleAggregations, 1000);

  setSortTable(by: BackendResourceEnum) {
    if (this.sortBy !== by) {
      this.sortBy = by;
      this.reverse = false;
    } else if (this.sortBy === by && this.reverse === false) {
      this.sortBy = by;
      this.reverse = true;
    } else if (this.sortBy === by && this.reverse === true) {
      this.sortBy = "";
      this.reverse = false;
    }

    this.updateVehicleAggregations(true);
  }

  @Watch("monthMethods.calendarKey")
  resetPage() {
    this.page = 0;
    this.updateVehicleAggregations(true);
  }

  @Watch("vehicleAggregationMapKey")
  onVehicleAggregationChange() {
    const autoSort = this.sortBy === "";
    this.debounceUpdateVehicleAggregation(autoSort);
  }

  updateEventList() {
    this.$emit("updateEventList", this.monthMethods.calendarFocus);
  }

  openTicketSideCard(ticket: ITicket) {
    this.$emit("openTicketSideCard", ticket);
  }
}
