











































import { ITicket } from "@/models/ticket.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import SideCard from "../utility/SideCard.vue";
import TicketDetailCard from "./TicketDetailCard.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";

@Component({
  components: {
    SideCard,
    TicketDetailCard,
    LatestEntriesCardEmpty
  },
  filters: {}
})
export default class TicketSideCard extends mixins(Vue) {
  @Prop()
  private value!: ITicket;

  @Prop()
  loading!: boolean;

  @Prop()
  loadingUpdate!: boolean;

  @Prop()
  partnerId!: string;

  @Prop()
  projectId!: string;

  @Prop()
  viewId!: string;

  height = document.documentElement.clientHeight;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  get ticket() {
    return this.value;
  }

  set ticket(ticket: ITicket) {
    this.$log.debug("ticket", ticket);
    this.$emit("input", ticket);
  }

  onResize() {
    this.height = document.documentElement.clientHeight;
  }

  confirm(item: MrfiktivReferenceGen) {
    this.$log.debug("confirm TicketSideCard", item);
    this.$emit("confirm", item);
  }
}
