







































import { emailRuleOptional, phoneRuleOptional } from "@/lib/rules/contactRule";
import { HandoverCompanyTypeEnum, ICompany } from "@/models/company.entity";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CompanyForm extends Vue {
  @Prop()
  value!: ICompany;

  isValid = false;

  @Watch("isValid")
  watchIsValid(value: boolean) {
    this.$emit("isValid", value);
  }

  get items(): string[] {
    return Object.values(HandoverCompanyTypeEnum);
  }

  get isEmailRule() {
    return [emailRuleOptional()];
  }

  get isPhoneRule() {
    return [phoneRuleOptional()];
  }
}
