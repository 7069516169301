






import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import ServiceTable from "./ServiceTable.vue";

@Component({
  components: {
    TheLayoutPortal,
    ServiceTable
  }
})
export default class ServiceTableView extends PartnerFallbackMixin {
  loading = false;

  async mounted() {
    this.loading = true;
    await this.trySetByRouteOrDefault();
    this.loading = false;
  }
}
