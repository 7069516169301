




















































































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { IControlElements } from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { displayAvailableWeekDaysAsShortString, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { BookingCreateResourceDtoGen } from "@/services/booking/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ResourceModule } from "@/store/modules/resource.store";
import { Component, Prop } from "vue-property-decorator";
import ResourceForm from "./ResourceForm.vue";
import { dateRule } from "@/lib/rules/dateRule";
import { ResourceUIDto } from "@/lib/dto/resource-ui.dto";
import { IResource, Resource } from "@/models/resource.entity";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";

@Component({
  components: {
    TheLayoutPortal,
    PaginatedTable,
    ConfirmActionDialog,
    ResourceForm
  },
  filters: {
    simpleDoubleDigitDate,
    displayAvailableWeekDaysAsShortString
  }
})
export default class ResourceTable extends PartnerFallbackMixin {
  @Prop({ default: false })
  load!: boolean;

  readonly store = ResourceModule;

  isLoading = false;

  /**
   * Opening delete confirmation
   */
  isDeleteDialogActive = false;
  isDeleteDialogLoading = false;

  /**
   * Opening delete all confirmation
   */
  isDeleteAllDialogActive = false;
  isDeleteAllDialogLoading = false;

  /**
   * Create dialog
   */
  isCreateDialogActive = false;
  isCreateDialogLoading = false;
  /**
   * Update dialog
   */
  isUpdateDialogActive = false;
  isUpdateDialogLoading = false;
  isValid = false;
  isDialogLoading = false;

  /** Create resource DTO */
  resourceDto: BookingCreateResourceDtoGen = this.getEmptyResourceDto();

  /** Update resource DTO */
  updateResourceDto: BookingCreateResourceDtoGen = this.getEmptyResourceDto();
  updateResourceId = "";

  search = "";

  itemsPerPage = 25;

  snack = false;
  snackColor = "";
  snackText = "";

  expanded = [];
  selectedItems: IResource[] = [];

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  headers = [
    { text: this.$t("views.booking.ResourceTable.isActive"), align: "start", value: "isActive", width: 200 },
    { text: this.$t("views.booking.ResourceTable.availability"), align: "start", value: "availability", width: 250 },
    { text: this.$t("views.booking.ResourceTable.displayName"), align: "start", value: "name" },
    {
      text: this.$t("views.booking.ResourceTable.date"),
      align: "end",
      value: "timestamp.created",
      rules: [dateRule()],
      type: "date"
    },
    { text: "", align: "end", value: "controls", width: 150, sortable: false }
  ];

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get resources() {
    return ResourceModule.paginationList;
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-pencil",
        text: this.$t("views.booking.ResourceTable.edit").toString(),
        action: this.openResourceFormInDialog
      },
      {
        icon: "mdi-open-in-new",
        text: this.$t("views.booking.ResourceTable.open").toString(),
        action: this.open
      },
      {
        icon: "mdi-delete-outline",
        text: this.$t("views.booking.ResourceTable.delete").toString(),
        action: (item: IResource) => {
          this.selectedItems = [item];
          this.isDeleteDialogActive = true;
        }
      }
    ];
  }

  async open(resource: IResource) {
    await new GoToHelper(this.$router).goToResourceDetail(this.partnerId, resource.id);
  }

  openResourceFormInDialog(resource: IResource) {
    this.updateResourceDto = new ResourceUIDto(resource);
    this.isUpdateDialogActive = true;
    this.updateResourceId = resource.id;
  }

  async updateResource() {
    if (!this.updateResourceDto) {
      this.$toast.error("error");
      throw new Error("Not defined");
    }

    this.isUpdateDialogLoading = true;

    try {
      await new Resource({
        partnerId: this.partnerId,
        id: this.updateResourceId,
        ...this.updateResourceDto
      }).replaceOneByPartnerId();

      this.$toast.success("👍");
      /** Close dialog only on success */
      this.isUpdateDialogActive = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isUpdateDialogLoading = false;
    }
  }

  closeUpdateResourceDialog() {
    this.isUpdateDialogActive = false;
    this.updateResourceDto = this.getEmptyResourceDto();
    this.updateResourceId = "";
  }

  async createResource() {
    this.isCreateDialogLoading = true;

    try {
      await new Resource({
        partnerId: this.partnerId,
        ...this.resourceDto
      }).create();

      this.$toast.success("👍");
      /** Close dialog only on success */
      this.isCreateDialogActive = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateDialogLoading = false;
    }
  }

  /**
   * Open delete all dialog and save the passed resources
   * @param selected list of resources
   */
  openDeleteAllDialog() {
    this.isDeleteAllDialogActive = true;
  }

  /**
   * Deletes all selected resources.
   */
  async deleteResources() {
    this.isDeleteAllDialogLoading = true;

    if (!this.selectedItems || this.selectedItems.length <= 0) {
      this.isDeleteAllDialogLoading = false;
      this.isDeleteAllDialogActive = false;
      this.$toast.error(this.$t("views.booking.ResourceTable.noDocumentsSelected"));
      return;
    }

    if (this.selectedItems.length >= 10) {
      const error = new Error(this.$t("views.booking.ResourceTable.tooManyError").toString());
      this.$toast.error(error.message);

      throw error;
    }

    const promises: Promise<void>[] = [];
    let counter = 0;
    for (const resource of this.selectedItems) {
      promises.push(resource.delete());
      counter++;
    }

    try {
      await Promise.all(promises);
      this.$toast.success(this.$t("views.booking.ResourceTable.deleted", { counter: counter }));
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.isDeleteAllDialogActive = false;
      this.isDeleteAllDialogLoading = false;
      /** Reset the selected items */
      this.selectedItems = [];
    }
  }

  async deleteResource() {
    this.isDeleteDialogLoading = true;

    const serviceToRemove = this.selectedItems[0];

    if (!serviceToRemove) {
      this.$toast.error(this.$t("views.booking.ResourceTable.noDocumentsSelected"));
    }

    await serviceToRemove.delete();

    this.$toast.success("👍");
    this.isDeleteDialogLoading = false;
    this.isDeleteDialogActive = false;
    this.selectedItems = [];
  }

  onIsValidChange(isValid: boolean) {
    this.isValid = isValid;
  }

  getStateColor(item: IResource) {
    if (item.isActive) {
      return "success";
    } else {
      return "grey";
    }
  }

  private getEmptyResourceDto(): BookingCreateResourceDtoGen {
    return {
      availability: [],
      name: "",
      isActive: false
    };
  }
}
