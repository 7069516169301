import { BookingAvailabilityGen } from "@/services/booking/v1/data-contracts";
import { formatUTCHoursAndMinutes } from "../utility/date-helper";

export class UpdateAvailabilityDto implements BookingAvailabilityGen {
  /**
   * Is available
   */
  isAvailable = false;

  /**
   * Date object to easily manipulate the date via setUTCHours
   * Set default startTime as 8AM
   */
  startDate = new Date(new Date().setUTCHours(8, 0, 0, 0));

  /**
   * Date object to easily manipulate the date via setUTCHours
   * Set default endTime as 5PM
   */
  endDate = new Date(new Date().setUTCHours(17, 0, 0, 0));

  /**
   * Internal string to manipulate via time picker
   */
  private _startString = "";

  /**
   * Internal string to manipulate via time picker
   */
  private _endString = "";

  constructor(availability?: BookingAvailabilityGen) {
    if (availability) {
      this.isAvailable = availability.isAvailable;
      this.startDate = new Date(availability.startTime);
      this.endDate = new Date(availability.endTime);
    }

    this._startString = formatUTCHoursAndMinutes(this.startDate);
    this._endString = formatUTCHoursAndMinutes(this.endDate);
  }

  /**
   * Sets the start date and stores internal state
   */
  set startString(start: string) {
    if (!start.includes(":")) {
      return;
    }

    this._startString = start;

    const [hours, minutes] = start.split(":");
    this.startDate.setUTCHours(+hours, +minutes, 0, 0);
  }

  /**
   * Returns the internal state
   */
  get startString() {
    return this._startString;
  }

  /**
   * Sets the end date and stores internal state
   */
  set endString(end: string) {
    if (!end.includes(":")) {
      return;
    }

    this._endString = end;

    const [hours, minutes] = end.split(":");
    this.endDate.setUTCHours(+hours, +minutes, 0, 0);
  }

  /**
   * Returns the internal state
   */
  get endString() {
    return this._endString;
  }

  /**
   * UTC Iso string of the start date to be used in the @see BookingAvailabilityGen
   *
   */
  get startTime() {
    return this.startDate.toISOString();
    // return this.startDate.toTimeString().substring(0, 5);
  }

  /**
   * UTC Iso string of the end date to be used in the @see BookingAvailabilityGen
   */
  get endTime() {
    return this.endDate.toISOString();
    // return this.endDate.toTimeString().substring(0, 5);
  }

  toDto(): BookingAvailabilityGen {
    const dto: BookingAvailabilityGen = {
      isAvailable: this.isAvailable,
      startTime: this.startTime,
      endTime: this.endTime
    };

    return dto;
  }
}
