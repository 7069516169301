/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum ManageStepperScreenTypes {
  /**
   * a step that shows a list of required settings
   */
  SETUP = "setup",

  /**
   * a step that shows a title and a description text. can be used for an inital description or a final success message
   */
  DESCRIPTION = "description",

  /**
   * a step that shows a single field to create a certain setting. currently unused
   */
  PREDEFINED_SETTING = "predefinedSetting",

  /**
   * a step that allows users to create their own settings
   */
  CUSTOM_SETTING = "customSetting",

  /**
   * A step to verify required settings and verifies the success of the setup
   */
  CREDENTIALS = "credentials",

  /**
   * a step that is provided by the slot (custom setup assistant step)
   */
  SLOT = "SLOT"
}
