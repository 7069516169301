







import { Vue, Component } from "vue-property-decorator";
import { SettingModule } from "@/store/modules/setting.store";
import PartnerManageDetailCard from "@/components/partner/PartnerManageDetailCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";

@Component({ components: { LayoutSimple, PartnerManageDetailCard } })
export default class PartnerManager extends Vue {
  get partnerSettingLoaded() {
    return SettingModule.partnerSettingLoaded;
  }
  async mounted() {
    if (this.$route.params.partnerId && this.$route.params.settingId) {
      await SettingModule.getPartnerSetting({
        partnerId: this.$route.params.partnerId,
        settingId: this.$route.params.settingId
      });
    }
  }
}
