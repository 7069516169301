























import { Component, Vue, Prop } from "vue-property-decorator";

export interface IDropdownMenuItem {
  text: string;
  icon: string;
  color?: string;
  disabled?: boolean;
  action: () => void;
}

export interface IDropdownMenuActionGroup {
  group: IDropdownMenuItem[];
  divider?: boolean;
}

@Component({
  components: {}
})
export default class ContextDropDownMenu extends Vue {
  @Prop()
  actions!: IDropdownMenuActionGroup[];

  executAction(action: () => void) {
    action();
  }

  color(color: string | undefined) {
    if (color) {
      return `color: ${color}`;
    }
  }
}
