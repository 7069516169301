























































































































import Card from "@/components/utility/Card.vue";
import ComboBox from "@/components/utility/ComboBox.vue";
import { detectDifferences } from "@/lib/detectDifferences";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { formatNumberPlate } from "@/lib/formatNumberplate";
import { calculateDayDifference, calculateMonthDifference, simpleDate } from "@/lib/utility/date-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { VehicleAggregation } from "@/store/modules/fleet-aggregation.store";
import { VehicleEventModule } from "@/store/modules/vehicle-event.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import IconCard from "../utility/IconCard.vue";
import StatisticsCardRow from "../utility/StatisticsCardRow.vue";
import Tooltip from "../utility/tooltip.vue";
import FleetVehicleDetailHomeCardMileageStatistic from "./FleetVehicleDetailHomeCardMileageStatistic.vue";
import FleetVehicleDetailHomeCardArchivedDialog from "./FleetVehicleDetailHomeCardArchivedDialog.vue";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { MrfiktivVehicleViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { TicketModule } from "@/store/modules/ticket.store";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";

@Component({
  components: {
    Card,
    ComboBox,
    IconCard,
    FleetVehicleDetailHomeCardMileageStatistic,
    StatisticsCardRow,
    LatestEntriesCardEmpty,
    Tooltip,
    FleetVehicleDetailHomeCardArchivedDialog
  }
})
export default class FleetVehicleDetailHomeCard extends Vue {
  @Prop()
  vehicleAggregation!: VehicleAggregation;

  @Prop()
  loading!: boolean;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  currentVehicle: MrfiktivVehicleViewModelGen = {
    ...this.vehicleAggregation.vehicle,
    registration: {
      ...(this.vehicleAggregation.vehicle.registration || {}),
      identificationnumber:
        this.vehicleAggregation.vehicle.registration?.identificationnumber ||
        this.vehicleAggregation.vehicle.identificationnumber ||
        "",
      numberplate:
        this.vehicleAggregation.vehicle.registration?.numberplate || this.vehicleAggregation.vehicle.numberplate,
      vehicleClass: this.vehicleAggregation.vehicle.registration?.vehicleClass
    }
  };

  get statusIcon() {
    if (this.vehicleAggregation.vehicle?.state === VehicleStateEnum.ACTIVE) {
      return "mdi-check-bold";
    }
    if (this.vehicleAggregation.vehicle?.state === VehicleStateEnum.INACTIVE) {
      return "mdi-close";
    }
    if (this.vehicleAggregation.vehicle?.state === VehicleStateEnum.DELETED) {
      return "mdi-delete";
    }

    return "mdi-check-bold";
  }

  get statusColor() {
    if (!this.vehicleAggregation.vehicle.state) return "blue-grey ";

    return VehicleModule.stateMap.get(this.vehicleAggregation.vehicle.state)?.color || "blue-grey ";
  }

  get hasContracts() {
    if (!this.vehicleAggregation.vehicle.contracts) return false;

    return this.vehicleAggregation.vehicle.contracts.length > 0;
  }

  /**
   * Calculates the months until a inspection is necessary
   */
  get monthsUntilInspection() {
    const months = calculateMonthDifference(
      Number(this.vehicleAggregation.vehicle?.registration?.huMonth),
      Number(this.vehicleAggregation.vehicle?.registration?.huYear)
    );

    return months;
  }

  /**
   * Returns the content text of the inspectino card depending on the time until inspection
   */
  get inspectionText() {
    if (this.monthsUntilInspection <= 0) {
      return this.$t("components.fleet.FleetVehicleDetailVehicleMainInspectionDataCard.inspectionRequired");
    }
    return this.$t("components.fleet.FleetVehicleDetailVehicleMainInspectionDataCard.nextInspectionText", {
      months: this.monthsUntilInspection
    });
  }

  get VehicleStateEnum() {
    return VehicleStateEnum;
  }

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailHomeCard");
  }

  /**
   * Returns the distance to start and end time for each contract from today
   */
  get contractDeadlines() {
    if (!this.vehicleAggregation.vehicle.contracts) {
      return [];
    }

    const contractDifference = this.vehicleAggregation.vehicle.contracts
      .filter(c => c.endDate && c.startDate)
      .map(c => {
        return {
          id: c.id,
          number: c.number,
          startDayDifference: c.startDate ? calculateDayDifference(new Date(c.startDate)) : 0,
          endDayDifference: c.endDate ? calculateDayDifference(new Date(c.endDate)) : 0,
          startDate: c.startDate,
          endDate: c.endDate
        };
      })
      .sort((a, b) => {
        return b.endDayDifference - a.endDayDifference;
      });

    return contractDifference;
  }

  /**
   * Returns all contracts that did not end yet
   */
  get nonExpiredContractDeadlines() {
    return this.contractDeadlines.filter(c => c.endDayDifference >= 0);
  }

  get reportCount() {
    return this.vehicleAggregation.reports.filter(r => r.progressStatus === ProgressStatusEnum.NEW).length;
  }

  get eventsOverdueCount() {
    return VehicleEventModule.overdueEvents.length;
  }

  get ticketsCount() {
    const tickets = TicketModule.filtered;
    let count = 0;
    for (const ticket of tickets) {
      if (
        ticket.state === TicketStatusEnum.OPEN &&
        ticket.refs?.find(r => r.refId === this.vehicleAggregation.vehicle.id)
      ) {
        count++;
      }
    }
    return count;
  }

  formatNumberPlate(value: any): string {
    return formatNumberPlate(value);
  }

  emitVehicle() {
    const changed = detectDifferences(this.vehicleAggregation.vehicle, this.currentVehicle);
    if (Object.keys(changed).length !== 0) {
      this.$emit("change", { ...changed, id: this.vehicleAggregation.vehicle.id });
    }
  }

  updateMileageAndEmit(mileages: any) {
    this.currentVehicle.mileages = mileages;
    this.emitVehicle();
  }

  getYearsUntilDeadline(deadline: any) {
    return Math.floor(deadline.endDayDifference / 365);
  }

  getMonthsUntilDeadline(deadline: any) {
    return Math.floor(deadline.endDayDifference / 30 - this.getYearsUntilDeadline(deadline) * 12);
  }

  getDaysUntilDeadline(deadline: any) {
    return deadline.endDayDifference % 30;
  }

  goToContract(contractId?: string) {
    if (!contractId) {
      return this.$emit("goTo", VehicleTabs.CONTRACT);
    }

    return this.$router.push({
      path: `/partner/${this.$route.params.partnerId}/fleet/vehicle/${this.$route.params.vehicleId}/contract/${contractId}?from=home`
    });
  }

  goToTicket() {
    return this.$emit("goTo", VehicleTabs.TICKET);
  }

  goToEvent() {
    return this.$emit("goTo", VehicleTabs.EVENT);
  }

  goToReport() {
    return this.$emit("goTo", VehicleTabs.REPORT);
  }

  simpleDate(date: string) {
    return simpleDate(date);
  }
}
