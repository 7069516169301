var render = function () {
  var _vm$form$contact, _vm$form$contact2, _vm$form$registration;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "1200px"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('partner-report-detail-info-message-card', {
    attrs: {
      "message": _vm.form.message
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "lg": "5"
    }
  }, [_c('customer-contact-card', {
    attrs: {
      "customerName": _vm.form.name,
      "email": (_vm$form$contact = _vm.form.contact) === null || _vm$form$contact === void 0 ? void 0 : _vm$form$contact.email,
      "phone": (_vm$form$contact2 = _vm.form.contact) === null || _vm$form$contact2 === void 0 ? void 0 : _vm$form$contact2.phone,
      "address": ""
    }
  }), _c('partner-registration-card', {
    attrs: {
      "loading": _vm.registrationUpdateLoading,
      "registrationImageLink": _vm.getRegistrationImageLink,
      "registration": _vm.form.registrationResults || {},
      "numberplate": (_vm$form$registration = _vm.form.registrationResults) === null || _vm$form$registration === void 0 ? void 0 : _vm$form$registration.numberplate
    },
    on: {
      "update": _vm.updateRegistration
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "lg": "7"
    }
  }, [_c('partner-form-detail-image-card', {
    attrs: {
      "images": _vm.getImages,
      "registration": _vm.getRegistration
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }