





















































import RulesMixin from "@/mixins/RulesMixin.vue";
import { ICredential } from "@/models/credential.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldConfigurationForm from "../report/CustomFieldConfigurationForm.vue";

@Component({
  components: { CustomFieldConfigurationForm }
})
export default class CredentialForm extends RulesMixin {
  @Prop()
  private value!: ICredential;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop()
  private valid!: boolean;

  get credential() {
    return this.value;
  }

  set credential(credential: ICredential) {
    this.$emit("input", credential);
  }

  get isValid() {
    return this.valid;
  }

  set isValid(v: boolean) {
    this.$emit("update:valid", v);
  }

  availableRessources = [ResourceEnum.ANONYMIZATION];
  actions = Object.keys(ActionEnum).map(k => ActionEnum[k as any]);

  addPem() {
    this.credential.permissions.push({
      type: this.availableRessources[0],
      action: [ActionEnum.MANAGE],
      id: ""
    });
  }

  removePermission(index: number) {
    this.credential.permissions.splice(index, 1);
  }

  get addPemBtnTxt() {
    if (this.credential.permissions.length) {
      return "credential.addFurther";
    } else {
      return "credential.add";
    }
  }
}
