







import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { dayMonthDate, formatHoursAndMinutes, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { VehicleEventEvent } from "@/store/modules/fleet-aggregation.store";
import { Component, Prop, Ref } from "vue-property-decorator";
import RefsEvent from "../utility/RefsEvent.vue";
import Tooltip from "../utility/tooltip.vue";
import EventCardAcknowledgementDialog from "../event/EventCardAcknowledgementDialog.vue";

@Component({
  components: { Tooltip, RefsEvent, EventCardAcknowledgementDialog },
  filters: { simpleDoubleDigitDate, formatHoursAndMinutes, dayMonthDate }
})
export default class FleetHomeVehicleTableEventsListItem extends DarkModeHighlightMixin {
  @Prop()
  event!: VehicleEventEvent;

  @Ref("eventCardAcknowledgementDialog")
  eventCardAcknowledgementDialog!: EventCardAcknowledgementDialog;

  loadingAck = false;

  get eventEvent(): IEventUIDto | undefined {
    if (this.event.data.length && this.event.data[0]) {
      return this.event.data[0];
    }
    return undefined;
  }

  click() {
    this.$emit("click", this.event);
  }

  async acknowledge() {
    await this.eventCardAcknowledgementDialog.open();
  }
}
