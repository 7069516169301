


















import { GoToHelper } from "@/lib/utility/goToHelper";
import { IVehicle } from "@/models/vehicle.entity";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { debounce } from "debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";

@Component({
  components: { LatestEntriesCardEmpty, RefsVehicle }
})
export default class FleetHomeVehicleMostUsedCars extends Vue {
  @Prop()
  loading!: boolean;

  readonly mobileRows = 5;
  readonly desktopRows = 5;

  get rowNumber() {
    let sliceSize = this.desktopRows;
    if (this.isMobile) {
      sliceSize = this.mobileRows;
    }

    return sliceSize;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get bones() {
    const bone = "list-item, divider";
    const bones = [];

    for (let i = 0; i < this.rowNumber; i++) {
      bones.push(bone);
    }

    return bones.join(",");
  }

  get updateTrigger() {
    return FleetAggregationModule.vehicleAggregationMapKey;
  }

  mounted() {
    this.setVehicles();
  }

  vehicles: { totalMileage: number; item: IVehicle; goToVehicle: () => void }[] = [];

  setVehicles() {
    const vehiclesSorted = Array.from(FleetAggregationModule.vehicleAggregationMap.values()).sort(
      (a, b) => b.totalMileage - a.totalMileage
    );
    this.vehicles.splice(0, this.vehicles.length);

    let counter = 0;
    for (const vehicleAggregation of vehiclesSorted) {
      if (counter > this.rowNumber) return;

      counter++;
      const vehicle = vehicleAggregation.vehicle;

      const totalMileage = vehicleAggregation.totalMileage;
      if (totalMileage) {
        this.vehicles.push({
          totalMileage: vehicleAggregation.totalMileage,
          item: vehicle,
          goToVehicle: () => this.vehicleUrl(vehicle)
        });
      }
    }
  }

  debounceSetVehicles = debounce(this.setVehicles, 1000);
  @Watch("updateTrigger")
  triggerSetVehicles() {
    this.debounceSetVehicles();
  }

  vehicleUrl(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, undefined, false);
  }
}
