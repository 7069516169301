











import PartnerReportDetail from "@/components/partner/PartnerReportDetail.vue";
import PartnerReportList from "@/components/partner/PartnerReportList.vue";
import PartnerReportListPaginated from "@/components/partner/PartnerReportListPaginated.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IReport } from "@/models/report.entity";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortalSplit,
    PartnerReportList,
    PartnerReportDetail,
    PartnerReportListPaginated
  }
})
export default class PartnerReports extends PartnerFallbackMixin {
  loading = false;

  get partnerId() {
    return PartnerModule.partner._id;
  }

  mounted() {
    PartnerModule.setReport(null);
    this.trySetByRouteOrDefault();
  }

  async loadDetail(report: IReport) {
    this.$log.debug(`PartnerReports.loadDetail: ${report._id}`);

    if (this.$vuetify.breakpoint.smAndDown) {
      await this.$router.push({
        name: "PartnerReportsDetailView",
        params: { reportId: report._id, partnerId: report.partnerId }
      });

      return;
    }

    try {
      this.loading = true;
      const promises: Promise<void | IReport>[] = [];

      promises.push(PartnerModule.getReportByIdForPartner(report._id));

      ActivityLogModule.loadDocumentsForReport({
        partnerId: report.partnerId,
        reportId: report._id
      });

      await Promise.all(promises);
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }

  async set(item: IReport) {
    await this.loadDetail(item);
  }
}
