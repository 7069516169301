import analyticsService from "@/services/mrfiktiv/services/analyticsService";
import {
  MrfiktivAnalyticsCountGen,
  MrfiktivBaseTrendAnalysisViewmodelGen,
  MrfiktivPartnerAnalysisViewmodelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "reportControlAnalytics",
  store
})
export class ReportControlAnalyticsStore extends VuexModule {
  private _dateRange: string[] = [
    new Date().toISOString(),
    new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()
  ];

  private _selectedPartnerIds: string[] = [];

  get dateRange(): string[] {
    return this._dateRange;
  }

  get selectedPartnerIds(): string[] {
    return this._selectedPartnerIds;
  }

  get from(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return tempo;
  }

  get to(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(20, 59, 59, 999)).toISOString();
    return tempo;
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  @Mutation
  _mutateSelectedPartnerIds(partnerIds: string[]) {
    this._selectedPartnerIds = partnerIds;
  }

  @Action
  setSelectedPartnerIds(partnerIds: string[]) {
    this.context.commit("_mutateSelectedPartnerIds", partnerIds);
  }

  @Action
  async getTrendAnalysisDataForReportControl() {
    const data: MrfiktivBaseTrendAnalysisViewmodelGen = await analyticsService.getTrendAnalysisDataForReportControl(
      this.from,
      this.to
    );

    this.context.commit("_mutateTrendAnalysisDataForReportControl", data);
  }

  private _trendAnalysisDataForReportControl: MrfiktivBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  get trendAnalysisDataForReportControl() {
    return this._trendAnalysisDataForReportControl;
  }

  @Mutation
  _mutateTrendAnalysisDataForReportControl(data: MrfiktivBaseTrendAnalysisViewmodelGen) {
    this._trendAnalysisDataForReportControl = data;
  }

  private _forwardedReportCountsByPartner: MrfiktivPartnerAnalysisViewmodelGen = {
    partnerDistribution: [],
    partnerTypeDistribution: []
  };

  get forwardedReportCountsByPartner() {
    return this._forwardedReportCountsByPartner;
  }

  @Action
  async getForwardedReportCountsByPartner() {
    const data: MrfiktivPartnerAnalysisViewmodelGen = await analyticsService.getForwardedReportCountsByPartner(
      this.from,
      this.to
    );

    this.context.commit("_mutateForwardedReportCountsByPartner", data);
  }

  @Mutation
  _mutateForwardedReportCountsByPartner(data: MrfiktivPartnerAnalysisViewmodelGen) {
    this._forwardedReportCountsByPartner = data;
  }

  @Action
  async getTotalForwardedReportCount() {
    const data = await analyticsService.getTotalForwardedReportCount(this.from, this.to);

    this.context.commit("_mutateTotalForwardedReportCount", data);
  }

  private _totalForwardedReportCount: MrfiktivAnalyticsCountGen = {
    count: 0
  };

  get totalForwardedReportCount() {
    return this._totalForwardedReportCount;
  }

  @Mutation
  _mutateTotalForwardedReportCount(data: MrfiktivAnalyticsCountGen) {
    this._totalForwardedReportCount = data;
  }
}

export const ReportControlAnalyticsModule = getModule(ReportControlAnalyticsStore);
