













import CostCard from "@/components/cost/CostCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import CostMixin from "./CostMixin.vue";
import { ICost, Cost } from "@/models/cost.entity";
import { CostModule } from "@/store/modules/cost.store";
import { handleError } from "@/lib/utility/handleError";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    TheLayoutPortal,
    CostCard,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CostDetailView extends mixins(PartnerFallbackMixin, CostMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get costId(): string {
    return this.$route.params.costId;
  }

  isLoading = false;

  cost: ICost | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    CustomFieldModule.fetchAllFromBeginning({ partnerId: this.partnerId }).catch(handleError);

    const cost =
      CostModule.maps.id.get(this.costId)[0] ??
      new Cost({
        id: this.costId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await cost.fetch();
      this.cost = cost;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
