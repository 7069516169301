


































































































import { SettingModule } from "@/store/modules/setting.store";
import { Component } from "vue-property-decorator";
import PartnerManageStepperMixin from "@/mixins/PartnerManageStepperMixin.vue";
import PartnerManageStepperStepLayout from "@/components/partner/PartnerManageStepperStepLayout.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { valueRule } from "@/lib/rules/valueRule";
import { keyRule } from "@/lib/rules/keyRule";
import { ManageStepperCustomSetting } from "@/lib/interfaces/partner/partnerManageStepperCustomSetting.interface";

/**
 * a step that allows users to create their own settings
 * does not require additional Props - only the ones relevant for the layout like goToNext, currentStep, totalSteps, setLoading
 * these are passed from the PartnerManageStepper
 */
@Component({
  components: { PartnerManageStepperStepLayout }
})
export default class PartnerManageStepperCustomSettingStep extends PartnerManageStepperMixin {
  settings: ManageStepperCustomSetting[] = [
    {
      key: "",
      value: "",
      isEncrypted: false,
      formValid: false
    }
  ];

  currentSetting = 0;

  loaded = true;

  setLoaded(value: boolean) {
    this.loaded = value;
  }

  set formValid(value: boolean) {
    this.settings[this.currentSetting].formValid = value;
  }

  get formValid() {
    return this.settings[this.currentSetting].formValid;
  }

  get key() {
    return this.settings[this.currentSetting].key;
  }

  set key(value: string) {
    this.settings[this.currentSetting].key = value;
  }

  get value() {
    return this.settings[this.currentSetting].value;
  }

  set value(value: string) {
    this.settings[this.currentSetting].value = value;
  }

  get isEncrypted() {
    return this.settings[this.currentSetting].isEncrypted;
  }

  set isEncrypted(value: boolean) {
    this.settings[this.currentSetting].isEncrypted = value;
  }

  title(index: number) {
    if (index === this.currentSetting)
      return String(this.$t("components.partner.PartnerManageStepper.CustomSettingStep.createNewSetting"));
    return this.settings[index].key;
  }

  nextStep() {
    if (this.key !== "" && this.formValid) {
      this.createSetting(this.goToNext);
    } else {
      this.goToNext();
    }
  }

  removeSetting(index: number) {
    this.settings.splice(index, 1);
    this.currentSetting--;
  }

  getValue(i: number) {
    return this.settings[i].isEncrypted ? "************" : this.settings[i].value;
  }

  async deleteSetting(i: number) {
    await SettingModule.getSettingByKey({ key: this.settings[i].key });
    this.removeSetting(i);
    await SettingModule.deleteSetting(SettingModule.partnerSetting.id);
  }

  deleteUnsavedSetting() {
    this.key = "";
    this.value = "";
    this.isEncrypted = false;
  }

  /**
   * Selection of settings
   */
  get items() {
    return [];
  }

  /**
   * Returns rules for value input field
   */
  get valueRules() {
    return [requiredRule(), valueRule()];
  }

  /**
   * Returns rules for value input field
   */
  get keyRules() {
    return [requiredRule(), keyRule()];
  }

  addSetting() {
    this.settings.push({
      key: "",
      value: "",
      isEncrypted: false,
      formValid: false
    });
    this.currentSetting++;
  }

  /**
   * Submit form event handler
   * creates a new setting
   *
   * @param {Function} onCreated: executed when setting is created successfully
   */
  async createSetting(onCreated: Function) {
    if (!this.formValid) return;
    this.setLoaded(false);

    const createPartnerSettingDto = {
      key: this.key,
      value: this.value,
      isEncrypted: this.isEncrypted,
      isActive: false
    };

    await SettingModule.handleCreateSetting(createPartnerSettingDto);

    if (SettingModule.duplicateSettingKey) {
      this.key = "";
      this.$toast.error(this.$t("components.partner.PartnerManageInitializeDialog.duplicateKey"));
    } else {
      try {
        await onCreated();
      } catch (error) {
        this.$log.error(error);
      }
    }
    this.setLoaded(true);
  }

  mounted() {
    this.setLoading(false);
  }
}
