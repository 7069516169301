



















import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { CreateDriver } from "@/models/fleet/create-driver";
import { MrfiktivCreateDriverDtoGen, MrfiktivDriverViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { DriverModule } from "@/store/modules/driver.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import TableWrapper, { IControlElements } from "../utility/TableWrapper.vue";
import FleetVehicleImportDialog from "./FleetVehicleImportDialog.vue";

@Component({
  components: { TableWrapper, FleetVehicleImportDialog }
})
export default class FleetDriverTable extends DarkModeHighlightMixin {
  rules = [requiredRule("")];
  isLoading = false;
  selected: MrfiktivDriverViewModelGen[] = [];

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        action: this.selectDriver
      }
    ];
  }

  get i18n() {
    return this.$t("views.fleet.FleetDriverListView");
  }

  get headers() {
    return [
      {
        text: this.i18n["firstName"],
        align: "left",
        value: "firstName",
        placeholder: "Max",
        rules: this.rules
      },
      {
        text: this.i18n["lastName"],
        align: "left",
        value: "lastName",
        placeholder: "Mustermann",
        rules: this.rules
      },
      { text: "", value: "controls", align: "right", sortable: false }
    ];
  }

  get emptyDriver() {
    return new CreateDriver("", "");
  }

  get allDrivers() {
    return DriverModule.drivers;
  }

  async loadAll() {
    this.isLoading = true;
    await DriverModule.getAll(PartnerModule.partner._id).finally(() => {
      this.isLoading = false;
    });
  }

  async confirmNewDrivers(newDrivers: MrfiktivCreateDriverDtoGen[]) {
    if (newDrivers.length >= 10) {
      const error = new Error(this.i18n["tooManyError"]);
      throw error;
    }

    const promises = [];
    for (const driver of newDrivers) {
      const promise = DriverModule.create({ partnerId: PartnerModule.partner._id, data: driver });

      promises.push(promise);
    }

    /**
     * Await all and display success based on amount of drivers created.
     */
    await Promise.all(promises);

    this.loadAll();
  }

  selectDriver(driver: MrfiktivDriverViewModelGen) {
    if (!driver.id) {
      return;
    }

    this.$router.push({
      name: "DriverDetail",
      params: {
        partnerId: PartnerModule.partner._id,
        driverId: driver.id
      }
    });
  }
}
