













import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ICostGroup, CostGroup } from "@/models/cost-group.entity";
import { CostGroupModule } from "@/store/modules/cost-group.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import CostGroupCard from "@/components/cost/CostGroupCard.vue";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";

@Component({
  components: {
    TheLayoutPortal,
    CostGroupCard,
    LatestEntriesCardEmpty
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CostGroupDetailView extends mixins(PartnerFallbackMixin) {
  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get costGroupId(): string {
    return this.$route.params.costGroupId;
  }

  isLoading = false;

  costGroup: ICostGroup | null = null;

  async mounted() {
    await this.trySetByRouteOrDefault();

    CustomFieldModule.fetchAllFromBeginning({ partnerId: this.partnerId }).catch(handleError);

    const costGroup =
      CostGroupModule.maps.id.get(this.costGroupId)[0] ??
      new CostGroup({
        id: this.costGroupId,
        partnerId: this.partnerId
      });

    try {
      this.isLoading = true;
      await costGroup.fetch();
      this.costGroup = costGroup;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
