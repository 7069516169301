




































































import { Component, Vue, Prop } from "vue-property-decorator";
import { BookingImageConfigurationDtoGen } from "@/services/booking/v1/data-contracts";
import { ReportImageType } from "@/models/Report/ReportImageType";
@Component({
  components: {}
})
export default class BookingImageConfiguration extends Vue {
  @Prop()
  imageConfig!: BookingImageConfigurationDtoGen[];

  @Prop({ default: false })
  isMobile!: boolean;

  /** Default image configuration when service is created or the partner hasn't configured images */
  readonly defaultImageConfig = [
    {
      type: ReportImageType.damage,
      configuration: {
        isActive: false,
        isRequired: false,
        isMultiple: false
      }
    }
  ];

  get reportImageTypes() {
    return Object.values(ReportImageType);
  }

  get imageConfigArray(): BookingImageConfigurationDtoGen[] {
    return this.imageConfig.length === 0 ? this.defaultImageConfig : this.imageConfig;
  }

  get isShowingDefaultImageConfig() {
    return this.imageConfig.length === 0;
  }

  isReportImageDisabled(item: ReportImageType) {
    return this.imageConfig.map(i => i.type).includes(item);
  }

  async addImageConfig() {
    /**
     * First time hitting the button, add the default config to the image config
     */
    if (this.isShowingDefaultImageConfig) {
      this.imageConfig.push({
        ...this.defaultImageConfig[0],
        configuration: {
          ...this.defaultImageConfig[0].configuration,
          isActive: true
        }
      });

      return;
    }

    this.imageConfig.push({
      type: this.reportImageTypes.find(
        reportImageType => !this.imageConfig.map(i => i.type).includes(reportImageType)
      ) as any,
      configuration: {
        isActive: true,
        isRequired: false,
        isMultiple: false
      }
    });

    /** Update UI */
    await this.$nextTick();
    /** Scroll the container so that the new added item is visible */
    const selectContainer = this.$refs["selectContainer"] as HTMLDivElement;
    selectContainer.scrollTop = selectContainer.scrollHeight;
  }

  removeImageConfig(item: BookingImageConfigurationDtoGen) {
    const index = this.imageConfig.indexOf(item);
    this.imageConfig.splice(index, 1);
  }
}
