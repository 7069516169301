var render = function () {
  var _vm$selected$customer, _vm$selected$customer2, _vm$selected$customer3, _vm$selected$customer4, _vm$selected$customer5, _vm$selected$customer6, _vm$selected$customer7, _vm$selected$customer8, _vm$selected$customer9, _vm$selected$customer10, _vm$selected$bookingI, _vm$selected$bookingI2, _vm$selected$bookingI3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.selected ? _c('v-card', {
    attrs: {
      "flat": _vm.flat,
      "outlined": ""
    }
  }, [_vm.selected.isDeleted ? _c('v-alert', {
    attrs: {
      "tile": true,
      "type": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.booking.BookingDetailCard.bookingCancelledAlertText")) + " ")]) : _vm._e(), _c('v-card-title', [_vm.selected.customerInformation ? _c('div', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s((_vm$selected$customer = _vm.selected.customerInformation) === null || _vm$selected$customer === void 0 ? void 0 : _vm$selected$customer.firstName) + " " + _vm._s((_vm$selected$customer2 = _vm.selected.customerInformation) === null || _vm$selected$customer2 === void 0 ? void 0 : _vm$selected$customer2.lastName) + " ")]) : _vm._e(), (_vm$selected$customer3 = _vm.selected.customerInformation) !== null && _vm$selected$customer3 !== void 0 && _vm$selected$customer3.firstName || (_vm$selected$customer4 = _vm.selected.customerInformation) !== null && _vm$selected$customer4 !== void 0 && _vm$selected$customer4.lastName ? _c('div', {
    staticClass: "px-1"
  }, [_vm._v("-")]) : _vm._e(), _c('div', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.serviceName) + " (" + _vm._s(_vm.resourceName) + ")")])]), _vm.detailed ? _c('v-card-text', [_c('v-list', [_c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-clock")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    style: _vm.selected.isDeleted ? 'text-decoration: line-through' : ''
  }, [_vm._v(" " + _vm._s(_vm._f("detailedDateWithDay")(_vm.selected.start)) + " ")]), _c('v-list-item-subtitle', {
    style: _vm.selected.isDeleted ? 'text-decoration: line-through' : ''
  }, [_c('span', [_vm._v(_vm._s(_vm._f("formatHoursAndMinutes")(new Date(_vm.selected.start))))]), _vm.selected.end ? _c('span', [_vm._v("- " + _vm._s(_vm._f("formatHoursAndMinutes")(new Date(_vm.selected.end))) + " ")]) : _vm._e()])], 1)], 1), _c('v-divider'), (_vm$selected$customer5 = _vm.selected.customerInformation) !== null && _vm$selected$customer5 !== void 0 && _vm$selected$customer5.email ? _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-email-outline")])], 1), _c('v-list-item-title', [_vm._v(_vm._s((_vm$selected$customer6 = _vm.selected.customerInformation) === null || _vm$selected$customer6 === void 0 ? void 0 : _vm$selected$customer6.email))])], 1) : _vm._e(), (_vm$selected$customer7 = _vm.selected.customerInformation) !== null && _vm$selected$customer7 !== void 0 && _vm$selected$customer7.phone ? _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-phone-outline")])], 1), _c('v-list-item-title', [_vm._v(_vm._s((_vm$selected$customer8 = _vm.selected.customerInformation) === null || _vm$selected$customer8 === void 0 ? void 0 : _vm$selected$customer8.phone))])], 1) : _vm._e(), (_vm$selected$customer9 = _vm.selected.customerInformation) !== null && _vm$selected$customer9 !== void 0 && _vm$selected$customer9.numberPlate ? _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-car-outline")])], 1), _c('v-list-item-title', [_vm._v(_vm._s((_vm$selected$customer10 = _vm.selected.customerInformation) === null || _vm$selected$customer10 === void 0 ? void 0 : _vm$selected$customer10.numberPlate))])], 1) : _vm._e(), (_vm$selected$bookingI = _vm.selected.bookingInformation) !== null && _vm$selected$bookingI !== void 0 && _vm$selected$bookingI.notes ? _c('v-divider') : _vm._e(), (_vm$selected$bookingI2 = _vm.selected.bookingInformation) !== null && _vm$selected$bookingI2 !== void 0 && _vm$selected$bookingI2.notes ? _c('v-list-item', {
    staticClass: "px-0",
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-text-long")])], 1), _c('v-list-item-subtitle', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s((_vm$selected$bookingI3 = _vm.selected.bookingInformation) === null || _vm$selected$bookingI3 === void 0 ? void 0 : _vm$selected$bookingI3.notes))])], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.showActions ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "small": "",
      "color": "info",
      "elevation": "0"
    },
    on: {
      "click": _vm.click
    }
  }, [_vm._v("Details")])], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }