












































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { IControlElements } from "@/components/utility/TableWrapper.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivPartnerLinkViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerLinkerModule } from "@/store/modules/partner-linker.store";
import { Component } from "vue-property-decorator";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";
import PartnerSelectionForm from "@/components/thg/PartnerSelectionForm.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";

@Component({
  components: {
    TheLayoutPortal,
    PaginatedTable,
    ConfirmActionDialog,
    PartnerSelectionForm
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class PartnerLinkerView extends PartnerFallbackMixin {
  isCreateLinkDialogActive = false;
  loading = false;

  refPartnerId = "";

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get pagination() {
    return PartnerLinkerModule;
  }

  get baseQuery() {
    return { partnerId: this.partnerId };
  }

  get headers() {
    return [
      { text: this.$t("objects.partnerLink.created"), value: "timestamp.created" },
      { text: this.$t("objects.partnerLink.isActive"), value: "isActive" },
      { text: this.$t("objects.partnerLink.refPartnerId"), value: "refPartnerId" },
      { text: "", align: "end", value: "controls", sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-delete-outline",
        action: this.delete,
        text: String(this.$t("remove")),
        disabled: !UserModule.abilities.can(ActionEnum.DELETE, ResourceEnum.PARTNER_LINK, this.partnerId)
      }
    ];
  }

  setRef(partnerId: string) {
    this.refPartnerId = partnerId;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
  }

  async delete(link: MrfiktivPartnerLinkViewModelGen) {
    await PartnerLinkerModule.delete({ partnerId: link.partnerId, id: link.id });
    this.$toast.success("🗑️");
  }

  async create() {
    if (!this.refPartnerId) {
      this.$toast.error("partner id missing");
      return;
    }

    try {
      this.loading = true;
      await PartnerLinkerModule.create({ partnerId: this.partnerId, data: { refPartnerId: this.refPartnerId } });

      this.$toast.success("👍");
      this.isCreateLinkDialogActive = false;
      this.refPartnerId = "";
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  close() {
    this.refPartnerId = "";
    this.isCreateLinkDialogActive = false;
  }
}
