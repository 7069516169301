var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mx-2 my-2"
  }, [_c('v-col', {
    key: 'FleetHomeVehicleTableRow+STATS' + _vm.vehicleAggregation.vehicleAggregationTimeFrameMap.key,
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('v-card-title', [_c('a', {
    on: {
      "click": function click($event) {
        return _vm.openVehicleSideCard(_vm.vehicleAggregation.vehicle);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.vehicleAggregation.vehicle.displayName || _vm.vehicleAggregation.vehicle.numberplate || _vm.vehicleAggregation.vehicle.identificationnumber || _vm.vehicleAggregation.vehicle.id) + " ")])]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.vehicleAggregation.vehicle.numberplate) + " "), _c('v-spacer'), _vm.vehicleAggregation.vehicle.state !== _vm.VehicleStateEnum.ACTIVE ? _c('v-chip', {
    attrs: {
      "color": _vm.statusColor(_vm.vehicleAggregation.vehicle.state),
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state." + _vm.vehicleAggregation.vehicle.state)) + " ")]) : _vm._e()], 1), _c('v-card-text', [_vm.ticketCount + _vm.vehicleAggregation.ticketsWithoutDue.length ? _c('tr', [_c('td', {
    key: 'curr.aggregation.ticket-counter-on-the-left' + _vm.curr.key,
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('b', [_vm._v(" " + _vm._s(_vm.ticketCount + _vm.vehicleAggregation.ticketsWithoutDue.length) + " ")])]), _c('td', {
    staticClass: "pl-1"
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetHomeVehicleTable.openTickets")) + " ")])])]) : _vm._e(), _c('fleet-home-vehicle-table-damage-list', {
    attrs: {
      "items": _vm.vehicleAggregation.reports
    }
  })], 1)], 1), _c('v-col', {
    key: 'FleetHomeVehicleTableRow+LIST' + _vm.vehicleAggregation.vehicleAggregationTimeFrameMap.key,
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('fleet-home-vehicle-table-events', {
    staticClass: "ml-n2 mr-n2",
    attrs: {
      "vehicleAggregation": _vm.vehicleAggregation,
      "prev": _vm.prev,
      "curr": _vm.curr,
      "next": _vm.next,
      "monthMethods": _vm.monthMethods
    },
    on: {
      "openEventSideCard": function openEventSideCard($event) {
        return _vm.$emit('openEventSideCard', $event);
      }
    }
  }), _c('fleet-home-vehicle-table-tickets', {
    staticClass: "ml-1 mr-1",
    attrs: {
      "vehicleAggregation": _vm.vehicleAggregation,
      "prev": _vm.prev,
      "curr": _vm.curr,
      "next": _vm.next,
      "monthMethods": _vm.monthMethods
    },
    on: {
      "openTicketSideCard": _vm.openTicketSideCard
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('fleet-home-vehicle-table-actions', {
    attrs: {
      "dateFocus": _vm.monthMethods.calendarFocus,
      "vehicleAggregation": _vm.vehicleAggregation,
      "partnerId": _vm.vehicleAggregation.vehicle.partnerId
    },
    on: {
      "openTicketSideCard": _vm.openTicketSideCard
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }