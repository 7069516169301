import { UpdateAvailabilityDto } from "./update-availability.dto";
import {
  BookingResourceViewModelGen,
  BookingCreateResourceDtoGen,
  BookingUpdateResourceDtoGen,
  BookingAvailabilityGen
} from "@/services/booking/v1/data-contracts";

/**
 * Class to prepare data for the UI for 1) display and 2) for saving.
 * Prepares the availability array and sets default values for the required fields.
 */
export class ResourceUIDto implements BookingUpdateResourceDtoGen {
  name = "";
  isActive = false;
  availability: BookingAvailabilityGen[] = [];

  constructor(resource: BookingResourceViewModelGen | BookingCreateResourceDtoGen) {
    if (resource) {
      this.name = resource.name;
      this.isActive = resource.isActive;

      if (resource.availability && resource.availability.length == 7) {
        this.availability = resource.availability.map(a => new UpdateAvailabilityDto(a).toDto());
      } else {
        this.availability = [
          ...Array(7)
            .fill(0)
            // eslint-disable-next-line
            .map(x => new UpdateAvailabilityDto().toDto())
        ];
      }
    }
  }

  toDto(): BookingUpdateResourceDtoGen | BookingCreateResourceDtoGen {
    const dto: BookingUpdateResourceDtoGen | BookingCreateResourceDtoGen = {
      name: this.name,
      isActive: this.isActive,
      availability: this.availability
    };

    return dto;
  }
}
