
































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import LatestEntriesCardList from "@/components/cards/LatestEntriesCardList.vue";
import { IListItem } from "@/lib/interfaces/list-item.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { LatestEntriesCardList, LatestEntriesCardEmpty } })
export default class LatestEntriesCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  route(id: string) {
    const path = this.formListRoute + `/${id}`;
    this.$router.push({ path: path });
  }

  get formListRoute() {
    return `/partners/${PartnerModule.partner._id}/form`;
  }

  get latestForms(): IListItem[] {
    if (PartnerModule.forms.length > 0) {
      // Show first 3 entries

      return PartnerModule.forms.slice(0, 3).map(form => ({
        id: form._id,
        title: `${simpleDate(form?.timestamps?.created)} - ${form.name}`,
        subtitle: `${this.excerptMessage(form.message)}`
      }));
    }
    return [];
  }

  excerptMessage(message: string) {
    return message?.substring(0, 75) + "...";
  }
}
