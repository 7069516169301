export interface IUpdateDto<T> {
  /**
   * Updates the entity using the backend, returns the updated entity
   */
  update: (silent?: boolean) => Promise<T | undefined>;

  /**
   * Indicates if the entity is updateable
   */
  readonly isUpdateable: boolean;
}

export abstract class UpdateDto<T> implements IUpdateDto<T> {
  /**
   * @inheritdoc
   */
  abstract update(silent?: boolean): Promise<T | undefined>;

  /**
   * @inheritdoc
   */
  abstract readonly isUpdateable: boolean;
}
