var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table-wrapper', {
    attrs: {
      "emptyItem": _vm.emptyDriver,
      "confirmNewItems": _vm.confirmNewDrivers,
      "controlElements": _vm.controlElements,
      "headers": _vm.headers,
      "allItems": _vm.allDrivers,
      "showSelect": true,
      "selectedItems": _vm.selected,
      "loading": _vm.isLoading
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.firstName",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'DriverDetail',
              params: {
                partnerId: item.partnerId,
                driverId: item.id
              }
            }
          }
        }, [_vm._v(_vm._s(item.firstName))])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }