var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.fleet.FleetHomeVehicleCostAnalytics.title")) + " ")]), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('analytics-date-range-selector-dialog', {
    attrs: {
      "dates": _vm.dates,
      "showFuture": true
    },
    on: {
      "setDate": _vm.loadCosts
    }
  }), _c('v-spacer'), _c('v-btn', {
    staticClass: "mr-1",
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.toggleCostOrder
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.costOrderDesc ? "mdi-sort-ascending" : "mdi-sort-descending") + " ")])], 1)], 1), _vm.isLoadingCosts ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), !_vm.loading && _vm.vehicles.length > 0 ? _c('v-card-text', {
    staticClass: "ma-auto"
  }, _vm._l(_vm.vehicles, function (item, index) {
    return _c('div', {
      key: 'refs-vehicle' + item.total + item.vehicle.id
    }, [_c('v-list-item', {
      staticClass: "mb-n1"
    }, [_c('refs-vehicle', {
      attrs: {
        "item": item.vehicle
      },
      on: {
        "openDetail": item.goToVehicle
      }
    }), _c('v-spacer'), _vm._v(" " + _vm._s(item.total) + " ")], 1), index !== _vm.vehicles.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 0) : _vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": _vm.bones
    }
  }) : _c('latest-entries-card-empty', {
    class: _vm.isMobile ? '' : 'mt-16'
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }