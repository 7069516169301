


















































import Card from "@/components/utility/Card.vue";
import ComboBox from "@/components/utility/ComboBox.vue";
import { MrfiktivDriverViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IContact } from "@/models/User/IContact";
import { emailRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: { Card, ComboBox }
})
export default class FleetDriverDetailDriverCard extends Vue {
  @Prop()
  driver!: MrfiktivDriverViewModelGen;

  currentDriver = this.driver;

  contact: IContact = this.driver.contact || { email: "", phone: "" };

  get i18n() {
    return this.$t("components.fleet.FleetDriverDetailDriverCard") || {};
  }

  emitDriver() {
    if (this.contact.email && this.contact.phone) {
      this.currentDriver.contact = this.contact;
    }
    this.$emit("change", this.currentDriver);
  }

  get emailRule() {
    return [emailRule()];
  }

  get requiredRule() {
    return [requiredRule()];
  }
}
