


































import Debug from "@/components/utility/Debug.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { BookingAvailabilityGen } from "@/services/booking/v1/data-contracts";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";
import { UpdateAvailabilityDto } from "@/lib/dto/update-availability.dto";

@Component({
  components: { Debug }
})
export default class AvailabilityFormField extends Vue {
  @Prop()
  availability!: BookingAvailabilityGen;
  @Prop({ default: false })
  isLoading!: boolean;
  @Prop()
  weekDay!: WeekDaysEnum;

  updatedAvailabilityDto: UpdateAvailabilityDto = new UpdateAvailabilityDto(this.availability);

  @Watch("updatedAvailabilityDto", { deep: true })
  emitUpdated() {
    this.$emit("change", { item: this.updatedAvailabilityDto.toDto(), day: this.weekDay });
  }

  mounted() {
    this.updatedAvailabilityDto = new UpdateAvailabilityDto(this.availability);
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get disabled() {
    return !this.updatedAvailabilityDto.isAvailable;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
