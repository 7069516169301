














































































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IWebhookConfig } from "@/models/webhook-config.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IControlElements, ITableWrapperHeader } from "@/lib/types/tableWrapper";
import WebhookConfigSideCard from "./WebhookConfigSideCard.vue";
import { WebhookConfigModule } from "@/store/modules/webhook-config.store";
import { handleError } from "@/lib/utility/handleError";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import WebhookConfigCreateDialog from "@/components/webhook/WebhookConfigCreateDialog.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    WebhookConfigSideCard,
    AssigneePreview,
    WebhookConfigCreateDialog
  }
})
export default class WebhookConfigTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = WebhookConfigModule;

  @Ref("webhookConfigCreateDialog")
  webhookConfigCreateDialog!: WebhookConfigCreateDialog;

  @Prop()
  partnerId!: string;

  selectedItem: IWebhookConfig | null = null;

  selectedItemForDelete: IWebhookConfig | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    if (!WebhookConfigModule.entities.length) return [];

    return [
      {
        text: $t("project.project.created"),
        value: "timestamp.created",
        width: "110"
      },
      {
        text: $t("objects.webhookConfig.title"),
        value: "title",
        align: "start"
      },
      {
        text: $t("objects.webhookConfig.isActive"),
        value: "isActive",
        align: "start"
      },
      {
        text: $t("objects.webhookConfig.type"),
        value: "type",
        align: "start"
      },
      {
        text: $t("objects.webhookConfig.url"),
        value: "url",
        align: "start",
        width: "300px"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: this.goToWebhookConfigDetail
      },
      {
        icon: "mdi-pencil",
        text: $t("edit"),
        action: this.setSelectedItem
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    return predefinedFilter;
  }

  getTypeName(webhookConfig: IWebhookConfig) {
    let name = $t(`BackendResourceEnum.${webhookConfig.resource}`);
    if (webhookConfig.action) name += " - " + $t(`ActionEnum.${webhookConfig.action}`);
    if (webhookConfig.type) name += " - " + $t(`WebhookTypeEnum.${webhookConfig.type}`);

    return name;
  }

  goToWebhookConfigDetail(item: IWebhookConfig) {
    item.goToDetail(this.$router);
  }

  setSelectedItem(item: IWebhookConfig | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  onDeleteItem(item: IWebhookConfig) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
