import i18n from "../../plugins/I18nPlugin";

/*
 * Keys should only contain letters, numbers, '-' and '_'
 */
export function keyRule(
  message = "components.partner.PartnerManageInitializeDialog.keyRule"
): (value: any) => boolean | string {
  const keyReggaex = RegExp(/^[a-zA-Z0-9_-]*$/);
  return value => keyReggaex.test(value) || String(i18n.tc(message));
}
