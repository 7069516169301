



































import { Component, Vue } from "vue-property-decorator";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import RefsSelectionByType from "@/components/utility/RefsSelectionByType.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import PartnerReportCardImages from "@/components/partner/PartnerReportCardImages.vue";

@Component({
  components: {
    PartnerReportCardImages,
    TheLayoutPortal,
    RefsSelectionByType
  }
})
export default class Test extends Vue {
  report = new CreateReportAsPartnerDto();

  get partnerId() {
    return this.$route.query.partnerId;
  }

  get refType() {
    return BackendResourceEnum.TICKET;
  }

  selected: any | null = null;

  select(selected: any[]) {
    this.selected = selected;
  }
}
