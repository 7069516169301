var render = function () {
  var _vm$selectedEvent$ack, _vm$getAckUser, _vm$selectedEvent$ack2, _vm$getAckUser2, _vm$selectedEvent$ack3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.selectedEvent ? _c('v-card', {
    attrs: {
      "flat": _vm.flat,
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.selectedEvent.summary) + " "), _c('v-spacer'), _vm.showActions ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openEvent
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-expand")])], 1) : _vm._e()], 1), _vm.detailed ? _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-list', [_c('v-list-item', {
    staticClass: "px-0",
    attrs: {
      "three-line": !_vm.selectedEvent.isAllDay && _vm.isRecurring,
      "two-line": _vm.selectedEvent.isAllDay && _vm.isRecurring
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-clock")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm._f("detailedDateWithDay")(_vm.selectedEvent.start)) + " ")]), !_vm.selectedEvent.isAllDay ? _c('v-list-item-subtitle', [_c('span', [_vm._v(_vm._s(_vm._f("formatHoursAndMinutes")(new Date(_vm.selectedEvent.start))))]), _vm.selectedEvent.end ? _c('span', [_vm._v("- " + _vm._s(_vm._f("formatHoursAndMinutes")(new Date(_vm.selectedEvent.end))))]) : _vm._e()]) : _vm._e(), _vm.selectedEvent.rrule ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.recurrenceDescription) + " ")]) : _vm._e()], 1)], 1), _vm.location ? _c('v-divider') : _vm._e(), _vm.location ? _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-map-marker-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.location))])], 1)], 1) : _vm._e(), _vm.isAcknowledged ? _c('v-divider') : _vm._e(), _vm.isAcknowledged ? _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-check-circle")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm._f("detailedDate")((_vm$selectedEvent$ack = _vm.selectedEvent.ack) === null || _vm$selectedEvent$ack === void 0 ? void 0 : _vm$selectedEvent$ack.at)))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$getAckUser = _vm.getAckUser((_vm$selectedEvent$ack2 = _vm.selectedEvent.ack) === null || _vm$selectedEvent$ack2 === void 0 ? void 0 : _vm$selectedEvent$ack2.by)) === null || _vm$getAckUser === void 0 ? void 0 : _vm$getAckUser.firstName) + " " + _vm._s((_vm$getAckUser2 = _vm.getAckUser((_vm$selectedEvent$ack3 = _vm.selectedEvent.ack) === null || _vm$selectedEvent$ack3 === void 0 ? void 0 : _vm$selectedEvent$ack3.by)) === null || _vm$getAckUser2 === void 0 ? void 0 : _vm$getAckUser2.lastName) + " ")])], 1)], 1) : _vm._e(), _vm.description ? _c('v-divider') : _vm._e(), _vm.description ? _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-text-box-outline")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Beschreibung ")])], 1)], 1) : _vm._e(), _vm.description ? _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-content', [_vm._v(" " + _vm._s(_vm.description) + " ")])], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.showActions ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "elevation": "0",
      "small": "",
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": _vm.updateEvent
    }
  }, [_vm._v(_vm._s(_vm.$t("edit")))]), _c('v-btn', {
    attrs: {
      "elevation": "0",
      "small": "",
      "outlined": "",
      "color": "error"
    },
    on: {
      "click": _vm.deleteEvent
    }
  }, [_vm._v(_vm._s(_vm.$t("delete")))])], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }