import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IResource, Resource } from "@/models/resource.entity";

export const ResourceDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IResource> {
  getIdentifier(entity: IResource): string {
    return (entity as any).id;
  }

  mapEntity(entity: IResource): IResource {
    return new Resource(entity);
  }

  mapsConfig = {};
})();
