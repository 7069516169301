export function detectDifferences(original: Record<string, any>, updated: Record<string, any>) {
  let differences = {};

  for (const key of Object.keys(updated)) {
    if (original[key] !== updated[key]) {
      differences = { ...differences, [key]: updated[key] };
    }
  }

  return differences;
}
