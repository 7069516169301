















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import CardIcon from "./CardIcon.vue";

@Component({
  components: { CardIcon }
})
export default class IconCard extends DarkModeHighlightMixin {
  @Prop()
  icon!: string;

  @Prop()
  iconColor!: string;

  @Prop({ default: true })
  outlined!: boolean;

  @Prop({ default: 3 })
  cols!: number;

  cols2 = 12 - this.cols;
}
