var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "loading": _vm.loadingTickets || _vm.loadingSideCard,
      "controlElements": _vm.controlElements,
      "predefinedFilter": _vm.predefinedFilter
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [!_vm.hideCreateButton ? _c('ticket-create-dialog', {
          attrs: {
            "partnerIdProp": _vm.partnerId,
            "isDialogActive": _vm.isCreateDialogActive,
            "refs": _vm.refs,
            "hideToast": true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isCreateDialogActive = $event;
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isCreateDialogActive = $event;
            },
            "created": function created(v) {
              return _vm.$emit('created', v);
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.vehicleRefs",
      fn: function fn(_ref) {
        var item = _ref.item;
        return _vm._l(item.refs, function (ref) {
          return _c('div', {
            key: item.id + ref.refId + ref.refType
          }, [ref.refType === _vm.BackendResourceEnum.VEHICLE ? _c('refs-list', {
            attrs: {
              "small": true,
              "itemPromise": function itemPromise() {
                return _vm.getVehicleById(ref.refId);
              },
              "refType": _vm.BackendResourceEnum.VEHICLE
            },
            on: {
              "openDetail": function openDetail(vehicle) {
                return vehicle.openDetail(_vm.$router, _vm.VehicleTabs.HOME);
              }
            }
          }) : _vm._e()], 1);
        });
      }
    }, {
      key: "item.state",
      fn: function fn(_ref2) {
        var _vm$ticketStateMap$ge;

        var item = _ref2.item;
        return [_c('tooltip', {
          attrs: {
            "text": _vm.$t('project.ticketState.' + item.state)
          }
        }, [_c('v-avatar', {
          attrs: {
            "color": (_vm$ticketStateMap$ge = _vm.ticketStateMap.get(item.state)) === null || _vm$ticketStateMap$ge === void 0 ? void 0 : _vm$ticketStateMap$ge.color,
            "size": "15px"
          }
        })], 1)];
      }
    }, {
      key: "item.number",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-list-item', {
          attrs: {
            "two-line": ""
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "pa-0"
        }, [_vm._v(" #" + _vm._s(item.number) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamp.created)) + " ")])], 1)], 1)];
      }
    }, {
      key: "item.assignees",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('assignee-preview', {
          attrs: {
            "value": item.assignees,
            "amount": 3
          }
        })];
      }
    }, {
      key: "item.title",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.title ? _c('v-list-item', {
          attrs: {
            "two-line": ""
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "pa-0  text-truncate",
          style: _vm.$vuetify.breakpoint.smAndDown ? 'max-width: 250px;' : 'max-width: 350px;'
        }, [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.openDetail(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])]), _c('v-list-item-subtitle', _vm._l(item.tags, function (tag, index) {
          return _c('v-chip', {
            key: 'tag' + index + tag,
            staticClass: "mr-1",
            attrs: {
              "x-small": ""
            }
          }, [_vm._v(" " + _vm._s(tag) + " ")]);
        }), 1)], 1)], 1) : _vm._e()];
      }
    }, {
      key: "item.due",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('due-date-chip', {
          attrs: {
            "ticket": item
          }
        })];
      }
    }])
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "rightText": _vm.$t('delete')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.deleteItem
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }