


































































import { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IProject } from "@/models/project.entity";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ProjectModule } from "@/store/modules/project.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ProjectCreateDialog from "./ProjectCreateDialog.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: { PaginatedTable, Tooltip, ConfirmActionDialog, ProjectCreateDialog },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ProjectTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = ProjectModule;

  @Prop()
  loadingTickets?: boolean;

  @Prop()
  loadingSideCard?: boolean;

  @Prop()
  loadingPartnerUsers?: boolean;

  @Prop()
  loadingProjects?: boolean;

  @Prop()
  partnerId!: string;

  isDeleteDialogActive = false;

  itemToDelete: IProject | null = null;

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  getModule() {
    return ProjectModule;
  }

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: this.$t("project.project.created").toString(),
        value: "timestamp.created",
        width: "110"
      },
      {
        text: this.$t("project.project.title").toString(),
        value: "title",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: this.goToProjectCustomView,
        disabled: this.loadingProjects
      },
      {
        icon: "mdi-pencil",
        text: this.$t("edit").toString(),
        action: this.goToProjectSetting,
        disabled: this.loadingProjects
      },
      {
        icon: "mdi-delete-outline",
        text: this.$t("sign.DocumentTable.delete").toString(),
        action: this.startDelete
      }
    ];
  }

  get projects() {
    return ProjectModule.paginationList;
  }

  async goToProjectDetail(item: IProject) {
    this.$emit("goToProjectDetail", item);
  }

  async goToProjectSetting(item: IProject) {
    this.$emit("goToProjectSetting", item);
  }

  async goToProjectCustomView(item: IProject) {
    this.$emit("goToProjectCustomView", item);
  }

  getUserForId(id: string) {
    return PartnerUserModule.maps.id.get(id)[0];
  }

  getUserNameForId(id: string) {
    return `${PartnerUserModule.maps.id.get(id)[0]?.firstName} ${PartnerUserModule.maps.id.get(id)[0]?.lastName}`;
  }

  startDelete(item: IProject) {
    this.itemToDelete = item;
    this.isDeleteDialogActive = true;
  }

  deleteItem() {
    this.$emit("delete", this.itemToDelete);
    this.itemToDelete = null;
    this.isDeleteDialogActive = false;
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.partnerId);
  }
}
