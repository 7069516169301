var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isMobile ? _c('v-container', {
    key: 'kanban-board-refresh-key' + _vm.refreshKey,
    attrs: {
      "fluid": "",
      "overflow-y-hidden": ""
    }
  }, [_c('debug', [_vm._v(" " + _vm._s(_vm.cards.length) + " elements in " + _vm._s(_vm.map.size) + " buckets (" + _vm._s(_vm.columns.map(function (c) {
    return c.key + " " + c.label;
  }).join(",")) + ") "), _c('br'), _vm._v("refreshKey "), _c('v-btn', {
    on: {
      "click": function click($event) {
        _vm.refreshKey++;
      }
    }
  }, [_vm._v("Refresh")]), _vm._l(_vm.columns, function (column, ind) {
    return _c('div', {
      key: 'colmn' + ind
    }, [_vm._v(" column.key" + _vm._s(column.key) + " colmn" + _vm._s(_vm.map.get(column.key)) + " ")]);
  })], 2), _c('div', {
    staticClass: "d-flex flex-row",
    staticStyle: {
      "overflow-x": "scroll"
    }
  }, _vm._l(_vm.columns, function (column) {
    return _c('div', {
      key: column.key,
      staticClass: "column-width mx-2 px-1 py-4",
      class: _vm.$vuetify.theme.dark ? 'dark' : 'light'
    }, [_c('div', {
      staticClass: "d-flex flex-row px-1 mx-1",
      staticStyle: {
        "display": "inline-block"
      }
    }, [column.color ? _c('v-avatar', {
      staticClass: "align-self-center mr-2",
      attrs: {
        "color": column.color,
        "elevation": 0,
        "size": "16"
      }
    }) : _vm._e(), column.label ? _c('span', {
      staticClass: "text-h6 flex-grow-1 text-truncate"
    }, [_vm._v(_vm._s(column.label))]) : _c('span', {
      staticClass: "text-h6 flex-grow-1 text-truncate"
    }, [_vm._v(_vm._s(column.key))]), _vm._t("chip", function () {
      var _vm$map$get;

      return [_c('v-chip', {
        staticClass: "align-end mt-1 mr-1",
        attrs: {
          "small": ""
        }
      }, [_vm._v(" " + _vm._s((_vm$map$get = _vm.map.get(column.key)) === null || _vm$map$get === void 0 ? void 0 : _vm$map$get.length) + " ")])];
    }, {
      "items": _vm.map.get(column.key)
    }), _c('debug', [_vm._v(" " + _vm._s((_vm.map.get(column.key) || []).length) + " elements in " + _vm._s(column) + " ")])], 2), _c('div', {
      style: "height: calc(100vh - ".concat(_vm.offset, "px); overflow-y: scroll;")
    }, [!_vm.loading ? _c('draggable', {
      staticStyle: {
        "height": "100%"
      },
      attrs: {
        "list": _vm.map.get(column.key),
        "group": "tasks",
        "ghost-class": "ghost"
      },
      on: {
        "start": function start($event) {
          return _vm.onDragStart(column.key);
        },
        "change": function change($e) {
          return _vm.change($e, column.key);
        }
      }
    }, _vm._l(_vm.map.get(column.key) || [], function (card, i) {
      return _c('div', {
        key: "".concat(column.key, "-").concat(i),
        staticClass: "ma-2"
      }, [_vm._t("default", null, {
        "item": card
      })], 2);
    }), 0) : _vm._e()], 1)]);
  }), 0)], 1) : _c('v-container', {
    key: 'kanban-board-refresh-key' + _vm.refreshKey,
    staticClass: "noselect",
    attrs: {
      "fluid": "",
      "overflow-y-hidden": ""
    }
  }, [_c('debug', [_vm._v(" " + _vm._s(_vm.cards.length) + " elements in " + _vm._s(_vm.map.size) + " buckets (" + _vm._s(_vm.columns.join(",")) + ")")]), _vm._l(_vm.columns, function (column, index) {
    return _c('div', {
      key: column.key,
      class: _vm.$vuetify.theme.dark ? 'dark' : 'light'
    }, [_c('div', {
      staticClass: "d-flex flex-row px-1 mx-1 my-1",
      staticStyle: {
        "display": "inline-block"
      },
      on: {
        "click": function click() {
          return _vm.toggleExpanded(index);
        }
      }
    }, [!_vm.expanded.includes(index) || !(_vm.map.get(column.key) && _vm.map.get(column.key).length) ? _c('v-icon', {
      attrs: {
        "color": column.color,
        "large": ""
      }
    }, [_vm._v(" mdi-chevron-right ")]) : _c('v-icon', {
      attrs: {
        "color": column.color,
        "large": ""
      }
    }, [_vm._v(" mdi-chevron-down ")]), column.label ? _c('span', {
      staticClass: "text-h6 flex-grow-1 text-truncate"
    }, [_vm._v(_vm._s(column.label))]) : _c('span', {
      staticClass: "text-h6 flex-grow-1 text-truncate"
    }, [_vm._v(_vm._s(column.key))]), _vm._t("chip", function () {
      var _vm$map$get2;

      return [_c('v-chip', {
        staticClass: "align-end mt-1 mr-1",
        attrs: {
          "small": ""
        }
      }, [_vm._v(" " + _vm._s((_vm$map$get2 = _vm.map.get(column.key)) === null || _vm$map$get2 === void 0 ? void 0 : _vm$map$get2.length) + " ")])];
    }, {
      "items": _vm.map.get(column.key)
    }), _c('debug', [_vm._v(" " + _vm._s((_vm.map.get(column.key) || []).length) + " elements in " + _vm._s(column) + " ")])], 2), !_vm.loading ? _c('draggable', {
      staticStyle: {
        "height": "100%",
        "min-height": "10px"
      },
      attrs: {
        "list": _vm.map.get(column.key),
        "group": "tasks",
        "ghost-class": "ghost",
        "handle": ".handle"
      },
      on: {
        "start": function start($event) {
          _vm.startDragCol = column.key;
        },
        "change": function change($e) {
          return _vm.change($e, column.key);
        }
      }
    }, _vm._l(_vm.expanded.includes(index) ? _vm.map.get(column.key) || [] : [], function (card, i) {
      return _c('div', {
        key: "".concat(column.key, "-").concat(i),
        staticClass: "ma-2"
      }, [_c('v-card', {
        attrs: {
          "elevation": "1",
          "loading": card ? card.loading : false
        }
      }, [_c('v-list-item', {
        staticClass: "pa-0 ma-0"
      }, [_c('v-list-item-action', {
        staticClass: "pa-0 ma-0 ml-1 mr-n1"
      }, [_c('v-icon', {
        staticClass: "handle",
        attrs: {
          "large": ""
        }
      }, [_vm._v(" mdi-drag-vertical ")])], 1), _c('v-list-item-content', {
        staticClass: "pa-0 ma-0"
      }, [_vm._t("default", null, {
        "item": card
      })], 2)], 1)], 1)], 1);
    }), 0) : _vm._e()], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }