






































import { Component, Prop } from "vue-property-decorator";
import { IFormableClass } from "@/lib/formable";
import { CreateDto } from "@/lib/utility/data/create-dto.interface";
import ExcelImport from "./ExcelImport.vue";
import ExcelImportMixin from "./ExcelImportMixin.vue";

/**
 * For excample usage see ExcelImportPlayground
 */
@Component({ components: { ExcelImport } })
export default class ExcelImportCreate<T> extends ExcelImportMixin<T> {
  /**
   * The dto class to create a new item
   */
  @Prop()
  dto!: { new (data?: T): CreateDto<T> } & IFormableClass;

  @Prop()
  outlined!: boolean;

  @Prop()
  small!: boolean;

  async processItem(item: T): Promise<T | undefined> {
    const res = await new this.dto({ ...item, ...this.baseData }).create(false);

    this.$emit("created", res);

    return res;
  }
}
