






























import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { IVehicle } from "@/models/vehicle.entity";

import { Component, Prop, Vue } from "vue-property-decorator";
import ContextMenu from "../../components/utility/ContextMenu.vue";
import DeleteDialog from "../dialog/DeleteDialog.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";

@Component({
  components: {
    ContextMenu,
    DeleteDialog
  }
})
export default class FleetVehicleDetailContextMenu extends Vue {
  @Prop()
  vehicle!: IVehicle;

  @Prop()
  loading!: boolean;

  selectedState = this.vehicle.state;

  get vehicleStates() {
    return [VehicleStateEnum.UPCOMING, VehicleStateEnum.ACTIVE, VehicleStateEnum.INACTIVE];
  }

  get backgroundColors() {
    return new Map([
      [VehicleStateEnum.UPCOMING, "accent"],
      [VehicleStateEnum.ACTIVE, "success"],
      [VehicleStateEnum.INACTIVE, "warning"]
    ]);
  }

  getBackgroundColor(selectedState: VehicleStateEnum) {
    return VehicleModule.stateMap.get(selectedState)?.color;
  }

  get backgroundColor() {
    if (!this.selectedState) {
      return { color: "Grey", label: "" };
    }
    return VehicleModule.stateMap.get(this.selectedState)?.color;
  }

  isConfirmDeleteDialogActive = false;

  getState(item: IVehicle) {
    if (!item.state) {
      return { color: "Grey", label: "" };
    }

    return VehicleModule.stateMap.get(item.state);
  }

  get VehicleStateEnum() {
    return VehicleStateEnum;
  }

  confirmDeletion() {
    this.deleteVehicle();
    this.isConfirmDeleteDialogActive = false;
  }

  async deleteVehicle() {
    this.$emit("delete");
  }

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailContextMenu") || {};
  }

  async changeVehicleStatus() {
    this.$log.debug("changeVehicleStatus", this.selectedState);
    const state = this.selectedState;

    this.$emit("change", { state: state, id: this.vehicle?.id });
  }
}
