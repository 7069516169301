


































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ITicket } from "@/models/ticket.entity";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: { TemplateEditor, Tooltip },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketSideCardBody extends RulesMixin {
  @Prop()
  private value!: ITicket;

  isEdit = false;

  body = this.ticket.body;

  get templateEditorStyle() {
    if (!this.isEdit) {
      return "";
    }
    return "height: 200px;";
  }

  get ticket() {
    return this.value;
  }

  set ticket(ticket: ITicket) {
    this.$emit("input", ticket);
  }

  get userId() {
    return this.ticket.userId;
  }

  get user() {
    return PartnerUserModule.maps.id.get(this.userId)[0];
  }

  get firstName() {
    return this.user?.firstName;
  }

  get lastName() {
    return this.user?.lastName;
  }

  get userName() {
    const user = this.user;

    if (!user) {
      return this.$t("timeLine.ActivityTimeLineItemDocument.unkown");
    }

    return `${user.firstName} ${user.lastName}`;
  }

  get subtitle() {
    const subtitle = this.$t("project.ticket.ticketOpenedByAt", {
      date: simpleDoubleDigitDate(this.ticket.timestamp.created),
      user: this.userName
    });
    return subtitle;
  }

  startEdit() {
    this.body = this.ticket.body;
    this.isEdit = true;
  }

  cancel() {
    this.isEdit = false;
    this.body = this.ticket.body;
  }

  updateBody() {
    this.$emit("update", this.body);
    this.ticket.body = this.body;
    this.isEdit = false;
  }
}
