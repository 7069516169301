var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('m-header', {
    attrs: {
      "title": 'Inboxes',
      "breadCrumbs": _vm.breadCrumbs,
      "chips": _vm.chips
    }
  }), _vm._l(_vm.inboxes, function (inbox, index) {
    return _c('v-list', {
      key: inbox.identifier
    }, [_c('v-list-item', {
      on: {
        "click": function click($event) {
          return _vm.goToInboxList(inbox.identifier);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(inbox.name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(inbox.identifier))])], 1), _c('v-list-item-action', [_c('b', [_vm._v(" " + _vm._s(inbox.module.filtered.length) + " ")])])], 1), index < _vm.inboxes.length - 1 ? _c('v-divider') : _vm._e()], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }