import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store/VuexPlugin";
import {
  MrfiktivAverageTrendAnalysisViewmodelGen,
  MrfiktivBaseTrendAnalysisViewmodelGen,
  MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen,
  MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import analyticsService from "@/services/mrfiktiv/services/analyticsService";

@Module({
  dynamic: true,
  namespaced: true,
  name: "organizationAnalytics",
  store
})
export class OrganizationAnalyticsStore extends VuexModule {
  private _dateRange: string[] = [
    new Date().toISOString(),
    new Date(new Date().setDate(new Date().getDate() - 30)).toISOString()
  ];

  private _selectedPartnerIds: string[] = [];

  get dateRange(): string[] {
    return this._dateRange;
  }

  get selectedPartnerIds(): string[] {
    return this._selectedPartnerIds;
  }

  get from(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return tempo;
  }

  get to(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(20, 59, 59, 999)).toISOString();
    return tempo;
  }

  private _trendAnalysisForMultiplePartnersData: MrfiktivBaseTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: [],
      sevenDayTend: [],
      thirtyDayTrend: []
    }
  };

  private _averageTrendAnalysisForMultiplePartnersData: MrfiktivAverageTrendAnalysisViewmodelGen = {
    data: {
      sum: 0,
      dayTrend: []
    }
  };

  private _reportsCountForMultiplePartnersData: MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen = { data: [] };

  private _statusDistributionForMultiplePartnersData: MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen = {
    data: []
  };

  get trendAnalysisForMultiplePartnersData() {
    return this._trendAnalysisForMultiplePartnersData;
  }

  get averageTrendAnalysisForMultiplePartnersData() {
    return this._averageTrendAnalysisForMultiplePartnersData;
  }

  get reportsCountForMultiplePartnersData() {
    return this._reportsCountForMultiplePartnersData;
  }

  get statusDistributionForMultiplePartnersData() {
    return this._statusDistributionForMultiplePartnersData;
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  @Mutation
  _mutateSelectedPartnerIds(partnerIds: string[]) {
    this._selectedPartnerIds = partnerIds;
  }

  @Action
  setSelectedPartnerIds(partnerIds: string[]) {
    this.context.commit("_mutateSelectedPartnerIds", partnerIds);
  }

  @Action
  async getTrendAnalysisDataForMultiplePartners(partnerIds: string[]) {
    const data: MrfiktivBaseTrendAnalysisViewmodelGen = await analyticsService.getTrendAnalysisDataForMultiplePartners(
      this.from,
      this.to,
      partnerIds
    );

    this.context.commit("_mutateTrendAnalysisDataForMultiplePartners", data);
  }

  @Mutation
  _mutateTrendAnalysisDataForMultiplePartners(data: MrfiktivBaseTrendAnalysisViewmodelGen) {
    this._trendAnalysisForMultiplePartnersData = data;
  }

  @Action
  async getAverageTrendAnalysisDataForMultiplePartners(partnerIds: string[]) {
    const data: MrfiktivAverageTrendAnalysisViewmodelGen = await analyticsService.getAverageTrendAnalysisDataForMultiplePartners(
      this.from,
      this.to,
      partnerIds
    );

    this.context.commit("_mutateAverageTrendAnalysisDataForMultiplePartners", data);
  }

  @Mutation
  _mutateAverageTrendAnalysisDataForMultiplePartners(data: MrfiktivAverageTrendAnalysisViewmodelGen) {
    this._averageTrendAnalysisForMultiplePartnersData = data;
  }

  @Action
  async getReportsCountDataForMultiplePartners(partnerIds: string[]) {
    const data: MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen = await analyticsService.getReportsCountDataForMultiplePartners(
      this.from,
      this.to,
      partnerIds
    );

    this.context.commit("_mutateReportsCountDataForMultiplePartners", data);
  }

  @Mutation
  _mutateReportsCountDataForMultiplePartners(data: MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen) {
    this._reportsCountForMultiplePartnersData = data;
  }

  @Action
  async getStatusDistributionDataForMultiplePartners(partnerIds: string[]) {
    const data: MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen = await analyticsService.getStatusDistributionDataForMultiplePartners(
      this.from,
      this.to,
      partnerIds
    );

    this.context.commit("_mutateStatusDistributionDataForMultiplePartners", data);
  }

  @Mutation
  _mutateStatusDistributionDataForMultiplePartners(data: MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen) {
    this._statusDistributionForMultiplePartnersData = data;
  }
}

export const OrganizationAnalyticsModule = getModule(OrganizationAnalyticsStore);
