/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * The keys of the settings for the pdr setup
 */
export enum PdrSettingsKeys {
  EMAIL = "PDR_EMAIL",
  PASSWORD = "PDR_PASSWORD",
  PARTNER_ID = "PDR_PARTNER_ID"
}
