/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateWebhookConfigDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateWebhookConfigDtoGen,
  MrfiktivWebhookConfigControllerGetAsAdminParamsGen,
  MrfiktivWebhookConfigControllerGetParamsGen,
  MrfiktivWebhookConfigViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class WebhookConfig<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Webhook Config
   * @name WebhookConfigControllerCreate
   * @summary (WEBHOOK_CONFIG - POST) Creates a webhook for a given partner
   * @request POST:/partner/{partnerId}/webhook
   * @secure
   * @response `201` `MrfiktivWebhookConfigViewModelGen` WebhookConfig created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  webhookConfigControllerCreate = (
    partnerId: string,
    data: MrfiktivCreateWebhookConfigDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivWebhookConfigViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/webhook`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Webhook Config
   * @name WebhookConfigControllerGet
   * @summary (WEBHOOK_CONFIG - READ) Gets all webhooks from a given partner
   * @request GET:/partner/{partnerId}/webhook
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivWebhookConfigViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  webhookConfigControllerGet = (
    { partnerId, ...query }: MrfiktivWebhookConfigControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivWebhookConfigViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/webhook`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Webhook Config
   * @name WebhookConfigControllerUpdate
   * @summary (WEBHOOK_CONFIG - UPDATE) Update webhook for a given partner
   * @request PUT:/partner/{partnerId}/webhook/{id}
   * @secure
   * @response `200` `MrfiktivWebhookConfigViewModelGen` Updated webhook document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  webhookConfigControllerUpdate = (
    partnerId: string,
    id: string,
    data: MrfiktivUpdateWebhookConfigDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivWebhookConfigViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/webhook/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Webhook Config
   * @name WebhookConfigControllerGetOne
   * @summary Gets a particular webhook from a given partner
   * @request GET:/partner/{partnerId}/webhook/{id}
   * @secure
   * @response `200` `MrfiktivWebhookConfigViewModelGen` (WEBHOOK_CONFIG - READ) WebhookConfig returned
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  webhookConfigControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivWebhookConfigViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/webhook/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Webhook Config
   * @name WebhookConfigControllerRemove
   * @summary (WEBHOOK_CONFIG - DELETE) Deletes a webhook for a given partner
   * @request DELETE:/partner/{partnerId}/webhook/{id}
   * @secure
   * @response `200` `MrfiktivWebhookConfigViewModelGen` WebhookConfig deleted
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  webhookConfigControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivWebhookConfigViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/webhook/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Webhook Config
   * @name WebhookConfigControllerGetAsAdmin
   * @summary (ADMIN) Gets all webhooks
   * @request GET:/webhook
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivWebhookConfigViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  webhookConfigControllerGetAsAdmin = (
    query: MrfiktivWebhookConfigControllerGetAsAdminParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivWebhookConfigViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/webhook`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
