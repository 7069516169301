




























import Card from "@/components/utility/Card.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { MrfiktivCustomFieldViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import TrashCanIcon from "../utility/TrashCanIcon.vue";
import CustomFieldDetailIcon from "./CustomFieldDetailIcon.vue";
import CustomFieldForm from "./CustomFieldForm.vue";

@Component({
  components: { TrashCanIcon, CustomFieldForm, Card, CustomFieldDetailIcon, ConfirmActionDialog },
  filters: {}
})
export default class CustomFieldDetailCard extends Vue {
  @Prop()
  customField!: MrfiktivCustomFieldViewModelGen;

  isValid = false;

  isDisabled = !!this.customField.id;

  openDetail() {
    new GoToHelper(this.$router).goToCustomFieldDetail(this.customField.partnerId, this.customField.id);
  }
}
