var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ma-1"
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.i18n['firstName'],
      "rules": _vm.requiredRule,
      "outlined": ""
    },
    on: {
      "blur": _vm.emitDriver
    },
    model: {
      value: _vm.currentDriver.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.currentDriver, "firstName", $$v);
      },
      expression: "currentDriver.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.i18n['lastName'],
      "rules": _vm.requiredRule,
      "outlined": ""
    },
    on: {
      "blur": _vm.emitDriver
    },
    model: {
      value: _vm.currentDriver.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.currentDriver, "lastName", $$v);
      },
      expression: "currentDriver.lastName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.i18n['email'],
      "outlined": "",
      "type": "email",
      "rules": _vm.emailRule
    },
    on: {
      "blur": _vm.emitDriver
    },
    model: {
      value: _vm.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.contact, "email", $$v);
      },
      expression: "contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.i18n['phone'],
      "outlined": "",
      "type": "phone"
    },
    on: {
      "blur": _vm.emitDriver
    },
    model: {
      value: _vm.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.contact, "phone", $$v);
      },
      expression: "contact.phone"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.i18n['licenseCheck'],
      "outlined": ""
    },
    on: {
      "blur": _vm.emitDriver
    },
    model: {
      value: _vm.currentDriver.driverLicenseCheck,
      callback: function callback($$v) {
        _vm.$set(_vm.currentDriver, "driverLicenseCheck", $$v);
      },
      expression: "currentDriver.driverLicenseCheck"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.i18n['uvvCheck'],
      "outlined": ""
    },
    on: {
      "blur": _vm.emitDriver
    },
    model: {
      value: _vm.currentDriver.driverUvvCheck,
      callback: function callback($$v) {
        _vm.$set(_vm.currentDriver, "driverUvvCheck", $$v);
      },
      expression: "currentDriver.driverUvvCheck"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }