import { Filter } from "@/lib/filterable";
import sharedContentService from "@/services/mrfiktiv/services/sharedContentService";
import {
  MrfiktivPartnerViewModelGen,
  MrfiktivSharedContentAccessViewmodelGen,
  MrfiktivSharedContentReferenceViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { IShortUser, ShortUser } from "./short-user.entity";

class SharedContentAccessEntity implements MrfiktivSharedContentAccessViewmodelGen, Partial<ISharedContentAccess> {
  user?: IShortUser;
  partner!: MrfiktivPartnerViewModelGen;
  shared!: MrfiktivSharedContentReferenceViewModelGen[];
  validBy!: string;
  isSystemGenerated?: boolean | undefined;

  partnerId: string;
  id: string;
  accessToken: string;

  constructor(partnerId: string, sharedContentId: string, accessToken: string) {
    this.partnerId = partnerId;
    this.id = sharedContentId;
    this.accessToken = accessToken;
  }

  private map(sharedContent: MrfiktivSharedContentAccessViewmodelGen) {
    this.user = new ShortUser(sharedContent.user);
    this.partner = sharedContent.partner;
    this.shared = sharedContent.shared;
    this.validBy = sharedContent.validBy;
    this.isSystemGenerated = sharedContent.isSystemGenerated;
  }

  async fetch(): Promise<this> {
    const access = await sharedContentService.sharedContentReportControllerAccessForPartner(
      this.partnerId,
      this.id,
      this.accessToken
    );

    this.map(access);

    return this;
  }
}
type ISharedContentAccess = SharedContentAccessEntity;
const SharedContentAccess = Filter.createForClass(SharedContentAccessEntity);

export { ISharedContentAccess, SharedContentAccess };
