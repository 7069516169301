



























































import Tooltip from "@/components/utility/tooltip.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ICredential } from "@/models/credential.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CredentialModule } from "@/store/modules/credential.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import CredentialCreateDialog from "./CredentialCreateDialog.vue";
import CredentialShowDialog from "./CredentialShowDialog.vue";
import CredentialUpdateDialog from "./CredentialUpdateDialog.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    Tooltip,
    CredentialCreateDialog,
    CredentialUpdateDialog,
    CredentialShowDialog
  }
})
export default class CredentialTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = CredentialModule;

  @Prop()
  partnerId!: string;

  @Prop()
  loadingPartnerUsers?: boolean;

  getUserForId(id: string) {
    return PartnerUserModule.maps.id.get(id)[0];
  }

  getUserNameForId(id: string) {
    return `${PartnerUserModule.maps.id.get(id)[0]?.firstName} ${PartnerUserModule.maps.id.get(id)[0]?.lastName}`;
  }

  selectedItem: ICredential | null = null;

  selectedItemForDelete: ICredential | null = null;

  isShowDialogActive = false;
  isDeleteDialogActive = false;
  isDeleteLoading = false;
  isUpdateDialogActive = false;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];
    headers.push({
      text: $t("objects.credential.isActive"),
      value: "isActive",
      width: "100px",
      align: "start"
    });
    headers.push({
      text: $t("objects.credential.name"),
      value: "name",
      align: "start"
    });
    headers.push({
      text: $t("objects.credential.userId"),
      value: "userId",
      width: "150px",
      align: "center"
    });
    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp.created",
      width: "150px",
      align: "center"
    });

    headers.push({
      text: "",
      value: "controls",
      align: "end",
      sortable: false
    });

    return headers;
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-eye",
        text: $t("show"),
        action: this.showCredential
      },
      {
        icon: "mdi-pencil",
        text: $t("edit"),
        action: this.onUpdateItem
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    return predefinedFilter;
  }

  getIsActiveMeta(item: ICredential) {
    if (item.isActive) {
      return {
        text: "Aktiviert",
        color: "green lighten-1"
      };
    } else {
      return {
        text: "Deaktiviert",
        color: "grey accent-2"
      };
    }
  }

  setSelectedItem(item: ICredential | null) {
    this.selectedItem = item;
  }

  showCredential(item: ICredential | null) {
    this.setSelectedItem(item);
    this.isShowDialogActive = true;
  }

  onDeleteItem(item: ICredential) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  onUpdateItem(item: ICredential) {
    this.setSelectedItem(item);
    this.isUpdateDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("API-KEY erfolgreich gelöscht."))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }
}
