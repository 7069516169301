var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.HANDOVER
    }
  }, [_vm._t("activator", null, {
    "on": _vm.open
  })], 2), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('objects.handover.create'),
      "isDialogActive": _vm.isDialogActive,
      "rightLoading": _vm.isCreateLoading,
      "rightDisabled": !_vm.isValid,
      "width": "1050"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "rightClick": _vm.create,
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      }
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isHandoverValid,
      callback: function callback($$v) {
        _vm.isHandoverValid = $$v;
      },
      expression: "isHandoverValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-text-field', {
    ref: "titleTextField",
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.handover.title')
    },
    model: {
      value: _vm.handover.title,
      callback: function callback($$v) {
        _vm.$set(_vm.handover, "title", $$v);
      },
      expression: "handover.title"
    }
  }), _c('template-editor', {
    ref: "templateEditor",
    staticStyle: {
      "height": "250px"
    },
    attrs: {
      "readOnly": false,
      "label": _vm.$t('objects.handover.description')
    },
    model: {
      value: _vm.handover.description,
      callback: function callback($$v) {
        _vm.$set(_vm.handover, "description", $$v);
      },
      expression: "handover.description"
    }
  }), _c('custom-field-date-time', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.cost.date')
      }
    },
    model: {
      value: _vm.handover.date,
      callback: function callback($$v) {
        _vm.$set(_vm.handover, "date", $$v);
      },
      expression: "handover.date"
    }
  }), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pa-0 pb-1"
  }, [_vm._v(_vm._s(_vm.$t("common.nouns.parties")))]), _c('v-row', [_vm._l(_vm.handover.parties, function (party, index) {
    return _c('v-col', {
      key: 'party' + index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      staticClass: "mx-0 my-1",
      attrs: {
        "justify": "space-between"
      }
    }, [_c('span', {
      staticClass: "subtitle mb-2"
    }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.company")) + " " + _vm._s(index + 1))]), _c('v-btn', {
      attrs: {
        "outlined": "",
        "color": "error",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeCompany(index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("remove")))])], 1), _c('company-form', {
      key: 'partyform' + index,
      attrs: {
        "value": party
      },
      on: {
        "isValid": function isValid($e) {
          return _vm.isCompanyValid = $e;
        }
      }
    })], 1);
  }), _c('v-col', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "success"
    },
    on: {
      "click": _vm.addCompany
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("add")))])], 1)], 2)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_vm._l(_vm.selectedVehicles, function (v, index) {
    return _c('v-card', {
      key: "vehicle-ref-".concat(v.id),
      staticClass: "mx-1 mb-1",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var hover = _ref.hover;
          return [_c('v-list-item', {
            staticClass: "pa-0"
          }, [_c('v-list-item-content', {
            staticClass: "pa-0"
          }, [_c('refs-vehicle', {
            staticClass: "pa-0",
            attrs: {
              "item": v,
              "small": true
            }
          })], 1), _c('v-list-item-action', {
            staticClass: "pa-0"
          }, [hover || _vm.$vuetify.breakpoint.mobile ? _c('v-btn', {
            attrs: {
              "icon": ""
            },
            on: {
              "click": function click($event) {
                return _vm.removeVehicle(index);
              }
            }
          }, [_c('v-icon', [_vm._v("mdi-close")])], 1) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), _c('refs-selection-by-type', {
    staticClass: "ma-1",
    attrs: {
      "partnerId": _vm.partnerId,
      "refType": _vm.ResourceEnum.VEHICLE,
      "showAsMenu": false,
      "selected": _vm.refs
    },
    on: {
      "select": _vm.setVehicle
    }
  }), _c('debug', [_vm._v(" " + _vm._s(_vm.handover) + " ")])], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }