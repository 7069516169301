var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.dialogModel,
      callback: function callback($$v) {
        _vm.dialogModel = $$v;
      },
      expression: "dialogModel"
    }
  }, [_c('v-card', {
    attrs: {
      "min-width": "300px",
      "loading": _vm.isLoading
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("project.project.updateCustomViewOrder")))]), _c('div', {
    staticClass: "mx-4"
  }, [_c('draggable', {
    attrs: {
      "list": _vm.tempOrder,
      "handle": ".handle"
    },
    on: {
      "change": function change($event) {
        _vm.isChanged = true;
      }
    }
  }, _vm._l(_vm.tempOrder, function (view, index) {
    return _c('v-list-item', {
      key: 'ProjectCustomViewOrder' + view.id + index
    }, [_c('v-list-item-action', [_c('v-icon', {
      staticClass: "handle"
    }, [_vm._v(" mdi-drag-vertical ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('v-icon', [_vm._v(_vm._s(_vm.tabIcon(view.type)))]), _vm._v(" " + _vm._s(view.title) + " ")], 1)], 1)], 1);
  }), 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "small": "",
      "outlined": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.dialogModel = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]), _c('v-btn', {
    attrs: {
      "small": "",
      "elevation": "0",
      "color": "info",
      "disabled": !_vm.isChanged,
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }