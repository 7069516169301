















































import { Component, Vue, Prop } from "vue-property-decorator";
import Debug from "@/components/utility/Debug.vue";
import { MrfiktivPartnerCustomerUserViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import CustomerBasicInfoCard from "@/components/partner/CustomerBasicInfoCard.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";

@Component({
  components: {
    CustomerContactCard,
    CustomerBasicInfoCard,
    Debug
  },

  filters: {
    simpleDate,
    getFlagEmoji
  }
})
export default class CustomerAccountDetail extends Vue {
  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({ default: false })
  navigateToDetails!: boolean;

  @Prop()
  customerAccount!: MrfiktivPartnerCustomerUserViewModelGen;

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
