





























import DashboardCalendar from "@/components/dashboard/DashboardCalendar.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    DashboardCalendar
  }
})
export default class PartnerCalendar extends PartnerFallbackMixin {
  foundPartner = false;
  loading = false;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  get forms() {
    return PartnerModule.forms;
  }

  get reports() {
    return PartnerModule.reports;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get headerUrl() {
    return PartnerModule.partner.settings?.headerImageUrl || "";
  }

  async mounted() {
    this.loading = true;
    try {
      this.foundPartner = await this.trySetByRouteOrDefault();
      await PartnerModule.getAllReportsForPartner();
      await PartnerModule.getAllFormsForPartner();
    } catch {
      this.$toast.error("Ooops... Fehler beim Laden der Anwendung!");
    } finally {
      this.loading = false;
    }
  }
}
