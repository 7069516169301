
import { Component, Vue } from "vue-property-decorator";
import { $t } from "@/lib/utility/t";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";

@Component({
  components: {},
  filters: {}
})
export default class CostMixin extends Vue {
  get breadCrumbs() {
    const locations = CostGoToHelper.locations;

    return {
      CostTable: (partnerId: string) => {
        return {
          text: $t("cost.costs").toString(),
          exact: true,
          to: locations.costTable({ partnerId })
        };
      },
      CostDetail: (partnerId: string, costId: string) => {
        return {
          text: $t("cost.cost").toString(),
          exact: true,
          to: locations.costDetail({ partnerId, costId })
        };
      },
      CostGroupTable: (partnerId: string) => {
        return {
          text: $t("cost.costGroups").toString(),
          exact: true,
          to: locations.costGroupTable({ partnerId })
        };
      },
      CostGroupDetail: (partnerId: string, costGroupId: string) => {
        return {
          text: $t("cost.costGroup").toString(),
          exact: true,
          to: locations.costGroupDetail({ partnerId, costGroupId })
        };
      }
    };
  }
}
