




































import { Component, Prop } from "vue-property-decorator";
import { IFormableClass } from "@/lib/formable";
import { UpdateDto } from "@/lib/utility/data/update-dto.interface";
import ExcelImport from "./ExcelImport.vue";
import ExcelImportMixin from "./ExcelImportMixin.vue";

/**
 * For excample usage see ExcelImportPlayground
 */
@Component({ components: { ExcelImport } })
export default class ExcelImportUpdate<T> extends ExcelImportMixin<T> {
  /**
   * The dto class to update a new item
   */
  @Prop()
  dto!: { new (data?: T): UpdateDto<T> } & IFormableClass;

  async processItem(item: T): Promise<T | undefined> {
    const res = await new this.dto({ ...item, ...this.baseData }).update(false);

    this.$emit("updated", res);

    return res;
  }
}
