import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreatePoolDtoGen,
  MrfiktivPoolControllerGetAllParamsGen,
  MrfiktivUpdatePoolDtoGen
} from "../v1/data-contracts";
import { Pool } from "../v1/Pool";

class PoolService {
  /**
  /**
   * The proxy.
   */
  mrfiktivProxy: Pool;

  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Pool(mrfiktivHttpClientProvider.client());
  }

  async create(partnerId: string, data: MrfiktivCreatePoolDtoGen) {
    return (await this.mrfiktivProxy.poolControllerCreate(partnerId, data)).data;
  }
  async delete(partnerId: string, poolId: string) {
    return (await this.mrfiktivProxy.poolControllerDelete(partnerId, poolId)).data;
  }
  async getAll(query: MrfiktivPoolControllerGetAllParamsGen) {
    return (await this.mrfiktivProxy.poolControllerGetAll(query)).data;
  }
  async getOne(partnerId: string, poolId: string) {
    return (await this.mrfiktivProxy.poolControllerGetOne(partnerId, poolId)).data;
  }
  async update(partnerId: string, poolId: string, data: MrfiktivUpdatePoolDtoGen) {
    return (await this.mrfiktivProxy.poolControllerUpdate(partnerId, poolId, data)).data;
  }
}

export default new PoolService(new MrfiktivHttpClientProvider());
