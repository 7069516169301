















import { Component, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { UserModule } from "@/store/modules/me-user.store";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class PartnerUserMissingPermissionsDialog extends Vue {
  isDialogActive = true;

  isLogoutLoading = false;

  async goToLoginPage() {
    this.isLogoutLoading = true;
    try {
      await UserModule.logout();
      this.$router.push({ name: "Login" });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLogoutLoading = false;
    }
  }
}
