





























import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { BookingModule } from "@/store/modules/booking.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import ResourceTable from "./ResourceTable.vue";
import ServiceTable from "./ServiceTable.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: {
    TheLayoutPortal,
    ResourceTable,
    ServiceTable
  },
  filters: {}
})
export default class BookingSettingView extends PartnerFallbackMixin {
  isLoading = false;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  async mounted() {
    await this.trySetByRouteOrDefault();

    await this.load();
  }

  async goToBookingView() {
    await new GoToHelper(this.$router).goToBookingView();
  }

  get partner() {
    return PartnerModule.partner;
  }

  async load() {
    this.isLoading = true;
    try {
      const bookingPromise = BookingModule.fetchAll({ partnerId: this.partner._id });

      await Promise.all([bookingPromise]);
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
