









































































import { requiredRule } from "@/lib/rules/requiredRule";
import { MrfiktivWebhookConfigHeaderViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class WebhookHeaderConfiguration extends mixins(Vue) {
  @Prop()
  value!: MrfiktivWebhookConfigHeaderViewModelGen[];

  @Prop()
  loading!: boolean;

  menu = -1;

  closeMenu = -1;

  nameCopy = "";

  valueCopy = "";

  get requiredRule() {
    return [requiredRule()];
  }

  setMenu(index: number) {
    this.menu = -1;
    const header = this.value[index];

    if (!header) return;

    this.nameCopy = header.name;
    this.valueCopy = header.value;
    this.$nextTick(() => {
      this.menu = index;
    });
  }

  createNewHeader() {
    this.value.push({ name: "", value: "" });
    this.$emit("input", this.value);
    this.menu = -1;
    this.$nextTick(() => {
      this.menu = this.value.length - 1;
    });
  }

  removeHeader(index: number) {
    this.value.splice(index, 1);
    this.$emit("input", this.value);
    this.cancelMenu();
    this.$emit("change");
  }

  cancelMenu() {
    this.setMenu(-1);
    this.nameCopy = "";
    this.valueCopy = "";
  }

  confirmMenu() {
    this.value.splice(this.menu, 1, {
      name: this.nameCopy,
      value: this.valueCopy
    });
    this.$emit("input", this.value);
    this.cancelMenu();
    this.$emit("change");
  }
}
