








import { Component, Vue } from "vue-property-decorator";
import PartnerFormDetail from "@/components/partner/PartnerFormDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    LayoutSimple,
    PartnerFormDetail
  }
})
export default class PartnerFormsDetailView extends Vue {
  loading = false;

  isGoHomeOnBack = false;

  async mounted() {
    this.loading = true;
    try {
      if (this.$route.query.isGoHomeOnBack === "true") {
        this.isGoHomeOnBack = true;
      }
      await PartnerModule.getFormByPartnerIdAndReportId({
        partnerId: this.$route.params.partnerId,
        documentId: this.$route.params.formId
      });
    } catch {
      this.$toast.error("Fehler beim Laden der Landingpagemeldung");
    } finally {
      this.loading = false;
    }
  }
}
