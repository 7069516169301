
import { Vue, Component, Prop } from "vue-property-decorator";
import { SettingModule } from "@/store/modules/setting.store";

@Component({})
export default class PartnerManageStepperMixin extends Vue {
  @Prop()
  goToNext!: Function;

  @Prop()
  currentStep!: number;

  @Prop()
  totalSteps!: number;

  @Prop()
  setLoading!: Function;

  @Prop()
  goToPrevious?: Function;

  /**
   * ends stepper and goes back to manager
   */
  end() {
    SettingModule.setPartnerSetting({} as any);
    this.$router.push({ name: "PartnerManage" });
  }
}
