















import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { ICredential } from "@/models/credential.entity";

@Component({
  components: {
    ConfirmActionDialog
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CredentialShowDialog extends mixins(PermissionMixin) {
  @Prop({ default: false })
  isDialogActive!: boolean;

  @Prop()
  item!: ICredential;

  copy() {
    navigator.clipboard.writeText(this.item.key);
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
  }

  get key() {
    return this.item?.key || "";
  }

  get isActive() {
    return this.item?.isActive || false;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.$emit("update:isDialogActive", v);
  }
}
