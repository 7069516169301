var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', [_c('partner-manage-stepper', {
    attrs: {
      "steps": _vm.steps
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }