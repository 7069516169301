var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.fleet.FleetHomeVehicleMostUsedCars.title")) + " ")]), !_vm.loading && _vm.vehicles.length > 0 ? _c('v-card-text', {
    staticClass: "ma-auto"
  }, _vm._l(_vm.vehicles, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('v-list-item', {
      staticClass: "mb-n1"
    }, [_c('refs-vehicle', {
      attrs: {
        "item": item.item
      },
      on: {
        "openDetail": item.goToVehicle
      }
    }), _c('v-spacer'), _vm._v(" " + _vm._s(item.totalMileage) + " ")], 1), index !== _vm.vehicles.length - 1 ? _c('v-divider') : _vm._e()], 1);
  }), 0) : _vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": _vm.bones
    }
  }) : _c('latest-entries-card-empty', {
    class: _vm.isMobile ? '' : 'mt-16'
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }