























































import { IEventUIDto, EventUIDto, IRecurringEventTemplate } from "@/lib/dto/event/event-ui.dto";
import { eventTemplates } from "@/lib/eventTemplates/eventTamples";
import { IVehicle } from "@/models/vehicle.entity";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import EventCreateForm from "./EventCreateForm.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { ConfirmActionDialog, EventCreateForm }
})
export default class EventCreateDialog extends Vue {
  @Prop()
  vehicle!: IVehicle;

  @Prop({ default: false })
  icon!: boolean;

  @Prop({ default: false })
  hideButton!: boolean;

  @Prop({ default: false })
  showVehicleSelection!: boolean;

  @Prop()
  vehicleId!: string;

  get selectedVehicle() {
    return this.vehicleId;
  }

  set selectedVehicle(value: string) {
    this.$emit("update:vehicleId", value);
  }

  /**
   * is the dialog active to create a new damage?
   */
  dialog = false;

  /**
   * is the dialog active to create a new damage?
   */
  isValid = false;

  event: IEventUIDto = new EventUIDto();

  get debug() {
    return ConfigModule.debug;
  }

  /**
   * Templates for the creation of events
   */
  get templates(): IRecurringEventTemplate[] {
    return eventTemplates;
  }

  updateEvent(item: IRecurringEventTemplate) {
    (this.$refs.eventForm as EventCreateForm).updateEvent(item);
  }

  get partner() {
    return PartnerModule.partner;
  }

  async createEvent(): Promise<IEventUIDto> {
    this.$emit("createEvent", this.event);
    this.dialog = false;

    return this.event;
  }
}
