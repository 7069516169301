



















import { Component, Vue } from "vue-property-decorator";
import FahrzeugscheinDetail from "@/components/fahrzeugschein/FahrzeugscheinDetail.vue";
import { SessionModule } from "@/store/modules/session";
import { ConfigModule } from "@/store/modules/config";
import { SessionEnum } from "@/store/enum/sessionEnum";
import { SessionEntity } from "@/models/sessionEntity";

@Component({
  components: {
    FahrzeugscheinDetail
  }
})
export default class FahrzeugscheinTable extends Vue {
  search = "";
  error = "";
  sortBy = "created";
  sortDesc = true;
  loading = false;
  headers = [
    {
      text: "Erstellt am:",
      value: "created"
    },
    {
      text: "Status",
      value: "status"
    },
    {
      text: "SessionId",
      align: "start",
      sortable: false,
      value: "session_id"
    }
  ];

  get fahrzeugscheine() {
    return SessionModule.sessions;
  }

  click(clickedSession: SessionEntity) {
    const session = this.fahrzeugscheine.find(f => f.session_id === clickedSession.session_id);
    if (session != undefined && (session.status === SessionEnum.FINISHED || session.status === SessionEnum.CORRECTED)) {
      this.$router.push({
        name: "FahrzeugscheinComponent",
        params: {
          id: session.session_id
        }
      });
    }
  }

  itemRowBackground(item: any) {
    if (item != undefined) {
      return item.status;
    }
  }

  async mounted() {
    if (ConfigModule.fahrzeugscheinToken && SessionModule.sessions.length === 0) {
      this.loading = true;
      try {
        await SessionModule.fetchSessions();
      } finally {
        this.loading = false;
      }
    } else {
      this.error = "Please provide token";
    }
  }
}
