




























































































export interface IPricing {
  title: string;
  subtitle: string;
  color: string;
  features: IFeature[];
  price: number;
  vehiclePrice: number;
  amountUsers: number;
  amountVehicles: number;
}

export interface IFeature {
  name: string;
  subtitle?: string;
  link?: string;
  color: string;
  icon: string;
  description: string;
}

import { deepCopy } from "@/lib/utility/deep-copy";
import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PricingCard extends Vue {
  @Prop()
  config!: IPricing;

  @Prop({ default: "EUR" })
  currency!: string;

  amountVehicles = 0;
  minVehicles = 10;
  price = 0;
  payment = "year";

  get monthPaymentStyle() {
    if (this.payment === "month") {
      return "border: 1px solid " + ConfigModule.color.secondary;
    }
    return "background-color: #f5f5f5;";
  }

  get yearPaymentStyle() {
    if (this.payment === "year") {
      return "border: 1px solid " + ConfigModule.color.secondary;
    }
    return "background-color: #f5f5f5;";
  }

  get priceCalculateted() {
    if (this.payment === "year") {
      return this.price * 0.9;
    }
    return this.price;
  }

  get pricePerVehicle() {
    if (this.payment === "year") {
      return this.config.vehiclePrice * 0.9;
    }
    return this.config.vehiclePrice;
  }

  mounted() {
    this.amountVehicles = deepCopy(this.config.amountVehicles);
    this.price = this.config.price;
  }

  get primaryColor() {
    return ConfigModule.color.primary;
  }

  checkAmountVehciles() {
    if (this.amountVehicles <= this.config.amountVehicles) {
      this.amountVehicles = deepCopy(this.config.amountVehicles);
    }
    this.price =
      (this.amountVehicles - deepCopy(this.config.amountVehicles)) * this.config.vehiclePrice + this.config.price;
  }

  order() {
    this.$emit("order", true);
  }
}
