var render = function () {
  var _vm$portalPartner$add, _vm$portalPartner$add2, _vm$portalPartner$add3, _vm$portalPartner$set, _vm$portalPartner$set2, _vm$portalPartner$set3, _vm$portalPartner$set4, _vm$sharedContent, _vm$user, _vm$user2, _vm$partner, _vm$sharedContent2, _vm$user3, _vm$user4, _vm$portalPartner$add4, _vm$portalPartner$add5, _vm$portalPartner$add6, _vm$portalPartner$set5, _vm$portalPartner$set6, _vm$portalPartner$set7, _vm$portalPartner$set8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', {
    attrs: {
      "logo": _vm.logo
    }
  }, [_c('v-row', {
    attrs: {
      "dense": "",
      "justify": "center"
    }
  }, [_vm.loading ? _c('v-col', {
    attrs: {
      "md": "12",
      "lg": "10",
      "xl": "8"
    }
  }, [_c('v-card', {
    staticClass: "mx-4  mt-4",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-skeleton-loader', {
    staticClass: "ma-4",
    attrs: {
      "type": "title, paragraph, paragraph"
    }
  })], 1)], 1) : _vm.error ? _c('v-col', {
    attrs: {
      "md": "12",
      "lg": "10",
      "xl": "8"
    }
  }, [_c('v-card', {
    staticClass: "mx-4  mt-4",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.expired")))]), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.expiredDetail")) + " ")]), _c('v-card-text', [_c('b', [_vm._v(_vm._s(_vm.portalPartner.companyName))]), _c('br'), _vm._v(" " + _vm._s((_vm$portalPartner$add = _vm.portalPartner.address) === null || _vm$portalPartner$add === void 0 ? void 0 : _vm$portalPartner$add.street) + "," + _vm._s((_vm$portalPartner$add2 = _vm.portalPartner.address) === null || _vm$portalPartner$add2 === void 0 ? void 0 : _vm$portalPartner$add2.zip) + " " + _vm._s((_vm$portalPartner$add3 = _vm.portalPartner.address) === null || _vm$portalPartner$add3 === void 0 ? void 0 : _vm$portalPartner$add3.city)), _c('br'), _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-email-outline")]), _c('a', {
    attrs: {
      "href": "mailto:".concat((_vm$portalPartner$set = _vm.portalPartner.settings) === null || _vm$portalPartner$set === void 0 ? void 0 : _vm$portalPartner$set.contact.email)
    }
  }, [_vm._v(_vm._s((_vm$portalPartner$set2 = _vm.portalPartner.settings) === null || _vm$portalPartner$set2 === void 0 ? void 0 : _vm$portalPartner$set2.contact.email))]), _c('br'), _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-phone-outline")]), _c('a', {
    attrs: {
      "href": "tel:".concat((_vm$portalPartner$set3 = _vm.portalPartner.settings) === null || _vm$portalPartner$set3 === void 0 ? void 0 : _vm$portalPartner$set3.contact.phone)
    }
  }, [_vm._v(_vm._s((_vm$portalPartner$set4 = _vm.portalPartner.settings) === null || _vm$portalPartner$set4 === void 0 ? void 0 : _vm$portalPartner$set4.contact.phone))]), _c('br')], 1)], 1)], 1) : _c('v-col', {
    staticClass: "fore-ground",
    attrs: {
      "md": "12",
      "lg": "10",
      "xl": "8"
    }
  }, [_c('v-card', {
    staticClass: "mx-4  mt-4",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-text', [!((_vm$sharedContent = _vm.sharedContent) !== null && _vm$sharedContent !== void 0 && _vm$sharedContent.isSystemGenerated) ? _c('span', [_c('b', [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.firstName) + " " + _vm._s((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.lastName) + " ")]), _vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.descriptionPersonal")))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.description", {
    company: (_vm$partner = _vm.partner) === null || _vm$partner === void 0 ? void 0 : _vm$partner.companyName
  })) + " "), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("views.PartnerSharedReport.validBy", {
    date: _vm.date
  })) + " ")])]), !((_vm$sharedContent2 = _vm.sharedContent) !== null && _vm$sharedContent2 !== void 0 && _vm$sharedContent2.isSystemGenerated) ? _c('v-card-text', {
    attrs: {
      "personal-contact-information": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.questions"))), _c('br'), _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-email-outline")]), _c('a', {
    attrs: {
      "href": "mailto:".concat((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : _vm$user3.userName)
    }
  }, [_vm._v(_vm._s((_vm$user4 = _vm.user) === null || _vm$user4 === void 0 ? void 0 : _vm$user4.userName))]), _vm._v(" "), _c('br')], 1) : _vm._e(), _c('v-card-text', {
    attrs: {
      "company-information": ""
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.portalPartner.companyName))]), _c('br'), _vm._v(" " + _vm._s((_vm$portalPartner$add4 = _vm.portalPartner.address) === null || _vm$portalPartner$add4 === void 0 ? void 0 : _vm$portalPartner$add4.street) + "," + _vm._s((_vm$portalPartner$add5 = _vm.portalPartner.address) === null || _vm$portalPartner$add5 === void 0 ? void 0 : _vm$portalPartner$add5.zip) + " " + _vm._s((_vm$portalPartner$add6 = _vm.portalPartner.address) === null || _vm$portalPartner$add6 === void 0 ? void 0 : _vm$portalPartner$add6.city)), _c('br'), _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-email-outline")]), _c('a', {
    attrs: {
      "href": "mailto:".concat((_vm$portalPartner$set5 = _vm.portalPartner.settings) === null || _vm$portalPartner$set5 === void 0 ? void 0 : _vm$portalPartner$set5.contact.email)
    }
  }, [_vm._v(_vm._s((_vm$portalPartner$set6 = _vm.portalPartner.settings) === null || _vm$portalPartner$set6 === void 0 ? void 0 : _vm$portalPartner$set6.contact.email))]), _c('br'), _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-phone-outline")]), _c('a', {
    attrs: {
      "href": "tel:".concat((_vm$portalPartner$set7 = _vm.portalPartner.settings) === null || _vm$portalPartner$set7 === void 0 ? void 0 : _vm$portalPartner$set7.contact.phone)
    }
  }, [_vm._v(_vm._s((_vm$portalPartner$set8 = _vm.portalPartner.settings) === null || _vm$portalPartner$set8 === void 0 ? void 0 : _vm$portalPartner$set8.contact.phone))]), _c('br')], 1)], 1), _c('v-timeline', {
    staticClass: "mx-4 mb-8",
    attrs: {
      "dense": "",
      "clipped": ""
    }
  }, [_vm.report ? _c('report-base-time-line', {
    attrs: {
      "report": _vm.report
    }
  }) : _vm._e(), _c('v-timeline-item', {
    staticClass: "no-print",
    attrs: {
      "hide-dot": ""
    }
  }), _c('tooltip', {
    staticClass: "no-print",
    attrs: {
      "text": _vm.$t('views.PartnerSharedReport.printHint')
    }
  }, [_c('v-btn', {
    staticClass: "no-print",
    attrs: {
      "elevation": "0",
      "color": "info"
    },
    on: {
      "click": _vm.print
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.PartnerSharedReport.print")) + " "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v("mdi-printer-outline")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }