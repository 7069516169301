







































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import CreateAgreementDialog from "@/components/handover/CreateAgreementDialog.vue";
import SnapshotSideCard from "@/components/handover/SnapshotSideCard.vue";
import SnapshotTable from "@/components/handover/SnapshotTable.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import ContextMenu from "@/components/utility/ContextMenu.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TableWrapper from "@/components/utility/TableWrapper.vue";
import TrashCanIcon from "@/components/utility/TrashCanIcon.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IControlElements, ITableWrapperHeader } from "@/lib/types/tableWrapper";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { dottedSubString } from "@/lib/utility/string-helper";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IAgreement } from "@/models/agreement.entity";
import { Handover, IHandover } from "@/models/handover.entity";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ConfirmActionDialog,
    TheLayoutPortal,
    SnapshotTable,
    MHeader,
    LatestEntriesCardEmpty,
    SnapshotSideCard,
    CreateAgreementDialog,
    ContextMenu,
    TrashCanIcon,
    TableWrapper
  },
  filters: { simpleDoubleDigitDate }
})
export default class HandoverAgreementsTableView extends mixins(Vue, PermissionMixin) {
  title = $t("handover.agreement.agreements");

  isLoading = true;

  handover: IHandover | null = null;

  agreement: IAgreement | null = null;

  updateFormValid = false;

  isDeleteDialogActive = false;

  editDescription = "";

  editIndex = -1;

  isEditDialogActive = false;

  deleteIndex = -1;

  isDeleteLoading = false;

  isUpdateLoading = false;

  containerWidth = 0;

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: "",
        value: "index",
        type: "date",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 125, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-pencil",
        text: $t("edit"),
        action: (item: { index: number }) => this.openEditDialog(item.index)
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: (item: { index: number }) => this.openDeleteDialog(item.index)
      }
    ];
  }

  get agreements() {
    return this.handover?.agreements.map((agreement, index) => ({ agreement, index })) ?? [];
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: $t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: $t("handover.agreement.agreements"),
      exact: true,
      disabled: true,
      to: {
        name: "HandoverAgreementsTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    return breadcrumbs;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push({
      text: $t("snapshot.createAgreement"),
      key: "newAgreeemnt",
      exec: () => (this.$refs.createAgreementDialog as CreateAgreementDialog)?.open()
    });

    return actions;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchHandover();

      const agreementId = this.$route.query.agreementId;
      if (agreementId) {
        this.handover?.agreements[Number(agreementId)];
        setTimeout(() => {
          document.getElementById(this.getIndex(Number(agreementId)))?.scrollIntoView({ behavior: "smooth" });
        }, 250);
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  measureWidth() {
    const client = (this.$refs.container as Element)?.getBoundingClientRect();

    this.containerWidth = client.width;
  }

  openEditDialog(index: number) {
    this.editIndex = index;
    this.editDescription = this.handover?.agreements[index]?.description ?? "";
    this.isEditDialogActive = true;
  }
  openDeleteDialog(index: number) {
    this.deleteIndex = index;
    this.isDeleteDialogActive = true;
  }

  getIndex(index: number) {
    return "agreement" + index;
  }

  async updateAgreement() {
    try {
      this.isUpdateLoading = true;
      const agreements: IAgreement[] = [...(this.handover?.agreements ?? [])];
      agreements[this.editIndex].description = this.editDescription;
      await this.handover?.updatePartial({ agreements });
      this.isEditDialogActive = false;
    } catch (e) {
      handleError;
    } finally {
      this.isUpdateLoading = false;
    }
  }

  async deleteAgreement() {
    try {
      this.isDeleteLoading = true;
      const agreements: IAgreement[] = [...(this.handover?.agreements ?? [])];
      agreements.splice(this.deleteIndex, 1);
      await this.handover?.updatePartial({ agreements });
      this.isDeleteDialogActive = false;
    } catch (e) {
      handleError;
    } finally {
      this.isDeleteLoading = false;
    }
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }
}
