













































































import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    TheLayoutPortal
  }
})
export default class PartnerPreviewScreens extends PartnerFallbackMixin {
  search = "";
  tab = 0;

  get partner() {
    return PartnerModule.partner;
  }

  get url(): string {
    if (this.partner && this.partner.settings && this.partner.settings.defaultUrl) {
      return `https://${this.partner.settings?.defaultUrl}/#/`;
    }

    return "";
  }

  get screens(): string[] {
    return this.partner.settings?.reportSettings.screenOrder || [];
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
  }

  get headers() {
    return [{ text: "Screen", value: "path" }];
  }

  screenTypes = Object.keys(ReportScreenEnum).map(k => {
    return {
      path: ReportScreenEnum[k as any]
    };
  });

  link(path: string) {
    return this.url + path;
  }
}
