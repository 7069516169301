

















































































































import { IDialog } from "@/lib/interfaces/dialog.interface";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { IInspection } from "@/models/inspection.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivBlueprintElementViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import ReportImageUpload from "@/views/report/ReportImageUpload.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import MImageMarker, { IMarker } from "../utility/mmmint/MImageMarker.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";

export enum TourDirectionEnum {
  LeftToRight = "leftToRight",
  TopToBottom = "topToBottom",
  RightToLeft = "rightToLeft",
  BottomToTop = "bottomToTop"
}

@Component({
  components: {
    MImageMarker,
    ConfirmActionDialog,
    ReportImageUpload,
    RefsVehicle
  }
})
export default class TourHandoverFindingDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  vehicles!: IVehicle[];

  @Prop()
  handover!: IHandover;

  @Prop()
  inspection?: IInspection;

  vehicle: IVehicle | null = null;

  isDialogActive = false;

  TourDirectionEnum = TourDirectionEnum;

  directions: TourDirectionEnum[] = [
    TourDirectionEnum.LeftToRight,
    TourDirectionEnum.TopToBottom,
    TourDirectionEnum.RightToLeft,
    TourDirectionEnum.BottomToTop
  ];
  directionModel = TourDirectionEnum.LeftToRight;

  selectedBlueprint: MrfiktivBlueprintElementViewmodelGen | null = null;

  get markers(): IMarker[] {
    const reports = HandoverReportModule.entities.filter(
      r =>
        r.position?.blueprint.id === this.selectedBlueprint?.id &&
        r.position?.coordinates?.x &&
        r.position?.coordinates?.y
    );

    const markers: IMarker[] = reports.map(r => {
      return {
        top: r.position?.coordinates?.y,
        left: r.position?.coordinates?.x,
        text: r.title
      } as IMarker;
    });

    return markers;
  }

  get blueprints() {
    return this.vehicle?.blueprints;
  }

  get selectedBlueprintId() {
    if (!this.selectedBlueprint) {
      return null;
    }

    return this.selectedBlueprint.id;
  }

  get bluePrintImageUrl() {
    return this.vehicle?.blueprints.find(b => b.id === this.selectedBlueprintId)?.image.url;
  }

  get direction() {
    return this.directionModel;
  }

  set direction(v: TourDirectionEnum) {
    this.directionModel = v;
    this.mykeey = this.mykeey + 1;
  }

  mykeey = 1;

  get markersSorted(): IMarker[] {
    switch (this.direction) {
      case TourDirectionEnum.LeftToRight:
        return this.markers.slice().sort((a, b) => a.left - b.left);
      case TourDirectionEnum.TopToBottom:
        return this.markers.slice().sort((a, b) => a.top - b.top);
      case TourDirectionEnum.RightToLeft:
        return this.markers.slice().sort((a, b) => b.left - a.left);
      case TourDirectionEnum.BottomToTop:
        return this.markers.slice().sort((a, b) => b.top - a.top);
      default:
        return this.markers;
    }
  }

  open() {
    if (!this.can(this.ActionEnum.CREATE, this.ResourceEnum.HANDOVER)) {
      throw new Error("Permission denied");
    }

    this.isDialogActive = true;
  }

  get dialogModel() {
    return this.isDialogActive;
  }

  set dialogModel(v: boolean) {
    if (!v) {
      this.$emit("close");
    }
    this.isDialogActive = v;
  }

  close() {
    this.$emit("close");
    this.dialogModel = false;
  }

  markerClicked(marker: IMarker | undefined) {
    this.$log.info(marker);
  }

  rightClick() {
    if (!this.selectedBlueprintId) {
      return;
    }

    this.$router.push({
      name: "HandoverTourView",
      params: {
        partnerId: this.handover.partnerId,
        handoverId: this.handover.id,
        blueprintId: this.selectedBlueprintId
      },
      query: { dir: this.direction }
    });
  }
}
