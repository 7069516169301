














































import { SettingModule } from "@/store/modules/setting.store";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component({})
export default class PartnerManageEditSetting extends Vue {
  /**
   * is edit currently active determines if options to cancel and or save are available
   */
  @Prop()
  editActive!: boolean;

  /**
   * get partner setting from store
   */
  get partnerSetting() {
    return SettingModule.partnerSetting;
  }

  /**
   * is cancel edit dialog open
   */
  cancelEditDialog = false;

  /**
   * Show cancel edit dialog
   */
  showCancelEditDialog() {
    this.cancelEditDialog = true;
  }

  /**
   * Hide cancel edit dialog
   */
  hideCancelEditDialog() {
    this.cancelEditDialog = false;
  }

  /**
   * show initial values
   * close edit mode
   * close dialog
   */
  submitCancelEditDialog() {
    this.hideCancelEditDialog();
    this.setEditActive(false);
  }

  /**
   * Close edit mode when different setting is opened
   */
  @Watch("partnerSetting", { deep: true })
  onPartnerSettingChanged() {
    this.setEditActive(false);
  }

  /**
   * (de-) activate edit mode
   */
  setEditActive(value: boolean) {
    this.$emit("update:editActive", value);
  }
}
