import { calculateDayDifference, getDays } from "./date-helper";
import { IVehicle } from "@/models/vehicle.entity";

export class ContractHelper {
  constructor(private readonly vehicle: IVehicle) {}

  /**
   * Returns the distance to start and end time for each contract from today
   */
  get contractDeadlines() {
    if (!this.vehicle.contracts) {
      return [];
    }

    const contractDifference = this.vehicle.contracts
      .filter(c => c.startDate && c.endDate)
      .map(c => {
        return {
          id: c.id,
          number: c.number,
          startDayDifference: c.startDate ? calculateDayDifference(new Date(c.startDate)) : 0,
          endDayDifference: c.endDate ? calculateDayDifference(new Date(c.endDate)) : 0
        };
      })
      .sort((a, b) => {
        return a.endDayDifference - b.endDayDifference;
      });

    return contractDifference;
  }

  /**
   * Returns all contracts that did not end yet
   */
  get nonExpiredContractDeadlines() {
    return this.contractDeadlines.filter(c => c.endDayDifference >= 0);
  }

  /**
   * The current contract is the contract that started the earliest but is not expired
   */
  get currentContract() {
    if (this.nonExpiredContractDeadlines.length === 0) {
      return undefined;
    }

    const sortByStartDayAscending = this.nonExpiredContractDeadlines.sort(
      (a, b) => a.startDayDifference - b.startDayDifference
    );

    return this.vehicle.contracts?.find(c => c.id === sortByStartDayAscending[0].id) || undefined;
  }

  get mileagesBeforeContractStart() {
    return this.vehicle.mileages?.filter(m => {
      return new Date(m.date) <= new Date(this.currentContract?.startDate || "");
    });
  }

  get mileagesAfterContractStart() {
    const activeMileages = this.vehicle?.mileages?.filter(
      m => new Date(m.date) > new Date(this.currentContract?.startDate || "")
    );

    const sortedActiveMileagesMostCurrentFirst = activeMileages?.sort((a, b) => {
      return new Date(a.date) < new Date(b.date) ? -1 : 1;
    });

    return [this.startMileage, ...(sortedActiveMileagesMostCurrentFirst || [])];
  }

  get startMileage() {
    const passedMileages = this.mileagesBeforeContractStart;

    if (!passedMileages || (passedMileages || []).length === 0) {
      return { mileage: 0, date: this.currentContract?.startDate };
    }

    const sortedPassedMileagesMostCurrentFirst = passedMileages.sort((a, b) => {
      return new Date(a.date) > new Date(b.date) ? -1 : 1;
    });

    return sortedPassedMileagesMostCurrentFirst[0];
  }

  get maxMileage() {
    return this.mileagesAfterContractStart[this.mileagesAfterContractStart.length - 1];
  }

  get slope() {
    const maxDays = getDays(new Date(this.maxMileage.date || new Date().toString()));
    const maxMileage = this.maxMileage.mileage || 0;
    const minDays = getDays(new Date(this.startMileage.date || ""));
    const startMileage = this.startMileage.mileage || 0;

    const dDays = maxDays - minDays;
    if (!dDays) {
      return 0;
    }
    const dMileage = maxMileage - startMileage;

    return dMileage / dDays;
  }

  get contractLength() {
    return (
      getDays(new Date(this.currentContract?.endDate || "")) - getDays(new Date(this.currentContract?.startDate || ""))
    );
  }

  get dateDifference() {
    return getDays(new Date(this.maxMileage.date || "")) - getDays(new Date(this.startMileage.date || ""));
  }

  get calculatedEndMileage() {
    return {
      mileage: this.slope * this.contractLength + this.startMileage.mileage,
      date: this.currentContract?.endDate
    };
  }

  get mileagePerDay() {
    return this.slope;
  }

  get mileagePerWeek() {
    return Math.round(this.slope * 7 * 100) / 100;
  }

  get mileagePerMonth() {
    return Math.round(this.slope * 30 * 100) / 100;
  }

  get allowedEndMileage() {
    return this.currentContract?.maxMilage;
  }
}
