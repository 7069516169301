import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import driverService from "@/services/mrfiktiv/services/driverService";
import {
  MrfiktivCreateDriverDtoGen,
  MrfiktivUpdateDriverDtoGen,
  MrfiktivDriverControllerGetAllParamsGen,
  MrfiktivDriverViewModelGen,
  MrfiktivUpdateDriverProofDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "driver",
  store
})
export class DriverStore extends BasePagination<MrfiktivDriverViewModelGen, MrfiktivDriverControllerGetAllParamsGen> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: MrfiktivDriverViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = [
    { key: "partnerId", type: PageFilterTypes.STRING },
    { key: "internalId", type: PageFilterTypes.STRING },
    { key: "contact.email", type: PageFilterTypes.STRING },
    { key: "contact.phone", type: PageFilterTypes.STRING },
    { key: "startDate", type: PageFilterTypes.STRING },
    { key: "endDate", type: PageFilterTypes.STRING },
    { key: "driverLicenseCheck", type: PageFilterTypes.BOOLEAN },
    { key: "driverUvvCheck", type: PageFilterTypes.BOOLEAN },
    { key: "firstName", type: PageFilterTypes.STRING },
    { key: "lastName", type: PageFilterTypes.STRING },
    { key: "_id", type: PageFilterTypes.OBJECT_ID }
  ].map(f => new PaginationFilterListElement(f));

  @Action
  protected async loadDocuments(query: MrfiktivDriverControllerGetAllParamsGen): Promise<any> {
    return driverService.getAll(query);
  }

  private _driver: MrfiktivDriverViewModelGen | undefined;

  get driver() {
    return this._driver;
  }
  get drivers() {
    return this._paginationList;
  }

  @Mutation
  mutateDriver(driver: MrfiktivDriverViewModelGen) {
    this._driver = driver;
  }
  @Mutation
  mutateDrivers(drivers: MrfiktivDriverViewModelGen[]) {
    this._paginationList = drivers;
  }

  @Action
  async create(data: { partnerId: string; data: MrfiktivCreateDriverDtoGen }) {
    const res = await driverService.create(data.partnerId, data.data);

    this.context.commit("mutateDriver", res);

    return res;
  }

  @Action
  async delete(data: { partnerId: string; driverId: string }) {
    const res = await driverService.delete(data.partnerId, data.driverId);

    this.context.commit("mutateDriver", res);

    return res;
  }

  @Action
  async getAll(partnerId: string) {
    await this.fetchAllFromBeginning({
      partnerId: partnerId
    });
  }

  @Action
  async getOne(data: { partnerId: string; driverId: string }) {
    const res = await driverService.getOne(data.partnerId, data.driverId);

    this.context.commit("mutateDriver", res);

    return res;
  }

  @Action
  async update(data: { partnerId: string; driverId: string; data: MrfiktivUpdateDriverDtoGen }) {
    const res = await driverService.update(data.partnerId, data.driverId, data.data);

    this.context.commit("mutateDriver", res);

    return res;
  }

  @Action
  async updateProof(data: { partnerId: string; driverId: string; data: MrfiktivUpdateDriverProofDtoGen }) {
    const res = await driverService.updateProof(data.partnerId, data.driverId, data.data);

    this.context.commit("mutateDriver", res);

    return res;
  }
}

export const DriverModule = getModule(DriverStore);
