import { render, staticRenderFns } from "./StatisticsSparkLineCard.vue?vue&type=template&id=4f779700&"
import script from "./StatisticsSparkLineCard.vue?vue&type=script&lang=ts&"
export * from "./StatisticsSparkLineCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
installComponents(component, {VSheet,VSparkline})
