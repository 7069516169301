


















import { formatHoursAndMinutes, simpleDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import {
  VehicleAggregation,
  VehicleAggregationTimeFrame,
  VehicleEventCollection
} from "@/store/modules/fleet-aggregation.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleEventModule } from "@/store/modules/vehicle-event.store";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import FleetCalendarCard from "./FleetCalendarCard.vue";
import FleetVehicleEventCrudMixin from "./FleetVehicleEventCrudMixin.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";

@Component({
  components: {
    FleetCalendarCard
  },
  filters: { simpleDoubleDigitDate, simpleDate, formatHoursAndMinutes }
})
export default class FleetVehicleDetailEventCard extends mixins(FleetVehicleEventCrudMixin, PermissionMixin) {
  @Prop()
  vehicleAggregation!: VehicleAggregation;

  parsed = new VehicleEventCollection();

  /**
   * Get Partner Id
   */
  get partnerId() {
    return PartnerModule.partner._id || PartnerModule.partner.id;
  }

  private calendarFocusLocal = new Date().toISOString();

  get calendarFocus() {
    return this.calendarFocusLocal;
  }

  set calendarFocus(cf: string) {
    this.calendarFocusLocal = cf;
    this.debounceEventAggregation(cf);
  }

  isEventAggregationLoading = false;

  focussedMonthYear = { month: new Date().getMonth(), year: new Date().getFullYear() };

  recurringEvents: IEventUIDto[] = [];

  mounted() {
    this.setEvents();
    this.loadRecurringEvents(this.vehicleAggregation.vehicle.id).then(this.setRecurringEvents);

    this.removeAllListeners();
    this.addListenersForEventUpdates();
  }

  beforeDestroy() {
    this.removeAllListeners();
  }

  debounceEventAggregation(calendarFocus: string) {
    debounce(this.updateEventAggregation, 200)(calendarFocus);
  }

  async updateEventAggregation(calendarFocus: string) {
    const newYear = new Date(calendarFocus).getFullYear();
    const newMonth = new Date(calendarFocus).getMonth();

    // only update aggregation when month changes
    if (newYear !== this.focussedMonthYear.year || newMonth !== this.focussedMonthYear.month) {
      this.isEventAggregationLoading = true;

      // change the month
      this.focussedMonthYear.month = newMonth;
      this.focussedMonthYear.year = newYear;

      // adding event listeners that will update the events
      this.removeAllListeners();
      this.addListenersForEventUpdates();

      // load and parse events
      await this.vehicleAggregation.updateEvents({
        year: newYear,
        month: newMonth
      });

      // stop loading
      this.isEventAggregationLoading = false;
    } else {
      this.$log.debug("time did not change");
    }
  }

  setRecurringEvents() {
    this.recurringEvents.splice(0, this.recurringEvents.length, ...VehicleEventModule.recurringEventList);
  }

  setEvents() {
    const vehicleEventCollection = this.vehicleAggregation.getEvents(this.focussedMonthYear);

    this.parsed.setEvents(vehicleEventCollection);
  }

  debounceSetEvents() {
    debounce(this.setEvents, 200)();
  }

  removeAllListeners() {
    for (const map of this.vehicleAggregation.vehicleAggregationTimeFrameMap.map.values()) {
      map.$off(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetEvents);
    }
  }

  addListenersForEventUpdates() {
    const previousDate = new Date(this.focussedMonthYear.year, this.focussedMonthYear.month - 1);
    const previous = { month: previousDate.getMonth(), year: previousDate.getFullYear() };
    const current = this.focussedMonthYear;
    const nextDate = new Date(this.focussedMonthYear.year, this.focussedMonthYear.month + 1);
    const next = { month: nextDate.getMonth(), year: nextDate.getFullYear() };

    for (const time of [previous, current, next]) {
      this.vehicleAggregation
        .getOrCreateVehicleAggregationTimeFrameForMonthFromMap(time)
        .$on(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetEvents);
    }
  }
}
