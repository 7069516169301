
















import { Component } from "vue-property-decorator";
import { PartnerModule } from "@/store/modules/partner";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PartnerFormDetail from "@/components/partner/PartnerFormDetail.vue";
import PartnerFormList from "@/components/partner/PartnerFormList.vue";

@Component({
  components: {
    TheLayoutPortalSplit,
    PartnerFormList,
    PartnerFormDetail
  }
})
export default class PartnerForms extends PartnerFallbackMixin {
  get formsLoading() {
    return PartnerModule.formsLoading;
  }

  async created() {
    await this.trySetByRouteOrDefault();
    await PartnerModule.getAllFormsForPartner();
  }
}
