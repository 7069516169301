














import PartnerCreateCard from "@/components/partner/PartnerCreateCard.vue";
import Logo from "@/components/utility/Logo.vue";
import TheLayoutBase from "@/layouts/TheLayoutBase.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutBase,
    Logo,
    PartnerCreateCard
  }
})
export default class PartnerCreate extends Vue {}
