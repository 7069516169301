








import QuickLinkList from "@/layouts/navigation/QuickLinkList.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    QuickLinkList
  }
})
export default class QuickLinks extends PartnerFallbackMixin {
  foundPartner = false;
  loading = false;
}
