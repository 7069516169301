






























































































































import { HexTransparency } from "@/lib/enum/hex-transparency.enum";
import { CalendarEvent, CalendarTypeEnum } from "@/lib/utility/calendarEvent";
import {
  detailedDateWithDay,
  formatAsFullDateAndFullHour,
  formatHoursAndMinutes,
  msToTime,
  simpleDoubleDigitDate
} from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Booking, IBooking } from "@/models/booking.entity";
import { PartnerModule } from "@/store/modules/partner";
import BookingForm from "@/views/booking/BookingForm.vue";
import { Component, Prop } from "vue-property-decorator";
import Calendar from "../utility/Calendar.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import BookingMenuCard from "./BookingMenuCard.vue";
import { Service, IService } from "@/models/service.entity";
import { ServiceModule } from "@/store/modules/service.store";
import { IResource } from "@/models/resource.entity";
import { ResourceModule } from "@/store/modules/resource.store";

type BookingCalendarEvent = CalendarEvent<IBooking> & {
  booking: IBooking;
  resourceName?: string;
  serviceName?: string;
};

@Component({
  components: {
    ConfirmActionDialog,
    ContextMenu,
    BookingForm,
    BookingMenuCard,
    Calendar
  },
  filters: {
    formatHoursAndMinutes,
    msToTime,
    simpleDoubleDigitDate,
    detailedDateWithDay
  }
})
export default class PartnerBookingCalendar extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  bookings!: IBooking[];

  @Prop()
  services!: Map<string, IService[]>;

  @Prop()
  resources!: Map<string, IResource[]>;

  calendarType = this.isMobile ? CalendarTypeEnum.DAY : CalendarTypeEnum.WEEK;

  /**
   * Opening create confirmation
   */
  isCreateDialogActive = false;
  isCreateDialogLoading = false;
  isCreateValid = false;

  selectedBooking: Partial<BookingCalendarEvent> | null = null;
  selected: IBooking | null | undefined = null;

  createBookingDto: IBooking = new Booking({ partnerId: this.partnerId });

  /**
   * Is the selected event menu open
   */
  selectedOpen = false;

  /**
   * Selected DOM Element to identify the position of the v-menu
   */
  selectedElement = {};

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get i18n() {
    return this.$t("components.booking.PartnerBookingCalendar");
  }

  get servicesList() {
    return ServiceModule.entities;
  }

  get resourcesList() {
    return ResourceModule.entities;
  }

  get events() {
    const events: BookingCalendarEvent[] = [];
    events.push(...this.mapBookingsToEvents);

    return events;
  }

  get categories() {
    return this.resourcesList.map(r => r.name);
  }

  get firstTime() {
    // TODO: Fix this to use the very first start date of service

    return "07:00";
  }

  get partner() {
    return PartnerModule.partner;
  }

  get headers() {
    return [
      { text: this.$t("views.booking.BookingTable.startInSeconds"), align: "start", value: "booking.start" },
      { text: this.$t("views.booking.BookingTable.isDeleted"), align: "start", value: "isDeleted" },
      { text: this.$t("views.booking.BookingTable.startTime"), align: "start", value: "booking.startTime" },
      { text: this.$t("views.booking.BookingTable.endTime"), align: "start", value: "booking.endTime" },
      { text: this.$t("views.booking.BookingTable.durationInSeconds"), align: "start", value: "booking.duration" },
      {
        text: this.$t("views.booking.BookingTable.customerInformation.firstName"),
        align: "start",
        value: "booking.customerInformation.firstName"
      },
      {
        text: this.$t("views.booking.BookingTable.customerInformation.lastName"),
        align: "start",
        value: "booking.customerInformation.lastName"
      },
      {
        text: this.$t("views.booking.BookingTable.service"),
        align: "start",
        value: "booking.serviceId"
      },
      {
        text: this.$t("views.booking.BookingTable.resource"),
        align: "start",
        value: "booking.resourceId"
      },
      {
        text: this.$t("views.booking.BookingTable.date"),
        align: "start",
        value: "booking.timestamp.created",
        width: 200
      },

      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get defaultColor() {
    return Service.SERVICE_DEFAULT_COLOR;
  }

  get placeholder() {
    return Service.SERVICE_INTERNAL_NAME;
  }

  get placeholderColor() {
    return Service.SERVICE_INTERNAL_COLOR;
  }

  get mapBookingsToEvents() {
    return this.bookings
      .sort((bookingPrev, bookingNext) => bookingPrev.start - bookingNext.start)
      .filter(booking => booking && booking.start && booking.end)
      .map(booking => {
        let serviceName = Service.SERVICE_INTERNAL_NAME;
        let color = Service.SERVICE_INTERNAL_COLOR;

        if (booking.serviceId) {
          const service = ServiceModule.maps.id.get(booking.serviceId)[0];
          serviceName = service?.name || Service.SERVICE_INTERNAL_NAME;
          color = service?.color || Service.SERVICE_DEFAULT_COLOR;

          if (booking.isDeleted) {
            color = color + HexTransparency.Sixty;
          }
        }

        const resource = ResourceModule.maps.id.get(booking.resourceId)[0];
        const resourceName = resource?.name;

        let name = "";
        if (booking.customerInformation?.firstName || booking.customerInformation?.lastName) {
          name = `${booking.customerInformation?.firstName} ${booking.customerInformation?.lastName} - `;
        }
        name += `${serviceName} (${resourceName})`;

        if (booking.isDeleted) {
          name = `(${this.i18n["cancelled"]}) ` + name;
        }

        const event: BookingCalendarEvent = {
          name: name,
          start: formatAsFullDateAndFullHour(booking.start),
          end: formatAsFullDateAndFullHour(booking.end),
          booking,
          category: resourceName,
          resourceName: resourceName,
          serviceName: serviceName,
          color: color,
          data: [booking]
        };

        return event;
      });
  }

  mapService(serviceId: string) {
    const services = this.services.get(serviceId) ?? [];

    return services[0] || "";
  }

  mapResource(resourceId: string) {
    return this.resources.get(resourceId) || "";
  }

  generateCalendarEventName(booking: IBooking, time: Date): string {
    return `${booking.customerInformation?.lastName} ${formatHoursAndMinutes(time)}`;
  }

  prevMonth() {
    (this.$refs.calendar as any)?.prev();
  }

  nextMonth() {
    (this.$refs.calendar as any)?.next();
  }

  onClickCreate() {
    this.createBookingDto = new Booking({ partnerId: this.partnerId });

    this.isCreateDialogActive = true;
  }

  /**
   * Function fired on click of 'Details' button in the small card(after click on a booking in the calendar)
   * here we don't need the booking details(separate api call), but can use the booking item from the calendar
   */
  onEventClick(event: Partial<BookingCalendarEvent>) {
    this.selectedBooking = event;
    this.selected = this.selectedBooking.booking;
  }

  onEventContextMenu(event: { event: Partial<BookingCalendarEvent> }) {
    this.$log.debug(event);
  }

  onTimeClick(event: any) {
    this.$log.debug(event);
  }

  onTimeContextMenu(event: any) {
    this.$log.debug(event);

    this.createBookingDto.setNearest30TimeString(event.time);

    this.$log.debug(new Date(this.createBookingDto.start), new Date(this.createBookingDto.end));

    this.isCreateDialogActive = true;
  }

  async onDetailClick(booking: IBooking) {
    await this.onRowClick({ booking });
  }

  async onClickUpcoming(event: BookingCalendarEvent) {
    await this.onRowClick({ booking: event.booking });
  }

  /** Opens the side-card with booking details + edit functionality */
  async onRowClick({ booking }: { booking: IBooking }) {
    /** Booking to pass to an outer component */
    this.selected = booking;
    this.$emit("click", booking);
  }

  async createBooking() {
    this.isCreateDialogLoading = true;

    if (!this.createBookingDto) {
      this.$toast.error(this.$t("views.booking.BookingTable.noBookingCreated"));

      return;
    }

    try {
      await this.createBookingDto.create(false);
      this.$toast.success("👍");

      this.$emit("reload");
      this.isCreateDialogActive = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateDialogLoading = false;
    }
  }

  clearSelectedEvent() {
    this.selectedBooking = null;
  }
}
