








































import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { simpleDate } from "@/lib/utility/date-helper";
import { FormEntity } from "@/models/formEntity";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import FilterCard from "@/components/filter/FilterCard.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";
import { getDateFilterList } from "@/lib/utility/filter";

@Component({
  components: {
    FilterCard
  }
})
export default class PartnerFormList extends DarkModeHighlightMixin {
  search = "";

  get form() {
    return PartnerModule.form;
  }

  get forms() {
    return PartnerModule.forms;
  }

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  isActive(item: any) {
    return item._id === PartnerModule.form._id ? "v-item--active v-list-item--active" : "";
  }

  date(item: FormEntity) {
    if (item.timestamps && item.timestamps.created) {
      return simpleDate(item.timestamps?.created);
    }

    return simpleDate(Date.now().toString());
  }

  async mounted() {
    if (PartnerModule.forms.length > 0) {
      await PartnerModule.getFormByIdForPartner(PartnerModule.forms[0]._id);
    } else {
      PartnerModule.setForm({} as FormEntity);
    }
  }

  set(item: any) {
    PartnerModule.getFormByIdForPartner(item._id);

    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.$router.push({ name: "PartnerFormsDetailView", params: { formId: item._id } });
    }
  }

  filterList: IFilterListElement[] = getDateFilterList("timestamps");

  items = PartnerModule.forms.slice();

  get filteredItems() {
    return this.items;
  }
}
