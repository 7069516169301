import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IHandover, Handover } from "@/models/handover.entity";
import handoverService from "@/services/mrfiktiv/services/handoverService";
import { MrfiktivHandoverControllerFindAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";

export const HandoverPageDataProvider = new (class extends AbstractPageDataProvider<
  IHandover,
  MrfiktivHandoverControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivHandoverControllerFindAllParamsGen): Promise<IPageViewModel<IHandover>> {
    const res = await handoverService.findAll(query);

    const meta = res.meta;
    const data = (res.data ?? []).map(handover => new Handover(handover));

    return { meta, data };
  }
})();
