var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.$t('anonymization.title'),
      "actions": _vm.actions
    },
    on: {
      "actionClicked": _vm.processAction
    }
  }), _c('anonymization-table', {
    ref: "credentialTable",
    attrs: {
      "partnerId": _vm.partnerId,
      "loadingPartnerUsers": _vm.loadingPartnerUsers
    }
  })], 1), _c('create-anonymization-dialog', {
    attrs: {
      "isDialogActive": _vm.dialog
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      }
    }
  }), _c('api-anonymization-dialog', {
    attrs: {
      "isDialogActive": _vm.apiDialog
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.apiDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.apiDialog = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }