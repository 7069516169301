




















import { mixins } from "vue-class-component";
import { Prop, Component, Vue } from "vue-property-decorator";
import CostTable from "@/components/cost/CostTable.vue";
import CostChip from "@/components/cost/CostChip.vue";
import { PartnerModule } from "@/store/modules/partner";
import { CostModule } from "@/store/modules/cost.store";
import { PageFilterElement, IPageFilterElement } from "@/models/page-filter-element.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { CostTypeEnum } from "@/lib/enum/cost-type.enum";
import { ICost } from "@/models/cost.entity";

@Component({
  components: {
    CostTable,
    CostChip
  }
})
export default class FleetTicketTable extends mixins(Vue) {
  @Prop({
    default: () => [
      new PageFilterElement({
        key: "refs.refType",
        operation: "$eq",
        value: BackendResourceEnum.VEHICLE
      })
    ]
  })
  filters!: IPageFilterElement[];

  readonly CostTypeEnum = CostTypeEnum;

  partnerId = this.$route.params.partnerId ?? PartnerModule.partner.id;

  selectedItems: ICost[] = [];

  beforeMount() {
    CostModule.setFilters(this.filters);
  }

  get total() {
    let total = 0;

    let costs = CostModule.filtered;
    if (this.selectedItems.length > 0) costs = this.selectedItems;
    for (const item of costs) {
      if (item.expenseOrIncome === CostTypeEnum.EXPENSE) total -= item.absoluteTotal;
      else total += item.absoluteTotal;
    }

    return total;
  }
}
