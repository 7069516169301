var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('partner-manage-stepper-step-layout', {
    attrs: {
      "title": _vm.settingKey,
      "currentStep": _vm.currentStep,
      "totalSteps": _vm.totalSteps,
      "rightButtonFunction": _vm.endStep,
      "rightButtonDisabled": !_vm.valid
    },
    scopedSlots: _vm._u([{
      key: "options",
      fn: function fn() {
        return [_vm.isDeleteOptionAvailable ? _c('partner-manage-delete-setting', {
          attrs: {
            "onDelete": _vm.onDelete
          }
        }) : _vm._e(), _vm.isEditOptionAvailable ? _c('partner-manage-edit-setting', {
          attrs: {
            "editActive": _vm.editActive
          },
          on: {
            "update:editActive": function updateEditActive($event) {
              _vm.editActive = $event;
            },
            "update:edit-active": function updateEditActive($event) {
              _vm.editActive = $event;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_c('v-form', {
    staticClass: "mr-2 ml-2 mt-6",
    staticStyle: {
      "width": "100%"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.endStep.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [!_vm.items ? _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "label": _vm.$t('components.partner.PartnerManageDetailCard.value'),
      "disabled": _vm.isInputDisabled,
      "outlined": "",
      "data-test-input-predefined-setting-value": ""
    },
    model: {
      value: _vm.editValue,
      callback: function callback($$v) {
        _vm.editValue = $$v;
      },
      expression: "editValue"
    }
  }) : _c('v-select', {
    attrs: {
      "items": _vm.items,
      "rules": _vm.rules,
      "label": _vm.$t('components.partner.PartnerManageDetailCard.value'),
      "disabled": _vm.isInputDisabled,
      "outlined": "",
      "data-test-input-predefined-setting-value": ""
    },
    model: {
      value: _vm.editValue,
      callback: function callback($$v) {
        _vm.editValue = $$v;
      },
      expression: "editValue"
    }
  }), _vm.editActive || !_vm.isPreloaded || _vm.isDeleted ? _c('v-checkbox', {
    staticClass: "mt-n3",
    attrs: {
      "disabled": _vm.isInputDisabled || _vm.forceEncrypt,
      "label": _vm.$t('components.partner.PartnerManageDetailCard.setEncryption')
    },
    model: {
      value: _vm.editIsEncrypted,
      callback: function callback($$v) {
        _vm.editIsEncrypted = $$v;
      },
      expression: "editIsEncrypted"
    }
  }) : _vm._e()], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }