var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.title ? _c('v-card-title', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('v-card', {
    staticClass: "p-0",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-list', {
    staticClass: "p-0",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.activeActions, function (action, index) {
    return _c('span', {
      key: action.key
    }, [_c('v-list-item', {
      key: action.key,
      attrs: {
        "dense": "",
        "disabled": action.disabled
      },
      on: {
        "click": function click($event) {
          return _vm.actionClicked(action);
        }
      }
    }, [_c('v-list-item-avatar', {
      staticClass: "square-avatar",
      attrs: {
        "color": action.color || 'grey',
        "size": 28
      }
    }, [action.icon ? _c('v-icon', {
      attrs: {
        "dark": "",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(action.icon) + " ")]) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(action.text) + " ")])], 1), action.disabled ? _c('v-list-item-action', {
      staticClass: "action-content"
    }, [_c('span', {
      staticClass: "item-count mr-7"
    }, [_c('v-icon', {
      attrs: {
        "small": "",
        "disabled": ""
      }
    }, [_vm._v("mdi-lock")])], 1)]) : _c('v-list-item-action', {
      staticClass: "action-content"
    }, [_c('span', {
      staticClass: "item-count"
    }, [_vm._v(_vm._s(action.count) + " "), _c('v-icon', [_vm._v("mdi-chevron-right")])], 1)])], 1), index < _vm.activeActions.length - 1 ? _c('v-divider', {
      attrs: {
        "inset": ""
      }
    }) : _vm._e()], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }