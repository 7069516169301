var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pa-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "justify": "right",
      "align": "right"
    }
  }, [_c('analytics-date-range-selector-dialog', {
    attrs: {
      "dates": _vm.dates
    },
    on: {
      "setDate": _vm.setDateRange
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('statistics-card-row', {
    attrs: {
      "data": _vm.rowData,
      "loading": _vm.loading
    }
  }), _c('time-series-line-chart-card', {
    attrs: {
      "loading": _vm.dataHandler.trendAnalysisDataForReportControl.isLoading,
      "data": _vm.trendData,
      "title": "views.portal.ReportControlAnalyticsDashboard.trendAnalysis"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "sm": "12"
    }
  }, [_c('bar-chart-card', {
    attrs: {
      "loading": _vm.dataHandler.forwardedReportsPerPartner.isLoading,
      "data": _vm.forwardedReportsPerPartnerData,
      "title": "views.portal.ReportControlAnalyticsDashboard.reportCountDistribution"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }