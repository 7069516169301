














import { IVehicle } from "@/models/vehicle.entity";

import { Component, Prop, Vue } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: {}
})
export default class VehicleSelection extends Vue {
  @Prop()
  items!: IVehicle[];

  @Prop()
  value!: string;

  @Prop({
    default: () => [requiredRule()]
  })
  rules!: Function[];

  get selected() {
    return this.value;
  }

  set selected(value: string) {
    this.$emit("input", value);
  }

  change(v: IVehicle) {
    this.$emit("change", v);
  }
}
