



















import { Component, Prop, Vue } from "vue-property-decorator";
import ExcelImportCreate from "../utility/ExcelImportCreate.vue";
import { CreateContractDto, ICreateContractDto } from "@/models/create-contract.dto";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { downloadAsXlsx, getTranslationsFromEntity } from "@/lib/download-as-xlsx";
import { ContractTypeEnum } from "@/lib/enum/contractType.enum";

@Component({
  components: { ExcelImportCreate }
})
export default class FleetContractImportDialog extends Vue {
  @Prop()
  outlined!: boolean;

  @Prop()
  small!: boolean;

  @Prop()
  partnerId!: string;

  readonly createDto = CreateContractDto;

  get baseData() {
    return {
      partnerId: this.$route.params.partnerId
    };
  }

  templateDownload() {
    const downloadables: (ICreateContractDto & {
      vehicleDisplayName: string;
    })[] = VehicleModule.paginationList.map(
      vehicle =>
        new CreateContractDto({
          buybackPrice: "" as any,
          contractType: ContractTypeEnum.LEASING,
          endDate: "",
          startDate: "",
          title: "",
          description: "",
          number: "",
          currency: "" as any,
          startMilage: "" as any,
          maxMilage: "" as any,
          maxReimbursedMilage: "" as any,
          creditForReimbursedMilage: "" as any,
          freeMileageAllowance: "" as any,
          excessMileageCosts: "" as any,
          purchasePrice: "" as any,
          financingCost: "" as any,
          fuelCost: "" as any,
          insuranceCost: "" as any,
          miscCost: "" as any,
          tiresCost: "" as any,
          partnerId: vehicle.partnerId,
          vehicleId: vehicle.id,
          vehicleDisplayName: vehicle.displayName
        })
    );

    downloadAsXlsx(downloadables, getTranslationsFromEntity(CreateContractDto));
  }
}
