
























































import { Component, Prop } from "vue-property-decorator";
import PartnerManageStepperMixin from "@/mixins/PartnerManageStepperMixin.vue";
import PartnerManageStepperStepLayout from "@/components/partner/PartnerManageStepperStepLayout.vue";
import PartnerManageDeleteSetting from "@/components/partner/PartnerManageDeleteSetting.vue";
import PartnerManageEditSetting from "@/components/partner/PartnerManageEditSetting.vue";
import { SettingModule } from "@/store/modules/setting.store";
import { requiredRule } from "@/lib/rules/requiredRule";
import { valueRule } from "@/lib/rules/valueRule";
import { ManageStepperSetupSetting } from "@/lib/interfaces/partner/partnerManageStepperSetupSetting.interface";
import { ManageStepperEnum } from "@/store/enum/partner/manage-stepper.enum";

/**
 * Step containing all settings relevant for setup process
 * setupSettings contains list of relevant settings
 * context determines what setup is for (da, ksr,... ).
 * to create a new step make sure that the SettingModule has the required functions like "set{Da/Ksr}SetupInformation" and adapt the resetSetupInformation and endStep methods
 */
@Component({
  components: {
    PartnerManageStepperStepLayout,
    PartnerManageDeleteSetting,
    PartnerManageEditSetting
  }
})
export default class PartnerManageStepperSetupStep extends PartnerManageStepperMixin {
  /**
   * List of settings that are asked for in setup step
   */
  @Prop()
  setupSettings!: ManageStepperSetupSetting[];

  /**
   * determines what setup is for. in order to know what to do with entered settings
   * e.g. ksr/ da ...
   */
  @Prop()
  context!: ManageStepperEnum;

  /**
   * is form valid/ are all settings set correctly
   */
  valid = false;

  /**
   * copy of settings list (for editing purposes)
   */
  settings = this.setupSettings;

  /**
   * removes setting value locally and makes it editable
   */
  handleEdit(key: string) {
    this.settings.forEach(setting => {
      if (setting.key === key) {
        setting.isFound = false;
        setting.value = "";
      }
    });
  }

  /**
   * load setting values from backend
   */
  async loadSettings() {
    //Load settings from backend
    this.setLoading(true);
    for (const setting of this.settings) {
      SettingModule.clearPartnerSetting();
      await SettingModule.getSettingByKey({ key: setting.key });
      const index = this.settings.findIndex(s => s.key === setting.key);
      if (SettingModule.partnerSetting && SettingModule.partnerSetting.key) {
        this.settings.splice(index, 1, {
          label: setting.label,
          key: setting.key,
          value: SettingModule.partnerSetting.isEncrypted ? "" : SettingModule.partnerSetting.value || "",
          isFound: !SettingModule.partnerSetting.isEncrypted,
          rules: setting.rules,
          isPassword: setting.isPassword,
          description: setting.description
        });
      } else {
        this.settings.splice(index, 1, {
          label: setting.label,
          key: setting.key,
          value: "",
          isFound: false,
          rules: setting.rules,
          isPassword: setting.isPassword,
          description: setting.description
        });
      }
    }
    this.setLoading(false);
  }

  /**
   * prefill settings
   */
  async beforeMount() {
    await this.loadSettings();
  }

  /**
   * save settings
   */
  endStep() {
    switch (this.context) {
      case ManageStepperEnum.DA: {
        SettingModule.setDaSetupInformation({
          username: this.settings[0].value,
          password: this.settings[1].value,
          group: this.settings[2].value,
          center: this.settings[3].value
        });
        break;
      }
      case ManageStepperEnum.DA2: {
        SettingModule.setDaSetupInformation({
          username: SettingModule.daSetupInformation.username,
          password: SettingModule.daSetupInformation.password,
          center: SettingModule.daSetupInformation.center,
          group: SettingModule.daSetupInformation.group,
          resource: Number(this.settings[0].value),
          eventType: Number(this.settings[1].value),
          workshopService: Number(this.settings[2].value)
        });
        break;
      }
      case ManageStepperEnum.KSR: {
        SettingModule.setKsrSetupInformation({
          customerOsaId: this.settings[0].value,
          companyId: this.settings[1].value
        });
        break;
      }
      case ManageStepperEnum.PDR: {
        SettingModule.setPdrSetupInformation({
          email: this.settings[0].value,
          password: this.settings[1].value
        });
        break;
      }

      default: {
        this.$log.error("setup steps not handled. unknown value for context: " + this.context);
      }
    }

    this.goToNext();
  }

  /**
   * reset settings/ load them from backend
   */
  async reset() {
    SettingModule.resetSetupInformation(this.context);
    await this.loadSettings();
  }

  /**
   * Returns rules for value input field
   */
  get valueRules() {
    return [requiredRule(), valueRule()];
  }
}
