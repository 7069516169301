/**
 * Enum to decide for which document to get base analytics: count
 */
export enum ServiceEnum {
  formService = "form",
  reportService = "report",
  imageService = "image",
  userService = "user",
  partnerService = "partner",
  newsletterService = "newsletter",
  appraisalService = "appraisal",
  vehicleService = "vehicle",
  ticketService = "ticket",
  costService = "cost",
  projectService = "project",
  documentService = "document",
  bookingService = "booking",
  customerAccountService = "customerAccount"
}
