/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { MrfiktivContactDocumentGen, MrfiktivCreateDriverDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

/**
 * Creates a driver
 */
export class CreateDriver implements MrfiktivCreateDriverDtoGen {
  /**
   * @inheritdoc
   */
  firstName: string;

  /**
   * @inheritdoc
   */
  lastName: string;

  /**
   * @inheritdoc
   */
  startDate: string = new Date().toISOString();

  /**
   * @inheritdoc
   */
  endDate?: string;

  /**
   * @inheritdoc
   */
  internalId?: string;

  /**
   * @inheritdoc
   */
  contact?: MrfiktivContactDocumentGen;

  /**
   * @inheritdoc
   */
  driverLicenseCheck?: boolean = false;

  /**
   * @inheritdoc
   */
  driverUvvCheck?: boolean = false;

  /**
   * Creates the object
   * @class
   */
  constructor(firstName: string, lastName: string) {
    this.firstName = firstName;
    this.lastName = lastName;
  }
}
