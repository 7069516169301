















































import PartnerAnalyticsDashboard from "@/components/analytics/PartnerAnalyticsDashboard.vue";
import OrganizationAnalyticsDashboard from "@/components/analytics/OrganizationAnalyticsDashboard.vue";
import ReportControlAnalyticsDashboard from "@/components/analytics/ReportControlAnalyticsDashboard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { FeatureModule } from "@/store/modules/feature.store";
import { UserModule } from "@/store/modules/me-user.store";
import { handleError } from "@/lib/utility/handleError";
import AdminCountAnalytics from "@/components/analytics/AdminCountAnalytics.vue";

@Component({
  components: {
    AdminCountAnalytics,
    PartnerAnalyticsDashboard,
    OrganizationAnalyticsDashboard,
    ReportControlAnalyticsDashboard,
    TheLayoutPortal
  }
})
export default class PartnerAnalyticsHome extends PartnerFallbackMixin {
  loading = true;
  tab = 0;
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  /**
   * Display the multi partner analytics only if enabled and user has more then one partner to analyze.
   */
  get isOrganizationAnalyticsEnabled() {
    return FeatureModule.isMultipartnerAnalyticsEnabled && UserModule.userAnalyticsPartnerIds.length > 1;
  }

  async mounted() {
    this.loading = true;

    try {
      await this.trySetByRouteOrDefault();
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
