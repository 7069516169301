




















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { TranslateResult } from "vue-i18n";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class StatisticsSparkLineCard extends DarkModeHighlightMixin {
  /**
   * The item to calculate statistics for.
   */
  @Prop()
  items!: any;

  /**
   * The number of month back to display in statistics.
   */
  @Prop({ default: 7 })
  numberOfMonth!: number;

  /**
   * Return the lasts month count but in reversed order so that the current month is the last.
   */
  get lastMonthCount() {
    const counts = this.countByYearAndMonth();

    const values = [];
    for (const yearMonth of this.lastYearAndMonthString) {
      values.push(counts[yearMonth] || 0);
    }

    return values.reverse();
  }

  /**
   * Count the items provided by the prop by year and month.
   */
  public countByYearAndMonth() {
    const counts = {};

    for (const item of this.items) {
      if (item?.timestamps) {
        const yearMonth = item?.timestamps?.created.substr(0, 7);
        counts[yearMonth] = counts[yearMonth] ? counts[yearMonth] + 1 : 1;
      } else if (item?.timestamp) {
        const yearMonth = item?.timestamp?.created.substr(0, 7);
        counts[yearMonth] = counts[yearMonth] ? counts[yearMonth] + 1 : 1;
      }
    }

    return counts;
  }

  /**
   * Get the last year and month based on prop this.numberOfMonth.
   *
   * @returns ['2022-01']
   */
  get lastYearAndMonthString() {
    const yearMonth = [];
    for (let i = 0; i < this.numberOfMonth; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      yearMonth.push(date.toISOString().substr(0, 7)); // '2022-01'
    }

    return yearMonth;
  }

  /**
   * Get the translate results as labels for the past last month but in reversed order so that the current month is last based on prop this.numberOfMonth.
   *
   * @returns translated array e.g. ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]
   */
  get lastMonthLabels(): TranslateResult[] {
    // Dates and arrays start at 0. As January is on 0st position in the array but first month of the year...
    const month = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    const labels = [];
    for (let i = 0; i < this.numberOfMonth; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      labels.push(this.$t(`utility.month.short.${month[date.getMonth()]}`));
    }

    return labels.reverse();
  }

  /**
   * Get the colors for the spark line.
   */
  get sparkLineColor() {
    return this.$vuetify.theme.dark ? "grey lighten-1" : "grey darken-1";
  }
}
