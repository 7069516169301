




















import PricingCard, { IFeature, IPricing } from "@/components/presales/PricingCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { deepCopy } from "@/lib/utility/deep-copy";
import { ConfigModule } from "@/store/modules/config";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    PricingCard
  }
})
export default class Pricing extends Vue {
  currency = "EUR";

  starterFeatures: IFeature[] = [
    {
      name: "Schadensmanagement",
      subtitle:
        "Unser digitales Schadensmanagement optimiert alle Prozesse rund um den Schadensfall. Ob als Werkstatt oder Fuhrpark - unsere Lösung ist auf Ihren Anwendungsfall anpassbar. Explizit für den Schadensfall zugeschnittene Formulare und Prozessschritte helfen Ihnen bei der Abwicklung von Schadensfällen.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/digitales-schadensmanagement/"
    },
    {
      name: "Service- und Wartungsmanagement",
      subtitle:
        "Verpassen Sie keinen Service- oder Wartungstermin mehr. Legen Sie z. B. Erinnerungen für HU- & AU-Termine oder einen Reifenwechsel an. Unser Tool lässt sich problemlos an Ihre Bedürfnisse anpassen.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/wartungs-und-servicemanagement/"
    },
    {
      name: "Fuhrparkmanagement",
      subtitle:
        "Sie haben jederzeit einen Einblick in den aktuellen Zustand Ihres Fuhrparks. Dank eines übersichtlichen Dashboards behalten Sie alle TO-DOs sowie die Fuhrparkkosten im Blick. In der Fahrzeugakte finden Sie alle Informationen zu Ihrem entsprechenden Fahrzeug. Von Schadensfällen über Servicetermine bis hin zu Leasingverträgen - hier erhalten Sie alle Informationen, ohne lange suchen zu müssen.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/digitales-fuhrparkmanagement/"
    },
    {
      name: "Aufgaben- und Prozessmanagement",
      subtitle:
        "Nutzen Sie unser Projektmanagement-Tool, um Aufgaben zu verwalten und Prozessschritte zu definieren. Dank benutzerdefinierter Felder ist unser Tool an Ihren Anwendungsfall anpassbar.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/aufgaben-und-prozessmanagement/"
    },
    {
      name: "Nachrichten und E-Mail Vorlagen",
      subtitle:
        "Erstellen Sie E-Mail-Vorlagen und schreiben Sie E-Mails ab jetzt nur noch einmal. Dank Platzhaltern können Sie E-Mails personalisieren und so eine gezielte Ansprache sicherstellen",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/nachrichten-und-emailvorlagen/"
    },
    {
      name: "Digitale Terminbuchung",
      subtitle:
        "Die digitale Terminbuchung bietet Unternehmen und Kunden die Möglichkeit, Termine schnell und bequem online zu vereinbaren. Durch die 24/7-Verfügbarkeit und automatisierten Erinnerungen sparen sowohl Kunden als auch Unternehmen Zeit und reduzieren Fehler bei der Terminplanung. Dies steigert die Effizienz, verbessert die Kundenerfahrung und fördert die Kundenbindung.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/digitale-terminbuchung/"
    },
    {
      name: "Digitale Dokumentenverwaltung",
      subtitle:
        "Laden Sie Rechnungen, Kostenvoranschläge und andere Dokumente direkt hoch und verknüpfen Sie diese mit Fahrzeugen, Schadensmeldungen, Terminen und Aufgaben.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "https://www.schadensmeldung.digital/funktionen/digitale-dokumentenverwaltung/"
    },
    {
      name: "Kollaboratives Arbeiten",
      subtitle:
        "Arbeiten Sie gemeinsam mit Ihrem Team an Schadensfällen, Aufgaben und weiteren Themen und profitieren Sie von einer Steigerung der Effizienz und Qualität. Geben Sie Nutzern unterschiedliche Berechtigungen und verwalten Sie das Team.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/kollaboratives-arbeiten/"
    }
  ];

  basicFeatures: IFeature[] = [
    {
      name: "Verknüpfte digitale Schadensakte",
      subtitle:
        "Geben Sie gezielt Zugriff auf Schadensakten. Mit teilbaren Links können Sie Schadensfälle im Handumdrehen an Werkstatt oder Versicherung übermitteln, ohne lästige Anhänge versenden zu müssen.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/verknuepfte-digitale-schadensakte/"
    },
    {
      name: "Digitale Signatur",
      subtitle:
        "Machen Sie Ihre Geschäfte schneller, einfacher und kosteneffizienter, indem Sie unsere digitale Signatur für das Signieren von Dokumenten nutzen.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: "",
      link: "https://www.schadensmeldung.digital/funktionen/digitale-signatur/"
    }
  ];

  premiumFeatures: IFeature[] = [
    {
      name: "Finanzmanagement",
      subtitle:
        "Behalten Sie den Überblick über Ihre Finanzen. Ob als Fuhrparkmanager oder Werkstattleiter – mit unserer Lösung können Sie sowohl Ausgaben als auch Einnahmen nahtlos verwalten und analysieren. Für Fuhrparks sind es die Kosten, für Werkstätten der Umsatz – wir bieten Ihnen die passende Lösung, um finanzielle Prozesse zu optimieren und transparent zu gestalten.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: ""
    },
    {
      name: "Monatlicher Social Media Post",
      subtitle:
        "Betreuen Sie mehrere Fuhrparks von Unternehmen oder nutzen Sie unsere Schadensmeldung als Werkstatt? Wir unterstützen Sie dabei, nachhaltig in den sozialen Medien präsent zu sein, indem wir Ihnen monatlich interessante Social-Media-Beiträge zur Verfügung stellen.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: ""
    },
    {
      name: "API-Zugriff",
      subtitle: "Nutzen Sie unsere Schnittstelle und binden Sie unser Lösung nahtlos in Ihre Prozesse ein.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: ""
    },
    {
      name: "Prozessoptimierung",
      subtitle:
        "Wir schauen uns Ihre Prozesse an und beraten Sie individuell zum optimalen Einsatz unserer Lösung. Wenn notwendig entwickeln wir Ihr individuelles Modul - denn individuelle Anforderungen verdienen individuelle Software.",
      color: ConfigModule.color.secondary,
      icon: "mdi-check-circle",
      description: ""
    }
  ];

  get starter(): IPricing {
    const features: IFeature[] = [];
    this.addFeatures(features, this.starterFeatures, true);
    this.addFeatures(features, this.basicFeatures, false);
    this.addFeatures(features, this.premiumFeatures, false);

    return {
      title: "Starter",
      subtitle: "Perfekt für den ersten Schritt zur Digitalisierung Ihres Fuhrparks!",
      color: ConfigModule.color.secondary,
      features: features,
      price: 69.0,
      vehiclePrice: 2.49,
      amountUsers: 5,
      amountVehicles: 15
    };
  }

  get basic(): IPricing {
    const features: IFeature[] = [];
    this.addFeatures(features, this.starterFeatures, true);
    this.addFeatures(features, this.basicFeatures, true);
    this.addFeatures(features, this.premiumFeatures, false);

    return {
      title: "Basic",
      subtitle: "Alles in einem – individuell und schnell im Einsatz in jeder Industrie.",
      color: ConfigModule.color.primary,
      features: features,
      price: 89.0,
      vehiclePrice: 2.99,
      amountUsers: 10,
      amountVehicles: 25
    };
  }

  get premium(): IPricing {
    const features: IFeature[] = [];
    this.addFeatures(features, this.starterFeatures, true);
    this.addFeatures(features, this.basicFeatures, true);
    this.addFeatures(features, this.premiumFeatures, true);

    return {
      title: "Premium",
      subtitle: "Perfekt, wenn Fuhrparkunternehmen Ihre Kunden sind!",
      color: "#212529",
      features: features,
      price: 99.0,
      vehiclePrice: 3.1,
      amountUsers: 10,
      amountVehicles: 25
    };
  }

  addFeatures(features: IFeature[], addFeatures: IFeature[], enabled: boolean) {
    for (const feature of addFeatures) {
      const featureToAdd = deepCopy(feature);

      if (!enabled) {
        featureToAdd.color = "#D3D3D3";
        featureToAdd.icon = "mdi-minus-circle";
      }

      features.push(featureToAdd);
    }
    return features;
  }

  mounted() {
    const currency = this.$route.query["currency"];

    if (currency === "CHF") {
      this.currency = "CHF";
    }
  }

  toContactSales() {
    const url = this.$route.query["redirect"];

    if (url) {
      window.open("https://" + url, "_blank");
    }

    window.open("https://www.schadensmeldung.digital", "_blank");
  }
}
