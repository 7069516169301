












import { Component, Prop } from "vue-property-decorator";
import PartnerManageStepperMixin from "@/mixins/PartnerManageStepperMixin.vue";
import PartnerManageStepperStepLayout from "@/components/partner/PartnerManageStepperStepLayout.vue";

@Component({
  components: { PartnerManageStepperStepLayout }
})
export default class PartnerManageStepperDescriptionStep extends PartnerManageStepperMixin {
  /**
   * Type of description
   * determines which text is chosen from locales file and displayed as title and description
   */
  @Prop()
  descriptionType!: string;

  mounted() {
    this.setLoading(false);
  }
}
