







import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { simpleDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IPartnerUser, PartnerUser } from "@/models/user.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import PartnerUserDetail from "./PartnerUserDetail.vue";

@Component({
  components: {
    LayoutSimple,
    PartnerUserDetail
  },
  filters: {
    simpleDate,
    getFlagEmoji
  }
})
export default class PartnerUserDetailView extends mixins(PaginationListVueMixin, PartnerFallbackMixin) {
  isLoadingDetail = false;

  user: IPartnerUser | null = null;

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get activitySource(): MrfiktivReferenceGen | undefined {
    if (!this.user) {
      return undefined;
    }

    return {
      refType: BackendResourceEnum.USER,
      refId: this.user.id
    };
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    try {
      this.isLoadingDetail = true;
      this.user = await new PartnerUser({ ...{ id: this.$route.params.userId }, partnerId: this.partnerId })
        .fetch()
        .then(user => user.fetchStatus());
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingDetail = false;
    }
  }

  goToAdminUser() {
    const location: RawLocation = {
      name: "UserAdministration",
      query: { userId: this.user?._id }
    };
    new GoToHelper(this.$router).go(location);
  }
}
