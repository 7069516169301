/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivAnalyticsControllerBookedServiceDistributionParamsGen,
  MrfiktivAnalyticsControllerCoundAndGroupByPartnerParamsGen,
  MrfiktivAnalyticsControllerCountParamsGen,
  MrfiktivAnalyticsControllerCountPerPartnerParamsGen,
  MrfiktivAnalyticsControllerGetForwardedReportCountsByPartnerParamsGen,
  MrfiktivAnalyticsControllerGetTotalForwardedReportCountParamsGen,
  MrfiktivAnalyticsControllerMultiplePartnersGetCountReportsParamsGen,
  MrfiktivAnalyticsControllerMultiplePartnersGetStatusDistributionParamsGen,
  MrfiktivAnalyticsControllerMultiplePartnersGetTrendAnalyticsParamsGen,
  MrfiktivAnalyticsControllerPartnerDistributionParamsGen,
  MrfiktivAnalyticsControllerReportHourAnalysisPerPartnerParamsGen,
  MrfiktivAnalyticsControllerReportWeekdayAnalysisPerPartnerParamsGen,
  MrfiktivAnalyticsControllerStatusDistributionForPartnerParamsGen,
  MrfiktivAnalyticsControllerStatusDistributionParamsGen,
  MrfiktivAnalyticsControllerTrendAnalysisForForwardedReportsParamsGen,
  MrfiktivAnalyticsControllerTrendAnalysisForPartnerParamsGen,
  MrfiktivAnalyticsCountGen,
  MrfiktivAverageTrendAnalysisViewmodelGen,
  MrfiktivBaseChartItemViewmodelGen,
  MrfiktivBaseTrendAnalysisViewmodelGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen,
  MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen,
  MrfiktivMultiplePartnersFindManyIdsForDateRangeDtoGen,
  MrfiktivPartnerAnalysisViewmodelGen,
  MrfiktivPartnersPerBookedServiceViewmodelGen,
  MrfiktivReportHourAnalysisPerPartnerViewmodelGen,
  MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen,
  MrfiktivStatusDistributionAnalyticsViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Analytics<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerCount
   * @summary Count the number of Documents in a specific Collection.
   * @request GET:/analytics/count
   * @secure
   * @response `200` `MrfiktivAnalyticsCountGen` The found record
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerCount = (query: MrfiktivAnalyticsControllerCountParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivAnalyticsCountGen, MrfiktivExceptionViewmodelGen | void>({
      path: `/analytics/count`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerCoundAndGroupByPartner
   * @summary Count the number of Documents in a specific Collection grouped by partners if possible.
   * @request GET:/analytics/count/group
   * @secure
   * @response `200` `(MrfiktivBaseChartItemViewmodelGen)[]` The found record
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerCoundAndGroupByPartner = (
    query: MrfiktivAnalyticsControllerCoundAndGroupByPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivBaseChartItemViewmodelGen[], MrfiktivExceptionViewmodelGen | void>({
      path: `/analytics/count/group`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerStatusDistribution
   * @summary Returns the aggregation of status distribution
   * @request GET:/analytics/statusDistribution
   * @secure
   * @response `200` `MrfiktivStatusDistributionAnalyticsViewModelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerStatusDistribution = (
    query: MrfiktivAnalyticsControllerStatusDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivStatusDistributionAnalyticsViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/analytics/statusDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerPartnerDistribution
   * @summary Returns the distribution of partners
   * @request GET:/analytics/partnerDistribution
   * @secure
   * @response `200` `MrfiktivPartnerAnalysisViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerPartnerDistribution = (
    query: MrfiktivAnalyticsControllerPartnerDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerAnalysisViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/analytics/partnerDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerBookedServiceDistribution
   * @summary Returns the distribution of booked services
   * @request GET:/analytics/bookedServiceDistribution
   * @secure
   * @response `200` `MrfiktivPartnersPerBookedServiceViewmodelGen` Distribution of the booked service type
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerBookedServiceDistribution = (
    query: MrfiktivAnalyticsControllerBookedServiceDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnersPerBookedServiceViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/analytics/bookedServiceDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerCountPerPartner
   * @summary Count the number of Documents in a specific Collection for a given partner.
   * @request GET:/partner/{partnerId}/analytics/count
   * @secure
   * @response `200` `MrfiktivAnalyticsCountGen` The found record
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerCountPerPartner = (
    { partnerId, ...query }: MrfiktivAnalyticsControllerCountPerPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAnalyticsCountGen, MrfiktivExceptionViewmodelGen | void>({
      path: `/partner/${partnerId}/analytics/count`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerStatusDistributionForPartner
   * @summary Returns the aggregation of status distribution
   * @request GET:/partner/{partnerId}/analytics/statusDistribution
   * @secure
   * @response `200` `MrfiktivStatusDistributionAnalyticsViewModelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerStatusDistributionForPartner = (
    { partnerId, ...query }: MrfiktivAnalyticsControllerStatusDistributionForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivStatusDistributionAnalyticsViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/statusDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerTrendAnalysisForPartner
   * @summary Return partner trendanalysis for day, week and 30 days
   * @request GET:/partner/{partnerId}/analytics/trend
   * @secure
   * @response `200` `MrfiktivBaseTrendAnalysisViewmodelGen` Report trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerTrendAnalysisForPartner = (
    { partnerId, ...query }: MrfiktivAnalyticsControllerTrendAnalysisForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivBaseTrendAnalysisViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerTrendAnalysisForForwardedReports
   * @summary Return forwarded reports from the partner trend analysis for day, week and 30 days
   * @request GET:/partner/{partnerId}/analytics/report-control/trend
   * @secure
   * @response `200` `MrfiktivBaseTrendAnalysisViewmodelGen` Report trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerTrendAnalysisForForwardedReports = (
    { partnerId, ...query }: MrfiktivAnalyticsControllerTrendAnalysisForForwardedReportsParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivBaseTrendAnalysisViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/report-control/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerGetTotalForwardedReportCount
   * @summary Return the count of reports forwarded by a given partner.
   * @request GET:/partner/{partnerId}/analytics/report-control/count
   * @secure
   * @response `200` `MrfiktivAnalyticsCountGen` The found record
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerGetTotalForwardedReportCount = (
    { partnerId, ...query }: MrfiktivAnalyticsControllerGetTotalForwardedReportCountParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAnalyticsCountGen, MrfiktivExceptionViewmodelGen | void>({
      path: `/partner/${partnerId}/analytics/report-control/count`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerGetForwardedReportCountsByPartner
   * @summary Return count of forwarded reports broken down by partner.
   * @request GET:/partner/{partnerId}/analytics/report-control/count/byPartner
   * @secure
   * @response `200` `MrfiktivPartnerAnalysisViewmodelGen` Count of forwarded reports by partner
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerGetForwardedReportCountsByPartner = (
    { partnerId, ...query }: MrfiktivAnalyticsControllerGetForwardedReportCountsByPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerAnalysisViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/report-control/count/byPartner`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerReportWeekdayAnalysisPerPartner
   * @summary Returns the aggregation of reports on a weekly basis.
   * @request GET:/partner/{partnerId}/analytics/weekdayAnalysis
   * @secure
   * @response `200` `MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen` Distribution of the impact type
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerReportWeekdayAnalysisPerPartner = (
    { partnerId, ...query }: MrfiktivAnalyticsControllerReportWeekdayAnalysisPerPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportWeeklyAnalysisPerPartnerViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/weekdayAnalysis`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerReportHourAnalysisPerPartner
   * @summary Returns the aggregation of conversions per hour
   * @request GET:/partner/{partnerId}/analytics/hourAnalysis
   * @secure
   * @response `200` `MrfiktivReportHourAnalysisPerPartnerViewmodelGen` Distribution of the conversion per hour
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerReportHourAnalysisPerPartner = (
    { partnerId, ...query }: MrfiktivAnalyticsControllerReportHourAnalysisPerPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportHourAnalysisPerPartnerViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/analytics/hourAnalysis`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerMultiplePartnersGetStatusDistribution
   * @summary (DEPRECATED) Returns aggregation of report progress statuses for selected partners.
   * @request GET:/partner/analytics/statusDistribution
   * @deprecated
   * @secure
   * @response `200` `MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen` Progress status of reports for selected partners for selected time period.
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `413` `void` Error thrown if the requested ids exceed the limit.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerMultiplePartnersGetStatusDistribution = (
    query: MrfiktivAnalyticsControllerMultiplePartnersGetStatusDistributionParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen,
      void | MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/analytics/statusDistribution`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerMultiplePartnersStatusDistribution
   * @summary Returns aggregation of report progress statuses for selected partners.
   * @request POST:/partner/analytics/statusDistribution
   * @secure
   * @response `200` `MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen` Progress status of reports for selected partners for selected time period.
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerMultiplePartnersStatusDistribution = (
    data: MrfiktivMultiplePartnersFindManyIdsForDateRangeDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivMultiPartnerStatusDistributionAnalyticsViewModelGen,
      void | MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/analytics/statusDistribution`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerMultiplePartnersGetCountReports
   * @summary (DEPRECATED) Returns aggregation of created reports per partner
   * @request GET:/partner/analytics/countReports
   * @deprecated
   * @secure
   * @response `200` `MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen` Report documents count for selected partners for selected time period.
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `413` `void` Error thrown if the requested ids exceed the limit.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerMultiplePartnersGetCountReports = (
    query: MrfiktivAnalyticsControllerMultiplePartnersGetCountReportsParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/analytics/countReports`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerMultiplePartnersCountReports
   * @summary Returns aggregation of created reports per partner
   * @request POST:/partner/analytics/countReports
   * @secure
   * @response `200` `MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen` Report documents count for selected partners for selected time period.
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerMultiplePartnersCountReports = (
    data: MrfiktivMultiplePartnersFindManyIdsForDateRangeDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivMultiPartnerCountReportsAnalyticsViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/analytics/countReports`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerMultiplePartnersGetTrendAnalytics
   * @summary (DEPRECATED) Return trend analytics for selected partners for day, week and 30 days
   * @request GET:/partner/analytics/trend
   * @deprecated
   * @secure
   * @response `200` `MrfiktivBaseTrendAnalysisViewmodelGen` Report trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `413` `void` Error thrown if the requested ids exceed the limit.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerMultiplePartnersGetTrendAnalytics = (
    query: MrfiktivAnalyticsControllerMultiplePartnersGetTrendAnalyticsParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivBaseTrendAnalysisViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/analytics/trend`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerMultiplePartnersTrendAnalytics
   * @summary Return trend analytics for selected partners for day, week and 30 days
   * @request POST:/partner/analytics/trend
   * @secure
   * @response `200` `MrfiktivBaseTrendAnalysisViewmodelGen` Report trend data
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerMultiplePartnersTrendAnalytics = (
    data: MrfiktivMultiplePartnersFindManyIdsForDateRangeDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivBaseTrendAnalysisViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/analytics/trend`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags analytics
   * @name AnalyticsControllerMultiplePartnersAverageTrendAnalytics
   * @summary Return trend analytics displaying average reports for all selected partners in a day.
   * @request POST:/partner/analytics/averageTrend
   * @secure
   * @response `200` `MrfiktivAverageTrendAnalysisViewmodelGen` Report trend average data
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  analyticsControllerMultiplePartnersAverageTrendAnalytics = (
    data: MrfiktivMultiplePartnersFindManyIdsForDateRangeDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivAverageTrendAnalysisViewmodelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/partner/analytics/averageTrend`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
