var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', _vm._l(_vm.prevTickets, function (ticket, index) {
    return _c('v-card', {
      key: 'refs-ticket-vehicle-parsed-prev' + index + ticket.start + _vm.vehicleAggregation.vehicle.id,
      staticClass: "ma-2",
      attrs: {
        "outlined": ""
      }
    }, [ticket.data.length && ticket.data[0] ? _c('refs-ticket', {
      attrs: {
        "item": ticket.data[0]
      },
      on: {
        "openDetail": _vm.onOpenDetail
      }
    }) : _vm._e()], 1);
  }), 1), _c('div', _vm._l(_vm.currentTickets, function (ticket, index) {
    return _c('v-card', {
      key: 'refs-ticket-vehicle-parsed-curr' + index + ticket.start + _vm.vehicleAggregation.vehicle.id,
      staticClass: "ma-2",
      attrs: {
        "outlined": ""
      }
    }, [ticket.data.length && ticket.data[0] ? _c('refs-ticket', {
      attrs: {
        "item": ticket.data[0]
      },
      on: {
        "openDetail": _vm.onOpenDetail
      }
    }) : _vm._e()], 1);
  }), 1), _c('div', _vm._l(_vm.nextTickets, function (ticket, index) {
    return _c('v-card', {
      key: 'refs-ticket-vehicle-parsed-curr' + index + ticket.start + _vm.vehicleAggregation.vehicle.id,
      staticClass: "ma-2",
      attrs: {
        "outlined": ""
      }
    }, [ticket.data.length && ticket.data[0] ? _c('refs-ticket', {
      attrs: {
        "item": ticket.data[0]
      },
      on: {
        "openDetail": _vm.onOpenDetail
      }
    }) : _vm._e()], 1);
  }), 1), _vm._l(_vm.vehicleAggregation.ticketsWithoutDue, function (ticket, index) {
    return _c('v-card', {
      key: 'refs-ticket-vehicle-without-due' + index + ticket.number + _vm.vehicleAggregation.vehicle.id,
      staticClass: "ma-2",
      attrs: {
        "outlined": ""
      }
    }, [_c('refs-ticket', {
      attrs: {
        "item": ticket
      },
      on: {
        "openDetail": _vm.onOpenDetail
      }
    })], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }