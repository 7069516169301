var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-list', {
    attrs: {
      "title": _vm.$t('components.partner.PartnerReportList.title'),
      "filterList": _vm.filterList,
      "predefinedFilter": _vm.predefinedFilter,
      "loading": _vm.loading || _vm.isLoadingAll,
      "isLoadingMore": _vm.isLoadingMore,
      "getModule": _vm.getModule
    },
    on: {
      "loadMore": _vm.loadMore,
      "refresh": _vm.refresh
    },
    scopedSlots: _vm._u([{
      key: "options",
      fn: function fn() {
        return [_c('partner-report-initialize-dialog')];
      },
      proxy: true
    }, {
      key: "listItem",
      fn: function fn(_ref) {
        var _listItem$title;

        var listItem = _ref.listItem;
        return [_c('v-list-item', {
          staticClass: "item",
          class: "".concat(_vm.itemFunctions.isActive(listItem), " ").concat(listItem.progressStatus === _vm.ProgressStatusEnum.NEW ? 'font-weight-black' : ''),
          attrs: {
            "disabled": _vm.isLoadingDetail
          },
          on: {
            "click": function click($event) {
              return _vm.set(listItem);
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s((_listItem$title = listItem.title) !== null && _listItem$title !== void 0 ? _listItem$title : listItem.numberplate) + " ")]), _c('v-list-item-subtitle', {
          staticClass: "text--primary"
        }, [_vm._v(" " + _vm._s(listItem.customerName) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(listItem.message))])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', [_vm._v(_vm._s(_vm.itemFunctions.date(listItem)))]), _c('span', [_c('tooltip', {
          attrs: {
            "text": _vm.itemFunctions.getExportReportMetaType(listItem)
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v(" " + _vm._s(_vm.itemFunctions.getExportReportMetaIcon(listItem)) + " ")])], 1), _c('assignee-preview', {
          attrs: {
            "value": listItem.assignees,
            "amount": 3
          }
        }), _c('tooltip', {
          attrs: {
            "text": _vm.$t("enums.ProgressStatusEnum.".concat(listItem.progressStatus))
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v(" " + _vm._s(_vm.itemFunctions.getProgressIcon(listItem)) + " ")])], 1)], 1)], 1)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }