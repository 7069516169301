var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm.disabled ? _c('v-btn', {
          attrs: {
            "icon": "",
            "x-small": "",
            "loading": _vm.loading
          },
          on: {
            "click": _vm.click
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1) : _c('v-btn', {
          attrs: {
            "icon": "",
            "x-small": "",
            "loading": _vm.loading
          },
          on: {
            "click": _vm.click
          }
        }, [_c('v-icon', [_vm._v("mdi-lock")])], 1)];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_vm._t("main")];
      },
      proxy: true
    }, {
      key: "card-actions",
      fn: function fn() {
        return [_vm._t("card-actions")];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }