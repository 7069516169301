var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mr-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('h3', [_vm._v("Neuen Partner anlegen")])])], 1), _c('v-divider'), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('PartnerCreateOrUpdateGeneralInfos', {
    attrs: {
      "partnerDto": _vm.partnerDto
    }
  }), _c('PartnerCreateOrUpdateLandingpageSettigns', {
    attrs: {
      "settings": _vm.partnerDto.settings
    }
  }), _c('PartnerCreateOrUpdateReportSettings', {
    attrs: {
      "reportSettings": _vm.partnerDto.settings.reportSettings
    }
  }), _c('PartnerCreateOrUpdateLicense', {
    attrs: {
      "settings": _vm.partnerDto.settings
    }
  }), _c('PartnerCreateOrUpdateSubmitCard', {
    attrs: {
      "partnerDto": _vm.partnerDto
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }