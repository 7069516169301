var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "controlElements": _vm.controlElements,
      "predefinedFilter": _vm.predefinedFilter
    },
    on: {
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "item.title",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-list-item', {
          staticClass: "pl-0",
          attrs: {
            "two-line": ""
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "pa-0 text-truncate"
        }, [_vm._v(" " + _vm._s(item.title) + " ")]), !_vm.isMobile ? _c('v-list-item-subtitle', {
          staticClass: "pa-0 text-truncate",
          staticStyle: {
            "max-width": "450px"
          }
        }, [_vm._v(" " + _vm._s(item.description) + " ")]) : _vm._e()], 1)], 1)];
      }
    }, {
      key: "item.isActive",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.isActive ? _c('v-chip', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.states.active")) + " ")]) : _c('v-chip', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.states.inactive")) + " ")])];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.getTypeName(item)) + " ")];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")];
      }
    }])
  }, [_c('template', {
    slot: "onboarding"
  }, [_c('v-card', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "750px"
    },
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('webhook.noWebhookConfigsTitle'))
    }
  }), _c('v-spacer')], 1), _c('v-card-subtitle', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('webhook.noWebhookConfigDescription'))
    }
  })]), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.WEBHOOK_CONFIG
    }
  }, [_c('webhook-config-create-dialog', {
    ref: "webhookConfigCreateDialog",
    attrs: {
      "hideButton": true,
      "partnerId": _vm.partnerId,
      "refs": []
    },
    on: {
      "created": function created($event) {
        return $event.goToDetail(_vm.$router);
      }
    }
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "color": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.webhookConfigCreateDialog.show();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("getStarted")) + " ")])], 1)], 1)], 1)], 1)], 2), _vm.selectedItem ? _c('webhook-config-side-card', {
    attrs: {
      "value": _vm.selectedItem
    },
    on: {
      "close": function close() {
        return _vm.setSelectedItem(null);
      }
    }
  }) : _vm._e(), _vm.isDeleteDialogActive && _vm.selectedItemForDelete ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error',
      "rightLoading": _vm.isDeleteLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }