import i18n from "../../plugins/I18nPlugin";

/*
 * Values should only contain letters, numbers, specialChars
 */
export function valueRule(
  message = "components.partner.PartnerManageInitializeDialog.valueRule"
): (value: any) => boolean | string {
  const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
  return value => regex.test(value) || String(i18n.tc(message));
}
