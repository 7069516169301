













import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import * as XLSX from "xlsx";
import ImportOverviewCard, { IImportOverviewConfig } from "../utility/ImportOverviewCard.vue";
import FleetContractImportDialog from "./FleetContractImportDialog.vue";
import FleetEventImportDialog from "./FleetEventImportDialog.vue";
import FleetVehicleImportDialog from "./FleetVehicleImportDialog.vue";

@Component({
  components: { ImportOverviewCard, FleetEventImportDialog, FleetVehicleImportDialog, FleetContractImportDialog }
})
export default class FleetImportCard extends mixins(PermissionMixin) {
  async generateChargingTemplate() {
    const vehicles = [];
    if (!VehicleModule.vehicles.length) {
      if (!PartnerModule.partner.id) {
        this.$toast.error("Partnercontext not set");
        return;
      }
      await VehicleModule.getAll({ partnerId: PartnerModule.partner.id });
    }
    for (const vehicle of VehicleModule.vehicles) {
      vehicles.push({
        vehicleId: vehicle.id,
        displayName: vehicle?.displayName || "",
        event1: new Date(),
        event2: new Date()
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(vehicles);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "eventtemplate");
    XLSX.writeFile(workbook, "eventtemplate" + new Date().toISOString() + ".xlsx", { compression: true });
  }

  get importConfig(): IImportOverviewConfig[] {
    return [
      {
        category: this.$t("components.fleet.FleetEventImportCard.eventTitle").toString(),
        items: [
          {
            title: this.$t("components.fleet.FleetEventImportCard.event.title").toString(),
            description: this.$t("components.fleet.FleetEventImportCard.event.description").toString(),
            component: "fleet-event-import-dialog",
            template: this.generateChargingTemplate,
            icon: "mdi-calendar-month",
            hasPermission: this.canCreateEvent
          }
        ]
      },
      {
        category: this.$t("components.fleet.FleetEventImportCard.vehicleTitle").toString(),
        items: [
          {
            title: this.$t("components.fleet.FleetEventImportCard.vehicle.title").toString(),
            description: this.$t("components.fleet.FleetEventImportCard.vehicle.description").toString(),
            component: "fleet-vehicle-import-dialog",
            icon: "mdi-car-3-plus",
            hasPermission: this.canCreateVehicle
          }
        ]
      },
      {
        category: this.$t("components.fleet.FleetEventImportCard.contractTitle").toString(),
        items: [
          {
            title: this.$t("components.fleet.FleetEventImportCard.contract.title").toString(),
            description: this.$t("components.fleet.FleetEventImportCard.contract.description").toString(),
            component: "fleet-contract-import-dialog",
            icon: "mdi-file-plus-outline",
            hasPermission: this.canCreateContract
          }
        ]
      }
    ];
  }
}
