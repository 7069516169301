var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [!_vm.isLoading ? _c('partner-booking-calendar', {
    attrs: {
      "bookings": _vm.bookings,
      "services": _vm.services,
      "resources": _vm.resources
    }
  }) : _vm._e(), _c('debug', [_vm._v(" " + _vm._s(_vm.bookings) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }