






























































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import AnalyticsPartnerMultiselect from "@/components/analytics/AnalyticsPartnerMultiselect.vue";
import BarChartCard from "@/components/analytics/BarChartCard.vue";
import PieChartCard from "@/components/analytics/PieChartCard.vue";
import TimeSeriesLineChartCard, { SeriesLineChartOptions } from "@/components/analytics/TimeSeriesLineChartCard.vue";
import StatisticsCard from "@/components/cards/StatisticsCard.vue";
import DashboardStatisticsList from "@/components/dashboard/DashboardStatisticsList.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { OrganizationAnalyticsModule } from "@/store/modules/organization-analytics.store";
import { PartnerModule } from "@/store/modules/partner";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    AnalyticsDateRangeSelectorDialog,
    AnalyticsPartnerMultiselect,
    TheLayoutPortal,
    StatisticsCard,
    DashboardStatisticsList,
    TimeSeriesLineChartCard,
    BarChartCard,
    PieChartCard
  }
})
export default class OrganizationAnalyticsDashboard extends Vue {
  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  userAnalyticsPartners: PartnerEntity[] = [];
  selectedAnalyticsPartnersIds: string[] = [];
  loading = false;

  showAverageTrendChart = false;

  dataHandler = {
    trendAnalysisForMultiplePartners: {
      getData: async (ids: string[]) => {
        await OrganizationAnalyticsModule.getTrendAnalysisDataForMultiplePartners(ids);
      },
      isLoading: false
    },
    averageTrendAnalysisForMultiplePartners: {
      getData: async (ids: string[]) => {
        await OrganizationAnalyticsModule.getAverageTrendAnalysisDataForMultiplePartners(ids);
      },
      isLoading: false
    },
    statusDistribtionForMultiplePartners: {
      getData: async (ids: string[]) => {
        await OrganizationAnalyticsModule.getStatusDistributionDataForMultiplePartners(ids);
      },
      isLoading: false
    },
    reportsCountForMultiplePartners: {
      getData: async (ids: string[]) => {
        await OrganizationAnalyticsModule.getReportsCountDataForMultiplePartners(ids);
      },
      isLoading: false
    }
  };

  get dates() {
    return OrganizationAnalyticsModule.dateRange;
  }

  get statusDistributionDataForMultiplePartners() {
    return OrganizationAnalyticsModule.statusDistributionForMultiplePartnersData.data.map(statusDistributionData => {
      const partnerCompanyname = PartnerModule.analyticsPartners.find(u => u._id === statusDistributionData.partnerId)
        ?.companyName;

      statusDistributionData.partnerId = partnerCompanyname || statusDistributionData.partnerId;

      return statusDistributionData;
    });
  }

  get reportCountForMultiplePartnersData() {
    return OrganizationAnalyticsModule.reportsCountForMultiplePartnersData.data.map(reportCounts => {
      const partnerCompanyname = PartnerModule.analyticsPartners.find(u => u._id === reportCounts.name)?.companyName;
      reportCounts.name = partnerCompanyname || reportCounts.name;

      return reportCounts;
    });
  }

  get trendDataForMultiplePartners() {
    const trendData: SeriesLineChartOptions[] = [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: OrganizationAnalyticsModule.trendAnalysisForMultiplePartnersData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: OrganizationAnalyticsModule.trendAnalysisForMultiplePartnersData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: OrganizationAnalyticsModule.trendAnalysisForMultiplePartnersData.data.thirtyDayTrend
      }
    ];
    return trendData;
  }

  get averageTrendDataForMultiplePartners() {
    // Pass empty arrays as 7 days and 30 days trends the chart is not updating if we don't pass it
    const trendData: SeriesLineChartOptions[] = [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: OrganizationAnalyticsModule.averageTrendAnalysisForMultiplePartnersData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: []
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: []
      }
    ];
    return trendData;
  }

  async mounted() {
    this.loading = true;

    try {
      await PartnerModule.getAnalyticsPartners(UserModule.userAnalyticsPartnerIds);
      // set the original userAnalyticsPartners as starters
      this.userAnalyticsPartners = PartnerModule.analyticsPartners.slice();
    } catch {
      this.$toast.error("Ooops... Fehler beim Laden der Anwendung!");
    } finally {
      this.loading = false;
    }
  }

  async setSeletedPartners(selectedPartnerIds: string[]) {
    if (selectedPartnerIds && selectedPartnerIds.length > 0) {
      // Make a local copy to sync selected partner ids and use to fetch data when date changes
      this.selectedAnalyticsPartnersIds = selectedPartnerIds.slice();

      await PartnerModule.getAnalyticsPartners(selectedPartnerIds);
      await this.getData(this.selectedAnalyticsPartnersIds);
    }
  }

  async getData(ids: string[]) {
    Object.keys(this.dataHandler).forEach(async key => {
      this.dataHandler[key].isLoading = true;

      try {
        await this.dataHandler[key].getData(ids);
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    });
  }

  async setDateRange(dateRange: string[]) {
    OrganizationAnalyticsModule.setDateRange(dateRange);
    await this.getData(this.selectedAnalyticsPartnersIds);
  }
}
