













































import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal
  }
})
export default class PreviewScreens extends Vue {
  search = "";

  get partner() {
    return PartnerModule.partner;
  }

  get url(): string {
    return "https://app.schadensmeldung.digital/#/";
  }

  get headers() {
    return [
      { text: "Pfad", value: "path" },
      {
        text: "Name",
        value: "name"
      }
    ];
  }

  screenTypes = Object.keys(ReportScreenEnum).map(k => {
    return {
      path: ReportScreenEnum[k as any]
    };
  });

  link(path: string) {
    return this.url + path;
  }
}
