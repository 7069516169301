/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { BookingClientProvider } from "../booking-http-client.provider";
import {
  BookingCreateServiceDtoGen,
  BookingPageViewModelGen,
  BookingServiceControllerFindAllByPartnerIdParamsGen,
  BookingServiceViewModelGen,
  BookingUpdateServiceDtoGen
} from "../v1/data-contracts";
import { Service } from "../v1/Service";

/**
 * Service to talk to the Booking endpoint.
 */
export class ServiceService {
  private readonly service: Service;

  constructor(clientProvider: BookingClientProvider) {
    this.service = new Service(clientProvider.client());
  }

  /**
   * Creates a service for a given partner
   *
   * @param partnerId
   * @param data
   * @returns
   */
  async create(partnerId: string, data: BookingCreateServiceDtoGen): Promise<BookingServiceViewModelGen> {
    return (await this.service.serviceControllerCreate(partnerId, data)).data;
  }

  /**
   * Gets all services from a given partner
   *
   * @param query
   * @returns
   */
  async findAllByPartnerId(
    query: BookingServiceControllerFindAllByPartnerIdParamsGen
  ): Promise<BookingPageViewModelGen & { data?: BookingServiceViewModelGen[] }> {
    return (await this.service.serviceControllerFindAllByPartnerId(query)).data;
  }

  /**
   * Gets a particular service from a given partner
   *
   * @param partnerId
   * @param id
   * @returns
   */
  async findOneByPartnerId(partnerId: string, id: string): Promise<BookingServiceViewModelGen> {
    return (await this.service.serviceControllerFindOneByPartnerId(partnerId, id)).data;
  }

  /**
   * Deletes a service for a given partner
   *
   * @param partnerId
   * @param id
   * @returns
   */
  async removeOneByPartnerId(partnerId: string, id: string): Promise<BookingServiceViewModelGen> {
    return (await this.service.serviceControllerRemove(partnerId, id)).data;
  }

  /**
   * Replaces a service for a given partner
   *
   * @param partnerId
   * @param id
   * @param data
   * @returns
   */
  async replaceOneByPartnerId(
    partnerId: string,
    id: string,
    data: BookingUpdateServiceDtoGen
  ): Promise<BookingServiceViewModelGen> {
    return (await this.service.serviceControllerReplace(partnerId, id, data)).data;
  }
}

export default new ServiceService(new BookingClientProvider());
