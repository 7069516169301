var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "onBack": _vm.onBack
    }
  }, [_c('v-container', [_vm.isLoading ? _c('v-skeleton-loader') : _c('resource-form', {
    attrs: {
      "value": _vm.resource,
      "isLoading": _vm.isLoading
    },
    on: {
      "input": function input($event) {
        _vm.updated = $event;
      }
    }
  }), _c('v-btn', {
    staticClass: "mt-4 mr-4",
    attrs: {
      "color": "primary",
      "large": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.booking.ResourceDetail.save")) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }