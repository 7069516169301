var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('partner-manage-stepper-step-layout', {
    attrs: {
      "title": _vm.$t('components.partner.PartnerManageStepper.VerifyCredentialsStep.title'),
      "currentStep": _vm.currentStep,
      "totalSteps": _vm.totalSteps,
      "rightButtonFunction": _vm.goNextFunction,
      "rightButtonLabel": _vm.goNextButtonLabel,
      "rightButtonIcon": _vm.goNextButtonIcon,
      "leftButtonFunction": _vm.goBackFunction
    }
  }, [[_vm.result ? _c('h1', {
    staticStyle: {
      "color": "green"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.VerifyCredentialsStep.success")) + " ")]) : _c('h1', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.VerifyCredentialsStep.fail")) + " ")])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }