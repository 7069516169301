var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-alert', {
    staticClass: "ma-4",
    attrs: {
      "value": true,
      "type": "success",
      "border": "left"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationCreateBatchView.success", {
    success: _vm.importedItems.length,
    all: _vm.totalItems
  })) + " ")])], 1)], 1), _vm.failedImports.length ? _c('v-alert', {
    staticClass: "ma-4",
    attrs: {
      "value": true,
      "type": "error",
      "border": "left"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgChargingStationCreateBatchView.error", {
    value: _vm.failedImports.length
  })) + " ")]), _c('v-col', {
    staticClass: "shrink"
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.createErrorReport
    }
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgChargingStationCreateBatchView.errorReport")))])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }