import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import webhookConfigService from "@/services/ainonymizer/services/webhook-config.service";
import {
  MrfiktivUpdateWebhookConfigDtoGen,
  MrfiktivWebhookConfigHeaderViewModelGen,
  MrfiktivWebhookConfigViewModelGen,
  MrfiktivWebhookSettingViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { WebhookConfigDataAccessLayer } from "@/store/modules/access-layers/webhook-config.access-layer";
import { WebhookTypeEnum } from "@/lib/enum/webhook-type.enum";
import { WebhookGoToHelper } from "@/lib/utility/webhook.go-to-helper";
import VueRouter from "vue-router";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";

@IsFilterable
class WebhookConfigBase
  implements IEntity<IWebhookConfig, MrfiktivUpdateWebhookConfigDtoGen>, MrfiktivWebhookConfigViewModelGen {
  /**
   * Indicates whether the webhook is loading
   */
  loading = false;

  /** Id of the webhook config object */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.webhookConfig.id"
  })
  id: string;

  /** The partnerId of the webhook config */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.webhookConfig.partnerId"
  })
  partnerId: string;

  /** Title of the webhook config object */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.webhookConfig.title"
  })
  title: string;

  /** Internal description of the webhook config object */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.webhookConfig.description"
  })
  description: string;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(BackendResourceEnum).map(e => {
        return {
          text: `enums.BackendResourceEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "objects.webhookConfig.resource"
  })
  resource: BackendResourceEnum;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(ActionEnum).map(e => {
        return {
          text: `enums.ActionEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "objects.webhookConfig.action"
  })
  action?: ActionEnum;

  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(WebhookTypeEnum).map(e => {
        return {
          text: `enums.WebhookTypeEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "objects.webhookConfig.type"
  })
  type?: WebhookTypeEnum;

  /** The url of the webhook config */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.webhookConfig.url"
  })
  url: string;

  /** The partnerId of the webhook config */
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.webhookConfig.isActive"
  })
  isActive: boolean;

  /**
   * Indicates whether the webhook is active
   * @example true
   */
  headers: MrfiktivWebhookConfigHeaderViewModelGen[];

  /** The restart config of the webhook */
  setting: MrfiktivWebhookSettingViewModelGen;

  /** timestamp */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  constructor(data: Partial<MrfiktivWebhookConfigViewModelGen>) {
    this.id = data.id || "";
    this.partnerId = data.partnerId || "";
    this.title = data.title || "";
    this.description = data.description || "";
    this.url = data.url || "";
    this.isActive = data.isActive || false;
    this.headers = data.headers || [];
    this.timestamp = new Timestamp(data.timestamp);
    this.resource = (data.resource ?? "") as BackendResourceEnum;
    this.action = (data.action ?? "") as ActionEnum;
    this.type = (data.type ?? "") as WebhookTypeEnum;
    this.setting = data.setting || {
      retries: 0
    };
  }

  map(data: MrfiktivWebhookConfigViewModelGen): void {
    this.id = data.id || "";
    this.partnerId = data.partnerId || "";
    this.title = data.title || "";
    this.description = data.description || "";
    this.url = data.url || "";
    this.isActive = data.isActive || false;
    this.headers = data.headers || [];
    this.timestamp = new Timestamp(data.timestamp);
    this.resource = (data.resource ?? "") as BackendResourceEnum;
    this.action = (data.action ?? "") as ActionEnum;
    this.type = (data.type ?? "") as WebhookTypeEnum;
    this.setting = data.setting || 0;
  }

  async create(): Promise<this> {
    const res = await webhookConfigService.create(this.partnerId, {
      partnerId: this.partnerId,
      title: this.title,
      description: this.description,
      url: this.url,
      isActive: this.isActive,
      resource: this.resource,
      action: this.action || undefined,
      type: this.type || undefined,
      headers: this.headers,
      setting: this.setting
    });

    this.map(res);
    WebhookConfigDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    this.loading = true;
    try {
      const res = await webhookConfigService.getOne(this.partnerId, this.id);
      this.map(res);
      WebhookConfigDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      WebhookConfigDataAccessLayer.delete(this);
      throw e;
    }
    this.loading = false;

    return this;
  }

  async updatePartial(dto: Partial<MrfiktivUpdateWebhookConfigDtoGen>): Promise<this> {
    const res = await webhookConfigService.update(this.partnerId, this.id, dto);

    this.map(res);
    WebhookConfigDataAccessLayer.set(this);

    return this;
  }

  async update(): Promise<this> {
    this.loading = true;
    try {
      await this.updatePartial({
        title: this.title,
        description: this.description,
        url: this.url,
        isActive: this.isActive,
        headers: this.headers,
        resource: this.resource,
        action: this.action || null,
        type: this.type || null,
        setting: this.setting
      });
    } catch (e) {
      this.loading = false;
      throw e;
    }
    this.loading = false;

    return this;
  }

  async delete(): Promise<void> {
    const res = await webhookConfigService.delete(this.partnerId, this.id);

    this.map(res);
    WebhookConfigDataAccessLayer.delete(this);
  }

  goToTable(router: VueRouter) {
    new WebhookGoToHelper(router).goToWebhookConfigTable({
      partnerId: this.partnerId
    });
  }

  goToDetail(router: VueRouter) {
    new WebhookGoToHelper(router).goToWebhookConfigDetail({
      partnerId: this.partnerId,
      webhookConfigId: this.id
    });
  }
}

type IWebhookConfig = WebhookConfigBase;
const WebhookConfig = Filter.createForClass(WebhookConfigBase);

export { IWebhookConfig, WebhookConfig };
