
























































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import AssigneePreview from "../utility/AssigneePreview.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import RefsSelect from "../utility/RefsSelect.vue";
import { handleError } from "@/lib/utility/handleError";
import SelectAssignees from "../utility/SelectAssignees.vue";
import PartnerPdfs from "../files/PartnerPdfs.vue";
import { WebhookConfig, IWebhookConfig } from "@/models/webhook-config.entity";
import CustomFieldConfigurationForm from "@/components/report/CustomFieldConfigurationForm.vue";
import CustomFieldValueChip from "@/components/report/CustomFieldValueChip.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { WebhookTypeEnum } from "@/lib/enum/webhook-type.enum";
import CustomFieldBoolean from "@/components/report/CustomFieldBoolean.vue";
import WebhookTypeSelection from "@/components/webhook/WebhookTypeSelection.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    AssigneePreview,
    RefsSelect,
    SelectAssignees,
    PartnerPdfs,
    CustomFieldConfigurationForm,
    CustomFieldValueChip,
    CustomFieldBoolean,
    WebhookTypeSelection
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class WebhookConfigCreateDialog extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  partnerId!: string;

  @Prop()
  isDialogActive!: boolean;

  @Prop()
  hideButton!: boolean;

  isLoading = false;

  isFormValid = false;

  webhookConfig: IWebhookConfig = new WebhookConfig({});

  get webhookTypes() {
    return Object.values(WebhookTypeEnum);
  }

  get categories(): BackendResourceEnum[] {
    return [BackendResourceEnum.VEHICLE, BackendResourceEnum.REPORT];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get isDialogActiveLocal() {
    return this.isDialogActive;
  }

  set isDialogActiveLocal(isDialogActive: boolean) {
    this.$emit("update:isDialogActive", isDialogActive);
  }

  show() {
    this.webhookConfig = new WebhookConfig({ partnerId: this.partnerId });
    this.isDialogActive = true;
    this.$nextTick(() => {
      document.getElementById("titleInput")?.focus();
    });
  }

  async onRightClick() {
    try {
      this.isDialogActive = true;
      await this.webhookConfig.create();
      this.$emit("created", this.webhookConfig);
      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDialogActive = false;
    }
  }

  onConfirm(item: MrfiktivReferenceGen) {
    this.$log.debug("onConfirm", item);
  }
}
