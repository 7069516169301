










































import { Component, Vue } from "vue-property-decorator";
import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import PieChartCard from "@/components/analytics/PieChartCard.vue";
import BarChartCard from "@/components/analytics/BarChartCard.vue";
import { ServiceEnum } from "@/lib/enum/service.enum";
import { PartnerModule } from "@/store/modules/partner";
import StatisticsCardRow, { IStatisticRowElement } from "@/components/utility/StatisticsCardRow.vue";
import { $t } from "@/lib/utility/t";
import { AdminCountAnalyticsModule } from "@/store/modules/admin-count-analytics.store";
import { handleError } from "@/lib/utility/handleError";

type GetDataParams = {
  selectedService: ServiceEnum;
};

@Component({
  components: {
    AnalyticsDateRangeSelectorDialog,
    PieChartCard,
    BarChartCard,
    StatisticsCardRow
  }
})
export default class AdminCountAnalytics extends Vue {
  selectedService: ServiceEnum | null = null;
  selectedPartnerId: string | null = null;

  dataHandler = {
    count: {
      getData: async (data: GetDataParams) => {
        if (data.selectedService) {
          await AdminCountAnalyticsModule.getCount({ selectedService: data.selectedService });
        }
      },
      isLoading: false
    },
    countAndGroupByPartner: {
      getData: async (data: GetDataParams) => {
        if (data.selectedService) {
          await AdminCountAnalyticsModule.getCountAndGroupByPartner({ selectedService: data.selectedService });
        }
      },
      isLoading: false
    },
    countAndGroupByPartnerAndDate: {
      getData: async (data: GetDataParams) => {
        if (data.selectedService) {
          await AdminCountAnalyticsModule.getCountAndGroupPerPartnerAndDateRange({
            selectedService: data.selectedService
          });
        }
      },
      isLoading: false
    }
  };

  get services() {
    return Object.values(ServiceEnum).map(serviceName => ({
      text: $t(`objects.${serviceName}.serviceName`),
      value: serviceName
    }));
  }

  get partners() {
    return PartnerModule.partners;
  }

  get dates() {
    return AdminCountAnalyticsModule.dateRange;
  }

  get count() {
    return AdminCountAnalyticsModule.count.count;
  }

  get countAndGroupByPartnerAndDate() {
    return AdminCountAnalyticsModule.countAndGroupByPartnerAndDate;
  }

  get countAndGroupByPartner() {
    return AdminCountAnalyticsModule.countAndGroupByPartner;
  }

  get allPartnersCountRowData(): IStatisticRowElement[] {
    return [
      {
        icon: "mdi-file-document-check-outline",
        title: "views.portal.AdminCountAnalytics.countStatisticsTitle",
        color: "primary",
        tooltip: "views.portal.AdminCountAnalytics.statisticsTooltip",
        data: this.count?.toString() || ""
      }
    ];
  }

  async setDateRange(dateRange: string[]) {
    AdminCountAnalyticsModule.setDateRange(dateRange);
    await this.getData();
  }

  async mounted() {
    this.selectedPartnerId = PartnerModule.partner._id;
    this.selectedService = this.services[0].value;
    await this.getData();
  }

  async getData() {
    Object.keys(this.dataHandler).forEach(async key => {
      this.dataHandler[key].isLoading = true;

      try {
        await this.dataHandler[key].getData({
          selectedService: this.selectedService,
          partnerId: this.selectedPartnerId
        } as GetDataParams);
      } catch (error) {
        handleError(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    });
  }
}
