













































import { Component, Vue, Prop } from "vue-property-decorator";
import { MrfiktivVehicleViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import QRCode from "qrcode";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";
import { PartnerModule } from "@/store/modules/partner";
import { IInitReport, CreateReportUrlFactory } from "@/lib/utility/createReportUrlFactory";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { GoToHelper } from "@/lib/utility/goToHelper";

interface QrLinkData {
  imageData: Blob;
  imageName: string;
}

@Component({
  components: {
    ConfirmActionDialog,
    RefsVehicle
  }
})
export default class VehicleMultipleQrCodeDownload extends Vue {
  @Prop({ default: () => [] })
  vehicles!: MrfiktivVehicleViewModelGen[];

  confirmQrDownloadDialogActive = false;
  qrDownloadLoading = false;

  qrLinks: QrLinkData[] = [];
  invalidQrVehiclesIndexes: number[] = [];

  get invalidVehicles() {
    return this.vehicles.filter((_, index) => this.invalidQrVehiclesIndexes.includes(index));
  }

  async generateQrCodes() {
    this.qrLinks = await this.generateLinks();
    this.confirmQrDownloadDialogActive = true;
  }

  async downloadQrCodes() {
    const folderName = `qr_codes_${new Date().toISOString()}`;
    await this.createAndDownloadZipFile(this.qrLinks, folderName);
  }

  close() {
    this.confirmQrDownloadDialogActive = false;
  }

  async goToVehicleDetail(vehicle: MrfiktivVehicleViewModelGen) {
    await new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, undefined, true);
  }

  private async generateLinks() {
    this.invalidQrVehiclesIndexes = [];
    const qrLinkData: QrLinkData[] = [];

    for (const [index, vehicle] of this.vehicles.entries()) {
      const link = await this.getVehicleQrCode(vehicle);

      if (link) {
        const imageData = await fetch(link).then(response => response.blob());
        const imageName = this.replaceInvalidFileNameCharacters(this.getQrCodeFilename(vehicle));
        qrLinkData.push({ imageName, imageData });
      } else {
        this.invalidQrVehiclesIndexes.push(index);
        this.$log.debug(`link for vehicle ${vehicle.displayName}_${vehicle.id} cannot be produced`);
      }
    }

    return qrLinkData;
  }

  private async createAndDownloadZipFile(links: QrLinkData[], folderName: string) {
    const zip = new JSZip();
    const folder = zip.folder(folderName);

    if (!folder) {
      this.$log.error("Could not create 'images' folder in zip file");
      return;
    }

    for (const { imageName, imageData } of links) {
      folder.file(imageName, imageData, { binary: true });
    }

    try {
      this.qrDownloadLoading = true;
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${folderName}.zip`);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.qrDownloadLoading = false;
    }
  }

  /**
   * Generates a QR code that encodes a URL. The URL leads to a report app and includes vehicle details such as
   * the Vehicle Identification Number (VIN) and the number plate. The VIN is a required parameter for generating the URL.
   * If the VIN is not present in the vehicle object, the function returns an empty string.
   * @param vehicle the vehicle for which to generate QR code
   * @returns the generated QR code
   */
  private async getVehicleQrCode(vehicle: MrfiktivVehicleViewModelGen) {
    const defaultUrl = PartnerModule.partner.settings?.defaultUrl;

    if (!defaultUrl || !vehicle.identificationnumber) {
      return "";
    }

    const initReportParams: Partial<IInitReport> = {
      registration_identificationnumber: vehicle.identificationnumber
    };

    if (vehicle.numberplate) {
      initReportParams.numberPlate = vehicle.numberplate;
    }

    const initReport = new CreateReportUrlFactory(initReportParams as IInitReport);

    const url = initReport.url(defaultUrl);
    const qrCodeUrl = await QRCode.toDataURL(url);

    return qrCodeUrl;
  }

  private getQrCodeFilename(vehicle: MrfiktivVehicleViewModelGen) {
    return vehicle.identificationnumber
      ? `${vehicle.displayName}_(${vehicle.identificationnumber}).png`
      : `${vehicle.displayName}.png`;
  }

  /**
   * Replaces invalid filename characters in a given string with underscores.
   *
   * @param filename - The original filename string.
   * @returns The sanitized filename with invalid characters replaced by underscores.
   */
  private replaceInvalidFileNameCharacters(filename: string) {
    const invalidChars = /[/:*?"<>| ]/g;
    const replaced = filename.replace(invalidChars, "_");
    return replaced;
  }
}
