












































































































































































import Card from "@/components/utility/Card.vue";
import ComboBox from "@/components/utility/ComboBox.vue";
import { TransmissionTypeEnum } from "@/lib/enum/transmission.enum";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { vehicleClassMap } from "@/lib/utility/vehicleClassMap";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import DeleteDialog from "../dialog/DeleteDialog.vue";
import FleetVehicleDetailContextMenu from "./FleetVehicleDetailContextMenu.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { driveTypeMap } from "@/lib/utility/driveTypeMap";
import { VehicleTypes } from "@/store/enum/vehicle-types.enum";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";

@Component({
  components: {
    FleetVehicleDetailContextMenu,
    DeleteDialog,
    Card,
    ComboBox
  }
})
export default class FleetVehicleDetailVehicleCard extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;
  VehicleStateEnum = VehicleStateEnum;

  @Prop()
  vehicle!: IVehicle;

  @Prop()
  loading!: boolean;

  isDisabled = true;
  isValid = false;

  isConfirmDeleteDialogActive = false;

  /**
   * Map to the ui class
   */
  currentVehicle: IVehicle = new Vehicle(this.vehicle);

  /**
   * If ui class was able to construct a registration date use it
   */
  registrationDate = this.currentVehicle.registrationDate;

  get vehicleStates() {
    return Object.values(VehicleStateEnum);
  }

  get transmissions() {
    return Object.values(TransmissionTypeEnum);
  }

  get vehicleClasses() {
    return Array.from(vehicleClassMap.keys());
  }

  get driveTypes() {
    return Array.from(driveTypeMap.keys());
  }

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailVehicleCard");
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get allTags() {
    const tags: string[] = [];
    VehicleModule.vehicles.forEach(v => {
      v?.tags?.forEach(t => {
        if (!tags.includes(t)) {
          tags.push(t);
        }
      });
    });

    if (tags.length > 0) {
      return tags;
    }

    if (this.vehicle?.tags && this.vehicle.tags.length > 0) {
      return this.vehicle.tags;
    }

    return [];
  }

  getDriveType(key: string) {
    return driveTypeMap.get(key)?.text;
  }

  getVehicleClass(key: VehicleTypes) {
    return vehicleClassMap.get(key)?.text;
  }

  emitVehicle() {
    if (this.registrationDate) {
      this.$log.debug(this.registrationDate);

      const [year, month, day] = this.registrationDate.split("-");

      if (this.currentVehicle.registration) {
        this.currentVehicle.registration.firstregistrationDay = day;
        this.currentVehicle.registration.firstregistrationMonth = month;
        this.currentVehicle.registration.firstregistrationYear = year;
      }
    }

    this.$emit("change", this.currentVehicle);

    this.isDisabled = true;
  }

  confirmDeletion() {
    this.isConfirmDeleteDialogActive = false;
    this.deleteVehicle();
  }

  deleteVehicle() {
    this.$log.debug("FleetVehicleDetailVehicleCard", "deleteVehicle");
    this.$emit("delete");
  }

  abort() {
    this.isDisabled = true;
    this.currentVehicle = new Vehicle(this.vehicle);
  }
}
