var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', {
    attrs: {
      "rules": _vm.rules,
      "label": _vm.$t('common.nouns.vehicle'),
      "items": _vm.items,
      "clearable": "",
      "item-text": "displayName",
      "item-value": "id",
      "data-test-vehicle-selection": ""
    },
    on: {
      "change": _vm.change
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }