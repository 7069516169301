













import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { formatHoursAndMinutes } from "@/lib/utility/date-helper";
import { DaysOfTheWeekHelper, DaysOfTheWeekOrder } from "@/lib/utility/daysOfTheWeekHelper";
import { BookingAvailabilityGen } from "@/services/booking/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import AvailabilityFormField from "./AvailabilityFormField.vue";
import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";

@Component({
  components: { ConfirmActionDialog, AvailabilityFormField, Debug },
  filters: { formatHoursAndMinutes }
})
export default class AvailabilityForm extends Vue {
  isValid = false;
  isDisabled = true;

  // Availability array ordered as Sun-Sat
  @Prop()
  availability!: BookingAvailabilityGen[];
  // Days of the week as to be displayed in the table(Mon-Sun)
  weekDays = DaysOfTheWeekHelper.getDaysOfTheWeek(DaysOfTheWeekOrder.Normal);

  @Prop({ default: false })
  isLoading!: boolean;

  changeAvailability(data: { item: BookingAvailabilityGen; day: WeekDaysEnum }) {
    // Get the index of the day we're editing(Mon-Sun)
    const index = this.weekDays.indexOf(data.day);
    this.availability.splice(index, 1, data.item);
    this.$emit("change", this.availability);
  }

  get items(): BookingAvailabilityGen[] {
    return this.availability;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get disabled() {
    return this.isLoading;
  }
}
