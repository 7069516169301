





































import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailForm from "@/components/utility/mmmint/MDetailForm.vue";
import MDetailTable, {
  DetailTableVisualizationEnum,
  IMDetailTableConfig,
  DetailTableTypeEnum
} from "@/components/utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { $t } from "@/lib/utility/t";
import { IMDetailFormConfig, MDetailFormConfig } from "@/lib/formable";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";

@Component({
  components: {
    TheLayoutPortal,
    MHeader: MHeader,
    MActionList,
    MDetailViewGrid,
    MDetailTable,
    MDetailForm
  }
})
export default class DesignGuidelinesObjectDetailExample extends PartnerFallbackMixin {
  get chips() {
    return [
      {
        text: "5 Vehicles",
        key: "primaryAction",
        icon: "mdi-car",
        disabled: true
      },
      {
        text: "6 upcoming Events",
        key: "secondaryAction",
        icon: "mdi-calendar-month-outline",
        disabled: true
      },
      {
        text: "9 open Tasks",
        key: "secondaryAction",
        color: "red"
      }
    ];
  }

  get actions() {
    return [
      {
        text: "Primary",
        key: "primaryAction",
        icon: "mdi-cog-outline"
      },
      {
        text: "Secondary",
        key: "secondaryAction",
        icon: "mdi-cog-outline"
      },
      {
        text: "Third Action",
        key: "thirdAction",
        icon: "mdi-cog-outline"
      },
      {
        text: "Fourth Action",
        key: "fourthAction",
        icon: "mdi-delete-outline",
        color: "red"
      }
    ];
  }

  get breadCrumbs() {
    return [
      {
        text: "Design Guidelines",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelines",
          params: {}
        }
      },
      {
        text: "Example with chip",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelinesHeaderExample",
          params: {}
        }
      },
      {
        text: "Details",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelinesObjectDetailExample",
          params: {}
        }
      }
    ];
  }

  processAction(action: { text: string; emit: string }) {
    this.$toast("Action clicked: " + action.text);
  }

  testItem = {
    title: "Hello world",
    status: "new",
    dates: {
      today: new Date().toISOString()
    },
    type: "Car"
  };

  get detailTableConfig(): IMDetailTableConfig[] {
    return [
      {
        key: "title",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "status",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          { value: "new", displayValue: "Neuer Lead", color: "warning" },
          { value: "customer", displayValue: "Kunde", color: "success" }
        ]
      },
      {
        key: "dates.today",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "657095c1504da003902cba49"
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "656f1929504da003902b0245"
      },
      {
        key: "status",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "dates.today",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "657095c1504da003902cba49"
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "656f1929504da003902b0245"
      },
      {
        type: DetailTableTypeEnum.CUSTOM,
        key: "656f1914504da003902b023b"
      },
      {
        key: "type",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP
      }
    ];
  }

  get customFieldsForm() {
    return CustomFieldModule.paginationList;
  }

  get customFieldValuesTable() {
    return [
      {
        value: "lead",
        id: "657095c1504da003902cba49"
      },
      {
        value: new Date().toISOString(),
        id: "656f1929504da003902b0245"
      },
      {
        value: "Hey this is cool!",
        id: "656f1914504da003902b023b"
      }
    ];
  }

  get tableShowMoreAction() {
    return {
      text: $t("designGuide.MDetailForm.allProps"),
      key: "showDetails"
    };
  }

  formTestItem = {
    name: "Max",
    age: "20",
    city: "osna",
    street: "Obere Findelstätte",
    myArray: ["Peter"],
    values: [
      {
        value: "lead",
        id: "657095c1504da003902cba49"
      },
      {
        value: "Hey this is cool!",
        id: "656f1914504da003902b023b"
      }
    ]
  };

  config: IMDetailFormConfig[] = [
    {
      category: "personal",
      key: "name",
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: ["name", "Vorname", "Nachname"],
      model: this.formTestItem.name,
      props: {
        label: "Name",
        rules: this.required,
        hint: "My hint",
        "persistent-hint": true
      }
    },
    {
      category: "personal",
      key: "age",
      type: DetailFormComponentsEnum.TEXT_FIELD,
      searchKeywords: ["alter", "age"],
      model: this.formTestItem.age,
      props: {
        label: "Alter",
        rules: this.required
      }
    },
    {
      category: "adress",
      key: "city",
      type: DetailFormComponentsEnum.TEXT_FIELD,
      model: this.formTestItem.city,
      searchKeywords: ["Stadt"],
      props: {
        label: "Stadt",
        rules: this.required
      }
    },
    {
      category: "adress",
      key: "street",
      type: DetailFormComponentsEnum.TEXT_FIELD,
      model: this.formTestItem.street,
      searchKeywords: ["Straße", "Street"],
      props: {
        label: "Straße"
      }
    },
    {
      category: "personal",
      key: "myArray",
      type: DetailFormComponentsEnum.SELECT_FIELD,
      model: this.formTestItem.myArray,
      searchKeywords: ["Arrays"],
      props: {
        label: "Arrays",
        multiple: true,
        items: ["Peter", "Griffin", "Harry Potter"]
      }
    }
  ];

  async syncChanges() {
    const config = new MDetailFormConfig(this.config);
    this.formTestItem.name = config.getConfigValueByKey("name");
    this.formTestItem.age = config.getConfigValueByKey("age");
    this.formTestItem.city = config.getConfigValueByKey("city");
    this.formTestItem.street = config.getConfigValueByKey("street");
    this.formTestItem.myArray = config.getConfigValueByKey("myArray");
  }

  async abortChanges() {
    const config = new MDetailFormConfig(this.config);
    config.configValueByKey = { key: "name", value: this.formTestItem.name };
    config.configValueByKey = { key: "age", value: this.formTestItem.age };
    config.configValueByKey = { key: "city", value: this.formTestItem.city };
    config.configValueByKey = { key: "street", value: this.formTestItem.street };
    config.configValueByKey = { key: "myArray", value: this.formTestItem.myArray };
  }

  get required() {
    return [requiredRule()];
  }
}
