import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import { CustomerAccountService } from "../mrfiktiv/v1/CustomerAccountService";
import { MrfiktivCustomerAccountControllerFindAllByPartnerIdParamsGen } from "../mrfiktiv/v1/data-contracts";

export class CustomerAccountMeService {
  /**
   * The proxy.
   */
  proxy: CustomerAccountService;

  /**
   * Initialize service
   * @param clientProvider
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.proxy = new CustomerAccountService(clientProvider.client());
  }

  async findAll(data: MrfiktivCustomerAccountControllerFindAllByPartnerIdParamsGen) {
    return (await this.proxy.customerAccountControllerFindAllByPartnerId(data)).data;
  }

  async findOne(partnerId: string, id: string) {
    return (await this.proxy.customerAccountControllerFindOneByUserId(partnerId, id)).data;
  }
}

export default new CustomerAccountMeService(new MrfiktivHttpClientProvider());
