var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-14"
  }, [_c('m-header', {
    attrs: {
      "title": _vm.value.title,
      "breadCrumbs": _vm.breadCrumbs,
      "chips": _vm.chips,
      "actions": _vm.isEditTitle ? [] : _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      },
      "alertClicked": function alertClicked($event) {
        return $event.exec();
      }
    },
    scopedSlots: _vm._u([_vm.isEditTitle ? {
      key: "title",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mt-1 mb-n1",
          attrs: {
            "label": _vm.$t('common.nouns.title'),
            "outlined": "",
            "loading": _vm.isLoadingTitle,
            "readonly": _vm.isLoadingTitle
          },
          model: {
            value: _vm.editTitleCopy,
            callback: function callback($$v) {
              _vm.editTitleCopy = $$v;
            },
            expression: "editTitleCopy"
          }
        }), _c('div', {
          staticClass: "mt-n6"
        }, [_c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info"
          },
          on: {
            "click": _vm.saveEditTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info",
            "outlined": ""
          },
          on: {
            "click": _vm.cancelEditTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }), _c('v-divider', {
    staticClass: "mt-2 mb-n4"
  }), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('custom-field-boolean', {
          attrs: {
            "loading": _vm.isActiveLoading,
            "customField": {
              label: _vm.$t('objects.webhookConfig.isActive')
            }
          },
          on: {
            "input": _vm.updateIsActive
          },
          model: {
            value: _vm.value.isActive,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "isActive", $$v);
            },
            expression: "value.isActive"
          }
        }), _c('webhook-type-selection', {
          attrs: {
            "resource": _vm.value.resource,
            "action": _vm.value.action,
            "type": _vm.value.type,
            "loading": _vm.isTypeLoading
          },
          on: {
            "update:resource": function updateResource($event) {
              return _vm.$set(_vm.value, "resource", $event);
            },
            "update:action": function updateAction($event) {
              return _vm.$set(_vm.value, "action", $event);
            },
            "update:type": function updateType($event) {
              return _vm.$set(_vm.value, "type", $event);
            },
            "update": _vm.updateType
          }
        }), _c('v-text-field', {
          attrs: {
            "loading": _vm.isUrlLoading,
            "outlined": "",
            "label": _vm.$t('objects.webhookConfig.url'),
            "rules": _vm.requiredRule
          },
          on: {
            "change": _vm.updateUrl
          },
          model: {
            value: _vm.value.url,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "url", $$v);
            },
            expression: "value.url"
          }
        }), _c('webhook-header-configuration', {
          attrs: {
            "loading": _vm.isHeadersLoading
          },
          on: {
            "change": _vm.updateHeaders
          },
          model: {
            value: _vm.value.headers,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "headers", $$v);
            },
            expression: "value.headers"
          }
        }), _c('v-text-field', {
          staticClass: "mt-7",
          attrs: {
            "loading": _vm.isSettingLoading,
            "outlined": "",
            "label": _vm.$t('objects.webhookConfig.setting'),
            "rules": _vm.requiredRule
          },
          on: {
            "change": _vm.updateSetting
          },
          model: {
            value: _vm.settingRetries,
            callback: function callback($$v) {
              _vm.settingRetries = $$v;
            },
            expression: "settingRetries"
          }
        }), _c('v-divider'), _c('m-action-list', {
          attrs: {
            "actions": _vm.bottomActions
          },
          on: {
            "actionClicked": function actionClicked($event) {
              return $event.exec();
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('small', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.subtitle))]), _c('v-spacer'), !_vm.isEditDescription ? _c('v-btn', {
    attrs: {
      "elevation": 0,
      "text": "",
      "x-small": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('edit'))
    },
    on: {
      "click": _vm.startEditDescription
    }
  }) : _vm._e()], 1), _c('v-textarea', {
    staticStyle: {
      "height": "180px"
    },
    attrs: {
      "loading": _vm.isLoadingDescription,
      "readonly": !_vm.isEditDescription || _vm.isLoadingDescription,
      "outlined": true
    },
    model: {
      value: _vm.descriptionCopy,
      callback: function callback($$v) {
        _vm.descriptionCopy = $$v;
      },
      expression: "descriptionCopy"
    }
  }), _vm.isEditDescription ? _c('div', {
    staticClass: "mt-n2",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "elevation": 0,
      "small": "",
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('save'))
    },
    on: {
      "click": _vm.saveEditDesciption
    }
  }), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "small": "",
      "outlined": "",
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('close'))
    },
    on: {
      "click": _vm.abortEditDesciption
    }
  })], 1) : _vm._e(), _c('operation-table', {
    staticClass: "ma-n2",
    attrs: {
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "hideTypeHeader": true
    }
  })], 1), _vm.isDeleteDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error'
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }