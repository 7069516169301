














































import Card from "@/components/utility/Card.vue";
import ComboBox from "@/components/utility/ComboBox.vue";
import { ContractHelper } from "@/lib/utility/contract-helper";
import { simpleDate } from "@/lib/utility/date-helper";
import { IVehicle } from "@/models/vehicle.entity";

import VChart from "vue-echarts";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import IconCard from "../utility/IconCard.vue";

@Component({
  components: {
    Card,
    ComboBox,
    IconCard,
    VChart,
    LatestEntriesCardEmpty
  }
})
export default class FleetVehicleDetailHomeCardMileageStatistic extends Vue {
  @Prop()
  vehicle!: IVehicle;

  isChartShown = true;

  get i18n() {
    return this.$t("components.fleet.FleetVehicleDetailHomeCardMileageStatistic");
  }

  simpleDate(date: string) {
    return simpleDate(date);
  }

  get contractHelper() {
    return new ContractHelper(this.vehicle);
  }

  get dates() {
    const dateArray = [];
    const currentDate = this.contractHelper.currentContract?.startDate
      ? new Date(this.contractHelper.currentContract?.startDate)
      : new Date();

    const endDate = this.contractHelper.currentContract?.endDate
      ? new Date(this.contractHelper.currentContract?.endDate)
      : new Date();

    while (currentDate <= endDate) {
      dateArray.push(simpleDate(currentDate.toString()));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    dateArray.push(simpleDate(currentDate.toString()));

    return dateArray;
  }

  mapMileageToChartData(d: { mileage: number; date: string | undefined }) {
    return [this.simpleDate(d.date || ""), Math.round(d.mileage)];
  }

  get expectedMileages() {
    return [
      this.mileages[this.mileages.length - 1],
      this.mapMileageToChartData(this.contractHelper.calculatedEndMileage)
    ];
  }

  get contractMileages() {
    const mileage = this.contractHelper.startMileage.mileage + (this.contractHelper.allowedEndMileage || 0);
    const startDate = this.simpleDate(this.contractHelper.startMileage?.date || "");
    const endDate = this.simpleDate(this.contractHelper.currentContract?.endDate || "");

    return [
      [startDate, mileage],
      [endDate, mileage]
    ];
  }

  get mileages() {
    return this.contractHelper.mileagesAfterContractStart.map(m => this.mapMileageToChartData(m));
  }

  get chartOption() {
    return {
      color: ["grey", "red", "green"],
      autoSize: true,
      tooltip: {
        trigger: "axis"
      },
      legend: {
        data: [this.i18n["expectedMileages"], this.i18n["actualMileages"], this.i18n["contractMileages"]]
      },
      grid: {
        left: "3%",
        right: "3%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: this.dates
      },
      yAxis: {
        type: "value"
      },
      series: [
        {
          name: this.i18n["expectedMileages"],
          type: "line",
          data: this.expectedMileages,
          xKey: "category",
          yKey: "value",
          lineStyle: { type: "dashed" }
        },
        {
          name: this.i18n["contractMileages"],
          type: "line",
          data: this.contractMileages,
          xKey: "category",
          yKey: "value"
        },
        {
          name: this.i18n["actualMileages"],
          type: "line",
          data: this.mileages,
          xKey: "category",
          yKey: "value"
        }
      ]
    };
  }

  get statisticList() {
    const list = [];

    const allowedByContract = (this.contractHelper.allowedEndMileage || 0) + this.contractHelper.startMileage.mileage;
    if (this.contractHelper.startMileage.mileage) {
      list.push({
        label: this.i18n["startMileage"],
        value: this.contractHelper.startMileage.mileage + "km"
      });
    }

    list.push({
      label: this.i18n["maxMileage"],
      value: (isNaN(this.contractHelper.maxMileage.mileage) ? 0 : this.contractHelper.maxMileage.mileage) + "km"
    });

    if (this.contractHelper.startMileage.mileage) {
      const expected = this.contractHelper.maxMileage.mileage - this.contractHelper.startMileage.mileage;
      list.push({
        label: this.i18n["difference"],
        value: (isNaN(expected) ? "0" : expected) + "km"
      });
    }

    const value = Math.round(this.contractHelper.calculatedEndMileage.mileage);
    list.push(
      {
        label: this.i18n["calculatedEndMileage"],
        value: (isNaN(value) ? 0 : value) + "km"
      },
      {
        label: this.i18n["allowedEndMileage"],
        value: allowedByContract + "km"
      }
    );

    if (allowedByContract < this.contractHelper.calculatedEndMileage.mileage) {
      list.push({
        label: this.i18n["expectedDifference"],
        value:
          Math.round(((this.contractHelper.calculatedEndMileage.mileage || 1) / (allowedByContract || 1) - 1) * 100) +
          "%",
        color: "red"
      });
    } else {
      list.push({
        label: this.i18n["expectedDifference"],
        value:
          Math.round(((allowedByContract || 1) / (this.contractHelper.calculatedEndMileage.mileage || 1) - 1) * 100) +
          "%",
        color: "green"
      });
    }

    list.push(
      {
        label: this.i18n["perDay"],
        value: Math.round(this.contractHelper.mileagePerDay * 100) / 100
      },
      {
        label: this.i18n["perWeek"],
        value: Math.round(this.contractHelper.mileagePerWeek * 100) / 100
      },
      {
        label: this.i18n["perMonth"],
        value: Math.round(this.contractHelper.mileagePerMonth * 100) / 100
      }
    );

    return list;
  }
}
