import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";

export enum WebhookRouteNames {
  WEBHOOK_CONFIG_TABLE = "WEBHOOK_CONFIG_TABLE",
  WEBHOOK_CONFIG_DETAIL = "WEBHOOK_CONFIG_DETAIL"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type WebhookConfig = { webhookConfigId: string };
type Query = { query?: Record<string, string> };

export class WebhookGoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    webhookConfigTable: (d: Partner & Query) => {
      return {
        name: WebhookRouteNames.WEBHOOK_CONFIG_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    webhookConfigDetail: (d: Partner & WebhookConfig & Query) => {
      return {
        name: WebhookRouteNames.WEBHOOK_CONFIG_DETAIL,
        params: { partnerId: d.partnerId, webhookConfigId: d.webhookConfigId },
        query: d.query
      };
    }
  };

  goToWebhookConfigTable(d: Tab & Partner) {
    this.go(WebhookGoToHelper.locations.webhookConfigTable(d), d.newTab);
  }

  goToWebhookConfigDetail(d: Tab & Partner & WebhookConfig) {
    this.go(WebhookGoToHelper.locations.webhookConfigDetail(d), d.newTab);
  }
}
