import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";
import { BookingAvailabilityGen } from "@/services/booking/v1/data-contracts";
import { DaysOfTheWeekHelper, DaysOfTheWeekOrder } from "../daysOfTheWeekHelper";

type BookingAvailabilityDisplay = BookingAvailabilityGen & { dayName: WeekDaysEnum };

/**
 * Class to help manipulate the Availabilities array.
 */
export class AvailabilityMapper {
  /**
   * Get the availabilities array sorted either from Sunday to Saturday or from Monday to Sunday.
   * You should know how the array is ordered before calling
   * @param availability the availability array with the 7 days
   * @param orderFrom indicates how the availabilities array is ordered(Javascript: Sun-Sat, Normal: Mon-Sun)
   * @param orderTo indicates how the availabilities array should be ordered(Javascript: Sun-Sat, Normal: Mon-Sun)
   * @returns the array ordered by the prefer days of week schedule.
   */
  public static getAvailabilitySorted(
    availability: BookingAvailabilityGen[],
    orderFrom: DaysOfTheWeekOrder,
    orderTo: DaysOfTheWeekOrder
  ) {
    if (orderFrom === orderTo) {
      throw new Error("Orders cannot be the same!");
    }

    // Get days in both orderings
    const daysOriginalOrder = DaysOfTheWeekHelper.getDaysOfTheWeek(orderFrom);
    const daysToOrder = DaysOfTheWeekHelper.getDaysOfTheWeek(orderTo);

    // Get an array with the day added
    const availabilityWithDays = this.createAvailabilityWithDay(availability, daysOriginalOrder);

    // Sort array to by week ordered as either Sun-Sat or Mon-Sun
    // So instead of Monday being at position 0, Sunday will be at position 0
    this.sortArrayByDayOfWeek(availabilityWithDays, daysToOrder);

    // Remove the dayName property
    const availabilitySorted = availabilityWithDays.map(a => {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { dayName: _, ...rest } = a;
      return rest;
    });

    return availabilitySorted;
  }

  /**
   * Sort availabilities by days of the week schedule(either a whole week ordered Sun-Sat, or Mon-Sun)
   * @param availabilityArray availabilities array
   * @param daysOfTheWeek array with the days of the week
   */
  private static sortArrayByDayOfWeek(availabilityArray: BookingAvailabilityDisplay[], daysOfTheWeek: WeekDaysEnum[]) {
    availabilityArray.sort((availabilityPrev, availabilityNext) => {
      const prevDayIndex = daysOfTheWeek.indexOf(availabilityPrev.dayName);
      const nextDayIndex = daysOfTheWeek.indexOf(availabilityNext.dayName);

      return prevDayIndex > nextDayIndex ? 1 : -1;
    });
  }

  /**
   * Adds the day of the week as an additional property to the availability object
   * @param availabilityArray the availability array
   * @param daysOfTheWeek
   * @returns
   */
  private static createAvailabilityWithDay(
    availabilityArray: BookingAvailabilityGen[],
    daysOfTheWeek: WeekDaysEnum[]
  ): BookingAvailabilityDisplay[] {
    return availabilityArray.map((availability, index) => {
      return {
        ...availability,
        dayName: daysOfTheWeek[index]
      };
    });
  }
}
