/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreatePoolDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivPoolControllerGetAllParamsGen,
  MrfiktivPoolViewModelGen,
  MrfiktivUpdatePoolDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Pool<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags pool
   * @name PoolControllerCreate
   * @summary (POOL-CREATE) Creates a pool
   * @request POST:/partner/{partnerId}/pool
   * @secure
   * @response `201` `MrfiktivPoolViewModelGen` Id of created operation
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  poolControllerCreate = (partnerId: string, data: MrfiktivCreatePoolDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPoolViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/pool`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags pool
   * @name PoolControllerGetAll
   * @summary (POOL-READ) Get all pools
   * @request GET:/partner/{partnerId}/pool
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPoolViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  poolControllerGetAll = ({ partnerId, ...query }: MrfiktivPoolControllerGetAllParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivPoolViewModelGen[] }, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/pool`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags pool
   * @name PoolControllerGetOne
   * @summary (POOL-READ) Get a pool by id
   * @request GET:/partner/{partnerId}/pool/{poolId}
   * @secure
   * @response `200` `MrfiktivPoolViewModelGen` Id of created operation
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  poolControllerGetOne = (partnerId: string, poolId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPoolViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/pool/${poolId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags pool
   * @name PoolControllerUpdate
   * @summary (POOL-UPDATE) Update a pool
   * @request PUT:/partner/{partnerId}/pool/{poolId}
   * @secure
   * @response `200` `MrfiktivPoolViewModelGen` the updated pool
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  poolControllerUpdate = (
    partnerId: string,
    poolId: string,
    data: MrfiktivUpdatePoolDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPoolViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/pool/${poolId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags pool
   * @name PoolControllerDelete
   * @summary (POOL-DELETE)Delete a pool
   * @request DELETE:/partner/{partnerId}/pool/{poolId}
   * @secure
   * @response `200` `MrfiktivPoolViewModelGen` the deleted pool
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  poolControllerDelete = (partnerId: string, poolId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPoolViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/pool/${poolId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
