import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { UserModule } from "@/store/modules/me-user.store";

export const predefinedTicketFilter = [
  {
    name: "components.FilterCardPagination.predefinedFilter.ticket.closed",
    filter: [
      {
        key: "state",
        operation: "$eq",
        value: TicketStatusEnum.CLOSED
      }
    ]
  },
  {
    name: "components.FilterCardPagination.predefinedFilter.ticket.open",
    filter: [
      {
        key: "state",
        operation: "$eq",
        value: TicketStatusEnum.OPEN
      }
    ]
  },
  {
    name: "components.FilterCardPagination.predefinedFilter.ticket.drafts",
    filter: [
      {
        key: "state",
        operation: "$eq",
        value: TicketStatusEnum.DRAFT
      }
    ]
  },
  {
    name: "project.ticket.myTickets",
    filter: [
      {
        key: "assignees",
        operation: "$eq",
        value: UserModule.userId
      }
    ]
  }
];
