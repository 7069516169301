

























import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import ContractForm from "@/components/fleet/ContractForm.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { Component } from "vue-property-decorator";
import FleetContractDetailView from "./FleetContractDetailView.vue";

@Component({
  components: {
    TheLayoutPortal,
    LatestEntriesCardEmpty,
    ContractForm
  }
})
export default class FleetContractFormView extends FleetContractDetailView {}
