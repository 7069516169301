






















import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class IconSelect extends Vue {
  icons = [
    "air-conditioner",
    "airbag",
    "alpha-d",
    "alpha-n",
    "alpha-p",
    "alpha-r",
    "arrow-left-bold",
    "arrow-left-bold-outline",
    "arrow-right-bold",
    "arrow-right-bold-outline",
    "battery",
    "battery-10",
    "battery-20",
    "battery-30",
    "battery-40",
    "battery-50",
    "battery-60",
    "battery-70",
    "battery-80",
    "battery-90",
    "battery-charging",
    "battery-charging-10",
    "battery-charging-100",
    "battery-charging-20",
    "battery-charging-30",
    "battery-charging-40",
    "battery-charging-50",
    "battery-charging-60",
    "battery-charging-70",
    "battery-charging-80",
    "battery-charging-90",
    "battery-charging-outline",
    "battery-outline",
    "bugle",
    "car",
    "car-2-plus",
    "car-3-plus",
    "car-arrow-left",
    "car-arrow-right",
    "car-back",
    "car-battery",
    "car-brake-abs",
    "car-brake-alert",
    "car-brake-fluid-level",
    "car-brake-hold",
    "car-brake-low-pressure",
    "car-brake-parking",
    "car-brake-retarder",
    "car-brake-temperature",
    "car-brake-worn-linings",
    "car-child-seat",
    "car-clock",
    "car-clutch",
    "car-cog",
    "car-connected",
    "car-convertible",
    "car-coolant-level",
    "car-cruise-control",
    "car-defrost-front",
    "car-defrost-rear",
    "car-door",
    "car-door-lock",
    "car-electric",
    "car-electric-outline",
    "car-emergency",
    "car-esp",
    "car-estate",
    "car-hatchback",
    "car-info",
    "car-key",
    "car-lifted-pickup",
    "car-light-alert",
    "car-light-dimmed",
    "car-light-fog",
    "car-light-high",
    "car-limousine",
    "car-multiple",
    "car-off",
    "car-outline",
    "car-parking-lights",
    "car-pickup",
    "car-search",
    "car-search-outline",
    "car-seat",
    "car-seat-cooler",
    "car-seat-heater",
    "car-select",
    "car-settings",
    "car-shift-pattern",
    "car-side",
    "car-speed-limiter",
    "car-sports",
    "car-tire-alert",
    "car-traction-control",
    "car-turbocharger",
    "car-wash",
    "car-windshield",
    "car-windshield-outline",
    "car-wireless",
    "car-wrench",
    "caravan",
    "cellphone-nfc",
    "coolant-temperature",
    "counter"
  ];
  @Prop()
  icon!: string;
  @Prop()
  disabled!: boolean;

  @Watch("icon")
  emitIcon() {
    this.$emit("icon-select", this.icon);
  }
}
