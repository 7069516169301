import { RRule } from "rrule";
import { RecurringEventEnum } from "../dto/event/event-ui.dto";

export const eventTemplates = [
  {
    enum: RecurringEventEnum.HU_AU_2,
    title: "views.fleet.EventTemplates.huau2.title",
    description: "views.fleet.EventTemplates.huau2.description",
    isRecurring: true,
    isAllDay: true,
    options: { freq: RRule.YEARLY, interval: 2 }
  },
  {
    enum: RecurringEventEnum.HU_AU_1,
    title: "views.fleet.EventTemplates.huau1.title",
    description: "views.fleet.EventTemplates.huau1.description",
    isRecurring: true,
    isAllDay: true,
    options: { freq: RRule.YEARLY, interval: 1 }
  },
  {
    enum: RecurringEventEnum.SP,
    title: "views.fleet.EventTemplates.sp.title",
    description: "views.fleet.EventTemplates.sp.description",
    isRecurring: true,
    isAllDay: true,
    options: { freq: RRule.YEARLY, interval: 1 }
  },
  {
    enum: RecurringEventEnum.UVV,
    title: "views.fleet.EventTemplates.uvv.title",
    description: "views.fleet.EventTemplates.uvv.description",
    isRecurring: true,
    isAllDay: true,
    options: { freq: RRule.YEARLY, interval: 1 }
  },
  {
    enum: RecurringEventEnum.TP,
    title: "views.fleet.EventTemplates.tp.title",
    description: "views.fleet.EventTemplates.tp.description",
    isRecurring: true,
    isAllDay: true,
    options: { freq: RRule.YEARLY, interval: 2 }
  },
  {
    enum: RecurringEventEnum.DGUV,
    title: "views.fleet.EventTemplates.dguv.title",
    description: "views.fleet.EventTemplates.dguv.description",
    isRecurring: true,
    isAllDay: true,
    options: { freq: RRule.YEARLY, interval: 1 }
  },
  {
    enum: RecurringEventEnum.CODE_XL,
    title: "views.fleet.EventTemplates.codexl.title",
    description: "views.fleet.EventTemplates.codexl.description",
    isRecurring: true,
    isAllDay: true,
    options: { freq: RRule.YEARLY, interval: 1 }
  }
];
