























import Tooltip from "@/components/utility/tooltip.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStateMap } from "@/store/mapper/report-icon.map";
import { ReportControlModule } from "@/store/modules/report-control.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    Tooltip,
    PaginatedTable
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ReportControlView extends PartnerFallbackMixin {
  get partnerId() {
    return this.$route.params.partnerId;
  }

  get pagination() {
    return ReportControlModule;
  }

  get baseQuery() {
    return { partnerId: this.partnerId };
  }

  get headers() {
    return [
      { text: this.$t("objects.reportControl.created"), value: "timestamp.created" },
      { text: this.$t("objects.reportControl.status"), value: "progressStatus" },
      { text: this.$t("objects.reportControl.partnerId"), value: "receiver.companyName" },
      { text: this.$t("objects.reportControl.id"), value: "id" }
    ];
  }

  color(progressStatus: ProgressStatusEnum) {
    return reportStateMap.get(progressStatus)?.color || "blue-grey lighten-4";
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
  }
}
