











import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import HandoverDetail from "@/components/handover/HandoverDetail.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { HandoverSnapshotModule } from "@/store/modules/handover-snapshot.store";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import { PartnerModule } from "@/store/modules/partner";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";

@Component({
  components: { TheLayoutPortal, MHeader, HandoverDetail, LatestEntriesCardEmpty }
})
export default class HandoverDetailView extends mixins(Vue, PermissionMixin, PartnerFallbackMixin) {
  isLoading = false;

  handover: IHandover | null = null;

  vehicles: IVehicle[] = [];

  get partner() {
    return PartnerModule.partner;
  }

  async fetchHandoverReports() {
    if (!this.handover) return;

    HandoverReportModule.setHiddenFilters([
      new PageFilterElement({ key: "handover.handoverId", value: this.handover.id }),
      new PageFilterElement({ key: "progressStatus", operation: "$ne", value: ProgressStatusEnum.DELETED })
    ]);
    await HandoverReportModule.fetchAll({ partnerId: this.handover.partnerId });
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;

    HandoverReportModule.setHiddenFilters([
      new PageFilterElement({ key: "handover.handoverId", value: handoverId }),
      new PageFilterElement({ key: "progressStatus", operation: "$ne", value: ProgressStatusEnum.DELETED })
    ]);
  }

  async fetchVehicles() {
    const partnerId = this.$route.params.partnerId;
    const vehicleIds = this.handover?.vehicleIds ?? [];

    const vehiclesAsync = vehicleIds.map(v =>
      new Vehicle({
        partnerId: partnerId,
        id: v
      }).fetch()
    );

    this.vehicles.splice(0);
    this.vehicles.push(...(await Promise.all(vehiclesAsync)));
  }

  async fetchSnapshots() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    try {
      await HandoverSnapshotModule.fetchAll({ partnerId, handoverId });
    } catch (e) {
      handleError(e);
    }
  }

  async mounted() {
    try {
      this.isLoading = true;
      await this.trySetByRouteOrDefault();

      await this.fetchHandover();
      await this.fetchSnapshots();
      await this.fetchVehicles();
      await this.fetchHandoverReports();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
