var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "isGoHomeOnBack": false
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }, [_c('partner-user-detail', {
    attrs: {
      "loading": _vm.isLoadingDetail
    },
    model: {
      value: _vm.user,
      callback: function callback($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }