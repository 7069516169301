






import PartnerManageStepper from "@/components/partner/PartnerManageStepper.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { Component, Vue } from "vue-property-decorator";
import { ManageStepperDescriptionTypes } from "@/store/enum/partner/manage-stepper-description-type.enum";
import { ManageStepperScreenTypes } from "@/store/enum/partner/manage-stepper-screen-types.enum";
import { ManageStepperEnum } from "@/store/enum/partner/manage-stepper.enum";
import { ManageStepperSetupSetting } from "@/lib/interfaces/partner/partnerManageStepperSetupSetting.interface";
import { IPartnerManageStepperStep } from "@/lib/interfaces/partner/partnerManageStepperStep.interface";
import { SettingModule } from "@/store/modules/setting.store";
import { KsrSettingsKeys } from "@/store/enum/manage/ksr-setting-keys.enum";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: {
    PartnerManageStepper,
    LayoutSimple
  }
})
export default class PartnerManageStepperKSRView extends Vue {
  mounted() {
    SettingModule.clearPartnerSetting();
  }

  /**
   * steps of DA Stepper
   */
  get steps(): IPartnerManageStepperStep[] {
    return [
      {
        type: ManageStepperScreenTypes.DESCRIPTION,
        descriptionType: ManageStepperDescriptionTypes.KSR_SETUP_ASSISTANT
      },
      { type: ManageStepperScreenTypes.SETUP, setupSettings: this.ksrSetupSettings, context: ManageStepperEnum.KSR },
      { type: ManageStepperScreenTypes.CREDENTIALS, context: ManageStepperEnum.KSR },
      // { type: ManageStepperScreenTypes.PREDEFINED_SETTING, key: "KSR_COMPANY_ID" },
      // { type: ManageStepperScreenTypes.CUSTOM_SETTING },
      { type: ManageStepperScreenTypes.DESCRIPTION, descriptionType: ManageStepperDescriptionTypes.END }
    ];
  }

  /**
   * settings that are used in DA setup step
   */
  get ksrSetupSettings(): ManageStepperSetupSetting[] {
    return [
      {
        key: KsrSettingsKeys.CUSTOMER_OSA_ID,
        value: ""
      },
      {
        key: KsrSettingsKeys.COMPANY_ID,
        value: "",
        rules: [isNumberRule(), requiredRule()]
      }
    ];
  }
}
