




























































import LatestFormEntriesCard from "@/components/cards/LatestFormEntriesCard.vue";
import LatestReportEntriesCard from "@/components/cards/LatestReportEntriesCard.vue";
import StatisticsCard from "@/components/cards/StatisticsCard.vue";
import Debug from "@/components/utility/Debug.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ExampleModule } from "@/store/example.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import PartnerUserMissingPermissionsDialog from "@/components/partner/PartnerUserMissingPermissionsDialog.vue";
import { PartnerUser } from "@/models/user.entity";
import { UserModule } from "@/store/modules/me-user.store";

@Component({
  components: {
    TheLayoutPortal,
    StatisticsCard,
    LatestFormEntriesCard,
    LatestReportEntriesCard,
    PartnerUserMissingPermissionsDialog,
    Debug
  }
})
export default class PartnerDashboard extends mixins(PermissionMixin, PartnerFallbackMixin) {
  foundPartner = false;
  loading = false;
  loadingReport = false;
  loadingForm = false;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  get forms() {
    return PartnerModule.forms;
  }

  get reports() {
    return ReportPaginationModule.paginationList;
  }

  get reportsLastMonth() {
    const monthInMilliseconds = 2629800000;
    return ReportPaginationModule.paginationList.filter(
      report => Date.parse(report.timestamps.created) >= Date.now() - monthInMilliseconds
    ).length;
  }

  get formsLastMonth() {
    const monthInMilliseconds = 2629800000;
    return PartnerModule.forms.filter(form => Date.parse(form.timestamps?.created) >= Date.now() - monthInMilliseconds)
      .length;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get authenticatedPartnerUser() {
    return new PartnerUser({ ...UserModule.user, partnerId: this.partnerId });
  }

  get headerUrl() {
    return PartnerModule.partner.settings?.headerImageUrl || "";
  }

  async mounted() {
    try {
      this.loading = true;
      this.loadingReport = true;
      this.loadingForm = true;

      this.foundPartner = await this.trySetByRouteOrDefault();
      this.loading = false;

      const promises = [];

      if (this.canReadReport) {
        ReportPaginationModule.setSearch("");

        // Get all reports from the last 6 months for the partner (6 months because the statistic card shows the last 6 month)
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        sixMonthsAgo.setDate(0);

        ReportPaginationModule.setItemsPerPage(250);
        ReportPaginationModule.setFilters([
          new PageFilterElement({
            key: "timestamps.created",
            operation: PageFilterOperationEnum.GREATER_EQUAL,
            value: sixMonthsAgo.toISOString()
          })
        ]);
        promises.push(
          ReportPaginationModule.fetchAll({
            partnerId: PartnerModule.partner._id
          }).then(() => {
            this.loadingReport = false;
          })
        );
      }

      if (this.canReadForm) {
        promises.push(
          PartnerModule.getAllFormsForPartner().then(() => {
            this.loadingForm = false;
          })
        );
      }

      await Promise.all(promises);
    } catch {
      this.$toast.error("Ooops... Fehler beim Laden der Anwendung!");
    } finally {
      this.loading = false;
      this.loadingReport = false;
      this.loadingForm = false;
    }

    if (ConfigModule.debug) {
      ExampleModule.setSearch("");

      await ExampleModule.fetchFirstPage({ partnerId: PartnerModule.partner.id });
      await ExampleModule.update(ExampleModule.entities[0].id);
      ExampleModule.setSearch(ExampleModule.entities[0].id);
    }
  }

  route(route: string) {
    this.$router.push({ name: route });
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get schadensmeldungStats() {
    return {
      statTitle: "views.portal.PartnerDashboard.statTitle.report",
      subtitle: "views.portal.PartnerDashboard.statTitle.subtitle",
      icon: "mdi-car-wrench",
      color: this.loadingColor,
      statistics: this.reportsLastMonth,
      change: ""
    };
  }

  get landingzoneStats() {
    return {
      statTitle: "views.portal.PartnerDashboard.statTitle.form",
      subtitle: "views.portal.PartnerDashboard.statTitle.subtitle",
      icon: "mdi-email-multiple-outline",
      color: this.loadingColor,
      statistics: this.formsLastMonth,
      change: ""
    };
  }

  get filteredExamples() {
    return ExampleModule.filtered.map(v => {
      return {
        name: v.customerName,
        id: v.id
      };
    });
  }

  get allExamples() {
    return ExampleModule.entities.map(v => {
      return {
        name: v.customerName,
        id: v.id
      };
    });
  }
}
