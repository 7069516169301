
























































import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import MHeader, { IAction } from "../utility/mmmint/MHeader.vue";
import SideCard from "../utility/SideCard.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { IAnonymization } from "@/models/anonymization.entity";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import { AnonymizationStatusEnum } from "@/lib/enum/AnonymizationStatus.enum";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "@/components/utility/mmmint/MDetailTable.vue";
import saveAs from "file-saver";

@Component({
  components: {
    MHeader,
    SideCard,
    LatestEntriesCardEmpty,
    MDetailViewGrid,
    MDetailTable
  }
})
export default class AnonymizationSideCard extends mixins(PartnerFallbackMixin) {
  @Prop()
  value!: IAnonymization;

  @Watch("value")
  async onValueChange() {
    await this.fetch();
  }

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  isLoading = false;

  get actions(): IAction[] {
    return [
      {
        text: "Download",
        key: "primaryAction",
        icon: "mdi-cog-outline"
      }
    ];
  }

  async processAction(action: IAction) {
    switch (action.key) {
      case "primaryAction":
        await this.downloadImage();
    }
  }

  get isDownloadDisabled() {
    return !this.value.anonymizedImage?.url;
  }

  async downloadImage() {
    if (!this.value.anonymizedImage?.url) {
      return;
    }
    const resp = await fetch(this.value.anonymizedImage?.url).then(resp => resp);

    /**
     * TODO: Fix viewmodel with metaData, store with original filename
     */
    await saveAs(await resp.blob(), this.value.downloadFileName);
  }

  /**
   * Returns the appropriate waiting status text based on the anonymization status.
   */
  get waitingStatusText(): string {
    switch (this.value.status) {
      case AnonymizationStatusEnum.PENDING:
      case AnonymizationStatusEnum.IN_PROGRESS:
        return this.$t("anonymization.sideCard.anonymizedDeletedI").toString();
      case AnonymizationStatusEnum.FAILED:
        return this.$t("anonymization.sideCard.anonymizedDeletedII").toString();
      case AnonymizationStatusEnum.COMPLETED:
        return this.$t("anonymization.sideCard.anonymizedDeletedIII").toString();
      default:
        return this.$t("anonymization.sideCard.anonymizedDeletedIIII").toString();
    }
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    return [
      {
        key: "status",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          { value: AnonymizationStatusEnum.PENDING, displayValue: AnonymizationStatusEnum.PENDING, color: "info" },
          {
            value: AnonymizationStatusEnum.IN_PROGRESS,
            displayValue: AnonymizationStatusEnum.IN_PROGRESS,
            color: "warning"
          },
          {
            value: AnonymizationStatusEnum.COMPLETED,
            displayValue: AnonymizationStatusEnum.COMPLETED,
            color: "success"
          },
          { value: AnonymizationStatusEnum.FAILED, displayValue: AnonymizationStatusEnum.FAILED, color: "error" }
        ]
      },
      {
        key: "timestamp.created",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      },
      {
        key: "calculationTime",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "totalTime",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "config.face",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [{ value: true, displayValue: "Ja", color: "success" }]
      },
      {
        key: "config.numberplate",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [{ value: true, displayValue: "Ja", color: "success" }]
      },
      {
        key: "config.deleteOriginalImage",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [{ value: true, displayValue: "Ja", color: "error" }]
      },
      {
        key: "config.deleteAnonymizedImage",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [{ value: true, displayValue: "Ja", color: "error" }]
      },
      {
        key: "config.model",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      }
    ];
  }

  get originalImage() {
    return this.value.originalImage?.url;
  }

  get anonymizedImage() {
    return this.value.anonymizedImage?.url;
  }

  close() {
    this.$emit("close");
  }

  async fetch() {
    try {
      this.isLoading = true;

      await this.value.fetch();
    } catch (error) {
      handleError(error);
      this.close();
    } finally {
      this.isLoading = false;
    }
  }

  async mounted() {
    await this.fetch();
  }
}
