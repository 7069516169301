var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ma-2"
  }, [_c('fleet-calendar-card', {
    attrs: {
      "parsed": _vm.parsed,
      "loading": _vm.loadingEvents,
      "vehicleId": _vm.currentVehicleId,
      "partnerId": _vm.partnerId
    },
    on: {
      "update:vehicleId": function updateVehicleId($event) {
        _vm.currentVehicleId = $event;
      },
      "update:vehicle-id": function updateVehicleId($event) {
        _vm.currentVehicleId = $event;
      },
      "updateEventList": function updateEventList($event) {
        return _vm.$emit('updateEventList', $event);
      },
      "openVehicleSideCard": function openVehicleSideCard($event) {
        return _vm.$emit('openVehicleSideCard', $event);
      },
      "openTicketSideCard": function openTicketSideCard($event) {
        return _vm.$emit('openTicketSideCard', $event);
      },
      "openReportSideCard": function openReportSideCard($event) {
        return _vm.$emit('openReportSideCard', $event);
      },
      "openContractDetail": function openContractDetail($event) {
        return _vm.$emit('openContractDetail', $event);
      },
      "openEventSideCard": function openEventSideCard($event) {
        return _vm.$emit('openEventSideCard', $event);
      }
    },
    model: {
      value: _vm.calendarFocus,
      callback: function callback($$v) {
        _vm.calendarFocus = $$v;
      },
      expression: "calendarFocus"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }