






import FleetImportCard from "@/components/fleet/FleetImportCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    FleetImportCard
  }
})
export default class FleetImportView extends mixins(PartnerFallbackMixin, PermissionMixin) {
  loading = false;

  get vehicles() {
    return VehicleModule.vehicles;
  }
}
