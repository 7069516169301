
































































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { IFleetHomeVehicleTableMonths } from "./FleetHomeVehicleTable.vue";
import FleetHomeVehicleTableEventsListItem from "./FleetHomeVehicleTableEventsListItem.vue";
import FleetEventMenuCard from "./FleetEventMenuCard.vue";
import SideCard from "../utility/SideCard.vue";
import FleetVehicleMenuCard from "./FleetVehicleMenuCard.vue";
import {
  VehicleAggregation,
  VehicleAggregationTimeFrame,
  VehicleEventEvent,
  VehicleEventTypeEnum
} from "@/store/modules/fleet-aggregation.store";
import { debounce } from "debounce";

@Component({
  components: { FleetHomeVehicleTableEventsListItem, SideCard, FleetEventMenuCard, FleetVehicleMenuCard }
})
export default class FleetHomeVehicleTableEvents extends DarkModeHighlightMixin {
  @Prop()
  vehicleAggregation!: VehicleAggregation;

  @Prop()
  prev!: VehicleAggregationTimeFrame;

  @Prop()
  curr!: VehicleAggregationTimeFrame;

  @Prop()
  next!: VehicleAggregationTimeFrame;

  @Prop()
  monthMethods!: IFleetHomeVehicleTableMonths;

  isExpandTop = false;

  isExpandedBottom = false;

  isExpand = true;

  height = document.documentElement.clientHeight;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  previousEvents: VehicleEventEvent[] = [];

  currentEvents: VehicleEventEvent[] = [];

  nextEvents: VehicleEventEvent[] = [];

  prevEventKey = 0;

  mounted() {
    this.setPrevEvents();
    this.setCurrEvents();
    this.setNextEvents();
    this.removeListeners();
    this.addListeners();
  }

  beforeDestroy() {
    this.removeListeners();
  }

  onResize() {
    this.height = document.documentElement.clientHeight;
  }

  sortByStart(list: VehicleEventEvent[]) {
    list.sort((a, b) => {
      const delta = new Date(a.start).getTime() - new Date(b.start).getTime();
      if (delta) return delta;

      return a.data[0].summary < b.data[0].summary ? -1 : 1;
    });

    return list;
  }

  debounceSetPrevEvents(eventType: VehicleEventTypeEnum) {
    if (eventType !== VehicleEventTypeEnum.EVENT) return;

    debounce(this.setPrevEvents, 200)();
  }

  setPrevEvents() {
    const events = Array.from(this.prev.aggregation.event.values());
    const sorted = this.sortByStart(events);
    this.previousEvents.splice(0, this.previousEvents.length, ...sorted);
  }

  debounceSetCurrEvents(eventType: VehicleEventTypeEnum) {
    if (eventType !== VehicleEventTypeEnum.EVENT) return;

    debounce(this.setCurrEvents, 200)();
  }

  setCurrEvents() {
    const events = Array.from(this.curr.aggregation.event.values());
    const sorted = this.sortByStart(events);
    this.currentEvents.splice(0, this.currentEvents.length, ...sorted);
  }

  debounceSetNextEvents(eventType: VehicleEventTypeEnum) {
    if (eventType !== VehicleEventTypeEnum.EVENT) return;

    debounce(this.setNextEvents, 200)();
  }

  setNextEvents() {
    const events = Array.from(this.next.aggregation.event.values());
    const sorted = this.sortByStart(events);
    this.nextEvents.splice(0, this.nextEvents.length, ...sorted);
  }

  removeListeners() {
    this.prev.$off(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetPrevEvents);
    this.curr.$off(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetCurrEvents);
    this.next.$off(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetNextEvents);
  }

  addListeners() {
    this.prev.$on(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetPrevEvents);
    this.curr.$on(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetCurrEvents);
    this.next.$on(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, this.debounceSetNextEvents);
  }
}
