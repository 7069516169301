var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": "Neuer Fahrzeugschein"
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('drop-field', {
    attrs: {
      "file": _vm.file,
      "icon": "mdi-newspaper-variant-outline",
      "isLoading": _vm.isLoading,
      "isUploaded": _vm.isUploading,
      "text": "Fahrzeugschein"
    },
    on: {
      "uploaded": _vm.changeUpload
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "disabled": "",
      "loading": _vm.isLoading,
      "text": "Fahrzeugschein",
      "label": "Session Id"
    },
    model: {
      value: _vm.fahrzeugscheinSessionId,
      callback: function callback($$v) {
        _vm.fahrzeugscheinSessionId = $$v;
      },
      expression: "fahrzeugscheinSessionId"
    }
  }), _c('v-btn', {
    attrs: {
      "loading": _vm.isLoading,
      "disabled": !_vm.fahrzeugscheinDetailAvailable,
      "color": "white",
      "middle": "",
      "light": "",
      "block": ""
    },
    on: {
      "click": _vm.redirectFahrzeugschein
    }
  }, [_vm._v(" Zum Fahrzeugschein ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }