






















































import PartnerReportDetailSideCard from "@/components/partner/PartnerReportDetailSideCard.vue";
import RefsInspection from "@/components/utility/RefsInspection.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { ITableWrapperHeader } from "@/lib/types/tableWrapper";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { Handover, IHandover } from "@/models/handover.entity";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IReport } from "@/models/report.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStateMap } from "@/store/mapper/report-icon.map";
import { HandoverReportModule } from "@/store/modules/handover-report.store";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { IVehicle } from "@/models/vehicle.entity";
import RefsVehicle from "@/components/utility/RefsVehicle.vue";

@Component({
  components: {
    PaginatedTable,
    RefsInspection,
    PartnerReportDetailSideCard,
    AssigneePreview,
    RefsVehicle
  }
})
export default class HandoverReportTable extends mixins(Vue, PermissionMixin) {
  readonly handoverReportStore = HandoverReportModule;

  @Prop()
  partnerId!: string;

  @Prop()
  handoverId!: string;

  isLoadingReport = false;
  isLoading = false;

  selectedReport: IReport | null = null;

  handover: IHandover | null = null;

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    predefinedFilter.push({
      name: "components.FilterCardPagination.predefinedFilter.ticket.open",
      filter: [
        new PageFilterElement({
          key: "progressStatus",
          value: ProgressStatusEnum.FINISHED,
          operation: PageFilterOperationEnum.NOT_EQUAL
        })
      ]
    });

    return predefinedFilter;
  }

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: this.$t("common.nouns.number").toString(),
        value: "handover.number",
        width: "100px",
        align: "center"
      },
      {
        text: this.$t("objects.thgMeterReading.status").toString(),
        value: "progressStatus",
        width: "100px"
      },
      {
        text: this.$t("objects.report.isFixed").toString(),
        value: "isFixed",
        width: "110px"
      },
      {
        text: this.$t("project.ticket.assignees").toString(),
        value: "assignees",
        width: "150px"
      },
      {
        text: this.$t("objects.report.title").toString(),
        value: "title"
      },
      {
        text: this.$t("finding.contact").toString(),
        value: "companyName"
      },
      {
        text: this.$t("common.nouns.vehicle").toString(),
        value: "vehicleId"
      },
      {
        text: this.$t("objects.vehicle.blueprint.text").toString(),
        value: "position.blueprint.name"
      },
      {
        text: this.$t("inspection.title").toString(),
        value: "handover.foundInInspection"
      },
      {
        text: this.$t("objects.report.timestamp.created").toString(),
        value: "timestamp.created",
        width: "90px",
        type: "date"
      }
    ];
  }

  color(progressStatus: ProgressStatusEnum) {
    return reportStateMap.get(progressStatus)?.color || "blue-grey lighten-4";
  }

  beforeMount() {
    HandoverReportModule.setHiddenFilters([
      new PageFilterElement({ key: "handover.handoverId", value: this.handoverId }),
      new PageFilterElement({ key: "progressStatus", operation: "$ne", value: ProgressStatusEnum.DELETED })
    ]);
  }

  async mounted() {
    this.isLoading = true;
    try {
      await this.fetchHandover();
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async openReportSideCard(item: IReport) {
    this.selectedReport = null;
    this.isLoadingReport = true;
    this.$nextTick(async () => {
      await item.fetch().catch(handleError);
      this.selectedReport = item;
      new GoToHelper(this.$router).setUrl({
        name: "HandoverReportTableView",
        params: {
          partnerId: this.partnerId,
          handoverId: this.handoverId
        },
        query: { reportId: item.id }
      });
      this.isLoadingReport = false;
    });
  }

  closeReportSideCard() {
    this.selectedReport = null;
    new GoToHelper(this.$router).setUrl({
      name: "HandoverReportTableView",
      params: {
        partnerId: this.partnerId,
        handoverId: this.handoverId
      },
      query: {}
    });
  }

  inspectionTitle(inspectionId: string): string {
    return this.handover?.inspections.find(i => i.id === inspectionId)?.title ?? "";
  }

  getVehicle(vehicleId: string): IVehicle {
    return VehicleModule.maps.id.get(vehicleId)[0];
  }
}
