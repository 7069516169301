









































import TemplateDialog from "@/components/template/TemplateDialog.vue";
import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { simpleDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IPartnerUser, PartnerUser } from "@/models/user.entity";
import { MrfiktivShortUserViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import PartnerUserDetail from "./PartnerUserDetail.vue";

@Component({
  components: {
    TheLayoutPortalSplit,
    TheLayoutPortalList,
    TemplateDialog,
    PartnerUserDetail
  },
  filters: {
    simpleDate,
    getFlagEmoji
  }
})
export default class PartnerUserList extends mixins(PaginationListVueMixin, PartnerFallbackMixin) {
  isLoadingDetail = false;
  isLoading = false;

  user: IPartnerUser | null = null;

  get ActionEnum() {
    return ActionEnum;
  }

  get ResourceEnum() {
    return ResourceEnum;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get to() {
    if (!this.user?.contact?.email) {
      return [];
    }

    return [this.user.contact.email];
  }

  get from() {
    return PartnerModule.partner;
  }

  get paginationList() {
    return this.getModule().paginationList;
  }

  getModule() {
    return PartnerUserModule;
  }

  isActive(item: IPartnerUser | IPartnerUser) {
    return item.id === this.user?.id ? "v-item--active v-list-item--active" : "";
  }

  async mounted() {
    await this.trySetByRouteOrDefault();

    try {
      this.isLoading = true;
      await this.refresh({ partnerId: this.partnerId });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }

    const item = this.getModule().paginationList[0];
    if (item) {
      await this.loadDetail(item);
    }
  }

  async set(item: any) {
    if (this.$vuetify.breakpoint.smAndDown) {
      await this.$router.push({
        name: "PartnerUserDetailView",
        params: { userId: item.id, partnerId: this.partnerId }
      });
    } else {
      await this.loadDetail(item);
    }
  }

  async loadDetail(user: IPartnerUser | MrfiktivShortUserViewModelGen) {
    this.isLoadingDetail = true;
    this.user = await new PartnerUser({ ...user, partnerId: this.partnerId }).fetchStatus();
    this.isLoadingDetail = false;
  }

  async loadAllFromLastId() {
    if (this.paginationList.length === 0) {
      await PartnerUserModule.fetchFirstPage({
        partnerId: this.partnerId
      });
    } else {
      await PartnerUserModule.fetchNextPage({ partnerId: this.partnerId });
    }
  }

  goToAdminUser() {
    const location: RawLocation = {
      name: "UserAdministration",
      query: { userId: this.user?._id }
    };
    new GoToHelper(this.$router).go(location);
  }
}
