











































































































































































import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { checkSessionStatus } from "@/lib/utility/checkSessionStatusFunc";
import { downloadFile } from "@/lib/utility/downloadFileFunc";
import { Type } from "@/lib/utility/type";
import { RegistrationRecognitionFahrzeugschein } from "@/models/fahrzeugscheinEntity";
import { ConfigModule } from "@/store/modules/config";
import { ErrorLogModule } from "@/store/modules/error-log";
import { FahrzeugscheinModule } from "@/store/modules/fahrzeugschein";
import { SessionModule } from "@/store/modules/session";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutSimple }
})
export default class FahrzeugscheinDetail extends Vue {
  nextFahrzeugscheinSessionId: any = undefined;
  previousFahrzeugscheinSessionId: any = undefined;

  isNextFahrzeugscheinAvailable = false;
  isPreviousFahrzeugscheinAvailable = false;

  currentIndex = -1;

  snackbar = false;
  loading = false;
  deleteLoading = false;
  timeout = 3000;

  deleteDialogOpen = false;

  constructor() {
    super();
  }

  get fahrzeugschein(): RegistrationRecognitionFahrzeugschein {
    // is the wrong type, but we need to use the correct type
    return FahrzeugscheinModule.fahrzeugschein as any;
  }

  get debug() {
    return ConfigModule.debug;
  }

  get isFinCorrect() {
    return FahrzeugscheinModule.isFinCorrect;
  }

  get finErrorMessage() {
    return FahrzeugscheinModule.finErrorMessage;
  }

  async mounted() {
    this.loading = true;

    try {
      await FahrzeugscheinModule.getFahrzeugschein(this.$route.params.id);

      this.currentIndex = SessionModule.sessions.map(s => s.session_id).indexOf(this.$route.params.id);

      this.$log.info("Current Index:" + this.currentIndex);

      this.getPreviousFahrzeugschein();
      this.getNextFahrzeugschein();

      await FahrzeugscheinModule.validateFin(this.fahrzeugschein.identifizierungsnummer);

      await this.updateCachedFahrzeugscheine();
    } catch {
      this.$toast.error("Error");
    } finally {
      this.loading = false;
    }
  }

  async changeFin() {
    await FahrzeugscheinModule.validateFin(this.fahrzeugschein.identifizierungsnummer);
  }

  async updateFahrzeugschein() {
    this.loading = !this.loading;
    await FahrzeugscheinModule.updateFahrzeugschein(this.$route.params.id);
    this.snackbar = !this.snackbar;
    this.loading = !this.loading;
  }

  async updateCachedFahrzeugscheine() {
    if (this.currentIndex > 0 && this.currentIndex < SessionModule.sessions.length && this.currentIndex != -1) {
      // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/slice
      const expectedUsedFahrzeugscheine = SessionModule.sessions.slice(this.currentIndex - 1, this.currentIndex + 2);

      try {
        await FahrzeugscheinModule.updateCachedFahrzeugscheine(expectedUsedFahrzeugscheine);
      } catch (error) {
        ErrorLogModule.addErrorLog(error);
        throw error;
      }
    }
  }

  getPreviousFahrzeugschein() {
    if (this.currentIndex != -1 && this.currentIndex > 0) {
      for (let i = this.currentIndex - 1; i >= 0; i--) {
        if (checkSessionStatus(SessionModule.sessions[i].status)) {
          this.previousFahrzeugscheinSessionId = SessionModule.sessions[i].session_id;
          this.$log.info("Previous Id:" + this.previousFahrzeugscheinSessionId);
          this.isPreviousFahrzeugscheinAvailable = true;
          return;
        }
      }
    }
  }

  getNextFahrzeugschein() {
    if (this.currentIndex != -1 && this.currentIndex < SessionModule.sessions.length) {
      for (let i = this.currentIndex + 1; i < SessionModule.sessions.length; i++) {
        if (checkSessionStatus(SessionModule.sessions[i].status)) {
          this.nextFahrzeugscheinSessionId = SessionModule.sessions[i].session_id;
          this.$log.info("Next Id:" + this.nextFahrzeugscheinSessionId);
          this.isNextFahrzeugscheinAvailable = true;
          return;
        }
      }
    }
  }

  redirectToNextFahrzeugschein() {
    this.$router.push({ name: "FahrzeugscheinComponent", params: { id: this.nextFahrzeugscheinSessionId } });
  }

  redirectToPreviousFahrzeugschein() {
    this.$router.push({ name: "FahrzeugscheinComponent", params: { id: this.previousFahrzeugscheinSessionId } });
  }

  async deleteFahrzeugschein() {
    this.deleteDialogOpen = false;
    this.deleteLoading = true;

    try {
      await FahrzeugscheinModule.deleteFahrzeugschein(this.$route.params.id);
    } catch (error) {
      ErrorLogModule.addErrorLog(error);
      throw error;
    }

    await SessionModule.fetchSessions();

    this.$router.push({ name: "Fahrzeugschein" });
  }

  async printXMLForFahrzeugschein() {
    try {
      await FahrzeugscheinModule.getXML(this.$route.params.id);
    } catch (error) {
      ErrorLogModule.addErrorLog(error);
      throw error;
    }

    if (Type.isPresent(FahrzeugscheinModule.xml) && !Type.isBlankString(FahrzeugscheinModule.xml)) {
      if (!Type.isBlankString(this.fahrzeugschein.kennzeichen) && !Type.isBlankString(FahrzeugscheinModule.xml)) {
        downloadFile(this.fahrzeugschein.kennzeichen + ".xml", FahrzeugscheinModule.xml);
      } else {
        downloadFile("fahrzeugschein.xml", FahrzeugscheinModule.xml);
      }
    }
  }
}
