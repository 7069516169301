var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', [_c('partner-manage-stepper', {
    attrs: {
      "steps": _vm.steps
    }
  }, [_c('template', {
    slot: "step"
  }, [_c('v-form', {
    staticClass: "pa-3",
    staticStyle: {
      "width": "100%"
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 pb-2"
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.resource")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.resourceDescription")))])], 1)], 1), _c('v-row', [_c('v-autocomplete', {
    attrs: {
      "disabled": _vm.resourcesLoading,
      "loading": _vm.resourcesLoading,
      "items": _vm.daResources,
      "clearable": "",
      "solo": "",
      "label": _vm.$t('components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.resource'),
      "item-text": "name",
      "item-value": "id"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.name) + " (" + _vm._s(data.item.id) + ") ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [[_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(data.item.id)
          }
        })], 1)]];
      }
    }]),
    model: {
      value: _vm.selectionResourceId,
      callback: function callback($$v) {
        _vm.selectionResourceId = $$v;
      },
      expression: "selectionResourceId"
    }
  })], 1), _c('v-row', {
    staticClass: "mt-3 pb-2"
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.eventType")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.eventTypeDescription")))])], 1)], 1), _c('v-row', [_c('v-autocomplete', {
    attrs: {
      "disabled": _vm.eventTypesLoading,
      "loading": _vm.eventTypesLoading,
      "items": _vm.daEventTypes,
      "clearable": "",
      "solo": "",
      "label": _vm.$t('components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.eventType'),
      "item-text": "name",
      "item-value": "id"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.name) + " (" + _vm._s(data.item.id) + ") ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [[_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(data.item.id)
          }
        })], 1)]];
      }
    }]),
    model: {
      value: _vm.selectionEventTypeId,
      callback: function callback($$v) {
        _vm.selectionEventTypeId = $$v;
      },
      expression: "selectionEventTypeId"
    }
  })], 1), _c('v-row', {
    staticClass: "mt-3 pb-2"
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.workshopService")) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.workshopServiceDescription")))])], 1)], 1), _c('v-row', [_c('v-autocomplete', {
    attrs: {
      "disabled": _vm.workshopServicesLoading,
      "loading": _vm.workshopServicesLoading,
      "items": _vm.daWorkshopServices,
      "clearable": "",
      "solo": "",
      "label": _vm.$t('components.partner.PartnerManageStepper.DescriptionStep.daSetupAssistant.workshopService'),
      "item-text": "name",
      "item-value": "id"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.name) + " (" + _vm._s(data.item.id) + ") ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [[_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(data.item.id)
          }
        })], 1)]];
      }
    }]),
    model: {
      value: _vm.selectionWorkshopServiceId,
      callback: function callback($$v) {
        _vm.selectionWorkshopServiceId = $$v;
      },
      expression: "selectionWorkshopServiceId"
    }
  })], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }