





















































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import BarChartCard from "@/components/analytics/BarChartCard.vue";
import PieChartCard from "@/components/analytics/PieChartCard.vue";
import TimeSeriesLineChartCard from "@/components/analytics/TimeSeriesLineChartCard.vue";
import StatisticsCard from "@/components/cards/StatisticsCard.vue";
import DashboardStatisticsList from "@/components/dashboard/DashboardStatisticsList.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { AnalyticsModule } from "@/store/modules/analytics.store";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    AnalyticsDateRangeSelectorDialog,
    TheLayoutPortal,
    StatisticsCard,
    DashboardStatisticsList,
    TimeSeriesLineChartCard,
    BarChartCard,
    PieChartCard
  }
})
export default class PartnerAnalyticsDashboard extends PartnerFallbackMixin {
  loading = false;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  dataHandler = {
    trendAnalysis: {
      getData: async () => {
        await AnalyticsModule.getTrendAnylsisDataPerPartner();
      },
      isLoading: false
    },
    statusDistribution: {
      getData: async () => {
        await AnalyticsModule.getStatusDistributionData();
      },
      isLoading: false
    },
    hourDistribution: {
      getData: async () => {
        await AnalyticsModule.getHourDistributionData();
      },
      isLoading: false
    },
    weekdayDistribution: {
      getData: async () => {
        await AnalyticsModule.getWeekdayDistributionDataPerPartner();
      },
      isLoading: false
    },
    reportsCount: {
      getData: async () => {
        await AnalyticsModule.getReportsCountDataPerPartner();
      }
    },
    formsCount: {
      getData: async () => {
        await AnalyticsModule.getFormsCountDataPerPartner();
      }
    }
  };

  get statusDistributionData() {
    return AnalyticsModule.statusDistributionData.data;
  }

  get hourDistributionData() {
    return AnalyticsModule.hourDistributionData.data;
  }

  get weekdayDistributionData() {
    return AnalyticsModule.weekdayDistributionData.data;
  }

  get reportsCountData() {
    return AnalyticsModule.reportsCountData.count;
  }

  get formsCountData() {
    return AnalyticsModule.formsCountData.count;
  }

  get dates() {
    return AnalyticsModule.dateRange;
  }

  async mounted() {
    this.loading = true;
    try {
      await this.getData();
    } catch {
      this.$toast.error("Ooops... Fehler beim Laden der Anwendung!");
    } finally {
      this.loading = false;
    }
  }

  async getData() {
    Object.keys(this.dataHandler).forEach(async key => {
      this.dataHandler[key].isLoading = true;

      try {
        await this.dataHandler[key].getData();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    });
  }

  async setDateRange(dateRange: string[]) {
    AnalyticsModule.setDateRange(dateRange);
    await this.getData();
  }

  // Charts
  get trendData() {
    const trendData = [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: AnalyticsModule.trendAnalysisData.data.thirtyDayTrend
      }
    ];
    return trendData;
  }
}
