






















































































































































































import CustomFieldValueChip from "@/components/report/CustomFieldValueChip.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { CustomFieldListElement } from "@/models/custom-field-list-element.entity";
import { ICustomField } from "@/models/custom-field.entity";
import { IProjectConfiguration } from "@/models/project-configuration.entity";
import { IProjectCustomView, ProjectCustomView } from "@/models/project-custom-view.entity";
import { CustomFieldEnum, CustomFieldModule } from "@/store/modules/custom-field.store";
import { ProjectCustomViewFieldEnum } from "@/views/project/enum/ProjectCustomViewFieldEnum";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    CustomFieldValueChip
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CustomViewCreateHelper extends mixins(PartnerFallbackMixin) {
  readonly ProjectCustomViewTypeEnum = ProjectCustomViewTypeEnum;
  @Prop()
  partnerId!: string;

  @Prop()
  value!: {
    update: () => Promise<void>;
    configuration: IProjectConfiguration;
  };

  /**
   * to customize which fields should be shown in the table
   */
  @Prop({
    default: () => ({})
  })
  tableBaseConfig!: IProjectCustomView;

  /**
   * to customize calendar
   */
  @Prop({
    default: () => ({
      calendarStart: {
        type: ProjectCustomViewFieldEnum.PROPERTY,
        key: "timestamp.created"
      }
    })
  })
  calendarBaseConfig!: IProjectCustomView;

  /**
   * to customize board
   */
  @Prop({
    default: () => ({})
  })
  boardBaseConfig!: IProjectCustomView;

  infoTab = "";

  setInfoTab(tab: string) {
    if (this.infoTab === tab) {
      this.infoTab = "";
    } else {
      this.infoTab = tab;
    }
  }

  isLoading = false;

  askCustomFieldForProjectBoardMenu = false;

  views: IProjectCustomView[] = [...this.value?.configuration?.views];

  get tablePosition() {
    return this.views.findIndex(view => view.type === ProjectCustomViewTypeEnum.TABLE) + 1;
  }

  get calendarPosition() {
    return this.views.findIndex(view => view.type === ProjectCustomViewTypeEnum.CALENDAR) + 1;
  }

  get boardPosition() {
    return this.views.findIndex(view => view.type === ProjectCustomViewTypeEnum.BOARD) + 1;
  }

  get isProjectBoard() {
    return this.views.some(view => view.type === ProjectCustomViewTypeEnum.BOARD);
  }

  set isProjectBoard(value: boolean) {
    if (value) {
      this.setInfoTab(ProjectCustomViewTypeEnum.BOARD);
    } else {
      this.setInfoTab("");
      this.views = this.views.filter(view => view.type !== ProjectCustomViewTypeEnum.BOARD);
    }
  }

  get isProjectTable() {
    return this.views.some(view => view.type === ProjectCustomViewTypeEnum.TABLE);
  }

  set isProjectTable(value: boolean) {
    if (value) {
      this.setInfoTab(ProjectCustomViewTypeEnum.TABLE);
      this.views.push(
        new ProjectCustomView({
          ...this.tableBaseConfig,
          title: $t("project.project.table"),
          type: ProjectCustomViewTypeEnum.TABLE
        })
      );
    } else {
      this.setInfoTab("");
      this.views = this.views.filter(view => view.type !== ProjectCustomViewTypeEnum.TABLE);
      this.$emit("addedView");
    }
  }

  get isProjectCalendar() {
    return this.views.some(view => view.type === ProjectCustomViewTypeEnum.CALENDAR);
  }

  set isProjectCalendar(value: boolean) {
    if (value) {
      this.setInfoTab(ProjectCustomViewTypeEnum.CALENDAR);
      this.views.push(
        new ProjectCustomView({
          ...this.calendarBaseConfig,
          title: $t("project.project.calendar"),
          type: ProjectCustomViewTypeEnum.CALENDAR
        })
      );
    } else {
      this.setInfoTab("");
      this.views = this.views.filter(view => view.type !== ProjectCustomViewTypeEnum.CALENDAR);
      this.$emit("addedView");
    }
  }

  get customFields() {
    return CustomFieldModule.paginationList.filter(customField =>
      [CustomFieldEnum.SINGLE_SELECT, CustomFieldEnum.MULTI_SELECT].includes(customField.type as CustomFieldEnum)
    );
  }

  get selectedCustomField() {
    const boardView = this.views.find(view => view.type === ProjectCustomViewTypeEnum.BOARD);
    if (!boardView?.boardColumn) {
      return "";
    }
    return boardView.boardColumn.key;
  }

  startAddProjectBoard() {
    if (this.isProjectBoard) {
      this.isProjectBoard = false;
      this.views = this.views.filter(view => view.type !== ProjectCustomViewTypeEnum.BOARD);
    } else {
      this.askCustomFieldForProjectBoardMenu = true;
    }
  }

  addBord(singleSelectCustomField: ICustomField) {
    // make sure that custom field is configured
    if (
      !this.value.configuration.customFieldConfig.some(config => config.customField.id === singleSelectCustomField.id)
    ) {
      this.value.configuration.customFieldConfig.push(
        new CustomFieldListElement({
          customField: singleSelectCustomField
        })
      );
    }

    this.views = this.views.filter(view => view.type !== ProjectCustomViewTypeEnum.BOARD);
    this.views.push(
      new ProjectCustomView({
        ...this.boardBaseConfig,
        boardColumn: {
          key: singleSelectCustomField.id,
          type: ProjectCustomViewFieldEnum.CUSTOM_FIELD
        },
        boardColumnOrder: singleSelectCustomField.configuration?.values.map(v => v.value) ?? [],
        title: $t("project.project.board"),
        type: ProjectCustomViewTypeEnum.BOARD
      })
    );

    this.$emit("addedView");
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.partnerId);
  }

  isAddViewDialogLoading = false;

  async confirmCreation() {
    this.isAddViewDialogLoading = true;
    this.value.configuration.views.push(...this.views);
    await this.value.update().catch(handleError);
    this.isAddViewDialogLoading = false;
  }
}
