




















































import TableWrapper, { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { EventListModule } from "@/store/modules/list-event.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { $t } from "@/lib/utility/t";

@Component({
  components: { TableWrapper, Tooltip }
})
export default class EventOverdueTable extends Vue {
  @Prop()
  loading!: boolean;

  @Prop({ default: false })
  dense!: boolean;

  selected: { event: IEventUIDto; index: number }[] = [];

  getVehicle(id: string) {
    return VehicleModule.maps.id.get(id)[0];
  }

  get overdueEvents(): { event: IEventUIDto; index: number }[] {
    return EventListModule.overdueEvents.map((event: IEventUIDto, index: number) => {
      return {
        event,
        index
      };
    });
  }

  get headersOverdue(): ITableWrapperHeader[] {
    return [
      {
        text: $t("components.fleet.FleetHomeCalendar.start").toString(),
        value: "event.start",
        type: "date",
        align: "start",
        width: "50px"
      },

      {
        text: "",
        value: "event.summary",
        align: "start",
        sortable: false
      }
    ];
  }

  async acknowledge() {
    this.selected.forEach(async event => {
      await event.event.acknowledge(new Date().toISOString());
    });
    this.$emit("selectedAction");
  }

  async clickRow({ event }: { event: IEventUIDto }) {
    this.$emit("click:row", event);
  }
}
