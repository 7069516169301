var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    attrs: {
      "subheader": "",
      "two-line": "",
      "flat": ""
    }
  }, [_c('v-list-item', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t("components.booking.BookingCustomerFieldConfiguration.email")))])], 1), _c('v-switch', {
          staticClass: "pr-4",
          attrs: {
            "value": "",
            "input-value": "true",
            "disabled": "",
            "inset": "",
            "label": _vm.$t('components.booking.BookingCustomerFieldConfiguration.active')
          }
        }), _c('v-switch', {
          attrs: {
            "value": "",
            "input-value": "true",
            "disabled": "",
            "inset": "",
            "label": _vm.$t('components.booking.BookingCustomerFieldConfiguration.required')
          }
        })];
      },
      proxy: true
    }])
  }), _vm._l(_vm.customerFieldConfig, function (customerField, index) {
    return _c('v-list-item', {
      key: index,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn() {
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t("components.booking.BookingCustomerFieldConfiguration.".concat(customerField.field))))])], 1), _c('v-switch', {
            staticClass: "pr-4",
            attrs: {
              "inset": "",
              "label": _vm.$t('components.booking.BookingCustomerFieldConfiguration.active')
            },
            model: {
              value: customerField.configuration.isActive,
              callback: function callback($$v) {
                _vm.$set(customerField.configuration, "isActive", $$v);
              },
              expression: "customerField.configuration.isActive"
            }
          }), _c('v-switch', {
            attrs: {
              "disabled": !customerField.configuration.isActive,
              "inset": "",
              "label": _vm.$t('components.booking.BookingCustomerFieldConfiguration.required')
            },
            model: {
              value: customerField.configuration.isRequired,
              callback: function callback($$v) {
                _vm.$set(customerField.configuration, "isRequired", $$v);
              },
              expression: "customerField.configuration.isRequired"
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }