










































































import { Component, Vue } from "vue-property-decorator";

import Logo from "@/components/utility/Logo.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import FahrzeugscheinTable from "@/components/fahrzeugschein/FahrzeugscheinTable.vue";
import { ConfigModule } from "@/store/modules/config";
import { isMobile } from "@/lib/utility/isMobile";

@Component({
  components: {
    TheLayoutPortal,
    FahrzeugscheinTable,
    Logo
  }
})
export default class Fahrzeugschein extends Vue {
  dialog = true;

  get isFahrzeugscheinTokenValid() {
    return ConfigModule.isFahrzeugscheinTokenValid;
  }

  get showFab() {
    return isMobile();
  }

  get apiKey() {
    return ConfigModule.fahrzeugscheinToken;
  }

  set apiKey(apiKey) {
    // do nothing, to not trigger a request
    // changeApiKey() will do that
  }

  apiKeyRules = {
    required: (value: string) => !!value || "Wird benötigt."
  };

  changeApiKey(apiKey: string) {
    ConfigModule.setFahrzeugscheinToken(apiKey);
  }

  newFahrzeugschein() {
    this.$router.push({ name: "FahrzeugscheinNew" });
  }
}
