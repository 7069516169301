

































import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "@/components/utility/SideCard.vue";
import BookingDetailCard from "./portal-detail-card/BookingDetailCard.vue";
import { IBooking } from "@/models/booking.entity";
import { IResource } from "@/models/resource.entity";
import { IService } from "@/models/service.entity";

@Component({
  components: {
    SideCard,
    BookingDetailCard
  }
})
export default class BookingSideCard extends Vue {
  @Prop()
  value!: IBooking;

  @Prop()
  loading!: boolean;

  @Prop()
  resourceName!: string;

  @Prop()
  serviceName!: string;

  @Prop()
  service!: IService;

  @Prop()
  resources!: IResource[];

  height = document.documentElement.clientHeight;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  onResize() {
    this.height = document.documentElement.clientHeight;
  }
}
