var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.vehicle ? _c('context-menu', [_c('v-select', {
    staticClass: "mb-n4",
    attrs: {
      "loading": _vm.loading,
      "items": _vm.vehicleStates,
      "dense": "",
      "solo": "",
      "flat": "",
      "outlined": ""
    },
    on: {
      "change": _vm.changeVehicleStatus
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state." + item)) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state." + item)) + " ")];
      }
    }], null, false, 3180806972),
    model: {
      value: _vm.selectedState,
      callback: function callback($$v) {
        _vm.selectedState = $$v;
      },
      expression: "selectedState"
    }
  }), _c('delete-dialog', {
    attrs: {
      "loading": _vm.loading,
      "title": _vm.$t('components.fleet.FleetVehicleDetailContextMenu.confirmDeletionTitle'),
      "text": _vm.$t('components.fleet.FleetVehicleDetailContextMenu.reasonText')
    },
    on: {
      "delete": _vm.confirmDeletion
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }