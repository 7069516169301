/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum ManageStepperDescriptionTypes {
  DA_SETUP_ASSISTANT = "daSetupAssistant",
  KSR_SETUP_ASSISTANT = "ksrSetupAssistant",
  PDR_SETUP_ASSISTANT = "pdrSetupAssistant",
  DEALER_DESK_SETUP_ASSISTANT = "dealerDeskSetupAssistant",
  END = "end"
}
