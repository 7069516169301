

























































































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { BookingModule } from "@/store/modules/booking.store";
import { ITableWrapperHeader, IControlElements } from "@/components/utility/TableWrapper.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { IBooking, Booking } from "@/models/booking.entity";
import { formatHoursAndMinutes, simpleDoubleDigitDate, displayMsAsMinutesAndHours } from "@/lib/utility/date-helper";
import { ServiceModule } from "@/store/modules/service.store";
import { Service } from "@/models/service.entity";
import { ResourceModule } from "@/store/modules/resource.store";
import { $t } from "@/lib/utility/t";
import BookingSideCard from "@/components/booking/BookingSideCard.vue";
import { handleError } from "@/lib/utility/handleError";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import BookingForm from "@/views/booking/BookingForm.vue";

@Component({
  components: {
    PaginatedTable,
    BookingSideCard,
    ConfirmActionDialog,
    BookingForm
  }
})
export default class BookingTable extends mixins(PartnerFallbackMixin) {
  readonly store = BookingModule;

  @Prop()
  partnerId!: string;

  @Prop()
  selectedItems: IBooking[] = [];

  selectedItem: IBooking | null = null;

  /**
   * Opening create confirmation
   */
  isCreateDialogActive = false;
  isCreateDialogLoading = false;
  isCreateValid = false;

  /**
   * Opening delete all confirmation
   */
  isDeleteAllDialogActive = false;
  isDeleteAllDialogLoading = false;

  createBookingDto: IBooking = new Booking({ partnerId: this.partnerId });

  get selectedItemsLocal(): IBooking[] {
    return this.selectedItems;
  }

  set selectedItemsLocal(value: IBooking[]) {
    this.$emit("update:selectedItems", value);
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];
    headers.push({
      text: $t("objects.booking.start"),
      value: "start",
      width: "50px",
      align: "start"
    });

    headers.push({
      text: $t("objects.booking.end"),
      value: "end",
      width: "50px",
      align: "start"
    });

    headers.push({
      text: $t("objects.booking.duration"),
      value: "duration",
      width: "50px",
      align: "start"
    });

    headers.push({
      text: $t("objects.booking.customerInformation"),
      value: "customerInformation",
      width: "100px",
      align: "start"
    });

    headers.push({
      text: $t("objects.booking.serviceId"),
      value: "serviceId",
      width: "100px",
      align: "start"
    });

    headers.push({
      text: $t("objects.booking.resourceId"),
      value: "resourceId",
      width: "100px",
      align: "start"
    });

    headers.push({
      text: $t("objects.booking.isDeleted"),
      value: "isDeleted",
      width: "50px",
      align: "start"
    });

    headers.push({
      text: $t("objects.timestamp.created"),
      value: "timestamp",
      width: "100px",
      align: "start"
    });

    return headers;
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [];
    return controlElements;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [];

    return predefinedFilter;
  }

  get resourceName() {
    return this.getResourceName(this.selectedItem?.resourceId);
  }

  get serviceName() {
    return this.getServiceName(this.selectedItem?.serviceId);
  }

  get resourcesList() {
    return ResourceModule.entities;
  }

  get servicesList() {
    return ServiceModule.entities;
  }

  get service() {
    if (!this.selectedItem || !this.selectedItem.serviceId) {
      return undefined;
    }

    const service = ServiceModule.maps.id.get(this.selectedItem.serviceId)[0];

    return service;
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  formatHoursAndMinutes(value: any) {
    return formatHoursAndMinutes(value);
  }

  displayMsAsMinutesAndHours(value: any) {
    return displayMsAsMinutesAndHours(value);
  }

  setSelectedItem(item: IBooking | null) {
    this.selectedItem = item;
    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  getServiceName(serviceId: string | undefined) {
    if (!serviceId) {
      return Service.SERVICE_INTERNAL_NAME;
    }

    const service = ServiceModule.maps.id.get(serviceId)[0];
    const serviceName = service?.name || Service.SERVICE_INTERNAL_NAME;

    return serviceName;
  }

  getResourceName(resourceId: string | undefined) {
    if (!resourceId) {
      return "";
    }

    const resource = ResourceModule.maps.id.get(resourceId)[0];
    const resourceName = resource?.name;
    return resourceName;
  }

  onClickCreate() {
    this.createBookingDto = new Booking({ partnerId: this.partnerId });

    this.isCreateDialogActive = true;
  }

  async createBooking() {
    this.isCreateDialogLoading = true;

    if (!this.createBookingDto) {
      this.$toast.error(this.$t("views.booking.BookingTable.noBookingCreated"));

      return;
    }

    try {
      await this.createBookingDto.create(false);
      this.$toast.success("👍");

      this.isCreateDialogActive = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateDialogLoading = false;
    }
  }

  onClickDelete() {
    this.isDeleteAllDialogActive = true;
  }

  async deleteBookings() {
    this.isDeleteAllDialogLoading = true;

    if (!this.selectedItems || this.selectedItems.length <= 0) {
      this.isDeleteAllDialogLoading = false;
      this.isDeleteAllDialogActive = false;
      this.$toast.error(this.$t("views.booking.BookingTable.noDocumentsSelected"));
      return;
    }

    if (this.selectedItems.length >= 10) {
      const error = new Error(this.$t("views.booking.BookingTable.tooManyError").toString());
      this.$toast.error(error.message);

      throw error;
    }

    const promises: Promise<void>[] = [];
    let counter = 0;

    for (const booking of this.selectedItems) {
      promises.push(booking.delete());
      counter++;
    }

    try {
      await Promise.all(promises);
      this.$toast.success(this.$t("views.booking.BookingTable.deleted", { counter: counter }));
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteAllDialogActive = false;
      this.isDeleteAllDialogLoading = false;
      /** Reset the selected items */
      this.selectedItems = [];
    }
  }
}
