var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('the-layout-portal-list', {
          attrs: {
            "title": _vm.$t('views.CustomFieldList.customFields'),
            "filterList": _vm.filterList,
            "loading": _vm.loading,
            "isLoadingMore": _vm.isLoadingMore,
            "getModule": _vm.getModule
          },
          on: {
            "loadMore": _vm.loadMore,
            "refresh": _vm.refresh
          },
          scopedSlots: _vm._u([{
            key: "options",
            fn: function fn() {
              return [_c('v-btn', {
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.initCreation
                }
              }, [_c('v-icon', [_vm._v(" mdi-plus ")])], 1)];
            },
            proxy: true
          }, {
            key: "listItem",
            fn: function fn(_ref) {
              var listItem = _ref.listItem;
              return [_c('v-list-item', {
                staticClass: "item",
                class: _vm.activeClass(listItem),
                attrs: {
                  "disabled": _vm.isLoadingDetail,
                  "three-line": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.openDetail(listItem);
                  }
                }
              }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(listItem.name) + " ")]), _c('v-list-item-subtitle', [_c('div', {
                staticClass: "label"
              }, [_vm._v(_vm._s(listItem.label))]), _c('div', [_vm._v(_vm._s(listItem.hint))])])], 1), _c('v-list-item-action', [_c('custom-field-detail-icon', {
                attrs: {
                  "type": listItem.type
                }
              })], 1)], 1)];
            }
          }])
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_vm.isLoading ? _c('div', [_c('v-skeleton-loader', {
          attrs: {
            "type": "list-item, list-item-two-line, list-item-two-line, list-item-two-line"
          }
        })], 1) : _vm.customField && _vm.customField.id ? _c('div', [_vm.customField.id ? _c('custom-field-detail-card', {
          key: "custom-field-detail-card-".concat(_vm.customField.id),
          attrs: {
            "customField": _vm.customField
          },
          on: {
            "onCheck": _vm.update,
            "onDelete": _vm.onDelete,
            "onClose": function onClose($event) {
              return _vm.setCustomField(null);
            }
          }
        }) : _vm._e(), _c('confirm-action-dialog', {
          attrs: {
            "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
            "isDialogActive": _vm.isDeleteDialogActive,
            "rightLoading": _vm.isLoading
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDeleteDialogActive = $event;
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDeleteDialogActive = $event;
            },
            "leftClick": function leftClick($event) {
              _vm.isDeleteDialogActive = false;
            },
            "rightClick": _vm.confirmDeletion
          }
        })], 1) : _c('v-card', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-img', {
          staticClass: "ma-8",
          attrs: {
            "src": require("@/assets/undraw_text_field_htlv.svg"),
            "max-height": "250px",
            "contain": ""
          }
        }), _c('v-card-text', {
          staticClass: "mt-2",
          attrs: {
            "justify-center": "",
            "align-center": ""
          }
        }, [_c('div', {
          staticClass: "ma-auto",
          staticStyle: {
            "max-width": "700px",
            "text-align": "justify"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.$t('views.CustomFieldList.explanation'))
          }
        })]), _c('v-card-actions', {
          staticClass: "mt-6",
          attrs: {
            "justify-center": "",
            "align-center": ""
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": _vm.initCreation
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.$t("views.CustomFieldList.newCustomField")) + " ")], 1), _c('v-spacer')], 1)], 1), _c('confirm-action-dialog', {
          attrs: {
            "isDialogActive": _vm.isCreateDialogActive,
            "title": _vm.$t('create'),
            "rightDisabled": !_vm.isCreateValid,
            "rightText": _vm.$t('create'),
            "width": "800px"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isCreateDialogActive = $event;
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isCreateDialogActive = $event;
            },
            "close": function close($event) {
              _vm.isCreateDialogActive = false;
            },
            "leftClick": function leftClick($event) {
              _vm.isCreateDialogActive = false;
            },
            "rightClick": _vm.onCreate
          }
        }, [_c('custom-field-form', {
          attrs: {
            "customField": _vm.createCustomFieldDto
          },
          model: {
            value: _vm.isCreateValid,
            callback: function callback($$v) {
              _vm.isCreateValid = $$v;
            },
            expression: "isCreateValid"
          }
        })], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }