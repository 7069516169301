import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import eventService from "@/services/mrfiktiv/services/eventService";
import {
  MrfiktivCreateEventDtoGen,
  MrfiktivEventControllerGetParamsGen,
  MrfiktivEventViewModelGen,
  MrfiktivUpdateEventDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { recurringRootEventFilter } from "@/views/event/filter/event.filter";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

/**
 * The store for recurring events.For the virtual events the RRule package is used. @see RRule.
 * This store is to be used in conjunction with the list-event store @see EventListStore
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "recurring-event",
  store
})
export class RecurringEventStore extends BasePagination<
  MrfiktivEventViewModelGen,
  MrfiktivEventControllerGetParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: MrfiktivEventViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = true;
  filterOptions: PaginationFilterListElement[] = [
    { key: "_id", type: PageFilterTypes.OBJECT_ID },
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "vehicleId", type: PageFilterTypes.OBJECT_ID, displayName: "objects.vehicle.id" },
    { key: "isAllDay", type: PageFilterTypes.BOOLEAN }
  ].map(f => new PaginationFilterListElement(f));

  /**
   * As this is a dedicated recurring event store, we filter by `isRecurringRoot`.
   */
  readonly defaultHiddenFilter = recurringRootEventFilter[0].filter[0];
  hiddenFilter: IPageFilterElement[] = [this.defaultHiddenFilter];
  @Action
  setHiddenFilter(filter: IPageFilterElement[]) {
    this.context.commit("_mutateHiddenFilter", filter.concat(this.defaultHiddenFilter));
  }

  private _idMap = new Map<string, MrfiktivEventViewModelGen>();

  protected _documentMapConfig = { id: this._idMap };

  /**
   * A recurring event,
   *
   * For the virtual events the RRule package is used. @see RRule.
   */
  private _event: MrfiktivEventViewModelGen | undefined = undefined;

  /**
   * List of the roots of recurring events.
   * Theses events are a configuration of a set of virtual events and are not to be mistaken with (virtual) event instances.
   *
   * A recurring event is not supposed to be shown in a calendar, instead its virtual instances are to be shown.
   * A recurring event can not be acknowledged, only its instances can be acknowledged.
   * For the virtual events the RRule package is used. @see RRule.
   */
  get events() {
    return this._paginationList;
  }

  @Mutation
  private _mutateEvent(request: MrfiktivEventViewModelGen) {
    this._event = request;
  }

  @Action
  protected async loadDocuments(query: MrfiktivEventControllerGetParamsGen) {
    const res = await eventService.getAll({ ...query });

    return res;
  }

  @Action
  async create(data: { partnerId: string; data: MrfiktivCreateEventDtoGen }) {
    const res = await eventService.create(data.partnerId, data.data);

    this.paginationList.unshift(res);
    this.context.commit("_mutateEvent", res);

    return res;
  }

  @Action
  async delete(data: { partnerId: string; eventId: string }) {
    const res = await eventService.delete(data.partnerId, data.eventId);

    this.removeInList(res);
    this.context.commit("_mutateEvent", res);

    return res;
  }

  @Action
  async getOne(data: { partnerId: string; eventId: string }) {
    const res = await eventService.getOne(data.partnerId, data.eventId);

    this.replaceInList(res);
    this.context.commit("_mutateEvent", res);

    return res;
  }

  @Action
  async update(data: { partnerId: string; eventId: string; data: MrfiktivUpdateEventDtoGen }) {
    const res = await eventService.update(data.partnerId, data.eventId, data.data);

    this.replaceInList(res);
    this.context.commit("_mutateEvent", res);

    return res;
  }
}

export const RecurringEventModule = getModule(RecurringEventStore);
