






































import RefsSelectionByType from "@/components/utility/RefsSelectionByType.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { emailRule, phoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { IInspection, Inspection } from "@/models/inspection.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldDateTime from "../report/CustomFieldDateTime.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import AddressForm from "./AddressForm.vue";
import CompanyForm from "./CompanyForm.vue";
import InspectionForm from "./InspectionForm.vue";
import AttendeeForm from "./AttendeeForm.vue";

@Component({
  components: {
    ConfirmActionDialog,
    RefsSelectionByType,
    CompanyForm,
    Debug,
    CustomFieldDateTime,
    AddressForm,
    InspectionForm,
    AttendeeForm
  }
})
export default class InspectionCreateDialog extends mixins(PartnerFallbackMixin, PermissionMixin) implements IDialog {
  @Prop()
  handover!: IHandover;

  isDialogActive = false;

  get requiredRule() {
    return [requiredRule()];
  }

  get emailRule() {
    return [emailRule()];
  }

  get phoneRule() {
    return [phoneRule()];
  }

  inspection: IInspection = new Inspection(this._empty);

  isCreateLoading = false;

  inspectionMenu = false;

  close() {
    this.$emit("close");
    this.isDialogActive = false;
  }

  isInspectionValid = false;

  get isValid() {
    return this.isInspectionValid;
  }

  private get _empty(): Partial<IInspection> {
    return {
      partnerId: this.handover.partnerId,
      handoverId: this.handover.id
    };
  }

  async open() {
    this.inspection = new Inspection(this._empty);
    this.isDialogActive = true;

    this.$nextTick(() => {
      (this.$refs.titleTextField as any)?.focus();
    });
  }

  async create() {
    try {
      this.isCreateLoading = true;
      const created = await this.inspection.create();
      this.$toast("👍");
      new GoToHelper(this.$router).goToInspectionDetail(created.id, this.handover.id, created.partnerId, false);
    } catch (error) {
      handleError(error);
    } finally {
      this.isCreateLoading = false;
      this.isDialogActive = false;
    }
  }

  addUser() {
    this.inspection.attendees.push({
      companyName: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: ""
    });
  }

  removeItem(index: number) {
    this.inspection.attendees.splice(index, 1);
  }
}
