var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "close-on-click": true,
      "offset-y": true
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-n3",
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu-down")])], 1)];
      }
    }])
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, _vm._l(_vm.actions, function (group, index) {
    return _c('span', {
      key: index
    }, [_vm._l(group.group, function (action, index) {
      return _c('v-list', {
        key: index,
        staticClass: "mx-2"
      }, [_c('v-list-item', {
        attrs: {
          "dense": "",
          "disabled": action.disabled
        },
        on: {
          "click": function click($event) {
            return _vm.executAction(action.action);
          }
        }
      }, [_c('v-list-item-icon', [_c('v-icon', {
        style: _vm.color(action.color)
      }, [_vm._v(_vm._s(action.icon))])], 1), _c('v-list-item-subtitle', {
        style: _vm.color(action.color)
      }, [_vm._v(" " + _vm._s(action.text) + " ")])], 1)], 1);
    }), group.divider ? _c('v-divider') : _vm._e()], 2);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }