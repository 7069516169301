











































import { Component, Vue } from "vue-property-decorator";
import Loader from "@/components/utility/Loader.vue";
import { FahrzeugscheinModule } from "@/store/modules/fahrzeugschein";
import { SessionModule } from "@/store/modules/session";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { SessionEnum } from "@/store/enum/sessionEnum";
import DropField from "@/components/files/DropField.vue";
import { ErrorLogModule } from "@/store/modules/error-log";

@Component({
  components: { LayoutSimple, Loader, DropField }
})
export default class FahrzeugscheinUpload extends Vue {
  isUploading = false;
  isLoading = false;

  file?: File = undefined;

  timeout = 5000;

  fahrzeugscheinDetailAvailable = false;

  get fahrzeugscheinStatus() {
    return FahrzeugscheinModule.status;
  }

  set fahrzeugscheinStatus(status) {
    FahrzeugscheinModule.setStatus(status);
  }

  get fahrzeugscheinSessionId() {
    return FahrzeugscheinModule.sessionId;
  }

  set fahrzeugscheinSessionId(sessionId) {
    FahrzeugscheinModule.setSessionId(sessionId);
  }

  changeUpload(file: File) {
    this.file = file;
    this.createFahrzeugschein();
  }

  async mounted() {
    this.fahrzeugscheinStatus = "";
    this.fahrzeugscheinSessionId = "";
  }

  async createFahrzeugschein() {
    this.isUploading = true;
    this.isLoading = true;

    try {
      await FahrzeugscheinModule.createFahrzeugschein(this.file);

      this.isUploading = false;

      this.$toast.success("Upload erfolgreich");

      await this.reloadFahrzeugscheinStatus();

      this.$toast.success("Ergebnis erfolgreich geladen");
    } catch (e) {
      this.$log.error(e);
      this.$toast.error("Fehler beim Upload");
      ErrorLogModule.addErrorLog({
        name: "Fahrzeugschein Erstellen",
        message: `${e}`
      });
    } finally {
      this.isLoading = false;
    }
  }

  async reloadFahrzeugscheinStatus() {
    this.isLoading = true;

    try {
      await this.waitToFinish();

      await SessionModule.fetchSessions();

      this.fahrzeugscheinDetailAvailable = true;
    } catch (error) {
      this.$store.state.errorLog.AddErrorLog({
        name: "Fahrzeugschein Erstellen - refresh",
        message: `${error}`
      });
    }

    this.isLoading = false;
  }

  async waitToFinish() {
    while (this.fahrzeugscheinStatus === SessionEnum.STARTED) {
      try {
        await FahrzeugscheinModule.reloadFahrzeugscheinStatus(this.fahrzeugscheinSessionId);
        await this.sleep(this.$store.state.config.throttleTimerInMs);
        this.$log.info(this.fahrzeugscheinStatus);
      } catch (e) {
        this.$log.error(e);
        throw e;
      }
    }
  }

  redirectFahrzeugschein() {
    this.$router.push({ name: "FahrzeugscheinComponent", params: { id: this.fahrzeugscheinSessionId } });
  }

  sleep(ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }
}
