







































import Debug from "@/components/utility/Debug.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ITicket } from "@/models/ticket.entity";
import ProjectGoToMixin, { ProjectGoToHelper } from "@/views/project/mixins/ProjectGoToMixin.vue";
import TicketCrudMixin from "@/views/project/mixins/TicketCrudMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TicketSideCard from "./TicketSideCard.vue";
import TicketTable from "./TicketTable.vue";

@Component({
  components: {
    TicketTable,
    TicketSideCard,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketTableCard extends mixins(TicketCrudMixin, ProjectGoToMixin) {
  /**
   * refs that will be set in new tickets and filtered (via or) for in table
   */
  @Prop({ default: () => [] })
  refs!: MrfiktivReferenceGen[];

  @Prop()
  loadingProjects!: boolean;

  @Prop()
  loadingTickets!: boolean;

  @Prop()
  loadingPartnerUsers!: boolean;

  @Prop({ default: true })
  openAsSideCard!: boolean;

  @Prop({ default: false })
  updateUrl!: boolean;

  @Prop({ default: false })
  showVehicleHeader?: boolean;

  isSideCard = false;

  close() {
    this.isSideCard = false;
    if (this.updateUrl) {
      this.setUrlTo.ticketTable({
        partnerId: this.partnerId
      });
    }
  }

  beforeMount() {
    this.partnerId = this.$route.params.partnerId;
  }

  async onDelete(ticket: ITicket) {
    const success = await this.deleteTicket(ticket);

    if (success) {
      this.close();
    }
  }

  async initTicketDetail(ticketNumber: number) {
    if (!this.openAsSideCard) {
      new ProjectGoToHelper(this.$router).go(
        ProjectGoToHelper.locations.ticketDetail(this.partnerId, ticketNumber.toString())
      );
      return;
    }

    if (this.updateUrl) {
      this.setUrlTo.ticketTable({
        partnerId: this.partnerId,
        number: ticketNumber
      });
    }

    this.isSideCard = true;
    const ticket = await this.getTicketByNumber(ticketNumber);
    this.ticketId = ticket?.id ?? "";
  }
}
