var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('debug', [_vm._v(" TicketDetailView " + _vm._s(_vm.partnerId) + " " + _vm._s(_vm.projectId) + " " + _vm._s(_vm.ticketNumber) + " ")]), _c('v-container', [_vm.loadingTicket ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.ticket && _vm.ticket.number >= 0 ? _c('div', [_c('ticket-detail-card', {
    staticStyle: {
      "height": "calc(100vh - 85px)"
    },
    attrs: {
      "loading": _vm.loadingUpdate,
      "partnerId": _vm.partnerId,
      "projectId": _vm.projectId,
      "viewId": _vm.viewId,
      "isSideCard": false
    },
    on: {
      "update": _vm.updateTicket,
      "updateTicketStatus": _vm.updateTicketStatus,
      "updateCustomFieldValues": _vm.updateCustomFieldValues,
      "updateReferences": _vm.updateTicket,
      "deleteTicket": _vm.onDeleteTicket,
      "update:assignees": function updateAssignees(assignees) {
        return _vm.updateTicket({
          assignees: assignees
        });
      },
      "delete:assignees": _vm.createDeleteAssigneeActivity,
      "add:assignees": _vm.createAddAssigneeActivity
    },
    model: {
      value: _vm.ticket,
      callback: function callback($$v) {
        _vm.ticket = $$v;
      },
      expression: "ticket"
    }
  })], 1) : _c('latest-entries-card-empty')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }