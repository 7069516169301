import { CostRouteNames } from "@/lib/utility/cost.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import CostDetailView from "@/views/cost/CostDetailView.vue";
import CostTableView from "@/views/cost/CostTableView.vue";
import CostGroupDetailView from "@/views/cost/CostGroupDetailView.vue";
import CostGroupTableView from "@/views/cost/CostGroupTableView.vue";
import CostGroupCustomViewView from "@/views/cost/CostGroupCustomViewView.vue";

/**
 * Routes for costs and tickets
 */
export const costRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/cost/",
    component: CostTableView,
    name: CostRouteNames.COST_TABLE,
    permission: {
      resource: ResourceEnum.COST,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.COST,
      icon: "mdi-cash-multiple",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  },
  {
    path: "/partner/:partnerId/cost/:costId",
    component: CostDetailView,
    name: CostRouteNames.COST_DETAIL,
    permission: {
      resource: ResourceEnum.COST,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/cost-group/",
    component: CostGroupTableView,
    name: CostRouteNames.COST_GROUP_TABLE,
    permission: {
      resource: ResourceEnum.COST_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.COST,
      icon: "mdi-cash-multiple",
      isEnabled: true,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  },
  {
    path: "/partner/:partnerId/cost-group/:costGroupId/",
    component: CostGroupCustomViewView,
    name: "COST_GROUP_CUSTOM_VIEW_HOME",
    permission: {
      resource: ResourceEnum.COST_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/cost-group/:costGroupId/settings",
    component: CostGroupDetailView,
    name: CostRouteNames.COST_GROUP_DETAIL,
    permission: {
      resource: ResourceEnum.COST_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/cost-group/:costGroupId/view/:viewId",
    component: CostGroupCustomViewView,
    name: CostRouteNames.COST_GROUP_CUSTOM_VIEW,
    permission: {
      resource: ResourceEnum.COST_GROUP,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
