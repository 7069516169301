/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BookingCreateResourceDtoGen,
  BookingExceptionViewmodelGen,
  BookingPageViewModelGen,
  BookingResourceControllerFindAllByPartnerIdParamsGen,
  BookingResourceViewModelGen,
  BookingUpdateResourceDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Resource<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags resource
   * @name ResourceControllerCreate
   * @summary Creates a resource for a given partner
   * @request POST:/partner/{partnerId}/resource
   * @secure
   * @response `201` `BookingResourceViewModelGen` Resource created
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  resourceControllerCreate = (partnerId: string, data: BookingCreateResourceDtoGen, params: RequestParams = {}) =>
    this.http.request<BookingResourceViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/resource`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags resource
   * @name ResourceControllerFindAllByPartnerId
   * @summary Gets all resources from a given partner
   * @request GET:/partner/{partnerId}/resource
   * @secure
   * @response `200` `(BookingPageViewModelGen & { data?: (BookingResourceViewModelGen)[] })`
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  resourceControllerFindAllByPartnerId = (
    { partnerId, ...query }: BookingResourceControllerFindAllByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingPageViewModelGen & { data?: BookingResourceViewModelGen[] }, BookingExceptionViewmodelGen>(
      {
        path: `/partner/${partnerId}/resource`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params
      }
    );
  /**
   * No description
   *
   * @tags resource
   * @name ResourceControllerFindOneByPartnerId
   * @summary Gets a particular resource from a given partner
   * @request GET:/partner/{partnerId}/resource/{id}
   * @secure
   * @response `200` `BookingResourceViewModelGen` Resource returned
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  resourceControllerFindOneByPartnerId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<BookingResourceViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/resource/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags resource
   * @name ResourceControllerReplace
   * @summary Replaces a resource for a given partner
   * @request PUT:/partner/{partnerId}/resource/{id}
   * @secure
   * @response `200` `BookingResourceViewModelGen` Resource details replaced
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  resourceControllerReplace = (
    partnerId: string,
    id: string,
    data: BookingUpdateResourceDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingResourceViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/resource/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags resource
   * @name ResourceControllerRemove
   * @summary Deletes a resource for a given partner
   * @request DELETE:/partner/{partnerId}/resource/{id}
   * @secure
   * @response `200` `BookingResourceViewModelGen` Resource deleted
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  resourceControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<BookingResourceViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/resource/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
