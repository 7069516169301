








































































































































import DueDateChip from "@/components/project/DueDateChip.vue";
import Debug from "@/components/utility/Debug.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { simpleDoubleDigitDate, formatHoursAndMinutes } from "@/lib/utility/date-helper";
import { CustomProjectTableHelper } from "@/lib/utility/project/customProjectTableHelper";
import {
  MrfiktivProjectCustomViewFieldDtoGen,
  MrfiktivCustomFieldListElementViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { CustomFieldEnum, CustomFieldModule } from "@/store/modules/custom-field.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { TicketModule } from "@/store/modules/ticket.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Ticket, ITicket } from "@/models/ticket.entity";
import { getNestedObjectValues } from "@/lib/objectPath-helper";
import TableWrapper from "@/components/utility/TableWrapper.vue";
import { PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { getArrayedNestedPath } from "@/lib/objectPath-helper";
import { ICustomFieldValue } from "@/models/custom-field-value.entity";
import CustomFieldValueChip from "@/components/report/CustomFieldValueChip.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import RefsList from "@/components/utility/RefsList.vue";
import { RefTypeMap } from "@/store/modules/refs.store";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import { Vehicle } from "@/models/vehicle.entity";
import { transformBooleanToText } from "@/lib/utility/boolean-helper";
import { CustomViewEntity } from "@/lib/interfaces/custom-view-entity.interface";

@Component({
  components: {
    Debug,
    Tooltip,
    DueDateChip,
    TableWrapper,
    CustomFieldValueChip,
    RefsList
  }
})
export default class CustomProjectTable<Type> extends Vue {
  readonly CustomFieldEnum = CustomFieldEnum;

  readonly BackendResourceEnum = BackendResourceEnum;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  loadingPartnerUsers!: boolean;

  @Prop()
  customConfig!: MrfiktivProjectCustomViewFieldDtoGen[];

  @Prop()
  items!: ITicket[];

  @Prop()
  partnerId!: string;

  @Prop({ default: "" })
  groupBy!: string;

  @Prop({ default: "" })
  sortBy!: string;

  @Prop({ default: true })
  sortDesc!: boolean;

  @Prop()
  filterOptions!: PaginationFilterListElement[];

  /**
   * The entity containing custo view configuration
   */
  @Prop({ default: () => ({}) })
  entity!: CustomViewEntity<Type>;

  selected = [];

  item!: ITicket;

  get testFilterssss() {
    return TicketModule.filters;
  }

  get testHiddnFilterssss() {
    return TicketModule.hiddenFilter;
  }

  get headers() {
    return new CustomProjectTableHelper(
      this.customConfig,
      this.filterOptions,
      this.entity?.configuration?.customFieldConfig ?? [],
      new Ticket()
    ).headers;
  }

  /**
   *
   */
  get customFieldSingleSelectHeaders() {
    return this.headers.filter(value =>
      [CustomFieldEnum.SINGLE_SELECT, CustomFieldEnum.MULTI_SELECT].includes(
        value.customFieldConfig?.customField.type as CustomFieldEnum
      )
    );
  }

  get customFields() {
    return CustomFieldModule.customFields;
  }

  customFieldIdFromHeader(header: {
    text: string;
    value: string | number | boolean;
    width?: string;
    align?: string;
    customFieldConfig?: MrfiktivCustomFieldListElementViewModelGen;
  }) {
    return header.customFieldConfig?.customField.id;
  }

  async getRefsItem(refType: BackendResourceEnum, refId: string) {
    const module = RefTypeMap.get(refType)?.module;

    if (!module) return;

    let found = module.maps.id.get(refId)[0] ?? module.filteredAndSorted.find(e => e.id === refId);

    if (!found && refType === BackendResourceEnum.VEHICLE) {
      const newEntity = new Vehicle({ id: refId, partnerId: this.partnerId });
      newEntity.loading = true;
      VehicleAccessLayer.set(newEntity);
      await newEntity.fetch();
      found = newEntity;
    }

    return found;
  }

  customFieldValue(
    header: {
      text: string;
      value: string | number | boolean;
      width?: string;
      align?: string;
      customFieldConfig?: MrfiktivCustomFieldListElementViewModelGen;
    },
    ticket: ITicket
  ) {
    const key = this.customFieldIdFromHeader(header);
    if (key) {
      const value = ticket[key]?.value;
      if (value) {
        return value;
      }
    }
  }

  /**
   *
   * @param header
   * @param ticket
   */
  customFieldColor(
    header: {
      text: string;
      value: string | number | boolean;
      width?: string;
      align?: string;
      customFieldConfig?: MrfiktivCustomFieldListElementViewModelGen;
    },
    ticket: ITicket
  ) {
    const key = this.customFieldIdFromHeader(header);
    if (key) {
      const value = ticket[key]?.color;
      if (value) {
        return value;
      }
    }
  }

  customKeyFilterAssinees(value: any) {
    return value.toString();
  }

  isOverdue(ticket: ITicket) {
    if (ticket.due && ticket.state === "open") {
      return new Date(ticket.due) < new Date();
    }

    return false;
  }

  // FIXME: Refactor into central component @see TicketSideCard.vue
  getUserNameForId(id: string) {
    const user = PartnerUserModule.maps.id.get(id)[0];

    if (!user) {
      return this.$t("timeLine.ActivityTimeLineItemDocument.unkown");
    }

    return `${user.firstName} ${user.lastName}`;
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  formatHoursAndMinutes(date: string) {
    return formatHoursAndMinutes(new Date(date));
  }

  transformBooleanToText(boolean: string) {
    return transformBooleanToText(boolean);
  }

  get extendedItems() {
    for (const item of this.items) {
      new CustomProjectTableHelper(
        this.customConfig,
        this.filterOptions,
        this.entity.configuration?.customFieldConfig ?? [],
        item
      ).getExtendedDocument();
    }

    return this.items;
  }

  getNestedObjectValues(obj: any, path: string) {
    return getNestedObjectValues(obj, path);
  }

  /**
   * Map the tickets data to resemble objects where properties are the header texts and value is the
   * actual data showed in the table under each column.
   * After constructing the data, emit it to the parent.
   * Example:
   *    for headers:
   *    [
   *      "Nummer",
   *      "Status",
   *      "Titel",
   *      "Fälligkeit",
   *      "Fahrzeug"
   *    ]
   *    The resulting object would look like this:
   *    {
   *      "Nummer": 344,
   *      "Status": "open",
   *      "Titel": " Fahrzeug: Vehicle #22 Schadensmeldungen: NRPLATE 21",
   *      "Fälligkeit": "",
   *      "Fahrzeug": "Vehicle #22",
   *      "Start": "2024-01-01T13:00"
   *    }
   */
  mapTicketsToTableData(): Record<string, any>[] {
    const tableProperties = this.headers.map(header => header.value);
    const tableHeaderTexts = this.headers.map(header => header.text);

    const sortedExtendedItems = this.sortExtendedItemsByGroupedBy();

    const elementsDisplayedInTable = sortedExtendedItems.map(extendedTicket => {
      const elementDisplayedInTable = {};

      tableHeaderTexts.forEach((header, index) => {
        const key = tableProperties[index] as string;

        if (key.startsWith("values.value")) {
          // Extract value of custom field
          elementDisplayedInTable[header] = extendedTicket[key]?.value;
        } else if (key === "assignees") {
          // Extract actual names of assignee
          elementDisplayedInTable[header] = extendedTicket[key]?.map((assigneeId: string) =>
            this.getUserNameForId(assigneeId)
          );
        } else {
          elementDisplayedInTable[header] = getNestedObjectValues(extendedTicket, key);
        }
      });

      return elementDisplayedInTable;
    });

    return elementsDisplayedInTable;
  }

  /**
   * Sort the tickets by groupedBy property in ascending order
   */
  private sortExtendedItemsByGroupedBy() {
    if (!this.groupBy) {
      return this.extendedItems;
    }

    const sortedItems = this.extendedItems.sort((a, b) => {
      /**
       * Extract the actual value shown in the table.
       * For properties like "state" it's straight forward, but for others
       * like "vehicle.value" we need to extract it.
       */
      const aValueToCompare = getNestedObjectValues(a, this.groupBy);
      const bValueToCompare = getNestedObjectValues(b, this.groupBy);

      /**
       * Skip array sorting - like assignees
       */
      if (Array.isArray(aValueToCompare) || Array.isArray(bValueToCompare)) {
        return 0;
      }

      return aValueToCompare < bValueToCompare ? -1 : 1;
    });

    return sortedItems;
  }

  getCustomFieldValueFromItem(item: { values: ICustomFieldValue[] }, customFieldId: string) {
    const customField = item.values?.find(v => v.id === customFieldId);
    if (customField?.value) {
      return customField.value;
    }

    return "";
  }

  customGroupMethod(items: any[], groupBys: string[], groupDesc: boolean[]): { name: string; items: any[] }[] {
    const groupBy = groupBys[0];
    const groupByKey = groupBy.split("?")[0];
    const groupByFilter = groupBy.split("?")[1];

    const isCustomField = groupByKey === "values.value";
    const isRefsFilter = groupByKey === "refs";

    const groupMap: Record<string, any[]> = {};

    for (const item of items) {
      let groups: string[] = [""];
      if (isCustomField && groupByFilter) {
        const customField = (item.values as ICustomFieldValue[]).find(v => v.id === groupByFilter);
        if (customField?.value) {
          if (typeof customField.value === "string") groups = [customField.value];
          else groups = customField.value;
        }
      } else if (isRefsFilter && groupByFilter) {
        const refs = [...(item.refs as MrfiktivReferenceGen[])].filter(v => v.refType === groupByFilter);
        groups = refs.map(r => r.refId);
      } else {
        groups = getArrayedNestedPath(item, groupByKey) as string[];
      }

      for (const group of groups) {
        if (!groupMap[group]) {
          groupMap[group] = [];
        }

        groupMap[group].push(item);
      }
    }

    const keys = Object.keys(groupMap).sort((a, b) => (!groupDesc[0] ? a.localeCompare(b) : b.localeCompare(a)));

    return keys.map(key => {
      return {
        name: key,
        items: groupMap[key]
      };
    });
  }

  @Prop()
  identifier?: string;

  @Prop({ default: 273 })
  offset?: number;
}
