













































































































































































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProjectModule } from "@/store/modules/project.store";
import { ITicket } from "@/models/ticket.entity";
import { TicketModule } from "@/store/modules/ticket.store";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import ProjectGoToMixin, { ProjectGoToHelper } from "@/views/project/mixins/ProjectGoToMixin.vue";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldListForm from "../report/CustomFieldListForm.vue";
import MHeader, { IAction, IBreadcrumb } from "../utility/mmmint/MHeader.vue";
import Tooltip from "../utility/tooltip.vue";
import TicketForm from "./TicketForm.vue";
import TicketRefsSelect from "./TicketRefsSelect.vue";
import TicketSideCardActions from "./TicketSideCardActions.vue";
import TicketSideCardActivities from "./TicketSideCardActivities.vue";
import TicketSideCardBody from "./TicketSideCardBody.vue";
import { ICustomFieldValue } from "@/models/custom-field-value.entity";

@Component({
  components: {
    TicketSideCardBody,
    TicketSideCardActivities,
    TicketSideCardActions,
    TicketForm,
    CustomFieldListForm,
    MHeader,
    Tooltip,
    TicketRefsSelect
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class TicketDetailCard extends mixins(RulesMixin, DarkModeHighlightMixin, ProjectGoToMixin) {
  @Prop()
  private value!: ITicket;

  @Prop()
  loading!: boolean;

  @Prop()
  partnerId!: string;

  @Prop()
  projectId!: string;

  @Prop()
  viewId!: string;

  @Prop({ default: true })
  isSideCard!: boolean;

  readonly TICKET_STATUS = "ticketStatus";

  editTitle = "";
  isEdit = false;
  editSheet = false;

  get ProjectGoToHelper() {
    return ProjectGoToHelper;
  }

  get ticketTo() {
    return ProjectGoToHelper.locations.ticketDetail(this.ticket.partnerId, this.ticket.number.toString());
  }

  /**
   * Is Ticket form valid
   */
  isFormValid = false;

  /**
   * are custom fields valid
   */
  isCustomFieldValid = false;

  ticketColor() {
    return TicketModule.ticketStateMap.get(this.ticket.state as TicketStatusEnum)?.color || "blue-grey lighten-4";
  }

  get dueDate(): string | null {
    if (this.ticket.due) {
      return simpleDoubleDigitDate(this.ticket.due);
    }

    return null;
  }

  get isOverdue() {
    if (this.ticket.due && this.ticket.state === TicketStatusEnum.OPEN) {
      return new Date(this.ticket.due) < new Date();
    }

    return false;
  }

  get breadCrumbList() {
    if (this.isSideCard) {
      return undefined;
    }

    const crumbs: IBreadcrumb[] = [];
    if (this.projectId) {
      crumbs.push(this.breadCrumbs.ProjectTable(this.partnerId));
      crumbs.push(this.breadCrumbs.ProjectDetail(this.partnerId, this.projectId));
      crumbs.push(this.breadCrumbs.ProjectCustomView(this.partnerId, this.projectId, this.viewId));
      crumbs.push(
        this.breadCrumbs.ProjectTicketDetail(
          this.partnerId,
          this.projectId,
          this.viewId,
          this.ticket?.number?.toString() || "..."
        )
      );
    } else {
      crumbs.push(this.breadCrumbs.TicketTable(this.partnerId));
      crumbs.push(this.breadCrumbs.TicketDetail(this.partnerId, this.ticket?.number?.toString() || "..."));
    }

    return crumbs;
  }
  get chips(): IAction[] {
    return [{ text: this.$t("project.ticketState.open").toString(), key: this.TICKET_STATUS, disabled: true }];
  }

  get ticket() {
    return this.value;
  }

  set ticket(ticket: ITicket) {
    this.$log.debug("ticket", ticket);
    this.$emit("input", ticket);
  }

  get isValid() {
    return this.isFormValid && this.isCustomFieldValid;
  }

  get project() {
    if (!this.ticket || !this.ticket.projectId) {
      return undefined;
    }

    const project = ProjectModule._data.get(this.ticket.projectId);

    if (project) return project;

    return ProjectModule.paginationList.find(p => p.id === this.ticket.projectId);
  }

  startEdit() {
    this.editTitle = deepCopy(this.ticket.title);
    this.isEdit = true;
  }

  cancel() {
    this.isEdit = false;
    this.editTitle = "";
  }

  updateTicketTitle() {
    this.$emit("update", { title: this.editTitle });
    this.ticket.title = deepCopy(this.editTitle);
    this.editTitle = "";
    this.isEdit = false;
  }

  updateTicketBody(body: string) {
    this.$emit("update", { body: body });
  }

  updateReferences(refs: MrfiktivReferenceGen[]) {
    this.$emit("updateReferences", { refs });
  }

  debounceUpdateCustomFields = debounce(
    (fieldValue: ICustomFieldValue) => this.updateCustomFieldValues(fieldValue),
    1000,
    false
  );
  updateCustomFieldValues(fieldValue: ICustomFieldValue) {
    if (!this.isFormValid || !this.isCustomFieldValid) {
      this.$log.error("Skip validation", this.isFormValid, this.isCustomFieldValid);
    }

    this.$log.debug(this.ticket.values);
    this.$log.debug(fieldValue);
    this.$emit("updateCustomFieldValues", this.ticket.values);
  }

  debounceUpdateBaseTicket = debounce(this.updateBaseTicket, 1000, false);
  updateBaseTicket() {
    if (!this.isFormValid || this.isCustomFieldValid) {
      this.$log.error("Skip validation");
    }

    this.$emit("update", this.ticket.baseUpdateDto);
  }

  updateTicketStatus(state: TicketStatusEnum) {
    this.ticket.state = state;
    this.$emit("updateTicketStatus", this.ticket);
  }

  deleteTicket() {
    this.$emit("deleteTicket", this.ticket);
  }
}
