


















import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { CalendarTypeEnum } from "@/lib/utility/calendarEvent";
import { formatHoursAndMinutes, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  FleetAggregationModule,
  VehicleAggregation,
  VehicleEventCollection,
  VehicleEventEvent,
  VehicleEventTypeEnum
} from "@/store/modules/fleet-aggregation.store";
import { PartnerModule } from "@/store/modules/partner";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import FleetCalendarCard from "./FleetCalendarCard.vue";
import FleetVehicleEventCrudMixin from "./FleetVehicleEventCrudMixin.vue";

@Component({
  components: {
    FleetCalendarCard
  },
  filters: { simpleDoubleDigitDate, formatHoursAndMinutes }
})
export default class FleetHomeCalendar extends mixins(DarkModeHighlightMixin, FleetVehicleEventCrudMixin) {
  @Prop({ default: false })
  loadingEvents!: boolean;

  isUpdateDialogActive = false;

  isUpdateValid = false;

  currentVehicleId = "";

  is = {
    [VehicleEventTypeEnum.MAIN_INSPECTION]: true,
    [VehicleEventTypeEnum.EVENT]: true,
    [VehicleEventTypeEnum.LEASING_START]: true,
    [VehicleEventTypeEnum.LEASING_END]: true,
    [VehicleEventTypeEnum.MILEAGE_UPDATE]: true,
    [VehicleEventTypeEnum.TICKET]: true,
    [VehicleEventTypeEnum.REPORT]: true,
    [VehicleEventTypeEnum.CREATION]: false
  };

  selectedEvent: VehicleEventEvent | null = null;

  calendarType = this.isMobile ? CalendarTypeEnum.DAY : CalendarTypeEnum.MONTH;

  AppointmentTypeEnum = VehicleEventTypeEnum;

  parsed = new VehicleEventCollection();

  eventVehicleId = "";

  private calendarFocuusLocal = new Date();
  get calendarFocus() {
    return this.calendarFocuusLocal;
  }
  set calendarFocus(cf: Date) {
    this.calendarFocuusLocal = cf;
    this.$emit("updateEventList", cf);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  simpleDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  get i18n() {
    return this.$t("components.fleet.FleetHomeCalendar");
  }

  get appointmentTypeColorsMap() {
    return VehicleAggregation.appointmentTypeColorsMap;
  }

  get vehicleAggregationMapKey() {
    return FleetAggregationModule.vehicleAggregationMapKey;
  }

  get headers(): ITableWrapperHeader[] {
    return [
      { text: this.i18n["start"], value: "start", type: "date" },
      { text: this.i18n["name"], value: "name" },
      { text: this.i18n["vehicle"], value: "vehicle" }
    ];
  }

  get partnerId() {
    return PartnerModule.partner.id ?? PartnerModule.partner._id;
  }

  mounted() {
    this.setParsedEvents();
  }

  async onCreateEvent(event: IEventUIDto) {
    if (!this.eventVehicleId) {
      this.$log.error("No vehicle ID");
      return;
    }

    await this.createEvent(event, this.eventVehicleId, new Date(this.calendarFocus));

    if (close) close();
  }

  closeSideCard() {
    this.selectedEvent = null;
  }

  @Watch("loadingEvents", { deep: true })
  @Watch("vehicleAggregationMapKey", { deep: true })
  debounceSetParsedEvent = debounce(() => this.setParsedEvents(), 100);

  async setParsedEvents() {
    const current = this.calendarFocuusLocal ? new Date(this.calendarFocuusLocal) : new Date();
    const parsed = await FleetAggregationModule.getEvents({ year: current.getFullYear(), month: current.getMonth() });

    this.parsed.setEvents(parsed);
  }
}
