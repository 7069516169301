








































import { GoToHelper } from "@/lib/utility/goToHelper";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import RefsDocument from "../utility/RefsDocument.vue";

@Component({
  components: {
    RefsDocument,
    ContextMenu,
    LatestEntriesCardEmpty,
    ConfirmActionDialog
  },
  filters: {}
})
export default class ContractCardDocument extends mixins(RulesMixin) {
  @Prop()
  documentId!: string;

  @Prop()
  partnerId!: string;

  loading = false;

  document: ISignDocument | null = null;

  isConfirmDeleteDialogActive = false;

  async mounted() {
    try {
      this.loading = true;
      const document = await new SignDocument({ partnerId: this.partnerId, id: this.documentId }).fetch();

      this.document = document ?? null;
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loading = false;
    }
  }

  goToDocument() {
    new GoToHelper(this.$router).goToDocumentDetail(this.documentId, this.partnerId, true);
  }

  startRemoveDialog() {
    this.isConfirmDeleteDialogActive = true;
  }
}
