


































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import StatisticsCardColumn from "./StatisticsCardColumn.vue";
import StatisticsSparkLineCard from "@/components/cards/StatisticsSparkLineCard.vue";
import { FormEntity } from "@/models/formEntity";
import { IThg } from "@/models/thg.entity";
import { IReport } from "@/models/report.entity";

@Component({ components: { StatisticsCardColumn, StatisticsSparkLineCard } })
export default class StatisticsCard extends DarkModeHighlightMixin {
  @Prop({ default: "" })
  statTitle!: string;

  @Prop({ default: "mdi-car-wrench" })
  icon!: string;

  @Prop()
  items!: IReport[] | FormEntity[] | IThg[];

  @Prop({ default: "" })
  subtitle!: string;

  @Prop({ default: "" })
  statistics!: string;

  @Prop({ default: "" })
  change!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "blue-grey" })
  progressbarColor!: string;

  isPositiv(value: string) {
    const firstChar = value.charAt(0);
    if (firstChar === "+") {
      return true;
    }

    return false;
  }
}
