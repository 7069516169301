





































































import ReportBaseTimeLine from "@/components/partner/ReportBaseTimeLine.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import { PartnerModule } from "@/store/modules/partner";
import { SharedReportModule } from "@/store/modules/shared-report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    ReportBaseTimeLine,
    Tooltip
  }
})
export default class PartnerSharedReport extends Vue {
  loading = false;
  isGoHomeOnBack = false;
  error = false;

  print() {
    window.print();
  }

  get logo() {
    return this.partner?.settings?.reportSettings?.logoUrl || "";
  }

  get report() {
    return SharedReportModule.sharedReport?.report;
  }

  get user() {
    return SharedReportModule.sharedReport?.sharedContentDetailViewmodel.user;
  }

  get partner() {
    return SharedReportModule.sharedReport?.sharedContentDetailViewmodel.partner;
  }

  get sharedContent() {
    return SharedReportModule.sharedReport?.sharedContentDetailViewmodel.sharedContent;
  }

  get date() {
    if (this.sharedContent?.validBy) {
      return simpleDate(this.sharedContent?.validBy);
    } else {
      return "unbegrenzt";
    }
  }

  get portalPartner() {
    return PartnerModule.partner;
  }

  async mounted() {
    this.loading = true;
    this.error = false;
    try {
      PartnerModule.getPartnerById(this.$route.params.partnerId);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim Laden des Partners");
    }

    try {
      if (this.$route.query.isGoHomeOnBack === "true") {
        this.isGoHomeOnBack = true;
      }
      await SharedReportModule.getSharedReport({
        partnerId: this.$route.params.partnerId,
        reportId: this.$route.params.reportId,
        accesToken: this.$route.params.accesssignature
      });
    } catch {
      this.error = true;
      this.$toast.error("Inhalt konnte nicht geladen werden.");
    }
    this.loading = false;
  }
}
