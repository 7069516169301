



















import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import EventForm from "@/components/event/EventForm.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import EventDetailView from "./EventDetailView.vue";

@Component({
  components: { EventForm, TheLayoutPortal, LatestEntriesCardEmpty }
})
export default class EventFormView extends mixins(EventDetailView) {}
