
































































































































































































































































import AvailabilityForm from "@/components/booking/AvailabilityForm.vue";
import BookingCustomerFieldConfiguration from "@/components/booking/BookingCustomerFieldConfiguration.vue";
import BookingImageConfiguration from "@/components/booking/BookingImageConfiguration.vue";
import ResourceList from "@/components/booking/ResourceList.vue";
import AddressForm from "@/components/partner/AddressForm.vue";
import PublicImageUploadDialog from "@/components/public-image/PublicImageUploadDialog.vue";
import IconSelect from "@/components/utility/IconSelect.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { PartnerColor } from "@/lib/partnerColor";
import { timeFormatRule } from "@/lib/rules/dateRule";
import { isNotEmptyArrayRule } from "@/lib/rules/isNotEmptyArray";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { AvailabilityMapper } from "@/lib/utility/booking/availabilityMapper";
import { DaysOfTheWeekOrder } from "@/lib/utility/daysOfTheWeekHelper";
import { deepCopy } from "@/lib/utility/deep-copy";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { BookingAvailabilityGen } from "@/services/booking/v1/data-contracts";
import { PublicImageImageViewmodelGen } from "@/services/image/v1/data-contracts";
import { MrfiktivPartnerViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PublicImageFolderEnum } from "@/store/enum/public-image/publicImageFolderEnum";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IService } from "@/models/service.entity";
import { IResource } from "@/models/resource.entity";
import CustomFieldConfigurationForm from "@/components/report/CustomFieldConfigurationForm.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    AvailabilityForm,
    BookingCustomerFieldConfiguration,
    BookingImageConfiguration,
    ResourceList,
    IconSelect,
    PublicImageUploadDialog,
    AddressForm,
    CustomFieldConfigurationForm
  }
})
export default class ServiceForm extends Vue {
  isValid = false;
  isDisabled = true;
  partnerColor = new PartnerColor();
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  @Prop()
  partner!: MrfiktivPartnerViewModelGen;

  @Prop({ default: false })
  isCreate!: boolean;

  @Prop()
  value!: IService;

  @Prop()
  resources!: IResource[];

  @Prop()
  selectedResources!: IResource[];

  @Prop({ default: false })
  isLoading!: boolean;

  folderName = PublicImageFolderEnum.PARTNER;
  serviceResourceIds: string[] = null as any;
  tabsSpaceClasses = ["ml-4", "mr-2"];
  // Availability as is originally created
  availability: BookingAvailabilityGen[] = [];

  get address() {
    if (
      !this.value.address.street &&
      !this.value.address.zip &&
      !this.value.address.city &&
      !this.value.address.geo.lat &&
      !this.value.address.geo.lng
    ) {
      // Initialize value address with partner address
      this.value.address = {
        street: this.partner.address?.street || "",
        city: this.partner.address?.city || "",
        state: this.partner.address?.state || "",
        zip: this.partner.address?.zip || "",
        countryCode: this.partner.address?.countryCode || CountryCodeEnum.germany,
        geo: this.partner.address?.geo || { lat: 0, lng: 0 }
      };

      return this.value.address;
    } else {
      return this.value.address;
    }
  }

  get hasOwnAddressOrPartners() {
    if (
      (!this.value.address.street &&
        !this.value.address.zip &&
        !this.value.address.city &&
        !this.value.address.geo.lat &&
        !this.value.address.geo.lng) ||
      !this.partner
    ) {
      return false;
    }

    return true;
  }

  @Watch("value", { deep: true })
  emitUpdated() {
    this.$emit("input", this.value);
  }

  @Watch("isValid")
  emitIsValid() {
    this.$emit("valid-change", this.isValid);
  }

  mounted() {
    this.serviceResourceIds = this.value.resourceIds.slice();
    /**
     * Sort the availabilities array Monday to Sunday
     * It comes from server as Sunday to Saturday
     */
    const availabilitySortedMondaySunday = AvailabilityMapper.getAvailabilitySorted(
      this.value.availability,
      DaysOfTheWeekOrder.Javascript,
      DaysOfTheWeekOrder.Normal
    );

    this.availability = deepCopy(availabilitySortedMondaySunday);
  }

  onResourcesChange(resources: string[]) {
    this.value.resourceIds = resources;
  }

  /**
   * Save availability table.
   * Availability array contains days and times. Day is coded as the index of the array.
   * So, 0 will be Monday, 1 Tuesday,...6 Sunday.
   * @param availability
   */
  onAvailabilityChange(availability: BookingAvailabilityGen[]) {
    /**
     * Order the availability array as Sunday to Saturday.
     * After edit, the availability should always be Sunday to Saturday.
     */

    const availabilitySortedSundaySaturday = AvailabilityMapper.getAvailabilitySorted(
      availability,
      DaysOfTheWeekOrder.Normal,
      DaysOfTheWeekOrder.Javascript
    );

    this.value.availability = deepCopy(availabilitySortedSundaySaturday);
  }

  onSaveClicked() {
    this.$log.info(this.value);
    this.$emit("save", this.value);
  }

  onAddressUpdate(address: any) {
    this.value.address = address;
  }

  onIconSelect(icon: string) {
    this.value.icon = icon;
  }

  handleServiceImageUpload(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.value.imageUrl = image.cdnUrl;
    }
  }

  isReportImageDisabled(item: ReportImageType) {
    return this.value.imageConfig.map(i => i.type).includes(item);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get numberRule() {
    return [isNumberRule(), requiredRule()];
  }

  get timeFormatRule() {
    return [timeFormatRule()];
  }

  get arrayNotEmptyRule() {
    return [isNotEmptyArrayRule()];
  }

  get disabled() {
    return this.isLoading;
  }
}
