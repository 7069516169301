


























































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FleetVehicleDetailVehicleCard from "@/components/fleet/FleetVehicleDetailVehicleCard.vue";
import Card from "@/components/utility/Card.vue";
import Debug from "@/components/utility/Debug.vue";
import UpdatableCard from "@/components/utility/UpdatableCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivPoolViewModelGen, MrfiktivUpdatePoolDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { PoolModule } from "@/store/modules/pool.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    LayoutSimple,
    Card,
    UpdatableCard,
    FleetVehicleDetailVehicleCard,
    LatestEntriesCardEmpty,
    Debug
  }
})
export default class FleetPoolDetailView extends PartnerFallbackMixin {
  loading = false;
  loadingUpdate = false;
  isDisabled = true;

  pool: MrfiktivPoolViewModelGen | undefined;

  get i18n() {
    return this.$t("views.fleet.FleetPoolDetailView");
  }

  async loadPool() {
    try {
      this.loading = true;
      await this.trySetByRouteOrDefault();

      VehicleModule.getAll({ partnerId: PartnerModule.partner._id });

      const pool = await PoolModule.getOne({
        partnerId: PartnerModule.partner._id,
        poolId: this.$route.params.poolId
      });
      this.pool = pool;
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loading = false;
    }
  }

  async updatePool(pool: MrfiktivPoolViewModelGen, refresh = false) {
    try {
      if (!this.pool?.name || !this.pool?.vehicles) {
        return;
      }

      const updateDto: MrfiktivUpdatePoolDtoGen = {
        name: this.pool.name,
        vehicles: this.pool.vehicles
      };

      this.loadingUpdate = true;

      const updated = await PoolModule.update({
        partnerId: PartnerModule.partner._id,
        poolId: this.pool.id,
        data: updateDto
      });
      this.pool = updated;
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loadingUpdate = false;
      if (refresh) {
        await this.loadPool();
      }
    }
  }

  get vehicles() {
    return VehicleModule.vehicles;
  }

  vehicleById(vehicleId: string) {
    return VehicleModule.maps.id.get(vehicleId)[0];
  }

  selectVehicle(id: string) {
    if (!id) {
      return;
    }

    this.$router.push({
      name: "VehicleDetail",
      params: {
        partnerId: PartnerModule.partner._id,
        vehicleId: id
      }
    });
  }

  async mounted() {
    await this.loadPool();
  }
}
