var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('paginated-table', {
    ref: "paginationTable",
    attrs: {
      "store": _vm.pagination,
      "title": _vm.$t('nav.PartnerLinkerView.title'),
      "headers": _vm.headers,
      "baseQuery": _vm.baseQuery,
      "controlElements": _vm.controlElements
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('Can', {
          attrs: {
            "I": _vm.ActionEnum.CREATE,
            "a": _vm.ResourceEnum.PARTNER_LINK
          }
        }, [_c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "elevation": "0",
            "color": "info"
          },
          on: {
            "click": function click($event) {
              _vm.isCreateLinkDialogActive = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamp.created)) + " ")];
      }
    }])
  })], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isCreateLinkDialogActive,
      "title": _vm.$t('views.PartnerLinkerView.create'),
      "leftText": _vm.$t('close'),
      "rightText": _vm.$t('create'),
      "loading": _vm.loading,
      "rightLoading": _vm.loading,
      "rightDisabled": !_vm.refPartnerId
    },
    on: {
      "leftClick": _vm.close,
      "rightClick": _vm.create,
      "close": _vm.close
    }
  }, [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.MANAGE,
      "a": _vm.ResourceEnum.PARTNER
    }
  }, [_c('partner-selection-form', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "save": _vm.setRef
    }
  })], 1), _c('v-text-field', {
    attrs: {
      "outlined": ""
    },
    model: {
      value: _vm.refPartnerId,
      callback: function callback($$v) {
        _vm.refPartnerId = $$v;
      },
      expression: "refPartnerId"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }