





















import { IAddress } from "@/models/address.entity";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class AddressForm extends Vue {
  @Prop()
  value!: IAddress;

  isValid = false;

  @Watch("isValid")
  watchIsValid(value: boolean) {
    this.$emit("isValid", value);
  }
}
