import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Driver } from "../v1/Driver";
import {
  MrfiktivCreateDriverDtoGen,
  MrfiktivDriverControllerGetAllParamsGen,
  MrfiktivDriverViewModelGen,
  MrfiktivUpdateDriverDtoGen,
  MrfiktivUpdateDriverProofDtoGen
} from "../v1/data-contracts";

class DriverService {
  /**
  /**
   * The proxy.
   */
  mrfiktivProxy: Driver;

  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Driver(mrfiktivHttpClientProvider.client());
  }

  async create(partnerId: string, data: MrfiktivCreateDriverDtoGen) {
    return (await this.mrfiktivProxy.driverControllerCreate(partnerId, data)).data;
  }
  async delete(partnerId: string, driverId: string) {
    return (await this.mrfiktivProxy.driverControllerDelete(partnerId, driverId)).data;
  }
  async getAll(params: MrfiktivDriverControllerGetAllParamsGen) {
    return (await this.mrfiktivProxy.driverControllerGetAll(params)).data;
  }
  async getOne(partnerId: string, driverId: string) {
    return (await this.mrfiktivProxy.driverControllerGetOne(partnerId, driverId)).data;
  }
  async update(partnerId: string, driverId: string, data: MrfiktivUpdateDriverDtoGen) {
    return (await this.mrfiktivProxy.driverControllerUpdate(partnerId, driverId, data))
      .data as MrfiktivDriverViewModelGen;
  }
  async updateProof(partnerId: string, driverId: string, data: MrfiktivUpdateDriverProofDtoGen) {
    return (await this.mrfiktivProxy.driverControllerUpdateProof(partnerId, driverId, data)).data;
  }
}

export default new DriverService(new MrfiktivHttpClientProvider());
