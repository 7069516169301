var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.verbs.download")))]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.dialog,
      "absolute": true,
      "title": _vm.$t('common.verbs.download'),
      "leftColor": "error",
      "leftDisabled": !_vm.isDownloadStarted || _vm.isFinished,
      "rightDisabled": _vm.isDownloadStarted
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "leftClick": _vm.abortDownload,
      "rightClick": _vm.downloadImages
    }
  }, [_vm.isDownloadStarted ? _c('v-card-text', [_c('v-progress-linear', {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.relativeProgress,
      "height": "25",
      "indeterminate": _vm.isCreatingZip
    }
  }, [_c('strong', [_vm._v(_vm._s(Math.ceil(_vm.relativeProgress)) + "% ( " + _vm._s(_vm.progress) + "/" + _vm._s(_vm.numberOfDownloads) + ")")])]), _c('v-subheader', {
    staticClass: "pl-0"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]), _vm.failedDownloads.length ? _c('v-data-table', {
    staticClass: "mt-4",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.failedDownloads,
      "item-key": "item.id",
      "sortBy": "timestamp.created",
      "sortDesc": true,
      "items-per-page": 10
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }