var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "title": _vm.project.title || _vm.$t('project.project.newProject'),
      "width": "600px",
      "loading": _vm.loading
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card-text', [_c('project-form', {
    attrs: {
      "valid": _vm.isValid,
      "disabled": !_vm.isEdit
    },
    on: {
      "update:valid": function updateValid($event) {
        _vm.isValid = $event;
      }
    },
    model: {
      value: _vm.project,
      callback: function callback($$v) {
        _vm.project = $$v;
      },
      expression: "project"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "info",
      "elevation": 0
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("close")) + " ")]), _c('v-spacer'), !_vm.isEdit ? _c('v-btn', {
    attrs: {
      "text": "",
      "outlined": "",
      "color": "info",
      "elevation": 0
    },
    on: {
      "click": _vm.goToProjectCustomView
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("project.project.viewTickets")) + " ")]) : _vm._e(), _vm.isUpdate && !_vm.isEdit ? _c('v-btn', {
    attrs: {
      "color": "info",
      "elevation": 0
    },
    on: {
      "click": function click($event) {
        _vm.isEdit = !_vm.isEdit;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]) : _vm.isUpdate && _vm.isEdit ? _c('v-btn', {
    attrs: {
      "disabled": !_vm.isValid,
      "color": "info",
      "elevation": 0
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('update', _vm.project);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]) : !_vm.isUpdate ? _c('v-btn', {
    attrs: {
      "disabled": !_vm.isValid,
      "color": "info",
      "elevation": 0
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('create', _vm.project);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }