











































































































































import { detailedDate } from "@/lib/utility/date-helper";
import { SettingModule } from "@/store/modules/setting.store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";
import { valueRule } from "@/lib/rules/valueRule";
import { MrfiktivUpdateSettingDtoGen as UpdateSettingDto } from "@/services/mrfiktiv/v1/data-contracts";
import PartnerManageDeleteSetting from "@/components/partner/PartnerManageDeleteSetting.vue";

@Component({
  components: { PartnerManageDeleteSetting }
})
export default class PartnerManageDetailCard extends Vue {
  /**
   * is edit form valid
   */
  isEditValid = false;

  /**
   * is cancel edit dialog open
   */
  cancelEditDialog = false;

  /**
   * is save edit dialog open
   */
  saveEditDialog = false;

  /**
   * is edit mode active
   */
  editActive = false;

  /**
   * Text shown in value field
   */
  editValue = "";

  /**
   * Is encryption activated in edit mode
   */
  editIsEncrypted = false;

  /**
   * Is encryption activated in edit mode
   */
  editIsPublic = false;

  /**
   * Prevents unintentional form submission when save dialog is submitted using enter key
   */
  lockForm = false;

  /**
   * get text shown in value field
   */
  get value() {
    if (this.partnerSetting.isEncrypted) {
      return "************";
    }
    if (this.editActive) {
      return this.editValue;
    }
    return this.partnerSetting.value || "";
  }

  /**
   * Update text in value field during edit
   */
  set value(value: string) {
    this.editValue = value;
  }

  /**
   * Returns rules for value input field
   */
  get valueRules() {
    return [requiredRule(), valueRule()];
  }

  /**
   * get is encryption activated in edit mode
   */
  get isEditEncrypted() {
    if (this.partnerSetting.isEncrypted) {
      return true;
    }
    return this.editIsEncrypted;
  }

  /**
   * change encryption setting in edit mode
   */
  set isEditEncrypted(value: boolean) {
    this.editIsEncrypted = value;
  }

  /**
   * get is encryption activated in edit mode
   */
  get isEditPublic() {
    if (this.partnerSetting.isEncrypted) {
      return true;
    }
    return this.editIsPublic;
  }

  /**
   * change encryption setting in edit mode
   */
  set isEditPublic(value: boolean) {
    this.editIsPublic = value;
  }

  /**
   * if user is already in detail view the option to go there is not shown
   */
  get displayToDetailView() {
    return !this.$route.params.settingId;
  }

  /**
   * get partner setting from store
   */
  get partnerSetting() {
    return SettingModule.partnerSetting;
  }

  /**
   * display date when setting was created
   */
  get date() {
    if (this.partnerSetting?.timestamp.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.partnerSetting.timestamp.created, locale);
    }
    return detailedDate(Date.now().toString());
  }

  /**
   * check if setting is encrypted.
   * setting can not be edited when encrypted and value is hidden
   */
  get isEncrypted() {
    return this.partnerSetting.isEncrypted;
  }

  /**
   * check if setting is encrypted.
   * setting can not be edited when encrypted and value is hidden
   */
  get isPublic() {
    return this.partnerSetting.isPublic || false;
  }

  /**
   * check if partner setting is loading
   */
  get partnerSettingLoaded() {
    return SettingModule.partnerSettingLoaded;
  }

  /**
   * get id of partnerSetting
   */
  get settingId() {
    return SettingModule.partnerSetting?.id;
  }

  /**
   * open setting in detail view
   */
  toDetailView() {
    this.$router.push({ name: "PartnerManageDetailView", params: { settingId: this.partnerSetting.id } });
  }

  /**
   * Close edit mode when different setting is opened
   */
  @Watch("partnerSetting", { deep: true })
  onPartnerSettingChanged() {
    this.setEditActive(false);
  }

  /**
   * (de-) activate edit mode
   */
  setEditActive(value: boolean) {
    this.editActive = value;
    this.value = this.partnerSetting.value || "";
    this.isEditEncrypted = this.partnerSetting.isEncrypted;
  }

  async onDelete() {
    await SettingModule.getSettings();
  }

  /**
   * Show cancel edit dialog
   */
  showCancelEditDialog() {
    this.cancelEditDialog = true;
  }

  /**
   * Hide cancel edit dialog
   */
  hideCancelEditDialog() {
    this.cancelEditDialog = false;
  }

  /**
   * show initial values
   * close edit mode
   * close dialog
   */
  submitCancelEditDialog() {
    this.hideCancelEditDialog();
    this.setEditActive(false);
  }

  /**
   * Show save edit dialog
   */
  showSaveEditDialog() {
    if (!this.lockForm && this.isEditValid) {
      this.saveEditDialog = true;
    }
  }

  /**
   * Hide save edit dialog
   */
  hideSaveEditDialog() {
    this.saveEditDialog = false;
  }

  /**
   * Save setting
   * close dialog
   */
  async submitSaveEditDialog() {
    this.lockForm = true;
    this.hideSaveEditDialog();

    const updateSettingDto: UpdateSettingDto = {
      key: this.partnerSetting.key,
      value: this.value,
      isEncrypted: this.isEditEncrypted,
      isPublic: this.isEditPublic
    };
    await SettingModule.updateSettingAndRefreshSettings(updateSettingDto);

    this.lockForm = false;
  }
}
