








import OperationTable from "@/components/operations/OperationTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    OperationTable
  }
})
export default class PartnerOperations extends PartnerFallbackMixin {
  get partnerId() {
    return this.$route.params.partnerId;
  }

  async mounted() {
    await this.trySetByRouteOrDefault();
  }
}
