import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import daService from "@/services/mrfiktiv/services/daService";
import {
  MrfiktivDaWebhookControllerGetParamsGen,
  MrfiktivDaWebhookViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";

export const DaWebhookPageDataProvider = new (class extends AbstractPageDataProvider<
  MrfiktivDaWebhookViewModelGen,
  MrfiktivDaWebhookControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(
    query: MrfiktivDaWebhookControllerGetParamsGen
  ): Promise<IPageViewModel<MrfiktivDaWebhookViewModelGen>> {
    const res = await daService.getDaWebhooks(query);

    const meta = res.meta;
    const data = res.data;

    return { meta, data };
  }
})();

export const DaWebhookDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<MrfiktivDaWebhookViewModelGen> {
  getIdentifier(entity: MrfiktivDaWebhookViewModelGen): string {
    return entity.id;
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "da-webhook",
  store
})
class DaWebhookStore extends PaginatedBaseStore<
  MrfiktivDaWebhookViewModelGen,
  MrfiktivDaWebhookControllerGetParamsGen
> {
  protected _data = DaWebhookDataAccessLayer;
  protected _pageProvider = DaWebhookPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);
}

export const DaWebhookModule = getModule(DaWebhookStore);
