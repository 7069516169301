import { BlueprintTypeEnum } from "@/lib/enum/BlueprintType.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { MrfiktivBaseImageGen, MrfiktivBlueprintElementViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { VehicleModule } from "@/store/modules/vehicle.store";

@IsFilterable
class BlueprintBase implements IEntity<IBlueprint>, MrfiktivBlueprintElementViewmodelGen {
  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.blueprint.id"
  })
  id: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.blueprint.name"
  })
  name: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.vehicle.blueprint.type",
    config: {
      items: Object.values(BlueprintTypeEnum).map(e => {
        return {
          text: `enums.BlueprintTypeEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  type: "inside" | "outside" | "other";

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.blueprint.description"
  })
  description?: string | undefined;

  /**
   * @inheritdoc
   */
  location!: string[];

  /**
   * @inheritdoc
   */
  image!: MrfiktivBaseImageGen;

  /**
   * @inheritdoc
   */
  partnerId: string;

  /**
   * @inheritdoc
   */
  vehicleId: string;

  /**
   * local file
   */
  private fileLocal!: File;

  /**
   * gets the local file to send to server
   */
  get file() {
    return this.fileLocal;
  }

  /**
   * sets the file and the imagesource
   */
  set file(file: File) {
    this.fileLocal = file;
    this.imageSrc = URL.createObjectURL(file);
  }

  /**
   * Image as object url to visualize in front end if its not uploaded
   */
  imageSrc!: string;

  loading = false;

  constructor(partnerId: string, vehicleId: string, data: Partial<MrfiktivBlueprintElementViewmodelGen>) {
    this.id = data.id ?? "";
    this.name = data.name ?? "";
    this.type = data.type ?? "other";
    if (data?.image) {
      this.image = data.image;
    }
    this.description = data.description;
    this.location = data.location || [];
    this.partnerId = partnerId;
    this.vehicleId = vehicleId;
  }

  async create(): Promise<IBlueprint> {
    if (!this.file) {
      throw new Error("No Image attached");
    }
    try {
      this.loading = true;
      const vehicle = await VehicleModule.createBlueprint({
        partnerId: this.partnerId,
        vehicleId: this.vehicleId,
        data: {
          name: this.name,
          type: this.type,
          description: this.description ?? "",
          location: this.location,
          content: this.file
        }
      });

      return new Blueprint(this.partnerId, this.vehicleId, vehicle.blueprints[vehicle.blueprints.length - 1]);
    } catch (error) {
      throw new Error("Error creating blueprint");
    } finally {
      this.loading = false;
    }
  }

  async delete() {
    await VehicleModule.deleteBlueprint({
      partnerId: this.partnerId,
      vehicleId: this.vehicleId,
      blueprintId: this.id
    });
  }
}

type IBlueprint = BlueprintBase;
const Blueprint = Filter.createForClass(BlueprintBase);

export { Blueprint, IBlueprint };
