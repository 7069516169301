




























































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";

@Component({
  components: {
    ConfirmActionDialog,
    ContextMenu
  },
  filters: { simpleDoubleDigitDate }
})
export default class HandoverDetailHandoverDateListItem extends mixins(Vue, PermissionMixin) {
  @Prop()
  value!: IHandover;

  isLoadingDate = false;

  isDeleteDialogActive = false;

  isUpdateDialogActive = false;

  plannedHandoverDate = "";

  get handoverDateString() {
    if (!this.value?.date) return "";

    const day = new Date(this.value.date);
    const date = day.getDate();
    const month = day.getMonth() + 1;
    const year = day.getFullYear();

    return `${date}.${month}.${year}`;
  }

  openEditDialog() {
    this.isUpdateDialogActive = true;
    this.plannedHandoverDate = this.value.date ? this.value.date.substring(0, 10) : "";
  }

  openDeleteDialog() {
    this.isDeleteDialogActive = true;
  }

  async setPlannedHandoverDate(dateString: string) {
    this.isLoadingDate = true;
    const date = new Date(dateString);
    await this.value
      .updatePartial({ date: date.toISOString() })
      .then(() => (this.isUpdateDialogActive = false))
      .catch(handleError)
      .then(() => (this.isLoadingDate = false));
  }

  async removePlannedHandoverDate() {
    this.isLoadingDate = true;
    await this.value
      .updatePartial({ date: null })
      .then(() => (this.isDeleteDialogActive = false))
      .catch(handleError)
      .then(() => (this.isLoadingDate = false));
  }
}
