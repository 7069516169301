import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import reportService from "@/services/mrfiktiv/services/reportService";
import {
  MrfiktivReportControllerFindAllParamsGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "./paginated-base.store";
import { PaginationFilterListElement } from "@/lib/utility/data/page-filter-list-element.interface";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

export class ExampleReportDataAccessLayer extends AbstractLocalDataAccessLayer<MrfiktivReportViewModelGen> {
  getIdentifier(entity: MrfiktivReportViewModelGen): string {
    return entity.id;
  }
}

export class ExampleReportPageDataProvider extends AbstractPageDataProvider<
  MrfiktivReportViewModelGen,
  MrfiktivReportControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivReportControllerFindAllParamsGen): Promise<IPageViewModel<MrfiktivReportViewModelGen>> {
    const res = await reportService.getReportPaginated(query);

    return res as IPageViewModel<MrfiktivReportViewModelGen>;
  }
}

/**
 * Example store for testing
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "exampleStore",
  store
})
export class ExampleStore extends PaginatedBaseStore<
  MrfiktivReportViewModelGen,
  MrfiktivReportControllerFindAllParamsGen
> {
  _data = new ExampleReportDataAccessLayer();
  _pageProvider = new ExampleReportPageDataProvider();

  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = [
    { key: "_id", type: PageFilterTypes.OBJECT_ID, displayName: "objects.report._id" }
  ].map(f => new PaginationFilterListElement(f));

  @Action
  async update(id: string) {
    await Promise.resolve("test");

    this._data.set({
      id: id,
      customerName: "LOCAL UPDATE IT WORKS IM SCREAMING 😱"
    } as any);
  }
}

export const ExampleModule = getModule(ExampleStore);
