
import { handleError } from "@/lib/utility/handleError";
import { MrfiktivLeasingContractGen, MrfiktivUpdateLeasingContractDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  filters: {}
})
export default class FleetContractCrudMixin extends Vue {
  isDeleteContractLoading = false;

  isUpdatingContractLoading = false;

  async deleteContract(partnerId: string, vehicleId: string, contractId: string) {
    try {
      this.isDeleteContractLoading = true;
      await VehicleModule.deleteContract({
        partnerId,
        vehicleId,
        contractId
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isDeleteContractLoading = false;
    }
  }

  async updateContract(partnerId: string, vehicleId: string, contractId: string, contract: MrfiktivLeasingContractGen) {
    try {
      this.isUpdatingContractLoading = true;
      const data: MrfiktivUpdateLeasingContractDtoGen = {
        number: contract.number?.toString(),
        title: contract.title,
        description: contract.description,
        currency: contract.currency ? contract.currency : undefined,
        startDate: contract.startDate ? contract.startDate : undefined,
        endDate: contract.endDate ? contract.endDate : undefined,
        startMilage: contract.startMilage || contract.startMilage === 0 ? Number(contract.startMilage) : undefined,
        maxMilage: contract.maxMilage || contract.maxMilage === 0 ? Number(contract.maxMilage) : undefined,
        freeMileageAllowance:
          contract.freeMileageAllowance || contract.freeMileageAllowance === 0
            ? Number(contract.freeMileageAllowance)
            : undefined,
        maxReimbursedMilage:
          contract.maxReimbursedMilage || contract.maxReimbursedMilage === 0
            ? Number(contract.maxReimbursedMilage)
            : undefined,
        purchasePrice:
          contract.purchasePrice || contract.purchasePrice === 0 ? Number(contract.purchasePrice) : undefined,
        buybackPrice: contract.buybackPrice || contract.buybackPrice === 0 ? Number(contract.buybackPrice) : undefined,
        creditForReimbursedMilage:
          contract.creditForReimbursedMilage || contract.creditForReimbursedMilage === 0
            ? Number(contract.creditForReimbursedMilage)
            : undefined,
        excessMileageCosts:
          contract.excessMileageCosts || contract.excessMileageCosts === 0
            ? Number(contract.excessMileageCosts)
            : undefined,
        insuranceCost:
          contract.insuranceCost || contract.insuranceCost === 0 ? Number(contract.insuranceCost) : undefined,
        financingCost:
          contract.financingCost || contract.financingCost === 0 ? Number(contract.financingCost) : undefined,
        fuelCost: contract.fuelCost || contract.fuelCost === 0 ? Number(contract.fuelCost) : undefined,
        tiresCost: contract.tiresCost || contract.tiresCost === 0 ? Number(contract.tiresCost) : undefined,
        miscCost: contract.miscCost || contract.miscCost === 0 ? Number(contract.miscCost) : undefined,
        refs: contract.refs || []
      };

      await VehicleModule.updateContract({
        partnerId,
        vehicleId,
        contractId,
        data
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.isUpdatingContractLoading = false;
    }
  }
}
