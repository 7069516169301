var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table-wrapper', {
    attrs: {
      "controlElements": _vm.controlElements,
      "headers": _vm.headers,
      "allItems": _vm.all,
      "loading": _vm.isLoading,
      "sortBy": "start",
      "sortDesc": false
    },
    on: {
      "click:row": function clickRow($event) {
        _vm.selectedEvent = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "elevation": "0",
            "color": "info"
          },
          on: {
            "click": _vm.goToFleetImport
          }
        }, [_vm._v("Import")])];
      },
      proxy: true
    }, {
      key: "item.start",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("detailedDateWithDay")(item.start)) + " ")];
      }
    }, {
      key: "item.vehicleId",
      fn: function fn(_ref2) {
        var _vm$getVehicle$number, _vm$getVehicle;

        var item = _ref2.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'VehicleDetail',
              params: {
                partnerId: item.partnerId,
                vehicleId: item.vehicleId
              }
            }
          }
        }, [_vm._v(" " + _vm._s((_vm$getVehicle$number = (_vm$getVehicle = _vm.getVehicle(item.vehicleId)) === null || _vm$getVehicle === void 0 ? void 0 : _vm$getVehicle.numberplate) !== null && _vm$getVehicle$number !== void 0 ? _vm$getVehicle$number : item.vehicleId) + " ")])];
      }
    }])
  }), _vm.selectedEvent ? _c('side-card', {
    attrs: {
      "title": _vm.i18n['appointment'],
      "width": "600"
    },
    on: {
      "close": _vm.clearSelectedEvent
    }
  }, [_c('v-row', [_c('fleet-vehicle-menu-card', {
    staticStyle: {
      "min-width": "600px"
    },
    attrs: {
      "selected": _vm.getVehicle(_vm.selectedEvent.vehicleId),
      "showActions": false
    }
  })], 1), _c('v-row', [_c('fleet-event-menu-card', {
    staticStyle: {
      "min-width": "600px"
    },
    attrs: {
      "selectedEvent": _vm.selectedEvent,
      "showActions": false
    }
  })], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }