













































































import { Component, Prop } from "vue-property-decorator";
import PartnerManageStepperMixin from "@/mixins/PartnerManageStepperMixin.vue";

@Component({
  components: {}
})
export default class PartnerManageStepperStepLayout extends PartnerManageStepperMixin {
  @Prop()
  title!: string;

  @Prop()
  leftButtonLabel?: string;
  @Prop({ default: "mdi-arrow-left" })
  leftButtonIcon?: string;
  @Prop()
  leftButtonFunction?: Function;

  @Prop()
  centerButtonLabel?: string;
  @Prop({ default: "mdi-refresh" })
  centerButtonIcon?: string;
  @Prop()
  centerButtonFunction?: Function;
  @Prop()
  centerButtonDisabled?: boolean;

  @Prop()
  rightButtonLabel?: string;
  @Prop({ default: "mdi-arrow-right" })
  rightButtonIcon?: string;
  @Prop()
  rightButtonFunction?: Function;
  @Prop()
  rightButtonDisabled?: boolean;

  get rightFunction() {
    return this.isEndStep ? this.end : this.rightButtonFunction;
  }

  get leftFunction() {
    return this.currentStep === 1 ? this.end : this.leftButtonFunction;
  }

  get isEndStep() {
    return this.currentStep === this.totalSteps;
  }

  get isProgressShown() {
    return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
  }
}
