




































import { SettingModule } from "@/store/modules/setting.store";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class PartnerManageDeleteSetting extends Vue {
  /**
   * value of input in delete dialog
   * must match key before setting can be deleted
   */
  deleteInput = "";

  /**
   * get partner setting from store
   */
  get partnerSetting() {
    return SettingModule.partnerSetting;
  }

  /**
   * is delete dialog open
   */
  deleteDialog = false;

  /**
   * Show delete dialog
   */
  showDeleteDialog() {
    this.deleteDialog = true;
  }

  /**
   * Hide delete dialog
   */
  hideDeleteDialog() {
    this.deleteDialog = false;
  }

  /**
   * optional, exectuted when setting is deleted
   */
  @Prop()
  onDelete?: Function;

  /**
   * Delete partner setting
   * close dialog
   */
  async submitDeleteDialog() {
    this.hideDeleteDialog();
    this.deleteInput = "";
    await SettingModule.deleteSetting(this.partnerSetting.id);
    if (this.onDelete) {
      await this.onDelete();
    }
  }
}
