var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.dialogModel,
      "absolute": true,
      "title": _vm.$t('anonymization.create.title'),
      "rightDisabled": _vm.loading || _vm.noImages,
      "leftDisabled": _vm.loading,
      "rightText": _vm.$t('anonymization.create.action')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialogModel = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialogModel = $event;
      },
      "close": _vm.close,
      "leftClick": _vm.close,
      "rightClick": _vm.submitAnonymization
    }
  }, [_c('drop-area-multi', {
    staticClass: "my-2",
    attrs: {
      "files": _vm.files,
      "loading": _vm.loading,
      "loadingProgress": _vm.loadingProgress,
      "text": _vm.$t('anonymization.create.dragOver'),
      "dragoverText": _vm.$t('anonymization.create.dragOver')
    },
    on: {
      "droped": _vm.createDto,
      "update:files": function updateFiles($event) {
        _vm.files = $event;
      }
    }
  }), _c('v-expansion-panels', {
    staticClass: "my-4"
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(_vm._s(_vm.$t("anonymization.create.settings")))]), _c('v-expansion-panel-content', [_c('span', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.$t("anonymization.create.qI")))]), _c('v-checkbox', {
    attrs: {
      "dense": "",
      "label": _vm.$t('anonymization.face')
    },
    model: {
      value: _vm.anonymization.config.face,
      callback: function callback($$v) {
        _vm.$set(_vm.anonymization.config, "face", $$v);
      },
      expression: "anonymization.config.face"
    }
  }), _c('v-checkbox', {
    attrs: {
      "dense": "",
      "label": _vm.$t('anonymization.numberplate')
    },
    model: {
      value: _vm.anonymization.config.numberplate,
      callback: function callback($$v) {
        _vm.$set(_vm.anonymization.config, "numberplate", $$v);
      },
      expression: "anonymization.config.numberplate"
    }
  }), _c('span', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.$t("anonymization.create.qII")))]), _c('v-checkbox', {
    attrs: {
      "dense": "",
      "label": _vm.$t('anonymization.original')
    },
    model: {
      value: _vm.anonymization.config.deleteOriginalImage,
      callback: function callback($$v) {
        _vm.$set(_vm.anonymization.config, "deleteOriginalImage", $$v);
      },
      expression: "anonymization.config.deleteOriginalImage"
    }
  }), _c('v-checkbox', {
    attrs: {
      "dense": "",
      "label": _vm.$t('anonymization.anonymized')
    },
    model: {
      value: _vm.anonymization.config.deleteAnonymizedImage,
      callback: function callback($$v) {
        _vm.$set(_vm.anonymization.config, "deleteAnonymizedImage", $$v);
      },
      expression: "anonymization.config.deleteAnonymizedImage"
    }
  }), _c('span', {
    staticClass: "caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("anonymization.create.captionII")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }