














































































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ICostGroup } from "@/models/cost-group.entity";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import CostGroupCreateDialog from "./CostGroupCreateDialog.vue";
import CostGroupSideCard from "./CostGroupSideCard.vue";
import { CostGroupModule } from "@/store/modules/cost-group.store";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import RefsCostGroup from "@/components/utility/RefsCostGroup.vue";
import { handleError } from "@/lib/utility/handleError";
import AssigneePreview from "@/components/utility/AssigneePreview.vue";

@Component({
  components: {
    PaginatedTable,
    ConfirmActionDialog,
    CostGroupCreateDialog,
    CostGroupSideCard,
    RefsCostGroup,
    AssigneePreview
  }
})
export default class CostGroupTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = CostGroupModule;

  @Prop()
  partnerId!: string;

  selectedItem: ICostGroup | null = null;

  selectedItemForDelete: ICostGroup | null = null;

  isDeleteDialogActive = false;

  isDeleteLoading = false;

  get headers(): ITableWrapperHeader[] {
    if (!CostGroupModule.entities.length) return [];

    return [
      {
        text: $t("project.project.created"),
        value: "timestamp.created",
        width: "110"
      },
      {
        text: $t("objects.costGroup.title"),
        value: "title",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-open-in-new",
        text: this.$t("sign.DocumentTable.open").toString(),
        action: this.goToCostGroupCustomView
      },
      {
        icon: "mdi-pencil",
        text: $t("edit"),
        action: this.setSelectedItem
      },
      {
        icon: "mdi-trash-can",
        text: $t("delete"),
        action: this.onDeleteItem
      }
    ];

    return controlElements;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [];

    return predefinedFilter;
  }

  goToCostGroupCustomView(item: ICostGroup) {
    new CostGoToHelper(this.$router).goToCostGroupCustomView({
      partnerId: this.partnerId,
      costGroupId: item.id,
      viewId: "0"
    });
  }

  setSelectedItem(item: ICostGroup | null) {
    this.selectedItem = null;

    this.$nextTick(() => {
      this.selectedItem = item;
      this.$emit("openSideCard", item);
    });
  }

  onDeleteItem(item: ICostGroup) {
    this.selectedItemForDelete = item;
    this.isDeleteDialogActive = true;
  }

  async onDelete() {
    this.isDeleteLoading = true;

    await this.selectedItemForDelete
      ?.delete()
      .then(() => this.$toast.success("👍"))
      .catch(handleError);

    this.isDeleteLoading = false;
    this.isDeleteDialogActive = false;
  }

  goToCustomFields() {
    new GoToHelper(this.$router).goToCustomFieldList(this.partnerId);
  }
}
