import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ICredential, Credential } from "@/models/credential.entity";

export const CredentialDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ICredential> {
  protected mapEntity(entity: ICredential): ICredential {
    return new Credential(entity);
  }

  getIdentifier(entity: ICredential): string {
    return entity.id;
  }

  mapsConfig = {};
})();
