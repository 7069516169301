























































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { HandoverStateEnum } from "@/lib/enum/handover-state.enum";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover } from "@/models/handover.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { handoverStateMap } from "@/store/mapper/handover-state.map";
import { HandoverModule } from "@/store/modules/handover.store";
import { PartnerModule } from "@/store/modules/partner";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import RefsList from "../utility/RefsList.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import CreateHandoverDialog from "./CreateHandoverDialog.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import AssigneePreview from "../utility/AssigneePreview.vue";
import { UserModule } from "@/store/modules/me-user.store";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";

@Component({
  components: {
    PaginatedTable,
    CreateHandoverDialog,
    RefsVehicle,
    RefsList,
    AssigneePreview
  },
  filters: {
    simpleDate
  }
})
export default class HandoverTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop({})
  vehicle?: IVehicle;

  @Prop({ default: false })
  newTab!: boolean;

  @Prop({ default: false })
  newTabVehicle!: boolean;

  readonly store = HandoverModule;

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get userId() {
    return UserModule.userId;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];

    headers.push({
      text: $t("objects.handover.number"),
      value: "number",
      width: "100px"
    });

    headers.push({
      text: $t("objects.handover.state"),
      value: "state",
      width: "100px"
    });

    headers.push({
      text: $t("project.ticket.assignees"),
      value: "assignees",
      width: "150px"
    });

    headers.push({
      text: $t("common.nouns.date"),
      value: "date",
      width: "100px"
    });

    headers.push({
      text: $t("objects.handover.title"),
      value: "title",
      sortable: false
    });

    if (!this.vehicle) {
      headers.push({
        text: $t("objects.handover.vehicleIds"),
        value: "vehicleIds",
        sortable: false
      });
    }

    headers.push({
      text: $t("objects.inspection.timestamp.created"),
      value: "timestamp.created"
    });

    return headers;
  }

  get predefinedFilter(): { name: string; filter: IPageFilterElement[] }[] {
    const predefinedFilter: { name: string; filter: IPageFilterElement[] }[] = [];

    predefinedFilter.push({
      name: "components.FilterCardPagination.predefinedFilter.ticket.open",
      filter: [
        new PageFilterElement({
          key: "state",
          value: HandoverStateEnum.OPEN,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    predefinedFilter.push({
      name: "project.ticket.mine",
      filter: [
        new PageFilterElement({
          key: "assignees",
          value: this.userId,
          operation: PageFilterOperationEnum.EQUAL
        })
      ]
    });

    return predefinedFilter;
  }

  color(status: HandoverStateEnum) {
    return handoverStateMap.get(status)?.color || "blue-grey lighten-4";
  }

  beforeMount() {
    if (this.vehicle) {
      this.store.setFilters([
        new PageFilterElement({
          key: "vehicleIds",
          value: this.vehicle.id
        })
      ]);
    }
  }

  setSelectedItem(item: IHandover) {
    new GoToHelper(this.$router).goToHandoverDetail(item.id, item.partnerId, this.newTab);
  }

  async getVehicle(vehicleId: string) {
    let vehicle = VehicleModule.maps.id.get(vehicleId)[0];
    if (vehicle) return vehicle;
    vehicle = await new Vehicle({ id: vehicleId, partnerId: this.partnerId }).fetch();

    return vehicle;
  }

  goToVehicle(value: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(value.id, value.partnerId, VehicleTabs.HOME, this.newTabVehicle);
  }
}
