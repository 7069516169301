































































































































































































































































































































































































































































































































import LatestReportEntriesCard from "@/components/cards/LatestReportEntriesCard.vue";
import CreateVehicleDialog from "@/components/fleet/CreateVehicleDialog.vue";
import EventOverdueTable from "@/components/fleet/EventOverdueTable.vue";
import FleetDriverTable from "@/components/fleet/FleetDriverTable.vue";
import FleetEventMenuCard from "@/components/fleet/FleetEventMenuCard.vue";
import FleetEventTable from "@/components/fleet/FleetEventTable.vue";
import FleetHomeCalendar from "@/components/fleet/FleetHomeCalendar.vue";
import FleetHomeStatisticsList from "@/components/fleet/FleetHomeStatisticsList.vue";
import FleetHomeVehicleDistribution from "@/components/fleet/FleetHomeVehicleDistribution.vue";
import FleetHomeVehicleMostUsedCars from "@/components/fleet/FleetHomeVehicleMostUsedCars.vue";
import FleetHomeVehicleCostAnalytics from "@/components/fleet/FleetHomeVehicleCostAnalytics.vue";
import FleetHomeVehicleTable from "@/components/fleet/FleetHomeVehicleTable.vue";
import FleetHomeVehicleTableTicketSideCard from "@/components/fleet/FleetHomeVehicleTableTicketSideCard.vue";
import FleetPoolTable from "@/components/fleet/FleetPoolTable.vue";
import FleetTicketTable from "@/components/fleet/FleetTicketTable.vue";
import FleetCostTable from "@/components/fleet/FleetCostTable.vue";
import FleetVehicleEventCrudMixin from "@/components/fleet/FleetVehicleEventCrudMixin.vue";
import FleetVehicleImportDialog from "@/components/fleet/FleetVehicleImportDialog.vue";
import FleetVehicleTable from "@/components/fleet/FleetVehicleTable.vue";
import TicketOverdueTable from "@/components/fleet/TicketOverdueTable.vue";
import PartnerReportDetailSideCard from "@/components/partner/PartnerReportDetailSideCard.vue";
import RefsEvent from "@/components/utility/RefsEvent.vue";
import RefsTicket from "@/components/utility/RefsTicket.vue";
import SideCard from "@/components/utility/SideCard.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { FleetTabs } from "@/lib/enum/fleet-tabs.enum";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { BaseGoToHelper, GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IReport } from "@/models/report.entity";
import { ITicket } from "@/models/ticket.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { DriverModule } from "@/store/modules/driver.store";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { EventListModule } from "@/store/modules/list-event.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { PoolModule } from "@/store/modules/pool.store";
import { ProjectModule } from "@/store/modules/project.store";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { TicketModule } from "@/store/modules/ticket.store";
import { VehicleModule } from "@/store/modules/vehicle.store";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import ProjectGoToMixin from "../project/mixins/ProjectGoToMixin.vue";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { CostModule } from "@/store/modules/cost.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import HandoverTable from "@/components/handover/HandoverTable.vue";

export enum FleetActions {
  CREATE_VEHICLE = "CREATE_VEHICLE",
  IMPORT_VEHICLE = "IMPORT_VEHICLE"
}

export enum ListTabs {
  OVERDUE = "overdue",
  UPCOMING = "upcoming"
}

@Component({
  components: {
    TheLayoutPortal,
    MHeader,
    FleetVehicleImportDialog,
    FleetHomeVehicleDistribution,
    FleetHomeStatisticsList,
    FleetHomeVehicleMostUsedCars,
    FleetHomeVehicleCostAnalytics,
    FleetHomeCalendar,
    FleetVehicleTable,
    FleetDriverTable,
    FleetPoolTable,
    FleetEventTable,
    FleetTicketTable,
    FleetCostTable,
    FleetHomeVehicleTable,
    FleetHomeVehicleTableTicketSideCard,
    PartnerReportDetailSideCard,
    EventOverdueTable,
    TicketOverdueTable,
    LatestReportEntriesCard,
    FleetEventMenuCard,
    SideCard,
    CreateVehicleDialog,
    RefsEvent,
    RefsTicket,
    EventSideCard: () => import("@/components/event/EventSideCard.vue"),
    ConfirmActionDialog,
    HandoverTable
  }
})
export default class FleetHomeView extends mixins(
  PartnerFallbackMixin,
  PermissionMixin,
  ProjectGoToMixin,
  FleetVehicleEventCrudMixin
) {
  loading = false;
  isLoadingTickets = false;
  isLoadingReports = false;
  isLoadingCosts = false;

  isOnboardingDialogActive = false;

  tab = 0;
  ticketTab: ListTabs = ListTabs.OVERDUE;
  eventTab: ListTabs = ListTabs.OVERDUE;

  isPromisingEvents = false;

  calendarDate = new Date();
  debounceGetEventsPromiseFrom = debounce((curr?: Date) => this.getEventsPromiseFrom(curr), 200);

  focusedTicket: ITicket | null = null;
  focusedEvent: IEventUIDto | null = null;
  focusedReport: IReport | null = null;
  isReportSideCard = false;
  isReportSideCardLoading = false;

  isCreateVehicleDialogActive = false;

  readonly FleetActions = FleetActions;
  readonly FleetTabs = FleetTabs;

  /**
   * Feature flags
   */
  showReports = false;

  get calendarDatePreviousMonth() {
    const date = new Date(this.calendarDate);
    date.setMonth(date.getMonth() - 1);

    const month = $t("time.months." + date.getMonth());
    const year = date.getFullYear();

    return `${month} ${year}`;
  }

  get calendarDateNextMonth() {
    const date = new Date(this.calendarDate);
    date.setMonth(date.getMonth() + 1);

    const month = $t("time.months." + date.getMonth());
    const year = date.getFullYear();

    return `${month} ${year}`;
  }

  get vehicles() {
    return VehicleModule.vehicles;
  }

  get reports() {
    return ReportPaginationModule.paginationList;
  }

  get tickets() {
    return TicketModule.paginationList;
  }

  get events() {
    return EventListModule.events;
  }

  get inactiveVehicles() {
    return this.vehicles.filter(v => v.state === "inactive");
  }

  get openReports() {
    return this.reports.filter(r => r.progressStatus === ProgressStatusEnum.NEW);
  }

  get overdueEvents(): IEventUIDto[] {
    return EventListModule.overdueEvents;
  }

  get upcomingEventLimit() {
    return simpleDoubleDigitDate(EventListModule.dateRange[1]);
  }

  get upcomingEvents(): IEventUIDto[] {
    return this.events.filter(e => e.start > new Date().getTime());
  }

  get overdueEventsPreview() {
    return this.overdueEvents.slice(0, 5);
  }

  get upcomingEventsPreview() {
    return this.upcomingEvents.slice(0, 5);
  }

  get overdueTickets(): ITicket[] {
    return TicketModule.tickets
      .filter(t => {
        return t.isOverdue;
      })
      .sort((a, b) => {
        if (a.due && b.due) {
          return new Date(a.due).getTime() - new Date(b.due).getTime();
        }
        return 0;
      });
  }

  get overdueTicketsPreview() {
    return this.overdueTickets.slice(0, 5);
  }

  get upcomingTickets(): ITicket[] {
    return TicketModule.tickets
      .filter(t => {
        return t.isDue;
      })
      .filter(t => {
        const dueDateTime = t.dueDateTime;
        if (dueDateTime) {
          return dueDateTime >= new Date().getTime();
        }

        return false;
      })
      .sort((a, b) => {
        if (a.due && b.due) {
          return new Date(a.due).getTime() - new Date(b.due).getTime();
        }

        return 0;
      });
  }

  get upcomingTicketsPreview() {
    return this.upcomingTickets.slice(0, 5);
  }

  get minHeight() {
    if (this.isMobile) {
      return 234;
    } else {
      return 350;
    }
  }

  get chips(): IAction[] {
    let icon = "mdi-car";
    if (PartnerModule.isTrain) {
      icon = "mdi-train";
    }

    return [
      {
        text: `${this.vehicles.length} ${this.$t("common.nouns.vehicles")}`,
        key: "fahrzeuge",
        icon: icon
      }
    ];
  }

  get crumbs() {
    return [
      {
        text: this.$t("common.nouns.vehicles"),
        exact: true,
        disabled: true,
        to: {
          name: "FleetHome",
          params: {
            partnerId: this.partnerId
          }
        }
      }
    ];
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.VEHICLE, PartnerModule.partner._id)) {
      actions.push({
        icon: "mdi-car-outline",
        text: this.$t("views.fleet.FleetHomeVehicleTable.addVehicle").toString(),
        key: FleetActions.CREATE_VEHICLE,
        color: ""
      });

      actions.push({
        icon: "mdi-car-2-plus",
        text: this.$t("views.fleet.FleetHomeVehicleTable.import").toString(),
        key: FleetActions.IMPORT_VEHICLE,
        color: ""
      });
    }

    return actions;
  }

  get tabs() {
    const items = [];

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, PartnerModule.partner.id))
      items.push(FleetTabs.HOME);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.EVENT, PartnerModule.partner.id))
      items.push(FleetTabs.ITINERARY);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, PartnerModule.partner.id))
      items.push(FleetTabs.VEHICLE);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.HANDOVER, PartnerModule.partner.id))
      items.push(FleetTabs.HANDOVER);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.EVENT, PartnerModule.partner.id))
      items.push(FleetTabs.EVENT);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TICKET, PartnerModule.partner.id))
      items.push(FleetTabs.TICKET);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.COST, PartnerModule.partner.id))
      items.push(FleetTabs.COST);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DRIVER, PartnerModule.partner.id))
      items.push(FleetTabs.DRIVER);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.POOL, PartnerModule.partner.id))
      items.push(FleetTabs.POOL);

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, PartnerModule.partner.id))
      items.push(FleetTabs.ANALYTICS);

    return items;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get i18n() {
    return this.$t("views.fleet.FleetHomeView");
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  async mounted() {
    const tab = this.$route.params.tab;
    this.setTab(tab as FleetTabs);

    const partnerId = this.partnerId;

    try {
      this.loading = true;

      await this.trySetByRouteOrDefault();

      FleetAggregationModule.vehicleAggregationMap.clear();

      const promises: Promise<any>[] = [];

      VehicleModule.setFilter([]);
      VehicleModule.setHiddenFilter([]);
      promises.push(VehicleModule.getAll({ partnerId }));

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.EVENT, partnerId)) {
        this.isLoadingEvents = true;

        this.$log.warn("FleetHomeView", "getEventsPromiseFrom");
        const date = new Date();
        promises.push(this.getEventsPromiseFrom(date));

        promises.push(
          EventListModule.listOverdue({
            partnerId: PartnerModule.partner._id
          }).finally(() => (this.isLoadingEvents = false))
        );
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TICKET, partnerId)) {
        this.isLoadingTickets = true;

        TicketModule.setFilter([
          new PageFilterElement({ key: "state", value: TicketStatusEnum.OPEN, operation: "$eq" })
        ]);
        TicketModule.setHiddenFilter([]);
        promises.push(TicketModule.fetchAll({ partnerId }).finally(() => (this.isLoadingTickets = false)));
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, partnerId)) {
        this.isLoadingReports = true;

        ReportPaginationModule.setFilters([
          new PageFilterElement({
            key: "progressStatus",
            operation: PageFilterOperationEnum.NOT_EQUAL,
            value: ProgressStatusEnum.FINISHED
          })
        ]);
        promises.push(
          ReportPaginationModule.fetchAll({
            partnerId
          }).finally(() => (this.isLoadingReports = false))
        );
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.DRIVER, partnerId)) {
        DriverModule.setFilter([]);
        DriverModule.setHiddenFilter([]);
        promises.push(DriverModule.getAll(partnerId));
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.POOL, partnerId)) {
        PoolModule.setFilter([]);
        PoolModule.setHiddenFilter([]);
        promises.push(PoolModule.getAll({ partnerId }));
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.COST, partnerId)) {
        this.isLoadingCosts = true;
        CostModule.setFilters([
          new PageFilterElement({
            key: "refs.refType",
            operation: PageFilterOperationEnum.EQUAL,
            value: BackendResourceEnum.VEHICLE
          })
        ]);
        CostModule.setHiddenFilters([]);
        promises.push(
          CostModule.fetchAll({
            partnerId: partnerId
          }).finally(() => (this.isLoadingCosts = false))
        );
      }

      if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.PROJECT, partnerId)) {
        ProjectModule.setFilter([]);
        ProjectModule.setHiddenFilter([]);
        ProjectModule.fetchFirstPage({ partnerId });
      }
      await Promise.all(promises);

      if (!VehicleModule.vehicles.length) {
        this.isOnboardingDialogActive = true;
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }

  get costsTotal() {
    const currentYear = new Date().getFullYear();
    const costs = CostModule.entities;
    let total = 0;
    for (const cost of costs) {
      const isVehicleCost = cost.refs.some(ref => ref.refType === BackendResourceEnum.VEHICLE);
      const costYear = new Date(cost.date).getFullYear();
      const isCurrentYear = costYear >= currentYear && costYear <= currentYear + 1;
      if (isVehicleCost && isCurrentYear) total += cost.total;
    }
    return total.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  }

  goToCosts() {
    this.setTab(FleetTabs.COST);
  }

  async processAction(action: IAction) {
    switch (action.key) {
      case FleetActions.CREATE_VEHICLE:
        this.isCreateVehicleDialogActive = true;
        break;
      case FleetActions.IMPORT_VEHICLE:
        await this.goToFleetImport();
        break;
    }
  }

  /**
   * Get all events from beginning of previous month to next
   * @param start the start date to get events from
   */
  private async getEventsPromiseFrom(curr: Date = new Date()) {
    this.calendarDate = curr;
    if (!UserModule.abilities.can(ActionEnum.READ, ResourceEnum.EVENT, this.partnerId)) return;

    curr = new Date(curr.getFullYear(), curr.getMonth());
    const beginingOfPreviousMonth = new Date(curr);
    beginingOfPreviousMonth.setMonth(curr.getMonth() - 1);
    const endOfNextMonth = new Date(curr);
    endOfNextMonth.setMonth(endOfNextMonth.getMonth() + 2);
    this.isPromisingEvents = true;
    return EventListModule.listAll({
      partnerId: this.partnerId,
      from: beginingOfPreviousMonth.getTime(),
      to: endOfNextMonth.getTime()
    })
      .catch(handleError)
      .finally(() => (this.isPromisingEvents = false));
  }

  getTabId(fleetTab: FleetTabs) {
    return this.tabs.indexOf(fleetTab);
  }

  setTab(tab?: FleetTabs) {
    this.tab = 0;
    if (tab) {
      const index = this.tabs.indexOf(tab as FleetTabs);

      if (index > -1) {
        this.tab = index;
      }
    }
  }

  @Watch("tab")
  syncRoute() {
    const location = this.$router.resolve({
      name: "FleetHomeTab",
      params: {
        partnerId: this.$route.params.partnerId,
        tab: this.tabs[this.tab] ?? ""
      }
    });
    if (location) {
      new BaseGoToHelper(this.$router).setUrl(location.location);
    }
  }

  user(userId: string) {
    return PartnerUserModule.maps.id.get(userId)[0];
  }

  userName(userId: string) {
    const user = this.user(userId);
    if (!user) {
      return this.$t("timeLine.ActivityTimeLineItemDocument.unkown");
    }

    return `${user.firstName} ${user.lastName}`;
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  goToFleetImport() {
    this.$router.push({ name: "FleetImportView" });
  }

  openVehicleSideCard(vehicle: IVehicle) {
    new GoToHelper(this.$router).goToVehicleDetail(vehicle.id, vehicle.partnerId, undefined, true);
  }

  openTicketSideCard(ticket: ITicket) {
    this.focusedTicket = null;
    this.$nextTick(() => {
      this.focusedTicket = ticket;
    });
  }

  async openEventSideCard(event: IEventUIDto) {
    this.focusedEvent = null;
    await event.createVirtual();
    this.$nextTick(() => {
      this.focusedEvent = event;
    });
  }

  async openReportSideCard(report: MrfiktivReportViewModelGen) {
    try {
      this.isReportSideCardLoading = false;
      this.isReportSideCard = true;

      const found = await PartnerModule.getReportByIdForPartner(report.id);

      this.focusedReport = found;
    } catch (e) {
      handleError(e);
    } finally {
      this.isReportSideCardLoading = false;
    }
  }

  async openContractDetail(contract: MrfiktivReportViewModelGen) {
    const partnerId = this.$route.params.partnerId;
    const vehicleId = contract.vehicleId;
    if (!partnerId) {
      this.$log.error("no partnerId");
      return;
    }
    if (!vehicleId) {
      this.$log.error("no vehicleId");
      return;
    }
    this.$router.push({
      name: "FleetContractDetailView",
      params: {
        partnerId: partnerId,
        vehicleId: vehicleId,
        contractId: contract.id
      }
    });
  }

  async onAckEvent() {
    this.isLoadingEvents = true;
    EventListModule.reset();

    try {
      await EventListModule.listOverdue({
        partnerId: PartnerModule.partner._id
      });

      this.$toast.success("👍");
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingEvents = false;
    }
  }

  async completeTicket(selected: ITicket[]) {
    this.$log.debug(selected);
    this.isLoadingTickets = true;

    try {
      for (const ticket of selected) {
        this.$log.debug(ticket);

        await ticket.updateState(TicketStatusEnum.CLOSED);
      }

      if (selected.length <= 1) {
        this.$toast.success(this.$t("project.ticket.updated", { number: selected[0].number }).toString(), {
          onClick: () => {
            this.goTo.ticketDetailSideCard(selected[0]);
          }
        });
      } else {
        this.$toast.success("👍");
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingTickets = false;
    }
  }

  async goToEventEdit(event: IEventUIDto) {
    await event.createVirtual();

    if (!event.id) {
      this.$log.error("event id missing");
      return;
    }
    await new GoToHelper(this.$router).goToEventDetailEdit(this.partnerId, event.id, false);
  }

  async goToEventDetail(event: IEventUIDto) {
    await event.createVirtual();

    if (!event.id) {
      this.$log.error("event id missing");
      return;
    }

    await new GoToHelper(this.$router).goToEventDetail(this.partnerId, event.id, true);
  }

  async onDeleteEvent(event: IEventUIDto) {
    if (!event.vehicleId) {
      this.$log.error("No vehicleId");
      return;
    }

    await this.deleteEvent(event, event.vehicleId, this.calendarDate);

    this.focusedEvent = null;

    this.debounceGetEventsPromiseFrom(this.calendarDate);
  }

  async onUpdateEvent(event: IEventUIDto, close?: Function) {
    if (!event.vehicleId) {
      this.$log.error("No vehicleId");
      return;
    }

    await this.updateEvent(event, event.vehicleId, this.calendarDate);

    if (close) close();
    this.debounceGetEventsPromiseFrom(this.calendarDate);
  }
}
