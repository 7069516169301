import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import CredentialTableView from "@/views/credential/CredentialTableView.vue";

/**
 * Routes for credentials
 */
export const credentialRoutes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/credential/",
    component: CredentialTableView,
    name: "CredentialTableView",
    permission: {
      resource: ResourceEnum.CREDENTIAL,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-api",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 95
    }
  }
];
