var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('operation-table', {
    attrs: {
      "baseQuery": {
        partnerId: _vm.partnerId
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }