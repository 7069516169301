































































































import CreateHandoverFindingDialog from "@/components/handover/CreateHandoverFindingDialog.vue";
import TourHandoverFindingDialog from "@/components/handover/TourHandoverFindingDialog.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailTable, {
  DetailTableTypeEnum,
  DetailTableVisualizationEnum,
  IMDetailTableConfig
} from "@/components/utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IProperty } from "@/components/utility/mmmint/MHeader.vue";
import MImageMarker, { IMarker } from "@/components/utility/mmmint/MImageMarker.vue";
import MNavigationList from "@/components/utility/mmmint/MNavigationList.vue";
import MQuickLinkList from "@/components/utility/mmmint/MQuickLinkList.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    MHeader: MHeader,
    MActionList,
    MDetailViewGrid,
    MDetailTable,
    MImageMarker,
    MNavigationList,
    MQuickLinkList,
    CreateHandoverFindingDialog,
    TourHandoverFindingDialog
  }
})
export default class DesignGuidelinesPortalMobileOptimizedExample extends PartnerFallbackMixin {
  foundPartner = false;
  loading = false;
  activeTab = 0;
  activated = false;

  title = "<Titel der Übergabe>";
  subtitle = "Lorem ipsum";

  createDialog = false;
  tourDialog = false;

  myAlerts = [];

  imageMarkerSrc = "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg";

  // Image options for selection
  imageOptions = [
    {
      imageId: 1,
      text: "Image 1",
      src: "https://static.mmmint.ai/public-partner/2024-08-01-header-3a93add1cd77a4980762124d.jpg"
    },
    {
      imageId: 2,
      text: "Image 2",
      src: "https://www.schadensmeldung.digital/images/features/schadenmanagement/individueller-funnel.webp"
    }
  ];

  get detailTableConfig(): IMDetailTableConfig[] {
    return [
      {
        key: "status",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP,
        displayConfig: [
          { value: "new", displayValue: "Offen", color: "warning" },
          { value: "closed", displayValue: "Abgeschlossen", color: "success" }
        ]
      },
      {
        key: "dates.today",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.DATE
      },
      {
        key: "type",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.CHIP
      }
    ];
  }

  markers: IMarker[] = [
    { top: 0.1, left: 0.2, color: "#ff0000", text: "1" }, // Red marker
    { top: 0.5, left: 0.5, color: "#00ff00", text: "2" }, // Green marker
    { top: 0.8, left: 0.3 } // Default color marker
  ];

  markerSize = "medium";

  get tableShowMoreAction() {
    return {
      text: $t("designGuide.MDetailForm.allProps"),
      key: "showDetails"
    };
  }

  addMarker(marker: IMarker) {
    marker.text = (this.markers.length + 1).toString();
    this.markers.push(marker);
  }

  get properties(): IProperty[] {
    return [
      {
        text: "Anzeigename",
        value: "TODO BUILD THIS as Cmponent",
        key: "name"
      },
      {
        text: "Kennzeichen",
        value: "TO DO IMPLEMENT SLOT",
        key: "numberplate"
      },
      {
        text: "Fahrgestellnummer",
        value: "1234",
        key: "OS-Peter"
      },
      {
        text: "Fahrgestellnummer",
        value: "1234",
        key: "OS-Peter"
      },
      {
        text: "Fahrgestellnummer",
        value: "1234",
        key: "OS-Peter"
      },
      {
        text: "Fahrgestellnummer",
        value: "1234",
        key: "OS-Peter"
      },
      {
        text: "Customfield 1",
        value: "Hello",
        key: "OS-Peter"
      },
      {
        text: "Customfield 2",
        value: "Peter",
        key: "OS-Peter"
      }
    ];
  }

  get alerts() {
    return this.myAlerts;
  }

  get otherAlerts() {
    return [
      {
        text: "Only use chips if you have important kpi's or key information like ticket status",
        type: "info"
      },
      {
        text: "If you use subtitle and chips, try only use few chips, best only one",
        type: "warning"
      },
      {
        text: "If you use multiple chips, try to provide information that has the same importance, e.g ",
        type: "warning"
      },
      {
        text: "Only use alerts directly in the header if you not use tabs (since you dont want to shift tabs)",
        type: "warning"
      }
    ];
  }

  get chips() {
    return [];
  }

  testItem = {
    title: "Hello world",
    status: "new",
    dates: {
      today: new Date().toISOString()
    },
    type: "Car"
  };

  get actions() {
    return [
      {
        text: "Schaden erstellen",
        key: "primaryAction",
        icon: "mdi-magnify",
        count: 5
      },
      {
        text: "Rundgang starten",
        key: "secondaryAction",
        icon: "mdi-cog-outline",
        count: 143
      },
      {
        text: "Begehung erstellen",
        key: "showDetails",
        icon: "mdi-note-edit-outline"
      },
      {
        text: "Protokoll erstellen",
        key: "showDetails",
        icon: "mdi-code-tags"
      },
      {
        text: "Übergabe löschen",
        key: "fourthAction",
        icon: "mdi-delete-outline",
        color: "red",
        count: 66
      }
    ];
  }

  get navigations() {
    return [
      {
        text: "Schäden",
        key: "primaryAction",
        icon: "mdi-magnify",
        color: "success",
        count: 165
      },
      {
        text: "Begehungen",
        key: "secondAction",
        icon: "mdi-note-edit-outline",
        color: "warning",
        count: 2
      },
      {
        text: "Protokolle",
        key: "showDetails",
        icon: "mdi-code-tags",
        color: "info",
        count: 1
      },
      {
        text: "Aktivitäten",
        key: "showActivities",
        icon: "mdi-timeline-check-outline",
        color: "black",
        count: 350
      },
      {
        text: "Analysen",
        key: "fourthAction",
        icon: "mdi-chart-line",
        color: "grey"
      }
    ];
  }

  get quicklinks() {
    return [
      {
        text: "Neuer Schaden",
        description: "Dokumentieren Sie Schäden am Fahrzeug",
        key: "newFinding",
        color: "success"
      },
      {
        text: "Rundgang starten",
        description: "Starten Sie einen Rundgang um alle Schäden zu begutachten.",
        key: "startTour",
        color: "success"
      },
      {
        text: "Begehung dokumentieren",
        description: "Legen Sie eine Begehung mit Ort, Datum, Teilnehmenden und mehr an.",
        key: "newInspection",
        color: "warning"
      },
      {
        text: "Protokoll erstellen",
        description: "Erstellen Sie ein revisionssicheres Protokoll des Status Quo.",
        key: "newProtocoll",
        color: "info"
      }
    ];
  }

  get breadCrumbs() {
    return [
      {
        text: "Übergaben",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelines",
          params: {}
        }
      },
      {
        text: "# <number>",
        exact: true,
        disabled: false,
        to: {
          name: "DesignGuidelinesHeaderExample",
          params: {}
        }
      }
    ];
  }

  goToDetail() {
    this.$router.push({ name: "DesignGuidelinesObjectDetailExample" });
  }

  processAlerts(action: IAction) {
    if (action.key === "agree") {
      this.myAlerts = [];
    }
  }

  processAction(action: IAction) {
    switch (action.key) {
      case "newFinding":
        this.createDialog = true;
        break;
      case "startTour":
        this.tourDialog = true;
        break;
    }
  }

  get isMobile() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return true;
      case "sm":
        return true;
      case "md":
        return false;
      case "lg":
        return false;
      case "xl":
        return false;
      default:
        return false;
    }
  }
}
