var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, divider, list-item-two-line,list-item-two-line, divider"
    }
  }) : _vm.customerAccount ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate text-subtitle-2 text-md-h5"
  }, [_vm._v(" " + _vm._s(_vm.customerAccount.userName) + " " + _vm._s(_vm._f("getFlagEmoji")(_vm.customerAccount.countryCode || "")) + " ")]), _c('v-spacer'), _c('h3', {
    staticClass: "text-truncate text-subtitle-2 text-md-h5"
  }, [_vm._v(" " + _vm._s(_vm._f("simpleDate")(_vm.customerAccount.timestamp.created)) + " ")])], 1), _c('v-card-subtitle', [_c('v-row', {
    staticStyle: {
      "margin": "0 1px 0 1px"
    }
  }, [_vm._v(" " + _vm._s(_vm.customerAccount.id) + " "), _c('v-spacer'), _c('v-chip', {
    attrs: {
      "color": _vm.customerAccount.isActive ? 'success' : 'error'
    }
  }, [_vm._v(" " + _vm._s(_vm.customerAccount.isActive ? _vm.$t("common.states.active") : _vm.$t("common.states.inactive")) + " ")])], 1)], 1), _c('customer-basic-info-card', {
    attrs: {
      "createdOn": _vm.customerAccount.timestamp.created,
      "isActive": _vm.customerAccount.isActive,
      "isMarketingOptIn": _vm.customerAccount.isMarketingOptIn,
      "userId": _vm.customerAccount.userId,
      "customerAccountId": _vm.customerAccount.id,
      "navigateToDetails": _vm.navigateToDetails
    }
  }), _c('customer-contact-card', {
    attrs: {
      "customerName": _vm.customerAccount.firstName + ' ' + _vm.customerAccount.lastName,
      "address": _vm.customerAccount.address,
      "customerContact": _vm.customerAccount.contact,
      "userId": _vm.customerAccount.userId,
      "navigateToDetails": false
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.customerAccount
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }