























import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { LeasingContract } from "@/models/leasingContract";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";

@Component({
  components: {},
  filters: { simpleDoubleDigitDate }
})
export default class RefsContract extends Vue implements IRefDetail<LeasingContract> {
  @Prop()
  item!: LeasingContract;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async goToDetail() {
    // TODO not implemented
  }
}
