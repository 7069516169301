var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.value.summary,
      "breadCrumbs": _vm.breadCrumbs,
      "alerts": _vm.alerts,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": _vm.processAction,
      "alertClicked": _vm.processAction
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm.showTitleEdit ? _c('div', [!_vm.isEditingTitle && !_vm.value.isRecurringRoot ? _c('v-btn', {
          attrs: {
            "small": "",
            "text": ""
          },
          on: {
            "click": _vm.startEditingTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("project.ticket.editTitle")) + " ")]) : _vm._e()], 1) : _vm._e()];
      },
      proxy: true
    }, _vm.isEditingTitle ? {
      key: "title",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mt-1 mb-n1",
          attrs: {
            "label": _vm.$t('common.nouns.title'),
            "outlined": ""
          },
          model: {
            value: _vm.editTitleCopy,
            callback: function callback($$v) {
              _vm.editTitleCopy = $$v;
            },
            expression: "editTitleCopy"
          }
        }), _c('div', {
          staticClass: "mt-n6"
        }, [_c('v-btn', {
          staticClass: "mr-1",
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info"
          },
          on: {
            "click": _vm.saveEditTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "elevation": 0,
            "color": "info",
            "outlined": ""
          },
          on: {
            "click": _vm.cancelEditTitle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])], 1)];
      },
      proxy: true
    } : null], null, true)
  }), !_vm.isEditingTitle ? _c('div', {
    staticClass: "text-medium-emphasis mx-4 mb-4 mt-n8 text-truncate"
  }, [_c('span', [_vm.value.ack ? _c('v-menu', {
    attrs: {
      "offset-y": "",
      "max-width": "300",
      "close-on-content-click": false,
      "transition": "scale"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-chip', _vm._g(_vm._b({
          staticClass: "mr-2",
          attrs: {
            "color": "success",
            "dark": "",
            "small": ""
          },
          on: {
            "click": _vm.inializeUnacknowledgement
          }
        }, 'v-chip', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("acknowledged")) + " ")])];
      }
    }], null, false, 2090201669),
    model: {
      value: _vm.isUnacknowledgeMenu,
      callback: function callback($$v) {
        _vm.isUnacknowledgeMenu = $$v;
      },
      expression: "isUnacknowledgeMenu"
    }
  }, [_c('v-card', {
    staticClass: "pa-1",
    attrs: {
      "elevation": 0
    }
  }, [_c('v-card-text', [_c('v-btn', {
    attrs: {
      "elevation": 0,
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.isUnacknowledgeConfirmation = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCard.unacknowledge")) + " ")])], 1), _vm.isUnacknowledgeConfirmation ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "elevation": 0
    },
    on: {
      "click": _vm.cancelUnacknowledgementDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('v-btn', {
    attrs: {
      "x-small": "",
      "elevation": 0,
      "color": "info",
      "loading": _vm.isUnacknowledgementLoading
    },
    on: {
      "click": _vm.unacknowledge
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm._l(_vm.chips, function (item, index) {
    return _c('span', {
      key: index
    }, [_c('v-chip', {
      staticClass: "mr-2",
      attrs: {
        "color": item.color,
        "dark": "",
        "small": ""
      }
    }, [_c('v-icon', {
      staticClass: "ml-n1 mr-1",
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(" " + _vm._s(item.icon) + " ")]), _vm._v(" " + _vm._s(item.text) + " ")], 1)], 1);
  }), _c('span', [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "max-width": "300",
      "close-on-content-click": false,
      "transition": "scale"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-chip', _vm._g(_vm._b({
          staticClass: "mr-2",
          attrs: {
            "color": "info",
            "dark": "",
            "small": ""
          },
          on: {
            "click": _vm.initializeDatePicker
          }
        }, 'v-chip', attrs, false), on), [_vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(new Date(_vm.value.start).toISOString())) + " ")])];
      }
    }], null, false, 2111389242)
  }, [_c('v-card', {
    staticClass: "pa-1",
    attrs: {
      "elevation": 0
    }
  }, [_c('v-date-picker', {
    attrs: {
      "readonly": _vm.value.isRecurringRoot
    },
    on: {
      "change": _vm.startConfirmStartDialog
    },
    model: {
      value: _vm.startStringCopy,
      callback: function callback($$v) {
        _vm.startStringCopy = $$v;
      },
      expression: "startStringCopy"
    }
  }), _vm.showConfirmDatePicker ? _c('div', {
    staticClass: "ma-1 m-n2"
  }, [_vm.value.isRecurringRoot ? _c('div', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCard.recurringChangeWarningInCaseOfChanges")) + " ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-btn', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "elevation": 0
    },
    on: {
      "click": _vm.discardStartDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")))]), _c('v-btn', {
    attrs: {
      "x-small": "",
      "elevation": 0,
      "color": "info"
    },
    on: {
      "click": _vm.confirmStartDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("save")))])], 1)]) : _vm._e()], 1)], 1)], 1)], 2) : _vm._e(), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _c('v-divider'), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "details",
      fn: function fn() {
        return [_c('m-detail-table', {
          attrs: {
            "title": _vm.$t('about'),
            "i18nPath": "objects.event",
            "item": _vm.value,
            "config": _vm.detailTableConfig,
            "showMoreAction": _vm.actionRecord.edit
          },
          on: {
            "actionClicked": _vm.processAction
          },
          scopedSlots: _vm._u([{
            key: "slot.userId",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('refs-user', {
                staticClass: "ma-n2 mx-n4",
                attrs: {
                  "item": _vm.getUserFromId(item.value)
                }
              }), _c('span', {
                domProps: {
                  "textContent": _vm._s(_vm.simpleDoubleDigitDate(_vm.value.timestamp.created))
                }
              })];
            }
          }, {
            key: "slot.timestamp.created",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('span', {
                domProps: {
                  "textContent": _vm._s(_vm.simpleDoubleDigitDate(item.value))
                }
              })];
            }
          }, {
            key: "slot.rrule",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [item.rrule ? _c('span', {
                domProps: {
                  "textContent": _vm._s(item.recurrenceDescription)
                }
              }) : _vm._e()];
            }
          }, {
            key: "slot.ack",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [item.value ? _c('refs-user', {
                staticClass: "ma-n2 mx-n4",
                attrs: {
                  "item": _vm.getUserFromId(item.value.by)
                }
              }) : _vm._e(), item.value ? _c('span', {
                domProps: {
                  "textContent": _vm._s(_vm.simpleDoubleDigitDate(item.value.at))
                }
              }) : _vm._e()];
            }
          }, {
            key: "slot.start",
            fn: function fn() {
              return [_c('span', [_vm.value.start ? _c('span', [_vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(new Date(_vm.value.start).toISOString())) + " ")]) : _vm._e(), !_vm.value.isAllDay ? _c('span', [_c('br'), _vm._v(" " + _vm._s(_vm.formatHoursAndMinutes(new Date(_vm.value.start))) + " ")]) : _vm._e()])];
            },
            proxy: true
          }, {
            key: "slot.end",
            fn: function fn() {
              return [_vm.value.end ? _c('span', [_c('span', [_vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(new Date(_vm.value.end).toISOString())) + " ")]), _vm.value.isAllDay ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("objects.event.isAllDay")) + " ")]) : _c('span', [_c('br'), _vm._v(" " + _vm._s(_vm.formatHoursAndMinutes(new Date(_vm.value.end))) + " ")])]) : _vm._e()];
            },
            proxy: true
          }, {
            key: "slot.ruleOptions",
            fn: function fn() {
              return [_c('event-recurring', {
                attrs: {
                  "value": _vm.value
                }
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('m-action-list', {
          attrs: {
            "divider": true,
            "actions": _vm.actions
          },
          on: {
            "actionClicked": _vm.processAction
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', [_c('div', {
    staticClass: "mb-2 mt-n2",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "height": "35px"
    }
  }, [_vm.creator ? _c('v-avatar', {
    staticClass: "white--text ml-2 mr-2",
    attrs: {
      "color": "primary",
      "size": "25px",
      "right": true
    }
  }, [_vm._v(" " + _vm._s(_vm.creator.firstName[0]) + _vm._s(_vm.creator.lastName[0]) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.subtitle) + " "), _c('v-spacer'), !_vm.value.isRecurringRoot ? _c('v-btn', {
    attrs: {
      "small": "",
      "text": ""
    },
    on: {
      "click": _vm.editDescription
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]) : _vm._e()], 1), _c('v-textarea', {
    staticClass: "px-1",
    attrs: {
      "outlined": "",
      "readonly": !_vm.isEditDescription,
      "multiline": ""
    },
    model: {
      value: _vm.descriptionCopy,
      callback: function callback($$v) {
        _vm.descriptionCopy = $$v;
      },
      expression: "descriptionCopy"
    }
  }), _vm.isEditDescription ? _c('div', {
    staticClass: "mt-n5 mb-2 ml-1"
  }, [_c('v-btn', {
    staticClass: "mr-1",
    attrs: {
      "x-small": "",
      "elevation": 0
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('cancel'))
    },
    on: {
      "click": _vm.abortUpdateDescription
    }
  }), _c('v-btn', {
    attrs: {
      "x-small": "",
      "elevation": 0,
      "color": "info"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('save'))
    },
    on: {
      "click": _vm.updateDescription
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "px-1 pb-2"
  }, [_c('refs-selected', {
    attrs: {
      "value": [_vm.vehicleRef],
      "partnerId": _vm.partner.id,
      "draggable": false,
      "removable": false
    }
  }), _vm.value.isRecurringRoot ? _c('event-card-recurring-list', {
    attrs: {
      "value": _vm.value
    }
  }) : _vm._e()], 1), _c('div', [_c('v-divider'), _c('activity-card', {
    attrs: {
      "partner": _vm.partner,
      "source": _vm.source,
      "config": {
        comment: true,
        mail: false
      }
    }
  })], 1)]), _c('event-card-acknowledgement-dialog', {
    ref: "eventCardAcknowledgementDialog",
    attrs: {
      "value": _vm.value
    },
    on: {
      "confirmed": function confirmed($event) {
        return _vm.$emit('acknowledged');
      }
    }
  }), _vm.isDeleteDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('delete')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.confirmDeletion
    }
  }) : _vm._e(), _vm.isTicketCreateDialogActive ? _c('ticket-create-dialog', {
    attrs: {
      "refs": [_vm.vehicleRef, _vm.eventRef],
      "partnerIdProp": _vm.partner.id,
      "isDialogActive": _vm.isTicketCreateDialogActive,
      "hideToast": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isTicketCreateDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isTicketCreateDialogActive = $event;
      },
      "created": _vm.onTicketCreated,
      "close": function close($event) {
        _vm.isTicketCreateDialogActive = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }