










import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import SnapshotDetail from "@/components/handover/SnapshotDetail.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ISnapshot, Snapshot } from "@/models/snapshot.entity";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, LatestEntriesCardEmpty, SnapshotDetail }
})
export default class SnapshotDetailView extends mixins(Vue, PermissionMixin) {
  isLoading = false;

  value: ISnapshot | null = null;

  async fetchSnapshot() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;
    const snapshotId = this.$route.params.snapshotId;

    this.value = await new Snapshot({
      partnerId: partnerId,
      handoverId: handoverId,
      id: snapshotId
    }).fetch();
  }

  async mounted() {
    try {
      this.isLoading = true;
      await this.fetchSnapshot();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
