var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('quick-link-list', {
    attrs: {
      "parseQuery": true
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }