var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    ref: "paginationTable",
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "showSelect": true,
      "controlElements": _vm.controlElements,
      "selectedItems": _vm.selectedItems,
      "loading": _vm.loadingVehicles
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selectActions",
      fn: function fn() {
        return [_c('Can', {
          attrs: {
            "I": _vm.ActionEnum.CREATE,
            "a": _vm.ResourceEnum.BLUEPRINT
          }
        }, [_c('v-btn', {
          staticClass: "ma-1",
          attrs: {
            "elevation": "0"
          },
          on: {
            "click": _vm.$refs.blueprintDialog.open
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("blueprint.create.title")) + " ")])], 1), _c('v-btn', {
          staticClass: "ma-1",
          attrs: {
            "elevation": "0",
            "color": "error"
          },
          on: {
            "click": function click($event) {
              _vm.isDeleteVehicleDialogActive = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.i18n["delete"]) + " ")])];
      },
      proxy: true
    }, {
      key: "bodyActions",
      fn: function fn() {
        return [_c('context-menu', [_c('v-list-item', {
          on: {
            "click": function click($event) {
              _vm.isCreateVehicleDialoActive = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]), _c('v-list-item', {
          on: {
            "click": _vm.goToFleetImport
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.import")) + " ")]), _vm.isQrCodeMultipleDownload ? _c('vehicle-multiple-qr-code-download', {
          attrs: {
            "vehicles": _vm.filteredVehicles
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.state",
      fn: function fn(_ref) {
        var _vm$getState;

        var item = _ref.item;
        return [_c('tooltip', {
          attrs: {
            "text": _vm.$t((_vm$getState = _vm.getState(item)) === null || _vm$getState === void 0 ? void 0 : _vm$getState.label)
          }
        }, [item.state ? _c('v-chip', {
          attrs: {
            "color": _vm.getState(item).color
          }
        }, [_vm._v(" " + _vm._s(_vm.i18n["state"][item.state]) + " ")]) : _vm._e()], 1)];
      }
    }, {
      key: "item.displayName",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'FleetVehicleDetail',
              params: {
                partnerId: item.partnerId,
                vehicleId: item.id
              }
            }
          }
        }, [_vm._v(_vm._s(item.displayName))])];
      }
    }])
  }, [_c('template', {
    slot: "onboarding"
  }, [_c('v-card', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "750px"
    },
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.noVehicle'))
    }
  }), _c('v-spacer')], 1), _c('v-card-subtitle', [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.noVehicleHint0'))
    }
  }), _vm.canCreateVehicle ? _c('a', {
    on: {
      "click": function click($event) {
        _vm.isCreateVehicleDialoActive = true;
      }
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.noVehicleHint1'))
    }
  })]) : _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.noVehicleHint1'))
    }
  }), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.noVehicleHint2'))
    }
  }), _vm.canCreateVehicle ? _c('a', {
    on: {
      "click": _vm.goToFleetImport
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.noVehicleHint3'))
    }
  })]) : _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.noVehicleHint3'))
    }
  }), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.fleet.FleetHomeVehicleTable.noVehicleHint4'))
    }
  })]), _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.VEHICLE
    }
  }, [_c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "color": "info"
    },
    on: {
      "click": function click($event) {
        _vm.isCreateVehicleDialoActive = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("getStarted")) + " ")])], 1)], 1)], 1)], 1)], 2), _c('create-vehicle-dialog', {
    attrs: {
      "isDialogActive": _vm.isCreateVehicleDialoActive,
      "partnerId": _vm.partnerId
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isCreateVehicleDialoActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isCreateVehicleDialoActive = $event;
      }
    }
  }), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('components.fleet.FleetVehicleDetailContextMenu.confirmDeletionTitleMultiple', {
        count: _vm.selectedItems.length
      }),
      "isDialogActive": _vm.isDeleteVehicleDialogActive
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteVehicleDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteVehicleDialogActive = $event;
      },
      "rightClick": function rightClick($event) {
        return _vm.deleteVehicles(_vm.selectedItems);
      }
    }
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isUpdateVehicleStatusActive,
      "rightLoading": _vm.loadingDisable
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isUpdateVehicleStatusActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isUpdateVehicleStatusActive = $event;
      },
      "rightClick": _vm.changeVehicleStatus,
      "leftClick": _vm.cancelChangeVehicleStatus,
      "close": _vm.cancelChangeVehicleStatus
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.vehicleStates,
      "solo": "",
      "flat": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state." + item)) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state." + item)) + " ")];
      }
    }]),
    model: {
      value: _vm.tempStatus,
      callback: function callback($$v) {
        _vm.tempStatus = $$v;
      },
      expression: "tempStatus"
    }
  })], 1), _c('blueprint-create-dialog', {
    ref: "blueprintDialog",
    attrs: {
      "hideButton": false,
      "partnerId": _vm.partnerId,
      "vehicles": _vm.selectedItems
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }