













import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import HandoverTable from "@/components/handover/HandoverTable.vue";
import MHeader, { IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IHandover, Handover } from "@/models/handover.entity";
import { mixins } from "vue-class-component";
import { Component, Vue } from "vue-property-decorator";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { handleError } from "@/lib/utility/handleError";
import InspectionTable from "@/components/handover/InspectionTable.vue";
import { dottedSubString } from "@/lib/utility/string-helper";

@Component({
  components: { TheLayoutPortal, HandoverTable, InspectionTable, MHeader, LatestEntriesCardEmpty }
})
export default class InspectionTableView extends mixins(Vue, PermissionMixin) {
  title = this.$t("inspection.title");

  isLoading = false;

  handover: IHandover | null = null;

  vehicles: IVehicle[] = [];

  get breadCrumbs(): IBreadcrumb[] {
    const breadcrumbs: IBreadcrumb[] = [];

    if (!this.handover) {
      return breadcrumbs;
    }

    breadcrumbs.push({
      text: this.$t("handover.title"),
      exact: true,
      disabled: false,
      to: {
        name: "HandoverTableView",
        params: {
          partnerId: this.handover.partnerId
        }
      }
    });

    let handoverTitle = "";
    if (handoverTitle) {
      handoverTitle = dottedSubString(this.handover.title, 30);
    } else {
      handoverTitle = `#${this.handover.number}`;
    }
    breadcrumbs.push({
      text: handoverTitle,
      exact: true,
      disabled: false,
      to: {
        name: "HandoverDetailView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    breadcrumbs.push({
      text: this.$t("inspection.title"),
      exact: true,
      disabled: true,
      to: {
        name: "InspectionTableView",
        params: {
          partnerId: this.handover.partnerId,
          handoverId: this.handover.id
        }
      }
    });

    return breadcrumbs;
  }

  async fetchHandover() {
    const partnerId = this.$route.params.partnerId;
    const handoverId = this.$route.params.handoverId;

    const handover = await new Handover({
      partnerId: partnerId,
      id: handoverId
    }).fetch();

    this.handover = handover;
  }

  async fetchVehicles() {
    const partnerId = this.$route.params.partnerId;
    const vehicleIds = this.handover?.vehicleIds ?? [];

    const vehiclesAsync = vehicleIds.map(v =>
      new Vehicle({
        partnerId: partnerId,
        id: v
      }).fetch()
    );

    this.vehicles.splice(0);
    this.vehicles.push(...(await Promise.all(vehiclesAsync)));
  }

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchHandover();
      await this.fetchVehicles();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
