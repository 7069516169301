

























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TimelineCard from "@/components/utility/TimelineItem.vue";

@Component({
  components: { TimelineCard }
})
export default class BookingBookingInformationTimeLineElement extends mixins(DarkModeHighlightMixin) {
  @Prop()
  notes!: string;

  isEditNotes = false;
  notesCopy = this.notes;

  editDescription() {
    this.notesCopy = this.notes;
    this.isEditNotes = true;
  }

  updateDescription() {
    this.isEditNotes = false;
    this.$emit("input", this.notesCopy);
  }

  abortUpdateDescription() {
    this.notesCopy = this.notes;
    this.isEditNotes = false;
  }
}
