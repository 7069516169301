var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', {
    staticClass: "mb-4 ma-0 ml-1 "
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-n1 pl-0",
    staticStyle: {
      "position": "absolute"
    }
  }, [_c('a', {
    on: {
      "click": _vm.goToList
    }
  }, [_c('v-icon', [_vm._v(" mdi-menu-left ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentDetail.back")) + " ")])], 1)])], 1)], 1), !_vm.isError ? _c('partner-report-detail', {
    attrs: {
      "displayToDetailView": false,
      "loading": _vm.loading
    },
    on: {
      "setReport": _vm.goToList
    }
  }) : _c('v-container', {
    staticClass: "pt-8"
  }, [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/undraw_towing_6yy4.svg"),
      "max-height": "200",
      "max-width": "400",
      "contain": ""
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v("Fehler beim Laden")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }