var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('m-header', {
    attrs: {
      "title": _vm.$t('views.booking.BookingTable.title'),
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      }
    }
  }), _c('booking-table', {
    attrs: {
      "partnerId": _vm.partnerId,
      "selectedItems": _vm.selectedItems
    },
    on: {
      "goTo": _vm.processGoToAction,
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }