import { WebhookRouteNames } from "@/lib/utility/webhook.go-to-helper";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import WebhookConfigDetailView from "@/views/webhook/WebhookConfigDetailView.vue";
import WebhookConfigTableView from "@/views/webhook/WebhookConfigTableView.vue";

/**
 * Routes for costs and tickets
 */
export const webhookConfigRouter: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/webhook-config/",
    component: WebhookConfigTableView,
    name: WebhookRouteNames.WEBHOOK_CONFIG_TABLE,
    permission: {
      resource: ResourceEnum.WEBHOOK_CONFIG,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-pirate",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 50
    }
  },
  {
    path: "/partner/:partnerId/webhook-config/:webhookConfigId",
    component: WebhookConfigDetailView,
    name: WebhookRouteNames.WEBHOOK_CONFIG_DETAIL,
    permission: {
      resource: ResourceEnum.WEBHOOK_CONFIG,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];
