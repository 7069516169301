var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', {
    attrs: {
      "mt-1": ""
    }
  }, [_c('v-row', {
    class: _vm.showFab ? '' : 'pb-6'
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('views.Fahrzeugschein.label'),
      "rules": [_vm.apiKeyRules.required],
      "clearable": "",
      "solo": ""
    },
    on: {
      "change": _vm.changeApiKey
    },
    model: {
      value: _vm.apiKey,
      callback: function callback($$v) {
        _vm.apiKey = $$v;
      },
      expression: "apiKey"
    }
  }), !_vm.showFab && _vm.isFahrzeugscheinTokenValid ? _c('v-btn', {
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.newFahrzeugschein
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.Fahrzeugschein.button")) + " ")]) : _vm._e()], 1)], 1), _c('fahrzeugschein-table')], 1), _vm.showFab ? _c('v-fab-transition', [_c('v-btn', {
    staticClass: "fab",
    attrs: {
      "fab": "",
      "absolute": "",
      "fixed": "",
      "large": "",
      "dark": "",
      "bottom": "",
      "right": "",
      "color": "primary"
    },
    on: {
      "click": _vm.newFahrzeugschein
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1) : _vm._e(), !_vm.isFahrzeugscheinTokenValid ? _c('v-dialog', {
    attrs: {
      "value": _vm.dialog,
      "width": "500"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline lighten-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("views.Fahrzeugschein.dialog.title")) + " ")]), _c('v-divider'), _c('v-card-text', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.Fahrzeugschein.dialog.description'))
    }
  }), _c('v-container', [_c('v-row', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('views.Fahrzeugschein.label'),
      "rules": [_vm.apiKeyRules.required],
      "outlined": "",
      "clearable": ""
    },
    on: {
      "change": _vm.changeApiKey
    },
    model: {
      value: _vm.apiKey,
      callback: function callback($$v) {
        _vm.apiKey = $$v;
      },
      expression: "apiKey"
    }
  })], 1)], 1), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.Fahrzeugschein.dialog.infoOnForgot'))
    }
  }), _c('div', {
    staticClass: "text-center"
  }, [_c('p', [_c('a', {
    attrs: {
      "href": "mailto:info@mmmint.ai"
    }
  }, [_vm._v("info@mmmint.ai")])])]), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('views.Fahrzeugschein.dialog.greeting'))
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Ok! ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }